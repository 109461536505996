import { makeObservable } from "mobx";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { UploadedFileBatchModelData } from "./types";
import { SyncUpdateValue } from "@/store/sync/types";

export class UploadedFileBatchObservable extends BaseSyncModel<UploadedFileBatchModelData> {
  public modelKind = SyncModelKind.UploadedFileBatch;

  constructor({
    id,
    data,
    store,
  }: { id: string; data: SyncUpdateValue<UploadedFileBatchModelData> } & WithAppStore) {
    super({ id, data, store });

    makeObservable(this, {
      modelKind: false,
    });
  }
}
