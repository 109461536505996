import { observer } from "mobx-react-lite";
import { FC } from "react";
import { SyncState } from "@/components/sync/types";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import styled from "@emotion/styled";
import { SyncStatusIcon } from "@/components/sync/SyncStatusIcon";
import { SyncStatusText } from "@/components/sync/SyncStatusText";

interface Props {
  syncState: SyncState;
}

export const SyncStatus: FC<Props> = observer(({ syncState }) => {
  return (
    <Label>
      <SyncStatusIcon syncState={syncState} />
      <SyncStatusText syncState={syncState} />
    </Label>
  );
});

const Label = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  flexWrap: "nowrap",
  gap: 6,
  fontSize: mdsFontSizes().small,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 6,
  paddingBottom: 6,
  color: mdsColors().grey.x500,
});
