import {
  MdsDropdownDetailItem,
  MdsDropdownDividerItem,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { useTitledConversations } from "@/components/chat/useTitledConversations";
import { MdsDropdownButtonItem, MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { formatDateString, isToday } from "@/domains/date/date";
import { DateTime } from "luxon";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { datadogRum } from "@datadog/browser-rum";

interface PreviousChatsContentListProps {
  onClick: (conversationId: string) => void;
  enabled?: boolean;
}

export const usePreviousChatsContentList = ({
  onClick,
}: PreviousChatsContentListProps): MdsDropdownContentList => {
  const start = performance.now();
  const titledConversations = useTitledConversations();

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
  const yesterday = today - 86_400_000; // 24 hours in ms
  const thisWeek = today - 604_800_000; // 7 days in ms
  let didToday = false;
  let didYesterday = false;
  let didThisWeek = false;
  let didLater = false;

  const items: MdsDropdownItem[] = [];
  titledConversations.forEach(({ conversationId, title, timestamp }) => {
    if (timestamp >= today && !didToday) {
      didToday = true;

      items.push({
        id: "Today",
        kind: MdsDropdownItemKind.Detail,
        text: "Today",
      } as MdsDropdownDetailItem);
    } else if (timestamp < today && timestamp >= yesterday && !didYesterday) {
      didYesterday = true;

      if (didToday) {
        items.push({
          id: "Yesterday-separator",
          kind: MdsDropdownItemKind.Divider,
        } as MdsDropdownDividerItem);
      }

      items.push({
        id: "Yesterday",
        kind: MdsDropdownItemKind.Detail,
        text: "Yesterday",
      } as MdsDropdownDetailItem);
    } else if (timestamp < yesterday && timestamp >= thisWeek && !didThisWeek) {
      didThisWeek = true;

      if (didToday || didYesterday) {
        items.push({
          id: "This-week-separator",
          kind: MdsDropdownItemKind.Divider,
        } as MdsDropdownDividerItem);
      }

      items.push({
        id: "This Week",
        kind: MdsDropdownItemKind.Detail,
        text: "This Week",
      } as MdsDropdownDetailItem);
    } else if (timestamp < thisWeek && !didLater) {
      didLater = true;

      if (didToday || didYesterday || didThisWeek) {
        items.push({
          id: "Later-separator",
          kind: MdsDropdownItemKind.Divider,
        } as MdsDropdownDividerItem);
      }

      items.push({
        id: "Later",
        kind: MdsDropdownItemKind.Detail,
        text: "Later",
      } as MdsDropdownDetailItem);
    }

    const dTime = DateTime.fromMillis(timestamp);
    items.push({
      id: conversationId,
      kind: MdsDropdownItemKind.Button,
      // label: timestamp + " " + title,
      label: title,
      content: () => (
        <div>
          <div>{title}</div>
          <MdsText
            color={mdsColors().grey.x500}
            size={MdsTextSize.XXSmall}
            overflow={MdsTextOverflow.Ellipsis}
          >
            {isToday(dTime) ? formatDateString(dTime, "h:mm a") : formatDateString(dTime, "MMM dd")}
          </MdsText>
        </div>
      ),
      onClick: () => {
        onClick(conversationId);
      },
    } as MdsDropdownButtonItem);
  });

  // since this can be an expensive operation, we log the time it takes to run
  const end = performance.now();
  datadogRum.addTiming("usePreviousChatsContentList", end - start);

  return { items };
};
