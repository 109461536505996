import styled from "@emotion/styled";
import { Button } from "react-aria-components";
import { IMDSButtonProps } from "./button.h";
import { Theme } from "@emotion/react";

// TODO: Temp example
export const StyledButton = styled(Button)<IMDSButtonProps>(props => ({
  display: "flex",
  textWrap: "nowrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
  borderRadius: props.theme.borderRadius.medium,
  fontWeight: props.theme.fontWeights.semiBold,
  fontSize: props.theme.fontSizes.small,
  cursor: "pointer",
  transition: "0.25s ease",
  userSelect: "none",
  padding: getPadding(props),

  ...getRegularColors(props),

  "&:disabled": {
    cursor: "none",
    pointerEvents: "none",
    backgroundColor: props.theme.colors.grey.x300,
  },

  "&:hover": {
    backgroundColor: props.theme.colors.grey.x100,
    transition: "0.2s all",
  },

  "&:active": {
    backgroundColor: props.theme.colors.grey.x200,
  },

  "& .mds-btn-icon": {
    color: props.theme.colors.grey.x0,
  },

  borderColor: "transparent",

  ...getExtras(props),
}));

const getPadding = (props: TButtonProps): string => {
  switch (props.size) {
    case "sm":
      return "8px 8px";
    case "lg":
      return "12px";
    default:
      return "10px 12px";
  }
};

const getExtras = (props: TButtonProps) => {
  switch (props.size) {
    case "lg":
      return {
        "& .mds-btn-icon": {
          fontSize: 16,
        },
        "& .mds-btn-label": {
          fontSize: 16,
          lineHeight: "24px",
        },
      };
  }
};

const getRegularColors = (props: TButtonProps) => {
  switch (props.kind) {
    case "primary":
      return {
        backgroundColor: props.theme.colors.primary.x500,
        color: props.theme.colors.grey.x0,
      };
    default:
      return {
        color: props.theme.colors.grey.x600,
      };
  }
};

type TButtonProps = IMDSButtonProps & { theme: Theme };
