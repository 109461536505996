import { CSSProperties } from "react";
import { SwitchProps } from "react-aria-components";

export enum IMDSSwitchVariant {
  WHITE = "white",
  BLACK = "black",
}

export interface IMDSSwitchProps extends SwitchProps {
  label?: string;
  style?: CSSProperties;
  variant?: IMDSSwitchVariant;
}
