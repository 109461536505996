import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsPrimaryChip } from "@/design-system/components/chip/primary";
import { useAppStore } from "@/store";
import { ChatMessageContext, ChatMessageContextKind } from "@/store/chat/types";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";

type ChatContextChipProps =
  | {
      context: ChatMessageContext;
      onRemove: () => void;
      title?: undefined;
      isDisabled?: boolean;
    }
  | {
      context?: undefined;
      onRemove?: undefined;
      title: string;
      isDisabled?: boolean;
    };

export const ChatContextChip = ({
  context,
  onRemove,
  title: titleProp,
  isDisabled = false,
}: ChatContextChipProps) => {
  const { store } = useAppStore();

  // title based on titleProp or context
  let title = titleProp ?? "Unknown";
  if (context) {
    if (context.kind === ChatMessageContextKind.CollectionMention) {
      title = store.collections.get(context.id)?.label ?? "Unknown";
    } else if (context.kind === ChatMessageContextKind.NoteMention) {
      title = store.notes.get(context.id)?.title ?? "Unknown";
    } else if (context.kind === ChatMessageContextKind.TimeRangeMention) {
      title = context.label ?? "Unknown";
    } else if (context.kind === ChatMessageContextKind.SearchQueryMention) {
      title = context.query_string ?? "Unknown";
    }
  }

  return (
    <MdsPrimaryChip onRemove={onRemove} className={chipStyles} isDisabled={isDisabled}>
      <div className={wrapperStyles}>
        {context?.kind === ChatMessageContextKind.NoteMention && (
          <MdsIcon kind={MdsIconKind.Document} className={iconStyles} />
        )}
        {context?.kind === ChatMessageContextKind.CollectionMention && (
          <MdsIcon kind={MdsIconKind.Collection} className={iconStyles} />
        )}
        {context?.kind === ChatMessageContextKind.TimeRangeMention && (
          <MdsIcon kind={MdsIconKind.CalendarDay} className={iconStyles} />
        )}
        {context?.kind === ChatMessageContextKind.SearchQueryMention && (
          <MdsIcon kind={MdsIconKind.Search} className={iconStyles} />
        )}
        <span className={titleStyles}>{title}</span>
      </div>
    </MdsPrimaryChip>
  );
};

const chipStyles = css({
  paddingTop: 0,
  paddingBottom: 0,
  height: 28,
  userSelect: "none",
});

const wrapperStyles = css({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

const titleStyles = css({
  maxWidth: "160px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const iconStyles = css({
  color: mdsColors().grey.x400,
});
