const LandingPageRedirectConfig = {
  HasVisitedLoginKey: "hasVisitedLogin",
  HasVisitedLoginValue: "1",
} as const;

export const recordVisitingLoginPage = () =>
  localStorage.setItem(
    LandingPageRedirectConfig.HasVisitedLoginKey,
    LandingPageRedirectConfig.HasVisitedLoginValue
  );

export const hasPreviouslyVisitedLoginPage = () =>
  localStorage.getItem(LandingPageRedirectConfig.HasVisitedLoginKey) ===
  LandingPageRedirectConfig.HasVisitedLoginValue;
