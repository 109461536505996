import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { useAppStore } from "@/store";
import { MdsInput } from "@/design-system/components/input/MdsInput";

interface DeleteAccountModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const DeleteAccountModal: FC<DeleteAccountModalProps> = observer(
  ({ isOpen, handleClose }) => {
    const { store, pageStore } = useAppStore();
    const page = pageStore.settingsAccountManagementPage;

    return (
      <MdsModal
        className={modal}
        rootElementId="root"
        isModalOpen={isOpen}
        handleCloseModal={handleClose}
      >
        <div className={modalContainer}>
          <div className={modalHeader}>
            {"Delete my Mem account"}
            <MdsSpacer />
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={handleClose} />
          </div>
          {page.hasActiveSubscription ? (
            <DeleteAccountModalContentHasSubscription
              onClick={() => store.navigation.goToSettingsSubscriptionManagement()}
            />
          ) : (
            <DeleteAccountModalContentDefault
              email={page.currentAccountEmail}
              handleDeleteAccount={page.handleDeleteAccount}
              isDeletingAccount={page.isDeletingAccount}
            />
          )}
        </div>
      </MdsModal>
    );
  }
);

const DeleteAccountModalContentDefault: FC<{
  email: string;
  handleDeleteAccount: () => Promise<void>;
  isDeletingAccount: boolean;
}> = ({ email, handleDeleteAccount, isDeletingAccount }) => {
  const [value, setValue] = useState<string>("");
  const hasUserConfirmed = value === "CONFIRM";
  return (
    <div>
      <div className={modalBody}>
        <p>
          This will close and delete this Mem account <strong>{email}</strong> permanently. This
          action is irreversible.
        </p>
        <p>Please type CONFIRM to proceed.</p>
        <MdsInput
          autoComplete="off"
          placeholder="Type CONFIRM"
          id="confirm-input"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <MdsButton
          variant={MdsButtonVariant.Danger}
          label="Delete my Mem account"
          onClick={handleDeleteAccount}
          isDisabled={!hasUserConfirmed}
          isLoading={isDeletingAccount}
        />
      </div>
      <MdsSpacer />
    </div>
  );
};

const DeleteAccountModalContentHasSubscription: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div>
      <div className={modalBody}>
        <p>You have an active Mem subscription.</p>
        <p>Please cancel your subscription before deleting your account.</p>
        <MdsButton label="Manage subscription" onClick={onClick} />
      </div>
    </div>
  );
};

const modal = css({
  maxWidth: 500,
});

const modalContainer = css({
  display: "flex",
  flexDirection: "column",
  margin: "30px",
});

const modalHeader = css({
  display: "flex",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "26px",
});

const modalBody = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 15,
  marginTop: 10,
  p: {
    fontSize: 14,
    lineHeight: "20px",
    margin: 0,
  },
});
