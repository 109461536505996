import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { DeleteNotePermanentlyModalStore } from "@/components/modal/delete-notes-permanently/DeleteNotePermanentlyModalStore";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { NoteChip } from "@/components/chips/NoteChip";
import { pluralize } from "@/modules/pluralize";
import { INoteObservable } from "@/store/note";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { useModalTitleLimit } from "@/components/modal/modal-title-limits/modal-title-limits";

export interface DeleteNotesPermanentlyModalProps {
  store: DeleteNotePermanentlyModalStore;
}

export const DeleteNotesPermanentlyModal = observer<DeleteNotesPermanentlyModalProps>(
  ({ store }) => {
    const primaryTitle = store.notes[0]?.title || "";
    const secondaryTitle = getSecondaryTitle(store.notes);
    const title = useModalTitleLimit({ primaryTitle, secondaryTitle, isAllShared: false });

    if (!store.notes.length) return null;

    return (
      <MdsModal
        isModalOpen={store.isOpen}
        rootElementId="root"
        handleCloseModal={() => store.close()}
        className={modalStyles}
      >
        <div className={rowStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              Delete <NoteChip inline title={title} /> {secondaryTitle}?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton
            variant={MdsIconButtonVariant.Transparent}
            iconKind={MdsIconKind.Exit}
            onClick={() => store.close()}
          />
        </div>

        <MdsText
          lineHeight={MdsTextSize.Small}
          color={mdsColors().grey.x600}
          size={MdsTextSize.Small}
        >
          Make sure you want to permanently delete{" "}
          {store.notes.length > 1 ? "these notes" : "this note"}.{" "}
          <p className={warningTextStyles}>You cannot undo this action.</p>
        </MdsText>

        <div className={buttonsStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.TextTertiary}
            onClick={() => store.close()}
          />
          <MdsButton
            label="Delete forever"
            variant={MdsButtonVariant.Danger}
            onClick={() => store.handleSubmit()}
          />
        </div>
      </MdsModal>
    );
  }
);

const getSecondaryTitle = (items: (INoteObservable | TemplateObservable)[]) => {
  if (items.length === 1) return "";

  return `and ${items.length - 1} other ${pluralize(items.length - 1, "note", "notes")}`;
};

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const modalStyles = css({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "16px",
  width: "min(600px, 90vw)",
  padding: "24px",
});

const rowStyles = css({
  alignItems: "center",
  display: "flex",
  gap: "12px",
  width: "100%",
});

const warningTextStyles = css({
  color: mdsColors().grey.x600,
  fontWeight: mdsFontWeights().semiBold,
});

const buttonsStyles = css({
  alignSelf: "flex-end",
  display: "flex",
  gap: "8px",
  marginTop: "8px",
});
