import { moduleFactory } from "@/factories/module";
import {
  hasPreviouslyVisitedLoginPage,
  recordVisitingLoginPage,
} from "@/modules/local-storage/landingPage";
import {
  _writeLocalStoragePendingRedirectPath,
  _clearLocalStoragePendingRedirectPath,
  _readLocalStoragePendingRedirectPath,
} from "@/modules/local-storage/redirect";
import {
  writeUpgradedUserEmail,
  clearUpgradedUserEmail,
  readUpgradedUserEmail,
} from "@/modules/local-storage/upgraded-user-email";

export const localStorageModule = moduleFactory.create({
  writePendingRedirectPath: _writeLocalStoragePendingRedirectPath,
  clearPendingRedirectPath: _clearLocalStoragePendingRedirectPath,
  readPendingRedirectPath: _readLocalStoragePendingRedirectPath,
  writeUpgradedUserEmail,
  clearUpgradedUserEmail,
  readUpgradedUserEmail,
  recordVisitingLoginPage,
  hasPreviouslyVisitedLoginPage,
});
