import { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChatMessage } from "@/domains/chat";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { MdsIconKind } from "@/design-system/components/icon";
import styled from "@emotion/styled";
import { ChatMessageInfoModal } from "@/components/messages/item/actions/info";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { uuidModule } from "@/modules/uuid";
import { ChatMessageDebugModal } from "@/components/messages/item/actions/debug";
import { ChatMessageFeedbackModal } from "@/components/messages/feedback";
import { copyNote } from "@/modules/copy-note";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

/**
 * This component renders the actions (like copy, view sources, send feedback, etc.) that can be performed on a system message.
 * Only system messages have actions
 */

interface Props {
  message: ChatMessage;
  isVisible?: boolean;
  inSidePanel?: boolean;
  isGuidedChat_experiment?: boolean;
}

export const ItemActions = observer(
  ({ message, isVisible, inSidePanel = false, isGuidedChat_experiment = false }: Props) => {
    const { store } = useAppStore();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [isFeedbackPositive, setIsFeedbackPositive] = useState(false);
    const spaceAccountChatMessage = message.spaceAccountChatMessage;
    const isUpvoted = spaceAccountChatMessage?.feedback.response === "UPVOTE";
    const isDownvoted = spaceAccountChatMessage?.feedback.response === "DOWNVOTE";
    const savedAsNoteId = message.savedAsNoteId;
    const debugModeEnabled = store.debug.debugModeEnabled;

    const handleInfoClick = () => {
      setIsInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
      setIsInfoModalOpen(false);
    };

    const handleCloseFeedbackModal = () => {
      setIsFeedbackModalOpen(false);
    };

    const handleSaveClick = useCallback(() => {
      const noteId = uuidModule.generate();
      store.chatMessages.saveChatMessage({ chatMessageId: message.id, noteId });
    }, [message.id, store.chatMessages]);

    const handleUpvoteClick = useCallback(() => {
      if (isUpvoted) {
        store.spaceAccountChatMessages.removeFeedback(message.id);
      } else {
        store.spaceAccountChatMessages.provideFeedback(message.id, "UPVOTE");
        setIsFeedbackModalOpen(true);
        setIsFeedbackPositive(true);

        /**
         * Track the message upvote
         */
        trackEvent(TrackedEvent.ChatMessageUpvote, {
          message_id: message.id,
          message_contents: message.sections.map(section => section.value.content).join("<br />"),
          is_guided_chat: isGuidedChat_experiment,
        });
      }
    }, [
      isUpvoted,
      message.id,
      message.sections,
      store.spaceAccountChatMessages,
      isGuidedChat_experiment,
    ]);

    const handleDownvoteClick = useCallback(() => {
      if (isDownvoted) {
        store.spaceAccountChatMessages.removeFeedback(message.id);
      } else {
        store.spaceAccountChatMessages.provideFeedback(message.id, "DOWNVOTE");
        setIsFeedbackModalOpen(true);
        setIsFeedbackPositive(false);

        /**
         * Track the message downvote
         */
        trackEvent(TrackedEvent.ChatMessageDownvote, {
          message_id: message.id,
          message_contents: message.sections.map(section => section.value.content).join("<br />"),
          is_guided_chat: isGuidedChat_experiment,
        });
      }
    }, [
      isDownvoted,
      message.id,
      message.sections,
      store.spaceAccountChatMessages,
      isGuidedChat_experiment,
    ]);

    const handleSubmitFeedback = useCallback(
      (suggestedFeedback: string | undefined, userText: string) => {
        store.spaceAccountChatMessages.provideFeedback(
          message.id,
          isFeedbackPositive ? "UPVOTE" : "DOWNVOTE",
          userText,
          suggestedFeedback ? [suggestedFeedback] : undefined
        );

        setIsFeedbackModalOpen(false);
      },
      [isFeedbackPositive, message.id, store.spaceAccountChatMessages]
    );

    const handleDebugButtonClick = () => {
      setIsDebugModalOpen(true);
    };

    const handleCloseDebugModal = () => {
      setIsDebugModalOpen(false);
    };

    const onCopy = () => {
      const content = message.sections.map(section => section.value.content).join("\n");
      copyNote(content);
    };

    return (
      <Wrapper className="message-actions">
        <ChatMessageInfoModal
          isOpen={isInfoModalOpen}
          onClose={handleCloseInfoModal}
          sources={message.sources}
          inSidePanel={inSidePanel}
        />
        <ChatMessageDebugModal
          isOpen={isDebugModalOpen}
          onClose={handleCloseDebugModal}
          message={message}
        />
        <ChatMessageFeedbackModal
          isModalOpen={isFeedbackModalOpen}
          handleCloseModal={handleCloseFeedbackModal}
          isPositive={isFeedbackPositive}
          onSubmit={handleSubmitFeedback}
        />
        <AnimatePresence>
          {isVisible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <ActionButtons data-test-id={`message-actions-${message.id}`}>
                <MdsIconButton
                  onClick={
                    savedAsNoteId
                      ? () => store.navigation.goToNote({ noteId: savedAsNoteId })
                      : handleSaveClick
                  }
                  variant={MdsIconButtonVariant.Transparent}
                  iconKind={savedAsNoteId ? MdsIconKind.BookmarkSolid : MdsIconKind.Bookmark}
                  tooltipConfig={{
                    label: savedAsNoteId ? "Saved as note" : "Save as note",
                    placement: MdsTooltipPlacement.Top,
                  }}
                />
                <MdsIconButton
                  variant={MdsIconButtonVariant.Transparent}
                  iconKind={MdsIconKind.Copy}
                  tooltipConfig={{
                    label: "Copy",
                    placement: MdsTooltipPlacement.Top,
                  }}
                  onClick={onCopy}
                />
                {message.sources?.length > 0 && (
                  <MdsIconButton
                    variant={MdsIconButtonVariant.Transparent}
                    iconKind={MdsIconKind.InfoCircle}
                    tooltipConfig={{
                      label: "Sources",
                      placement: MdsTooltipPlacement.Top,
                    }}
                    onClick={handleInfoClick}
                    className="info-button"
                  />
                )}
                <MdsIconButton
                  variant={MdsIconButtonVariant.Transparent}
                  iconKind={isUpvoted ? MdsIconKind.UpvoteSolid : MdsIconKind.Upvote}
                  tooltipConfig={{
                    label: isUpvoted ? "Marked as good" : "Mark as good",
                    placement: MdsTooltipPlacement.Top,
                  }}
                  onClick={handleUpvoteClick}
                />
                <MdsIconButton
                  variant={MdsIconButtonVariant.Transparent}
                  iconKind={isDownvoted ? MdsIconKind.DownvoteSolid : MdsIconKind.Downvote}
                  tooltipConfig={{
                    label: isDownvoted ? "Marked as bad" : "Mark as bad",
                    placement: MdsTooltipPlacement.Top,
                  }}
                  onClick={handleDownvoteClick}
                />
                {debugModeEnabled && (
                  <MdsIconButton
                    variant={MdsIconButtonVariant.Transparent}
                    iconKind={MdsIconKind.Warning}
                    tooltipConfig={{
                      label: "Debug",
                      placement: MdsTooltipPlacement.Top,
                    }}
                    onClick={handleDebugButtonClick}
                  />
                )}
              </ActionButtons>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  minHeight: 34,
});

const ActionButtons = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: 8,
});
