import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { SidebarDropdownMenuContent } from "@/components/layout/components/sidebar/SidebarDropdownMenuContent";
import { useProfileDropdownHandlers } from "@/components/layout/components/account-profile/useProfileDropdownHandlers";

export const SidebarDropdownMenu = observer(
  ({
    onClick,
    className,
    displayAccountInfo,
  }: {
    onClick?: () => void;
    className?: string;
    displayAccountInfo?: boolean;
  }) => {
    const { store } = useAppStore();
    const featureFlags = store.spaceAccountFeatureFlags.featureFlags;

    const { syncState } = store.sync;
    const { profilePhotoUrl, profileDisplayName, profileEmailAddress } = store.account.myAccount;
    const { isPreloadingDisabled: isNoteContentPreloadingDisabled } = store.noteContentDocuments;
    const isDebugMode = store.debug.debugModeEnabled;

    const handlers = useProfileDropdownHandlers(onClick);

    return (
      <SidebarDropdownMenuContent
        className={className}
        isDebugMode={isDebugMode}
        syncInfo={{
          isNoteContentPreloadingDisabled,
          syncState,
        }}
        accountInfo={{
          profilePhotoUrl,
          profileDisplayName,
          profileEmailAddress,
        }}
        displayAccountInfo={displayAccountInfo ?? false}
        handlers={handlers}
        featureFlags={featureFlags}
      />
    );
  }
);
