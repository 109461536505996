import { Maybe } from "@/domains/common/types";
import { api } from "@/modules/api";
import { enumModule } from "@/modules/enum";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { ModalDefinitionKind, SyncErrorModalDefinition } from "@/store/modals/types";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { SyncError } from "@/store/sync/operations/errors/SyncError";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import {
  ExternalOperation,
  ExternalOperationKind,
  SyncOperation,
  SyncOperationGeneric,
} from "@/store/sync/operations/types";
import { SyncCustomErrorData } from "@/store/sync/types";
import { formatSyncOperation, saveJsonToFile } from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import { compact, isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { action, computed, makeObservable, reaction, runInAction } from "mobx";
import { JsonObject } from "type-fest";

export class AppSyncActionQueue extends BaseSyncActionQueue<AppStore> {
  constructor(args: { getSpaceId: () => string } & AppSubStoreArgs<AppStore>) {
    super(args);

    makeObservable<this>(this, {
      // ERROR HANDLING
      syncErrorModalFields: computed,
      syncError: computed,

      // LOCAL DB OPERATIONS
      processOperation: action,
      processExternalOperation: action,
      processStandardSyncOperation: action,

      // DEBUG
      fullDebugInfo: computed,
      friendlyDebugInfo: computed,
      toggleDebugging: action,
      saveDebugInfoToFile: false,
    });

    reaction(
      () => this.syncError,
      (syncError, previousSyncError) => {
        runInAction(() => {
          if (isEqual(syncError, previousSyncError)) return;

          if (previousSyncError) this.store.modals.removeModal(previousSyncError);
          if (syncError) this.store.modals.addModal(syncError);
        });
      }
    );
  }

  get syncError(): Maybe<SyncErrorModalDefinition> {
    if (!this.isFailing) return;

    const syncError = this.processingError?.syncErrorModalFieldsGenerator?.(this.store);
    if (!syncError) return;

    return {
      kind: ModalDefinitionKind.SyncError,
      syncError,
    };
  }

  get syncErrorModalFields(): Maybe<SyncErrorModalFields> {
    // Use the syncError in modals because there might be another error queued in front of it.
    return this.store.modals.syncError;
  }

  async processOperation(
    operation: SyncOperationGeneric
  ): Promise<Maybe<SyncOperation | ExternalOperation>> {
    const isExternalOperation = enumModule.findMatchingStringValue(
      ExternalOperationKind,
      operation.operationKind
    );

    /**
     * Standard sync operations are submitted to our backend.
     * External operations have custom execution logic.
     */
    if (isExternalOperation) {
      return this.processExternalOperation(operation as ExternalOperation);
    } else {
      return this.processStandardSyncOperation(operation as SyncOperation);
    }
  }

  async processStandardSyncOperation(operation: SyncOperation): Promise<Maybe<SyncOperation>> {
    try {
      const response = await api.post(`/v2/sync/operations/submit`, {
        params: { query: { space_id: this.getSpaceId() } },
        body: await operation.generateSyncOperation(),
      });

      // HANDLE COMPLETED OPERATIONS
      if (
        !response.error &&
        response.data.status === "COMPLETED" &&
        "sync_operation" in response.data
      ) {
        operation.acknowledge(response.data.sync_operation.value.latest_space_account_sequence_id);
        return operation;
      }

      // HANDLE SKIPPED OPERATIONS
      if (!response.error && response.data.status === "SKIPPED") {
        await this.skipAndRevertRelatedOperationsById(operation.operationId);
        return;
      }

      // HANDLE CUSTOM ERRORS
      if (
        !response.error &&
        response.data.status === "FAILED" &&
        "info" in response.data &&
        "error_data" in response.data.info
      ) {
        const errorInfo = response.data.info.error_data as SyncCustomErrorData;
        this.handleCustomError(operation, errorInfo);
        return;
      }

      // HANDLE 500-599 ERRORS
      // If the database is unavailable, the app server will return a 503.
      // We can retry the operation later.
      if (response.response.status >= 500 && response.response.status < 600) {
        logger.error({
          message: "[SYNC][SyncActionQueue] 500 Error processing standard sync operation",
          info: { error: objectModule.safeAsJson(response) },
        });

        this.handleCustomError(operation, {
          kind: "TRANSIENT",
          value: {}, // TODO: value is required by API, but we're not using it yet.
        });
        return;
      }

      // HANDLE UNKNOWN ERRORS
      this.handleCustomError(operation, { kind: "UNKNOWN", value: {} });
    } catch (error) {
      if (error instanceof SyncError) throw error;

      logger.error({
        message: "[SYNC][SyncActionQueue] Unhandled error processing standard sync operation",
        info: { error: objectModule.safeErrorAsJson(error as Error) },
      });

      // handle the error as TRANSIENT because we hope connection will be restored soon
      this.handleCustomError(operation, { kind: "TRANSIENT", value: {} });

      // re-throw to allow uppers to deal with the rejection
      throw error;
    }
  }

  async processExternalOperation(operation: ExternalOperation): Promise<Maybe<ExternalOperation>> {
    logger.info({
      message: "[SYNC][SyncActionQueue] Processing external operation",
      info: {
        operation: objectModule.safeAsJson({ operation }),
      },
    });

    try {
      await operation.executeExternalOperation();

      /** @todo - Check if this is necessary for ExternalOperations, or if it can just be removed. */
      operation.acknowledge(0);

      return operation;
    } catch (unknownErr) {
      const err = unknownErr as Error;

      logger.error({
        message: "[SYNC][SyncActionQueue] Error processing external operation",
        info: {
          error: objectModule.safeErrorAsJson(err),
        },
      });

      this.handleCustomError(operation, { kind: "UNKNOWN", value: {} });
    }
  }

  // DEBUGGING
  get fullDebugInfo() {
    const processing = this.store.sync.actionQueue.processing;
    const pending = this.store.sync.actionQueue.pending;
    const optimisticUpdates = this.store.sync.actionQueue.optimisticUpdates;

    return {
      info: {
        account_id: `${this.store.account.myAccountId}`,
        space_id: `${this.getSpaceId()}`,
        space_account_id: `${this.store.spaceAccounts.getSpaceAccountBySpaceId(this.getSpaceId())?.id}`,
        current_time: DateTime.local().toLocaleString(DateTime.TIME_SIMPLE),
      },
      stats: {
        tab_state: this.store.tabLifecycleManager.state,
        sync_processing_state_in_this_tab: this.processingState,
        processing_count_global: processing.length,
        pending_count_global: pending.length,
        last_processed_at_start: this.lastProcessingItemStart
          ? DateTime.fromJSDate(this.lastProcessingItemStart).toLocaleString(DateTime.TIME_SIMPLE)
          : null,
        last_processed_at_end: this.lastProcessingItemStop
          ? DateTime.fromJSDate(this.lastProcessingItemStop).toLocaleString(DateTime.TIME_SIMPLE)
          : null,
        sync_state: this.store.sync.syncState,
      },
      errors: {
        is_failing: this.isFailing,
        processing_error: this.processingError
          ? objectModule.safeErrorAsJson(this.processingError)
          : null,
      },
      queue: {
        processing: processing.map(e => formatSyncOperation(e)),
        pending: pending.map(e => formatSyncOperation(e)),
        optimisticUpdates: optimisticUpdates.map(e => objectModule.safeAsJson(e)),
      },
    } as const;
  }

  saveDebugInfoToFile = async () => {
    const debugInfo = this.fullDebugInfo;
    const fileName = `debug-info-${DateTime.local().toFormat("yyyy-MM-dd-HH-mm-ss")}`;
    await saveJsonToFile(debugInfo, fileName);
  };

  get friendlyDebugInfo() {
    const fullInfo = this.fullDebugInfo;
    const visibleOperationCount = 2;

    const formatOperations = (operations: (JsonObject | null)[]) => {
      return compact([
        ...operations.slice(0, visibleOperationCount),
        operations.length > visibleOperationCount
          ? `...and ${operations.length - visibleOperationCount} more`
          : null,
      ]);
    };

    return {
      ...fullInfo,
      queue: {
        processing: formatOperations(fullInfo.queue.processing),
        pending: formatOperations(fullInfo.queue.pending),
      },
    } as const;
  }

  toggleDebugging = () => {
    this.store.debug.toggleDebugMode();
  };
}
