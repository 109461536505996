import { FC } from "react";
import { observer } from "mobx-react-lite";
import EmptyCollections from "@/assets/images/empty-collections.svg";
import { EmptyContainer } from "@/components/styling/empty";
import { CollectionViewPageListEmptyButton } from "@/pages/collections-view/components/CollectionViewPageListEmptyButton";
import { CollectionsViewPageNewNote } from "@/pages/collections-view/components/CollectionsViewPageNewNote";

interface Props {
  isSidePanel: boolean;
}

export const CollectionViewPageListEmpty: FC<Props> = observer(({ isSidePanel }) => {
  return (
    <>
      <CollectionsViewPageNewNote isSidePanel={isSidePanel} />
      <EmptyContainer>
        <img src={EmptyCollections} alt="No collections" />
        <CollectionViewPageListEmptyButton isSidePanel={isSidePanel} />
      </EmptyContainer>
    </>
  );
});
