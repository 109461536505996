import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { CustomEventName, dispatchCustomEvent } from "@/modules/custom-event";
import { IGrantTemplateAclViaEmailAddressOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  SYNC_OPERATION_EMAIL_NOT_FOUND_EXCEPTION,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";

export class GrantTemplateAclViaEmailAddressOperation extends BaseSyncOperation<IGrantTemplateAclViaEmailAddressOperation> {
  get operationKind(): SyncOperationKind {
    return "GRANT_TEMPLATE_ACL_VIA_EMAIL_ADDRESS";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;

    const existingTemplate = await this.store.templates.getAsync(templateId);
    if (!existingTemplate) return [];

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
      model_data: {
        ...existingTemplate.modelData,
        target_scopes: [
          ...existingTemplate.modelData.target_scopes,
          // TODO: How do handle this?
        ],
      },
      model_scopes: existingTemplate.modelScopes,
    };

    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    if (_errorData.metadata?.error_cls === SYNC_OPERATION_EMAIL_NOT_FOUND_EXCEPTION) {
      dispatchCustomEvent(CustomEventName.ShareFailed, {
        email: this.payload.email_address,
      });

      this.ignoreError();
      return;
    }

    this.triggerToast(
      <>
        {this.payload.email_address} could not be shared. If this error continues, please contact
        support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>You do not have permissions to share this template. Please contact the owner.</>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
