import { FloatingActions } from "@/components/floating-actions/FloatingActions";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsSpacer } from "@/design-system/components/spacer";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

export interface MultiSelectActionsProps {
  listState: ListStateObservable;
}

export const MultiSelectActions = observer<MultiSelectActionsProps>(({ listState }) => {
  const numberOfSelectedIds = listState.selectedItemIds.length;
  const isOpen = !!numberOfSelectedIds;

  // TODO: consider using 3rd party hooks for all shortcuts
  useEffect(() => {
    const onEscPressed = (event: KeyboardEvent) => {
      if (!listState?.isSelectModeActive) {
        return;
      }

      if (event.key !== "Escape") {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      listState.reset();
    };

    document.addEventListener("keydown", onEscPressed);
    return () => {
      document.removeEventListener("keydown", onEscPressed);
    };
  }, [listState]);

  const manyActions = listState.canAddToCollection && listState.canRemoveFromCollection;

  return (
    <FloatingActions isOpen={isOpen}>
      <div className={cx(boxStyle, manyActions)}>
        <Selected>{numberOfSelectedIds} selected</Selected>
        <MdsButton
          label={""}
          className={deselectButtonStyles}
          variant={MdsButtonVariant.Secondary}
          size={MdsButtonSize.Small}
          onClick={listState.clearSelection}
        >
          Deselect
        </MdsButton>
        <MdsSpacer />
        {listState.store.debug.debugModeEnabled && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Copy"
            collapsedIcon={listState.isCopying ? MdsIconKind.Spinner : MdsIconKind.Copy}
            onClick={listState.handleCopySelectedNotes}
            isDisabled={listState.isCopying}
          />
        )}
        {listState.canAddToCollection && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="# Organize"
            collapsedIcon={MdsIconKind.Collection}
            onClick={listState.handleAddToCollection}
          />
        )}
        {listState.canRemoveFromCollection && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Remove from collection"
            collapsedIcon={MdsIconKind.Minus}
            onClick={listState.handleRemoveFromCollection}
          />
        )}
        {listState.canRestoreFromTrash && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            fullSizeLabel="Restore from trash"
            collapsedIcon={MdsIconKind.Redo}
            onClick={listState.handleRestore}
          />
        )}
        {listState.canMoveNoteToTrash && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            collapsedIcon={MdsIconKind.Trash}
            fullSizeIcon={MdsIconKind.Trash}
            onClick={listState.handleDeleteNote}
            fullSizeLabel="Delete"
          />
        )}
        {listState.canMoveCollectionToTrash && (
          <MdsPanelResponsiveBtn
            threshold="575px"
            collapsedIcon={MdsIconKind.Trash}
            fullSizeIcon={MdsIconKind.Trash}
            onClick={listState.handleDeleteCollections}
            fullSizeLabel="Delete"
          />
        )}
      </div>
    </FloatingActions>
  );
});

const boxStyle = css({
  alignItems: "center",
  background: "white",
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: 8,
  boxShadow: `0px 6px 10px rgba(69, 78, 104, 0.2)`,
  display: "flex",
  gap: 8,
  justifyContent: "flex-start",
  padding: `10px 18px`,
  fontSize: 14,
});

const Selected = styled.span(({ theme }) => ({
  color: mdsColors().grey.x500,
  fontWeight: theme.fontWeights.medium,
}));

const deselectButtonStyles = css({
  border: "none",
});
