import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { uuidModule } from "@/modules/uuid";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import styled from "@emotion/styled";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";

interface PendingApiKey {
  id: string;
  secretKeyToken: string;
}

/**
 * @TODO - @MacroMackie - This was is a temporary implementation for now.
 * (Just copied from the other Settings-Pages).
 *
 * When we roll this out more broadly, we should rewrite these pages with best practices.
 */
export const SettingsApiKeysSection: FC = observer(() => {
  const { store } = useAppStore();
  const [pendingApiKey, setPendingApiKey] = useState<PendingApiKey | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isPublicApiFeatureEnabled = store.spaceAccountFeatureFlags.getFlagStatus("public-api");

  const createApiKey = useCallback(async () => {
    const id = uuidModule.generate();
    const secretKeyToken = uuidModule.generate();

    await store.apiKeys.createApiKey({
      id,
      secretKeyToken,
      // Title and description are not set for now.
      title: null,
      description: null,
    });

    setPendingApiKey({ id, secretKeyToken });
  }, [store.apiKeys]);

  useEffect(() => {
    // When isCreatingApiKey becomes false and we have a pending key, show the modal
    if (!store.apiKeys.isCreatingApiKey && pendingApiKey) {
      setIsModalOpen(true);
    }
  }, [store.apiKeys.isCreatingApiKey, pendingApiKey]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPendingApiKey(null);
  };

  if (!isPublicApiFeatureEnabled) {
    return null;
  }

  return (
    <>
      <div>Create an API key using the button below.</div>
      <br />
      <MdsButton
        variant={MdsButtonVariant.Tertiary}
        label="Create API Key"
        onClick={createApiKey}
        isLoading={store.apiKeys.isCreatingApiKey}
      />
      <br />
      <MdsModal rootElementId="root" isModalOpen={isModalOpen} handleCloseModal={handleCloseModal}>
        <ModalContainer>
          <Header>
            <Title>
              <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
                API Key Created
              </MdsText>
            </Title>
            <MdsIconButton iconKind={MdsIconKind.Exit} onClick={handleCloseModal} />
          </Header>
          <Content>
            <MdsText
              lineHeight={MdsTextSize.Small}
              color={mdsColors().grey.x600}
              size={MdsTextSize.Small}
            >
              Your new API key has been created. Make sure to save your secret key as it won&apos;t
              be shown again.
            </MdsText>
            <KeyContainer>
              <MdsText
                lineHeight={MdsTextSize.Small}
                color={mdsColors().grey.x600}
                size={MdsTextSize.Small}
              >
                {pendingApiKey ? `sk-mem-${pendingApiKey.secretKeyToken}` : ""}
              </MdsText>
            </KeyContainer>
          </Content>
          <ButtonSection>
            <MdsButton
              label="Close"
              variant={MdsButtonVariant.Tertiary}
              onClick={handleCloseModal}
            />
          </ButtonSection>
        </ModalContainer>
      </MdsModal>
    </>
  );
});

const ModalContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "24px",
  maxWidth: 500,
});

const Header = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const Title = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const Content = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
});

const KeyContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "16px",
  backgroundColor: mdsColors().grey.x50,
  borderRadius: "4px",
  width: "100%",
});

const ButtonSection = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
