import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { pluralize } from "@/modules/pluralize";
import { MDSSwitchFloaty } from "@/mds/components/buttons/switch-floaty";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";

export const SearchPageFloaty = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;

  const deepSearchResultsCount = page.deepSearchResults.length;
  const selectedItemsCount = page.listState.selectedItemIds.length;
  const isShowingDeepSearchResults = page.showDeepSearchResults;

  const isVisible = selectedItemsCount === 0 && deepSearchResultsCount > 0;
  const prefix = isShowingDeepSearchResults ? "Showing" : "Show";
  const deepSearchResultsLabel = `${prefix} ${deepSearchResultsCount} Deep Search ${pluralize(
    deepSearchResultsCount,
    "result",
    "results"
  )}`;

  return (
    <Wrapper isVisible={isVisible}>
      <MDSSwitchFloaty
        label={deepSearchResultsLabel}
        iconKind={MdsIconKind.Radar}
        defaultSelected={page.showDeepSearchResults}
        onChange={(v: boolean) => page.setShowDeepSearchResults(v)}
        style={{ minWidth: 306 }}
      />
    </Wrapper>
  );
});

interface WrapperProps {
  isVisible: boolean;
}

const Wrapper = styled.div<WrapperProps>(({ isVisible }) => ({
  opacity: isVisible ? 1 : 0,
  bottom: isVisible ? 20 : -65,
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
  position: "fixed",
  transition: "all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)",
  width: "100%",
  left: "0%",
  zIndex: 2,
}));
