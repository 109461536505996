import { IMDSToggleButtonProps } from "./toggle-button.types";
import { StyledToggleButton } from "./toggle-button.style";

export function MDSToggleButton(props: IMDSToggleButtonProps) {
  const { children, ariaLabel, ...rest } = props;

  return (
    <StyledToggleButton {...rest} aria-label={ariaLabel}>
      {children}
    </StyledToggleButton>
  );
}
