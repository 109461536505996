import { logger } from "@/modules/logger";
import { clientEnvModule } from "@/modules/client-env";
import { uuidModule } from "@/modules/uuid";

/**
 * @description Detects if there are other tabs open
 *
 * The utility works by:
 * 1. Broadcasting a PING message to all tabs
 * 2. Collecting PONG responses from other tabs
 * 3. After a timeout period, checking if any responses were received
 *
 * @example
 * const hasOtherTabs = await this.store.tabDetector.hasOtherOpenTabs();
 */
export class TabDetector {
  private static instance: TabDetector;
  private channel: BroadcastChannel;
  private activeTabResponses: Set<string> = new Set();
  public readonly tabId: string;

  constructor() {
    this.tabId = uuidModule.generate();
    this.channel = new BroadcastChannel("mem-tab-detector");
    this.setupListeners();
  }

  private setupListeners() {
    this.channel.onmessage = event => {
      const { type, senderId, responseToId } = event.data;

      if (type === "PING") {
        // Respond to ping with our tabId
        this.channel.postMessage({
          type: "PONG",
          senderId: this.tabId,
          responseToId: senderId,
        });
      } else if (type === "PONG" && responseToId === this.tabId) {
        // Collect responses from other tabs
        this.activeTabResponses.add(senderId);
      }
    };

    // Clean up on page unload
    window.addEventListener("unload", () => {
      this.channel.close();
    });
  }

  /**
   * Checks if there are other tabs open
   * @param timeoutMs - How long to wait for responses from other tabs (in milliseconds)
   * @returns Promise<boolean> - True if other tabs are detected, false otherwise
   */
  public async hasOtherOpenTabs(timeoutMs: number = 1000): Promise<boolean> {
    // Skip check if we're in desktop app (single instance)
    if (clientEnvModule.isDesktop()) {
      return false;
    }

    this.activeTabResponses.clear();

    // Broadcast a ping to other tabs
    this.channel.postMessage({
      type: "PING",
      senderId: this.tabId,
    });

    // Wait for responses with a timeout
    await new Promise(resolve => setTimeout(resolve, timeoutMs));

    const hasOtherTabs = this.activeTabResponses.size > 0;

    logger.debug({
      message: "[TabDetector] Checked for other tabs",
      info: {
        tabId: this.tabId,
        otherTabsCount: this.activeTabResponses.size,
        hasOtherTabs,
      },
    });

    return hasOtherTabs;
  }
}
