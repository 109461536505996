import { uuidModule } from "@/modules/uuid";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { CollectionItemUpsertedSyncUpdateValue } from "@/store/collection-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IAddTemplateToCollectionOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";

export class AddTemplateToCollectionOperation extends BaseSyncOperation<IAddTemplateToCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "ADD_TEMPLATE_TO_COLLECTION";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.template_id;

    const existingTemplate = await this.store.templates.getAsync(templateId);
    if (!existingTemplate) return [];

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
      model_data: {
        ...existingTemplate.modelData,
        target_scopes: [
          ...existingTemplate.modelData.target_scopes,
          {
            scope_kind: "COLLECTION_SCOPE",
            role_kind: "EDITOR",
            value: { collection_id: this.payload.collection_id },
          },
        ],
      },
      model_scopes: existingTemplate.modelScopes,
    };

    const templateUpserted: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: templateId,
    });

    const collectionItemValue: CollectionItemUpsertedSyncUpdateValue = {
      model_id: collectionItemId,
      model_kind: "COLLECTION_ITEM",
      model_version: 0,
      model_data: {
        collection_id: this.payload.collection_id,
        item_id: templateId,
        item_kind: "TEMPLATE",
        locally_created_at: this.committedAt,
        added_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
      },
      model_scopes: [],
    };

    const collectionItemUpserted: OptimisticSyncUpdate<CollectionItemModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionItemValue,
    };

    return [templateUpserted, collectionItemUpserted];
  }

  async triggerRecompute() {
    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.template_id,
    });
    await this.store.templates.recompute(this.payload.template_id);
    await this.store.collectionItems.recompute(collectionItemId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
