import { uuidModule } from "@/modules/uuid";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import { ChatMessageModelData, ChatMessageUpsertedSyncUpdateValue } from "@/store/chat/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { validateChatMessageContext } from "@/store/sync/operations/helpers/chat";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ISubmitGuidedChatMessageOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";

export class SubmitGuidedChatMessageOperation extends BaseSyncOperation<ISubmitGuidedChatMessageOperation> {
  get operationKind(): SyncOperationKind {
    return "SUBMIT_GUIDED_CHAT_MESSAGE";
  }

  get spaceAccountChatMessageId() {
    return resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.payload.id,
    });
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    const chatMessageUpdate = this.generateSaveChatMessageSyncUpdate();
    if (chatMessageUpdate) {
      optimisticUpdates.push(chatMessageUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.chatMessages.recompute(this.payload.id);
    await this.store.spaceAccountChatMessages.recompute(this.spaceAccountChatMessageId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send message. Please try again.");
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to chat in this conversation.");
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to send message. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to send message. Please try again.");
  }

  protected generateSaveChatMessageSyncUpdate() {
    const chatMessageUpdateValue: ChatMessageUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "CHAT_MESSAGE",
      model_version: 0,
      model_data: {
        id: this.payload.id,
        chat_conversation_id: this.payload.chat_conversation_id,
        locally_created_at: this.committedAt,
        sent_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        status: "PROCESSING",
        sources: [],
        contexts: this.payload.contexts.map(ctx =>
          validateChatMessageContext(this.payload.id, ctx)
        ),
        actions: [],
        sections: [
          {
            id: uuidModule.generate(),
            chat_message_id: this.payload.id,
            kind: "TEXT",
            status: "PROCESSING",
            value: { kind: "TEXT", schema_version: 1, content: this.payload.content },
          },
        ],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const chatMessageUpdate: OptimisticSyncUpdate<ChatMessageModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: chatMessageUpdateValue,
    };

    return chatMessageUpdate;
  }
}
