import { addNotesToCollection } from "@/actions/addNotesToCollection";
import { EventContext } from "@/domains/metrics/context";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store";
import { type INoteObservable } from "@/store/note";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
export const createCollection = async ({
  title,
  description,
  store,
  notes,
  eventContext,
}: {
  title: string;
  description?: string;
  notes?: (INoteObservable | TemplateObservable)[];
  store: AppStore;
  eventContext: EventContext;
}) => {
  const collectionId = uuidModule.generate();

  // create the collection
  await store.collections.createCollection({
    collectionId,
    title,
    description,
    eventContext,
  });
  const newCollection = await store.collections.getAsync(collectionId);

  // sanity check and ensure newCollection is not undefined
  if (!newCollection) {
    logger.error({
      message: "[createCollection]: Failed to create collection.",
      info: {
        collectionId,
        title,
      },
    });

    return;
  }

  // make sure the new collection shows up in Recents
  await newCollection.addToRecents();

  // optionally attach notes
  if (notes) {
    await addNotesToCollection({
      notes,
      collection: newCollection,
      store,
      eventContext,
    });
  } else {
    toastModule.triggerToast({
      content: (
        <div>
          <a onClick={() => store.navigation.goToCollection({ collectionId: newCollection.id })}>
            {newCollection.label}
          </a>{" "}
          created
        </div>
      ),
    });
  }
};
