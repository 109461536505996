import { useAppStore } from "@/store";
import { useEffect, useState } from "react";

export const useLiveNoteSubscription = (noteId?: string) => {
  const { store } = useAppStore();
  const [subscribedNoteId, setSubscribedNoteId] = useState<string | undefined>();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!noteId) return;
      const subscribed = store.notes.subscribeToLiveNoteSuggestions(noteId);
      if (subscribed) {
        clearInterval(timer);
        setSubscribedNoteId(noteId);
      }
    }, 250);

    return () => {
      clearInterval(timer);
      if (subscribedNoteId) {
        store.notes.unsubscribeFromLiveNoteSuggestions(subscribedNoteId);
        setSubscribedNoteId(undefined);
      }
    };
  }, [noteId, store.notes, subscribedNoteId]);
};
