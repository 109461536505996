import { AppStore } from "@/store";
import { CollectionsFilterMode } from "@/store/note/types";

export function getSelectedCollectionTitles(
  store: AppStore,
  collectionIds: string[],
  mode?: CollectionsFilterMode,
  noCollectionIsSelected?: boolean
): string[] {
  const collectionTitles = collectionIds
    .map(id => store.collections.getCollectionObservableById({ collectionId: id }))
    .map(collection => collection?.label ?? "Unknown"); // collection.label fallbacks Untitled Collection so the only way its still unknown if collection itself is missing

  if (mode !== "all" && noCollectionIsSelected) {
    collectionTitles.unshift("Not in any collection");
  }

  return collectionTitles;
}
