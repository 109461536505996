import { memo, FC, useMemo } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsTooltipConfig, MdsTooltipPlacement } from "@/design-system/components/tooltip/types";

import { Placement } from "@floating-ui/react";
import { FloatingTooltip } from "@/design-system/components/tooltip/utils/FloatingTooltip";
import { FloatingTooltipTrigger } from "@/design-system/components/tooltip/utils/FloatingTooltipTrigger";
import { FloatingTooltipContent } from "@/design-system/components/tooltip/utils/FloatingTooltipContent";
import {
  mdsBorderRadius,
  mdsColors,
  mdsFontSizes,
  mdsShadows,
  mdsSpacings,
} from "@/design-system/foundations";
import { FloatingTooltipOptions } from "@/design-system/components/tooltip/utils/useFloatingTooltip";

export interface MdsTooltipProps extends Pick<FloatingTooltipOptions, "open"> {
  tooltipClassName?: string;
  contentClassName?: string;
  config: MdsTooltipConfig;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const placementMapping: Record<MdsTooltipPlacement, Placement> = {
  [MdsTooltipPlacement.Top]: "top",
  [MdsTooltipPlacement.TopLeft]: "top-start",
  [MdsTooltipPlacement.TopRight]: "top-end",
  [MdsTooltipPlacement.Right]: "right",
  [MdsTooltipPlacement.Bottom]: "bottom",
  [MdsTooltipPlacement.BottomLeft]: "bottom-start",
  [MdsTooltipPlacement.BottomRight]: "bottom-end",
  [MdsTooltipPlacement.Left]: "left",
};

export const MdsTooltip: FC<MdsTooltipProps> = memo(
  ({ open, config, children, tooltipClassName, contentClassName, onClick }) => {
    const combinedContentStyles = cx(baseContentStyles, contentClassName);
    const combinedTooltipContentStyles = cx(baseTooltipContentStyles, tooltipClassName);

    const targetPlacement = useMemo(() => placementMapping[config.placement], [config.placement]);

    return (
      <FloatingTooltip
        open={open}
        placement={targetPlacement}
        transitionDelay={config.delaySeconds}
      >
        <FloatingTooltipTrigger className={combinedContentStyles} onClick={onClick}>
          {children}
        </FloatingTooltipTrigger>

        <FloatingTooltipContent>
          <div className={combinedTooltipContentStyles}>{config.label || config.content}</div>
        </FloatingTooltipContent>
      </FloatingTooltip>
    );
  }
);

const baseContentStyles = css({
  width: "fit-content",
});

const baseTooltipContentStyles = css({
  background: mdsColors().grey.x700,
  color: mdsColors().white,
  fontSize: mdsFontSizes().xxsmall,
  paddingTop: mdsSpacings().xs,
  paddingBottom: mdsSpacings().xs,
  paddingLeft: mdsSpacings().xsPlus,
  paddingRight: mdsSpacings().xsPlus,
  borderRadius: mdsBorderRadius().mediumPlus,
  boxShadow: mdsShadows().tooltip,
  userSelect: "none",
});
