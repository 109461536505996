import { MdsIconKind } from "@/design-system/components/icon";
import { generateRecentDateString } from "@/domains/date/date";
import { Uuid } from "@/domains/global/identifiers";
import { MentionContent } from "@/store/chat/mention/MentionContent";
import { MentionSubtitle } from "@/store/chat/mention/MentionSubtitle";
import { MentionTitle } from "@/store/chat/mention/MentionTitle";
import { SmallerIcon } from "@/store/chat/mention/SmallerIcon";
import { smallerIconFontSizeStyles } from "@/store/chat/mention/smallerIconFontSizeStyles";
import { useAppStore } from "@/store/utils/hooks";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

export const NoteMentionContent = observer<{
  id: Uuid;
  label: string;
}>(({ id, label }) => {
  const { store } = useAppStore();
  const note = store.notes.get(id);
  return (
    <MentionContent>
      <MentionTitle>
        <span>{label}</span>
        {note?.isShared && (
          <SmallerIcon
            kind={MdsIconKind.Shared}
            innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
          />
        )}
      </MentionTitle>
      {note && (
        <MentionSubtitle>
          {generateRecentDateString(
            DateTime.fromISO(
              note.lastMentionedAt ||
                note.lastViewedAt ||
                note.receivedAt ||
                note.locallyCreatedAt ||
                ""
            ),
            { skipFullDayName: true }
          )}
        </MentionSubtitle>
      )}
    </MentionContent>
  );
});
