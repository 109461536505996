import { last } from "lodash-es";

export const toOxfordComma = (list: string[]): string => {
  if (list.length === 0) return "";
  if (list.length === 1) return list[0];
  if (list.length === 2) return `${list[0]} and ${list[1]}`;

  const lastItem = last(list);
  const allButLast = list.slice(0, -1);
  return `${allButLast.join(", ")}, and ${lastItem}`;
};
