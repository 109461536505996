import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { ThreadModelData } from "./types";
import { ThreadObservable } from "./ThreadObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreThreadStore extends BaseSyncModelStore<ThreadObservable, ThreadModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Thread, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<ThreadModelData>): ThreadObservable {
    return new ThreadObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
