import { logger } from "@/modules/logger";
import Dexie, { EntityTable } from "dexie";
import { objectModule } from "@/modules/object";
import {
  SerializedOptimisticUpdate,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { SerializedSyncOperation } from "@/store/sync/operations/types";
import { SettingsDB } from "@/domains/db/settingsDb";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import {
  UNTITLED_COLLECTION_TITLE,
  UNTITLED_NOTE_TITLE,
  UNTITLED_TEMPLATE_TITLE,
} from "@/domains/untitled/untitled";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";

export class MemDB extends Dexie {
  public readonly spaceAccountId: string;

  public readonly mappedTables: Record<
    SyncModelKind,
    {
      remote: Dexie.Table<SyncUpdateValue<SyncModelData>>;
      local: Dexie.Table<SyncUpdateValue<SyncModelData>>;
    }
  >;

  public readonly queue: {
    processing: EntityTable<
      SerializedSyncOperation,
      | "operationId"
      | "committedAt"
      | "operationKind"
      | "modelId"
      | "collectionId"
      | "noteId"
      | "templateId"
    >;
    pending: EntityTable<
      SerializedSyncOperation,
      | "operationId"
      | "committedAt"
      | "operationKind"
      | "latestSpaceAccountSequenceId"
      | "modelId"
      | "collectionId"
      | "noteId"
      | "templateId"
    >;
    optimisticUpdates: EntityTable<
      SerializedOptimisticUpdate<SyncModelData>,
      | "optimistic_update_id"
      | "sync_operation_id"
      | "model_kind"
      | "model_id"
      | "locally_committed_at"
    >;
  };

  /**
   * @TODO - After we roll out CVRs, we can remove the `syncUpdates` table.
   *
   * TODO: @MacroMackie follow up with this on Monday, Nov 11.
   */
  public readonly syncUpdates: Dexie.Table<SyncUpdate<SyncModelData> & { sync_id: string }>;
  public readonly settings: SettingsDB;
  public readonly searchSuggestions: Dexie.Table<SearchSuggestion>;

  private readonly _supportedModels: SyncModelKind[] = [
    SyncModelKind.Note,
    SyncModelKind.NoteContentDocument,
    SyncModelKind.Collection,
    SyncModelKind.CollectionItem,
    SyncModelKind.CollectionMetadata,
    SyncModelKind.Contact,
    SyncModelKind.ChatConversation,
    SyncModelKind.ChatMessage,
    SyncModelKind.FavoriteItem,
    SyncModelKind.SpaceAccountContact,
    SyncModelKind.SpaceAccountChatMessage,
    SyncModelKind.SpaceAccountCollection,
    SyncModelKind.SpaceAccountNote,
    SyncModelKind.SavedSearch,
    SyncModelKind.SpaceAccountFeatureFlagsConfig,
    SyncModelKind.SpaceAccountTopic,
    SyncModelKind.SpaceAccountTopicItem,
    SyncModelKind.DataImport,
    SyncModelKind.DataExport,
    SyncModelKind.SpaceAccountAppCallout,
    SyncModelKind.ApiKey,
    SyncModelKind.Template,
    SyncModelKind.TemplateContentDocument,
    SyncModelKind.SpaceAccountTemplate,
    SyncModelKind.Thread,
    SyncModelKind.ThreadEvent,
    SyncModelKind.NoteSource,
    SyncModelKind.Source,
    SyncModelKind.UploadedFile,
    SyncModelKind.UploadedFileBatch,
  ];

  constructor(spaceAccountId: string) {
    super(`MemDB/${spaceAccountId}`);

    this.spaceAccountId = spaceAccountId;

    this.setupVersionAndStores();
    this.addListeners();

    this.mappedTables = this.mapTables();
    this.queue = {
      processing: this.table("processing"),
      pending: this.table("pending"),
      optimisticUpdates: this.table("optimisticUpdates"),
    };
    this.syncUpdates = this.table("syncUpdates");
    this.settings = new SettingsDB(this.table("settings"));
    this.searchSuggestions = this.table("searchSuggestions");
  }

  public open() {
    logger.info({
      message: "[SYNC][MemDB] Opening",
    });

    return super.open();
  }

  public close() {
    logger.info({
      message: "[SYNC][MemDB] Closing",
    });

    return super.close();
  }

  public async erase() {
    await this.transaction(
      "rw",
      [
        ...Object.values(this.mappedTables).flatMap(table => [table.local, table.remote]),
        this.syncUpdates,
        this.searchSuggestions,
      ],
      async () => {
        await Promise.all([
          Object.values(this.mappedTables).flatMap(table => [
            table.local.clear(),
            table.remote.clear(),
          ]),
          this.syncUpdates.clear(),
          this.searchSuggestions.clear(),
        ]);
      }
    );
    await this.transaction("rw", [this.settings.table], async () => {
      await this.settings.clearNoteContentPreloadingState();
      await this.settings.clearSpaceAccountTopicItemPreloadingState();
      await this.settings.clearLastSyncId();
      await this.settings.removeCurrentChatConversationId();
    });
  }

  // TODO: maybe delay message and show a modal to user vs system window
  // https://web.dev/articles/persistent-storage#how_is_permission_granted
  // https://dexie.org/docs/StorageManager
  async persist(): Promise<boolean> {
    return await navigator.storage?.persist?.();
  }

  async estimateQuota(): Promise<StorageEstimate | undefined> {
    return await navigator.storage?.estimate?.();
  }

  async isStoragePersisted(): Promise<boolean> {
    return (await navigator.storage?.persisted?.()) ?? false;
  }

  // Define indexes for each table
  // Note that actual schema doesn't matter since indexedDb is key-value storage
  // Don't forget to update _supportedModels
  private setupVersionAndStores() {
    const defaultIndexes = "model_id";

    this.version(1).stores({
      "remote/NOTE": defaultIndexes,
      "local/NOTE": `${defaultIndexes},primary_label,modified_at,created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,

      "remote/NOTE_CONTENT_DOCUMENT": defaultIndexes,
      "local/NOTE_CONTENT_DOCUMENT": defaultIndexes,

      "remote/COLLECTION": defaultIndexes,
      "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id]`,

      "remote/COLLECTION_ITEM": defaultIndexes,
      "local/COLLECTION_ITEM": `${defaultIndexes},collection_id,item_id,[collection_id+item_id+model_id],[item_id+collection_id+model_id]`,

      "remote/COLLECTION_METADATA": defaultIndexes,
      "local/COLLECTION_METADATA": defaultIndexes,

      "remote/CONTACT": defaultIndexes,
      "local/CONTACT": `${defaultIndexes},is_direct,profile_display_name,profile_email_address,profile_display_name_and_email_address`,

      "remote/FAVORITE_ITEM": defaultIndexes,
      "local/FAVORITE_ITEM": `${defaultIndexes},sort_key,[sort_key+model_id]`,

      "remote/SPACE_ACCOUNT_NOTE": defaultIndexes,
      "local/SPACE_ACCOUNT_NOTE": defaultIndexes,

      "remote/SPACE_ACCOUNT_COLLECTION": defaultIndexes,
      "local/SPACE_ACCOUNT_COLLECTION": defaultIndexes,

      "remote/SAVED_SEARCH": defaultIndexes,
      "local/SAVED_SEARCH": defaultIndexes,

      "remote/SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG": defaultIndexes,
      "local/SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG": defaultIndexes,

      "remote/SPACE_ACCOUNT_TOPIC": defaultIndexes,
      "local/SPACE_ACCOUNT_TOPIC": defaultIndexes,

      "remote/SPACE_ACCOUNT_TOPIC_ITEM": defaultIndexes,
      "local/SPACE_ACCOUNT_TOPIC_ITEM": `${defaultIndexes},space_account_topic_id,item_id,[space_account_topic_id+item_id+model_id],[item_id+space_account_topic_id+model_id]`,

      "remote/CHAT_CONVERSATION": defaultIndexes,
      "local/CHAT_CONVERSATION": `${defaultIndexes}`,

      "remote/CHAT_MESSAGE": defaultIndexes,
      "local/CHAT_MESSAGE": `${defaultIndexes},is_system_message,locally_created_at,status,context_ids,context_kinds,[locally_created_at+model_id],[locally_created_at+is_system_message+model_id],[locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]`,

      "remote/SPACE_ACCOUNT_CHAT_MESSAGE": defaultIndexes,
      "local/SPACE_ACCOUNT_CHAT_MESSAGE": defaultIndexes,

      "remote/SPACE_ACCOUNT_CONTACT": defaultIndexes,
      "local/SPACE_ACCOUNT_CONTACT": defaultIndexes,

      "remote/DATA_IMPORT": defaultIndexes,
      "local/DATA_IMPORT": `${defaultIndexes},started_at,ended_at`,

      "remote/SPACE_ACCOUNT_APP_CALLOUT": defaultIndexes,
      "local/SPACE_ACCOUNT_APP_CALLOUT": defaultIndexes,

      "remote/TEMPLATE": defaultIndexes,
      "local/TEMPLATE": defaultIndexes,
      "remote/TEMPLATE_CONTENT_DOCUMENT": defaultIndexes,
      "local/TEMPLATE_CONTENT_DOCUMENT": defaultIndexes,
      "remote/SPACE_ACCOUNT_TEMPLATE": defaultIndexes,
      "local/SPACE_ACCOUNT_TEMPLATE": defaultIndexes,

      "remote/API_KEY": defaultIndexes,
      "local/API_KEY": defaultIndexes,

      "remote/DATA_EXPORT": defaultIndexes,
      "local/DATA_EXPORT": defaultIndexes,

      processing: "&operationId,committedAt,operationKind,modelId,collectionId,noteId",
      pending:
        "&operationId,committedAt,operationKind,latestSpaceAccountSequenceId,modelId,collectionId,noteId",
      optimisticUpdates: "&sync_id,sync_operation_id,model_kind,model_id,locally_committed_at",
      syncUpdates: "&sync_id,locally_committed_at,committed_at",
      searchSuggestions: `&modelId,sortKey,mentionKey,label,*labelWords`,
      searchFullText: `&modelId,content`,
      settings: "",
    });

    /**
     * Migrated from "sync_id" to "optimistic_update_id".
     * Because it is a primary key, we need to drop-and-recreate the table.
     * (Because these are just the optimisticUpdates, they are safe to drop, although
     * some clients may see stale data for a short period of time.)
     */
    this.version(2).stores({
      /** Deleting the table by setting it to null. */
      optimisticUpdates: null,
    });

    this.version(3).stores({
      /** Recreating the table with the new schema. */
      optimisticUpdates:
        "&optimistic_update_id,sync_operation_id,model_kind,model_id,locally_committed_at",
    });

    this.version(4).stores({
      "local/CONTACT": `${defaultIndexes},is_direct,profile_display_name,profile_email_address,profile_display_name_and_email_address,[is_direct+model_id]`,
      searchFullText: `&modelId,content,sortKey`,
    });

    this.version(5).stores({
      "local/DATA_IMPORT": `${defaultIndexes},started_at,[started_at+model_id]`,
    });

    this.version(6)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(note => {
            note.lowercase_primary_label = note.primary_label.toLowerCase();
          });
      });

    this.version(7)
      .stores({
        "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id]`,
      })
      .upgrade(tx => {
        return tx
          .table("local/COLLECTION")
          .toCollection()
          .modify(collection => {
            collection.title = collection.title || UNTITLED_COLLECTION_TITLE;
            collection.lowercase_title = collection.title.toLowerCase();
          });
      });

    this.version(8)
      .stores({
        searchSuggestions: `&modelId,sortKey,mentionKey,label,lowercaseLabel,*labelWords`,
      })
      .upgrade(async tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 8",
        });
        const suggestions = await tx.table("searchSuggestions").toArray();
        const notes = await tx.table("local/NOTE").toArray();
        const collections = await tx.table("local/COLLECTION").toArray();
        const templates = await tx.table("local/TEMPLATE").toArray();

        const noteMap = new Map(notes.map(note => [note.model_id, note]));
        const collectionMap = new Map(
          collections.map(collection => [collection.model_id, collection])
        );
        const templateMap = new Map(templates.map(template => [template.model_id, template]));

        await Promise.all(
          suggestions.map(async suggestion => {
            let label;
            if (suggestion.type === SearchSuggestionType.NOTE) {
              const note = noteMap.get(suggestion.modelId);
              label = note?.primary_label || UNTITLED_NOTE_TITLE;
            } else if (suggestion.type === SearchSuggestionType.COLLECTION) {
              const collection = collectionMap.get(suggestion.modelId);
              label = collection?.title || UNTITLED_COLLECTION_TITLE;
            } else if (suggestion.type === SearchSuggestionType.TEMPLATE) {
              const template = templateMap.get(suggestion.modelId);
              label = template?.title || UNTITLED_TEMPLATE_TITLE;
            }

            if (label) {
              await tx.table("searchSuggestions").update(suggestion.modelId, {
                label,
                lowercaseLabel: label.toLowerCase(),
              });
            }
          })
        );
      });

    // intro locally_created_at
    this.version(9)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[trashed_at+model_id]`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 9",
        });
        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(note => {
            note.locally_created_at = note.model_data.locally_created_at;
          });
      });

    // Added shared_with_me_at, [is_available+shared_with_me_at+model_id], [is_available+is_shared+shared_with_me_at+model_id] and [is_available+is_owned_by_me+shared_with_me_at+model_id]
    this.version(10)
      .stores({
        "local/COLLECTION": `${defaultIndexes},title,lowercase_title,modified_at,created_at,last_viewed_at,last_added_to_at,last_interacted_at,shared_with_me_at,is_owned_by_me,is_shared,is_available,[is_available+modified_at+model_id],[is_available+created_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_added_to_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_title+model_id],[is_available+shared_with_me_at+model_id],[is_available+is_shared+modified_at+model_id],[is_available+is_shared+created_at+model_id],[is_available+is_shared+last_viewed_at+model_id],[is_available+is_shared+lowercase_title+model_id],[is_available+is_shared+shared_with_me_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_title+model_id],[is_available+is_owned_by_me+shared_with_me_at+model_id]`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 10",
        });
        return tx
          .table("local/COLLECTION")
          .toCollection()
          .modify(async collection => {
            const spaceAccountCollectionId = resolveSpaceAccountCollectionSyncModelUuid({
              spaceAccountId: this.spaceAccountId,
              collectionId: collection.model_id,
            });
            const spaceAccountCollection = await this.table("local/SPACE_ACCOUNT_COLLECTION").get(
              spaceAccountCollectionId
            );
            collection.shared_with_me_at =
              spaceAccountCollection?.model_data.shared_at ||
              spaceAccountCollection?.model_data.shared_with_me_at ||
              "";
          });
      });

    // add [is_available+lowercase_primary_label+model_id] and [is_available+is_owned_by_me+lowercase_primary_label+model_id]
    this.version(11).stores({
      "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_primary_label+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_primary_label+model_id],[trashed_at+model_id]`,
    });

    // add shared_with_me_at, [is_available+shared_with_me_at+model_id],  [is_available+is_owned_by_me+shared_with_me_at+model_id]
    this.version(12)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,shared_with_me_at,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_primary_label+model_id],[is_available+shared_with_me_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_primary_label+model_id],[is_available+is_owned_by_me+shared_with_me_at+model_id],[trashed_at+model_id]`,
      })
      .upgrade(async tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 12",
        });
        const spaceAccountNotes = await tx.table("local/SPACE_ACCOUNT_NOTE").toArray();

        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(async note => {
            const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
              spaceAccountId: this.spaceAccountId,
              noteId: note.model_id,
            });

            const spaceAccountNote = spaceAccountNotes.find(
              note => note.model_id === spaceAccountNoteId
            );

            note.shared_with_me_at = spaceAccountNote?.model_data.shared_with_me_at || "";
          });
      });

    // add locally_created_at, [collection_id+item_id+locally_created_at+model_id], [item_id+collection_id+locally_created_at+model_id]
    this.version(13)
      .stores({
        "local/COLLECTION_ITEM": `${defaultIndexes},collection_id,item_id,locally_created_at,[collection_id+item_id+model_id],[item_id+collection_id+model_id],[item_id+collection_id+locally_created_at+model_id],[collection_id+item_id+locally_created_at+model_id]`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 13",
        });
        return tx
          .table("local/COLLECTION_ITEM")
          .toCollection()
          .modify(collectionItem => {
            collectionItem.locally_created_at = collectionItem.model_data.locally_created_at;
          });
      });

    this.version(14).stores({
      searchFullText: null,
    });

    this.version(15)
      .stores({
        "local/NOTE_CONTENT_DOCUMENT": `${defaultIndexes},note_id`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 15",
        });
        return tx
          .table("local/NOTE_CONTENT_DOCUMENT")
          .toCollection()
          .modify(noteContentDocument => {
            noteContentDocument.note_id = noteContentDocument.model_data.note_id;
          });
      });

    // add chat_conversation_id, [chat_conversation_id+locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]
    this.version(16)
      .stores({
        "local/CHAT_MESSAGE": `${defaultIndexes},is_system_message,locally_created_at,status,context_ids,context_kinds,chat_conversation_id,[locally_created_at+model_id],[locally_created_at+is_system_message+model_id],[locally_created_at+is_system_message+status+context_ids+context_kinds+model_id],[chat_conversation_id+locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 16",
        });
        return tx
          .table("local/CHAT_MESSAGE")
          .toCollection()
          .modify(chatMessage => {
            chatMessage.chat_conversation_id = chatMessage.model_data.chat_conversation_id;
          });
      });

    // Add the SpaceAccountAppCallout model
    this.version(17).stores({
      "remote/SPACE_ACCOUNT_APP_CALLOUT": defaultIndexes,
      "local/SPACE_ACCOUNT_APP_CALLOUT": defaultIndexes,
    });

    // Add mentioned_note_ids
    this.version(18)
      .stores({
        "local/NOTE": `${defaultIndexes},primary_label,lowercase_primary_label,modified_at,created_at,locally_created_at,last_viewed_at,last_interacted_at,is_owned_by_me,trashed_at,is_trashed,is_available,shared_with_me_at,[is_available+locally_created_at+model_id],[is_available+modified_at+model_id],[is_available+last_viewed_at+model_id],[is_available+last_interacted_at+model_id],[is_available+lowercase_primary_label+model_id],[is_available+shared_with_me_at+model_id],[is_available+is_owned_by_me+modified_at+model_id],[is_available+is_owned_by_me+locally_created_at+model_id],[is_available+is_owned_by_me+last_viewed_at+model_id],[is_available+is_owned_by_me+lowercase_primary_label+model_id],[is_available+is_owned_by_me+shared_with_me_at+model_id],[trashed_at+model_id],*mentioned_note_ids`,
      })
      .upgrade(async tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 18",
        });
        return tx
          .table("local/NOTE")
          .toCollection()
          .modify(async note => {
            note.mentioned_note_ids = note.model_data.mentioned_note_ids || [];
          });
      });

    // added item_id and item_kind to index
    this.version(19)
      .stores({
        "local/FAVORITE_ITEM": `${defaultIndexes},sort_key,item_id,item_kind,[sort_key+item_id+item_kind+model_id]`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 19",
        });
        return tx
          .table("local/FAVORITE_ITEM")
          .toCollection()
          .modify(favoriteItem => {
            favoriteItem.item_id = favoriteItem.model_data.item_id;
            favoriteItem.item_kind = favoriteItem.model_data.item_kind;
          });
      });

    // Add the Template / TemplateContentDocument / SpaceAccountTemplate models
    this.version(20).stores({
      processing: "&operationId,committedAt,operationKind,modelId,collectionId,noteId,templateId",
      pending:
        "&operationId,committedAt,operationKind,latestSpaceAccountSequenceId,modelId,collectionId,noteId,templateId",
      "remote/TEMPLATE": defaultIndexes,
      "local/TEMPLATE": `${defaultIndexes},title,lowercase_title,created_at,modified_at,shared_with_me_at,last_used_at,last_viewed_at,is_owned_by_me,[is_owned_by_me+model_id],[is_owned_by_me+modified_at+model_id],[modified_at+model_id],[is_owned_by_me+created_at+model_id],[created_at+model_id],[is_owned_by_me+last_viewed_at+model_id],[last_viewed_at+model_id],[is_owned_by_me+last_used_at+model_id],[last_used_at+model_id],[is_owned_by_me+shared_with_me_at+model_id],[shared_with_me_at+model_id],[is_owned_by_me+lowercase_title+model_id],[lowercase_title+model_id]`,
      "remote/TEMPLATE_CONTENT_DOCUMENT": defaultIndexes,
      "local/TEMPLATE_CONTENT_DOCUMENT": defaultIndexes,
      "remote/SPACE_ACCOUNT_TEMPLATE": defaultIndexes,
      "local/SPACE_ACCOUNT_TEMPLATE": defaultIndexes,
    });

    // Add the ApiKey model
    this.version(21).stores({
      "remote/API_KEY": defaultIndexes,
      "local/API_KEY": defaultIndexes,
    });

    // Add an index for chat conversations locally_created_at
    this.version(22)
      .stores({
        "local/CHAT_CONVERSATION": `${defaultIndexes},locally_created_at`,
      })
      .upgrade(tx => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 22",
        });
        return tx
          .table("local/CHAT_CONVERSATION")
          .toCollection()
          .modify(chatConversation => {
            chatConversation.locally_created_at = chatConversation.model_data.locally_created_at;
          });
      });

    // Add the DATA_EXPORT model
    this.version(23).stores({
      "remote/DATA_EXPORT": defaultIndexes,
      "local/DATA_EXPORT": defaultIndexes,
    });

    // Add the UploadedFileBatch and UploadedFile models
    this.version(24)
      .stores({
        "remote/UPLOADED_FILE_BATCH": defaultIndexes,
        "local/UPLOADED_FILE_BATCH": defaultIndexes,
        "remote/UPLOADED_FILE": defaultIndexes,
        "local/UPLOADED_FILE": defaultIndexes,
      })
      .upgrade(() => {
        logger.info({
          message:
            "[SYNC][MemDB] Upgrading to version 24 - Adding UploadedFileBatch and UploadedFile models",
        });
      });

    // Add Thread, ThreadEvent, and NoteSource tables
    this.version(25)
      .stores({
        "remote/THREAD": defaultIndexes,
        "local/THREAD": defaultIndexes,
        "remote/THREAD_EVENT": defaultIndexes,
        "local/THREAD_EVENT": defaultIndexes,
        "remote/NOTE_SOURCE": defaultIndexes,
        "local/NOTE_SOURCE": defaultIndexes,
      })
      .upgrade(() => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 25 - Adding thread and note source tables",
        });
      });

    // Add the Source model
    this.version(26)
      .stores({
        "remote/SOURCE": defaultIndexes,
        "local/SOURCE": defaultIndexes,
      })
      .upgrade(() => {
        logger.info({
          message: "[SYNC][MemDB] Upgrading to version 26 - Adding source table",
        });
      });
  }

  private mapTables(): Record<SyncModelKind, { remote: Dexie.Table; local: Dexie.Table }> {
    return this._supportedModels.reduce(
      (acc, kind) => {
        acc[kind] = {
          remote: this.table(`remote/${kind}`),
          local: this.table(`local/${kind}`),
        };
        return acc;
      },
      {} as Record<SyncModelKind, { remote: Dexie.Table; local: Dexie.Table }>
    );
  }

  private addListeners() {
    // Log global events
    this.on(
      "ready",
      () => {
        logger.info({
          message: "[MemDB] is ready",
        });
      },
      true // means it will fire every time the db is becomes ready
    );

    this.on("populate", () => {
      logger.info({
        message: "[MemDB] is populated",
      });
    });

    this.on("blocked", error => {
      // Its not technically an error, db will become unlocked after version change is complete on whoever is holding the lock
      // but we log it anyway to keep an eye on it
      logger.info({
        message: "[MemDB] is blocked",
        info: { error: objectModule.safeAsJson({ ...error }) },
      });
    });
  }
}
