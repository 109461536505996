import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { IndexedTemplateSyncUpdateValue, TemplateModelData } from "@/store/templates/types";
import { WithAppStore } from "@/store/types";
import { action, computed, makeObservable, observable, override, runInAction } from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { UNTITLED_TEMPLATE_TITLE } from "@/domains/untitled/untitled";
import { UpdateTemplateOperation } from "@/store/sync/operations/templates/UpdateTemplateOperation";
import { UpdateTemplateContentUsingDiffOperation } from "@/store/sync/operations/templates/UpdateTemplateContentUsingDiffOperation";
import { resolveTemplateContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveTemplateContentDocumentSyncModelUuid";
import { TemplateContentDocumentObservable } from "@/store/templates/TemplateContentDocumentObservable";
import {
  GrantableSyncScopeRoleKind,
  SyncModelPermissionEntryWithStatus,
} from "@/domains/sync-scopes/types";
import { GrantTemplateAclViaSpaceAccountOperation } from "@/store/sync/operations/templates/GrantTemplateAclViaSpaceAccountOperation";
import { GrantTemplateAclViaEmailAddressOperation } from "@/store/sync/operations/templates/GrantTemplateAclViaEmailAddressOperation";
import { RevokeTemplateAclViaSpaceAccountOperation } from "@/store/sync/operations/templates/RevokeTemplateAclViaSpaceAccountOperation";
import { TemplateCollectionListObservable } from "@/store/collection-items/TemplateCollectionListObservable";
import { RemoveTemplateFromCollectionOperation } from "@/store/sync/operations/templates/RemoveTemplateFromCollectionOperation";
import { AddTemplateToCollectionOperation } from "@/store/sync/operations/templates/AddTemplateToCollectionOperation";
import { DeleteTemplateOperation } from "@/store/sync/operations/templates/DeleteTemplateOperation";
import { SpaceAccountTemplateObservable } from "@/store/templates/SpaceAccountTemplateObservable";
import { resolveSpaceAccountTemplateSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountTemplateSyncModelUuid";
import { uniq } from "lodash-es";
import { filter } from "lodash-es";
import { IContactModel } from "@/store/contacts/types";
import { getPermissionsForTemplateSyncModel } from "@/store/sync/operations/helpers/permissions/getPermissionsForModel";
import { MarkTemplateViewedOperation } from "@/store/sync/operations/templates/MarkTemplateViewedOperation";
import { getQueuedTemplateDocumentUpdates } from "@/store/sync/operations/helpers/templates/getQueuedTemplateDocumentUpdates";

export class TemplateObservable extends BaseSyncModel<TemplateModelData> {
  public modelKind = SyncModelKind.Template;
  public collectionList: TemplateCollectionListObservable;

  // TODO
  isTrashed = false;
  generateCommonEditorContext = () => undefined;
  uploadFileAssociatedWithNote = () => {};
  uploadImageAssociatedWithNote = () => {};

  private isLocked: boolean = false;
  title: string;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<TemplateModelData> } & WithAppStore) {
    super({ id, data, store });

    this.title = data.model_data.title;
    this.collectionList = new TemplateCollectionListObservable({ templateId: id, store });

    makeObservable<TemplateObservable, "isLocked">(this, {
      getTemplateContentsForInsertionAsync: true,
      getRemoteContentAsync: true,
      modifiedAt: true,
      spaceAccountTemplate: true,
      isTrashed: false,

      delete: false,
      generateCommonEditorContext: false,
      uploadFileAssociatedWithNote: false,
      uploadImageAssociatedWithNote: false,

      collectionList: observable,
      modelKind: observable,
      updateFromLocal: override,
      permissions: override,

      title: observable,
      isLocked: observable,
      lock: action,
      unlock: action,
      setTitle: action,
      save: action,

      isAutoSharingEnabled: computed,
      memoryQueue: computed,
      templateContentDocumentId: computed,
      templateContentDocument: computed,
      queuedDocumentUpdates: computed,
      remoteContent: computed,

      label: computed,
      secondaryLabel: computed,
      locallyCreatedAt: computed,
      authors: computed,

      // ACTIONS
      updateContentUsingDiff: action,
      update: action,
      grantAccessViaSpaceAccount: action,
      grantAccessViaEmailAddress: action,
      revokeAccessViaSpaceAccount: action,
      addToCollection: action,
      removeFromCollection: action,
      markAsViewed: action,
      updateFromData: action,
    });
  }

  async updateFromLocal() {
    const data = await this.store.templates.localTable.get(this.id);
    if (data) runInAction(() => this.updateFromData(data));
  }

  updateFromData(data: IndexedTemplateSyncUpdateValue) {
    this.data = data;
    if (!this.isLocked) {
      this.title = data.model_data.title;
    }
  }

  get permissions(): SyncModelPermissionEntryWithStatus[] {
    return getPermissionsForTemplateSyncModel({
      id: this.id,
      remoteData: this.remoteData,
      store: this.store,
      actionQueue: this.store.sync.actionQueue,
    });
  }

  // EDITABLE PROPERTIES
  public lock() {
    this.isLocked = true;
  }

  public unlock() {
    this.isLocked = false;
  }

  setTitle(title: string) {
    this.lock();
    this.title = title;
  }

  public async save() {
    if (this.title !== this.modelData.title)
      await new UpdateTemplateOperation({
        store: this.store,
        payload: {
          id: this.id,
          title: this.title,
        },
      }).execute();

    this.unlock();
  }

  /**
   * PROPERTIES
   */
  get isAutoSharingEnabled(): boolean {
    return this.modelData.is_auto_sharing_enabled;
  }

  get memoryQueue() {
    return this.store.templates.getQueue({ id: this.id });
  }

  get templateContentDocumentId(): Uuid {
    return resolveTemplateContentDocumentSyncModelUuid({ templateId: this.id });
  }

  get templateContentDocument(): TemplateContentDocumentObservable | undefined {
    return this.store.templateContentDocuments.get(this.templateContentDocumentId);
  }

  get remoteContent(): string | null {
    return this.templateContentDocument?.data?.model_data.encoded_content || null;
  }

  async getRemoteContentAsync(): Promise<string | undefined> {
    if (this.remoteContent) return this.remoteContent;

    const doc = await this.store.templateContentDocuments.getAsync(this.templateContentDocumentId);
    return doc?.data?.model_data.encoded_content || undefined;
  }

  async getTemplateContentsForInsertionAsync(): Promise<
    | {
        remoteContent?: string;
        documentUpdates: string[];
      }
    | undefined
  > {
    const remoteContent = await this.getRemoteContentAsync();
    const documentUpdates = this.queuedDocumentUpdates
      .map(update => update.encodedContentDiff)
      .filter(s => s.length > 0);

    if (!remoteContent && !documentUpdates.length) return;

    return {
      remoteContent,
      documentUpdates,
    };
  }

  get queuedDocumentUpdates() {
    return getQueuedTemplateDocumentUpdates({
      operationsByModelId: this.store.sync.actionQueue.operationsByModelId,
      id: this.id,
    });
  }

  get label(): string {
    return this.title || UNTITLED_TEMPLATE_TITLE;
  }

  get secondaryLabel(): string {
    return this.modelData.primary_label;
  }

  get locallyCreatedAt(): string {
    return this.modelData.locally_created_at;
  }

  get modifiedAt(): string {
    return this.modelData.locally_modified_at;
  }

  get spaceAccountTemplate(): SpaceAccountTemplateObservable | undefined {
    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      templateId: this.id,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });
    return this.store.spaceAccountTemplates.get(spaceAccountTemplateId);
  }

  get authors(): IContactModel[] {
    const spaceAccountIds = uniq([
      this.data.model_data.owned_by_space_account_id,
      ...this.data.model_data.modified_by_space_account_ids,
      ...this.remoteData.model_data.modified_by_space_account_ids,
    ]);
    const getContactObservableByContactSpaceAccountId = (spaceAccountId: string) => {
      if (spaceAccountId === this.store.spaceAccounts.myPersonalSpaceAccountId) {
        return this.store.spaceAccounts.myPersonalSpaceAccount;
      }
      return this.store.contacts.getBySpaceAccountId(spaceAccountId);
    };
    const contacts = spaceAccountIds.map(getContactObservableByContactSpaceAccountId);
    return filter(contacts) as IContactModel[];
  }

  /**
   * ACTIONS
   */
  public async update({ isAutoSharingEnabled }: { isAutoSharingEnabled: boolean }) {
    await new UpdateTemplateOperation({
      store: this.store,
      payload: { id: this.id, is_auto_sharing_enabled: isAutoSharingEnabled },
    }).execute();
  }

  public async updateContentUsingDiff({
    encodedContentDiff,
    primaryLabel,
  }: {
    encodedContentDiff: string | null;
    primaryLabel: string;
  }) {
    if (!encodedContentDiff) return; // Ignore metadata changes for templates.

    new UpdateTemplateContentUsingDiffOperation({
      store: this.store,
      payload: {
        id: this.id,
        encoded_content_diff: encodedContentDiff,
      },
      primaryLabel,
    }).execute();
  }

  public async delete() {
    new DeleteTemplateOperation({
      store: this.store,
      payload: {
        id: this.id,
      },
    }).execute();
  }

  public async grantAccessViaSpaceAccount({
    roleKind,
    targetSpaceAccountId,
  }: {
    roleKind: GrantableSyncScopeRoleKind;
    targetSpaceAccountId: string;
  }) {
    // TODO: do we want to make it go through the queue or keep it almost automatic?
    await new GrantTemplateAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
        role_kind: roleKind,
      },
    }).execute();
  }

  public async grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    // TODO: do we want to make it go through the queue or keep it almost automatic?
    await new GrantTemplateAclViaEmailAddressOperation({
      store: this.store,
      payload: {
        id: this.id,
        role_kind: roleKind,
        email_address: targetEmailAddress,
      },
    }).execute();
  }

  public async addToCollection({
    targetCollectionId,
    roleKind = "EDITOR",
  }: {
    targetCollectionId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }) {
    // TODO: do we want to make it go through the queue or keep it almost automatic?
    await new AddTemplateToCollectionOperation({
      store: this.store,
      payload: {
        role_kind: roleKind,
        template_id: this.id,
        collection_id: targetCollectionId,
      },
    }).execute();
  }

  public async revokeAccessViaSpaceAccount({
    targetSpaceAccountId,
  }: {
    targetSpaceAccountId: string;
  }) {
    // TODO: do we want to make it go through the queue or keep it almost automatic?
    await new RevokeTemplateAclViaSpaceAccountOperation({
      store: this.store,
      payload: {
        id: this.id,
        space_account_id: targetSpaceAccountId,
      },
    }).execute();
  }

  public async removeFromCollection({ targetCollectionId }: { targetCollectionId: string }) {
    // TODO: do we want to make it go through the queue or keep it almost automatic?
    await new RemoveTemplateFromCollectionOperation({
      store: this.store,
      payload: {
        template_id: this.id,
        collection_id: targetCollectionId,
      },
    }).execute();
  }

  public async markAsViewed() {
    await new MarkTemplateViewedOperation({
      store: this.store,
      payload: { template_id: this.id },
    }).execute();
  }
}
