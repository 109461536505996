import { observer } from "mobx-react-lite";
import { MdsItemListAsyncNoteRowData } from "@/design-system/components/item-list/types";
import { FC } from "react";
import { useAppStore } from "@/store";
import { MdsItemListItemRow } from "@/design-system/components/item-list/rows/MdsItemListItemRow";

export interface MdsItemListAsyncNoteRowProps {
  data: MdsItemListAsyncNoteRowData;
  className?: string;
}

export const MdsItemListAsyncNoteRow: FC<MdsItemListAsyncNoteRowProps> = observer(
  ({ data, className }) => {
    const { store } = useAppStore();
    const note = store.notes.get(data.payload.noteId);

    if (!note) return null;

    const itemRowData =
      typeof data.payload.itemRow === "function"
        ? data.payload.itemRow(note)
        : data.payload.itemRow;

    return <MdsItemListItemRow data={itemRowData} className={className} />;
  }
);
