import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsAccountMigrationDebugSection } from "@/pages/settings/components/SettingsAccountMigrationDebugSection";
import { SettingsAccountMigrationImporterSection } from "@/pages/settings/components/SettingsAccountMigrationImporterSection";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { SettingsAccountMigrationV2Section } from "@/pages/settings/components/migration/migration-section";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";

export const SettingsAccountMigrationPage: FC = observer(() => {
  const { store } = useAppStore();
  const isMigrationInProgress = store.dataImports.isMigrationStarted;

  if (isMigrationInProgress) {
    return (
      <SettingsSectionWrapper>
        <SettingsSubsectionWrapper title="Migration from Mem 1.0 to 2.0">
          <div className={importWrapperStyle}>
            <SettingsAccountMigrationV2Section />
          </div>
        </SettingsSubsectionWrapper>
      </SettingsSectionWrapper>
    );
  }

  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Import from Mem 1.0">
        <div className={importWrapperStyle}>
          <SettingsAccountMigrationImporterSection />
          <SettingsAccountMigrationDebugSection />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

export const importWrapperStyle = css({
  fontSize: 14,
  lineHeight: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: mdsColors().grey.x700,
});
