import {
  makeObservable,
  observable,
  computed,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { UploadedFileModelData } from "./types";
import { UploadedFileObservable } from "./UploadedFileObservable";
import { SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription } from "dexie";
import { searchUploadedFiles } from "@/store/uploaded-files/search-uploaded-files";
import { uuidModule } from "@/modules/uuid";
import { CreateUploadedFileOperation } from "@/store/sync/operations/uploaded-file/CreateUploadedFileOperation";

interface FileDetails {
  fileMimeType: string;
  normalizedFileName: string;
  fileSizeBytes: number;
  uploadedFileBlobName: string;
}

export class AppStoreUploadedFileStore extends BaseSyncModelStore<
  UploadedFileObservable,
  UploadedFileModelData
> {
  liveQuerySubscription: Maybe<Subscription>;
  uploadedFiles: UploadedFileObservable[] = [];
  private initialLoaded: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.UploadedFile, ...injectedDeps });
    makeObservable<
      this,
      "subscribeLiveQuery" | "unsubscribeLiveQuery" | "setIsReady" | "initialLoaded"
    >(this, {
      searchUploadedFiles: true,
      createSyncModel: false,

      uploadedFiles: observable,
      liveQuerySubscription: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      initialLoaded: observable,
      setIsReady: action,
      isReady: computed,
      createUploadedFile: action,
      createUploadedFiles: action,
    });

    onBecomeObserved(this, "uploadedFiles", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "uploadedFiles", () => this.unsubscribeLiveQuery());
    onBecomeObserved(this, "isReady", () => this.subscribeLiveQuery());
  }

  get isReady() {
    return this.initialLoaded;
  }

  createSyncModel(data: SyncUpdateValue<UploadedFileModelData>): UploadedFileObservable {
    return new UploadedFileObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public searchUploadedFiles = async (batchId?: string): Promise<UploadedFileObservable[]> => {
    const ids = await Dexie.waitFor(searchUploadedFiles({ store: this.store, batchId }));
    return this.getMany(ids);
  };

  /**
   * Creates a new uploaded file.
   *
   * @param batchId The ID of the batch this file belongs to
   * @param file The file details containing normalizedFileName, fileMimeType, fileSizeBytes, and uploadedFileBlobName
   * @returns The ID of the newly created file
   */
  public async createUploadedFile({
    batchId,
    file,
  }: {
    batchId: string;
    file: FileDetails;
  }): Promise<string> {
    const id = uuidModule.generate();
    const { fileMimeType, normalizedFileName, fileSizeBytes, uploadedFileBlobName } = file;

    await new CreateUploadedFileOperation({
      store: this.store,
      payload: {
        id,
        mime_type: fileMimeType,
        uploaded_file_batch_id: batchId,
        normalized_filename: normalizedFileName,
        file_size_bytes: fileSizeBytes,
        uploaded_file_blob_name: uploadedFileBlobName,
      },
    }).execute();

    return id;
  }

  /**
   * Creates multiple uploaded files at once.
   *
   * @param batchId The ID of the batch these files belong to
   * @param files Array of file details containing normalizedFileName, fileMimeType, fileSizeBytes, and uploadedFileBlobName
   * @returns Array of IDs of the newly created files
   */
  public async createUploadedFiles({
    batchId,
    files,
  }: {
    batchId: string;
    files: FileDetails[];
  }): Promise<string[]> {
    return Promise.all(files.map(file => this.createUploadedFile({ batchId, file })));
  }

  private subscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.searchUploadedFiles();
    }).subscribe({
      next: items => {
        runInAction(() => {
          this.uploadedFiles = items;
          this.setIsReady(true);
        });
      },
    });
  }

  private unsubscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
  }

  private setIsReady(value: boolean) {
    this.initialLoaded = value;
  }
}
