import { FC } from "react";
import styled from "@emotion/styled";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";

export interface V2TransitionCardButtonProps extends EmotionClassStyles {
  isActive: boolean;
  primaryLabel: string;
  secondaryLabel: string;
  imageSrc: string;
  onClick: () => void;
}

export const V2TransitionCardButton: FC<V2TransitionCardButtonProps> = ({
  isActive,
  imageSrc,
  primaryLabel,
  secondaryLabel,
  onClick,
}) => {
  return (
    <Wrapper isActive={isActive} onClick={onClick}>
      <Image src={imageSrc} />
      <MdsText weight={MdsTextWeight.SemiBold} size={MdsTextSize.Medium}>
        {primaryLabel}
      </MdsText>
      <MdsText weight={MdsTextWeight.Regular} size={MdsTextSize.Small}>
        {secondaryLabel}
      </MdsText>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  height: "180px",
  width: "100%",
  minWidth: "248px",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  borderStyle: "solid",
  borderWidth: isActive ? 2 : 1,
  borderColor: isActive ? mdsColors().grey.x600 : mdsColors().grey.x100,
  boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.05)`,
  cursor: "pointer",
  paddingLeft: isActive ? 0 : 1,
  paddingRight: isActive ? 0 : 1,
  transition: "border-color 0.1s ease-in-out",
  userSelect: "none",
  "&:hover": {
    borderColor: isActive ? mdsColors().grey.x600 : mdsColors().grey.x300,
  },
}));

const Image = styled.img({
  boxSizing: "content-box",
  padding: 16,
  width: "80px",
  height: "80px",
});
