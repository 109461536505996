import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsTextOverflow } from "@/design-system/components/text/types";

interface CollectionChipProps {
  inline?: boolean;
  id?: string;
  label?: string;
}

export const CollectionChip: FC<CollectionChipProps> = ({ inline, id, label }) => {
  return (
    <div className={cx(chipStyles, inline && inlineStyles)}>
      {!inline && <CollectionIcon size={24} collectionId={id} />}
      {inline && (
        <>
          <MdsIcon
            kind={MdsIconKind.Collection}
            innerStyles={{ Icon: { className: inlineChipIconStyles } }}
            className={inlineStyles}
          />{" "}
        </>
      )}
      <MdsText
        className={inline ? inlineStyles : undefined}
        size={MdsTextSize.Large}
        weight={MdsTextWeight.Bold}
        overflow={MdsTextOverflow.Ellipsis}
      >
        {label}
      </MdsText>
    </div>
  );
};

const chipStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
  background: mdsColors().grey.x25,
  padding: "4px 6px",
  borderRadius: 8,
  overflow: "hidden",
});

const inlineChipIconStyles = css({
  fontSize: 16,
});

const inlineStyles = css({
  display: "inline",
});
