import { MdsTextProps } from "@/design-system/components/text/MdsText";
import { MdsText } from "@/design-system/components/text";
import { api } from "@/modules/api";
import styled from "@emotion/styled";
import { FC, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logger } from "@/modules/logger";
import pRetry from "p-retry";
import memLogomarkImage from "@/assets/images/mem-logomark.svg";
import { motion } from "framer-motion";
import { css, keyframes } from "@/domains/emotion";

export const V1MigrationPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnameRef = useRef(location.pathname);
  pathnameRef.current = location.pathname;

  useEffect(() => {
    pRetry(async () => {
      const response = await api.get(`/v2/redirect/map/{path}`, {
        params: {
          path: {
            path: location.pathname.replace(/^\//, ""),
          },
        },
      });
      if (location.pathname !== pathnameRef.current) return;

      const pathname = response.data;
      if (typeof pathname !== "string") {
        throw new Error(`Invalid pathname: ${pathname}`);
      }

      if (pathname === location.pathname) {
        logger.warn({ message: `[redirect] No redirect found for ${location.pathname}` });
        navigate(`/redirect-404-not-found${location.pathname}`);
        return;
      }

      logger.info({ message: `[redirect] Redirecting from ${location.pathname} to ${pathname}` });
      navigate(pathname.replace(/^\/?/, "/"));
    });
  }, [location.pathname, navigate]);

  return (
    <FullyCentered>
      <Box>
        <motion.img
          src={memLogomarkImage}
          className={loaderStyles}
          animate={{ scale: [1, 1.05, 1] }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
        <Text>Finding the updated path for your content migrated from Mem 1.0...</Text>
      </Box>
    </FullyCentered>
  );
};

const FullyCentered = styled.div({
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
});

const Box = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "center",
  width: "260px",
});

const pulseAnimation = keyframes({
  from: {
    transform: "scale3d(1, 1, 1)",
  },
  "50%": {
    transform: "scale3d(1.05, 1.05, 1.05)",
  },
  to: {
    transform: "scale3d(1, 1, 1)",
  },
});

const loaderStyles = css({
  width: 48,
  height: 48,
  animation: `1s ${pulseAnimation} infinite`,
});

const Text = styled(MdsText)<MdsTextProps>(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.smedium,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.smedium,
}));
