import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CollectionsViewPageHeader } from "@/pages/collections-view/components/CollectionsViewPageHeader";
import { CollectionsViewPageList } from "@/pages/collections-view/components/CollectionsViewPageList";
import { DeleteCollectionsModal } from "@/components/modal/delete-collections/DeleteCollectionsModal";
import { DeleteSharedNotesModal } from "@/components/modal/delete-shared-notes/DeleteSharedNotesModal";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { useAppStore } from "@/store";
import { RemoveNotesFromSharedCollectionModal } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModal";
import { MakeCollectionPrivateModal } from "@/components/modal/make-collection-private/MakeCollectionPrivateModal";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { ChatMessageContextKind } from "@/store/chat/types";
import { Appear } from "@/components/appear";
import styled from "@emotion/styled";

interface CollectionsViewPageProps {
  isSidePanel?: boolean;
}

export const CollectionsViewPage: FC<CollectionsViewPageProps> = observer(
  ({ isSidePanel = false }) => {
    const { store, pageStore: appPageStore } = useAppStore();
    const pageViewStore = isSidePanel
      ? store.sidePanel.collectionsViewPage
      : appPageStore.collectionsViewPage;
    pageViewStore.useEffects();

    /**
     * Track views of the collection.
     */
    useEffect(() => {
      if (!pageViewStore.collection) return;
      trackEvent(TrackedEvent.CollectionView, {
        collection_id: pageViewStore.collection.id,
        collection_primary_label: pageViewStore.collection.label,
        is_in_side_panel: isSidePanel,
      });
    }, [pageViewStore.collection, isSidePanel]);

    /**
     * Add collection navigation context to chat conversation when the collections view page is mounted
     */
    useEffect(() => {
      const collectionId = pageViewStore.collection?.id;
      if (!collectionId) return;

      store.chatConversations.addNavContext({
        id: collectionId,
        kind: ChatMessageContextKind.CollectionMention,
      });

      return () => {
        store.chatConversations.removeNavContext(collectionId);
      };
    }, [store.chatConversations, pageViewStore.collection?.id]);

    return (
      <StyledAppear visible={pageViewStore.isSubscriptionInitialized}>
        <MakeCollectionPrivateModal store={pageViewStore.makeCollectionPrivateModal} />
        <ShareSheetModal store={pageViewStore.shareSheetModal} />
        <DeleteCollectionsModal state={pageViewStore.deleteCollectionsModal} />
        <DeleteSharedNotesModal store={pageViewStore.deleteSharedNotesModal} />
        <RemoveNotesFromSharedCollectionModal
          store={pageViewStore.removeNotesFromSharedCollectionModal}
        />
        <CollectionsViewPageHeader isSidePanel={isSidePanel} />
        <MdsPanelContainer>
          <CollectionsViewPageList isSidePanel={isSidePanel} />
        </MdsPanelContainer>
      </StyledAppear>
    );
  }
);

const StyledAppear = styled(Appear)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflow: "hidden",
});
