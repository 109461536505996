import styled from "@emotion/styled";

export const CopilotSeeMoreButton = styled.button(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  marginInline: theme.spacing.sm,
  marginTop: theme.spacing.sm,
  padding: 0,
  "&:hover": {
    textDecoration: `underline`,
  },
}));
