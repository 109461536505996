import { CustomEventMap, CustomEventName } from "./custom-event.types";

export function dispatchCustomEvent<K extends CustomEventName>(
  eventName: K,
  detail: CustomEventMap[K],
  target: EventTarget = document
): void {
  const event = new CustomEvent<CustomEventMap[K]>(eventName, {
    detail,
    bubbles: true,
    cancelable: true,
  });
  target.dispatchEvent(event);
}
