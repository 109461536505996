import { OutboundSyncStatus } from "@/components/sync/types";
import { useEffect, useState } from "react";
import { useTimeout } from "usehooks-ts";

export const useShowSpinner = (status: OutboundSyncStatus) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useTimeout(
    () => {
      setShowSpinner(status === OutboundSyncStatus.Syncing);
    },
    status === OutboundSyncStatus.Syncing ? 1000 : null
  );

  useEffect(() => {
    if (status !== OutboundSyncStatus.Syncing) {
      setShowSpinner(false);
    }
  }, [status]);

  return showSpinner;
};
