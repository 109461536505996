import { FC } from "react";
import { observer } from "mobx-react-lite";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { TemplatesListPageHeader } from "@/pages/templates-list/components/TemplatesListPageHeader";
import { TemplatesListPageList } from "@/pages/templates-list/components/TemplatesListPageList";
import { useAppStore } from "@/store";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { DeleteTemplateModal } from "@/components/modal/delete-template/DeleteTemplateModal";

export const TemplatesListPage: FC = observer(function TemplatesListPage() {
  const { pageStore } = useAppStore();
  const page = pageStore.templatesListPage;

  return (
    <>
      <ShareSheetModal store={page.shareSheetModal} />
      <DeleteTemplateModal store={page.deleteTemplateModal} />
      <TemplatesListPageHeader />
      <MdsPanelContainer>
        <TemplatesListPageList />
      </MdsPanelContainer>
    </>
  );
});
