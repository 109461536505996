import { CollectionsListPageStore } from "@/store/pages/CollectionsListPageStore/CollectionsListPageStore";
import { CollectionsViewPageStore } from "@/store/pages/CollectionsViewPageStore/CollectionsViewPageStore";
import { NotesListPageStore } from "@/store/pages/NotesListPageStore";
import { TrashPageStore } from "@/store/pages/TrashPageStore";
import { PageStoreConstructorArgs } from "@/store/types";
import { AppStore, PublicAppStore } from "@/store";
import { makeAutoObservable } from "mobx";
import { SearchPageStore } from "@/store/pages/SearchPageStore/SearchPageStore";
import { QuickSearchModalStore } from "@/store/pages/QuickSearchModalStore/QuickSearchModalStore";
import { SettingsPageStore } from "@/store/pages/SettingsPageStore";
import { SettingsEmailPreferencesPageStore } from "@/store/pages/SettingsEmailPreferencesPageStore/SettingsEmailPreferencesPageStore";
import { SettingsAccountManagementPageStore } from "@/store/pages/SettingsAccountManagementPageStore/SettingsAccountManagementPageStore";
import { NotesViewPageStore } from "@/store/pages/NotesViewPageStore/NotesViewPageStore";
import { SettingsSubscriptionManagementPageStore } from "@/store/pages/SettingsSubscriptionManagementPageStore";
import { CopilotViewStore } from "@/store/pages/CopilotViewStore";
import { TemplatesListPageStore } from "@/store/pages/TemplatesListPageStore/TemplatesListPageStore";
import { TemplatesViewPageStore } from "@/store/pages/TemplatesViewPageStore/TemplatesViewPageStore";
import { AppCalloutModal } from "@/store/modals/AppCalloutModal";
import { ThreadsListPageStore } from "@/store/pages/ThreadsListPageStore/ThreadsListPageStore";
import { ThreadsViewPageStore } from "@/store/pages/ThreadsViewPageStore/ThreadsViewPageStore";

export class PageStore {
  collectionsListPage: CollectionsListPageStore;
  collectionsViewPage: CollectionsViewPageStore;
  notesListPage: NotesListPageStore;
  notesViewPage: NotesViewPageStore;
  templatesViewPage: TemplatesViewPageStore;
  settingsPage: SettingsPageStore;
  settingsAccountManagementPage: SettingsAccountManagementPageStore;
  settingsEmailPreferencesPage: SettingsEmailPreferencesPageStore;
  settingsSubscriptionManagementPage: SettingsSubscriptionManagementPageStore;
  templatesListPage: TemplatesListPageStore;
  trashPage: TrashPageStore;
  searchPage: SearchPageStore;
  threadsListPage: ThreadsListPageStore;
  threadsViewPage: ThreadsViewPageStore;

  quickSearchModal: QuickSearchModalStore;
  copilotView: CopilotViewStore;

  publicAppStore: PublicAppStore;
  store: AppStore;
  migrationStartedModal: AppCalloutModal;

  constructor({ publicAppStore, store, api, pusher }: PageStoreConstructorArgs) {
    const injectedDeps = { store, api, pusher };

    this.publicAppStore = publicAppStore;
    this.store = store;

    this.collectionsListPage = new CollectionsListPageStore(injectedDeps);
    this.collectionsViewPage = new CollectionsViewPageStore(injectedDeps);
    this.notesListPage = new NotesListPageStore(injectedDeps);
    this.notesViewPage = new NotesViewPageStore(injectedDeps);
    this.templatesViewPage = new TemplatesViewPageStore(injectedDeps);
    this.settingsPage = new SettingsPageStore(injectedDeps);
    this.settingsAccountManagementPage = new SettingsAccountManagementPageStore(injectedDeps);
    this.settingsEmailPreferencesPage = new SettingsEmailPreferencesPageStore(injectedDeps);
    this.settingsSubscriptionManagementPage = new SettingsSubscriptionManagementPageStore(
      injectedDeps
    );
    this.templatesListPage = new TemplatesListPageStore(injectedDeps);
    this.templatesViewPage = new TemplatesViewPageStore(injectedDeps);
    this.trashPage = new TrashPageStore(injectedDeps);
    this.searchPage = new SearchPageStore(injectedDeps);
    this.threadsListPage = new ThreadsListPageStore(injectedDeps);
    this.threadsViewPage = new ThreadsViewPageStore(injectedDeps);

    this.quickSearchModal = new QuickSearchModalStore(injectedDeps);
    this.copilotView = new CopilotViewStore(injectedDeps);
    this.migrationStartedModal = new AppCalloutModal(injectedDeps);

    makeAutoObservable(this);
  }

  useInitializePageStoreEffects = () => {
    this.notesListPage.initialize();
    this.collectionsListPage.initialize();
    this.collectionsViewPage.initialize();
    this.store.templates.initialize();
  };
}
