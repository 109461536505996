import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { SyncModelKind } from "@/store/sync/types";

export const resolveTemplateContentDocumentSyncModelUuid = ({
  templateId,
}: {
  templateId: Uuid;
}): Uuid => {
  return resolveSyncModelNamespacedUuid({
    modelKind: { value: SyncModelKind.TemplateContentDocument },
    uuids: [templateId],
  });
};
