import { MentionChip } from "@/pages/chat/ChatInput";
import { MemCommonMentionKind } from "@mem-labs/common-editor";
import {
  ChatMessageContextKind,
  NoteMentionContext,
  CollectionMentionContext,
  TimeRangeMentionContext,
  MentionContext,
  ChatMessageContext,
  SearchQueryMentionContext,
} from "@/store/chat/types";
import { SubmitChatMessageSyncOperationChatMessageContextValue } from "@/store/sync/operations/types";

const GROUP_SIZE = 3;

export function makeVisibleChips(availableChips: MentionChip[]) {
  const groupCount = new Set(availableChips.map(chip => chip.groupTitle)).size;

  if (groupCount < 2) return availableChips;

  const groupedChips = availableChips.reduce(
    (acc, chip) => {
      const group = chip.groupTitle || "";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(chip);
      return acc;
    },
    {} as Record<string, MentionChip[]>
  );

  return Object.entries(groupedChips).flatMap(([, group]) => {
    // move featuredItems into place
    const featuredItems = group.filter(item => typeof item.featuredIndex === "number");
    for (const item of featuredItems) {
      const currentIndex = group.indexOf(item);
      const targetIndex = item.featuredIndex!;
      if (currentIndex !== targetIndex && targetIndex < group.length) {
        group.splice(currentIndex, 1);
        group.splice(targetIndex, 0, item);
      }
    }

    return group.slice(0, GROUP_SIZE);
  });
}

export function mentionChipToContext(mention: MentionChip): MentionContext | null {
  switch (mention.kind) {
    case MemCommonMentionKind.Note:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.NoteMention,
      } as NoteMentionContext;
    case MemCommonMentionKind.Collection:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.CollectionMention,
      } as CollectionMentionContext;
    case MemCommonMentionKind.TimeRange: {
      const [start, end] = mention.metadata?.split("-").map(Number) ?? [];
      if (!start || !end) return null;

      return {
        id: mention.id,
        kind: ChatMessageContextKind.TimeRangeMention,
        start: new Date(start * 1000),
        end: new Date(end * 1000),
        label: mention.label,
      } as TimeRangeMentionContext;
    }
    case MemCommonMentionKind.SearchQuery:
      return {
        id: mention.id,
        kind: ChatMessageContextKind.SearchQueryMention,
        query_string: mention.metadata,
      } as SearchQueryMentionContext;
    default:
      return null;
  }
}

export function chatMentionContextToOperationContext(
  context: ChatMessageContext
): SubmitChatMessageSyncOperationChatMessageContextValue {
  switch (context.kind) {
    case ChatMessageContextKind.NoteMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          note_id: context.id,
        },
      };
    case ChatMessageContextKind.CollectionMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          collection_id: context.id,
        },
      };
    case ChatMessageContextKind.TimeRangeMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          time_start: context.start.valueOf() / 1000,
          time_end: context.end.valueOf() / 1000,
        },
      };
    case ChatMessageContextKind.SearchQueryMention:
      return {
        context_id: context.id,
        kind: context.kind,
        value: {
          schema_version: 1,
          query_string: context.query_string,
        },
      };
    default:
      return {
        context_id: context.id,
        kind: "GLOBAL",
        value: {
          schema_version: 1,
          note_id: context.id,
        },
      };
  }
}

export function removeMentionFromDraft(mentionId: string, draftMessage: string): string {
  const messageDom = new DOMParser().parseFromString(draftMessage, "text/html");
  const mentionElements = messageDom.querySelectorAll(`[data-mention-id="${mentionId}"]`);

  mentionElements.forEach(element => {
    // Check if the element has spaces on both sides
    const previousSibling = element.previousSibling;
    const nextSibling = element.nextSibling;

    const hasPrecedingSpace =
      previousSibling &&
      previousSibling.nodeType === Node.TEXT_NODE &&
      previousSibling.textContent?.endsWith(" ");

    const hasFollowingSpace =
      nextSibling &&
      nextSibling.nodeType === Node.TEXT_NODE &&
      nextSibling.textContent?.startsWith(" ");

    // Remove the element
    element.remove();

    // If there were spaces on both sides, remove one space
    if (
      hasPrecedingSpace &&
      hasFollowingSpace &&
      previousSibling &&
      nextSibling &&
      previousSibling.nodeType === Node.TEXT_NODE &&
      nextSibling.nodeType === Node.TEXT_NODE
    ) {
      // Remove the space from either the previous or next sibling
      const previousText = previousSibling as Text;
      if (previousText.textContent && previousText.textContent.endsWith(" ")) {
        previousText.textContent = previousText.textContent.slice(0, -1);
      }
    }
  });

  const newMessage = messageDom.body.innerHTML;
  return newMessage;
}

/**
 * Determines which mentions were removed between two HTML strings
 * @param oldHtml The previous HTML content
 * @param newHtml The new HTML content
 * @returns Array of mention IDs that were present in oldHtml but not in newHtml
 */
export function findRemovedMentionIds(oldHtml: string, newHtml: string): string[] {
  if (!oldHtml || !newHtml) return [];

  // Create DOM parsers for both the old and new content
  const oldDom = new DOMParser().parseFromString(oldHtml, "text/html");
  const newDom = new DOMParser().parseFromString(newHtml, "text/html");

  // Get all mention elements from the old content
  const oldMentionElements = oldDom.querySelectorAll("[data-mention-id]");
  const removedMentionIds: string[] = [];

  // Check each old mention to see if it still exists in the new content
  oldMentionElements.forEach(element => {
    const mentionId = element.getAttribute("data-mention-id");
    if (!mentionId) return;

    // Check if this mention still exists in the new content
    const newMentionElements = newDom.querySelectorAll(`[data-mention-id="${mentionId}"]`);

    // If the mention existed in the old draft but not in the new one, add it to the removed list
    if (newMentionElements.length === 0) {
      removedMentionIds.push(mentionId);
    }
  });

  return removedMentionIds;
}
