import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { mdsLineSpacings } from "@/design-system/foundations/typography";

interface ImportIsRunningModalContentProps {
  handleGoToImports: () => void;
  handleCloseModal: () => void;
}

export const ImportIsRunningModalContent = observer<ImportIsRunningModalContentProps>(
  ({ handleGoToImports, handleCloseModal }) => {
    return (
      <Container>
        <TitleText
          size={MdsTextSize.Large}
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Large}
        >
          Import from 1.0 started
        </TitleText>
        <MdsText
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
        >
          Importing your notes and collections from Mem 1.0. This may take a few minutes.
        </MdsText>
        <ActionSection></ActionSection>
        <ButtonSection>
          <MdsButton
            variant={MdsButtonVariant.Outlined}
            label="See Imports"
            onClick={handleGoToImports}
          />
          <MdsButton
            variant={MdsButtonVariant.FilledDark}
            label="Got it"
            onClick={handleCloseModal}
          />
        </ButtonSection>
      </Container>
    );
  }
);

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "24px",
  maxWidth: 640,
  minWidth: 320,
});

const ActionSection = styled.div({
  maxWidth: 640,
  containerType: "inline-size",
  containerName: "actionSection",
});

const ButtonSection = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "flex-end",
});

const TitleText = styled(MdsText)({
  letterSpacing: mdsLineSpacings().mediumLarge,
});
