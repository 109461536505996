import { generateKeyBetween } from "fractional-indexing";
import {
  computed,
  makeObservable,
  action,
  onBecomeObserved,
  override,
  observable,
  runInAction,
  onBecomeUnobserved,
} from "mobx";
import { DraggableLocation } from "@hello-pangea/dnd";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { FavoriteItem, FavoriteItemModelData } from "@/store/favorite-items/types";
import { AppSubStoreArgs } from "@/store/types";
import { UpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/favorites/UpdateFavoriteItemSortKeyOperation";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { FavoriteItemIndexes } from "@/store/favorite-items/FavoriteItemIndexes";
import { Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription } from "dexie";
import { AppStore } from "@/store/AppStore";
import { searchFavoriteItems } from "@/store/favorite-items/search-favorite-items";

export class AppStoreFavoriteItemsStore extends BaseSyncModelStore<
  FavoriteItemObservable,
  FavoriteItemModelData
> {
  liveQuerySubscription: Maybe<Subscription>;
  sortedFavoriteItems: FavoriteItem[] = [];

  private initialLoaded: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.FavoriteItem, ...injectedDeps });
    makeObservable<
      this,
      "subscribeLiveQuery" | "unsubscribeLiveQuery" | "setIsReady" | "initialLoaded"
    >(this, {
      computeIndexes: override,
      createSyncModel: false,

      sortedFavoriteItems: observable,
      liveQuerySubscription: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      reorderFavoriteItems: action,
      searchFavoriteItems: true,
      initialLoaded: observable,
      setIsReady: action,
      isReady: computed,
    });

    onBecomeObserved(this, "sortedFavoriteItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "sortedFavoriteItems", () => this.unsubscribeLiveQuery());
    onBecomeObserved(this, "isReady", () => this.subscribeLiveQuery());
  }

  get isReady() {
    return this.initialLoaded;
  }

  public createSyncModel(
    updateValue: SyncUpdateValue<FavoriteItemModelData>
  ): FavoriteItemObservable {
    return new FavoriteItemObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  public reorderFavoriteItems = async (
    destination: DraggableLocation,
    source: DraggableLocation
  ) => {
    if (destination.index > this.sortedFavoriteItems.length) return;

    const sortOrder = (() => {
      if (destination.index > source.index) {
        // Key between the destination and the next item.
        const start = this.sortedFavoriteItems[destination.index].sortKey;
        const end = this.sortedFavoriteItems[destination.index + 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      } else if (destination.index < source.index) {
        // Key between the destination and the previous item.
        const end = this.sortedFavoriteItems[destination.index].sortKey;
        const start = this.sortedFavoriteItems[destination.index - 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      }
    })();

    const favoriteItem = this.sortedFavoriteItems[source.index];
    if (!favoriteItem || !sortOrder) return;

    await new UpdateFavoriteItemSortKeyOperation({
      store: this.store,
      payload: {
        favorite_item_id: favoriteItem.modelId,
        sort_key: sortOrder,
      },
    }).execute();
  };

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<FavoriteItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData>[];
  }): Record<string, unknown> {
    return new FavoriteItemIndexes({ remoteData, optimisticUpdates }).indexes;
  }

  public searchFavoriteItems = (): Promise<FavoriteItem[]> => {
    return Dexie.waitFor(searchFavoriteItems({ store: this.store }));
  };

  private subscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.searchFavoriteItems();
    }).subscribe({
      next: items => {
        runInAction(() => {
          this.sortedFavoriteItems = items;
          this.setIsReady(true);
        });
      },
    });
  }

  private unsubscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
  }

  private setIsReady(value: boolean) {
    this.initialLoaded = value;
  }
}
