import { BaseError } from "@/domains/errors";
import { api } from "@/modules/api";
import { objectModule } from "@/modules/object";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable } from "mobx";
import { windowModule } from "@/modules/window";
import { PlatformAccountState } from "@/store/platform-info/types";
import { logger } from "@/modules/logger";

export class SettingsSubscriptionManagementPageStore extends AppSubStore {
  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      hasActiveSubscription: computed,
      openBillingPortal: action,
      openSubscriptionModal: action,
      fetchBillingPortalUrl: action,
    });
  }

  get hasActiveSubscription() {
    return this.store.platformInfo.accountState === PlatformAccountState.Active;
  }

  public async openBillingPortal() {
    try {
      const billingPortalUrl = await this.fetchBillingPortalUrl();

      /** @TODO - Why is it necessary to open the window first? */
      const openedWindow = await windowModule.openInNewTab({ url: new URL("about:blank") });

      if (openedWindow) openedWindow.location.href = billingPortalUrl;
    } catch (unknownErr) {
      const err = unknownErr as BaseError;

      logger.error(err);

      alert("Unable to contact Stripe. Please try again later, or contact support@mem.ai");
    }
  }

  public openSubscriptionModal() {
    this.store.platformInfo.openSubscribeNowInterstitial();
  }

  public async fetchBillingPortalUrl(): Promise<string> {
    const spaceId = this.store.spaces.myPersonalSpaceId;
    const response = await api.get("/v2/me/subscriptions/stripe/billing_portal", {
      params: { query: { space_id: spaceId } },
    });

    if (response.data) {
      return response.data.stripe_billing_portal_url;
    }

    const err = new BaseError({
      message:
        "[SettingsSubscriptionManagementPageStore] Failed to fetch billing portal url for current account.",
      info: { result: objectModule.safeAsJson(response) },
    });

    logger.error(err);

    throw err;
  }
}
