import { GuestAppStoreConstructorArgs, GuestAppSubStoreArgs } from "@/store/types";
import { makeAutoObservable, runInAction } from "mobx";
import { AppStoreGuestAccountStore } from "@/store/guest-account/AppStoreGuestAccountStore";
import { PublicAppStore } from "@/store/PublicAppStore";
import {
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
  buildAsyncData,
} from "@/domains/async/AsyncData";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStoreGuestInterfaceStore } from "@/store/guest-interface/AppStoreGuestInterfaceStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { AppStoreGuestNoteStore } from "@/store/guest-note/AppStoreGuestNoteStore";
import { AppStoreQueriesCacheStore } from "@/store/queries/AppStoreQueriesCacheStore";
import { AppStoreGuestSyncStore } from "@/store/guest-sync/AppStoreGuestSyncStore";
import { AppStoreGuestContactsStore } from "@/store/guest-contacts/AppStoreGuestContactsStore";
import { AppStoreGuestNotesViewPageStore } from "@/store/guest-notes-view-page/AppStoreGuestNotesViewPageStore";
import { MemDB } from "@/domains/db/db";
import { TabDetector } from "@/store/tab-detector";
import { TabLifecycleManager } from "@/store/tab-lifecycle";

/**
 * A version of our AppStore which is accessible when the user gets a link to access a note before
 * signing up.
 */
export class GuestAppStore {
  contacts: AppStoreGuestContactsStore;
  guestAccount: AppStoreGuestAccountStore;
  interface: AppStoreGuestInterfaceStore;
  note: AppStoreGuestNoteStore;
  queriesCache: AppStoreQueriesCacheStore;
  sync: AppStoreGuestSyncStore;
  tabLifecycleManager: TabLifecycleManager;
  tabDetector: TabDetector;

  notesViewPage: AppStoreGuestNotesViewPageStore;
  shareSheetModal: ShareSheetModalStore;

  publicAppStore: PublicAppStore;

  protected _memDb: MemDB | undefined;

  initializationState = buildAsyncData<boolean>({});

  constructor({ publicAppStore, api, pusher }: GuestAppStoreConstructorArgs) {
    const injectedDeps: GuestAppSubStoreArgs = { store: this, api, pusher };

    this.publicAppStore = publicAppStore;
    this.tabLifecycleManager = new TabLifecycleManager();
    this.tabDetector = new TabDetector();
    this.contacts = new AppStoreGuestContactsStore(injectedDeps);
    this.guestAccount = new AppStoreGuestAccountStore(injectedDeps);
    this.interface = new AppStoreGuestInterfaceStore(injectedDeps);
    this.note = new AppStoreGuestNoteStore(injectedDeps);
    this.queriesCache = new AppStoreQueriesCacheStore();
    this.sync = new AppStoreGuestSyncStore(injectedDeps);

    this.notesViewPage = new AppStoreGuestNotesViewPageStore(injectedDeps);
    this.shareSheetModal = ShareSheetModalStore.forGuestStore({ guestStore: this });

    makeAutoObservable(this);
  }

  get memDb(): MemDB {
    if (!this._memDb) {
      throw new Error("Attempt to access MemDB before initialization");
    }

    return this._memDb;
  }

  get auth() {
    return this.publicAppStore.auth;
  }

  async initialize() {
    try {
      runInAction(() => {
        this.initializationState = asyncDataLoading(this.initializationState);
      });

      /** Put initializers here. */

      runInAction(() => {
        this.initializationState = asyncDataLoaded(this.initializationState, true);
      });
    } catch (err) {
      logger.error({
        message: "Failed to initialize GuestAppStore",
        info: objectModule.safeAsJson({ err }),
      });

      runInAction(() => {
        this.initializationState = asyncDataFailed(this.initializationState);
      });

      throw err;
    }
  }
}
