import { MdsIconKind } from "@/design-system/components/icon";
import { NoteMediaKind } from "@/store/note";
import {
  MediaKindsFacetFilterItemKind,
  MediaKindsFacetFilterItem,
} from "@/components/filters/MediaKindsFacetFilter/types";

export function getMediaKindsFacetFilterItems(
  selectedMediaKinds: NoteMediaKind[]
): MediaKindsFacetFilterItem[] {
  return [
    {
      id: "label-tasks",
      kind: MediaKindsFacetFilterItemKind.Label,
      label: "Tasks",
      isSelected: false,
    },
    {
      id: "media-kind-open-task",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("OPENTASK"),
      label: "Open tasks",
      data: {
        mediaKind: "OPENTASK",
        icon: MdsIconKind.CheckboxEmpty,
      },
    },
    {
      id: "media-kind-task",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("TASK"),
      label: "Task lists",
      data: {
        mediaKind: "TASK",
        icon: MdsIconKind.Tasks,
      },
    },
    {
      id: "label-attachments",
      kind: MediaKindsFacetFilterItemKind.Label,
      label: "Attachments",
      isSelected: false,
    },
    {
      id: "media-kind-images",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("IMAGE"),
      label: "Images",
      data: {
        mediaKind: "IMAGE",
        icon: MdsIconKind.Image,
      },
    },
    {
      id: "media-kind-files",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("FILE"),
      label: "Files",
      data: {
        mediaKind: "FILE",
        icon: MdsIconKind.Paperclip,
      },
    },
    {
      id: "label-formatting",
      kind: MediaKindsFacetFilterItemKind.Label,
      label: "Formatting",
      isSelected: false,
    },
    {
      id: "media-kind-tables",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("TABLE"),
      label: "Tables",
      data: {
        mediaKind: "TABLE",
        icon: MdsIconKind.Table,
      },
    },
    {
      id: "media-kind-code",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("CODE"),
      label: "Code",
      data: {
        mediaKind: "CODE",
        icon: MdsIconKind.Code,
      },
    },
    {
      id: "media-kind-quotes",
      kind: MediaKindsFacetFilterItemKind.MediaKind,
      isSelected: selectedMediaKinds.includes("QUOTE"),
      label: "Quotes",
      data: {
        mediaKind: "QUOTE",
        icon: MdsIconKind.Quote,
      },
    },
  ];
}
