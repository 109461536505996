import { observer } from "mobx-react-lite";

import { PlatformIconKind } from "@/domains/api/types";
import { MdsIcon } from "@/design-system/components/icon";
import { MdsIconStylingProps } from "@/design-system/components/icon/MdsIcon";
import { resolvePlatformIconData } from "@/components/icons/utils/resolve-platform-icon";

interface PlatformIconProps extends MdsIconStylingProps {
  iconKind: PlatformIconKind;
}

export const PlatformIcon = observer<PlatformIconProps>(({ iconKind, className, innerStyles }) => {
  const data = resolvePlatformIconData({ iconKind });

  if (data.icon) {
    return <MdsIcon kind={data.icon} className={className} innerStyles={innerStyles} />;
  }

  if (data.image) {
    return <img src={data.image} />;
  }

  return null;
});
