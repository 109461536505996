import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { TemplateEditor } from "@/pages/templates-view/TemplateEditor";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { FileUploadRejectedModal } from "@/components/modal/file-upload-rejected/FileUploadRejectedModal";
import { ImageUploadRejectedModal } from "@/components/modal/image-upload-rejected/ImageUploadRejectedModal";
import { DeleteTemplateModal } from "@/components/modal/delete-template/DeleteTemplateModal";

export const TemplatesViewPage = observer(function TemplatesViewPage() {
  const { store: appStore, pageStore } = useAppStore();
  const store = pageStore.templatesViewPage;
  store.useEffects();

  const chatHistory = appStore.chatMessages.chatHistory;
  const isSidePanelOpen = appStore.interface.isChatSidebarOpen;

  if (store.showNotFound) {
    return <NotFoundPage entityType={EntityNotFoundType.Template} />;
  }

  return (
    <>
      {store.addToCollectionModal && <AddToCollectionModal store={store.addToCollectionModal} />}
      {store.fileUploadRejectedModal && (
        <FileUploadRejectedModal store={store.fileUploadRejectedModal} />
      )}
      {store.imageUploadRejectedModal && (
        <ImageUploadRejectedModal store={store.imageUploadRejectedModal} />
      )}
      <DeleteTemplateModal store={store.deleteTemplateModal} />
      <TemplateEditor
        isSidePanelOpen={!!isSidePanelOpen}
        chatHistory={chatHistory}
        templateObservable={store.itemObservable}
        openFileUploadRejectedModal={store.openFileUploadRejectedModal}
        openImageUploadRejectedModal={store.openImageUploadRejectedModal}
        myAccount={store.myAccount}
        goToMention={store.goToMention}
      />
    </>
  );
});
