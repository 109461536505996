import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";

import { ChatView } from "@/components/chat/ChatView";
import { useCallback } from "react";
import { RedirectToActiveOrNewChat } from "@/components/chat/RedirectToActiveOrNewChat";

export interface ChatViewRouterProps {
  conversationId?: string;
  inSidePanel?: boolean;
}

export const ChatViewRouter = observer<ChatViewRouterProps>(function ChatViewRouter({
  conversationId,
  inSidePanel = false,
}) {
  const { store } = useAppStore();

  const handleSelectChat = useCallback(
    (conversationId: string) => {
      if (inSidePanel) {
        store.sidePanel.goToChat(conversationId);
      } else {
        store.navigation.goToChatConversation({ conversationId });
      }
    },
    [inSidePanel, store.navigation, store.sidePanel]
  );

  const handleNewChat = async () => {
    const id = await store.chatConversations.createOrResumeEmptyGuidedChatConversation();
    handleSelectChat(id);
  };

  if (!conversationId) {
    return <RedirectToActiveOrNewChat inSidePanel={inSidePanel} />;
  }

  return (
    <ChatView
      conversationId={conversationId}
      onNewChat={handleNewChat}
      onSelectChat={handleSelectChat}
    />
  );
});
