import { AuthOverlay } from "@/app/overlays";
import { ImportIsRunningModal } from "@/components/modal/imports";
import { V2TransitionOverlay } from "@/components/v2-transition";
import { observer } from "mobx-react-lite";

interface AppOverlaysProps {}

export const StandardModeAppAppOverlays = observer<AppOverlaysProps>(() => {
  return (
    <>
      <AuthOverlay />
      <ImportIsRunningModal />
      <V2TransitionOverlay />
    </>
  );
});
