import { MdsButton } from "@/design-system/components/button";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import {
  MdsButtonShape,
  MdsButtonSize,
  MdsButtonVariant,
} from "@/design-system/components/button/types";
import { SelectedButton } from "@/components/filters/ToggleButton/SelectedButton";

interface IProps {
  hasSelectedItems: boolean;
  computedButtonLabel: string;
  setIsOpen: (isOpen: boolean) => void;
  onClear: () => void;
  iconKind?: MdsIconKind;
}

export function ToggleButton({
  hasSelectedItems,
  computedButtonLabel,
  setIsOpen,
  onClear,
  iconKind = MdsIconKind.User,
}: IProps) {
  return (
    <MdsButton
      shape={MdsButtonShape.Round}
      size={MdsButtonSize.Medium}
      variant={hasSelectedItems ? MdsButtonVariant.Toggled : MdsButtonVariant.Outlined}
      iconKind={iconKind}
      label={computedButtonLabel}
      onClick={() => setIsOpen(true)}
      isSelected={hasSelectedItems}
      icons={
        hasSelectedItems ? (
          <SelectedButton onSelectClear={onClear} />
        ) : (
          <MdsIcon kind={MdsIconKind.AngleDown} />
        )
      }
    />
  );
}
