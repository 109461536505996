import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors, mdsMediaQueries } from "@/design-system/foundations";
import { mdsLineSpacings } from "@/design-system/foundations/typography";
import { css } from "@/domains/emotion";
import { useLastNonNullRender } from "@/hooks/useLastNonNullRender";
import { MessageModal } from "@/store/modals/types";
import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";

export interface MessageOverlayProps {
  message?: MessageModal;
}

export const MessageOverlayContent = observer<MessageOverlayProps>(function MessageOverlay({
  message,
}) {
  const content = useLastNonNullRender(() => {
    if (!message) return null;

    return (
      <div className={containerStyles}>
        <MdsText
          size={MdsTextSize.Large}
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Large}
          className={css({
            letterSpacing: mdsLineSpacings().mediumLarge,
          })}
        >
          {message.title}
        </MdsText>
        <div className={contentStyles}>
          <MdsText
            color={mdsColors().grey.x600}
            lineHeight={MdsTextSize.Small}
            size={MdsTextSize.Small}
          >
            {message.message}
          </MdsText>
        </div>
        <div className={buttonSectionStyles}>
          {message.secondaryButton && (
            <MdsButton
              variant={MdsButtonVariant.Outlined}
              label={message.secondaryButton.label}
              onClick={() => message.secondaryButton?.onClick?.()}
            />
          )}
          <MdsButton
            variant={MdsButtonVariant.FilledDark}
            label={message.buttonLabel ?? ""}
            onClick={() => message.handleButtonClick()}
          />
        </div>
      </div>
    );
  });
  return (
    <MdsModal
      isModalOpen={!!message}
      showOverflow={true}
      handleCloseModal={() => message?.handleClose()}
      handleUnmountModal={noop} // Don't go up when unmounting.
    >
      {content}
    </MdsModal>
  );
});

const containerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "24px",
  width: "min(530px, 90vw)",
});

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  justifyContent: "flex-end",
  marginTop: "24px",

  [mdsMediaQueries().rules.phone]: {
    flexDirection: "column",
    width: "100%",
    "& button": {
      width: "100%",
    },
  },
});
