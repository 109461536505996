import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRevokeTemplateAclViaSpaceAccountOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";

export class RevokeTemplateAclViaSpaceAccountOperation extends BaseSyncOperation<IRevokeTemplateAclViaSpaceAccountOperation> {
  get operationKind(): SyncOperationKind {
    return "REVOKE_TEMPLATE_ACL_VIA_SPACE_ACCOUNT";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;

    const existingTemplate = await this.store.templates.getAsync(templateId);
    if (!existingTemplate) return [];

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
      model_data: {
        ...existingTemplate.modelData,
        target_scopes: existingTemplate.modelData.target_scopes.filter(scope => {
          return !(
            scope.scope_kind === "SPACE_ACCOUNT_SCOPE" &&
            scope.value.space_account_id === this.payload.space_account_id
          );
        }),
      },
      model_scopes: existingTemplate.modelScopes,
    };

    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
