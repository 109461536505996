import styled from "@emotion/styled";

export const SearchPagePaginationCapacityReached = () => {
  return <Wrapper>Maximum results displayed. Try narrowing your search.</Wrapper>;
};

const Wrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.grey.x500};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
