import { generateShortDateString } from "@/domains/date/date";
import { SortByKind } from "@/modules/lenses/types";
import { TemplatesSortByKind } from "@/store/templates/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";

export const templatesLensDateLabelMap: {
  [key in TemplatesSortByKind]: (template: TemplateObservable) => string;
} = {
  [SortByKind.LastModified]: template =>
    generateShortDateString(template.modelData.locally_modified_at),
  [SortByKind.LastCreated]: template =>
    generateShortDateString(template.modelData.locally_created_at),
  [SortByKind.LastViewed]: template =>
    generateShortDateString(
      template.spaceAccountTemplate?.modelData.last_viewed_at ||
        template.modelData.locally_created_at
    ),
  [SortByKind.Alphabetical]: template =>
    generateShortDateString(template.modelData.locally_created_at),
  [SortByKind.LastShared]: template => {
    return generateShortDateString(
      template.spaceAccountTemplate?.modelData.shared_with_me_at || ""
    );
  },
  [SortByKind.LastUsed]: template => {
    return generateShortDateString(template.spaceAccountTemplate?.modelData.last_used_at || "");
  },
};
