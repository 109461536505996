import { ChatViewRouter } from "@/components/chat/ChatViewRouter";
import { uuidModule } from "@/modules/uuid";

import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useParams } from "react-router-dom";

interface ChatConversationPageProps {}

export const ChatConversationPage: FC<ChatConversationPageProps> = observer(() => {
  const { conversationId: conversationIdParam } = useParams<{ conversationId?: string }>();

  let conversationId: string | undefined;
  try {
    conversationId = uuidModule.maybeParseValue(conversationIdParam);
  } catch (_) {
    conversationId = undefined;
  }

  return <ChatViewRouter conversationId={conversationId} inSidePanel={false} />;
});
