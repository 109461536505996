import { CalendarDate, today, getLocalTimeZone, getDayOfWeek } from "@internationalized/date";
import { datadogRum } from "@datadog/browser-rum";

export interface TimeRange {
  label: string;
  readableDate: string;
  timestamp_start: number;
  timestamp_end: number;
}

const MONTHS = [
  ["January", "Jan"],
  ["February", "Feb"],
  ["March", "Mar"],
  ["April", "Apr"],
  ["May", "May"],
  ["June", "Jun"],
  ["July", "Jul"],
  ["August", "Aug"],
  ["September", "Sep"],
  ["October", "Oct"],
  ["November", "Nov"],
  ["December", "Dec"],
];

function dateToTimestamp(date: CalendarDate, endOfDay: boolean = false): number {
  const jsDate = date.toDate(getLocalTimeZone());
  if (endOfDay) {
    jsDate.setHours(23, 59, 59, 999);
  } else {
    jsDate.setHours(0, 0, 0, 0);
  }
  return Math.floor(jsDate.getTime() / 1000);
}

function formatDate(date: CalendarDate): string {
  return `${MONTHS[date.month - 1][1]} ${date.day}`;
}

function getReadableDate(startDate: CalendarDate, endDate: CalendarDate): string {
  const startFormatted = formatDate(startDate);
  if (startDate.compare(endDate) === 0) {
    return startFormatted;
  }
  return `${startFormatted}–${formatDate(endDate)}`;
}

// TODO: memoize this based on a reasonable time period.
// We don't need to recompute this every minute, but we do need to recompute at least once per day.
// A KISS approach would be to recompute once a minute if the generation is performant enough on a Chromebook.
export function getTimeRanges(): TimeRange[] {
  const startTime = performance.now();

  try {
    const ranges: TimeRange[] = [];
    const nowDate = today(getLocalTimeZone());

    // Today
    ranges.push({
      label: "Today",
      readableDate: formatDate(nowDate),
      timestamp_start: dateToTimestamp(nowDate),
      timestamp_end: dateToTimestamp(nowDate, true),
    });

    // Yesterday
    const yesterday = nowDate.subtract({ days: 1 });
    ranges.push({
      label: "Yesterday",
      readableDate: formatDate(yesterday),
      timestamp_start: dateToTimestamp(yesterday),
      timestamp_end: dateToTimestamp(yesterday, true),
    });

    // Specific day ranges
    const dayRanges = [7, 14, 30, 60, 90];
    for (const days of dayRanges) {
      const pastDate = nowDate.subtract({ days: days - 1 });
      ranges.push({
        label: `Last ${days} days`,
        readableDate: getReadableDate(pastDate, nowDate),
        timestamp_start: dateToTimestamp(pastDate),
        timestamp_end: dateToTimestamp(nowDate, true),
      });
    }

    // Last 2 weeks
    const twoWeeksAgo = nowDate.subtract({ weeks: 2 });
    ranges.push({
      label: "Last 2 weeks",
      readableDate: getReadableDate(twoWeeksAgo, nowDate),
      timestamp_start: dateToTimestamp(twoWeeksAgo),
      timestamp_end: dateToTimestamp(nowDate, true),
    });

    // This week (per locale)
    const currentWeekday = getDayOfWeek(nowDate, navigator.language);
    const thisWeekStart = nowDate.subtract({ days: currentWeekday });
    const thisWeekEnd = thisWeekStart.add({ days: 6 });
    ranges.push({
      label: "This week",
      readableDate: getReadableDate(thisWeekStart, thisWeekEnd),
      timestamp_start: dateToTimestamp(thisWeekStart),
      timestamp_end: dateToTimestamp(thisWeekEnd, true),
    });

    // Last week (per locale)
    const lastWeekEnd = nowDate.subtract({ days: currentWeekday + 1 });
    const lastWeekStart = lastWeekEnd.subtract({ days: 6 });
    ranges.push({
      label: "Last week",
      readableDate: getReadableDate(lastWeekStart, lastWeekEnd),
      timestamp_start: dateToTimestamp(lastWeekStart),
      timestamp_end: dateToTimestamp(lastWeekEnd, true),
    });

    // This month (per locale)
    const thisMonthStart = nowDate.set({ day: 1 });
    const thisMonthEnd = thisMonthStart.add({ months: 1 }).subtract({ days: 1 });
    ranges.push({
      label: "This month",
      readableDate: getReadableDate(thisMonthStart, thisMonthEnd),
      timestamp_start: dateToTimestamp(thisMonthStart),
      timestamp_end: dateToTimestamp(thisMonthEnd, true),
    });

    // Last month
    const lastMonth = nowDate.set({ day: 1 }).subtract({ months: 1 });
    const lastMonthEnd = nowDate.set({ day: 1 }).subtract({ days: 1 });
    ranges.push({
      label: "Last month",
      readableDate: getReadableDate(lastMonth, lastMonthEnd),
      timestamp_start: dateToTimestamp(lastMonth),
      timestamp_end: dateToTimestamp(lastMonthEnd, true),
    });

    // Last quarter
    const currentQuarter = Math.floor((nowDate.month - 1) / 3);
    const lastQuarterStart =
      currentQuarter === 0
        ? nowDate.subtract({ years: 1 }).set({ month: 10, day: 1 }) // If in Q1, last quarter was Q4 of previous year
        : nowDate.set({ month: (currentQuarter - 1) * 3 + 1, day: 1 }); // Otherwise, last quarter was in same year
    const lastQuarterEnd = lastQuarterStart.add({ months: 3 }).subtract({ days: 1 });
    ranges.push({
      label: "Last quarter",
      readableDate: getReadableDate(lastQuarterStart, lastQuarterEnd),
      timestamp_start: dateToTimestamp(lastQuarterStart),
      timestamp_end: dateToTimestamp(lastQuarterEnd, true),
    });

    // Last year
    const lastYear = nowDate.set({ month: 1, day: 1 }).subtract({ years: 1 });
    const lastYearEnd = nowDate.set({ month: 1, day: 1 }).subtract({ days: 1 });
    ranges.push({
      label: "Last year",
      readableDate: getReadableDate(lastYear, lastYearEnd),
      timestamp_start: dateToTimestamp(lastYear),
      timestamp_end: dateToTimestamp(lastYearEnd, true),
    });

    for (let i = 0; i < 12; i++) {
      const monthStart = nowDate.set({ month: i + 1, day: 1 });
      const monthEnd =
        i === 11
          ? monthStart.set({ month: 12, day: 31 })
          : monthStart.add({ months: 1 }).subtract({ days: 1 });

      if (monthEnd.compare(nowDate) <= 0) {
        // Current year's month has passed or is current
        ranges.push({
          label: MONTHS[i][0],
          readableDate: getReadableDate(monthStart, monthEnd),
          timestamp_start: dateToTimestamp(monthStart),
          timestamp_end: dateToTimestamp(monthEnd, true),
        });
      } else {
        // Month hasn't occurred yet this year, use last year's month
        const lastYearMonthStart = monthStart.subtract({ years: 1 });
        const lastYearMonthEnd = monthEnd.subtract({ years: 1 });
        ranges.push({
          label: `${MONTHS[i][0]} ${lastYearMonthStart.year}`,
          readableDate: getReadableDate(lastYearMonthStart, lastYearMonthEnd),
          timestamp_start: dateToTimestamp(lastYearMonthStart),
          timestamp_end: dateToTimestamp(lastYearMonthEnd, true),
        });
      }
    }

    return ranges;
  } finally {
    const duration = performance.now() - startTime;
    datadogRum.addTiming("getTimeRanges", duration);
  }
}
