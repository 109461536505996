import {
  flip,
  FloatingPortal,
  Placement as FloatingPlacement,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  offset,
  autoUpdate,
} from "@floating-ui/react";
import { ReactNode, FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { AnimatePresence } from "framer-motion";
import { SpringAnimator } from "@/design-system/components/animation";

type Placement = "above" | "above-right-alignment" | "below" | "below-right-alignment" | "right";

export interface MdsDropdownProps extends EmotionClassStyles {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  contentList: MdsDropdownContentList;
  children: ReactNode;
  placement?: Placement;
  offset?: number;
  innerStyles?: {
    ContentContainer?: {
      className?: string;
    };
    Content?: {
      className?: string;
    };
    Row?: {
      className?: string;
    };
  };
}

const getPlacement = (placement?: Placement): FloatingPlacement => {
  switch (placement) {
    case "above":
      return "top-start";
    case "below":
      return "bottom-start";
    case "below-right-alignment":
      return "bottom-end";
    case "right":
      return "right-start";
    case "above-right-alignment":
      return "top-end";
  }
  return "bottom-start";
};

const contentContainerStyles = css({
  position: "relative",
});

const rightAlignContainerStyles = css({
  position: "relative",
  height: "fit-content",
});

export const MdsDropdown: FC<MdsDropdownProps> = ({
  className,
  children,
  isOpen,
  onOpenChange,
  contentList,
  placement,
  innerStyles,
  offset: offsetProp,
}) => {
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: isOpen => {
      if (isOpen) {
        /** We don't want to report open on hover states, because the dropdown opening
         * is handled by the parent component. This should only used to report dismiss events. */
        return;
      }

      onOpenChange(false);
    },
    placement: getPlacement(placement),
    middleware: [flip(), offset(offsetProp ?? 4)],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const placementContainerStyles =
    placement === "below-right-alignment" || placement === "above-right-alignment"
      ? rightAlignContainerStyles
      : undefined;
  const placedContentContainerStyles = cx(contentContainerStyles, placementContainerStyles);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} className={className}>
        {children}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={cx(placedContentContainerStyles, innerStyles?.ContentContainer?.className)}
          >
            <AnimatePresence>
              <SpringAnimator>
                <MdsDropdownContent
                  className={innerStyles?.Content?.className}
                  onOpenChange={onOpenChange}
                  contentList={contentList}
                  rowStyles={innerStyles?.Row?.className}
                />
              </SpringAnimator>
            </AnimatePresence>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
