import { DateSegment } from "react-aria-components";

import styled from "@emotion/styled";

export const StyledDateSegment = styled(DateSegment)`
  padding: 2px 4px;
  font-variant-numeric: tabular-nums;
  text-align: end;
  color: ${({ theme }) => theme.colors.grey.x800};

  &[data-type="literal"] {
    padding: 0;
    color: ${({ theme }) => theme.colors.grey.x400};
  }

  &[data-placeholder] {
    color: ${({ theme }) => theme.colors.grey.x400};
  }

  &:focus {
    color: unset;
    background: ${({ theme }) => theme.colors.grey.x50};
    outline: none;
    border-radius: 4px;
    caret-color: transparent;
  }
`;
