import styled from "@emotion/styled";

export const MentionTitle = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey.x600,
  display: "flex",
  gap: theme.spacing.sm,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));
