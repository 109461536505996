import { Maybe } from "@/domains/common/types";
import { ApiSchema, api } from "@/modules/api";
import { GuestAppStore } from "@/store/GuestAppStore";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";
import { SyncOperationGeneric, SyncOperationGuestMode } from "@/store/sync/operations/types";

export class GuestSyncActionQueue extends BaseSyncActionQueue<GuestAppStore> {
  get syncErrorModalFields(): SyncErrorModalFields {
    return {};
  }

  async processOperation(
    operation: SyncOperationGuestMode
  ): Promise<Maybe<SyncOperationGuestMode>> {
    /**
     * Guest mode support is not implemented yet.
     * For now, mocking this out with the "normal" submission endpoint.
     * We'll need to update this in the future when guest mode support is implemented.
     */
    const response = await api.post(`/v2/sync/operations/submit`, {
      params: { query: { space_id: this.getSpaceId() } },
      body: await operation.generateSyncOperation(),
    });
    console.debug("[SYNC][SyncActionQueue] Synced operation", operation, response);

    // HANDLE COMPLETED OPERATIONS
    if (
      !response.error &&
      response.data.status === "COMPLETED" &&
      "sync_operation" in response.data
    ) {
      operation.acknowledge(response.data.sync_operation.value.latest_space_account_sequence_id);
      return operation;
    }

    // HANDLE SKIPPED OPERATIONS
    if (!response.error && response.data.status === "SKIPPED") {
      await this.skipAndRevertOperationById(operation.operationId);
      return;
    }

    // HANDLE CUSTOM ERRORS
    if (
      !response.error &&
      response.data.status === "FAILED" &&
      "info" in response.data &&
      "error_data" in response.data.info
    ) {
      const errorInfo = response.data.info
        .error_data as ApiSchema["SubmitSyncOperationFailedCustomResponseData"];
      this.handleCustomError(operation, errorInfo);
      return;
    }

    // HANDLE UNKNOWN ERRORS
    this.handleCustomError(operation, { kind: "UNKNOWN", value: {} }); // TODO: value is required by API, but we're not using it yet.
  }

  async triggerRecomputeByOperation(_operation: SyncOperationGeneric) {
    return;
  }
}
