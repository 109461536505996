import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateNoteUsingTemplateOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { NoteContentDocumentModelData } from "@/store/note/types";
import {
  NoteContentDocumentUpsertedSyncUpdateValue,
  NoteUpsertedSyncUpdateValue,
} from "@/store/note/types";
import { NoteModelData } from "@/store/note/types";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { resolveCollectionItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveCollectionItemSyncModelUuid";
import {
  CollectionItemModelData,
  CollectionItemUpsertedSyncUpdateValue,
} from "@/store/collection-items/types";
import { generateTemplateSharingText } from "@/domains/templates/generateTemplateSharingText";
import { MarkTemplateUsedOperation } from "@/store/sync/operations/templates/MarkTemplateUsedOperation";
import { resolveSpaceAccountTemplateSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountTemplateSyncModelUuid";

export class CreateNoteUsingTemplateOperation extends BaseSyncOperation<ICreateNoteUsingTemplateOperation> {
  private template?: TemplateObservable;
  private successToastMessageComponent: JSX.Element | null = null;

  get operationKind(): SyncOperationKind {
    return "CREATE_NOTE_USING_TEMPLATE_AND_DIFF";
  }

  async execute() {
    this.template = await this.store.templates.getAsync(this.payload.template_id);
    await this.generateSuccessToastMessageComponent();
    await super.execute();
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const syncUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    if (!this.template || !this.payload.encoded_content) return syncUpdates;

    const noteValue: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.note_id,
      model_kind: "NOTE",
      model_version: 0,
      model_data: {
        trashed_at: null,
        created_at: this.committedAt,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        created_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        modified_by_space_account_ids: [this.store.spaceAccounts.myPersonalSpaceAccountId],
        primary_label: this.template.modelData.primary_label,
        primary_label_strategy: "INFERRED_FROM_CONTENT",
        secondary_label: "",
        secondary_label_strategy: "INFERRED_FROM_CONTENT",
        media_kinds: [],
        mentioned_note_ids: [],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const noteUpserted: OptimisticSyncUpdate<NoteModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteValue,
    };
    syncUpdates.push(noteUpserted);

    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.note_id,
    });
    const noteContentDocumentValue: NoteContentDocumentUpsertedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
      model_data: {
        note_id: this.payload.note_id,
        encoded_content: this.payload.encoded_content,
      },
      model_scopes: [
        generateDefaultOwnerScopes({ store: this.store }),
        ...this.template.modelData.target_scopes,
      ],
    };
    const noteContentDocumentUpserted: OptimisticSyncUpdate<NoteContentDocumentModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteContentDocumentValue,
    };
    syncUpdates.push(noteContentDocumentUpserted);

    const spaceAccountTemplateUpdates = await new MarkTemplateUsedOperation({
      store: this.store,
      payload: {
        template_id: this.template?.id,
      },
      operationId: this.operationId,
    }).generateOptimisticUpdates();
    syncUpdates.push(...spaceAccountTemplateUpdates);

    if (this.template.isAutoSharingEnabled) {
      const collection_item_updates = this.template.modelData.target_scopes
        .filter(scope => scope.scope_kind === "COLLECTION_SCOPE")
        .map(scope => {
          const collectionItemId = resolveCollectionItemSyncModelUuid({
            collectionId: scope.value.collection_id,
            itemId: this.payload.note_id,
          });
          const collectionItemValue: CollectionItemUpsertedSyncUpdateValue = {
            model_id: collectionItemId,
            model_kind: "COLLECTION_ITEM",
            model_version: 0,
            model_data: {
              collection_id: scope.value.collection_id,
              item_id: this.payload.note_id,
              item_kind: "NOTE",
              locally_created_at: this.committedAt,
              added_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
            },
            model_scopes: [
              generateDefaultOwnerScopes({ store: this.store }),
              ...(this.template?.modelData.target_scopes || []),
            ],
          };
          const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
            optimistic_update_id: uuidModule.generate(),
            locally_committed_at: this.committedAt,
            kind: "UPSERTED",
            value: collectionItemValue,
          };
          return collectionItemUpdate;
        });
      syncUpdates.push(...collection_item_updates);
    }

    return syncUpdates;
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.note_id);
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.note_id,
    });
    await this.store.noteContentDocuments.recompute(noteContentDocumentId);

    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      templateId: this.payload.template_id,
    });
    await this.store.spaceAccountTemplates.recompute(spaceAccountTemplateId);

    if (this.template) {
      for (const scope of this.template.modelData.target_scopes) {
        if (scope.scope_kind === "COLLECTION_SCOPE") {
          const collectionItemId = resolveCollectionItemSyncModelUuid({
            collectionId: scope.value.collection_id,
            itemId: this.payload.note_id,
          });
          await this.store.collections.recompute(scope.value.collection_id);
          await this.store.collectionItems.recompute(collectionItemId);
        }
      }
    }
  }

  get successToastMessage() {
    if (!this.template) return null;
    return this.successToastMessageComponent;
  }

  async generateSuccessToastMessageComponent() {
    if (!this.template) return;
    this.successToastMessageComponent = await generateTemplateSharingText({
      template: this.template,
      store: this.store,
    });
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
