import EmptyCollections from "@/assets/images/empty-collections.svg";
import { AppStore, useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";
import { LensKind } from "@/modules/lenses/types";
import { NotesListPageEmptyButton } from "@/pages/notes/components/NotesListPageEmptyButton";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";

const COPY_WITH_FILTERS = "No results match your current filters";

interface NotesListPageListEmptyProps {
  area: LensKind;
}

export const NotesListPageListEmpty = observer<NotesListPageListEmptyProps>(({ area }) => {
  const { pageStore, store } = useAppStore();
  const page = pageStore.notesListPage;
  const { filters } = page;
  const copy = getCopy(store, area, filters);

  return (
    <EmptyContainer dataTestId="notes-list-page-list-empty">
      <img src={EmptyCollections} alt={copy} />
      <EmptyText>{copy}</EmptyText>
      <NotesListPageEmptyButton area={area} />
    </EmptyContainer>
  );
});

const getCopy = (store: AppStore, area: LensKind, filters: NotesListFilter) => {
  const isMigrationStarted = store.dataImports.isMigrationStarted;
  const isMigrationInProgress = store.dataImports.isMigrationInProgress;

  if (filters.hasAnyFilters) {
    return COPY_WITH_FILTERS;
  }

  if (isMigrationInProgress) {
    return COPY_DURING_MIGRATION[area];
  }

  if (isMigrationStarted) {
    return COPY[area];
  }

  return COPY_BEFORE_MIGRATION[area];
};

/**
 * @deprecated
 * TODO: remove after DTF is complete
 * */
const COPY_BEFORE_MIGRATION: Record<LensKind, string> = {
  [LensKind.All]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.AddedByMe]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.SharedWithMe]: "No notes have been shared with you",
} as const;

/**
 * @deprecated
 * TODO: remove after DTF is complete
 * */
const COPY_DURING_MIGRATION: Record<LensKind, string> = {
  [LensKind.All]: "No notes available yet. Any Mem 1.0 notes will appear here during migration.",
  [LensKind.AddedByMe]:
    "No notes available yet. Any Mem 1.0 notes will appear here during migration",
  [LensKind.SharedWithMe]:
    "No notes available yet. Any shared Mem 1.0 notes will appear here during migration after all collaborators have been upgraded to 2.0.",
} as const;

const COPY: Record<LensKind, string> = {
  [LensKind.All]: "You haven't created any notes.",
  [LensKind.AddedByMe]: "You haven't created any notes.",
  [LensKind.SharedWithMe]: "No notes have been shared with you",
} as const;
