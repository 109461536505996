import { capturePerformanceAppBoot, identifyUserInMetrics, trackEvent } from "@/domains/metrics";
import { useAppStore } from "@/store";
import { useEffect } from "react";
import { TrackedEvent } from "@/domains/metrics";

interface PerformanceMemory {
  usedJSHeapSize: number;
  totalJSHeapSize: number;
  jsHeapSizeLimit: number;
}

interface ExtendedPerformance extends Performance {
  memory?: PerformanceMemory;
}

export const useAuthenticatedMetrics = () => {
  const { store } = useAppStore();

  useEffect(() => {
    if (store.spaceAccounts.myPersonalSpaceAccountId) {
      identifyUserInMetrics(store.spaceAccounts.myPersonalSpaceAccountId, {
        email: store.account.myAccount.profileEmailAddress,
        name: store.account.myAccount.displayName,
        profilePhotoUrl: store.account.myAccount.profilePhotoUrl,
      });

      /**
       * @note (from Kevin):
       *
       * I commented out the counts here (e.g. num_notes) for now because I'm concerned that they:
       * (a) May not be accurate because they only reflect what's in the in-memory pools
       * (b) May be expensive and non-performant to compute
       */
      capturePerformanceAppBoot({
        // num_notes: store.notes.all.length,
        // num_collections: store.collections.all.length,
        // num_chat_messages: store.chatMessages.all.length,
        // num_sync_events: // TODO,
      });

      if (store.sync.bootstrapDuration) {
        trackEvent(TrackedEvent.PerformanceBootstrap, {
          seconds: store.sync.bootstrapDuration / 1000,
        });
      }

      const trackPerformanceHeartbeat = () => {
        const perf = performance as ExtendedPerformance;
        const numNotesInListInMemory = store.notes.pool.size;
        const numCollectionsInMemory = store.collections.pool.size;
        const numChatMessagesInMemory = store.chatMessages.pool.size;

        trackEvent(TrackedEvent.PerformanceHeartbeat, {
          numNotesInListInMemory,
          numCollectionsInMemory,
          numChatMessagesInMemory,
          memory_mb:
            hasMemory(perf) && perf.memory
              ? Math.round(perf.memory.usedJSHeapSize / 1024 / 1024)
              : undefined,
        });
      };

      /**
       * Track performance heartbeat after 60s, then every 60m thereafter
       */
      let interval: NodeJS.Timeout;
      const timeout = setTimeout(() => {
        trackPerformanceHeartbeat();
        interval = setInterval(trackPerformanceHeartbeat, 60 * 60 * 1000);
      }, 60 * 1000);

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.sync.bootstrapDuration,
    store.account.myAccount.displayName,
    store.account.myAccount.id,
    store.account.myAccount.profileEmailAddress,
    store.account.myAccount.profilePhotoUrl,
    store.spaceAccounts.myPersonalSpaceAccountId,
  ]);
};

export const useAnonMetrics = () => {
  useEffect(() => {
    capturePerformanceAppBoot({});
  }, []);
};

function hasMemory(performance: Performance): performance is ExtendedPerformance {
  return "memory" in performance && performance.memory !== undefined;
}
