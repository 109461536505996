export enum CustomEventName {
  Example = "user-action",
  ShareFailed = "share-failed",
}

export interface CustomEventMap {
  [CustomEventName.Example]: {
    elementId: string;
    timestamp: number;
  };
  [CustomEventName.ShareFailed]: {
    email: string;
  };
}
