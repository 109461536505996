import styled from "@emotion/styled";

export const CopilotContainerText = styled.span(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));
