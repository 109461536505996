import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { PageBannerSection } from "@/components/banners/page-banner-section";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { MdsTextFilterField } from "@/design-system/components/text-filter-field/MdsTextFilterField";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { OnChange } from "@/design-system/constants/handlers/types";
import { ReactChangeEvent } from "@/design-system/constants/handlers/types";
import { useCallback } from "react";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { CollectionsFacetFilter } from "@/components/filters/CollectionsFacetFilter/CollectionsFacetFilter";
import { ContactsFacetFilter } from "@/components/filters/ContactsFacetFilter";

export const TemplatesListPageHeader = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.templatesListPage;

  const onTemplatesFilterChange: OnChange<ReactChangeEvent<HTMLInputElement>> = useCallback(
    event => {
      page.setTemplatesFilterQuery({ query: event.target.value });
    },
    [page]
  );

  const clearTemplatesFilterQuery = useCallback(() => {
    page.setTemplatesFilterQuery({ query: "" });
  }, [page]);

  const clearTemplatesFilterOnEscapeKeyPress = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    event => {
      event.stopPropagation();
      clearTemplatesFilterQuery();
    },
    [clearTemplatesFilterQuery]
  );

  return (
    <Wrapper>
      <MdsPanelHeader
        heading="Templates"
        icon={MdsIconKind.Template}
        actions={
          <MdsPanelResponsiveBtn
            threshold={mdsPanelBreakpoint}
            fullSizeLabel="Create template"
            fullSizeIcon={MdsIconKind.Plus}
            collapsedIcon={MdsIconKind.Plus}
            collapsedTooltipConfig={{
              label: "Create template",
              placement: MdsTooltipPlacement.Top,
              delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
            }}
            onClick={page.handleCreateNewTemplate}
          />
        }
        tabs={[
          {
            label: "All",
            onClick: () =>
              page.filters.setParams({
                lens: LensKind.All,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.filters.lens === LensKind.All,
          },
          {
            label: "Created by me",
            onClick: () =>
              page.filters.setParams({
                lens: LensKind.AddedByMe,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.filters.lens === LensKind.AddedByMe,
          },
          {
            label: "Shared with me",
            onClick: () =>
              page.filters.setParams({
                lens: LensKind.SharedWithMe,
                sortBy: SortByKind.LastModified,
              }),
            isActive: page.filters.lens === LensKind.SharedWithMe,
          },
        ]}
      />
      <WrapperBottom>
        <WrapperBottomLeft>
          <FiltersContainer>
            <MdsTextFilterField
              placeholder="Filter by name"
              value={page.templatesFilterQuery}
              onChange={onTemplatesFilterChange}
              onClearText={clearTemplatesFilterQuery}
              onEscapeKeyPress={clearTemplatesFilterOnEscapeKeyPress}
              autoFocus={false}
            />
            {renderSortedFilters(page.filters)}
          </FiltersContainer>
        </WrapperBottomLeft>
        <WrapperBottomRight>
          <MdsDropdownButton
            contentList={page.filters.sortOptions}
            label={page.filters.sortLabel}
            iconKind={MdsIconKind.AngleDown}
            iconPosition={MdsButtonIconPosition.Right}
            placement="below-right-alignment"
          />
        </WrapperBottomRight>
      </WrapperBottom>
      <PageBannerSection />
    </Wrapper>
  );
});

const renderSortedFilters = (pageFilters: NotesListFilter) => {
  const filters = [
    {
      isActive:
        pageFilters.selectedCollectionTitles.length > 0 ||
        pageFilters.collectionNoCollectionIsSelected,
      defaultOrder: 0,
      component: (
        <CollectionsFacetFilter
          key="collections"
          supportNoCollectionOption
          searchText={pageFilters.collectionsSearchQuery}
          items={pageFilters.collectionsFilterItems}
          onSearchTextChange={pageFilters.setCollectionSearchQuery}
          onSearchTextClear={pageFilters.clearCollectionSearchQuery}
          onSelectItem={pageFilters.toggleCollectionItem}
          onSelectClear={pageFilters.clearCollections}
          buttonPrefix={pageFilters.collectionNoCollectionIsSelected ? "" : "In"}
          buttonLabel="Collections"
          selectedModeKey={pageFilters.selectedCollectionModeKey}
          onSelectMode={pageFilters.onCollectionModeSelectionChange}
          noCollectionIsSelected={pageFilters.collectionNoCollectionIsSelected}
          onNoCollectionIsSelected={pageFilters.setCollectionNoCollectionIsSelected}
          selectedItems={pageFilters.selectedCollectionTitles}
        />
      ),
    },
    {
      isActive: pageFilters.modifiedBySearchItems.some(item => item.isSelected),
      defaultOrder: 3,
      component: (
        <ContactsFacetFilter
          key="modifiedBy"
          items={pageFilters.modifiedBySearchItems}
          searchText={pageFilters.modifiedBySearchQuery}
          onSearchTextChange={pageFilters.setModifiedBySearchQuery}
          onSearchTextClear={pageFilters.clearModifiedBySearchQuery}
          onSelectItem={pageFilters.toggleModifiedByItem}
          onSelectClear={pageFilters.clearModifiedBy}
          buttonPrefix="Edited by"
        />
      ),
    },
    {
      isActive: pageFilters.createdBySearchItems.some(item => item.isSelected),
      defaultOrder: 4,
      component: (
        <ContactsFacetFilter
          key="createdBy"
          items={pageFilters.createdBySearchItems}
          searchText={pageFilters.createdBySearchQuery}
          onSearchTextChange={pageFilters.setCreatedBySearchQuery}
          onSearchTextClear={pageFilters.clearCreatedBySearchQuery}
          onSelectItem={pageFilters.toggleCreatedByItem}
          onSelectClear={pageFilters.clearCreatedBy}
          buttonPrefix="Created by"
        />
      ),
    },
  ];

  return filters
    .sort((a, b) => {
      const activeSort = Number(b.isActive) - Number(a.isActive);
      if (activeSort !== 0) {
        return activeSort;
      }

      return a.defaultOrder - b.defaultOrder;
    })
    .map(filter => filter.component);
};

const Wrapper = styled.div({});

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
}));

const WrapperBottomLeft = styled.div({
  minWidth: 0,
});

const WrapperBottomRight = styled.div({
  flexShrink: 0,
});

const FiltersContainer = styled(FadeHorizontalScroll)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));
