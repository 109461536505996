import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { observer } from "mobx-react-lite";
import { OutboundSyncStatus, SyncState } from "@/components/sync/types";
import { PropsWithChildren } from "react";
import SyncRingImage from "@/assets/images/syncing-ring.svg";
import { useShowSpinner } from "@/components/sync/UseShowSyncing";

interface Props extends PropsWithChildren {
  syncState: SyncState;
  className?: string;
}

export const SyncAvatarIndicator = observer(({ syncState, children, className }: Props) => {
  const { status } = syncState;

  const showSpinner = useShowSpinner(status);
  const showIcon = status === OutboundSyncStatus.Paused;

  return (
    <Wrapper className={className}>
      {children}
      <Spinner src={SyncRingImage} alt="syncing" className={className} show={showSpinner} />
      <Icon kind={MdsIconKind.CloudSlashFlipped} className={iconStyles} show={showIcon} />
    </Wrapper>
  );
});

const Spinner = styled.img<{ show: boolean }>(({ show }) => ({
  position: "absolute",
  display: "block",
  width: "calc(100% + 8px)",
  height: "calc(100% + 8px)",
  top: -4,
  left: -4,
  animation: "spin 8s linear infinite",
  transition: "opacity 0.3s ease-in-out",
  opacity: show ? 1 : 0,

  "@keyframes spin": {
    "0%": {
      transform: "scale(1) rotate(0deg)",
    },
    "100%": {
      transform: "scale(1) rotate(360deg)",
    },
  },
}));

const Icon = styled(MdsIcon)<{ show: boolean }>(({ show }) => ({
  position: "absolute",
  top: -6,
  right: -6,
  opacity: show ? 1 : 0,
  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
  transform: show ? "scale(1)" : "scale(0.5)",
}));

/**
 * We can parametrize to support more sizes
 */
const iconStyles = css({
  color: mdsColors().mblue.x600,
  position: "absolute",
  top: -6,
  right: -6,
});

const Wrapper = styled.div({
  position: "relative",
});
