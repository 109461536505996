import { Uuid } from "@/domains/global/identifiers";
import { clientEnvClientGroupId } from "@/modules/client-env/clientGroupId";
import { uuidModule } from "@/modules/uuid";
import { isNull } from "lodash-es";

/**
 * Generated once on initial app boot.
 *
 * On web, this generates a unique ID per-browser-tab.
 * The client ID is scoped to the client group ID to ensure
 * proper multi-tab behavior.
 */
const UNIQUE_SYNC_CLIENT_INFO_MAPPING: {
  [clientGroupId: string]: {
    clientId: Uuid | null;
  };
} = {};

export const clientEnvClientId = async (): Promise<Uuid> => {
  /**
   * We generate our client ids using the current client group ID.
   *
   * If the client group id changes, it will force the generation of a new client id.
   *
   * (This is to ensure multi-tab works correctly - if one tab clears out the client group,
   * other tabs will have their client ids busted.)
   */
  const { clientGroupId } = await clientEnvClientGroupId();

  /**
   * If we don't have a key for this client group, we create one.
   */
  if (!UNIQUE_SYNC_CLIENT_INFO_MAPPING[clientGroupId]) {
    UNIQUE_SYNC_CLIENT_INFO_MAPPING[clientGroupId] = {
      clientId: null,
    };
  }

  /**
   * If no value is set, we generate a new client id.
   */
  if (isNull(UNIQUE_SYNC_CLIENT_INFO_MAPPING[clientGroupId].clientId)) {
    UNIQUE_SYNC_CLIENT_INFO_MAPPING[clientGroupId].clientId = uuidModule.generate();
  }

  return UNIQUE_SYNC_CLIENT_INFO_MAPPING[clientGroupId].clientId;
};

export const clearClientEnvClientId = (): void => {
  /**
   * We clear out -everything- in the mapping.
   */
  for (const key in UNIQUE_SYNC_CLIENT_INFO_MAPPING) {
    delete UNIQUE_SYNC_CLIENT_INFO_MAPPING[key];
  }
};
