import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { NoteSourceModelData } from "./types";
import { NoteSourceObservable } from "./NoteSourceObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreNoteSourceStore extends BaseSyncModelStore<
  NoteSourceObservable,
  NoteSourceModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.NoteSource, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<NoteSourceModelData>): NoteSourceObservable {
    return new NoteSourceObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
