import { observer } from "mobx-react-lite";
import { MdsDropdownButton } from "@/design-system/components/dropdown/MdsDropdownButton";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown/types";
import { css } from "@/domains/emotion";
import { mdsBorderRadius, mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { ChatContextChip } from "@/pages/chat/ChatContextChip";

interface AgentModeChipProps {
  isAgentMode: boolean;
  onToggleAgentMode: () => void;
  hasSomeContexts: boolean;
}

export const AgentModeChip = observer<AgentModeChipProps>(function AgentModeChip({
  isAgentMode,
  onToggleAgentMode,
  hasSomeContexts,
}) {
  const contentList: MdsDropdownContentList = {
    items: [
      {
        id: "dropdown-header",
        kind: MdsDropdownItemKind.Detail,
        text: "Mem will look at",
      },
      {
        id: "any-relevant-notes",
        kind: MdsDropdownItemKind.Button,
        label: "Any relevant notes",
        subtitle: "Allow Mem to gather information",
        isChecked: isAgentMode,
        onClick: () => {
          if (!isAgentMode) onToggleAgentMode();
        },
      },
      {
        id: "only-selected-notes",
        kind: MdsDropdownItemKind.Button,
        label: "Only selected notes",
        subtitle: "Focus Mem on specific information",
        isChecked: !isAgentMode,
        onClick: () => {
          if (isAgentMode) onToggleAgentMode();
        },
      },
    ],
  };

  if (!hasSomeContexts) {
    return (
      <MdsTooltip
        config={{
          label: "Mem will look at any notes relevant to your question",
          placement: MdsTooltipPlacement.Top,
          delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
        }}
      >
        <ChatContextChip title="Any relevant notes" />
      </MdsTooltip>
    );
  }
  return (
    <MdsDropdownButton
      contentList={contentList}
      label={() => <>{isAgentMode ? <span>And any relevant notes</span> : <span>Only</span>}</>}
      placement="above"
      iconKind={hasSomeContexts ? MdsIconKind.AngleDown : null}
      iconPosition={MdsButtonIconPosition.Right}
      buttonClassName={buttonStyles}
    />
  );
});

// make it look like a chip
const buttonStyles = css({
  fontWeight: mdsFontWeights().regular,
  backgroundColor: mdsColors().grey.x50,
  "&:hover": {
    background: mdsColors().grey.x100,
    color: mdsColors().grey.x700,
  },
  padding: "0 4px",
  margin: 0,
  borderRadius: mdsBorderRadius().medium,
  minHeight: "28px",
});
