import { getCollectionItemSubtitle } from "@/components/collection-item-subtitle/CollectionItemSubtitle";
import { MdsIconKind } from "@/design-system/components/icon";
import { Uuid } from "@/domains/global/identifiers";
import { MentionContent } from "@/store/chat/mention/MentionContent";
import { MentionSubtitle } from "@/store/chat/mention/MentionSubtitle";
import { MentionTitle } from "@/store/chat/mention/MentionTitle";
import { SmallerIcon } from "@/store/chat/mention/SmallerIcon";
import { smallerIconFontSizeStyles } from "@/store/chat/mention/smallerIconFontSizeStyles";
import { useAppStore } from "@/store/utils/hooks";
import { observer } from "mobx-react-lite";

export const CollectionMentionContent = observer<{
  id: Uuid;
  label: string;
}>(({ id, label }) => {
  const { store } = useAppStore();
  const collection = store.collections.get(id);
  return (
    <MentionContent>
      <MentionTitle>
        <span>{label}</span>
        {collection?.isShared && (
          <SmallerIcon
            kind={MdsIconKind.Shared}
            innerStyles={{ Icon: { className: smallerIconFontSizeStyles } }}
          />
        )}
      </MentionTitle>
      {collection && (
        <MentionSubtitle>
          {getCollectionItemSubtitle(collection.itemList.sizeData, "note")}
        </MentionSubtitle>
      )}
    </MentionContent>
  );
});
