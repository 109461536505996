import { actions } from "@/actions";
import { NoteEditorTopBarShareButton } from "@/components/note/editor/top-bar/NoteEditorTopBarShareButton";
import { OrganizeNote, useOrganizeNoteState } from "@/components/note/editor/top-bar/organize";
import { MdsDropdownIconButton } from "@/design-system/components/dropdown";
import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { TemplateIcon } from "@/design-system/components/item-list/rows/icon/template";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { MdsEditableTextField } from "@/design-system/components/text-field/MdsEditableTextField";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsFontSizes, mdsPanelBreakpoint } from "@/design-system/foundations";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

interface TemplateEditorTopBarProps {
  template?: TemplateObservable;
}

export const TemplateEditorTopBar = observer<TemplateEditorTopBarProps>(
  function TemplateEditorTopBar({ template }) {
    const { store, pageStore } = useAppStore();
    const page = pageStore.templatesViewPage;
    const organizeNoteState = useOrganizeNoteState();

    const templateTitleInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (template && !template.title) templateTitleInputRef.current?.focus();
    }, [template]);

    useEffect(() => {
      // Make sure to save the template when the component unmounts because blur is not guaranteed
      // to be called when navigating away from the page.
      return () => {
        if (template && !template.isDeleted) template.save();
      };
    }, [template]);

    if (!template) return null;

    const dropdownContentList: MdsDropdownContentList = {
      items: [
        {
          id: "copy-template-link",
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          iconKind: MdsIconKind.Link,
          onClick: () => actions.copyTemplateLinkToClipboard({ templateId: template.id }),
        },
        {
          id: "delete-template",
          kind: MdsDropdownItemKind.Button,
          label: "Delete",
          iconKind: MdsIconKind.Trash,
          onClick: () =>
            page.deleteTemplateModal.open({
              templateId: template.id,
            }),
        },
      ],
    };

    return (
      <Wrapper>
        <MdsPanelHeader
          icon={<TemplateIcon />}
          // We want to go back to a collection details page if we came from there so we goBack instead of goUp.
          onBackClick={store.navigation.goBack}
          heading={
            <MdsEditableTextField
              ref={templateTitleInputRef}
              value={template.title}
              placeholder={"Template name"}
              onFocus={() => template.lock()}
              onChange={e => template.setTitle(e.currentTarget.value)}
              onBlur={async () => await template.save()}
              onEnterKeyPress={async () => await template.save()}
              autoFocus={template?.title === ""}
              className={titleInputStyles}
              readOnly={!template.canWrite}
            />
          }
          headingTextProps={{
            size: MdsTextSize.Small,
            lineHeight: MdsTextSize.XSmall,
            weight: MdsTextWeight.Medium,
          }}
          subheading={
            template.collectionList.allCollections.length && (
              <MdsItemListRowFeaturedContent
                collections={sortCollectionsForChips(template.collectionList.allCollections)}
              />
            )
          }
          actions={
            <>
              <OrganizeNote note={template} {...organizeNoteState}>
                <MdsPanelResponsiveBtn
                  fullSizeLabel="Organize"
                  fullSizeIcon={MdsIconKind.Collection}
                  collapsedIcon={MdsIconKind.Collection}
                  collapsedTooltipConfig={{
                    label: "Organize",
                    placement: MdsTooltipPlacement.Top,
                    delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
                  }}
                  threshold={mdsPanelBreakpoint}
                  data-test-id="note-editor-top-bar-organize-button"
                  onClick={organizeNoteState.toggleOrganizeIsOpen}
                />
              </OrganizeNote>
              <NoteEditorTopBarShareButton
                note={template}
                shareSheetModal={pageStore.templatesViewPage.shareSheetModal}
              />
              <MdsDropdownIconButton
                placement="below-right-alignment"
                contentList={dropdownContentList}
              />
            </>
          }
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({});

const titleInputStyles = css({
  width: "100%",
  padding: 0,
  fontSize: mdsFontSizes().small,
  lineHeight: mdsFontSizes().small,
});
