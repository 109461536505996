import { MouseEvent, KeyboardEvent } from "react";
import { observer } from "mobx-react-lite";
import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import {
  MdsDropdownContent,
  MdsDropdownContentProps,
} from "@/design-system/components/dropdown/MdsDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { AppStore, useAppStore } from "@/store";
import { SyncModelKind } from "@/store/sync/types";
import { css, cx } from "@/domains/emotion";
import {
  CalculateSelectedItemIndex,
  RegisterSelectedItemHandler,
} from "@/store/pages/QuickSearchModalStore/QuickSearchModalStore";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { actions } from "@/actions";
import { isMac } from "@/domains/platform/isMac";
import { mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { CollectionsListPageStore } from "@/store/pages/CollectionsListPageStore";
import { DropdownAnimation } from "@/components/dropdown-animation";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable, NoteObservable } from "@/store/note";
import { generateRecentDateString } from "@/domains/date/date";
import { useLocation } from "react-router";
import { trackEvent, TrackedEvent } from "@/domains/metrics";

export interface RecentsListProps {
  className?: string;
  extraContentListItems?: MdsDropdownItem[];
  header?: string;
  itemClassName?: string;
  selectedItemClassName?: string;
  kind?: SyncModelKind;
  recentsList?: (INoteObservable | CollectionObservable)[];
  limit?: number;
  animateOpen?: boolean;
  animateItems?: boolean;
  onClick?: () => void;
  onHover?: MdsDropdownContentProps["onHover"];
  calculateSelectedItemIndex?: CalculateSelectedItemIndex;
  registerSelectedItemHandler?: RegisterSelectedItemHandler;
  excludeCurrentItem?: boolean;
}

export const RecentsList = observer<RecentsListProps>(function RecentItems({
  className,
  extraContentListItems = [],
  header,
  itemClassName,
  selectedItemClassName,
  kind,
  recentsList,
  limit = 5,
  animateOpen = false,
  animateItems = false,
  onClick,
  onHover,
  calculateSelectedItemIndex,
  registerSelectedItemHandler,
  excludeCurrentItem = false,
}) {
  const location = useLocation();
  const { store, pageStore } = useAppStore();
  const page = pageStore.collectionsListPage;

  /**
   * We use state here [instead of observing the store] because we don't want to
   * update the UI while the recents UI while this component is open.
   *
   * (e.g. if someone selects a Note, we don't want the recent items list to update
   * while the list is fading out).
   */
  const recentItems = useMemo<(INoteObservable | CollectionObservable)[]>(() => {
    // This is reactive to the observables array.
    if (recentsList) return recentsList;

    if (kind === SyncModelKind.SpaceAccountCollection) {
      return store.recentItems.sortedRecentCollections;
    }

    if (kind === SyncModelKind.SpaceAccountNote) {
      return store.recentItems.sortedRecentNotesInteractedWithByMe;
    }

    return store.recentItems.sortedRecentItems;

    /** This is intentional. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind, recentsList]);

  const contentList: MdsDropdownContentList = useMemo(() => {
    const filteredItems = !excludeCurrentItem
      ? recentItems
      : recentItems.filter(item => getFilteredRecentsList(item, store));

    const slicedItems = filteredItems.slice(0, limit);

    const selectedItemIndex = calculateSelectedItemIndex?.({ rowCount: slicedItems.length });

    let clearSelected = true;
    try {
      if (slicedItems.length === 0) {
        return getEmptyList(kind, header, page);
      }

      return {
        items: [
          {
            id: "header",
            text: header ?? "Recents",
            kind: MdsDropdownItemKind.Detail,
          },
          ...slicedItems.map((item, index) => {
            const isSelected =
              typeof selectedItemIndex === "number"
                ? selectedItemIndex === index
                : selectedItemIndex?.itemId === item.id;

            const isActive = item.path === location.pathname + location.search;

            const combinedItemClassName = cx(
              itemClassName,
              (isSelected || isActive) && selectedItemClassName
            );

            const onClickHandler = ({
              event,
            }: {
              event?: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>;
            }) => {
              if (item instanceof NoteObservable) {
                if (isMac() ? event?.metaKey : event?.ctrlKey) {
                  actions.openNoteInNewTab({ noteId: item.id });
                } else {
                  const navigator = event?.altKey ? store.sidePanel : store.navigation;
                  navigator.goToNote({ noteId: item.id, resetStack: true });
                  onClick?.();
                }

                /**
                 * Track the navigation to a note
                 */
                trackEvent(TrackedEvent.QuickSearchOpenNote, {
                  query: "",
                  position: index,
                  note_id: item.id,
                  note_primary_label: store.notes.get(item.id)?.title,
                });
              }
              if (item instanceof CollectionObservable) {
                if (isMac() ? event?.metaKey : event?.ctrlKey) {
                  actions.openCollectionInNewTab({ collectionId: item.id });
                } else {
                  store.navigation.goToCollection({ collectionId: item.id });
                  onClick?.();
                }

                /**
                 * Track the navigation to a collection
                 */
                trackEvent(TrackedEvent.QuickSearchOpenCollection, {
                  query: "",
                  position: index,
                  collection_id: item.id,
                  collection_primary_label: store.collections.get(item.id)?.label,
                });
              }
            };

            if (isSelected && registerSelectedItemHandler) {
              clearSelected = false;
              setTimeout(registerSelectedItemHandler, 0, {
                handler: onClickHandler,
                itemId: item.id,
              });
            }

            const iconKind: MdsIconKind | undefined = (() => {
              if (item instanceof NoteObservable) return MdsIconKind.Document;
              if (item instanceof CollectionObservable) return MdsIconKind.Collection;
              return undefined;
            })();

            const selectedLabelDetail = generateRecentDateString(
              DateTime.fromISO(item.createdAt || ""),
              { skipFullDayName: true }
            );

            const button: MdsDropdownButtonItem = {
              id: item.id,
              kind: MdsDropdownItemKind.Button,
              label: item instanceof CollectionObservable ? item.label : item.title,
              isSelected,
              selectedLabelDetail,
              iconKind,
              className: combinedItemClassName,
              onClick: onClickHandler,
              iconSize: 16,
              animate: animateItems,
            };
            return button;
          }),
          ...extraContentListItems,
        ],
      };
    } finally {
      if (clearSelected && registerSelectedItemHandler) {
        setTimeout(registerSelectedItemHandler, 0, {});
      }
    }
  }, [
    animateItems,
    calculateSelectedItemIndex,
    extraContentListItems,
    header,
    itemClassName,
    kind,
    limit,
    location.pathname,
    location.search,
    onClick,
    page,
    recentItems,
    registerSelectedItemHandler,
    selectedItemClassName,
    store,
    excludeCurrentItem,
  ]);

  return (
    <DropdownAnimation skipAnimation={!animateOpen}>
      <MdsDropdownContent contentList={contentList} className={className} onHover={onHover} />
    </DropdownAnimation>
  );
});

// TODO: Consider implementing shared styles
const emptyListCommonStyles = {
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().small,
  whiteSpace: "wrap",
  lineHeight: "20px",
  padding: "0 8px 8px 8px",
};

const emptyListTextStyles = css({
  ...emptyListCommonStyles,
});

const addCollectionStyles = css({
  ...emptyListCommonStyles,
  textDecoration: "underline",
  cursor: "pointer",
});

const getEmptyList = (
  kind: SyncModelKind | undefined,
  header: string | undefined,
  page: CollectionsListPageStore
): MdsDropdownContentList => {
  const list: MdsDropdownContentList = {
    items: [
      {
        id: "header",
        text: header ?? "Recents",
        kind: MdsDropdownItemKind.Detail,
      },
    ],
  };

  if (kind === SyncModelKind.SpaceAccountCollection) {
    list.items.push(
      {
        id: "list",
        kind: MdsDropdownItemKind.Other,
        content: (
          <div className={emptyListTextStyles}>Your recently accessed collections appear here</div>
        ),
      },
      {
        id: "add-collection",
        kind: MdsDropdownItemKind.Other,
        content: (
          <div className={addCollectionStyles}>
            {/* TODO: Consider promoting to a dedicated component */}
            <a onClick={async () => await page.handleCreateNewCollection()}>Create a collection</a>
          </div>
        ),
      }
    );
  } else if (kind === SyncModelKind.SpaceAccountNote) {
    list.items.push({
      id: "list",
      kind: MdsDropdownItemKind.Other,
      content: (
        <div className={emptyListTextStyles}>
          Your recently viewed notes and collections appear here
        </div>
      ),
    });
  }

  return list;
};

const getFilteredRecentsList = (item: INoteObservable | CollectionObservable, store: AppStore) => {
  const { currentItemId } = store.navigation;
  if (item.id !== currentItemId) {
    return true;
  }

  if (item instanceof NoteObservable && store.navigation.isCurrentlyOnNotePage) {
    return false;
  }

  if (item instanceof CollectionObservable && store.navigation.isCurrentlyOnCollectionPage) {
    return false;
  }

  return true;
};
