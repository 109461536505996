import EmptySearch from "@/assets/images/empty-search.svg";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";

interface SearchPageNotesListEmptyProps {
  query: string;
  isSuccess: boolean;
  anyFiltersSelected: boolean;
  onRetryClick: () => void;
}

export function SearchPageNotesListEmpty({
  query,
  isSuccess,
  anyFiltersSelected,
  onRetryClick,
}: SearchPageNotesListEmptyProps): JSX.Element {
  return (
    <EmptyContainer>
      <img src={EmptySearch} alt="No results" />
      <EmptyText>{getEmptyText(query, isSuccess, anyFiltersSelected)}</EmptyText>
      {!isSuccess && (
        <MdsButton
          label="Retry"
          variant={MdsButtonVariant.FilledDark}
          onClick={onRetryClick}
          iconKind={MdsIconKind.Redo}
        />
      )}
    </EmptyContainer>
  );
}

const getEmptyText = (query: string, isSuccess: boolean, anyFiltersSelected: boolean): string => {
  if (!isSuccess) {
    return getErrorText(query);
  }

  if (anyFiltersSelected) {
    return "No results matching the selected filters";
  }

  if (query) {
    return "No results for " + query;
  }

  return "No results matching the selected filters";
};

const getErrorText = (query: string): string => {
  if (query) {
    return "Could not load results for " + query;
  }

  return "Could not load results";
};
