import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";

export interface FloatingActionsProps extends React.PropsWithChildren {
  isOpen: boolean;
}

export const FloatingActions = memo<FloatingActionsProps>(function FloatingActions({
  isOpen,
  children,
}) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={wrapperStyles}
          initial={{ bottom: -65 }}
          animate={{ bottom: 10 }}
          exit={{ bottom: -65 }}
          transition={{
            duration: 0.2,
            // cubic-bezier
            ease: [0.34, 1.56, 0.64, 1],
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});

const wrapperStyles = css({
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
  position: "fixed",
  zIndex: ZIndex.FloatingActions,
  // Center horizontally without blocking clicks on the sides
  width: "fit-content",
  left: 0,
  right: 0,
  marginInline: "auto",
});
