import { NodeEnvironment } from "@/domains/common/types";
import {
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_SITE,
  DATADOG_SERVICE_NAME,
} from "@/domains/datadog/constants";
import { getBackendAnalyticsBaseUrl } from "@/manager/backend-api-client/initializeBackendApiManager";
import { datadogLoggingClientManager } from "@/manager/datadog-logging-client";
import { clientEnvModule } from "@/modules/client-env";
import { AppStore } from "@/store/AppStore";
import { datadogRum } from "@datadog/browser-rum";

export const initializeAccountObservability = async ({ store }: { store: AppStore }) => {
  const nodeEnv = clientEnvModule.nodeEnv();
  const packageVersion = clientEnvModule.packageVersion();
  const datadogLogger = datadogLoggingClientManager.instance();
  const { buildInfo } = clientEnvModule.buildInfo();
  const version = `${packageVersion}-${buildInfo.gitCommitSha}`;

  const { id, profileEmailAddress, profileDisplayName } = store.account.myAccount;

  datadogLogger.setUser({
    id,
    email: profileEmailAddress,
    name: profileDisplayName,
  });

  if (nodeEnv === NodeEnvironment.Production) {
    datadogRum.init({
      applicationId: DATADOG_RUM_APPLICATION_ID,
      clientToken: DATADOG_RUM_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_SERVICE_NAME,
      env: nodeEnv,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      proxy: `${getBackendAnalyticsBaseUrl()}/v2/analytics/datadog/proxy`,
    });

    datadogRum.setUser({
      id,
      email: profileEmailAddress,
      name: profileDisplayName,
    });

    datadogRum.startSessionReplayRecording();
  }
};
