import {
  MdsItemKind,
  MdsItemListAsyncThreadRowData,
  MdsItemListRowData,
} from "@/design-system/components/item-list/types";
import { MdsItemListRowType, MdsItemListSize } from "@/design-system/components/item-list/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  reaction,
  runInAction,
} from "mobx";
import { ThreadObservable } from "@/store/threads/ThreadObservable";
import { Maybe } from "@/domains/common/types";
import { SortByKind } from "@/modules/lenses/types";
import { liveQuery, Subscription } from "dexie";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { EventContext } from "@/domains/metrics/context";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { ThreadRecord } from "@/store/threads/types";

export class ThreadsListPageStore extends AppSubStore {
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  threadsFilterQuery: string = "";

  isLoading: boolean;
  subscriptionInitialized: boolean;
  liveQuerySubscription: Maybe<Subscription> = undefined;
  subscribedThreadRows: ThreadRecord[] = [];

  eventContext = EventContext.TemplatesMultiselectActions;

  filters: NotesListFilter;

  private readonly supportedSortOptions: SortByKind[] = [
    SortByKind.LastCreated,
    SortByKind.LastModified,
    SortByKind.LastViewed,
    SortByKind.LastShared,
    SortByKind.LastUsed,
    SortByKind.Alphabetical,
  ];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    // TODO: This needs to become a ThreadsListFilter
    this.filters = new NotesListFilter(this.store, this.supportedSortOptions);

    this.isLoading = true;
    this.subscriptionInitialized = false;

    makeObservable<this, "generateItemDropdown" | "initializeLiveQuery" | "supportedSortOptions">(
      this,
      {
        isEmpty: true,
        mapThreadToRow: true,
        resetState: true,
        supportedSortOptions: true,
        initializeLiveQuery: action,
        liveQuerySubscription: observable,
        subscriptionInitialized: observable,

        subscribedThreadRows: observable,
        itemRows: computed,

        supportsAddToCollectionListAction: observable,
        supportsMoveToTrashListAction: observable,
        threadsFilterQuery: observable,
        listState: false,
        eventContext: false,

        isLoading: observable,
        generateItemDropdown: false,
        orderedItemIds: computed,

        initialize: action,
        filters: false,
      }
    );

    reaction(
      () => this.subscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );

    onBecomeObserved(this, "subscribedThreadRows", () => this.initializeLiveQuery());
  }

  get isEmpty() {
    return this.subscribedThreadRows.length === 0;
  }

  get itemRows() {
    const output: MdsItemListRowData[] = [];

    for (const item of this.subscribedThreadRows) {
      output.push(this.mapThreadToRow(item.id));
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  mapThreadToRow(threadId: string): MdsItemListAsyncThreadRowData {
    return {
      type: MdsItemListRowType.AsyncThread,
      key: threadId,
      size: MdsItemListSize.XLarge,
      payload: {
        threadId,
        itemRow: (thread: ThreadObservable) => {
          return {
            type: MdsItemListRowType.Item,
            key: thread.id,
            size: MdsItemListSize.XLarge,
            payload: {
              id: thread.id,
              kind: MdsItemKind.Thread,
              label: thread.id,
              onClick: () => this.store.navigation.goToThread({ threadId }),
              listState: this.listState,
            },
          };
        },
      },
    };
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  resetState = () => {};

  initialize = async () => {
    this.initializeLiveQuery();
  };

  initializeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();

    this.liveQuerySubscription = liveQuery(() => {
      return this.store.threads.localTable.toCollection().keys();
    }).subscribe({
      next: items => {
        runInAction(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.subscribedThreadRows = items.map((item: any) => ({ id: item }));
          this.subscriptionInitialized = true;
        });
      },
    });
  }
}
