import { observer } from "mobx-react-lite";
import { MdsButton } from "@/design-system/components/button";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { useState } from "react";
import { useCallback } from "react";

export const SettingsSubscriptionManagementPage = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.settingsSubscriptionManagementPage;
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnMount(() => {
    if (page.hasActiveSubscription) page.fetchBillingPortalUrl();
  });

  const handleOpenBillingPortal = useCallback(async () => {
    setIsLoading(true);
    await page.openBillingPortal();
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
  }, [page]);

  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Manage your subscription">
        <div className={sectionStyles}>
          <MdsText size={MdsTextSize.Small} color={mdsColors().grey.x700}>
            Mem 2.0 is free during the Alpha period.
          </MdsText>
          {page.hasActiveSubscription && (
            <>
              <MdsText size={MdsTextSize.Small} color={mdsColors().grey.x700}>
                You can manage your Mem 1.0 subscription using the button below.
              </MdsText>
              <MdsButton
                label="Manage Mem 1.0 subscription"
                onClick={handleOpenBillingPortal}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

const sectionStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 16,
});
