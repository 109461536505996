import { MdsIconKind } from "@/design-system/components/icon";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import MemLogo from "@/assets/images/mem-logo-bw.svg";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { Link } from "react-router-dom";
import { appRoutes } from "@/app/router";
import { clientEnvModule } from "@/modules/client-env";
import { FC } from "react";
import { SIDEBAR_VARIANTS, SidebarVariant } from "@/components/layout/components/sidebar/constants";
import { css } from "@/domains/emotion";
import { AnimatePresence, motion } from "framer-motion";
import { isMac } from "@/domains/platform/isMac";

interface TopBarSidebarSectionProps {
  isNarrowView: boolean;
  currentSidebarVariant: SidebarVariant;
  shouldHideSidebarToggle: boolean;
  toggleMenu: () => void;
  toggleSidebar: () => void;
  navigateBack: () => void;
  navigateForward: () => void;
  canNavigateBack: boolean;
  canNavigateForward: boolean;
}

export const TopBarSidebarSection: FC<TopBarSidebarSectionProps> = observer(
  ({
    currentSidebarVariant,
    shouldHideSidebarToggle,
    toggleMenu,
    toggleSidebar,
    navigateBack,
    navigateForward,
    canNavigateBack,
    canNavigateForward,
  }) => {
    const isDesktop = clientEnvModule.isDesktop();
    const isMacOS = isMac();
    const isCollapsedView = currentSidebarVariant === SidebarVariant.Collapsed;
    const isSuperMiniView = currentSidebarVariant === SidebarVariant.Hidden;

    const macOSTrafficLightsSpacerOrWindowsHamburgerMenu = isMacOS ? (
      <StoplightSpacer />
    ) : (
      <HamburgerButton iconKind={MdsIconKind.Menu} onClick={toggleMenu} />
    );

    if (isDesktop) {
      if (isSuperMiniView) {
        return (
          <DesktopWrapper
            style={{
              maxWidth: 80,
            }}
          >
            <LeftSection>{macOSTrafficLightsSpacerOrWindowsHamburgerMenu}</LeftSection>
            <RightSection />
          </DesktopWrapper>
        );
      }

      return (
        <DesktopWrapper
          style={{
            width: isCollapsedView ? "auto" : "100%",
            maxWidth: 240,
          }}
        >
          <LeftSection>
            {macOSTrafficLightsSpacerOrWindowsHamburgerMenu}
            <MdsIconButton
              iconKind={MdsIconKind.Sidebar}
              onClick={toggleSidebar}
              className={shouldHideSidebarToggle ? hiddenDesktopIconButtonStyles : undefined}
            />
          </LeftSection>
          <RightSection>
            <>
              <MdsIconButton
                iconKind={MdsIconKind.LeftArrow}
                onClick={navigateBack}
                isDisabled={!canNavigateBack}
              />
              <MdsIconButton
                iconKind={MdsIconKind.RightArrow}
                onClick={navigateForward}
                isDisabled={!canNavigateForward}
              />
            </>
          </RightSection>
        </DesktopWrapper>
      );
    }

    return (
      <motion.div
        layout
        initial={false}
        animate={currentSidebarVariant}
        variants={SIDEBAR_VARIANTS}
        style={{ overflow: "hidden", flexShrink: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <AnimatePresence mode="wait">
          {currentSidebarVariant === SidebarVariant.Collapsed ? (
            <WebCollapsedWrapper>
              <MdsIconButton
                iconKind={MdsIconKind.Sidebar}
                onClick={toggleSidebar}
                className={shouldHideSidebarToggle ? hiddenIconButtonStyles : undefined}
              />
            </WebCollapsedWrapper>
          ) : (
            <WebExpandedWrapper>
              <Link to={appRoutes.notesList({}).path}>
                <Logo src={MemLogo} alt="Mem Logo" />
              </Link>

              <MdsIconButton
                iconKind={MdsIconKind.Sidebar}
                onClick={toggleSidebar}
                className={shouldHideSidebarToggle ? hiddenIconButtonStyles : undefined}
              />
            </WebExpandedWrapper>
          )}
        </AnimatePresence>
      </motion.div>
    );
  }
);

const DesktopWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: 8,
});

const LeftSection = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
});

const RightSection = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
});

const WebExpandedWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  paddingRight: 8,
  maxWidth: 236,
});

const WebCollapsedWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 8,
  width: 42,
});

const Logo = styled.img({
  marginTop: 8,
  marginLeft: 16,
  height: 20,
});

const StoplightSpacer = styled.div({
  height: 20,
  width: 80,
});

const hiddenDesktopIconButtonStyles = css({
  display: "none",
});

/**
 * On web, we keep this around so it animates correctly.
 */
const hiddenIconButtonStyles = css({
  opacity: 0,
  pointerEvents: "none",
});

const HamburgerButton = styled(MdsIconButton)({
  marginLeft: 8,
  marginRight: 16,
});
