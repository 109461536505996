import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, FC } from "react";

import { ClassNamesArg, css, cx, keyframes } from "@/domains/emotion";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { iconDefinitionMapping } from "@/design-system/components/icon/iconDefinitionMapping";
import { mdsColors } from "@/design-system/foundations";
import cloudSlashFlippedImage from "@/assets/images/cloud-slash-flipped.svg";
import codeBlockImage from "@/assets/images/code-block.svg";

export interface MdsIconStylingProps {
  className?: ClassNamesArg;
  innerStyles?: {
    Icon?: {
      className?: ClassNamesArg;
    };
  };
}

export interface MdsIconProps extends MdsIconStylingProps {
  kind: MdsIconKind;
}

// Will override FA icons
const customIcons: Partial<Record<MdsIconKind, string>> = {
  [MdsIconKind.CloudSlashFlipped]: cloudSlashFlippedImage,
  [MdsIconKind.CodeBlock]: codeBlockImage,
};

export const MdsIcon: FC<MdsIconProps> = memo(({ kind, className, innerStyles }) => {
  return (
    <div className={cx(wrapperStyles, className)}>
      <Icon kind={kind} innerStyles={innerStyles} />
    </div>
  );
});

const Icon = ({ kind, innerStyles }: MdsIconProps) => {
  const iconDefinition = iconDefinitionMapping[kind];

  if (kind in customIcons) {
    return <img src={customIcons[kind]} className={cx(fsStyles, innerStyles?.Icon?.className)} />;
  }

  return (
    <FontAwesomeIcon
      icon={iconDefinition}
      className={cx(
        fsStyles,
        innerStyles?.Icon?.className,
        (kind === MdsIconKind.Spinner || kind === MdsIconKind.SpinnerSolid) && spinnerStyles
      )}
    />
  );
};

const wrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  height: 16,
  width: 16,
  color: mdsColors().grey.x700, // Applied here to allow .mds-btn-icon to override style
});

const fsStyles = css({
  fontSize: "14px",
  lineHeight: 1,
  userSelect: "none",
  draggable: "false",
});

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
});

const spinnerStyles = css({
  animation: `${spin} 1s linear infinite`,
});
