import { useCallback, useRef } from "react";
import { usePublicAppStore } from "@/store";

const TAP_THRESHOLD_MS = 1500; // Time window for taps
const REQUIRED_TAPS = 5;

interface DebugModeTapDetectorProps {
  children: React.ReactNode;
}

export const DebugModeTapDetector: React.FC<DebugModeTapDetectorProps> = ({ children }) => {
  const { publicStore } = usePublicAppStore();
  const tapsRef = useRef<number[]>([]);

  const handleTap = useCallback(() => {
    const now = Date.now();
    tapsRef.current = [...tapsRef.current, now].filter(tap => now - tap < TAP_THRESHOLD_MS);

    if (tapsRef.current.length >= REQUIRED_TAPS) {
      publicStore.debug.toggleDebugMode();
      tapsRef.current = []; // Reset taps after activation
    }
  }, [publicStore.debug]);

  return <div onClick={handleTap}>{children}</div>;
};
