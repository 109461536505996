import { api } from "@/modules/api";
import { GuestAppStore } from "@/store/GuestAppStore";
import { SpaceAccountContactSyncUpdate } from "@/store/contacts/types";
import { NoteSyncUpdate } from "@/store/note/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import { FetchValue } from "@/store/queries/types";
import { AppStoreBaseSyncStore } from "@/store/sync/AppStoreBaseSyncStore";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { GuestSyncActionQueue } from "@/store/sync/GuestSyncActionQueue";
import {
  GuestListSyncUpdatesResponse,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
} from "@/store/sync/types";

export class AppStoreGuestSyncStore extends AppStoreBaseSyncStore<
  GuestAppStore,
  GuestSyncActionQueue,
  GuestListSyncUpdatesResponse
> {
  public stores: Record<
    SyncModelKind,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BaseSyncModelStore<any, SyncModelData> | undefined
  > = {
    CHAT_CONVERSATION: undefined,
    CHAT_MESSAGE: undefined,
    COLLECTION: undefined,
    COLLECTION_ITEM: undefined,
    COLLECTION_METADATA: undefined,
    CONTACT: undefined,
    FAVORITE_ITEM: undefined,
    NOTE: undefined,
    NOTE_CONTENT_DOCUMENT: undefined,
    SAVED_SEARCH: undefined,
    SPACE_ACCOUNT_CHAT_MESSAGE: undefined,
    SPACE_ACCOUNT_COLLECTION: undefined,
    SPACE_ACCOUNT_CONTACT: undefined,
    SPACE_ACCOUNT_NOTE: undefined,
    SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG: undefined,
    SPACE_ACCOUNT_TEMPLATE: undefined,
    SPACE_ACCOUNT_TOPIC: undefined,
    SPACE_ACCOUNT_TOPIC_ITEM: undefined,
    TEMPLATE: undefined,
    TEMPLATE_CONTENT_DOCUMENT: undefined,
    DATA_IMPORT: undefined,
    DATA_IMPORT_ITEM: undefined,
    DATA_EXPORT: undefined,
    SPACE_ACCOUNT_APP_CALLOUT: undefined,
    API_KEY: undefined,
    THREAD: undefined,
    THREAD_EVENT: undefined,
    SOURCE: undefined,
    NOTE_SOURCE: undefined,
    UPLOADED_FILE: undefined,
    UPLOADED_FILE_BATCH: undefined,
  };

  get spaceId() {
    return this.store.guestAccount.myPersonalSpaceAccountId;
  }

  createSyncActionQueue(): GuestSyncActionQueue {
    return new GuestSyncActionQueue({
      getSpaceId: () => this.spaceId,
      api: this.api,
      pusher: this.pusher,
      store: this.store,
    });
  }

  get syncQuery() {
    const id = `sync-updates`;
    type QueryValue = GuestListSyncUpdatesResponse;
    const fetchValue: FetchValue<QueryValue> = async signal => {
      if (signal.aborted) return;
      console.debug("[SYNC][AppStoreGuestNoteStore] Querying for sync actions...");

      /**
       * Guest mode support is not implemented yet.
       * For now, mocking this out with the "normal" submission endpoint.
       * We'll need to update this in the future when guest mode support is implemented.
       */
      const response = await api.get("/v2/sync-updates", {
        params: {
          query: {
            last_sync_id: this.lastSyncId,
            space_id: this.spaceId,
          },
        },
      });

      if (signal.aborted) return;
      if (response.error)
        throw new Error("[SYNC][AppStoreGuestNoteStore] syncQuery error: " + response.error);
      /**
       * @TODO - Once the API is updated to return the correct response,
       * we can remove the type assertion.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { data: response.data as any };
    };

    const createQuery = () =>
      new QueryObservable<QueryValue>({
        auth: this.store.auth,
        queriesCache: this.store.queriesCache,
        id,
        refreshInterval: this.pollingInterval,
        fetchValue,
      });

    return this.store.queriesCache.get<QueryValue>(id, createQuery);
  }

  async finishProcessingQueryResponse() {}

  async processSyncUpdate(update: SyncUpdate<SyncModelData>): Promise<void> {
    switch (update.value.model_kind) {
      case "CONTACT":
        await this.store.contacts.processSyncUpdate(update as SpaceAccountContactSyncUpdate);
        break;
      case "NOTE":
        await this.store.note.processSyncUpdate(update as NoteSyncUpdate);
        break;
    }
  }

  subscribe() {}
  unsubscribe() {}

  async fetchAndSaveBootstrapEvents() {
    // TODO: Implement
    return { lastSyncId: undefined, count: 0 };
  }

  async bulkProcessLocal() {
    // TODO: Implement
  }
}
