import { Uuid } from "@/domains/global/identifiers";
import { IntervalObservable } from "@/store/common";
import { DataExportSyncModelData } from "@/store/data-exports/types";

import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";

import { WithAppStore } from "@/store/types";
import { Duration } from "luxon";
import { computed, makeObservable, observable } from "mobx";

export class DataExportObservable extends BaseSyncModel<DataExportSyncModelData> {
  public modelKind = SyncModelKind.DataExport;

  _isRunningInterval: IntervalObservable;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<DataExportSyncModelData>;
  } & WithAppStore) {
    super({ id, data, store });

    this._isRunningInterval = new IntervalObservable({
      duration: Duration.fromObject({ seconds: 5 }),
    });

    makeObservable(this, {
      endedAt: true,
      startedAt: true,
      _isRunningInterval: observable,
      modelKind: observable,
      isRunning: computed,
    });
  }

  get startedAt() {
    return new Date(this.modelData.started_at);
  }

  get endedAt() {
    return this.modelData.ended_at ? new Date(this.modelData.ended_at) : undefined;
  }

  get isRunning() {
    // If it hasn't ended yet, then it's still running
    if (!this.endedAt) {
      return true;
    }
  }
}
