import { ShareSheetModalFailedRow } from "@/components/modal/share-sheet/failed/ShareSheetModalFailedRow";
import { CustomEventName, useCustomEvent } from "@/modules/custom-event";
import styled from "@emotion/styled";
import { useState } from "react";

export const ShareSheetModalFailedContainer = () => {
  const [failedRows, setFailedRows] = useState<string[]>([]);

  useCustomEvent(CustomEventName.ShareFailed, ({ email }) => {
    const message = `${email} is not on Mem 2.0`;
    if (!failedRows.includes(message)) {
      setFailedRows([...failedRows, message]);
    }
  });

  const dismissRow = (row: string) => {
    setFailedRows(failedRows.filter(r => r !== row));
  };

  return (
    <Wrapper>
      {failedRows.map(row => (
        <ShareSheetModalFailedRow key={row} message={row} onDismiss={() => dismissRow(row)} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  marginTop: theme.spacing.sm,
}));
