import styled from "@emotion/styled";
import { CalendarHeaderCell } from "react-aria-components";

export const StyledCalendarHeaderCell = styled(CalendarHeaderCell)`
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey.x500};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
