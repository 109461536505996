import { moduleFactory } from "@/factories/module";
import {
  electronBindingNavigateToExternalUrl,
  electronBindingNavigateToInternalUrl,
  electronBindingFindInPage,
  electronBindingStopFindInPage,
  electronBindingOnFindInPageResult,
  electronBindingToggleMenu,
  electronBindingUpdateNow,
  electronBindingMinimizeWindow,
  electronBindingMaximizeWindow,
  electronBindingCloseWindow,
} from "@/modules/electron/bindings";

export const electronModule = moduleFactory.create({
  navigateToInternalUrl: electronBindingNavigateToInternalUrl,
  navigateToExternalUrl: electronBindingNavigateToExternalUrl,
  findInPage: electronBindingFindInPage,
  stopFindInPage: electronBindingStopFindInPage,
  onFindInPageResult: electronBindingOnFindInPageResult,
  toggleMenu: electronBindingToggleMenu,
  minimizeWindow: electronBindingMinimizeWindow,
  maximizeWindow: electronBindingMaximizeWindow,
  closeWindow: electronBindingCloseWindow,
  updateNow: electronBindingUpdateNow,
});
