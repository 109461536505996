import { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { APP_CONFIG } from "@/domains/global/config";
import { NodeEnvironment } from "@/domains/common/types";
import { clientEnvModule } from "@/modules/client-env";
import { windowModule } from "@/modules/window";
import { localStorageModule } from "@/modules/local-storage";

/**
 * If the following criteria is true:
 * - User is in the main production environment (mem.ai)
 * - User is on the root page (https://mem.ai/)
 * - User has never logged in before (they are a new user to the platform)
 *
 * Then we redirect them to the `LandingPageUrl`.
 *
 * The goal here is that we would like to give new users some more information
 * about mem (and our landing page has a little more information about the product.)
 *
 * By default, we disable this in dev mode (because the assumption is that the user
 * will always want to hit the login page, and not be redirected to the landing page.)
 */
export const useRedirectToLandingPage = () => {
  const isRootPage = useMatch({
    path: "/",
    end: true,
  });

  const isLoginPage = useMatch("/login/*");

  useEffect(() => {
    if (clientEnvModule.isDesktop()) return;

    if (isLoginPage) {
      return localStorageModule.recordVisitingLoginPage();
    }

    /**
     * If we are in the main production environment (https://mem.ai), and we are on
     * the root `/` page, and we have never logged in before (so we are a brand new
     * user to the platform), then we redirect the user to the production page.
     */
    const nodeEnv = clientEnvModule.nodeEnv();
    const isMainProdEnv = nodeEnv === NodeEnvironment.Production;

    if (isMainProdEnv && isRootPage && !localStorageModule.hasPreviouslyVisitedLoginPage()) {
      windowModule.navigateToExternal({ url: new URL(APP_CONFIG.URLS.LANDING_PAGE) });
    }
  }, [isLoginPage, isRootPage]);
};
