import { ErrorOverlay } from "@/components/overlay/ErrorOverlay";
import {
  PublicAppStore,
  useAppStore,
  useInitializeAppStore,
  useInitializeGuestAppStore,
  usePublicAppStore,
} from "@/store";
import { QuickSearchModal } from "@/store/pages/SearchPageStore/QuickSearchModal/QuickSearchModal";
import { AppStoreContext, GuestAppStoreContext } from "@/store/utils/context";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { LoadingOverlay } from "@/components/overlay";
import { SyncStoreInitializationChecker } from "@/app/overlays/SyncStoreInitializationChecker";
import { BootstrapOverlay } from "@/app/overlays/BootstrapOverlay";
import { LogoutOverlay } from "@/app/overlays/LogoutOverlay";
import { UpgradedUserEmailChecker } from "@/app/overlays/UpgradedUserEmailChecker";

interface OutletLoadingHandlers {
  setIsReady: () => void;
  setIsError: () => void;
  setLoadingMessage: (message: string) => void;
}

interface StoreProviderOutletProps {}

export const StoreProviderOutlet: FC<StoreProviderOutletProps> = observer(() => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);
  const [showErrorOverlay, setShowErrorOverlay] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");

  const setIsReady = () => setShowLoadingOverlay(false);
  const setIsError = () => setShowErrorOverlay(true);

  return (
    <>
      <ErrorOverlay isVisible={showErrorOverlay} />
      <LoadingOverlay isVisible={showLoadingOverlay} loadingMessage={loadingMessage} />
      <StoreProviderOutletContent
        setIsReady={setIsReady}
        setIsError={setIsError}
        setLoadingMessage={setLoadingMessage}
      />
    </>
  );
});

export const StoreProviderOutletContent: FC<OutletLoadingHandlers> = observer(
  ({ setIsReady, setIsError, setLoadingMessage }) => {
    const { publicStore } = usePublicAppStore();

    if (publicStore.auth.isGuestMode) {
      return (
        <GuestModeStoreProviderOutlet
          publicStore={publicStore}
          setIsReady={setIsReady}
          setIsError={setIsError}
          setLoadingMessage={setLoadingMessage}
        />
      );
    }

    if (publicStore.auth.isStandardMode) {
      return (
        <StandardModeStoreProviderOutlet
          publicStore={publicStore}
          setIsReady={setIsReady}
          setIsError={setIsError}
          setLoadingMessage={setLoadingMessage}
        />
      );
    }

    /**
     * If the user is not in guest mode or standard mode, we
     * don't wrap the outlet in any store providers.
     */
    return (
      <>
        <Outlet />
      </>
    );
  }
);

const GuestModeStoreProviderOutlet: FC<{ publicStore: PublicAppStore } & OutletLoadingHandlers> =
  observer(({ publicStore, setIsReady, setIsError }) => {
    const state = useInitializeGuestAppStore({
      publicAppStore: publicStore,
    });

    useEffect(() => {
      if (state.loading) {
        return;
      }

      if (state.error || !state.data) {
        setIsError();

        return;
      }

      setIsReady();
    }, [setIsError, setIsReady, state.data, state.error, state.loading]);

    if (!state.data) {
      return null;
    }

    return (
      <GuestAppStoreContext.Provider value={state.data}>
        <Outlet />
      </GuestAppStoreContext.Provider>
    );
  });

const StandardModeStoreProviderOutlet: FC<{ publicStore: PublicAppStore } & OutletLoadingHandlers> =
  observer(({ publicStore, setIsReady, setIsError, setLoadingMessage }) => {
    const state = useInitializeAppStore({
      publicAppStore: publicStore,
    });

    useEffect(() => {
      if (state.loading) {
        return;
      }

      if (state.error || !state.data) {
        setIsError();

        return;
      }

      /**
       * Note that we don't call `setIsReady` here because we also need
       * the StandardModeStoreProviderOutletContent to be ready.
       */
    }, [setIsError, setIsReady, state.data, state.error, state.loading]);

    if (!state.data) {
      return null;
    }

    return (
      <AppStoreContext.Provider value={state.data}>
        <StandardModeStoreProviderOutletContent
          setIsReady={setIsReady}
          setIsError={setIsError}
          setLoadingMessage={setLoadingMessage}
        />
      </AppStoreContext.Provider>
    );
  });

/**
 * We use this to kick-off global side effects in a hook.
 */
const StandardModeStoreProviderOutletContent: FC<OutletLoadingHandlers> = observer(
  ({ setIsReady, setIsError, setLoadingMessage }) => {
    const { store, pageStore } = useAppStore();

    store.useInitializeAppStoreEffects();
    pageStore.useInitializePageStoreEffects();

    useEffect(() => {
      if (store.readyState.loading) {
        return;
      }

      if (store.readyState.error || !store.readyState.data) {
        setIsError();

        return;
      }

      setIsReady();
    }, [
      setIsError,
      setIsReady,
      store.readyState.data,
      store.readyState.error,
      store.readyState.loading,
    ]);

    const bootstrapProgressMessage = store.sync.bootstrapProgressMessage;
    useEffect(() => {
      setLoadingMessage(bootstrapProgressMessage);
    }, [bootstrapProgressMessage, setLoadingMessage]);

    if (!store.readyState.data) {
      return null;
    }

    return (
      <>
        <SyncStoreInitializationChecker />
        <BootstrapOverlay />
        <LogoutOverlay />
        <UpgradedUserEmailChecker />
        <QuickSearchModal />
        <Outlet />
      </>
    );
  }
);
