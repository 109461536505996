import { actions } from "@/actions";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { MdsButtonShape, MdsButtonVariant } from "@/design-system/components/button/types";
import { MdsText, MdsTextProps } from "@/design-system/components/text/MdsText";
import { mdsColors } from "@/design-system/foundations";
import { APP_CONFIG } from "@/domains/global/config";
import { windowModule } from "@/modules/window";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

interface Props {
  noteId: string;
  isDesktop: boolean;
}

export const FatalEditorErrorNotice = observer<Props>(({ noteId, isDesktop }) => {
  return (
    <FullyCentered>
      <Box>
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="40" cy="32" r="27" fill="url(#paint0_linear_8823_73582)" />
          <path
            d="M40.6522 58.7396C40.5665 59.2956 40.9322 59.7946 41.4881 59.8803L57.3013 62.3181C57.7955 62.3943 58.3563 62.038 58.442 61.4821L61.1845 43.6923L56.2429 42.9305C55.131 42.7591 54.3996 41.761 54.571 40.6491L55.3328 35.7075L45.4495 34.1839C44.8936 34.0982 44.385 34.5257 44.3088 35.0198L40.6522 58.7396ZM45.9066 31.2189L56.0987 32.7901C57.1488 32.952 58.0705 33.5367 58.6974 34.3922L63.452 40.8799C64.079 41.7354 64.359 42.7904 64.1971 43.8405L61.407 61.9392C61.0642 64.1629 59.0062 65.6163 56.8443 65.283L41.0311 62.8453C38.8073 62.5025 37.3444 60.5063 37.6872 58.2825L41.3439 34.5627C41.6771 32.4008 43.6829 30.8761 45.9066 31.2189ZM52.1372 43.3093C52.9402 43.4331 53.515 44.2174 53.3912 45.0204L52.3247 51.9386C52.1913 52.8034 51.4166 53.3164 50.6136 53.1926C49.7488 53.0593 49.2264 52.3463 49.3597 51.4816L50.4262 44.5633C50.55 43.7603 51.2725 43.176 52.1372 43.3093ZM51.9808 57.4506C51.8094 58.5625 50.7496 59.2844 49.6995 59.1225C48.5876 58.9511 47.8561 57.953 48.0275 56.8412C48.1894 55.7911 49.197 54.9978 50.3089 55.1692C51.359 55.3311 52.1427 56.4005 51.9808 57.4506Z"
            fill={mdsColors().red.x800}
          />
          <defs>
            <linearGradient
              id="paint0_linear_8823_73582"
              x1="40"
              y1="5"
              x2="40"
              y2="59"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E8E9ED" />
              <stop offset="1" stopColor="#E8E9ED" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <Text>
          {isDesktop
            ? "There was an error editing this note. To edit and view this note, open this note on web."
            : "There was an error editing this note. Please reload the page or contact support."}
        </Text>
        <Button
          shape={MdsButtonShape.Square}
          variant={MdsButtonVariant.Outlined}
          label={isDesktop ? "Open in web" : "Reload"}
          onClick={() => {
            if (isDesktop) {
              actions.openNoteInNewTab({ noteId });
              return;
            }
            windowModule.forceHardReload();
          }}
        />
        {!isDesktop && (
          <Button
            shape={MdsButtonShape.Square}
            variant={MdsButtonVariant.Outlined}
            label={"Help and support"}
            onClick={() => {
              windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.HELP_AND_SUPPORT) });
            }}
          />
        )}
      </Box>
    </FullyCentered>
  );
});

const FullyCentered = styled.div({
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
});

const Box = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "center",
  width: "260px",
});

const Text = styled(MdsText)<MdsTextProps>(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.smedium,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.smedium,
}));

const Button = styled(MdsButton)(({ theme }) => ({
  padding: `${theme.spacing.sm} ${theme.spacing.smd}`,
}));
