import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import { Maybe } from "@/domains/common/types";
import { MountedStore } from "@/store/pages/MountedStore";
import { Uuid } from "@/domains/global/identifiers";
import { ThreadObservable } from "@/store/threads/ThreadObservable";

export class ThreadsViewPageStore extends AppSubStore {
  mountState = new MountedStore();
  itemObservable: Maybe<ThreadObservable>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable(this, {
      mountState: false,
      useEffects: false,
      amIMounted: computed,
      itemObservable: observable,
      threadId: computed,
      thread: computed,
      itemExists: computed,
      showNotFound: computed,
      setThreadObservable: action,
    });

    reaction(
      () => ({ thread: this.thread, amIMounted: this.amIMounted }),
      async ({ thread: maybeThread }) => {
        if (maybeThread) {
          this.setThreadObservable(maybeThread);
        }
      }
    );
  }

  setThreadObservable(threadObservable?: ThreadObservable) {
    this.itemObservable = threadObservable;
  }

  get amIMounted() {
    return this.mountState.isMounted;
  }

  get threadId(): Maybe<Uuid> {
    return this.store.routing.threadIdParam ?? undefined;
  }

  get thread() {
    if (!this.mountState.isMounted || !this.threadId) return;
    return this.store.threads.get(this.threadId);
  }

  get showNotFound() {
    if (!this.mountState.isMounted) return false;

    return !this.itemExists;
  }

  get itemExists(): boolean {
    // Return true when it's unknown.
    if (!this.mountState.isMounted || !this.threadId) {
      return true;
    }

    const doesNotExist = this.store.threads.doesNotExist(this.threadId);
    return doesNotExist !== true;
  }

  useEffects() {
    this.mountState.useEffects();
  }
}
