import { sleep } from "@/domains/event-loop/synchronization";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";
import { AppStore } from "@/store/AppStore";
import { MemoryQSyncOperation } from "@/store/note";
import {
  deserializeSyncOperation,
  serializeSyncOperation,
} from "@/store/sync/operations/helpers/common";
import { makeAutoObservable, runInAction } from "mobx";

type QOperation = {
  operation: MemoryQSyncOperation;
  uniqueId?: string;
};

/**
 * NoteQueueObservable is a class that manages a queue of operations for a note.
 * It is used to store operations in localStorage per tab and restores them on page load.
 * It also periodically checks for orphaned operations (from crashed tabs) and restores them.
 *
 * Algorithm:
 * 1. On initialization:
 *    - Generates unique tabId using UUID
 *    - Restores any operations from localStorage for this or other tabs
 *    - Starts periodic check for orphaned operations
 *
 * 2. Operation Storage:
 *    - Only stores UPDATE_NOTE_CONTENT_USING_DIFF operations
 *    - Each operation is stored in localStorage with key: note_queue_cache-{noteId}-{tabId}
 *    - Operations are serialized before storage and deserialized on retrieval
 *
 * 3. Operation Processing:
 *    - Operations are processed sequentially from the queue
 *    - Each operation is executed and then removed from localStorage (if stored)
 *    - Deduplication happens based on uniqueId if specified
 *
 * 4. Orphaned Operation Recovery:
 *    - Every 1 second, checks localStorage for operations from other tabs
 *    - If operations found from inactive tabs, restores and processes them
 *    - Helps prevent lost operations if a tab crashes
 *
 * 5. Cleanup:
 *    - When component becomes unobserved, stops periodic checks
 *    - Clears operations from localStorage when processed
 *
 * Case 1:
 * - tabA has operations [op1, op2]
 * - tabA crashes
 * - new tabB starts
 * - tabB restores op1, op2 on construction, process them as normal
 *
 * Case 2:
 * - tabA has operations [op1, op2]
 * - tabB has operations [op3, op4]
 * - tabA crashes
 * - tabB process op3, op4 as normal
 * - tabA recovers and restores op1, op2 based on periodic check
 * - tabB process op1, op2 as normal
 *
 * Case 3:
 * - tabA has operations [op1, op2]
 * - tabB has operations [op3, op4]
 * - tabA crashes
 * - tabB crashes
 * - new tabC starts
 * - tabC restores op1, op2, op3, op4 on construction, process them as normal
 *
 * Note: we don't need to dedup operations as they are deduped on the sync Q level.
 * Note: localStorage is limited to 5MB, so we need to be mindful of the size of the operations we store.
 * Ideally, ops in localStorage are short lived, but in extreme cases, when sync Q is blocked for a very long time,
 * the size of the ops in localStorage can potentially outnumber the quota.
 * Note: performance considerations: it takes ~10ms to write 4.5mb of data to localStorage on modern Mac (~40ms when CPU is throttled 4x).
 *
 * TODO: extend this approach to also store template updates: UPDATE_TEMPLATE
 */
export class NoteQueueObservable {
  public static readonly CACHE_KEY = "note_queue_cache";
  public static readonly CHECK_INTERVAL = 1000; // 1 second
  public readonly tabId = uuidModule.generate();

  private id: string;
  private static readonly CACHE_KEY_DIVIDER = "|---||---|";
  private checkInterval: number | null = null;

  // Preserve the operation ids that we processed to avoid ping-pong between tabs and allow deduping.
  private processedOperationIds: string[] = [];

  constructor(
    private store: AppStore,
    id: string
  ) {
    this.id = id;
    makeAutoObservable(this);

    this.restoreOperations();
    this.startPeriodicCheck();
  }

  // QUEUE FOR OPERATIONS
  operations: QOperation[] = [];

  isProcessing = false;

  // To separate diff note queues.
  get cacheUniqueKey() {
    return `${NoteQueueObservable.CACHE_KEY}-${this.id}`;
  }

  get isEmpty() {
    return !this.isProcessing || !this.operations.length;
  }

  push(operation: MemoryQSyncOperation, opt?: { dropUniqueId?: string; uniqueId?: string }) {
    const dropUniqueId = opt?.dropUniqueId || opt?.uniqueId;
    // Dedup by uniqueId.
    this.operations = this.operations.filter(
      (operation, i) =>
        // The head is prob. already being processed.
        i === 0 ||
        // Only some operations want to be deduped, e.g, metadata.
        !dropUniqueId ||
        // Keep other operations.
        operation.uniqueId !== dropUniqueId
    );
    this.operations.push({ operation, uniqueId: opt?.uniqueId });
    this.storeOperations();
    if (this.operations.length > 0) this.process();
  }

  async process() {
    if (this.isProcessing) return;
    runInAction(() => (this.isProcessing = true));
    while (this.operations.length > 0) {
      const { operation } = this.operations[0];
      if (operation) {
        await operation.execute();
        // Only remove it from the queue after it's processed.
        runInAction(() => {
          this.operations.shift();
          this.storeOperations();
          this.processedOperationIds.push(operation.id);
        });
      }
    }
    runInAction(() => (this.isProcessing = false));
  }

  async waitForQueue() {
    let done = false;
    setTimeout(() => {
      if (done) return;

      logger.warn({
        message: `[${this.id}] MemCommonEditorStore: Operation queue is taking too long`,
        info: {
          queueLength: this.operations.length,
          isProcessing: this.isProcessing,
          head: {
            id: this.operations[0].operation.id,
            kind: this.operations[0].operation.operationKind,
          },
        },
      });
    }, 800);

    while (this.isProcessing || this.operations.length > 0) {
      await sleep(100);
    }
    done = true;
  }

  restoreOperations() {
    if (this.isProcessing) {
      return;
    }

    try {
      // Find and restore any unprocessed operations from other tabs
      const keys = Object.keys(localStorage).filter(key => key.startsWith(this.cacheUniqueKey));

      runInAction(() => {
        const operations = keys.flatMap(key => {
          const cached = localStorage.getItem(key);
          if (!cached) return [];

          const ops = cached
            .split(NoteQueueObservable.CACHE_KEY_DIVIDER)
            .map(qOp => this.deserialize(this.store, qOp));

          // Skip if operation was already processed in this tab, this helps avoid ping-pong between tabs.
          const opsToProcess = ops.filter(
            op =>
              !this.processedOperationIds.includes(op.operation.id) &&
              !this.operations.some(existing => existing.operation.id === op.operation.id)
          );

          // Remove duplicates by keeping only the first occurrence of each operation ID
          const uniqueOpsToProcess = opsToProcess.filter(
            (op, index) => opsToProcess.findIndex(o => o.operation.id === op.operation.id) === index
          );

          if (uniqueOpsToProcess.length > 0) {
            // Store under our tab ID before removing old entry
            this.operations = [...this.operations, ...uniqueOpsToProcess];

            this.storeOperations();
          }

          // Now since we restored the operations, it's safe to remove the old entry.
          localStorage.removeItem(key);
          return uniqueOpsToProcess;
        });

        if (operations.length > 0) {
          this.process();
        }
      });
    } catch (unknownError) {
      const error = unknownError as Error;
      logger.error({
        message: `[${this.id}] [debug] NoteQueueObservable: Error restoring operations`,
        info: {
          error: objectModule.safeErrorAsJson(error),
        },
      });
    }
  }

  dispose() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }
  }

  storeOperations() {
    const serialized = this.operations
      .filter(
        qOp =>
          qOp.operation.operationKind === "UPDATE_NOTE_CONTENT_USING_DIFF" &&
          !!(qOp.operation.payload as { encoded_content_diff: string }).encoded_content_diff
      )
      .map(qOp => this.serialize(qOp));

    if (serialized.length > 0) {
      localStorage.setItem(
        `${this.cacheUniqueKey}-${this.tabId}`, // Store with tab-specific identifier
        serialized.join(NoteQueueObservable.CACHE_KEY_DIVIDER)
      );
    }
  }

  private startPeriodicCheck() {
    this.checkInterval = window.setInterval(() => {
      this.restoreOperations();
    }, NoteQueueObservable.CHECK_INTERVAL);
  }

  private serialize(op: QOperation): string {
    const { operation, uniqueId } = op;
    const obj = {
      uniqueId,
      ...serializeSyncOperation(operation),
    };

    return JSON.stringify(obj);
  }

  private deserialize(store: AppStore, str: string): QOperation {
    const obj = JSON.parse(str);
    if (!obj) {
      throw new Error("Could not parse operation");
    }

    const operation = deserializeSyncOperation(store, obj);
    if (!operation) {
      throw new Error("Failed to deserialize operation", obj);
    }

    return {
      operation,
      uniqueId: obj.uniqueId,
    };
  }
}
