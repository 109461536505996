import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { TemplateObservable } from "@/store/templates/TemplateObservable";

export const removeNoteFromCollection = async ({
  note,
  collection,
  store,
}: {
  note: INoteObservable | TemplateObservable;
  collection: CollectionObservable;
  store: AppStore;
}) => {
  if (!note.collectionList) return;

  await note.collectionList.removeCollection({ collectionId: collection.id });
  toastModule.triggerToast({
    content: (
      <div>
        {`${note instanceof TemplateObservable ? "Template" : "Note"} removed from `}
        <a onClick={() => store.navigation.goToCollection({ collectionId: collection.id })}>
          {collection.label}
        </a>
        {"."}
      </div>
    ),
  });
};
