import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { appRoutes } from "@/app/router";
import { googleIdentityModule } from "@/modules/google-identity";
import { AuthCardLogInWithGoogleButton } from "@/components/auth/google/AuthCardLogInWithGoogleButton";
import { AuthCardBase } from "@/components/auth/AuthCardBase";
import { authCardStyles } from "@/components/auth/utils/authCardStyles";
import { usePublicAppStore } from "@/store";
import { AuthCardLogInWithEmailPasswordButton } from "@/components/auth/email-password/AuthCardLogInWithEmailPasswordButton";

export interface AuthCardLogInProps {}

export const AuthCardLogIn: FC<AuthCardLogInProps> = observer(() => {
  const navigate = useNavigate();
  const { publicStore } = usePublicAppStore();

  const shouldShowEmailPasswordButton = publicStore.debug.debugModeEnabled;

  const logInWithGoogle = useCallback(async () => await googleIdentityModule.triggerLogIn(), []);

  const logInWithEmailPassword = useCallback(() => {
    navigate(appRoutes.logInEmailPassword({}).path);
  }, [navigate]);

  return (
    <AuthCardBase title="Log in to Mem 2.0">
      <div className={authCardStyles.actionSection}>
        <AuthCardLogInWithGoogleButton onClick={logInWithGoogle} />
        {shouldShowEmailPasswordButton && (
          <AuthCardLogInWithEmailPasswordButton onClick={logInWithEmailPassword} />
        )}
      </div>

      <div className={authCardStyles.altText}>
        <span>Don&apos;t have an account?</span>{" "}
        <span>
          <Link className={authCardStyles.link} to={appRoutes.signUp({}).path}>
            Sign up
          </Link>
        </span>
      </div>
    </AuthCardBase>
  );
});
