import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListAsyncTemplateRowData,
  MdsItemListRowData,
} from "@/design-system/components/item-list/types";
import { MdsItemListRowType, MdsItemListSize } from "@/design-system/components/item-list/types";
import { uuidModule } from "@/modules/uuid";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  reaction,
  runInAction,
} from "mobx";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { Maybe } from "@/domains/common/types";
import { SortByKind } from "@/modules/lenses/types";
import { TemplateIndexTuple, TemplatesSortByKind } from "@/store/templates/types";
import { LensKind } from "@/modules/lenses/types";
import { liveQuery, Subscription } from "dexie";
import localDb from "@/domains/local-db";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { EventContext } from "@/domains/metrics/context";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { LensGroup } from "@/modules/timeline/types";
import { groupLens } from "@/modules/timeline/group-lenses";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { MdsDropdownItem } from "@/design-system/components/dropdown/types";
import { templatesLensDateLabelMap } from "@/modules/lenses/templates";
import { actions } from "@/actions";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { DeleteTemplateModalStore } from "@/components/modal/delete-template/DeleteTemplateModalStore";
import { NotesSortByKind } from "@/store/note";

export type TemplateListPageLensKind = LensKind.All | LensKind.AddedByMe | LensKind.SharedWithMe;
export type TemplateListPageSortByKind = TemplatesSortByKind;
export type TemplateListPageFilters = {
  createdBy?: string[];
  modifiedBy?: string[];
};

const DEFAULT_SORT_BY = SortByKind.LastModified;
const DEFAULT_LENS = LensKind.All;

export interface TemplatesListPageParams {
  sortBy: TemplateListPageSortByKind;
  lens: TemplateListPageLensKind;
  filter: TemplateListPageFilters;
}

export class TemplatesListPageStore extends AppSubStore {
  supportsAddToCollectionListAction = true;
  supportsMoveToTrashListAction = true;
  listState: ListStateObservable;
  templatesFilterQuery: string = "";

  addToCollectionModal: AddToCollectionModalStore;
  deleteTemplateModal: DeleteTemplateModalStore;
  shareSheetModal: ShareSheetModalStore;

  isLoading: boolean;
  subscriptionInitialized: boolean;
  liveQuerySubscription: Maybe<Subscription> = undefined;
  subscribedTemplateRows: TemplateIndexTuple[] = [];

  eventContext = EventContext.TemplatesMultiselectActions;

  filters: NotesListFilter;

  private readonly supportedSortOptions: SortByKind[] = [
    SortByKind.LastCreated,
    SortByKind.LastModified,
    SortByKind.LastViewed,
    SortByKind.LastShared,
    SortByKind.LastUsed,
    SortByKind.Alphabetical,
  ];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.deleteTemplateModal = new DeleteTemplateModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });

    // TODO: This needs to become a TemplatesListFilter
    this.filters = new NotesListFilter(this.store, this.supportedSortOptions);

    this.isLoading = true;
    this.subscriptionInitialized = false;

    makeObservable<this, "generateItemDropdown" | "initializeLiveQuery" | "supportedSortOptions">(
      this,
      {
        groupedLensItems: true,
        setTemplatesFilterQuery: true,
        handleCreateNewTemplate: true,
        mapTemplateToRow: true,
        resetState: true,
        supportedSortOptions: true,
        initializeLiveQuery: action,
        liveQuerySubscription: observable,
        subscriptionInitialized: observable,

        subscribedTemplateRows: observable,
        itemRows: computed,

        supportsAddToCollectionListAction: observable,
        supportsMoveToTrashListAction: observable,
        templatesFilterQuery: observable,
        listState: false,
        addToCollectionModal: false,
        deleteTemplateModal: false,
        shareSheetModal: false,
        eventContext: false,

        isLoading: observable,
        generateItemDropdown: false,
        orderedItemIds: computed,

        initialize: action,
        filters: false,
      }
    );

    reaction(
      () => this.filters.params,
      params => {
        this.store.memDb.settings.setTemplatesListPageParams(params);
      }
    );

    reaction(
      () => this.subscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );

    onBecomeObserved(this, "subscribedTemplateRows", () => this.initializeLiveQuery());
  }

  get groupedLensItems(): LensGroup<TemplateIndexTuple>[] {
    return groupLens(
      this.subscribedTemplateRows.map(tuple => ({
        // TemplateIndexTuple is [is_owned_by_me, date_time, model_id] or [date_time, model_id]
        dateTime: new Date(tuple.length === 3 ? tuple[1] : tuple[0]),
        item: tuple,
      }))
    );
  }

  setTemplatesFilterQuery({ query }: { query: string }) {
    this.templatesFilterQuery = query;
  }

  handleCreateNewTemplate = async () => {
    const templateId = uuidModule.generate();
    await this.store.templates.createTemplate({ templateId });
    this.store.navigation.goToTemplate({ templateId });
  };

  get itemRows() {
    const output: MdsItemListRowData[] = [];

    if (this.filters.sortBy === SortByKind.Alphabetical) {
      return this.subscribedTemplateRows.map(tuple =>
        // Last element of tuple is the model_id
        this.mapTemplateToRow(tuple.at(-1) as string)
      );
    }

    for (const group of this.groupedLensItems) {
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      for (const item of group.items) {
        const tuple = item.item;
        output.push(this.mapTemplateToRow(tuple.at(-1) as string));
      }
    }
    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });
    return output;
  }

  mapTemplateToRow(templateId: string): MdsItemListAsyncTemplateRowData {
    return {
      type: MdsItemListRowType.AsyncTemplate,
      key: templateId,
      size: MdsItemListSize.XLarge,
      payload: {
        templateId,
        itemRow: (template: TemplateObservable) => {
          const collections = template.collectionList.allCollections;
          const spaceAccountTemplate = template.spaceAccountTemplate;
          const sharedWithMeBySpaceAccountId =
            spaceAccountTemplate?.modelData.shared_with_me_by_space_account_id;
          const sharedByContact = sharedWithMeBySpaceAccountId
            ? this.store.contacts.getBySpaceAccountId(sharedWithMeBySpaceAccountId)
            : undefined;

          return {
            type: MdsItemListRowType.Item,
            key: template.id,
            size: MdsItemListSize.XLarge,
            payload: {
              id: template.id,
              kind: MdsItemKind.Template,
              titleIcon: template.isShared ? { kind: MdsIconKind.Shared } : undefined,
              label: template.label,
              onClick: () => this.store.navigation.goToTemplate({ templateId }),
              sharedBy: sharedByContact,
              dateLabel: templatesLensDateLabelMap[this.filters.params.sortBy](template),
              dropdown: this.generateItemDropdown({ template }),
              listState: this.listState,
              extraRows: [
                {
                  id: `template-${template.id}-content`,
                  content: () => (
                    <MdsItemListRowFeaturedContent
                      collections={sortCollectionsForChips(collections)}
                      snippet={
                        !collections.length && template.secondaryLabel
                          ? [{ text: template.secondaryLabel }]
                          : []
                      }
                    />
                  ),
                },
              ],
            },
          };
        },
      },
    };
  }

  private generateItemDropdown({ template }: { template: TemplateObservable }): MdsItemDropdown {
    const ownerActions: MdsDropdownItem[] = template.isOwnedByMe
      ? [
          {
            id: `delete-${template.id}`,
            kind: MdsDropdownItemKind.Button,
            iconKind: MdsIconKind.Trash,
            label: "Delete",
            onClick: () => this.deleteTemplateModal.open({ templateId: template.id }),
          },
        ]
      : [];

    return {
      items: [
        {
          id: `copy-link-${template.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            actions.copyTemplateLinkToClipboard({ templateId: template.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `share-${template.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () => {
            this.shareSheetModal.open({
              id: template.id,
              entityKind: ShareSheetEntityKind.Template,
              eventContext: this.eventContext,
            });
          },
        },
        ...ownerActions,
      ],
    };
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  resetState = () => {
    this.templatesFilterQuery = "";
  };

  initialize = async () => {
    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getTemplatesListPageParams(),
      this.store.memDb.settings.getTemplatesListPageParams(),
    ]);
    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setTemplatesListPageParams(localDBParams);
      await localDb.settings.removeTemplatesListPageParams();
    }
    this.filters.setParams({
      sortBy: (localDBParams?.sortBy || memDbParams?.sortBy || DEFAULT_SORT_BY) as NotesSortByKind,
      lens: localDBParams?.lens || memDbParams?.lens || DEFAULT_LENS,
      filter: localDBParams?.filter || memDbParams?.filter || {},
    });
    this.initializeLiveQuery();
  };

  initializeLiveQuery() {
    reaction(
      () => ({ params: this.filters.params, query: this.templatesFilterQuery }),
      ({ params, query }) => {
        const { lens, sortBy, limit, filter } = params;
        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(() => {
          return this.store.templates.search({
            query,
            lens,
            sortBy: sortBy as TemplatesSortByKind,
            limit,
            filter,
          });
        }).subscribe({
          next: rows => {
            runInAction(() => {
              this.subscribedTemplateRows = rows;
              this.subscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }
}
