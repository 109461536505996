import { DateTime, Duration } from "luxon";
import { makeObservable, action, observable } from "mobx";

export class IntervalObservable {
  /**
   * The target timestamp, updated at each interval.
   */
  value = DateTime.now();

  /** Internal interval reference so we can clear it later. */
  private _intervalId: ReturnType<typeof setInterval> | undefined;
  private _intervalMs: number;

  constructor({ duration }: { duration: Duration }) {
    makeObservable<this, "_intervalId" | "_intervalMs" | "value" | "start" | "dispose" | "tick">(
      this,
      {
        _intervalId: false,
        _intervalMs: observable,
        value: observable,
        forceRefresh: action,
        start: action,
        dispose: action,
        tick: action,
      }
    );

    this._intervalMs = duration.toMillis();
    this.start();
  }

  private start = () => {
    this._intervalId = setInterval(this.tick, this._intervalMs);
  };

  /** The method the interval calls each time. */
  private tick = () => {
    this.value = DateTime.now();
  };

  /**
   * We expose this primarily for testing purposes.
   */
  public forceRefresh = () => {
    this.tick();
  };

  /** Clean up the interval when you're done with it. */
  dispose = () => {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }

    this._intervalId = undefined;
  };
}
