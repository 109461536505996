import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsIconKind } from "@/design-system/components/icon";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { MentionChip } from "@/pages/chat/ChatInput";
import { AppStore } from "@/store";
import { CollectionMentionContent } from "@/store/chat/mention/CollectionMentionContent";
import { NoteMentionContent } from "@/store/chat/mention/NoteMentionContent";
import { getTimeRanges } from "@/store/chat/getTimeRanges";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import noop from "lodash-es/noop";
import { DateIcon } from "@/design-system/components/item-list/rows/icon/date";
import { DateMentionContent } from "@/store/chat/mention/DateMentionContent";
import { MemCommonMentionKind } from "@mem-labs/common-editor";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search/local/types";
import { uuidModule } from "@/modules/uuid";
import { SearchIcon } from "@/design-system/components/item-list/rows/icon/search";
import { SearchMentionContent } from "@/store/chat/mention/SearchMentionContent";

const MAX_RESULTS = 20;

const getDropdownButtonContentForNote = (
  id: string,
  label: string,
  isOwnedByMe: boolean
): {
  id: string;
  isOwnedByMe: INoteObservable["isOwnedByMe"];
  iconKind: MentionChip["iconKind"];
  content: MentionChip["content"];
} => {
  return {
    id,
    isOwnedByMe,
    iconKind: () => <NoteIcon toggleSelected={noop} />,
    content: () => <NoteMentionContent id={id} label={label} />,
  };
};

const getDropdownButtonContentForCollection = (
  id: string,
  label: string,
  store: AppStore
): {
  iconKind: MentionChip["iconKind"];
  content: MentionChip["content"];
  isOwnedByMe: CollectionObservable["isOwnedByMe"];
} => {
  const collection = store.collections.get(id);
  return {
    iconKind: () => <CollectionIcon collectionId={id} />,
    content: () => <CollectionMentionContent id={id} label={label} />,
    isOwnedByMe: !!collection?.isOwnedByMe,
  };
};

const getDropdownButtonContentForDate = (
  label: string,
  subtitle: string
): {
  iconKind: MentionChip["iconKind"];
  content: MentionChip["content"];
} => {
  return {
    iconKind: () => <DateIcon />,
    content: () => <DateMentionContent label={label} subtitle={subtitle} />,
  };
};

const getDropdownButtonContentForSearchQuery = (
  query: string
): {
  iconKind: MentionChip["iconKind"];
  content: MentionChip["content"];
} => {
  return {
    iconKind: () => <SearchIcon toggleSelected={noop} />,
    content: () => <SearchMentionContent query={query} />,
  };
};

const getTimeRangeChips = (): MentionChip[] =>
  getTimeRanges().map(range => ({
    id: uuidModule.generate(),
    label: range.label,
    subtitle: range.readableDate,
    iconKind: MdsIconKind.CalendarDay,
    groupTitle: "Look at notes from a date",
    kind: MemCommonMentionKind.TimeRange,
    metadata: `${range.timestamp_start}-${range.timestamp_end}`,
  }));

export const makeGuidedChips = async (
  mentionQuery: string,
  store: AppStore,
  suggestions: SearchSuggestion[]
): Promise<MentionChip[]> => {
  const mentionQueryText = mentionQuery.slice(1).trim();
  const lowercaseMentionQueryText = mentionQueryText.toLowerCase();
  const chips: MentionChip[] = [];

  // Add time range chips
  // feature time ranges will get pulled to the top
  const featuredTimeRanges = ["today", "last 7 days", "last 30 days"];
  getTimeRangeChips().forEach(chip => {
    const { iconKind, content } = getDropdownButtonContentForDate(chip.label, chip.subtitle ?? "");

    chips.push({
      ...chip,
      featuredIndex: featuredTimeRanges.some(featuredTimeRangeLabel =>
        chip.label.toLowerCase().includes(featuredTimeRangeLabel)
      )
        ? featuredTimeRanges.indexOf(chip.label.toLocaleLowerCase())
        : undefined,
      iconKind,
      content,
    });
  });

  // add Collections
  const collectionSuggestions = suggestions.filter(
    suggestion => suggestion.type === SearchSuggestionType.COLLECTION
  );

  const collectionItems =
    lowercaseMentionQueryText.length === 0
      ? store.recentItems.sortedRecentCollectionsInteractedWithByMe
      : collectionSuggestions.map(suggestion => ({
          ...suggestion,
          id: suggestion.modelId,
          title: suggestion.label,
        }));

  collectionItems.slice(0, MAX_RESULTS).forEach(collection => {
    const { id, label } = collection;
    const { iconKind: icon, content } =
      getDropdownButtonContentForCollection(id, label, store) ?? {};
    chips.push({
      id,
      label,
      iconKind: icon ?? MdsIconKind.Collection,
      content,
      groupTitle: "Look at notes in a collection",
      kind: MemCommonMentionKind.Collection,
    });
  });

  // add Notes
  const noteSuggestions = suggestions.filter(
    suggestion => suggestion.type === SearchSuggestionType.NOTE
  );

  const noteItems =
    lowercaseMentionQueryText.length === 0
      ? store.recentItems.sortedRecentNotesInteractedWithByMe
      : noteSuggestions.map(suggestion => ({
          ...suggestion,
          id: suggestion.modelId,
          title: suggestion.label,
          isOwnedByMe: suggestion.isAvailable === 1,
        }));

  noteItems.slice(0, MAX_RESULTS).forEach(note => {
    const { id, title } = note;
    const label = title || UNTITLED_NOTE_TITLE;
    const { iconKind: icon, content } =
      getDropdownButtonContentForNote(id, label, note.isOwnedByMe) ?? {};
    chips.push({
      id,
      label,
      iconKind: icon ?? MdsIconKind.Notebook,
      content,
      groupTitle: "Look at a specific note",
      kind: MemCommonMentionKind.Note,
    });
  });

  const filteredChips = chips.filter(chip =>
    chip.label.toLowerCase().includes(lowercaseMentionQueryText)
  );

  const { iconKind: searchIconKind, content: searchContent } =
    getDropdownButtonContentForSearchQuery(mentionQueryText);
  const searchChip: MentionChip = {
    id: uuidModule.generate(),
    label: `Search for "${mentionQueryText}"`,
    iconKind: searchIconKind,
    content: searchContent,
    groupTitle: "Look at a set of search results",
    metadata: mentionQueryText,
    kind: MemCommonMentionKind.SearchQuery,
  };

  return [...filteredChips, searchChip];
};
