import { MdsIconKind } from "@/design-system/components/icon/types";

import { EventContext } from "@/domains/metrics/context";

import { MdsButtonShape } from "@/design-system/components/button/types";

import { MdsButtonVariant } from "@/design-system/components/button/types";

import { MdsButton } from "@/design-system/components/button/MdsButton";

import { MdsButtonSize } from "@/design-system/components/button/types";
import { useAppStore } from "@/store/utils/hooks";
import { LensKind } from "@/modules/lenses/types";

interface NotesListPageEmptyButtonProps {
  area: LensKind;
}

export function NotesListPageEmptyButton({ area }: NotesListPageEmptyButtonProps) {
  const { store, pageStore } = useAppStore();
  const { filters } = pageStore.notesListPage;

  if (filters.hasAnyFilters) {
    return (
      <MdsButton
        label="Clear all filters"
        variant={MdsButtonVariant.FilledDark}
        shape={MdsButtonShape.Square}
        size={MdsButtonSize.Medium}
        onClick={() => filters.clearAllFilters()}
      />
    );
  }

  if (area === LensKind.SharedWithMe) {
    return null;
  }

  return (
    <MdsButton
      label="Create note"
      variant={MdsButtonVariant.FilledDark}
      shape={MdsButtonShape.Square}
      size={MdsButtonSize.Medium}
      isDisabled={store.notes.newNoteIsBeingCreated}
      onClick={() => store.notes.composeNewNote({ eventContext: EventContext.NotesActions })}
      iconKind={MdsIconKind.ComposeInline}
    />
  );
}
