import { appRoutes } from "@/app/router";
import { NoteType } from "@/components/note/types";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";
import { windowModule } from "@/modules/window";

export const copyNoteLinkToClipboard = async ({
  noteId,
  noteType = NoteType.Note,
}: {
  noteId: string;
  noteType?: NoteType;
}) => {
  const targetUrl = windowModule.buildUrl({
    path:
      noteType === NoteType.Note
        ? appRoutes.notesView({ params: { noteId } }).path
        : appRoutes.templatesView({ params: { templateId: noteId } }).path,
  });

  await copyTextToClipboard({
    text: targetUrl.toString(),
  });

  toastModule.triggerToast({ content: "Link copied to clipboard" });
};
