import { ClientPlatformKind, PlatformIconKind } from "@/domains/api/types";
import { Uuid } from "@/domains/global/identifiers";
import { clientEnvModule } from "@/modules/client-env";
import { enumModule } from "@/modules/enum";
import { AppCalloutKind, SpaceAccountAppCalloutModelData } from "@/store/app-callout/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { DateTime } from "luxon";
import { makeObservable, computed } from "mobx";

export class SpaceAccountAppCalloutObservable extends BaseSyncModel<SpaceAccountAppCalloutModelData> {
  public readonly modelKind = SyncModelKind.SpaceAccountAppCallout;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<SpaceAccountAppCalloutModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: true,
      calloutKind: computed,
      isDismissible: computed,
      isActive: computed,
      startAt: computed,
      endAt: computed,
      targetPlatformKinds: computed,
      iconKind: computed,
      primaryLabel: computed,
      secondaryLabel: computed,
      inlineActionLabel: computed,
      inlineActionUrl: computed,
      primaryActionLabel: computed,
      primaryActionUrl: computed,
      primaryActionIconKind: computed,
      secondaryActionLabel: computed,
      secondaryActionUrl: computed,
      secondaryActionIconKind: computed,
      spaceAccountId: computed,
      isDismissed: computed,
      isVisible: computed,
      appCalloutId: computed,
    });
  }

  /**
   * Configuration
   */

  get calloutKind(): AppCalloutKind {
    return (
      enumModule.findMatchingStringValue(AppCalloutKind, this.modelData.callout_kind) ??
      AppCalloutKind.UNKNOWN
    );
  }

  get appCalloutId(): string {
    return this.modelData.app_callout_id;
  }

  get isDismissible(): boolean {
    return this.modelData.is_dismissible;
  }

  get isActive(): boolean {
    return this.modelData.is_active;
  }

  get startAt(): DateTime | null {
    return this.modelData.start_at ? DateTime.fromISO(this.modelData.start_at) : null;
  }

  get endAt(): DateTime | null {
    return this.modelData.end_at ? DateTime.fromISO(this.modelData.end_at) : null;
  }

  get targetPlatformKinds(): ClientPlatformKind[] {
    const kinds = this.modelData.target_platform_kinds;
    if (!kinds) return [];

    const parsedKinds = kinds.map(kind =>
      enumModule.findMatchingStringValue(ClientPlatformKind, kind)
    );

    return parsedKinds.filter((kind): kind is ClientPlatformKind => kind !== undefined);
  }

  /**
   * Primary Content
   */

  get iconKind(): PlatformIconKind | null {
    const iconKind = this.modelData.icon_kind;
    if (!iconKind) return null;

    return enumModule.findMatchingStringValue(PlatformIconKind, iconKind) ?? null;
  }

  get primaryLabel(): string {
    return this.modelData.primary_label;
  }

  get secondaryLabel(): string {
    return this.modelData.secondary_label;
  }

  /**
   * Inline Action
   */

  get inlineActionLabel(): string | null {
    return this.modelData.inline_action_label;
  }

  get inlineActionUrl(): string | null {
    return this.modelData.inline_action_url;
  }

  /**
   * Primary Action
   */

  get primaryActionLabel(): string | null {
    return this.modelData.primary_action_label;
  }

  get primaryActionUrl(): string | null {
    return this.modelData.primary_action_url;
  }

  get primaryActionIconKind(): PlatformIconKind | null {
    const iconKind = this.modelData.primary_action_icon_kind;
    if (!iconKind) return null;

    return enumModule.findMatchingStringValue(PlatformIconKind, iconKind) ?? null;
  }

  /**
   * Secondary Action
   */

  get secondaryActionLabel(): string | null {
    return this.modelData.secondary_action_label;
  }

  get secondaryActionUrl(): string | null {
    return this.modelData.secondary_action_url;
  }

  get secondaryActionIconKind(): PlatformIconKind | null {
    const iconKind = this.modelData.secondary_action_icon_kind;
    if (!iconKind) return null;

    return enumModule.findMatchingStringValue(PlatformIconKind, iconKind) ?? null;
  }

  /**
   * Space-Account-Specific Fields
   */

  get spaceAccountId(): Uuid {
    return this.modelData.space_account_id;
  }

  get isDismissed(): boolean {
    return this.modelData.is_dismissed;
  }

  /**
   * Custom Getters
   */
  get isVisible(): boolean {
    const platformKind = clientEnvModule.platformKind();
    if (!this.targetPlatformKinds.includes(platformKind)) return false;

    if (!this.isActive) return false;
    if (this.isDismissed) return false;
    /**
     * @TODO - Swap this with the chrono store once the latest round
     *         of updates lands.
     */
    const currentTime = DateTime.now();

    if (this.startAt && this.startAt > currentTime) return false;
    if (this.endAt && this.endAt < currentTime) return false;

    return true;
  }
}
