import { SyncStatusLastSyncedText } from "@/components/sync/SyncStatusLastSyncedText";
import { OutboundSyncStatus, SyncState } from "@/components/sync/types";
import { pluralize } from "@/modules/pluralize";

interface Props {
  syncState: SyncState;
}

export const SyncStatusText = ({ syncState }: Props) => {
  const count = syncState.pendingOperationCount;
  const { status, inboundLastSyncedAt, outboundLastSyncedAt } = syncState;

  switch (status) {
    case OutboundSyncStatus.Idle:
      return (
        <SyncStatusLastSyncedText
          inboundLastSyncedAt={inboundLastSyncedAt}
          outboundLastSyncedAt={outboundLastSyncedAt}
        />
      );

    case OutboundSyncStatus.Syncing:
      return (
        <div>
          Syncing {count} {pluralize(count, "change", "changes")}
        </div>
      );
    case OutboundSyncStatus.Paused:
      return (
        <div>
          {count} {pluralize(count, "change", "changes")} will sync <br /> when Mem reconnects
        </div>
      );
  }
};
