import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";
import {
  SpaceAccountCollectionModelData,
  SpaceAccountCollectionUpsertedSyncUpdateValue,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveNoteFromCollectionOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";

export class RemoveNoteFromCollectionOperation extends BaseSyncOperation<IRemoveNoteFromCollectionOperation> {
  private collection?: CollectionObservable;
  private collectionItem?: CollectionItemObservable;
  private spaceAccountCollection?: SpaceAccountCollectionObservable;

  get operationKind(): SyncOperationKind {
    return "REMOVE_NOTE_FROM_COLLECTION";
  }

  get successToastMessage() {
    return this.triggerSuccessToast ? <>Removed from {this.mediumCollectionTitle}</> : null;
  }

  get collectionTitle() {
    return this.store.collections.get(this.payload.collection_id)?.label;
  }

  get noteTitle() {
    return this.store.notes.get(this.payload.note_id)?.title || UNTITLED_COLLECTION_TITLE;
  }

  get mediumCollectionTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.collectionTitle}
      </MdsText>
    );
  }

  get mediumNoteTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.noteTitle}
      </MdsText>
    );
  }

  private get collectionItemId() {
    return uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.note_id,
    });
  }

  private get spaceAccountCollectionId() {
    return uuidModule.resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.payload.collection_id,
    });
  }

  async execute() {
    this.collection = await this.store.collections.getAsync(this.payload.collection_id);
    this.collectionItem = await this.store.collectionItems.getAsync(this.collectionItemId);
    this.spaceAccountCollection = await this.store.spaceAccountCollections.getAsync(
      this.spaceAccountCollectionId
    );

    await super.execute();
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates = [];

    const collectionItemUpdate = this.generateCollectionItemUpdate();
    if (collectionItemUpdate) {
      optimisticUpdates.push(collectionItemUpdate);
    }

    const collectionUpdate = this.generateCollectionUpdate();
    if (collectionUpdate) {
      optimisticUpdates.push(collectionUpdate);
    }

    const spaceAccountCollectionUpdate = this.generateSpaceAccountCollectionUpdate();
    if (spaceAccountCollectionUpdate) {
      optimisticUpdates.push(spaceAccountCollectionUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute(): Promise<void> {
    await this.store.collections.recompute(this.payload.collection_id);
    await this.store.collectionItems.recompute(this.collectionItemId);
    await this.store.notes.recompute(this.payload.note_id);
    await this.store.spaceAccountCollections.recompute(this.spaceAccountCollectionId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be removed from {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to remove {this.mediumNoteTitle} from{" "}
        {this.mediumCollectionTitle}. Please contact the collection owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumNoteTitle} could not be removed from {this.mediumCollectionTitle}. If this error
        continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  protected generateCollectionItemUpdate():
    | OptimisticSyncUpdate<CollectionItemModelData>
    | undefined {
    if (!this.collectionItem) {
      logger.debug({
        message: "Attempted to remove note from collection when collection item is undefined",
        info: {
          collectionItemId: this.collectionItemId,
        },
      });

      return;
    }

    const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
      model_id: this.collectionItem.id,
      model_kind: "COLLECTION_ITEM",
      model_version: this.collectionItem.modelVersion ?? 0,
    };

    const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: collectionItemValue,
    };

    return collectionItemUpdate;
  }

  protected generateCollectionUpdate(): OptimisticSyncUpdate<CollectionModelData> | undefined {
    if (!this.collection) {
      logger.debug({
        message: "Attempted to remove note from collection when collection item is undefined",
        info: {
          collectionItemId: this.collectionItemId,
        },
      });

      return;
    }

    const collectionValue: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.collection_id,
      model_kind: "COLLECTION",
      model_version: this.collection.modelVersion,
      model_data: {
        ...this.collection.modelData,
        last_removed_from_at: this.committedAt,
      },
      model_scopes: this.collection.modelScopes,
    };

    const collectionUpdate: OptimisticSyncUpdate<CollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: collectionValue,
    };

    return collectionUpdate;
  }

  protected generateSpaceAccountCollectionUpdate():
    | OptimisticSyncUpdate<SpaceAccountCollectionModelData>
    | undefined {
    if (!this.spaceAccountCollection) {
      logger.debug({
        message:
          "Attempted to remove note from collection when space account collection is undefined",
        info: {
          spaceAccountCollectionId: this.spaceAccountCollectionId,
        },
      });

      return;
    }

    const spaceAccountCollectionValue: SpaceAccountCollectionUpsertedSyncUpdateValue = {
      model_id: this.spaceAccountCollection.id,
      model_kind: "SPACE_ACCOUNT_COLLECTION",
      model_version: this.spaceAccountCollection.modelVersion,
      model_data: {
        ...this.spaceAccountCollection.modelData,
        last_removed_from_at: this.committedAt,
      },
      model_scopes: this.spaceAccountCollection.modelScopes,
    };

    const spaceAccountCollectionUpdate: OptimisticSyncUpdate<SpaceAccountCollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: spaceAccountCollectionValue,
    };

    return spaceAccountCollectionUpdate;
  }
}
