import styled from "@emotion/styled";
import { Popover } from "react-aria-components";

export const StyledPopover = styled(Popover)`
  background: ${({ theme }) => theme.colors.grey.x0};
  border: 1px solid ${({ theme }) => theme.colors.grey.x100};
  border-radius: ${({ theme }) => theme.borderRadius.mediumLarge}px;
  box-shadow: ${({ theme }) => theme.shadows.popover};
  padding: ${({ theme }) => theme.spacing.md};
`;
