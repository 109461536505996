import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { TemplateContentDocumentModelData } from "@/store/templates/types";
import { WithAppStore } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";
import { makeObservable, observable } from "mobx";

export class TemplateContentDocumentObservable extends BaseSyncModel<TemplateContentDocumentModelData> {
  public modelKind = SyncModelKind.TemplateContentDocument;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<TemplateContentDocumentModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, { modelKind: observable });
  }
}
