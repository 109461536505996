import { Maybe } from "@/domains/common/types";
import { UNTITLED_TEMPLATE_TITLE } from "@/domains/untitled/untitled";
import { AppStore } from "@/store/AppStore";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { SpaceAccountTemplateModelData, TemplateIndexData } from "@/store/templates/types";
import { TemplateModelData } from "@/store/templates/types";

export class TemplateIndexes {
  private store: AppStore;
  private data: SyncUpdateValue<TemplateModelData>;
  private spaceAccountTemplate: SyncUpdateValue<SpaceAccountTemplateModelData> | undefined;
  private optimisticUpdates: OptimisticSyncUpdate<TemplateModelData>[];

  constructor({
    store,
    remoteData,
    optimisticUpdates,
    spaceAccountTemplate,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<TemplateModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<TemplateModelData>[];
    spaceAccountTemplate: Maybe<SyncUpdateValue<SpaceAccountTemplateModelData>>;
  }) {
    this.store = store;
    this.optimisticUpdates = optimisticUpdates;
    this.spaceAccountTemplate = spaceAccountTemplate;
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<TemplateModelData>) || remoteData;
  }

  get title() {
    return this.data.model_data.title || UNTITLED_TEMPLATE_TITLE;
  }

  get lowercase_title() {
    return this.title.toLowerCase();
  }

  get created_at() {
    return this.data.model_data.locally_created_at;
  }

  get modified_at() {
    return this.optimisticUpdates.reduce((latest, update) => {
      const actionDate = update.locally_committed_at;
      return actionDate > latest ? actionDate : latest;
    }, this.data.model_data.locally_modified_at);
  }

  get last_used_at() {
    return this.spaceAccountTemplate?.model_data.last_used_at || this.created_at || "";
  }

  get last_viewed_at() {
    return this.spaceAccountTemplate?.model_data.last_viewed_at || "";
  }

  get shared_with_me_at(): string {
    return this.spaceAccountTemplate?.model_data.shared_with_me_at || "";
  }

  get is_owned_by_me() {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const owner_scope = this.data.model_scopes.find(scope => scope.role_kind === "OWNER");
    if (!owner_scope) return false;
    if ("space_account_id" in owner_scope.value)
      return owner_scope.value.space_account_id === myPersonalSpaceAccountId;
    return false;
  }

  get indexes(): TemplateIndexData {
    return {
      title: this.title,
      lowercase_title: this.lowercase_title,
      created_at: this.created_at,
      modified_at: this.modified_at,
      shared_with_me_at: this.shared_with_me_at,
      last_used_at: this.last_used_at,
      last_viewed_at: this.last_viewed_at,
      is_owned_by_me: this.is_owned_by_me ? 1 : 0,
    };
  }
}
