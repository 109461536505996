import { AssertTrue, ValidateEnumIsSubtype } from "@/domains/typing/types";
import { ApiSchema } from "@/modules/api";
import { UpsertedSyncUpdateResponse, DeletedSyncUpdateResponse } from "@/store/sync/types";

export type SpaceAccountAppCalloutModelData =
  ApiSchema["SpaceAccountAppCalloutSyncModelDataResponseSchema"];

export type SpaceAccountAppCalloutUpsertedSyncUpdateValue =
  ApiSchema["SpaceAccountAppCalloutSyncModelResponseSchema"];
export type SpaceAccountAppCalloutDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type SpaceAccountAppCalloutSyncUpdateValue =
  | SpaceAccountAppCalloutUpsertedSyncUpdateValue
  | SpaceAccountAppCalloutDeletedSyncUpdateValue;

export type SpaceAccountAppCalloutUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  kind: "UPSERTED";
  value: SpaceAccountAppCalloutUpsertedSyncUpdateValue;
};
export type SpaceAccountAppCalloutDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  kind: "DELETED";
  value: SpaceAccountAppCalloutDeletedSyncUpdateValue;
};
export type SpaceAccountAppCalloutSyncUpdate =
  | SpaceAccountAppCalloutUpsertedSyncUpdate
  | SpaceAccountAppCalloutDeletedSyncUpdate;

export type AppCalloutKindValues =
  ApiSchema["SpaceAccountAppCalloutSyncModelDataResponseSchema"]["callout_kind"];

export enum AppCalloutKind {
  BANNER = "BANNER",
  MODAL = "MODAL",
  CARD = "CARD",
  UNKNOWN = "UNKNOWN",
}

type _ValidateAppCalloutKindValues = AssertTrue<
  ValidateEnumIsSubtype<AppCalloutKind, AppCalloutKindValues>
>;
