import { ChatMessageStore, SendMessageArgs } from "@/domains/chat/chat-message-store";
import { Maybe } from "@/domains/common/types";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";
import { chatMentionContextToOperationContext } from "@/pages/chat/lib";
import { AppStore } from "@/store/AppStore";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { ChatMessageIndexes } from "@/store/chat/ChatMessageIndexes";
import { ChatMessageObservable } from "@/store/chat/ChatMessageObservable";
import { isSystemMessage } from "@/store/chat/chatMessageUtils";
import {
  ChatHistoryIndexes,
  ChatMessageContext,
  ChatMessageContextKind,
  ChatMessageModelData,
  ChatMessageUpsertedSyncUpdateValue,
  GLOBAL_CONTEXT_ID,
  GlobalContext,
  IndexedChatMessage,
} from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SaveChatMessageOperation } from "@/store/sync/operations/chat/SaveChatMessageOperation";
import { SaveDraftNoteOperation } from "@/store/sync/operations/chat/SaveDraftNoteOperation";
import { SubmitGuidedChatMessageOperation } from "@/store/sync/operations/chat/SubmitGuidedChatMessageOperation";
import { SubmitChatMessageSyncOperationChatMessageContextValue } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { liveQuery, Subscription } from "dexie";
import {
  computed,
  makeObservable,
  runInAction,
  action,
  override,
  onBecomeObserved,
  observable,
  onBecomeUnobserved,
} from "mobx";

export class AppStoreChatMessageStore
  extends BaseSyncModelStore<ChatMessageObservable, ChatMessageModelData>
  implements ChatMessageStore
{
  liveQuerySubscription: Maybe<Subscription>;
  sortedChatMessageIndexes: IndexedChatMessage[] = [];
  draftMessagesByConversationId = observable.map<string, string>();
  chatContextsByConversationId = observable.map<string, ChatMessageContext[]>();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatMessage, ...injectedDeps });
    makeObservable<this>(this, {
      getNumMessagesInConversation: false,
      getMostRecentlySentUserMessage: false,
      getMessagesInConversation: true,
      getChatContextsByConversationId: true,
      getChatHistoryById_forGuidedChat: true,
      getContextFromIndexedMessage: true,

      sortedChatMessageIndexes: observable,
      liveQuerySubscription: observable,
      draftMessagesByConversationId: observable,
      chatContextsByConversationId: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,
      setDraftMessage: action,
      getDraftMessage: false,
      clearDraftMessage: action,

      computeIndexes: override,
      createSyncModel: false,
      sendNewMessage: action,
      saveChatMessage: action,
      saveDraftNoteSection: action,
      processSyncUpdate: override,
      processLiveSyncUpdate: action,
      // sortedMessages: computed,
      chatHistory: computed,
      unsentMessages: computed,
      lastSystemMessageId: computed,
      lastUserMessageId: computed,
    });

    onBecomeObserved(this, "sortedChatMessageIndexes", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "sortedChatMessageIndexes", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() =>
      this.localTable
        .orderBy(
          "[chat_conversation_id+locally_created_at+is_system_message+status+context_ids+context_kinds+model_id]"
        )
        .keys()
    ).subscribe({
      next: ids => {
        runInAction(() => {
          this.sortedChatMessageIndexes = ids as unknown as IndexedChatMessage[]; // safe to cast as long as we're sure ids are strings
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  createSyncModel(data: SyncUpdateValue<ChatMessageModelData>): ChatMessageObservable {
    return new ChatMessageObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get lastUserMessageId(): string | undefined {
    return (
      this.sortedChatMessageIndexes
        // this sorting is a bandaid during the guided chat experiment. ideally, let's find the last message _per conversation_ and sort via Dexie
        .toSorted((a, b) => new Date(a[1]).getTime() - new Date(b[1]).getTime())
        .findLast(([, , isSystemMessage]) => !isSystemMessage)?.[6]
    );
  }

  get lastSystemMessageId(): string | undefined {
    return (
      this.sortedChatMessageIndexes
        // this sorting is a bandaid during the guided chat experiment. ideally, let's find the last message _per conversation_ and sort via Dexie
        .toSorted((a, b) => new Date(a[1]).getTime() - new Date(b[1]).getTime())
        .findLast(([, , isSystemMessage]) => isSystemMessage)?.[6]
    );
  }

  get unsentMessages(): ChatMessageObservable[] {
    const result: ChatMessageObservable[] = [];
    for (let i = this.sortedChatMessageIndexes.length - 1; i >= 0; i--) {
      const [, , isSystemMessage, status, , , modelId] = this.sortedChatMessageIndexes[i];
      if (isSystemMessage) {
        continue;
      }

      if (status !== "PROCESSING") {
        break;
      }

      const message = this.get(modelId);
      if (message) {
        result.push(message);
      }
    }

    return result.reverse();
  }

  get chatHistory() {
    // pull together context for primary chat conversation
    const collectionId = this.store.routing.collectionIdParam;
    let context: Maybe<ChatMessageContext>;

    if (collectionId)
      context = {
        kind: ChatMessageContextKind.CollectionDetailView,
        id: collectionId,
        observable: () => this.store.collections.get(collectionId),
      };

    const noteId = this.store.routing.noteIdParam;
    if (noteId) {
      context = {
        kind: ChatMessageContextKind.NoteDetailView,
        id: noteId,
        observable: () => this.store.notes.get(noteId),
      };
    }

    // get the primary chat conversation
    const primaryConversationId = this.store.chatConversations.primaryChatConversationId;
    return this.getChatHistoryById_forGuidedChat(primaryConversationId, context);
  }

  // This multi-conversation-aware getter supports the Guided Chat product experiment.
  // If we keep this, optimize and clean this up and then remove the _forGuidedChat suffix.
  getChatHistoryById_forGuidedChat(
    conversationId: string,
    context?: ChatMessageContext
  ): ChatHistory {
    return new ChatHistory({
      context,
      store: this.store,
      conversationId,
      indexes: this.sortedChatMessageIndexes
        .filter(indexedMessage => {
          // TODO: if we keep this around, we might want to filter at the Dexie level
          return indexedMessage[0] === conversationId;
        })
        .map((indexedMessage: IndexedChatMessage): ChatHistoryIndexes => {
          return {
            chatConversationId: indexedMessage[0],
            locallyCreatedAt: indexedMessage[1],
            isSystemMessage: indexedMessage[2],
            status: indexedMessage[3],
            context: this.getContextFromIndexedMessage(indexedMessage),
            id: indexedMessage[6],
          };
        }),
    });
  }

  getContextFromIndexedMessage(indexedMessage: IndexedChatMessage): ChatMessageContext {
    const globalContext: GlobalContext = {
      kind: ChatMessageContextKind.Global,
      id: GLOBAL_CONTEXT_ID,
    };

    const contextKind = indexedMessage[5].find(
      kind =>
        kind === ChatMessageContextKind.NoteDetailView ||
        kind === ChatMessageContextKind.CollectionDetailView
    ) as Maybe<ChatMessageContextKind>;

    if (!contextKind) {
      return globalContext;
    }

    const contextId = indexedMessage[4][indexedMessage[5].indexOf(contextKind)];
    if (!contextId) {
      return globalContext;
    }

    return {
      kind: contextKind,
      id: contextId,
    } as ChatMessageContext;
  }

  getMessagesInConversation(conversationId: string): ChatMessageObservable[] {
    return this.sortedChatMessageIndexes
      .filter(indexedMessage => indexedMessage[0] === conversationId)
      .map(indexedMessage => this.get(indexedMessage[6]))
      .filter(msg => !!msg);
  }

  async sendNewMessage({
    message,
    agentMode,
    contexts = [],
    conversationId,
    contextStartedAt,
    isGuidedChat_experiment = true,
  }: SendMessageArgs) {
    // If no conversationId is provided, send it as the first message in a new conversation
    if (!conversationId) {
      conversationId =
        await this.store.chatConversations.createOrResumeEmptyGuidedChatConversation();
    }

    const legacyOperationContext: SubmitChatMessageSyncOperationChatMessageContextValue = (() => {
      if (contexts[0]?.kind === ChatMessageContextKind.NoteDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "NOTE_DETAIL_VIEW",
          value: { schema_version: 1, note_id: contexts[0]?.id },
        };
      }
      if (contexts[0]?.kind === ChatMessageContextKind.CollectionDetailView) {
        return {
          context_id: uuidModule.generate(),
          started_at: contextStartedAt,
          kind: "COLLECTION_DETAIL_VIEW",
          value: { schema_version: 1, collection_id: contexts[0]?.id },
        };
      }
      return {
        context_id: uuidModule.generate(),
        started_at: contextStartedAt,
        kind: "GLOBAL",
        value: { schema_version: 1 },
      };
    })();

    const operationContexts: SubmitChatMessageSyncOperationChatMessageContextValue[] =
      isGuidedChat_experiment
        ? [legacyOperationContext, ...contexts.map(chatMentionContextToOperationContext)]
        : [legacyOperationContext];

    const messageId = uuidModule.generate();

    await new SubmitGuidedChatMessageOperation({
      store: this.store,
      payload: {
        id: messageId,
        chat_conversation_id: conversationId,
        content: message,
        contexts: operationContexts,
        agent_mode: agentMode,
      },
    }).execute();

    /**
     * Track the message send event
     */
    trackEvent(TrackedEvent.ChatMessageSend, {
      message_id: messageId,
      message_contents: message,
      context: contexts[0]?.kind,
      note_id:
        contexts[0]?.kind === ChatMessageContextKind.NoteDetailView ? contexts[0]?.id : undefined,
      note_title:
        contexts[0]?.kind === ChatMessageContextKind.NoteDetailView
          ? this.store.notes.get(contexts[0]?.id)?.title
          : undefined,
      collection_id:
        contexts[0]?.kind === ChatMessageContextKind.CollectionDetailView
          ? contexts[0]?.id
          : undefined,
      collection_title:
        contexts[0]?.kind === ChatMessageContextKind.CollectionDetailView
          ? this.store.collections.get(contexts[0]?.id)?.label
          : undefined,
      is_guided_chat: isGuidedChat_experiment ?? false,
      is_agent_mode: agentMode ?? false,
      look_at_kinds: operationContexts
        .filter(context => context.kind !== ChatMessageContextKind.Global)
        .map(context => context.kind),
      num_look_at_contexts: operationContexts.filter(
        context => context.kind !== ChatMessageContextKind.Global
      ).length,
    });
  }

  async saveChatMessage({ chatMessageId, noteId }: { chatMessageId: string; noteId: string }) {
    const chatMessage = await this.getAsync(chatMessageId);
    if (!chatMessage) {
      return;
    }

    const encodedContent = notesModule.convertMdxToEncodedContent(chatMessage.content);

    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(
      new SaveChatMessageOperation({
        store: this.store,
        payload: {
          chat_message_id: chatMessageId,
          saved_note_id: noteId,
          encoded_content: encodedContent,
        },
      })
    );
  }

  async saveDraftNoteSection({
    sectionId,
    chatMessageId,
    noteId,
  }: {
    sectionId: string;
    chatMessageId: string;
    noteId: string;
  }) {
    const chatMessage = this.get(chatMessageId);
    const section = chatMessage?.sectionMap[sectionId];
    if (!chatMessage || !section) return;
    const encodedContent = notesModule.convertMdxToEncodedContent(section.value.content);
    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(
      new SaveDraftNoteOperation({
        store: this.store,
        payload: {
          chat_message_id: chatMessageId,
          chat_message_section_id: sectionId,
          saved_note_id: noteId,
          encoded_content: encodedContent,
        },
      })
    );
  }

  public async processSyncUpdate(update: SyncUpdate<ChatMessageModelData>) {
    await this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(update.value.model_id);
    await super.processSyncUpdate(update);
  }

  async processLiveSyncUpdate(
    sync_operation_id: string,
    value: ChatMessageUpsertedSyncUpdateValue
  ) {
    // We should only have one pending optimistic update for chat messages + there are too many if we leave them
    await this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(value.model_id);
    const optimisticUpdate: OptimisticSyncUpdate<ChatMessageModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: value.model_data.locally_created_at,
      kind: "UPSERTED",
      value: value,
    };
    await this.store.sync.actionQueue.applyOptimisticUpdate(sync_operation_id, optimisticUpdate);
    await this.recompute(optimisticUpdate.value.model_id);
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ChatMessageModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ChatMessageModelData>[];
  }): Record<string, unknown> {
    return new ChatMessageIndexes({ remoteData, optimisticUpdates }).indexes;
  }

  setDraftMessage(conversationId: string, message: string) {
    if (this.draftMessagesByConversationId.get(conversationId) === message) {
      return;
    }
    this.draftMessagesByConversationId.set(conversationId, message);
  }

  getDraftMessage(conversationId: string): string {
    return this.draftMessagesByConversationId.get(conversationId) ?? "";
  }

  clearDraftMessage(conversationId: string) {
    this.draftMessagesByConversationId.delete(conversationId);
  }

  async getMostRecentlySentUserMessage(): Promise<
    Maybe<{
      messageId: string;
      conversationId: string;
      locallyCreatedAt: Date;
    }>
  > {
    const message = await this.localTable
      .orderBy("[locally_created_at+is_system_message+model_id]")
      .reverse()
      .filter(row => !isSystemMessage(row.model_data))
      .first();

    const messageId = message?.model_id;
    const conversationId = message?.model_data.chat_conversation_id;
    const locallyCreatedAt = message?.model_data.locally_created_at;

    if (!messageId || !conversationId || !locallyCreatedAt) return undefined;

    return {
      messageId,
      conversationId,
      locallyCreatedAt: new Date(locallyCreatedAt),
    };
  }

  async getNumMessagesInConversation(conversationId: string): Promise<number> {
    return this.localTable.where("chat_conversation_id").equals(conversationId).count();
  }

  getChatContextsByConversationId(conversationId: string): ChatMessageContext[] {
    // TODO: remove or refactor this in light of permanent contexts that survive refreshes and view-based contexts in ChatView
    return this.chatContextsByConversationId.get(conversationId) ?? [];
  }
}
