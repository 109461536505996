export enum TrackedEvent {
  NoteView = "note_view",
  NotesView = "notes_view",
  NoteCreate = "note_create",
  NoteAddToCollection = "note_add_to_collection",
  NoteShare = "note_share",
  TemplateCreate = "template_create",
  TemplateUsed = "template_used",
  TemplateView = "template_view",
  TemplatesView = "templates_view",
  CollectionView = "collection_view",
  CollectionsView = "collections_view",
  CollectionCreate = "collection_create",
  CollectionShare = "collection_share",
  TemplateShare = "template_share",
  ChatView = "chat_view",
  ChatCreate = "chat_create",
  ChatCreateFromConversationTooLong = "chat_create_from_conversation_too_long",
  ChatResume = "chat_resume",
  ChatSelect = "chat_select",
  ChatMessageSend = "chat_message_send",
  ChatMessageSendFromSuggestion = "chat_message_send_from_suggestion",
  ChatMessageUpvote = "chat_message_upvote",
  ChatMessageDownvote = "chat_message_downvote",
  ChatInputLookAtClick = "chat_input_look_at_click",
  ChatInputAgentModeToggle = "chat_input_agent_mode_toggle",
  ChatInputContextAdd = "chat_input_context_add",
  ChatInputContextRemove = "chat_input_context_remove",
  SearchView = "search_view",
  SearchResultsOpenNote = "search_results_open_note",
  SearchResultsOpenCollection = "search_results_open_collection",
  QuickSearchView = "quick_search_view",
  QuickSearchOpenCollection = "quick_search_open_collection",
  QuickSearchOpenNote = "quick_search_open_note",
  CopilotOpenNote = "copilot_open_note",
  CopilotUpvoteNote = "copilot_upvote_note",
  CopilotDownvoteNote = "copilot_downvote_note",
  ImportFrom1Dot0StartImport = "import_from_1_dot_0_start_import",
  ExportCreate = "export_create",
  PerformanceHeartbeat = "performance_heartbeat",
  SyncErrorShown = "sync_error_shown",
  AppCalloutBannerActionClicked = "app_callout_banner_action_clicked",
  AppCalloutBannerDismissed = "app_callout_banner_dismissed",
  PerformanceBootstrap = "performance_bootstrap",
  AppStoreInitErrorShown = "app_store_init_error_shown",
}
