import { ChangeEventHandler, useMemo, useState } from "react";
import {
  MdsDropdown,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
  MdsDropdownProps,
  MdsDropdownSearchInputHeader,
} from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import {
  MdsMenuItemSelectionVariant,
  MdsMenuItemSize,
} from "@/design-system/components/menu-item/types";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { MdsMenuItem } from "@/design-system/components/menu-item/MdsMenuItem";
import { ContactsFacetFilterItem } from "@/components/filters/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { ToggleButton } from "@/components/filters/ToggleButton/ToggleButton";
import { pluralize } from "@/modules/pluralize";

interface ContactsFacetFilterProps
  extends Omit<MdsDropdownProps, "contentList" | "isOpen" | "onOpenChange" | "children"> {
  buttonLabel?: string;
  buttonPrefix?: string;
  searchText: string;
  onSearchTextChange: ChangeEventHandler<HTMLInputElement>;
  onSearchTextClear: () => void;
  items: ContactsFacetFilterItem[];
  onSelectItem: (id: string) => void;
  onSelectClear: () => void;
}

export const ContactsFacetFilter = ({
  buttonLabel,
  buttonPrefix,
  items,
  searchText,
  onSearchTextChange,
  onSearchTextClear,
  onSelectItem,
  onSelectClear,
  ...dropdownProps
}: ContactsFacetFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedItems = items.filter(item => item.isSelected);
  const hasSelectedItems = selectedItems.length > 0;

  const computedButtonLabel = useMemo(() => {
    const count = selectedItems.length;
    if (buttonLabel) {
      return buttonLabel;
    }

    if (buttonPrefix) {
      if (selectedItems.length === 1) {
        return `${buttonPrefix} ${selectedItems[0].displayName}`;
      }

      if (count > 1) {
        return `${buttonPrefix} ${selectedItems[0].displayName} and ${count - 1} ${pluralize(count - 1, "other", "others")}`;
      }

      return `${buttonPrefix}`;
    }

    if (!count) {
      return "Contacts";
    }

    return `${items.filter(item => item.isSelected).length} Contacts`;
  }, [items, buttonLabel, buttonPrefix, selectedItems]);

  const contentListItems: MdsDropdownItem[] = useMemo(() => {
    const output: MdsDropdownItem[] = [];

    for (const item of items) {
      if (!item.isVisible) {
        continue;
      }

      output.push({
        kind: MdsDropdownItemKind.Other,
        id: item.id,
        content: (
          <MdsMenuItem
            title={item.displayName}
            subtitle={item.emailAddress}
            icon={
              <AccountProfileImageContent
                photoUrl={item.photoUrl}
                displayName={item.displayName}
                size={ProfileSize.Medium}
              />
            }
            size={MdsMenuItemSize.Medium}
            isSelected={!!item.isSelected}
            selectionVariant={MdsMenuItemSelectionVariant.RadioButton}
            onClick={() => onSelectItem(item.id)}
          />
        ),
      });
    }
    return output;
  }, [items, onSelectItem]);

  const contentList: MdsDropdownContentList = useMemo(
    () => ({
      items: contentListItems,
      header: {
        children: (
          <MdsDropdownSearchInputHeader
            onChange={onSearchTextChange}
            onClear={onSearchTextClear}
            value={searchText}
            placeholder="Filter"
          />
        ),
        height: 50,
      },
    }),
    [onSearchTextChange, onSearchTextClear, searchText, contentListItems]
  );

  return (
    <div data-test-id="contacts-facet-filter">
      <MdsDropdown
        contentList={contentList}
        {...dropdownProps}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        innerStyles={{
          Row: { className: rowStyles },
          Content: { className: contentStyles },
        }}
      >
        <ToggleButton
          setIsOpen={setIsOpen}
          iconKind={MdsIconKind.User}
          hasSelectedItems={hasSelectedItems}
          computedButtonLabel={computedButtonLabel}
          onClear={() => {
            onSelectClear();
          }}
        />
      </MdsDropdown>
    </div>
  );
};

const rowStyles = css({
  overflow: "visible",
});

const contentStyles = css({
  maxHeight: 320,
  minWidth: 176,
});
