import { SpaceAccountNoteSuggestion, SpaceAccountNoteSuggestionKind } from "@/store/note/types";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store/utils/hooks";
import { EventContext } from "@/domains/metrics/context";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { motion, AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useState, useMemo } from "react";
import { uuidModule } from "@/modules/uuid";

interface Props {
  suggestions: SpaceAccountNoteSuggestion[];
}

interface SuggestionProps {
  suggestion: SpaceAccountNoteSuggestion;
  index: number;
  direction: number;
}

const CopilotSuggestion = observer(({ suggestion, index }: SuggestionProps) => {
  const { store, pageStore } = useAppStore();

  const note = pageStore.copilotView.activeItem;
  const collection =
    "collection_id" in suggestion.value
      ? store.collections.get(suggestion.value.collection_id)
      : undefined;
  const relatedNote =
    "note_id" in suggestion.value ? store.notes.get(suggestion.value.note_id) : undefined;

  const handleAction = async () => {
    switch (suggestion.kind) {
      case SpaceAccountNoteSuggestionKind.ADD_TO_NEW_COLLECTION: {
        const collectionId = uuidModule.generate();
        await store.collections.createCollection({
          collectionId,
          eventContext: EventContext.SidePanelSmartActions,
          title: suggestion.value.collection_title,
        });
        await note?.collectionList.addCollection({
          collectionId,
          eventContext: EventContext.SidePanelSmartActions,
        });
        break;
      }
      case SpaceAccountNoteSuggestionKind.ADD_TO_EXISTING_COLLECTION:
        await note?.collectionList.addCollection({
          collectionId: suggestion.value.collection_id,
          eventContext: EventContext.SidePanelSmartActions,
        });
        break;
      case SpaceAccountNoteSuggestionKind.ASK_CHAT: {
        const conversationId = await store.chatConversations.createNewGuidedConversation();
        await store.chatMessages.sendNewMessage({
          message: suggestion.value.message,
          conversationId,
          agentMode: true,
        });
        store.sidePanel.goToChat(conversationId);
        break;
      }
      case SpaceAccountNoteSuggestionKind.SEE_RELATED_NOTE:
        store.sidePanel.goToNote({ noteId: suggestion.value.note_id });
        break;
    }
    if (note) store.notes.removeSuggestion(note.id, suggestion.id);
  };

  const suggestionDetails = useMemo(() => {
    switch (suggestion.kind) {
      case SpaceAccountNoteSuggestionKind.ADD_TO_NEW_COLLECTION:
        return {
          iconKind: MdsIconKind.Collection,
          secondaryLabel: "Add to new collection",
          primaryLabel: suggestion.value.collection_title,
        };
      case SpaceAccountNoteSuggestionKind.ADD_TO_EXISTING_COLLECTION:
        return {
          iconKind: MdsIconKind.Collection,
          secondaryLabel: "Add to existing collection",
          primaryLabel: collection?.title || "Untitled Collection",
        };
      case SpaceAccountNoteSuggestionKind.ASK_CHAT:
        return {
          iconKind: MdsIconKind.Message,
          secondaryLabel: "Ask Chat",
          primaryLabel: suggestion.value.message,
        };
      case SpaceAccountNoteSuggestionKind.SEE_RELATED_NOTE:
        return {
          iconKind: MdsIconKind.Document,
          secondaryLabel: "See related note",
          primaryLabel: relatedNote?.title || "Untitled Note",
        };
    }
  }, [suggestion, collection, relatedNote]);

  return (
    <SuggestionButton key={`${suggestion.kind}-${index}`} onClick={handleAction} type="button">
      <MdsIcon kind={suggestionDetails.iconKind} className="suggestion-icon" />
      <LabelContainer>
        <SecondaryLabel>{suggestionDetails.secondaryLabel}</SecondaryLabel>
        <PrimaryLabel>{suggestionDetails.primaryLabel}</PrimaryLabel>
      </LabelContainer>
    </SuggestionButton>
  );
});

export const CopilotSuggestions = observer(({ suggestions }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const handleNext = () => {
    setDirection(-1);
    setCurrentIndex(prev => (prev + 1) % suggestions.length);
  };

  const handlePrevious = () => {
    setDirection(1);
    setCurrentIndex(prev => (prev - 1 + suggestions.length) % suggestions.length);
  };

  if (suggestions.length === 0) return null;

  useEffect(() => {
    if (suggestions.length > 0) setCurrentIndex(0);
  }, [suggestions]);

  return (
    <SuggestionsContainer
      initial={{ opacity: 0, y: 8 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 4 }}
      transition={{
        duration: 0.25,
        ease: [0.32, 0.72, 0, 1],
      }}
    >
      <SuggestionsHeader>
        <MdsIcon kind={MdsIconKind.Radar} className="header-icon" />
        Smart Actions
        <NavigationControls>
          <NavigationButton onClick={handlePrevious} disabled={suggestions.length <= 1}>
            <MdsIcon kind={MdsIconKind.AngleUp} />
          </NavigationButton>
          <SuggestionCounter>{`${currentIndex + 1} / ${suggestions.length}`}</SuggestionCounter>
          <NavigationButton onClick={handleNext} disabled={suggestions.length <= 1}>
            <MdsIcon kind={MdsIconKind.AngleDown} />
          </NavigationButton>
        </NavigationControls>
      </SuggestionsHeader>
      <SuggestionsContent>
        <AnimatePresence initial={false} mode="wait" custom={direction}>
          <CopilotSuggestion
            key={currentIndex}
            suggestion={suggestions[currentIndex]}
            index={currentIndex}
            direction={direction}
          />
        </AnimatePresence>
      </SuggestionsContent>
    </SuggestionsContainer>
  );
});

const NavigationControls = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.xs,
  marginLeft: "auto",
}));

const NavigationButton = styled.button(({ theme }) => ({
  background: "none",
  border: "none",
  padding: theme.spacing.xxs,
  cursor: "pointer",
  color: theme.colors.grey.x500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },

  "&:hover:not(:disabled)": {
    color: theme.colors.grey.x700,
  },
}));

const SuggestionCounter = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.xxsmall,
  color: theme.colors.grey.x500,
}));

const SuggestionsContainer = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing.md,
  backgroundColor: theme.colors.grey.x25,
  borderRadius: theme.spacing.sm,
  overflow: "hidden",
  maxHeight: "40vh",
}));

const SuggestionsHeader = styled.div(({ theme }) => ({
  color: theme.colors.lilac.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
  padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.xs}`,
  borderBottom: `1px solid ${theme.colors.grey.x100}`,
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.xs,
  justifyContent: "space-between",

  ".header-icon": {
    color: theme.colors.lilac.x500,
    fontSize: theme.fontSizes.small,
  },
}));

const SuggestionsContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xs,
  padding: theme.spacing.sm,
  overflowY: "auto",
  willChange: "transform",
}));

const SuggestionButton = styled(motion.button)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing.sm,
  width: "100%",
  padding: `${theme.spacing.sm} ${theme.spacing.md}`,
  backgroundColor: theme.colors.white,
  borderRadius: theme.spacing.sm,
  border: `1px solid ${theme.colors.grey.x100}`,
  transition: "all 0.2s ease-in-out",
  minHeight: "40px",
  cursor: "pointer",
  textAlign: "left",
  "&:hover": {
    outline: `1px solid ${theme.colors.lilac.x300}`,
  },
  ".suggestion-icon": {
    color: theme.colors.lilac.x500,
    flexShrink: 0,
    marginTop: "2px",
  },
}));

const LabelContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
  flex: 1,
  textAlign: "left",
}));

const SecondaryLabel = styled.div(({ theme }) => ({
  color: theme.colors.lilac.x500,
  fontSize: theme.fontSizes.xsmall,
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.xsmall,
  textAlign: "left",
}));

const PrimaryLabel = styled.div(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.xsmall,
  fontWeight: theme.fontWeights.regular,
  whiteSpace: "normal",
  wordBreak: "break-word",
  lineHeight: theme.lineHeights.small,
  textAlign: "left",
}));
