import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsColors, mdsSpacings } from "@/design-system/foundations";
import {
  formatDateString,
  generateRelativePrepositionAdverbialDateString,
  generateShortDateString,
} from "@/domains/date/date";
import { css } from "@/domains/emotion";
import { api } from "@/modules/api";
import { fileModule } from "@/modules/file";
import { DataExportObservable } from "@/store/data-exports/DataExportObservable";
import { DateTime } from "luxon";

interface Props {
  dataExport: DataExportObservable;
}

export const ExportItem = ({ dataExport }: Props) => {
  const handleDownloadClick = async () => {
    try {
      const response = await api.get("/v2/data-exports/{data_export_id}/download-url", {
        params: { path: { data_export_id: dataExport.id } },
      });

      if (response.data?.url) {
        console.log("downloading file:", response.data.url);
        fileModule.downloadFileFromUrlDirectly({
          fileName: `mem-export-${generateShortDateString(DateTime.fromJSDate(dataExport.startedAt))}.zip`,
          downloadUrl: response.data.url,
          inTab: false,
        });
      } else {
        console.error("Failed to get download URL", response.error);
      }
    } catch (error) {
      console.error("Error downloading export", error);
    }
  };

  return dataExport.isRunning ? (
    <div className={downloadContainerStyles}>
      <div className={downloadIconContainerStyles}>
        <MdsIcon
          kind={MdsIconKind.Spinner}
          className={spinnerIconStyles}
          innerStyles={{
            Icon: { className: iconStyles },
          }}
        />
      </div>
      <div className={downloadDetailsStyles}>
        Export started{" "}
        {generateRelativePrepositionAdverbialDateString(DateTime.fromJSDate(dataExport.startedAt))}
      </div>
    </div>
  ) : (
    <div className={downloadContainerStyles}>
      <div className={downloadIconContainerStyles}>
        <MdsIcon
          kind={MdsIconKind.CheckCircleSolid}
          className={iconComponentStyles}
          innerStyles={{
            Icon: { className: iconStyles },
          }}
        />
      </div>
      <div className={downloadDetailsStyles}>
        <span>
          <MdsTooltip
            config={{
              label: formatDateString(DateTime.fromJSDate(dataExport.startedAt), "MM/dd/yy h:mm a"),
              placement: MdsTooltipPlacement.Top,
              delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
            }}
          >
            Exported{" "}
            {generateRelativePrepositionAdverbialDateString(
              DateTime.fromJSDate(dataExport.startedAt)
            )}
          </MdsTooltip>
        </span>
        <MdsButton
          label="Download"
          variant={MdsButtonVariant.Tertiary}
          onClick={handleDownloadClick}
        />
      </div>
    </div>
  );
};

const iconComponentStyles = css({
  color: mdsColors().green.x400,
  width: "1.5em",
  height: 40,
  marginRight: mdsSpacings().xs,
});

const spinnerIconStyles = css({
  width: "1.5em",
  height: 40,
  marginRight: mdsSpacings().xs,
});

const iconStyles = css({
  width: "1.5em",
  height: "1.5em",
});

const downloadContainerStyles = css({
  display: "flex",
  alignItems: "center",
  gap: mdsSpacings().smd,
  width: "100%",
  "&:not(:last-child) > div:last-child": {
    borderBottom: `1px solid ${mdsColors().grey.x50}`,
  },
});

const downloadIconContainerStyles = css({
  paddingBottom: mdsSpacings().md,
  paddingTop: mdsSpacings().sm,
});

const downloadDetailsStyles = css({
  display: "flex",
  alignItems: "center",
  gap: mdsSpacings().smd,
  justifyContent: "space-between",
  paddingBottom: mdsSpacings().md,
  paddingTop: mdsSpacings().sm,
  width: "100%",
});
