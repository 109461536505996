export enum NoteEditorMode {
  // default mode
  Editable = "editable",
  // note is not editable but we still want updated of the content
  ReadOnly = "read-only",
  // note is not editable and we don't want to update the content
  Static = "static",
}

export type MentionTooltipParams = {
  top: number;
  left: number;
  visible: boolean;
  itemId: string;
};

export enum NoteType {
  Note = "note",
  Template = "template",
}
