import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { AccountInfo } from "@/components/layout/components/account-info/AccountInfo";
import { FC, useMemo } from "react";
import { css } from "@/domains/emotion";
import {
  ProfileDropdownItemsHandlers,
  SyncInfo,
  useProfileDropdownItems,
} from "@/components/layout/components/account-profile/useProfileDropdownItems";

export type AccountProfileNavbarCardContentHandlers = ProfileDropdownItemsHandlers;

export interface AccountProfileNavbarCardContentProps {
  accountInfo: {
    profilePhotoUrl: string | null;
    profileDisplayName: string;
    profileEmailAddress: string;
  };
  handlers: AccountProfileNavbarCardContentHandlers;
  isDebugMode?: boolean;
  syncInfo: SyncInfo;
  featureFlags: Record<string, boolean>;
}

export const AccountProfileNavbarCardContent: FC<AccountProfileNavbarCardContentProps> = ({
  accountInfo,
  syncInfo,
  handlers,
  isDebugMode,
}) => {
  const profileDropdownItems = useProfileDropdownItems({
    isDebugMode,
    handlers,
    syncInfo,
  });

  const contentList = useMemo<MdsDropdownContentList>(() => {
    return {
      items: [
        {
          id: "account-info",
          kind: MdsDropdownItemKind.Other,
          content: (
            <AccountInfo
              profilePhotoUrl={accountInfo.profilePhotoUrl}
              profileDisplayName={accountInfo.profileDisplayName}
              profileEmailAddress={accountInfo.profileEmailAddress}
            />
          ),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        ...profileDropdownItems,
      ],
    };
  }, [
    accountInfo.profileDisplayName,
    accountInfo.profileEmailAddress,
    accountInfo.profilePhotoUrl,
    profileDropdownItems,
  ]);

  return <MdsDropdownContent className={dropdownStyles} contentList={contentList} />;
};

const dropdownStyles = css({
  minWidth: 240,
});
