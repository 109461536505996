export enum MdsCardKind {
  Seamless = "seamless",
  Flat = "flat",
  XSmallShadow = "x-small-shadow",
  SmallShadow = "small-shadow",
  LargeShadow = "large-shadow",
}

export enum MdsCardSize {
  Grow = "grow",
  Auto = "auto",
}

export enum MdsCardRadius {
  None = "none",
  Medium = "medium",
  MediumLarge = "medium-large",
  Large = "large",
}

export enum MdsCardBorder {
  Hidden = "hidden",
  Solid = "solid",
  SmartCard = "smart-card",
}

export enum MdsUtilityCardSize {
  Small = "small",
  Medium = "medium",
}
