import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { PlatformIconKind } from "@/domains/api/types";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { useAppBannerLinkOnClickHandler } from "@/components/banners/app-callout-banner/utils/app-banner-link-handling";
import { resolvePlatformIconData } from "@/components/icons";

interface AppCalloutBannerActionButtonProps {
  iconKind: PlatformIconKind | null;
  label: string | null;
  url: string | null;
}

export const AppCalloutBannerActionButton: FC<AppCalloutBannerActionButtonProps> = observer(
  ({ iconKind, label, url }) => {
    if (!label) return null;
    if (!url) return null;

    const { onClickHandler } = useAppBannerLinkOnClickHandler({ url });

    /**
     * We only support MdsIcon-compatible PlatformIconKinds for
     * banner actions. (No custom images, etc.)
     */
    const parsedMdsIconKind = useMemo(() => {
      if (!iconKind) return undefined;

      const { icon } = resolvePlatformIconData({ iconKind });

      return icon;
    }, [iconKind]);

    return (
      <ActionButton
        size={MdsButtonSize.Small}
        variant={MdsButtonVariant.Tertiary}
        label={label}
        onClick={onClickHandler}
        iconKind={parsedMdsIconKind}
      />
    );
  }
);

const ActionButton = styled(MdsButton)({});
