import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateTemplateOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { logger } from "@/modules/logger";

export class UpdateTemplateOperation extends BaseSyncOperation<IUpdateTemplateOperation> {
  private existingTemplate?: TemplateObservable;

  get operationKind(): SyncOperationKind {
    return "UPDATE_TEMPLATE";
  }

  async execute() {
    this.existingTemplate = await this.store.templates.getAsync(this.payload.id);
    await super.execute();
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;

    if (!this.existingTemplate) {
      logger.error({
        message: "Attempted to update template that doesn't exist",
        info: { templateId },
      });
      return [];
    }

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: this.existingTemplate.modelVersion,
      model_data: {
        ...this.existingTemplate.modelData,
        title: this.payload.title ?? this.existingTemplate.modelData.title,
        locally_modified_at: this.committedAt,
        modified_by_space_account_ids: [
          ...this.existingTemplate.modelData.modified_by_space_account_ids,
          this.store.spaceAccounts.myPersonalSpaceAccountId,
        ],
        is_auto_sharing_enabled:
          this.payload.is_auto_sharing_enabled ??
          this.existingTemplate.modelData.is_auto_sharing_enabled,
      },
      model_scopes: this.existingTemplate.modelScopes,
    };
    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
