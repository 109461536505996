import { useAppStore } from "@/store";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { IMDSSwitchVariant, MDSSwitch } from "@/mds/components/forms";
import { BoldedList } from "@/domains/templates/generateTemplateSharingText";

interface TemplatesEditorBannerProps {
  template: TemplateObservable;
}

export const TemplatesEditorBanner = observer<TemplatesEditorBannerProps>(
  function TemplatesEditorBanner({ template }) {
    const { store } = useAppStore();
    const targetScopes = template.modelData.target_scopes;

    const targetScopeCollectionIds = targetScopes
      .filter(scope => scope.scope_kind === "COLLECTION_SCOPE")
      .map(scope => scope.value.collection_id);
    const targetScopeSpaceAccountIds = targetScopes
      .filter(scope => scope.scope_kind === "SPACE_ACCOUNT_SCOPE")
      .map(scope => scope.value.space_account_id)
      .filter(id => id !== store.spaceAccounts.myPersonalSpaceAccountId);

    const targetScopeCollections = store.collections.getMany(targetScopeCollectionIds);
    const targetScopeContacts = store.contacts.getManyBySpaceAccountId(targetScopeSpaceAccountIds);
    const hasTargetScopes = targetScopeCollections.length > 0 || targetScopeContacts.length > 0;
    const isAutoSharingEnabled = template.isAutoSharingEnabled;

    const { privateCollectionNames, sharedNames } = useMemo(() => {
      const privateCollectionNames = targetScopeCollections
        .filter(collection => collection.modelData.shared_at === null)
        .map(collection => collection.label);
      const sharedCollectionNames = targetScopeCollections
        .filter(collection => collection.modelData.shared_at !== null)
        .map(collection => collection.label);
      const contactEmails = targetScopeContacts.map(contact => contact?.profileEmailAddress);
      const sharedNames = [...sharedCollectionNames, ...contactEmails];
      return { privateCollectionNames, sharedNames };
    }, [targetScopeCollections, targetScopeContacts]);

    const headingText = useMemo(() => {
      if (privateCollectionNames.length > 0 && sharedNames.length > 0) {
        return "Automatically organize and share notes created with this template";
      }
      if (privateCollectionNames.length > 0) {
        return "Automatically organize notes created with this template";
      }
      if (sharedNames.length > 0) {
        return "Automatically share notes created with this template";
      }
      return "You're editing a template";
    }, [privateCollectionNames, sharedNames]);

    const sharingText = useMemo(() => {
      return (
        <span>
          Notes created with this template will be{" "}
          {privateCollectionNames.length > 0 && (
            <>
              added to <BoldedList items={privateCollectionNames} />
            </>
          )}
          {privateCollectionNames.length > 0 && sharedNames.length > 0 && " and "}
          {sharedNames.length > 0 && (
            <>
              shared with <BoldedList items={sharedNames} />
            </>
          )}
        </span>
      );
    }, [privateCollectionNames, sharedNames]);

    const handleAutoSharingSwitch = async (value: boolean) => {
      await template.update({ isAutoSharingEnabled: value });
    };

    return (
      <Wrapper>
        <Container hasTargetScopes={hasTargetScopes && isAutoSharingEnabled}>
          <ContainerTop>
            <HeadingText>{headingText}</HeadingText>
            {hasTargetScopes && (
              <SwitchContainer>
                <SwitchLabel>{isAutoSharingEnabled ? "On" : "Off"}</SwitchLabel>
                <MDSSwitch
                  variant={IMDSSwitchVariant.BLACK}
                  defaultSelected={isAutoSharingEnabled}
                  onChange={handleAutoSharingSwitch}
                />
              </SwitchContainer>
            )}
          </ContainerTop>
          {hasTargetScopes && isAutoSharingEnabled && <SharingText>{sharingText}</SharingText>}
        </Container>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.md,
}));

const Container = styled.div<{ hasTargetScopes: boolean }>(({ theme, hasTargetScopes }) => ({
  backgroundColor: hasTargetScopes ? theme.colors.blue.x100 : theme.colors.grey.x25,
  padding: theme.spacing.md,
  borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
}));

const ContainerTop = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const SwitchContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const SwitchLabel = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
}));

const HeadingText = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
}));

const SharingText = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
}));
