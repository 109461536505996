import { CollectionItemSubtitle } from "@/components/collection-item-subtitle/CollectionItemSubtitle";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { observer } from "mobx-react-lite";

export const CollectionItemSubtitleContainer = observer(function CollectionItemSubtitleContainer({
  itemTypeName,
  collection,
}: {
  itemTypeName?: string;
  collection: CollectionObservable;
}) {
  const sizeData = collection.itemList.sizeData;
  const description = collection.description;
  return (
    <CollectionItemSubtitle
      sizeData={sizeData}
      itemTypeName={itemTypeName}
      description={description}
    />
  );
});
