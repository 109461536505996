import { observer } from "mobx-react-lite";
import {
  MdsItemListSkeletonRowData,
  MdsItemListRowType,
  MdsItemListAsyncThreadRowData,
} from "@/design-system/components/item-list/types";
import { FC } from "react";
import { useAppStore } from "@/store";
import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { MdsItemListItemRow } from "@/design-system/components/item-list/rows/MdsItemListItemRow";

export interface MdsItemListAsyncThreadRowProps {
  data: MdsItemListAsyncThreadRowData;
  className?: string;
}

export const MdsItemListAsyncThreadRow: FC<MdsItemListAsyncThreadRowProps> = observer(
  ({ data, className }) => {
    const { store } = useAppStore();
    const thread = store.threads.get(data.payload.threadId);

    const skeletonRowData: MdsItemListSkeletonRowData = {
      key: data.key,
      type: MdsItemListRowType.Skeleton,
      size: data.size,
      payload: {},
    };

    if (!thread) return <MdsItemListSkeletonRow data={skeletonRowData} className={className} />;

    const itemRowData =
      typeof data.payload.itemRow === "function"
        ? data.payload.itemRow(thread)
        : data.payload.itemRow;

    return <MdsItemListItemRow data={itemRowData} className={className} />;
  }
);
