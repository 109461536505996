import { ApiSchema } from "@/modules/api";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { BaseSyncOperationGuestMode } from "@/store/sync/operations/BaseSyncOperationGuestMode";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import { GenericSyncOperationKind } from "@/store/sync/types";
import { MaybeNull } from "@/domains/common/types";
import { MemCommonEditorFileInfo, MemCommonEditorImageInfo } from "@mem-labs/common-editor";
import { BaseExternalOperation } from "@/store/sync/operations/BaseExternalOperation";
import { Uuid } from "@/domains/global/identifiers";

// NOTE OPERATIONS
export type ICreateNoteOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateNoteSyncOperationPayloadRequest"];
export type IUpdateNoteContentUsingDiffOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateNoteContentUsingDiffSyncOperationPayloadRequest"];
export type IDeleteNoteOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelDeleteNoteSyncOperationPayloadRequest"];
export type ITrashNoteOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelTrashNoteSyncOperationPayloadRequest"];
export type IRestoreNoteOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRestoreNoteSyncOperationPayloadRequest"];

// NOTE + TEMPLATE OPERATIONS
export type ICreateNoteUsingTemplateOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateNoteUsingTemplateSyncOperationPayloadRequest"];
export type IUpdateNoteContentUsingTemplateAndDiffOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateNoteContentUsingTemplateAndDiffSyncOperationPayloadRequest"];

// NOTE ACL OPERATIONS
export type IGrantNoteAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantNoteAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IGrantNoteAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantNoteAclViaEmailAddressSyncOperationPayloadRequest"];
export type IUpdateNoteAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateNoteAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IUpdateNoteAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateNoteAclViaEmailAddressSyncOperationPayloadRequest"];
export type IUpdateNoteAclViaCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateNoteAclViaCollectionSyncOperationPayloadRequest"];
export type IRevokeNoteAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRevokeNoteAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IRevokeNoteAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRevokeNoteAclViaEmailAddressSyncOperationPayloadRequest"];

// COLLECTION OPERATIONS
export type ICreateCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateCollectionSyncOperationPayloadRequest"];
export type IUpdateCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateCollectionSyncOperationPayloadRequest"];
export type IDeleteCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelDeleteCollectionSyncOperationPayloadRequest"];
export type IAddNoteToCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelAddNoteToCollectionSyncOperationPayloadRequest"];
export type IRemoveNoteFromCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRemoveNoteFromCollectionSyncOperationPayloadRequest"];

// COLLECTION ACL OPERATIONS
export type IGrantCollectionAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantCollectionAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IGrantCollectionAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantCollectionAclViaEmailAddressSyncOperationPayloadRequest"];
export type IUpdateCollectionAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateCollectionAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IUpdateCollectionAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateCollectionAclViaEmailAddressSyncOperationPayloadRequest"];
export type IRevokeCollectionAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRevokeCollectionAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IRevokeCollectionAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRevokeCollectionAclViaEmailAddressSyncOperationPayloadRequest"];
export type IMakeCollectionPrivateOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelMakeCollectionPrivateSyncOperationPayloadRequest"];

// FAVORITES OPERATIONS
export type IAddNoteToFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelAddNoteToFavoritesSyncOperationPayloadRequest"];
export type IAddCollectionToFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelAddCollectionToFavoritesSyncOperationPayloadRequest"];
export type IAddSavedSearchToFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelAddSavedSearchToFavoritesSyncOperationPayloadRequest"];
export type IRemoveNoteFromFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRemoveNoteFromFavoritesSyncOperationPayloadRequest"];
export type IRemoveCollectionFromFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRemoveCollectionFromFavoritesSyncOperationPayloadRequest"];
export type IRemoveSavedSearchFromFavoritesOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRemoveSavedSearchFromFavoritesSyncOperationPayloadRequest"];
export type IUpdateFavoriteItemSortKeyOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateFavoriteItemSortKeySyncOperationPayloadRequest"];

// RECENTS OPERATIONS
export type IMarkNoteViewedOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelMarkNoteViewedSyncOperationPayloadRequest"];
export type IMarkCollectionViewedOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelMarkCollectionViewedSyncOperationPayloadRequest"];

// CHAT OPERATIONS
export type ICreateChatConversationOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateChatConversationSyncOperationPayloadRequest"];
export type ISubmitChatMessageOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelSubmitChatMessageSyncOperationPayloadRequest"];
export type ISubmitGuidedChatMessageOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelSubmitGuidedChatMessageSyncOperationPayloadRequest"];
export type ISaveDraftNoteOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelSaveDraftNoteSyncOperationPayloadRequest"];
export type ISaveChatMessageOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelSaveChatMessageSyncOperationPayloadRequest"];
export type IProvideChatMessageFeedbackOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelProvideChatMessageFeedbackSyncOperationPayloadRequest"];

export type SubmitChatMessageSyncOperationChatMessageContextValue =
  ApiSchema["SubmitChatMessageSyncOperationChatMessageContextValue"];

// APP CALLOUT OPERATIONS
export type IDismissSpaceAccountAppCalloutOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelDismissSpaceAccountAppCalloutSyncOperationPayloadRequest"];

// API KEY OPERATIONS
export type ICreateApiKeyOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateApiKeySyncOperationPayloadRequest"];

export type IUpdateApiKeyOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateApiKeySyncOperationPayloadRequest"];

export type IDeleteApiKeyOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelDeleteApiKeySyncOperationPayloadRequest"];
// TEMPLATE OPERATIONS
export type ICreateTemplateOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateTemplateSyncOperationPayloadRequest"];
export type IUpdateTemplateOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateTemplateSyncOperationPayloadRequest"];
export type IUpdateTemplateContentUsingDiffOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateTemplateContentUsingDiffSyncOperationPayloadRequest"];
export type IDeleteTemplateOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelDeleteTemplateSyncOperationPayloadRequest"];
export type IMarkTemplateViewedOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelMarkTemplateViewedSyncOperationPayloadRequest"];
export type IMarkTemplateUsedOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelMarkTemplateUsedSyncOperationPayloadRequest"];

// TEMPLATE ACL OPERATIONS
export type IGrantTemplateAclViaEmailAddressOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantTemplateAclViaEmailAddressSyncOperationPayloadRequest"];
export type IGrantTemplateAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelGrantTemplateAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IUpdateTemplateAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateTemplateAclViaSpaceAccountSyncOperationPayloadRequest"];
export type IRevokeTemplateAclViaSpaceAccountOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRevokeTemplateAclViaSpaceAccountSyncOperationPayloadRequest"];

// TEMPLATE +  COLLECTION OPERATIONS
export type IAddTemplateToCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelAddTemplateToCollectionSyncOperationPayloadRequest"];
export type IRemoveTemplateFromCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelRemoveTemplateFromCollectionSyncOperationPayloadRequest"];
export type IUpdateTemplateAclViaCollectionOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelUpdateTemplateAclViaCollectionSyncOperationPayloadRequest"];

// DATA EXPORT OPERATIONS
export type ICreateDataExportOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateDataExportSyncOperationPayloadRequest"];

// UPLOADED FILE BATCH OPERATIONS
export type ICreateUploadedFileBatchOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateUploadedFileBatchSyncOperationPayloadRequest"];
export type ICreateUploadedFileOperation =
  ApiSchema["SubmitSyncOperationRequestSyncModelCreateUploadedFileSyncOperationPayloadRequest"];

export type ISyncOperation =
  | ICreateNoteOperation
  | IUpdateNoteContentUsingDiffOperation
  | IDeleteNoteOperation
  | ITrashNoteOperation
  | IRestoreNoteOperation
  | IGrantNoteAclViaSpaceAccountOperation
  | IGrantNoteAclViaEmailAddressOperation
  | IUpdateNoteAclViaSpaceAccountOperation
  | IUpdateNoteAclViaEmailAddressOperation
  | IUpdateNoteAclViaCollectionOperation
  | IRevokeNoteAclViaSpaceAccountOperation
  | IRevokeNoteAclViaEmailAddressOperation
  | ICreateCollectionOperation
  | IUpdateCollectionOperation
  | IDeleteCollectionOperation
  | IAddNoteToCollectionOperation
  | IRemoveNoteFromCollectionOperation
  | IGrantCollectionAclViaSpaceAccountOperation
  | IGrantCollectionAclViaEmailAddressOperation
  | IUpdateCollectionAclViaSpaceAccountOperation
  | IUpdateCollectionAclViaEmailAddressOperation
  | IRevokeCollectionAclViaSpaceAccountOperation
  | IRevokeCollectionAclViaEmailAddressOperation
  | IMakeCollectionPrivateOperation
  | IAddNoteToFavoritesOperation
  | IAddCollectionToFavoritesOperation
  | IAddSavedSearchToFavoritesOperation
  | IRemoveNoteFromFavoritesOperation
  | IRemoveCollectionFromFavoritesOperation
  | IRemoveSavedSearchFromFavoritesOperation
  | IUpdateFavoriteItemSortKeyOperation
  | IMarkNoteViewedOperation
  | IMarkCollectionViewedOperation
  | ICreateChatConversationOperation
  | ISubmitChatMessageOperation
  | ISubmitGuidedChatMessageOperation
  | ISaveDraftNoteOperation
  | ISaveChatMessageOperation
  | IProvideChatMessageFeedbackOperation
  | IDismissSpaceAccountAppCalloutOperation
  | ICreateApiKeyOperation
  | IUpdateApiKeyOperation
  | IDeleteApiKeyOperation
  | ICreateTemplateOperation
  | IUpdateTemplateOperation
  | IUpdateTemplateContentUsingDiffOperation
  | IDeleteTemplateOperation
  | IGrantTemplateAclViaEmailAddressOperation
  | IGrantTemplateAclViaSpaceAccountOperation
  | IUpdateTemplateAclViaSpaceAccountOperation
  | IRevokeTemplateAclViaSpaceAccountOperation
  | IAddTemplateToCollectionOperation
  | IRemoveTemplateFromCollectionOperation
  | IUpdateTemplateAclViaCollectionOperation
  | ICreateNoteUsingTemplateOperation
  | IUpdateNoteContentUsingTemplateAndDiffOperation
  | IMarkTemplateViewedOperation
  | IMarkTemplateUsedOperation
  | ICreateDataExportOperation
  | ICreateUploadedFileBatchOperation;

// GUEST MODE OPERATIONS
export const GUEST_MODE_SUPPORTED_SYNC_OPERATIONS = [
  "UPDATE_NOTE_CONTENT_USING_DIFF",
  "GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS",
  "UPDATE_NOTE_ACL_VIA_EMAIL_ADDRESS",
  "REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS",
] as const;

export type GuestModeSupportedSyncOperationKind =
  (typeof GUEST_MODE_SUPPORTED_SYNC_OPERATIONS)[number];

// TODO - Update when guest mode support is implemented
export type IUpdateNoteContentUsingDiffOperationGuestMode = IUpdateNoteContentUsingDiffOperation;
export type IGrantNoteAclViaEmailAddressOperationGuestMode = IGrantNoteAclViaEmailAddressOperation;
export type IUpdateNoteAclViaEmailAddressOperationGuestMode =
  IUpdateNoteAclViaEmailAddressOperation;
export type IRevokeNoteAclViaEmailAddressOperationGuestMode =
  IRevokeNoteAclViaEmailAddressOperation;

export type ISyncOperationGuestMode =
  | IUpdateNoteContentUsingDiffOperationGuestMode
  | IGrantNoteAclViaEmailAddressOperationGuestMode
  | IUpdateNoteAclViaEmailAddressOperationGuestMode
  | IRevokeNoteAclViaEmailAddressOperationGuestMode;

export enum ExternalOperationKind {
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  UPLOAD_FILE = "UPLOAD_FILE",
}

export type IUploadImageExternalOperation = {
  id: Uuid;
  client_id: string;
  locally_committed_at: string;
  operation_kind: ExternalOperationKind.UPLOAD_IMAGE;
  payload: {
    schema_version: 1;
    info: MemCommonEditorImageInfo;
  };
};

export type IUploadFileExternalOperation = {
  id: Uuid;
  client_id: string;
  locally_committed_at: string;
  operation_kind: ExternalOperationKind.UPLOAD_FILE;
  payload: {
    schema_version: 1;
    info: MemCommonEditorFileInfo;
  };
};

export type IExternalOperation = IUploadImageExternalOperation | IUploadFileExternalOperation;

export type SyncOperation = BaseSyncOperation<ISyncOperation>;

export type SyncOperationGuestMode = BaseSyncOperationGuestMode<
  ISyncOperation,
  ISyncOperationGuestMode
>;

export type ExternalOperation = BaseExternalOperation<IExternalOperation>;

export type SyncOperationGeneric = BaseSyncOperationGeneric<
  ISyncOperation | ISyncOperationGuestMode | IExternalOperation
>;

export interface SerializedSyncOperation {
  operationKind: GenericSyncOperationKind;
  payload:
    | ISyncOperation["payload"]
    | ISyncOperationGuestMode["payload"]
    | IExternalOperation["payload"];
  operationId: string;
  committedAt: string;
  latestSpaceAccountSequenceId: MaybeNull<number>;
  modelId: MaybeNull<string>;
  noteId: MaybeNull<string>;
  collectionId: MaybeNull<string>;
  templateId: MaybeNull<string>;
  primaryLabel: MaybeNull<string>;
  secondaryLabel: MaybeNull<string>;
}
