import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { PlatformIconKind } from "@/domains/api/types";
import { AppCalloutBannerIcon } from "@/components/banners/app-callout-banner/app-callout-banner-icon";
import { AppCalloutBannerPrimaryLabel } from "@/components/banners/app-callout-banner/app-callout-banner-primary-label";
import { AppCalloutBannerSecondaryLabel } from "@/components/banners/app-callout-banner/app-callout-banner-secondary-label";
import { AppCalloutBannerActionButton } from "@/components/banners/app-callout-banner/app-callout-banner-action-button";
import { MdsCard, MdsCardKind } from "@/design-system/components/card";
import { MdsIconButton, MdsIconButtonSize } from "@/design-system/components/icon-button";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsBorders } from "@/design-system/foundations";

interface AppCalloutBannerProps {
  iconKind: PlatformIconKind | null;
  isDismissible: boolean;
  primaryLabel: string;
  secondaryLabel: string;
  inlineActionLabel: string | null;
  inlineActionUrl: string | null;
  primaryActionLabel: string | null;
  primaryActionUrl: string | null;
  primaryActionIconKind: PlatformIconKind | null;
  secondaryActionLabel: string | null;
  secondaryActionUrl: string | null;
  secondaryActionIconKind: PlatformIconKind | null;
  handleDismiss: () => Promise<void>;
}

export const AppCalloutBannerContent: FC<AppCalloutBannerProps> = observer(
  ({
    iconKind,
    isDismissible,
    primaryLabel,
    secondaryLabel,
    inlineActionLabel,
    inlineActionUrl,
    primaryActionLabel,
    primaryActionUrl,
    primaryActionIconKind,
    secondaryActionLabel,
    secondaryActionUrl,
    secondaryActionIconKind,
    handleDismiss,
  }) => {
    return (
      <Wrapper>
        <StyledCard kind={MdsCardKind.XSmallShadow}>
          <LeftSection>
            <AppCalloutBannerIcon iconKind={iconKind} />
            <ContentSection>
              <AppCalloutBannerPrimaryLabel label={primaryLabel} />
              <AppCalloutBannerSecondaryLabel
                label={secondaryLabel}
                inlineActionLabel={inlineActionLabel}
                inlineActionUrl={inlineActionUrl}
              />
              <ActionButtons>
                <AppCalloutBannerActionButton
                  label={primaryActionLabel}
                  url={primaryActionUrl}
                  iconKind={primaryActionIconKind}
                />
                <AppCalloutBannerActionButton
                  label={secondaryActionLabel}
                  url={secondaryActionUrl}
                  iconKind={secondaryActionIconKind}
                />
              </ActionButtons>
            </ContentSection>
          </LeftSection>
          <RightSection>
            {isDismissible && (
              <DismissButton
                iconKind={MdsIconKind.Exit}
                size={MdsIconButtonSize.Small}
                onClick={handleDismiss}
              />
            )}
          </RightSection>
        </StyledCard>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  width: "100%",
  paddingLeft: 16,
  paddingRight: 16,
});

const StyledCard = styled(MdsCard)({
  flexWrap: "nowrap",
  borderRadius: "12px",
  height: "100%",
  width: "100%",
  minWidth: "320px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  flexGrow: 1,
  border: mdsBorders().default,
  containerType: "inline-size",
  containerName: "appCalloutBanner",
});

const LeftSection = styled.div({
  flexWrap: "nowrap",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingLeft: "16px",
  paddingTop: "16px",
  "@container appCalloutBanner (min-width: 440px)": {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: "0px",
  },
});

const ContentSection = styled.div({
  paddingTop: "0px",
  paddingBottom: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  "@container appCalloutBanner (min-width: 440px)": {
    paddingTop: "24px",
  },
});

const RightSection = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const ActionButtons = styled.div({
  flexWrap: "wrap",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "8px",
});

const DismissButton = styled(MdsIconButton)({
  margin: "8px",
});
