import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { StyledBanner, StyledRightContainer } from "@/mds/components/banner/banner.style";
import { MdsBannerProps, MDSBannerSlotName } from "./banner.types";
import { MdsIconButton, MdsIconButtonVariant } from "@/design-system/components/icon-button";
import { Children, isValidElement, ReactElement, ReactNode } from "react";

export const MDSBanner = ({ kind, message, iconKind, children, onDismiss }: MdsBannerProps) => {
  return (
    <StyledBanner kind={kind} showDismissBtn={!!onDismiss}>
      {iconKind && <MdsIcon kind={iconKind} />}
      {getSlot(children, MDSBannerSlotName.Left)}
      <p>{message}</p>

      <StyledRightContainer>
        {getSlot(children, MDSBannerSlotName.Right)}
        {onDismiss && (
          <MdsIconButton
            iconKind={MdsIconKind.Exit}
            variant={MdsIconButtonVariant.FullyTransparent}
            onClick={onDismiss}
          />
        )}
      </StyledRightContainer>
    </StyledBanner>
  );
};

const getSlot = (children: ReactNode, slot: MDSBannerSlotName) => {
  return Children.toArray(children).find(
    (child): child is ReactElement => isValidElement(child) && child.props.slot === slot
  );
};
