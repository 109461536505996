import { MdsButtonShape } from "@/design-system/components/button/types";
import { MdsButtonVariant } from "@/design-system/components/button/types";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { MdsButtonSize } from "@/design-system/components/button/types";
import { useAppStore } from "@/store/utils/hooks";
import { EmptyText } from "@/components/styling/empty";
import { observer } from "mobx-react-lite";

interface Props {
  isSidePanel: boolean;
}

export const CollectionViewPageListEmptyButton = observer<Props>(({ isSidePanel }) => {
  const { store, pageStore } = useAppStore();
  const page = isSidePanel ? store.sidePanel.collectionsViewPage : pageStore.collectionsViewPage;
  const canWrite =
    !page.collectionObservable?.isDeleted && page.collectionObservable?.canWrite == true;

  const { filters } = page;

  if (filters.hasAnyFilters) {
    return (
      <>
        <EmptyText>No results match your current filters</EmptyText>
        <MdsButton
          label="Clear all filters"
          variant={MdsButtonVariant.FilledDark}
          shape={MdsButtonShape.Square}
          size={MdsButtonSize.Medium}
          onClick={() => filters.clearAllFilters()}
        />
      </>
    );
  }

  return (
    <>
      <EmptyText>
        No notes in this collection yet.{" "}
        {canWrite && "Create a new note in this collection or add an existing one."}
      </EmptyText>
    </>
  );
});
