import { Uuid } from "@/domains/global/identifiers";
import { SearchSuggestion, SearchSuggestionIndexData } from "@/domains/search";
import uFuzzy from "@leeoniya/ufuzzy";
import { searchSort } from "@/modules/search-sort";
import { AppStore } from "@/store";
import { getAllWords } from "@/domains/search/get-all-words";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";

export class Search {
  private readonly store: AppStore;
  private readonly fuzzy: uFuzzy;

  constructor({ store }: { store: AppStore }) {
    this.store = store;
    this.fuzzy = new uFuzzy();
  }

  public get db() {
    return this.store.memDb;
  }

  public async forSuggestions(
    str: string,
    sortOption: "mentions" | "default" = "default",
    and?: (item: SearchSuggestion) => boolean,
    limit: number = 100
  ): Promise<SearchSuggestion[]> {
    if (!str) {
      return [];
    }

    let sortBy = "sortKey";
    if (sortOption === "mentions") {
      sortBy = "mentionKey";
    }

    const words = getAllWords(str);
    let query = this.db.searchSuggestions.where("labelWords").startsWithAnyOf(words);

    if (and) {
      query = query.and(and);
    }

    const results = await query
      .filter(suggestion => words.every(word => suggestion.lowercaseLabel.includes(word)))
      .distinct()
      .reverse()
      .sortBy(sortBy);

    // Hoist results that start with any of the search words to the top,
    // within this group of results, sort matches by shortest to longest title
    const sortedResults = results.sort((a, b) => {
      const query = str.toLowerCase();
      const aStartsWith = a.lowercaseLabel.startsWith(query);
      const bStartsWith = b.lowercaseLabel.startsWith(query);

      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      if (aStartsWith && bStartsWith) return a.label.length - b.label.length;

      // Maintain existing sort order for non-prefix matches
      return 0;
    });

    return sortedResults.slice(0, limit);
  }

  // TODO: extract this to a separate module
  public inMemory(query: string, items: SearchSuggestion[]): SearchSuggestion[] {
    const haystack = items.map(item => item.label);
    const indexes = this.fuzzy.filter(haystack, query);

    return indexes?.map(index => items[index]).sort(searchSort) || [];
  }

  public async updateSuggestion(suggestion: SearchSuggestion) {
    logger.info({
      message: `[Search] updateSuggestion ${suggestion.modelId}`,
      info: {
        suggestion_id: suggestion.modelId,
        suggestion: objectModule.safeAsJson({ ...suggestion }),
      },
    });

    try {
      if (!suggestion.isAvailable) {
        await this.remove(suggestion.modelId);
        return;
      }

      await this.db.searchSuggestions.put(this.calculateIndexes(suggestion));
    } catch (error) {
      const err = error as Error;
      logger.error({
        message: `[Search] updateSuggestion error ${suggestion.modelId}`,
        info: {
          suggestion_id: suggestion.modelId,
          suggestion: objectModule.safeAsJson({ ...suggestion }),
          error: objectModule.safeErrorAsJson(err),
        },
      });
    }
  }

  public async bulkUpdateSuggestions(suggestions: SearchSuggestion[]) {
    const toDelete = suggestions.filter(suggestion => !suggestion.isAvailable).map(e => e.modelId);
    const toPut = suggestions
      .filter(suggestion => suggestion.isAvailable)
      .map(this.calculateIndexes);

    await this.db.transaction("rw", [this.db.searchSuggestions], () => {
      logger.info({
        message: `[Search] bulkUpdateSuggestions put`,
        info: {
          suggestion_ids: toPut.map(p => p.modelId),
          suggestions: objectModule.safeToJson({ items: toPut }),
        },
      });

      this.db.searchSuggestions.bulkPut(toPut);

      logger.info({
        message: `[Search] bulkUpdateSuggestions delete`,
        info: {
          ids: toDelete,
        },
      });

      this.db.searchSuggestions.bulkDelete(toDelete);

      logger.info({
        message: `[Search] bulkUpdateSuggestions commit`,
      });
    });
  }

  public async remove(modelId: Uuid) {
    await this.db.transaction("rw", [this.db.searchSuggestions], () => {
      // Returning Dexie's PromiseExtended to avoid PrematureCommitError.
      return this.db.searchSuggestions.delete(modelId);
    });
  }

  private calculateIndexes(suggestion: SearchSuggestion): SearchSuggestionIndexData {
    return {
      ...suggestion,
      label: suggestion.label,
      lowercaseLabel: suggestion.label.toLowerCase(),
      labelWords: getAllWords(suggestion.label),
    };
  }
}
