import { AssertTrue, ValidateEnumIsSubtype } from "@/domains/typing/types";
import { ApiSchema } from "@/modules/api";

type PlatformIconKindValues =
  ApiSchema["SpaceAccountAppCalloutSyncModelDataResponseSchema"]["icon_kind"];

/**
 * Matches a subset of the values from the server.
 * Clients match these to their own icon kinds.
 * (e.g. FontAwesome, SF Symbols, etc.)
 */
export enum PlatformIconKind {
  DownloadLaptopMobile = "DOWNLOAD_LAPTOP_MOBILE",
  Laptop = "LAPTOP",
  Mobile = "MOBILE",
  Chat = "CHAT",
}

type _ValidatePlatformIconKindValues = AssertTrue<
  ValidateEnumIsSubtype<PlatformIconKind, PlatformIconKindValues>
>;

type ClientPlatformKindValues = ApiSchema["ClientPlatformKind"];

export enum ClientPlatformKind {
  Web = "web",
  MacOS = "macos",
  iOS = "ios",
  Internal = "internal",
  Unknown = "unknown",
}

type _ValidateClientPlatformKindValues = AssertTrue<
  ValidateEnumIsSubtype<ClientPlatformKind, ClientPlatformKindValues>
>;
