import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { SyncErrorModalFields } from "@/store/sync/operations/errors/SyncErrorModalFields";

export interface MessageModal {
  title: string;
  message: string;
  buttonLabel: string;
  handleClose(): void;
  handleButtonClick(): void;
  secondaryButton?: {
    label: string;
    onClick: () => void;
  };
}

export enum ModalDefinitionKind {
  Message = "Message",
  SyncError = "SyncError",
  ShareSheet = "ShareSheet",
  FileUploadRejected = "FileUploadRejected",
  ImageUploadRejected = "ImageUploadRejected",
  UpgradedUserEmailMismatch = "UpgradedUserEmailMismatch",
}

export type MessageModalDefinition = {
  kind: ModalDefinitionKind.Message;
  modal: MessageModal;
};

export type SyncErrorModalDefinition = {
  kind: ModalDefinitionKind.SyncError;
  syncError: SyncErrorModalFields;
};

export type ShareSheetModalDefinition = {
  kind: ModalDefinitionKind.ShareSheet;
  store: ShareSheetModalStore;
};

export type UpgradedUserEmailMismatchModalDefinition = {
  kind: ModalDefinitionKind.UpgradedUserEmailMismatch;
};

export type ModalDefinition =
  | MessageModalDefinition
  | SyncErrorModalDefinition
  | ShareSheetModalDefinition
  | UpgradedUserEmailMismatchModalDefinition;
