import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { MdsText, MdsTextWeight, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations/colors";

interface AppCalloutBannerPrimaryLabelProps {
  label: string;
}

export const AppCalloutBannerPrimaryLabel: FC<AppCalloutBannerPrimaryLabelProps> = observer(
  ({ label }) => {
    if (!label) return null;

    return (
      <PrimaryLabelText
        size={MdsTextSize.Medium}
        weight={MdsTextWeight.SemiBold}
        color={mdsColors().grey.x600}
        lineHeight={MdsTextSize.Medium}
      >
        {label}
      </PrimaryLabelText>
    );
  }
);

const PrimaryLabelText = styled(MdsText)({
  marginBottom: "4px",
});
