import { MdsShortcutKeyCharacter } from "@/design-system/components/shortcut-key/types";
import styled from "@emotion/styled";

interface MdsShortcutKeyProps {
  character: MdsShortcutKeyCharacter;
}

const shortcutKeyCharacterMap = {
  [MdsShortcutKeyCharacter.Command]: "⌘",
  [MdsShortcutKeyCharacter.C]: "C",
};

export const MdsShortcutKey = ({ character }: MdsShortcutKeyProps) => {
  return (
    <ShortcutKeyWrapper>
      <ShortcutKeyText>{shortcutKeyCharacterMap[character]}</ShortcutKeyText>
    </ShortcutKeyWrapper>
  );
};

const ShortcutKeyWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "16px",
  width: "16px",
  padding: "1px 3px",
  borderRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.grey.x100}`,
}));

const ShortcutKeyText = styled.span(({ theme }) => ({
  fontSize: "11px",
  color: theme.colors.grey.x500,
  lineHeight: theme.lineHeights.xxsmall,
}));
