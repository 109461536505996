import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import {
  MdsFloatingDropdownContent,
  MdsFloatingDropdownContentProps,
} from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
  mdsSpacings,
} from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import {
  MemCommonRichTextInputInstance,
  MemCommonRichTextInputInitializer,
  MemCommonRichTextInputActionKind,
  MemCommonMentionKeyDownKind,
  MemCommonMentionKind,
  MemCommonRichTextInputEvent,
  MemCommonRichTextInputEventKind,
  MemCommonRichTextInput,
  MemCommonEditorTheme,
  MemCommonCloseMentionCondition,
  MemCommonEditorSlashCommand,
} from "@mem-labs/common-editor";
import { observer } from "mobx-react-lite";
import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useLayoutEffect,
  useEffect,
  MouseEventHandler,
} from "react";
import useMeasure from "react-use/lib/useMeasure";
import styled from "@emotion/styled";
import { useDebounceCallback } from "usehooks-ts";
import { useAsync } from "@/modules/use-async";
import { Maybe } from "@/domains/common/types";
import { CommandIds } from "@/store/chat/ChatHistory";
import { ZIndex } from "@/domains/design/constants";
import { makeVisibleChips, findRemovedMentionIds } from "@/pages/chat/lib";
import { MdsHorizontalDivider } from "@/design-system/components/divider";
import { MdsButtonChip } from "@/design-system/components/chip/button";
import { ChatMessageContext } from "@/store/chat/types";
import { ChatContextChip } from "@/pages/chat/ChatContextChip";
import { AgentModeChip } from "@/pages/chat/AgentModeChip";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { MdsInput } from "@/design-system/components/input/MdsInput";

export interface MentionChip {
  id: string;
  kind?: MemCommonMentionKind;
  className?: MdsDropdownButtonItem["className"];
  iconKind: MdsDropdownButtonItem["iconKind"];
  label: MdsDropdownButtonItem["label"];
  subtitle?: string;
  content?: MdsDropdownButtonItem["content"];
  beforeSelection?: () => Promise<void>;
  alwaysVisible?: boolean;
  groupTitle?: string;
  action?: MemCommonEditorSlashCommand | (() => Promise<Maybe<MemCommonEditorSlashCommand>>);
  featuredIndex?: number;
  metadata?: string;
}

export interface ChatInputProps {
  className?: string;
  conversationId: string;
  hasSomePermanentContext: boolean;
  getAvailableChips: (
    mentionQuery: string,
    opts?: { unifiedChatMentionMode?: boolean }
  ) => Promise<MentionChip[]>;
  onHeight?: (height: number) => void;
  onSubmit: (payload: {
    markdownContent: string;
    htmlContent: string;
    isGuidedChat_experiment: boolean;
    agentMode: boolean;
  }) => void;
  onDraftChange?: (draft?: string) => void;
  inSidePanel?: boolean;
  isGuidedChat_experiment: boolean;
  getDraftMessage?: () => string | undefined;
  addMentionToContexts: (mention: MentionChip) => void;
  removeMentionFromContexts: (mention: ChatMessageContext) => void;
  contexts: ChatMessageContext[];
  isDisabled?: boolean;
}

export const ChatInput = observer<ChatInputProps>(function RichTextAreaInput({
  conversationId,
  className,
  getAvailableChips,
  hasSomePermanentContext,
  onHeight,
  onSubmit,
  onDraftChange,
  isGuidedChat_experiment,
  getDraftMessage,
  addMentionToContexts,
  contexts,
  removeMentionFromContexts,
  isDisabled,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isAgentMode, setIsAgentMode] = useState(true);

  const latestDraftMessage = useRef<string | undefined>();
  const draftContentSentLock = useRef(false); // prevent sending the initial message multiple times
  const chatInputRef = useRef<MemCommonRichTextInputInstance>(null);
  const chatInputInitializer: MemCommonRichTextInputInitializer = useMemo(() => {
    return async () => ({
      autoFocus: !isDisabled,
      autoSubmit: true,
      theme: MemCommonEditorTheme.Light,
      isDisabled: isDisabled,
    });
  }, [isDisabled]);

  const removeDeletedMentionsRef = useRef<(beforeHtml: string, afterHtml: string) => void>(
    (_b, _a) => {}
  );

  // Update the removeDeletedMentionsRef function when the contexts or hasSomePermanentContext prop changes.
  // This is a workaround for the fact that MemCommonRichTextInput is not reactive, so its callbacks can not
  // use React state or props.
  // TODO: remove this work-around after Linear 11144: https://linear.app/mem-labs/issue/MEM-11144
  useEffect(() => {
    removeDeletedMentionsRef.current = (beforeHtml, afterHtml) => {
      // Find IDs of mentions that were removed from the draft
      const removedMentionIds = findRemovedMentionIds(beforeHtml, afterHtml);
      if (removedMentionIds.length === 0) return;

      // Remove each context whose ID is in the removedMentionIds list
      for (const ctx of [...contexts]) {
        if (removedMentionIds.includes(ctx.id)) {
          removeMentionFromContexts(ctx);
          if (contexts.length < 2 && !hasSomePermanentContext) {
            // when we remove the last context, go into agent mode implicitly
            setIsAgentMode(true);
          }
        }
      }
    };
  }, [contexts, hasSomePermanentContext, removeMentionFromContexts]);

  const sendSubmitAction = useCallback((event?: MouseEvent) => {
    event?.stopPropagation();
    chatInputRef.current?.dispatchAction({
      kind: MemCommonRichTextInputActionKind.SendContents,
      payload: null,
    });
  }, []);

  const [mentionQuery, setMentionQuery] = useState("");
  const [menuOpenVia, setMenuOpenVia] = useState<"button" | "keyboard" | null>(null);

  const lockedMouseYRef = useRef(0);
  const mouseYRef = useRef(0);
  const handleMouseMove: MouseEventHandler<HTMLDivElement> = useDebounceCallback(
    event => {
      mouseYRef.current = event.clientY;
      if (Math.abs(lockedMouseYRef.current - mouseYRef.current) > 2) {
        // Mouse was moved, unlock hover to select.
        lockedMouseYRef.current = 0;
      }
    },
    20,
    { maxWait: 40 }
  );

  const [keyboardSelectionChipIndex, setKeyboardSelectedChipIndex] = useState(-1);

  const [
    scrollIntoViewMentionKeyboardSelectionChipIndex,
    setScrollIntoViewMentionKeyboardSelectionChipIndex,
  ] = useState(false);

  const {
    result: { availableChips, visibleChips } = { availableChips: [], visibleChips: [] },
    invoke: invokeChipGetter,
  } = useAsync({
    fn: async (mentionQuery: string) => {
      const availableChips = await getAvailableChips(mentionQuery, {
        unifiedChatMentionMode: true,
      });
      const visibleChips = makeVisibleChips(availableChips ?? []);

      return {
        availableChips,
        visibleChips,
      };
    },
    options: { defaultValue: { availableChips: [], visibleChips: [] } },
  });

  const handleKeyboardUp = useCallback(() => {
    setKeyboardSelectedChipIndex(index => {
      if (index < 1) return visibleChips.length - 1;
      return index - 1;
    });
  }, [visibleChips]);

  const handleKeyboardDown = useCallback(() => {
    setKeyboardSelectedChipIndex(index => {
      if (index >= visibleChips.length - 1) return 0;
      return index + 1;
    });
  }, [visibleChips]);

  // Auto-focus the chat input when the conversationId prop changes
  useEffect(() => {
    if (isDisabled) return;

    chatInputRef.current?.dispatchAction({
      kind: MemCommonRichTextInputActionKind.FocusEditor,
      payload: null,
    });
  }, [conversationId, isDisabled]);

  useEffect(() => {
    invokeChipGetter(mentionQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionQuery]);

  useEffect(() => {
    // Reset mentions menu.
    setKeyboardSelectedChipIndex(availableChips?.length ? 0 : -1);
    setScrollIntoViewMentionKeyboardSelectionChipIndex(!!availableChips?.length);
    lockedMouseYRef.current = mouseYRef.current;
  }, [availableChips]);

  useEffect(() => {
    // as soon as we can, send the draft content to the chat input
    const interval = setInterval(() => {
      // only do this all if a getDraftMessage prop was provided
      if (!getDraftMessage) {
        clearInterval(interval);
        return;
      }

      // wait until the chat input ref is available and we can send actions to it
      const dispatchAction = chatInputRef.current?.dispatchAction;
      if (!dispatchAction) return;

      const draftMessage = getDraftMessage();
      if (!draftMessage) {
        clearInterval(interval);
        return;
      }

      // if the initial message has already been sent, don't do anything else
      if (draftContentSentLock.current) {
        clearInterval(interval);
        return;
      }

      if (draftMessage === latestDraftMessage.current) {
        // This component can get re-rendered for reasons other than the content changing,
        // such as selection change, @-mention menus appearing, etc.
        // We skip those updates to avoid spamming Common Editor with false updates.
        clearInterval(interval);
        return;
      }

      // send the initial message to the chat input
      draftContentSentLock.current = true;
      latestDraftMessage.current = draftMessage;

      dispatchAction({
        kind: MemCommonRichTextInputActionKind.SetEditorContent,
        payload: {
          content: draftMessage,
        },
      });
    }, 100);

    return () => {
      draftContentSentLock.current = false;
      clearInterval(interval);
    };
  });

  const availableChipsRef = useRef(availableChips);
  availableChipsRef.current = availableChips;

  const keyboardSelectionChipIndexRef = useRef(keyboardSelectionChipIndex);
  keyboardSelectionChipIndexRef.current = keyboardSelectionChipIndex;

  const handleSelectMention = useCallback(
    async (option?: MentionChip) => {
      const dispatchAction = chatInputRef.current?.dispatchAction;
      if (!availableChipsRef.current) return;

      const mention = (() => {
        if (option) return option;
        return visibleChips[Math.max(keyboardSelectionChipIndexRef.current, 0)];
      })();

      if (!mention) return;

      const action = typeof mention.action === "function" ? await mention.action() : mention.action;
      if (!action && typeof mention.action === "function") return;

      mention.beforeSelection?.();
      addMentionToContexts(mention);

      setMentionQuery("");
      setMentionClientRect(undefined);
      setMenuOpenVia(null);

      dispatchAction?.({
        kind: MemCommonRichTextInputActionKind.CloseMention,
        payload: {
          id: mention.id,
          kind: mention.kind ?? MemCommonMentionKind.Note,
          label: mention.label,
          action,
          metadata: mention.metadata,
        },
      });

      chatInputRef.current?.dispatchAction({
        kind: MemCommonRichTextInputActionKind.FocusEditor,
        payload: null,
      });
    },
    [addMentionToContexts, visibleChips]
  );

  const contentList = useMemo<MdsDropdownContentList>(() => {
    if (!availableChips) return { items: [] };

    const theseChips = isGuidedChat_experiment ? visibleChips : availableChips;
    const scrollableItems: MdsDropdownItem[] = [];
    const scrollable: MdsDropdownItem = {
      id: "scrollable",
      kind: MdsDropdownItemKind.ScrollableSection,
      items: scrollableItems,
      className: scrollableMentionStyles,
    };
    const contentList: MdsDropdownContentList = {
      items: [],
    };
    const unsupportedCommandIds: string[] = [CommandIds.InsertFile, CommandIds.InsertImage];
    let lastGroupTitle = "";
    theseChips.forEach((mention, index) => {
      if (unsupportedCommandIds.includes(mention.id)) return;

      let items = scrollableItems;
      if (mention.alwaysVisible && scrollableItems.length) {
        items = contentList.items;
        items.push({
          id: mention.id + "-separator",
          kind: MdsDropdownItemKind.Divider,
        });
      }
      const { groupTitle } = mention;
      if (groupTitle && groupTitle !== lastGroupTitle) {
        items.push({
          id: `group-${groupTitle}`,
          kind: MdsDropdownItemKind.Detail,
          text: groupTitle,
          className: groupTitleStyles,
        });
        lastGroupTitle = groupTitle;
      }
      items.push({
        id: mention.id,
        kind: MdsDropdownItemKind.Button,
        iconKind: mention.iconKind,
        content: mention.content,
        label: mention.label,
        scrollIntoView:
          scrollIntoViewMentionKeyboardSelectionChipIndex && index === keyboardSelectionChipIndex,
        className: cx(
          mention.className,
          index === keyboardSelectionChipIndex ? selectedOptionStyles : noHoverStyles
        ),
        onClick: () => {
          handleSelectMention(mention);
        },
      });
    });

    const mentionContent = mentionQuery.slice(1).toLowerCase();

    // add inline filter when opened via the button
    if (scrollableItems.length > 0 && menuOpenVia === "button" && isGuidedChat_experiment) {
      scrollableItems.unshift({
        id: "type-ahead-divider",
        kind: MdsDropdownItemKind.Divider,
      });
      scrollableItems.unshift({
        id: "type-ahead",
        kind: MdsDropdownItemKind.Other,
        content: (
          <div className={filterInputStyles}>
            <MdsIcon kind={MdsIconKind.Filter} />
            <MdsInput
              placeholder="Filter"
              value={mentionContent}
              onChange={e => setMentionQuery("@" + e.target.value)}
              onKeyDown={e => {
                if (e.key === "Escape") {
                  setMentionClientRect(undefined);
                  setMentionQuery("");
                  setMenuOpenVia(null);
                  e.preventDefault();
                  e.stopPropagation();
                }

                if (e.key === "ArrowUp") {
                  handleKeyboardUp();
                }

                if (e.key === "ArrowDown") {
                  handleKeyboardDown();
                }

                if (e.key === "Enter") {
                  handleSelectMention();
                }
              }}
            />
          </div>
        ),
      });
    }

    if (scrollableItems.length && !mentionQuery.startsWith("/") && !isGuidedChat_experiment) {
      const taggingCollection = mentionQuery.startsWith("#");
      const mentionTypeName = taggingCollection ? "collection" : "note";
      const text = `${taggingCollection ? "Tag" : "Mention"} a${!mentionContent.trim().length ? " recent" : ""} ${mentionTypeName}`;
      scrollableItems.unshift({
        id: "header",
        kind: MdsDropdownItemKind.Detail,
        className: detailPaddingStyles,
        text,
      });
    }

    if (scrollableItems.length) {
      contentList.items.unshift(scrollable);
    }

    return contentList;
  }, [
    availableChips,
    handleKeyboardDown,
    handleKeyboardUp,
    handleSelectMention,
    isGuidedChat_experiment,
    keyboardSelectionChipIndex,
    mentionQuery,
    menuOpenVia,
    scrollIntoViewMentionKeyboardSelectionChipIndex,
    visibleChips,
  ]);

  useEffect(() => {
    const dispatchAction = chatInputRef.current?.dispatchAction;
    if (!dispatchAction || !mentionQuery) return;

    dispatchAction({
      kind: MemCommonRichTextInputActionKind.CloseMention,
      payload: {
        condition: contentList.items.length
          ? MemCommonCloseMentionCondition.RenderingResults
          : MemCommonCloseMentionCondition.NoResults,
      },
    });
  }, [contentList, mentionQuery]);

  const handleDropdownHover = useCallback(
    ({ itemId }: { itemId?: string }) => {
      // Lock hover to select until mouse is moved enough.
      // Since it's assigned to mouseenter/leave, this only takes effect when a new element is hovered.
      if (lockedMouseYRef.current) return;

      setKeyboardSelectedChipIndex(visibleChips?.findIndex(e => e.id === itemId) ?? -1);
      setScrollIntoViewMentionKeyboardSelectionChipIndex(false);
    },
    [visibleChips]
  );

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const buttonChipRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    onHeight?.(height + 2 * parseInt(CHAT_INPUT_VERTICAL_PADDING));
  }, [height, onHeight]);

  const [mentionClientRect, setMentionClientRect] = useState<DOMRect>();

  const chatInputEventHandler = useCallback(
    (event: MemCommonRichTextInputEvent) => {
      console.debug(`[ChatInput] Event ${JSON.stringify(event)}`, undefined, 2);

      if (
        event.kind !== "focus-changed" &&
        event.kind !== "content-height" &&
        !(event.kind === "selection-changed" && !event.payload) // selection-changed with no payload object fires when the editor is not fully mounted, so we don't trust the contents at this time
      ) {
        const editorHtml = chatInputRef.current?.editor.getHTML();
        // run callback if the editor content has changed
        if (editorHtml !== latestDraftMessage.current) {
          if (latestDraftMessage.current && editorHtml) {
            removeDeletedMentionsRef.current(latestDraftMessage.current, editorHtml);
          }

          latestDraftMessage.current = editorHtml;
          onDraftChange?.(editorHtml);
        }
      }

      switch (event.kind) {
        case MemCommonRichTextInputEventKind.Contents: {
          onSubmit({
            ...event.payload,
            isGuidedChat_experiment,
            agentMode: isAgentMode,
          });
          break;
        }
        case MemCommonRichTextInputEventKind.MentionUpdateQuery: {
          const mentionQuery = event.payload.mentionQuery;
          if (!mentionQuery) {
            // Close dropdown if mention char is removed.
            setMentionClientRect(undefined);
            setMentionQuery("");
            setMenuOpenVia(null);
            break;
          }

          const isInsertMenu = mentionQuery[0] === "/";
          if (isInsertMenu) {
            // Slash menu is not meant to be used to insert mentions but to insert content
            // (files, images, templates, etc) or formatting commands.
            // So we're letting common-editor know we're not showing any dropdowns.
            chatInputRef.current?.dispatchAction({
              kind: MemCommonRichTextInputActionKind.CloseMention,
              payload: null,
            });
            break;
          }

          if (event.payload.clientRect) {
            setMentionQuery(mentionQuery);
            setMentionClientRect(event.payload.clientRect ?? undefined);
          }
          break;
        }
        case MemCommonRichTextInputEventKind.MentionKeyDown: {
          const availableChips = availableChipsRef.current;
          if (!availableChips) break;

          switch (event.payload.kind) {
            case MemCommonMentionKeyDownKind.ArrowDown: {
              lockedMouseYRef.current = mouseYRef.current;
              handleKeyboardDown();
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.ArrowUp: {
              lockedMouseYRef.current = mouseYRef.current;
              handleKeyboardUp();
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.Enter: {
              if (availableChips.length) {
                handleSelectMention();
              }
              break;
            }
          }
          break;
        }
        case MemCommonRichTextInputEventKind.IsEmpty: {
          setIsEmpty(event.payload.isEmpty);
          break;
        }
      }
    },
    [
      handleKeyboardDown,
      handleKeyboardUp,
      handleSelectMention,
      isAgentMode,
      isGuidedChat_experiment,
      onDraftChange,
      onSubmit,
    ]
  );

  const handleDropdownOpenChange: MdsFloatingDropdownContentProps["onOpenChange"] = (
    open,
    _event,
    reason
  ) => {
    switch (reason) {
      case "escape-key":
      case "outside-press":
        if (open === false) {
          setMentionClientRect(undefined);
          setMentionQuery("");
          setMenuOpenVia(null);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <MdsFloatingDropdownContent
        zIndex={ZIndex.Dropdown}
        clientRect={mentionClientRect}
        placement="top-start"
        onHover={handleDropdownHover}
        contentListClassName={
          mentionQuery.startsWith("/") ? insertMenuContentClassName : mentionsListContentClassName
        }
        contentList={contentList}
        onOpenChange={handleDropdownOpenChange}
        offsetOptions={[{ mainAxis: 22, crossAxis: -16 }]}
        rootBoundary="document"
      />
      <Container
        className={className}
        ref={ref}
        onMouseMoveCapture={handleMouseMove}
        isDisabled={isDisabled}
      >
        {isGuidedChat_experiment && (
          <>
            <ContextArea>
              <div>
                <MdsTooltip
                  config={{
                    label: "Focus Mem on a specific date range, collection, or note",
                    placement: MdsTooltipPlacement.Top,
                    delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
                  }}
                >
                  <MdsButtonChip
                    ref={buttonChipRef}
                    onClick={() => {
                      setMentionQuery("@");
                      setMentionClientRect(buttonChipRef.current?.getBoundingClientRect());
                      setMenuOpenVia("button");

                      trackEvent(TrackedEvent.ChatInputLookAtClick);
                    }}
                    buttonIcon={MdsIconKind.Plus}
                  >
                    Look at
                  </MdsButtonChip>
                </MdsTooltip>
              </div>
              <CurrentContextChips>
                {contexts.map(ctx => (
                  <ChatContextChip
                    key={ctx.id}
                    context={ctx}
                    onRemove={() => {
                      removeMentionFromContexts(ctx);
                      if (contexts.length < 2 && !hasSomePermanentContext) {
                        // when we remove the last context, go into agent mode implicitly
                        setIsAgentMode(true);
                      }
                      chatInputRef.current?.dispatchAction({
                        kind: MemCommonRichTextInputActionKind.RemoveMention,
                        payload: {
                          mentionId: ctx.id,
                        },
                      });
                    }}
                  />
                ))}
                {hasSomePermanentContext && (
                  <MdsTooltip
                    config={{
                      label: "Mem will look at all messages, notes, and collections in this chat",
                      placement: MdsTooltipPlacement.Top,
                      delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
                    }}
                  >
                    <ChatContextChip title="This chat" />
                  </MdsTooltip>
                )}
                <AgentModeChip
                  isAgentMode={isAgentMode}
                  onToggleAgentMode={() => {
                    const isAgentModeEnabled =
                      hasSomePermanentContext || contexts.length > 0 ? !isAgentMode : true;

                    trackEvent(TrackedEvent.ChatInputAgentModeToggle, {
                      isAgentMode: isAgentModeEnabled,
                    });

                    setIsAgentMode(isAgentModeEnabled);
                  }}
                  hasSomeContexts={hasSomePermanentContext || contexts.length > 0}
                />
              </CurrentContextChips>
            </ContextArea>
            <MdsHorizontalDivider />
          </>
        )}
        <InputContainer>
          <Expand>
            <Scrollable>
              <MemCommonRichTextInput
                richTextInputEventHandler={chatInputEventHandler}
                richTextInputInitializer={chatInputInitializer}
                richTextInputInstanceRef={chatInputRef}
                className={chatInputStyles}
              ></MemCommonRichTextInput>
            </Scrollable>
          </Expand>
          <svg width="0" height="0" className="hidden">
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="7.03%" stopColor="#EB2487" />
              <stop offset="93.99%" stopColor="#F93939" />
            </linearGradient>
          </svg>
          <BottomAligner>
            <MdsIconButton
              isDisabled={isEmpty || isDisabled}
              iconStyles={isEmpty ? undefined : sendIconStyles}
              iconKind={isEmpty ? MdsIconKind.SendAlt : MdsIconKind.Send}
              onClick={sendSubmitAction}
            />
          </BottomAligner>
        </InputContainer>
      </Container>
    </div>
  );
});

const sendIconStyles = css({
  "> path": {
    fill: `url(#gradient)`,
  },
});

const CHAT_INPUT_VERTICAL_PADDING = mdsSpacings().smd;
const CHAT_INPUT_HORIZONTAL_PADDING = mdsSpacings().md;

const mentionsListContentClassName = css({
  maxHeight: "500px",
  width: "340px",
  maxWidth: "calc(100% - 20px)",
});

const insertMenuContentClassName = css({
  background: mdsColors().white,
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: mdsSpacings().sm,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  maxWidth: "calc(100% - 20px)",
  padding: "4px",
  width: "288px",
});

const Container = styled.div<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  borderRadius: "8px",
  border: `1px solid #F3F4F6`,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  padding: `${CHAT_INPUT_VERTICAL_PADDING} ${CHAT_INPUT_HORIZONTAL_PADDING}`,
  width: "100%",
  opacity: isDisabled ? 0.5 : 1,
  pointerEvents: isDisabled ? "none" : "auto",
  gap: mdsSpacings().sm,
}));

const ContextArea = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: mdsSpacings().xs,
});

const CurrentContextChips = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: mdsSpacings().xs,
});

const InputContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const chatInputStyles = css({
  "--extra-padding-left": `0`,
  "--extra-padding-right": `0`,
  "--extra-padding-bottom": `0`,
  "--extra-padding-top": `0`,
});

const Expand = styled.div({
  flex: 1,
});

const Scrollable = styled.div(({ theme }) => ({
  flex: 1,
  height: "fit-content",
  maxHeight: `calc(6 * ${theme.lineHeights.medium})`,
  overflowY: "auto",

  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const selectedOptionStyles = css({
  background: mdsColors().grey.x25,
});

const noHoverStyles = css({
  "&:hover": {
    background: "unset",
  },
});

const BottomAligner = styled.div({
  alignItems: "flex-end",
  display: "flex",
  height: "100%",
  width: "fit-content",
});

const scrollableMentionStyles = css({
  maxHeight: 220,
});

const detailPaddingStyles = css({
  padding: "8px 0 4px 10px",
});

const groupTitleStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
  padding: `${mdsSpacings().sm} ${mdsSpacings().sm} ${mdsSpacings().xs} ${mdsSpacings().sm} `,
});

const filterInputStyles = css({
  display: "flex",
  alignItems: "center",
  gap: mdsSpacings().sm,
  padding: mdsSpacings().xs,
  width: "100%",
});
