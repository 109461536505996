import Icons from "@/assets/icons/Icons";
import { MdsDropdownButton, MdsDropdownIconButton } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsButton, MdsButtonVariant, MdsButtonSize } from "@/design-system/components/button";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { usePreviousChatsContentList } from "@/components/chat/usePreviousChatsContentList";
import { css } from "@/domains/emotion";
import { mdsPanelWidths } from "@/design-system/foundations/breakpoints";
import { MdsPanelResponsive } from "@/design-system/components/panel";
import { AnimationTiming } from "@/design-system/constants/common";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { MdsIconButtonSize } from "@/design-system/components/icon-button";
import { MdsIconButton } from "@/design-system/components/icon-button";

interface ChatHeaderProps {
  inSidePanel?: boolean;
  isGuidedChat_experiment?: boolean;
  conversationId?: string;
  onNewChat?: () => void;
  onSelectChat?: (conversationId: string) => void;
}

interface GuidedChatActionsProps {
  onNewChat: () => void;
  onSelectChat: (conversationId: string) => void;
}

const GuidedChatActions = observer<GuidedChatActionsProps>(({ onSelectChat, onNewChat }) => {
  const { store } = useAppStore();

  const contentList = usePreviousChatsContentList({
    onClick: onSelectChat,
  });

  const isCreatingNewConversation = store.chatConversations.isCreatingNewConversation;

  return (
    <>
      <MdsPanelResponsive minWidth={mdsPanelWidths().narrow + "px"}>
        <MdsButton
          label="New chat"
          iconKind={MdsIconKind.Plus}
          variant={MdsButtonVariant.Outlined}
          size={MdsButtonSize.Small}
          onClick={onNewChat}
          isDisabled={isCreatingNewConversation}
        />
      </MdsPanelResponsive>
      <MdsPanelResponsive minWidth={mdsPanelWidths().narrow + "px"}>
        <MdsDropdownButton
          label="Past chats"
          iconKind={MdsIconKind.History}
          variant={MdsButtonVariant.Outlined}
          size={MdsButtonSize.Small}
          contentList={contentList}
          placement="below-right-alignment"
          innerStyles={{
            Content: {
              className: contentStyles,
            },
          }}
        />
      </MdsPanelResponsive>

      <MdsPanelResponsive maxWidth={mdsPanelWidths().narrow - 1 + "px"}>
        <MdsIconButton
          size={MdsIconButtonSize.Small}
          iconKind={MdsIconKind.Plus}
          onClick={onNewChat}
          isDisabled={isCreatingNewConversation}
          tooltipConfig={{
            label: "New chat",
            placement: MdsTooltipPlacement.Top,
            delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
          }}
        />
      </MdsPanelResponsive>
      <MdsPanelResponsive maxWidth={mdsPanelWidths().narrow - 1 + "px"}>
        <MdsDropdownIconButton
          size={MdsIconButtonSize.Small}
          iconKind={MdsIconKind.History}
          contentList={contentList}
          placement="below-right-alignment"
          innerStyles={{
            Content: {
              className: contentStyles,
            },
          }}
          tooltipConfig={{
            label: "Past chats",
            placement: MdsTooltipPlacement.Top,
            delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
          }}
        />
      </MdsPanelResponsive>
    </>
  );
});

export const ChatHeader = observer<ChatHeaderProps>(
  ({ inSidePanel, isGuidedChat_experiment, onNewChat, onSelectChat }) => {
    const { store } = useAppStore();
    const isOnTopOfMainPanel =
      store.sidePanel.isSidePanelOpen && !store.sidePanel.canRenderSplitView;
    const shouldRenderBackButton = inSidePanel && isOnTopOfMainPanel;

    return (
      <MdsPanelHeader
        heading="Mem"
        headingTextProps={{
          size: MdsTextSize.Small,
          lineHeight: MdsTextSize.XSmall,
          weight: MdsTextWeight.Medium,
        }}
        icon={<MemIcon />}
        onBackClick={
          shouldRenderBackButton
            ? isGuidedChat_experiment
              ? store.navigation.goToChat
              : store.sidePanel.goUp
            : undefined
        }
        actions={
          isGuidedChat_experiment &&
          onNewChat &&
          onSelectChat && <GuidedChatActions onNewChat={onNewChat} onSelectChat={onSelectChat} />
        }
      />
    );
  }
);

const MemIcon = styled(Icons.Mem)({
  height: "32px",
  width: "32px",
});

const contentStyles = css({
  maxWidth: "320px",

  overflow: "auto",
  maxHeight: "min(calc(100vh - 140px), 600px)",
});
