import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { FC } from "react";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsPanelResponsiveBtn } from "@/components/responsive-btn";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { mdsPanelBreakpoint } from "@/design-system/foundations";
import { LensKind } from "@/modules/lenses/types";
import { EventContext } from "@/domains/metrics/context";
import { DateFacetFilter } from "@/components/filters/DateFacetFilter/DateFacetFilter";
import { ContactsFacetFilter } from "@/components/filters/ContactsFacetFilter";
import { CollectionsFacetFilter } from "@/components/filters/CollectionsFacetFilter/CollectionsFacetFilter";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { MediaKindsFacetFilter } from "@/components/filters/MediaKindsFacetFilter/MediaKindsFacetFilter";
import { PageBannerSection } from "@/components/banners/page-banner-section";

export const NotesListPageHeader: FC = observer(() => {
  const { store, pageStore } = useAppStore();
  const page = pageStore.notesListPage;

  return (
    <Wrapper>
      <MdsPanelHeader
        heading="Notes"
        tabs={getTabs(page.filters)}
        icon={MdsIconKind.Notebook}
        actions={
          <MdsPanelResponsiveBtn
            fullSizeLabel="Create note"
            threshold={mdsPanelBreakpoint}
            fullSizeIcon={MdsIconKind.ComposeInline}
            collapsedIcon={MdsIconKind.ComposeInline}
            isDisabled={store.notes.newNoteIsBeingCreated}
            onClick={() => store.notes.composeNewNote({ eventContext: EventContext.NotesActions })}
            collapsedTooltipConfig={{
              label: "Create note",
              placement: MdsTooltipPlacement.Top,
              delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
            }}
          />
        }
      />
      <WrapperBottom>
        <WrapperBottomLeft>
          <FiltersContainer>{renderSortedFilters(page.filters)}</FiltersContainer>
        </WrapperBottomLeft>
        <WrapperBottomRight>
          <MdsDropdownButton
            contentList={page.filters.sortOptions}
            label={page.filters.sortLabel}
            iconKind={MdsIconKind.AngleDown}
            iconPosition={MdsButtonIconPosition.Right}
            placement="below-right-alignment"
          />
        </WrapperBottomRight>
      </WrapperBottom>
      <PageBannerSection />
    </Wrapper>
  );
});

const renderSortedFilters = (pageFilters: NotesListFilter) => {
  const filters = [
    {
      isActive:
        pageFilters.selectedCollectionTitles.length > 0 ||
        pageFilters.collectionNoCollectionIsSelected,
      defaultOrder: 0,
      component: (
        <CollectionsFacetFilter
          key="collections"
          supportNoCollectionOption
          searchText={pageFilters.collectionsSearchQuery}
          items={pageFilters.collectionsFilterItems}
          onSearchTextChange={pageFilters.setCollectionSearchQuery}
          onSearchTextClear={pageFilters.clearCollectionSearchQuery}
          onSelectItem={pageFilters.toggleCollectionItem}
          onSelectClear={pageFilters.clearCollections}
          buttonPrefix={pageFilters.collectionNoCollectionIsSelected ? "" : "In"}
          buttonLabel="Collections"
          selectedModeKey={pageFilters.selectedCollectionModeKey}
          onSelectMode={pageFilters.onCollectionModeSelectionChange}
          noCollectionIsSelected={pageFilters.collectionNoCollectionIsSelected}
          onNoCollectionIsSelected={pageFilters.setCollectionNoCollectionIsSelected}
          selectedItems={pageFilters.selectedCollectionTitles}
        />
      ),
    },
    {
      isActive: !!pageFilters.mediaKindsFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 1,
      component: (
        <MediaKindsFacetFilter
          key="media-kinds"
          items={pageFilters.mediaKindsFacetFilterItems}
          onSelectItem={pageFilters.toggleMediaKindItem}
          onSelectClear={pageFilters.clearMediaKinds}
          selectedItems={pageFilters.selectedMediaKinds}
        />
      ),
    },
    {
      isActive: !!pageFilters.params.filter?.date,
      defaultOrder: 2,
      component: (
        <DateFacetFilter
          key="date"
          items={pageFilters.dateFilterItems}
          selectedRange={pageFilters.dateSelectedRange}
          onClear={() => pageFilters.clearDateFilter()}
        />
      ),
    },

    {
      isActive: pageFilters.modifiedBySearchItems.some(item => item.isSelected),
      defaultOrder: 3,
      component: (
        <ContactsFacetFilter
          key="modifiedBy"
          items={pageFilters.modifiedBySearchItems}
          searchText={pageFilters.modifiedBySearchQuery}
          onSearchTextChange={pageFilters.setModifiedBySearchQuery}
          onSearchTextClear={pageFilters.clearModifiedBySearchQuery}
          onSelectItem={pageFilters.toggleModifiedByItem}
          onSelectClear={pageFilters.clearModifiedBy}
          buttonPrefix="Edited by"
        />
      ),
    },
    {
      isActive: pageFilters.createdBySearchItems.some(item => item.isSelected),
      defaultOrder: 4,
      component: (
        <ContactsFacetFilter
          key="createdBy"
          items={pageFilters.createdBySearchItems}
          searchText={pageFilters.createdBySearchQuery}
          onSearchTextChange={pageFilters.setCreatedBySearchQuery}
          onSearchTextClear={pageFilters.clearCreatedBySearchQuery}
          onSelectItem={pageFilters.toggleCreatedByItem}
          onSelectClear={pageFilters.clearCreatedBy}
          buttonPrefix="Created by"
        />
      ),
    },
  ];

  return filters
    .sort((a, b) => {
      const activeSort = Number(b.isActive) - Number(a.isActive);
      if (activeSort !== 0) {
        return activeSort;
      }

      return a.defaultOrder - b.defaultOrder;
    })
    .map(filter => filter.component);
};

const Wrapper = styled.div(() => ({}));

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
  padding: theme.spacing.md,
}));

const FiltersContainer = styled(FadeHorizontalScroll)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));

const WrapperBottomLeft = styled.div({
  minWidth: 0,
});

const WrapperBottomRight = styled.div({
  flexShrink: 0,
});

const getTabs = (pageFilters: NotesListFilter) => {
  return [
    {
      label: "All",
      isActive: pageFilters.params.lens === LensKind.All,
      onClick: () => pageFilters.setLens(LensKind.All),
    },
    {
      label: "Created by me",
      isActive: pageFilters.params.lens === LensKind.AddedByMe,
      onClick: () => pageFilters.setLens(LensKind.AddedByMe),
    },
    {
      label: "Shared with me",
      isActive: pageFilters.params.lens === LensKind.SharedWithMe,
      onClick: () => pageFilters.setLens(LensKind.SharedWithMe),
    },
  ];
};
