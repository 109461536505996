import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { mdsColors } from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { ZIndex } from "@/domains/design/constants";
import { useCallback } from "react";

export const QuickSearchModalSearchBar = observer(function QuickSearchModalSearchBar() {
  const { pageStore } = useAppStore();
  const state = pageStore.quickSearchModal;

  /**
   * If someone clicks on the search bar, we prevent the event from bubbling
   * out further (to the modal, etc.)
   */
  const onClickHandler = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <div className={inputWrapperStyles} onClick={onClickHandler}>
      <div className={cx(inputContainerStyles, mediaQueryStyles)}>
        <MdsIcon kind={MdsIconKind.Search} className={searchIconStyles} />
        <textarea
          ref={state.handleRef}
          placeholder="Search or ask your Mem"
          defaultValue={state.value}
          onChange={state.handleValueChange}
          onBlur={state.handleBlur}
          rows={1}
        />
        <MdsIconButton
          className={forwardIconStyles}
          iconKind={MdsIconKind.RightArrow}
          onClick={() => {
            pageStore.searchPage.resetPreviousQueryString();
            state.handleSearch();
          }}
        />
      </div>
    </div>
  );
});

const inputWrapperStyles = css({
  borderColor: "#d9d9d9",
  borderRadius: "8px",
  padding: "4px",
});

const inputContainerStyles = css({
  backgroundColor: "rgba(255, 255, 255, 1)",
  alignItems: "center",
  border: "1px solid",
  borderColor: mdsColors().grey.x100,
  borderRadius: "16px",
  color: "#fff",
  display: "flex",
  height: "auto",
  justifyContent: "center",
  margin: "0 auto",
  maxWidth: "780px",
  minHeight: "56px",
  minWidth: "780px",
  overflow: "visible",
  padding: "0 16px",
  width: "780px",
  zIndex: ZIndex.QuickSearchModel,
  userSelect: "none",

  "& > textarea": {
    border: "none",
    boxShadow: "none",
    flex: 1,
    fontSize: "16px",
    height: "auto",
    lineHeight: "24px",
    marginLeft: "20px",
    outline: "none",
    overflow: "auto",
    padding: "10px 0",
    resize: "none",
  },
});

const mediaQueryStyles = css({
  "@media (max-width: 812px)": {
    minWidth: "unset",
    maxWidth: "unset",
    width: "calc(100vw - 32px)",
  },
});

const searchIconStyles = css({
  height: "20px",
  marginLeft: "4px",
  width: "20px",
});

const forwardIconStyles = css({
  color: "#323640 !important",
  margin: "auto -8px",
  "&:hover": {
    backgroundColor: "unset",
  },
  "& .mds-btn-icon": {
    color: "#9397A5 !important",
    cursor: "pointer",
    "&:hover": {
      color: "#323640 !important",
    },
  },
});
