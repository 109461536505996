import { getChatItems } from "@/store/chat/getChatItems";
import { AppStore } from "@/store/AppStore";
import { ChatMessageContext, ChatItem, ChatItemKind, ChatHistoryIndexes } from "@/store/chat/types";
import { makeObservable, observable, action, computed } from "mobx";
import { MentionChip } from "@/pages/chat/ChatInput";
import { UNTITLED_NOTE_TITLE, UNTITLED_TEMPLATE_TITLE } from "@/domains/untitled/untitled";
import { MENTION_PREFIX_COLLECTION, MENTION_PREFIX_NOTE } from "@/store/chat/constants";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { MdsIconKind } from "@/design-system/components/icon/types";
import { Maybe } from "@/domains/common/types";
import styled from "@emotion/styled";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { uuidModule } from "@/modules/uuid";
import { NoteIcon } from "@/design-system/components/item-list/rows/icon/note/note";
import { noop } from "lodash-es";
import { css } from "@/domains/emotion";
import { mdsSpacings } from "@/design-system/foundations";
import { DateTime } from "luxon";
import { UpdateNoteContentUsingDiffOperation } from "@/store/sync/operations/notes/UpdateNoteContentUsingDiffOperation";
import { notesModule } from "@/modules/notes";
import { INoteObservable } from "@/store/note";
import {
  MemCommonEditorActionKind,
  MemCommonEditorFileVariant,
  MemCommonEditorSlashCommand,
} from "@mem-labs/common-editor";
import { Subscription } from "dexie";
import { EventContext } from "@/domains/metrics/context";
import { fileModule } from "@/modules/file";
import { fileModule as commonEditorFileModule } from "@mem-labs/common-editor";
import { v4 as uuidv4 } from "uuid";
import { MentionContent } from "@/store/chat/mention/MentionContent";
import { MentionTitle } from "@/store/chat/mention/MentionTitle";
import { NoteMentionContent } from "@/store/chat/mention/NoteMentionContent";
import { CollectionMentionContent } from "@/store/chat/mention/CollectionMentionContent";
import { MentionSubtitle } from "@/store/chat/mention/MentionSubtitle";
import { Uuid } from "@/domains/global/identifiers";
import { makeGuidedChips } from "@/store/chat/chipsForGuidedChat";
import { NoteType } from "@/components/note/types";
import { TemplateVariableCode } from "@/store/templates/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { TrackedEvent, trackEvent } from "@/domains/metrics";
const MAX_RESULTS = 100;

enum InsertFileMode {
  Attachment,
  Image,
}

export class ChatHistory {
  private store: AppStore;

  context?: ChatMessageContext; // TODO (RICK): remove this after guided chat. There will no longer be a "single context" concept to work around.
  items: ChatItem[] = [];
  contextStartedAt?: string;
  liveQuerySubscription: Maybe<Subscription>;
  searchSuggestions: SearchSuggestion[] = [];
  mentionQuery = "";
  conversationId?: Uuid;
  isGuidedMode = true;

  constructor({
    indexes,
    context,
    store,
    conversationId,
  }: {
    indexes: ChatHistoryIndexes[];
    context?: ChatMessageContext;
    store: AppStore;
    conversationId?: Uuid;
  }) {
    this.context = context;
    this.store = store;
    this.conversationId = conversationId;

    this.items = getChatItems(indexes);
    const lastSectionHeader = this.items.findLast(item => item.kind === ChatItemKind.SectionHeader);
    if (
      lastSectionHeader &&
      "locallyCreatedAt" in lastSectionHeader &&
      typeof lastSectionHeader.locallyCreatedAt === "string"
    ) {
      this.contextStartedAt = lastSectionHeader.locallyCreatedAt;
    }

    makeObservable<
      ChatHistory,
      | "store"
      | "getSlashCommandChips"
      | "getSearchSuggestionType"
      | "insertFileAction"
      | "insertTemplateAction"
      | "setDraftMessage"
      | "getDraftMessage"
    >(this, {
      isPrimaryChatConversation: true,
      hasSomeContexts: true,
      isGuidedMode: true,
      conversationId: true,
      getSearchSuggestionType: true,
      liveQuerySubscription: true,
      store: false,
      insertFileAction: false,
      insertTemplateAction: false,
      getSlashCommandChips: false,
      getAvailableChips: false,
      getAvailableChips_normal: false,
      getAvailableChips_guidedChat: false,
      contextStartedAt: false,
      context: observable,
      items: observable,
      submitChatMessage: action,
      mentionQuery: observable,
      searchSuggestions: observable,
      search: action,
      setDraftMessage: false,
      getDraftMessage: false,
      contexts: computed,
    });
  }

  getDraftMessage(): string | undefined {
    if (!this.conversationId) return undefined;
    const msg = this.store.chatMessages.getDraftMessage(this.conversationId);
    return msg;
  }

  get contexts(): ChatMessageContext[] {
    if (this.context) return [this.context];
    if (!this.conversationId) return [];
    return this.store.chatMessages.getChatContextsByConversationId(this.conversationId);
  }

  get hasSomeContexts(): boolean {
    if (!this.conversationId) return false;
    const msgs = this.store.chatMessages.getMessagesInConversation(this.conversationId);
    return msgs.some(msg => msg.hasGuidedContext || msg.hasSources);
  }

  get isPrimaryChatConversation(): boolean {
    return this.conversationId === this.store.chatConversations.primaryChatConversationId;
  }

  setDraftMessage = (message?: string) => {
    if (!this.conversationId) return;
    if (!message) {
      this.store.chatMessages.clearDraftMessage(this.conversationId);
      return;
    }
    this.store.chatMessages.setDraftMessage(this.conversationId, message);
  };

  private getSearchSuggestionType(mentionChar: string, nodeType?: NoteType) {
    if (nodeType === NoteType.Template) {
      return SearchSuggestionType.TEMPLATE;
    }

    if (mentionChar === MENTION_PREFIX_NOTE) {
      return SearchSuggestionType.NOTE;
    }

    if (mentionChar === MENTION_PREFIX_COLLECTION) {
      return SearchSuggestionType.COLLECTION;
    }

    return SearchSuggestionType.OTHER;
  }

  private async insertFileAction(
    mode: InsertFileMode
  ): Promise<Maybe<MemCommonEditorSlashCommand>> {
    const isImageMode = mode === InsertFileMode.Image;
    const file = await fileModule.askForUserFile(isImageMode ? "image/*" : undefined);
    if (!file) return;

    const fileId = uuidv4();
    const { info, variant } = await commonEditorFileModule.extractInfoAsync({ file, fileId });

    if (!fileModule.checkIfFileCanBeUploaded({ info })) return;

    if (isImageMode) {
      if (variant !== MemCommonEditorFileVariant.Image) return;

      return {
        kind: MemCommonEditorActionKind.InsertImage,
        payload: {
          imageId: fileId,
          imageMimeType: info.fileMimeType,
          imageName: file.name,
          encodedImageContent: info.encodedFileContent,
        },
      };
    }

    return {
      kind: MemCommonEditorActionKind.InsertFile,
      payload: { fileName: file.name, ...info },
    };
  }

  private async insertTemplateAction(
    templateId: string
  ): Promise<Maybe<MemCommonEditorSlashCommand>> {
    const template = await this.store.templates.getAsync(templateId);

    const templateContents = await template?.getTemplateContentsForInsertionAsync();
    if (!template || !templateContents) return;

    const templateVariables = Object.entries(this.store.templates.getVariables()).map(
      ([code, variable]) => ({
        code,
        value: variable.value,
      })
    );

    const { remoteContent, documentUpdates } = templateContents;

    trackEvent(TrackedEvent.TemplateUsed, {
      template_name: template.title || UNTITLED_TEMPLATE_TITLE,
      context: "insert_menu",
    });

    return {
      kind: MemCommonEditorActionKind.InsertTemplate,
      payload: {
        templateId,
        templateRemoteContent: remoteContent,
        templateDocumentUpdates: documentUpdates,
        templateVariables,
      },
    };
  }

  getSlashCommandChips = async (mentionQueryText: string, noteType?: NoteType) => {
    const groupTitles = {
      Date: "Insert date",
      Attachment: "Insert attachment",
      Formatting: "Insert formatting",
      Template: "Insert template",
      TemplateVariable: "Insert template variable",
    };
    const today = DateTime.now().toLocaleString(DateTime.DATE_FULL);
    const yesterday = DateTime.now().minus({ days: 1 }).toLocaleString(DateTime.DATE_FULL);
    const tomorrow = DateTime.now().plus({ days: 1 }).toLocaleString(DateTime.DATE_FULL);
    type SLASH_COMMAND = SearchSuggestion & {
      iconKind: MdsIconKind;
      content?: MentionChip["content"];
      groupTitle: MentionChip["groupTitle"];
      action: MentionChip["action"];
    };
    const SLASH_COMMANDS: SLASH_COMMAND[] = [
      {
        modelId: "insert-date-today",
        type: SearchSuggestionType.OTHER,
        label: `Date Day Today's ${today}`,
        content: () => (
          <MenuLabel>
            Today <LighterText>– {today}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `date day today's ${today}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: today,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-date-yesterday",
        type: SearchSuggestionType.OTHER,
        label: `Yesterday's ${yesterday}`,
        content: () => (
          <MenuLabel>
            Yesterday <LighterText>– {yesterday}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `yesterday's ${yesterday}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: yesterday,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-date-tomorrow",
        type: SearchSuggestionType.OTHER,
        label: `Tomorrow's ${tomorrow}`,
        content: () => (
          <MenuLabel>
            Tomorrow <LighterText>– {tomorrow}</LighterText>
          </MenuLabel>
        ),
        lowercaseLabel: `tomorrow's ${tomorrow}`,
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Date,
        iconKind: MdsIconKind.CalendarDay,
        action: {
          kind: MemCommonEditorActionKind.InsertText,
          payload: {
            text: tomorrow,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: CommandIds.InsertImage,
        type: SearchSuggestionType.OTHER,
        label: "Image Photo Picture PNG JPG JPEG",
        content: () => <MenuLabel>Image</MenuLabel>,
        lowercaseLabel: "image photo picture png jpg jpeg",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Attachment,
        iconKind: MdsIconKind.Image,
        action: () => this.insertFileAction(InsertFileMode.Image),
        isAvailable: 1,
      },
      {
        modelId: CommandIds.InsertFile,
        type: SearchSuggestionType.OTHER,
        label: "File PDF ZIP CSV MP3 MP4 WAV MOV DOC XLS",
        content: () => <MenuLabel>File</MenuLabel>,
        lowercaseLabel: "file pdf zip csv mp3 mp4 wav mov doc xls",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Attachment,
        iconKind: MdsIconKind.Paperclip,
        action: () => this.insertFileAction(InsertFileMode.Attachment),
        isAvailable: 1,
      },
      {
        modelId: "insert-header-1",
        type: SearchSuggestionType.OTHER,
        label: "Large Header Section Title H1",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Large Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "large header section title h1",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H1,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 1,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-header-2",
        type: SearchSuggestionType.OTHER,
        label: "Medium Header Section Subtitle H2",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Medium Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "medium header section subtitle h2",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H2,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 2,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-header-3",
        type: SearchSuggestionType.OTHER,
        label: "Small Header Section h3",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Small Header</MenuLabel>
            <KeyboardShortcut>
              <Key>#</Key>
              <Key>#</Key>
              <Key>#</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "small header section h3",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.H3,
        action: {
          kind: MemCommonEditorActionKind.ToggleHeaderTextFormat,
          payload: {
            level: 3,
            focus: true,
          },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-check-list",
        type: SearchSuggestionType.OTHER,
        label: "Task List Checklist TODOs Checkbox Action Item",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Task List</MenuLabel>
            <KeyboardShortcut>
              <Key>[</Key>
              <Key>]</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "task list checklist todos checkbox action item",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.Tasks,
        action: {
          kind: MemCommonEditorActionKind.ToggleChecklistTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-bullet-list",
        type: SearchSuggestionType.OTHER,
        label: "Bulleted List Unordered",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Bulleted List</MenuLabel>
            <KeyboardShortcut>
              <Key>-</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "bulleted list unordered",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.List,
        action: {
          kind: MemCommonEditorActionKind.ToggleBulletListTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-ordered-list",
        type: SearchSuggestionType.OTHER,
        label: "Numbered List Ordered",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Numbered List</MenuLabel>
            <KeyboardShortcut>
              <Key>1</Key>
              <Key>.</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "numbered list ordered",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.NumberedList,
        action: {
          kind: MemCommonEditorActionKind.ToggleOrderedListTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-table",
        type: SearchSuggestionType.OTHER,
        label: "Table Sheet Column Row",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Table</MenuLabel>
            <KeyboardShortcut>
              <Key>|</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "table sheet column row",
        lastViewedAt: "",
        sortKey: 0,
        iconKind: MdsIconKind.Table,
        groupTitle: groupTitles.Formatting,
        action: {
          kind: MemCommonEditorActionKind.InsertTable,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-inline-code",
        type: SearchSuggestionType.OTHER,
        label: "Inline Code Script JavaScript Function CSS HTML",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Code</MenuLabel>
            <KeyboardShortcut>
              <Key>`</Key>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "inline code script javascript function css html",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.Code,
        action: {
          kind: MemCommonEditorActionKind.ToggleInlineCodeTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-codeblock",
        type: SearchSuggestionType.OTHER,
        label: "Code Block Script JavaScript Function CSS HTML",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Code Block</MenuLabel>
            <KeyboardShortcut>
              <Key>`</Key>
              <Key>`</Key>
              <Key>`</Key>
              <Key>⏎</Key>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "code block script javascript function css html",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.CodeBlock,
        action: {
          kind: MemCommonEditorActionKind.ToggleCodeBlockTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-blockquote",
        type: SearchSuggestionType.OTHER,
        label: "Quote Blockquote Quotation",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Quote</MenuLabel>
            <KeyboardShortcut>
              <Key>&gt;</Key>
              <SpaceKey>Space</SpaceKey>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "quote blockquote quotation",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.QuoteRight,
        action: {
          kind: MemCommonEditorActionKind.ToggleQuoteBlockTextFormat,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
      {
        modelId: "insert-divider",
        type: SearchSuggestionType.OTHER,
        label: "HR Divider Separator Line Rule Break",
        content: () => (
          <SpaceBetween>
            <MenuLabel>Divider</MenuLabel>
            <KeyboardShortcut>
              <Key>-</Key>
              <Key>-</Key>
              <Key>-</Key>
            </KeyboardShortcut>
          </SpaceBetween>
        ),
        lowercaseLabel: "hr divider separator line rule break",
        lastViewedAt: "",
        sortKey: 0,
        groupTitle: groupTitles.Formatting,
        iconKind: MdsIconKind.HorizontalRule,
        action: {
          kind: MemCommonEditorActionKind.InsertHorizontalRule,
          payload: { focus: true },
        },
        isAvailable: 1,
      },
    ];
    const variables = this.store.templates.getVariables();
    const TEMPLATE_VARIABLES: (SLASH_COMMAND & { value: string })[] = [
      {
        modelId: `insert-variable-${TemplateVariableCode.UserName}`,
        type: SearchSuggestionType.OTHER,
        label: `Variable User's Name`,
        lowercaseLabel: `variable user's name`,
        content: () => <MenuLabel>User`s Name</MenuLabel>,
        lastViewedAt: "",
        sortKey: 0,
        isAvailable: 1,
        groupTitle: groupTitles.TemplateVariable,
        // iconKind: MdsIconKind.CalendarDay,
        iconKind: MdsIconKind.Template,
        action: {
          kind: MemCommonEditorActionKind.InsertTemplateVariable,
          payload: {
            code: TemplateVariableCode.UserName,
            displayName: variables[TemplateVariableCode.UserName].displayName,
          },
        },
        value: variables[TemplateVariableCode.UserName].value,
      },
      {
        modelId: `insert-variable-${TemplateVariableCode.UserFirstName}`,
        type: SearchSuggestionType.OTHER,
        label: `Variable User's First Name`,
        lowercaseLabel: `variable user's first name`,
        content: () => <MenuLabel>User`s First Name</MenuLabel>,
        lastViewedAt: "",
        sortKey: 0,
        isAvailable: 1,
        groupTitle: groupTitles.TemplateVariable,
        // iconKind: MdsIconKind.CalendarDay,
        iconKind: MdsIconKind.Template,
        action: {
          kind: MemCommonEditorActionKind.InsertTemplateVariable,
          payload: {
            code: TemplateVariableCode.UserFirstName,
            displayName: variables[TemplateVariableCode.UserFirstName].displayName,
          },
        },
        value: variables[TemplateVariableCode.UserName].value,
      },
      {
        modelId: `insert-variable-${TemplateVariableCode.DateToday}`,
        type: SearchSuggestionType.OTHER,
        label: `Variable Date Day Today's`,
        lowercaseLabel: `variable date day today's`,
        content: () => <MenuLabel>Today</MenuLabel>,
        lastViewedAt: "",
        sortKey: 0,
        isAvailable: 1,
        groupTitle: groupTitles.TemplateVariable,
        // iconKind: MdsIconKind.CalendarDay,
        iconKind: MdsIconKind.Template,
        action: {
          kind: MemCommonEditorActionKind.InsertTemplateVariable,
          payload: {
            code: TemplateVariableCode.DateToday,
            displayName: variables[TemplateVariableCode.DateToday].displayName,
          },
        },
        value: variables[TemplateVariableCode.DateToday].value,
      },
    ];
    if (noteType === NoteType.Template) {
      SLASH_COMMANDS.unshift(...TEMPLATE_VARIABLES);
    } else if (mentionQueryText) {
      const suggestions = await this.search(
        mentionQueryText,
        "/",
        undefined,
        false,
        NoteType.Template
      );
      const templates = await Promise.all(
        suggestions.map(suggestion => this.store.templates.getAsync(suggestion.modelId))
      );
      const foundTemplates = templates.filter(template => template) as TemplateObservable[];
      foundTemplates.toReversed().forEach(template => {
        SLASH_COMMANDS.unshift({
          modelId: template.id,
          type: SearchSuggestionType.TEMPLATE,
          // inMemory (below) can't handle pre-selected suggestions, so here we fool it.
          label: mentionQueryText,
          content: () => <MenuLabel>{template.title || UNTITLED_TEMPLATE_TITLE}</MenuLabel>,
          lowercaseLabel: (template.title || UNTITLED_TEMPLATE_TITLE).toLowerCase(),
          lastViewedAt: "",
          sortKey: 0,
          isAvailable: 1,
          groupTitle: groupTitles.Template,
          iconKind: MdsIconKind.Template,
          action: () => this.insertTemplateAction(template.id),
        });
      });
    } else {
      // Most recent templates:
      const recentTemplates = this.store.templates.sortedRecentTemplatesInteractedWithByMe.slice(
        0,
        SLASH_MENU_MAX_SEARCH_RESULTS_PER_GROUP
      );
      // Reverse the order of the templates because we're using unshift.
      recentTemplates.toReversed().forEach(({ template, lastInteractedAt }) => {
        SLASH_COMMANDS.unshift({
          modelId: `insert-template-${template.id}`,
          type: SearchSuggestionType.TEMPLATE,
          label: template.title || UNTITLED_TEMPLATE_TITLE,
          lowercaseLabel: (template.title || UNTITLED_TEMPLATE_TITLE).toLowerCase(),
          lastViewedAt: lastInteractedAt,
          sortKey: 0,
          isAvailable: 1,
          groupTitle: groupTitles.Template,
          iconKind: MdsIconKind.Template,
          action: () => this.insertTemplateAction(template.id),
        });
      });
    }

    const suggestions = mentionQueryText
      ? this.store.search.inMemory(mentionQueryText, SLASH_COMMANDS)
      : SLASH_COMMANDS;

    if (mentionQueryText && new Set(suggestions.map(e => e.type)).size > 1) {
      // We got to trim each to 3 results
      const counters = new Map<SearchSuggestionType, number>();
      const toRemove: number[] = [];
      suggestions.forEach((e, index) => {
        const n = (counters.get(e.type) ?? 0) + 1;
        if (n > SLASH_MENU_MAX_SEARCH_RESULTS_PER_GROUP) {
          toRemove.push(index);
          return;
        }
        counters.set(e.type, n);
      });
      for (const index of toRemove.toReversed()) {
        suggestions.splice(index, 1);
      }
    }
    const chips = suggestions.map(e => {
      const command = SLASH_COMMANDS.find(c => e.modelId === c.modelId);
      const chip: MentionChip = {
        id: e.modelId,
        label: e.label,
        className: command ? menuButtonStyles : undefined,
        content: command?.content,
        iconKind: command?.iconKind,
        groupTitle: command?.groupTitle,
        action: command?.action,
      };
      return chip;
    });

    return chips;
  };

  search = async (
    mentionQueryText: string,
    mentionChar: string,
    excludeId?: string,
    excludeCurrent?: boolean,
    nodeType?: NoteType
  ) => {
    const suggestionType = this.getSearchSuggestionType(mentionChar, nodeType);
    let andCondition = (item: SearchSuggestion) => item.type === suggestionType;
    if (excludeId || excludeCurrent) {
      andCondition = item =>
        item.type === suggestionType &&
        (!excludeId || item.modelId !== excludeId) &&
        (!excludeCurrent || item.modelId !== this.context?.id);
    }

    const suggestions = await this.store.search.forSuggestions(
      mentionQueryText,
      "mentions",
      andCondition,
      1000
    );

    return suggestions;
  };

  getAvailableChips_guidedChat = async (
    mentionQuery: string,
    _opts?: { inlineCreation?: boolean; excludeCurrent?: boolean; excludeId?: string }
  ): Promise<MentionChip[]> => {
    if (mentionQuery.trim().length === 0) return [];

    const mentionQueryText = mentionQuery.slice(1).trim();
    const suggestions = await this.store.search.forSuggestions(
      mentionQueryText,
      "mentions",
      undefined,
      1000
    );

    return makeGuidedChips(mentionQuery, this.store, suggestions);
  };

  // TODO: extract to a separate module
  getAvailableChips_normal = async (
    mentionQuery: string,
    opts?: {
      inlineCreation?: boolean;
      excludeCurrent?: boolean;
      excludeId?: string;
      noteType?: NoteType;
    }
  ): Promise<MentionChip[]> => {
    const mentionChar = mentionQuery[0];
    const mentionQueryText = mentionQuery.slice(1).trim();

    if (mentionChar === "/") {
      return await this.getSlashCommandChips(mentionQueryText, opts?.noteType);
    }

    const suggestions = await this.search(
      mentionQueryText,
      mentionChar,
      opts?.excludeId,
      opts?.excludeCurrent
    );

    const isSearchingCollections = mentionQuery.startsWith("#");
    const iconKind = isSearchingCollections ? MdsIconKind.Collection : MdsIconKind.Document;

    const lowercaseMentionQueryText = mentionQueryText.toLowerCase();

    let addCreateNew = !!opts?.inlineCreation && !!lowercaseMentionQueryText.length;

    const getDropdownButtonContentForNote = (
      id: string,
      label: string
    ): {
      isOwnedByMe: INoteObservable["isOwnedByMe"];
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
    } => {
      const note = this.store.notes.get(id);
      return {
        isOwnedByMe: !!note?.isOwnedByMe,
        iconKind: () => <NoteIcon toggleSelected={noop} />,
        content: () => <NoteMentionContent id={id} label={label} />,
      };
    };

    const getDropdownButtonContentForCollection = (
      id: string,
      label: string
    ): {
      iconKind: MentionChip["iconKind"];
      content: MentionChip["content"];
      isOwnedByMe: CollectionObservable["isOwnedByMe"];
    } => {
      const collection = this.store.collections.get(id);
      return {
        iconKind: () => <CollectionIcon collectionId={id} />,
        content: () => <CollectionMentionContent id={id} label={label} />,
        isOwnedByMe: !!collection?.isOwnedByMe,
      };
    };

    const prepareChips = (getExtraInfo: typeof getDropdownButtonContentForCollection) => {
      const exactMatchIndex = suggestions.findIndex(
        suggestion => suggestion.lowercaseLabel.trim() === lowercaseMentionQueryText
      );
      if (exactMatchIndex >= 0) {
        const exactMatch = suggestions[exactMatchIndex];
        suggestions.splice(exactMatchIndex, 1);
        suggestions.unshift(exactMatch);
      }
      suggestions.slice(0, MAX_RESULTS).forEach(suggestion => {
        const { modelId: id, label, lowercaseLabel } = suggestion;
        const extraInfo = getExtraInfo(id, label);
        if (!extraInfo) return;

        const { iconKind, content, isOwnedByMe } = extraInfo;
        chips.push({
          id,
          label,
          iconKind,
          content,
        });
        if (lowercaseLabel.trim() === lowercaseMentionQueryText && isOwnedByMe) {
          addCreateNew = false;
        }
      });
    };

    const chips: MentionChip[] = [];
    switch (mentionChar) {
      case MENTION_PREFIX_NOTE: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentNotesInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, MAX_RESULTS)
            .forEach(note => {
              const { id, title } = note;
              const label = title || UNTITLED_NOTE_TITLE;
              const {
                iconKind: icon,
                content,
                isOwnedByMe,
              } = getDropdownButtonContentForNote(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
              if (label.trim().toLowerCase() === lowercaseMentionQueryText && isOwnedByMe) {
                addCreateNew = false;
              }
            });
          break;
        }

        prepareChips(getDropdownButtonContentForNote);
        break;
      }
      case MENTION_PREFIX_COLLECTION: {
        if (!lowercaseMentionQueryText) {
          this.store.recentItems.sortedRecentCollectionsInteractedWithByMe
            .filter(e => e.id !== this.context?.id)
            .slice(0, 5)
            .forEach(collection => {
              const { id, label } = collection;
              const { iconKind: icon, content } =
                getDropdownButtonContentForCollection(id, label) ?? {};
              chips.push({
                id,
                label,
                iconKind: icon ?? iconKind,
                content,
              });
            });
          break;
        }

        prepareChips(getDropdownButtonContentForCollection);
        break;
      }
    }
    const exactMatchIndex = chips.findIndex(
      chip => chip.label.trim().toLowerCase() === lowercaseMentionQueryText
    );
    if (exactMatchIndex >= 0) {
      const exactMatch = chips[exactMatchIndex];
      chips.splice(exactMatchIndex, 1);
      chips.unshift(exactMatch);
    }
    if (addCreateNew) {
      const id = uuidModule.generate();
      chips.push({
        alwaysVisible: true,
        id: id,
        label: mentionQueryText,
        iconKind: () => (
          <PlusIconWrapper>
            <MdsIcon kind={MdsIconKind.Plus} />
          </PlusIconWrapper>
        ),
        content: () => (
          <MentionContent>
            <MentionTitle>“{mentionQueryText}”</MentionTitle>
            <MentionSubtitle>
              Create new {isSearchingCollections ? "collection" : "note"}
            </MentionSubtitle>
          </MentionContent>
        ),
        beforeSelection: async () => {
          if (isSearchingCollections) {
            await this.store.collections.createCollection({
              collectionId: id,
              title: mentionQueryText,
              description: "",
              eventContext: EventContext.EditorInline,
            });
            return;
          }
          await this.store.notes.createNote({
            noteId: id,
            eventContext: EventContext.EditorInline,
          });

          const encodedContent = notesModule.convertMdxToEncodedContent("# " + mentionQueryText);
          const queue = this.store.notes.getNoteQueue({ noteId: id });
          queue.push(
            new UpdateNoteContentUsingDiffOperation({
              store: this.store,
              payload: {
                id,
                encoded_content_diff: encodedContent || "",
              },
              primaryLabel: mentionQueryText,
              secondaryLabel: "",
              mediaKinds: [],
            })
          );
        },
      });
    }

    return chips;
  };

  getAvailableChips = async (
    mentionQuery: string,
    opts?: {
      inlineCreation?: boolean;
      excludeCurrent?: boolean;
      excludeId?: string;
      noteType?: NoteType;
      unifiedChatMentionMode?: boolean;
    }
  ): Promise<MentionChip[]> => {
    return opts?.unifiedChatMentionMode
      ? this.getAvailableChips_guidedChat(mentionQuery, opts)
      : this.getAvailableChips_normal(mentionQuery, opts);
  };

  submitChatMessage = async ({
    markdownContent,
    isGuidedChat_experiment,
    agentMode,
    contexts: providedContexts,
  }: {
    markdownContent: string;
    isGuidedChat_experiment: boolean;
    agentMode: boolean;
    contexts?: ChatMessageContext[];
  }) => {
    console.log("[CHAT] Chat History ID is ", this.conversationId);

    if (!markdownContent.trim()) return;

    this.setDraftMessage(undefined);
    const contexts: ChatMessageContext[] =
      providedContexts ?? (this.context !== undefined ? [this.context] : []);

    await this.store.chatMessages.sendNewMessage({
      message: markdownContent,
      contexts,
      contextStartedAt: this.contextStartedAt,
      conversationId: this.conversationId,
      isGuidedChat_experiment,
      agentMode,
    });
  };
}

const PlusIconWrapper = styled.div({
  width: 40,
  height: 40,
  // borderRadius: theme.borderRadius.mediumLarge,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const menuButtonStyles = css({
  borderRadius: mdsSpacings().xs,
  padding: mdsSpacings().sm,
});

const LighterText = styled.span(({ theme }) => ({
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  color: theme.colors.grey.x500,
}));

const MenuLabel = styled.span(({ theme }) => ({
  color: theme.colors.grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const SpaceBetween = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const KeyboardShortcut = styled.span(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.xs,
}));

const Key = styled.span(({ theme }) => ({
  display: `flex`,
  height: `16px`,
  minWidth: `16px`,
  padding: `1px`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  gap: `8px`,

  borderRadius: `4px`,
  border: `1px solid ${theme.colors.grey.x100}`,

  color: theme.colors.grey.x500,
  textAlign: `center`,
  fontSize: theme.fontSizes.xxxsmall,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
}));

const SpaceKey = styled(Key)({
  padding: `1px 3px`,
});

export enum CommandIds {
  InsertImage = "insert-image",
  InsertFile = "insert-file",
}

const SLASH_MENU_MAX_SEARCH_RESULTS_PER_GROUP = 3;
