import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { OptimisticSyncUpdate, SyncModelData, SyncModelKind } from "@/store/sync/types";
import { type UploadedFileModelData, type MimeType } from "@/store/uploaded-files/types";
import { type ICreateUploadedFileOperation } from "@/store/sync/operations/types";

export class CreateUploadedFileOperation extends BaseSyncOperation<ICreateUploadedFileOperation> {
  get operationKind() {
    return "CREATE_UPLOADED_FILE" as const;
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

    const fileUpdate = this.generateUploadedFileUpdate();
    if (fileUpdate) {
      optimisticUpdates.push(fileUpdate);
    }

    return optimisticUpdates;
  }

  protected generateUploadedFileUpdate(): OptimisticSyncUpdate<UploadedFileModelData> {
    return {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: {
        model_id: this.payload.id,
        model_kind: SyncModelKind["UploadedFile"],
        model_version: 1,
        model_data: {
          id: this.payload.id,
          mime_type: (this.payload.mime_type || "unknown") as MimeType,
          uploaded_file_batch_id: this.payload.uploaded_file_batch_id,
          normalized_filename: this.payload.normalized_filename,
          file_size_bytes: this.payload.file_size_bytes,
        },
        model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
      },
    };
  }

  async triggerRecompute(): Promise<void> {
    await this.store.uploadedFiles.recompute(this.payload.id);
  }
}
