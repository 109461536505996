import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { SourceModelData } from "./types";
import { SourceObservable } from "./SourceObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreSourceStore extends BaseSyncModelStore<SourceObservable, SourceModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Source, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<SourceModelData>): SourceObservable {
    return new SourceObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
