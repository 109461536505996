import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { TemplateContentDocumentObservable } from "@/store/templates/TemplateContentDocumentObservable";
import { TemplateContentDocumentModelData } from "@/store/templates/types";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";

export class AppStoreTemplateContentDocumentStore extends BaseSyncModelStore<
  TemplateContentDocumentObservable,
  TemplateContentDocumentModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.TemplateContentDocument, ...injectedDeps });
  }

  createSyncModel(
    updateValue: SyncUpdateValue<TemplateContentDocumentModelData>
  ): TemplateContentDocumentObservable {
    return new TemplateContentDocumentObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }
}
