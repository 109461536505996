import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import {
  FavoriteItemModelData,
  FavoriteItemUpsertedSyncUpdateValue,
} from "@/store/favorite-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";

export class UpdateFavoriteItemSortKeyOperation extends BaseSyncOperation<IUpdateFavoriteItemSortKeyOperation> {
  private favoriteItem?: FavoriteItemObservable;

  get operationKind(): SyncOperationKind {
    return "UPDATE_FAVORITE_ITEM_SORT_KEY";
  }

  async execute() {
    this.favoriteItem = await this.store.favoriteItems.getAsync(this.payload.favorite_item_id);

    await super.execute();
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<FavoriteItemModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData>[] = [];

    const favoriteItemUpdatedUpdate = this.generateOptimisticFavoriteItemUpdatedUpdate();
    if (favoriteItemUpdatedUpdate) {
      optimisticUpdates.push(favoriteItemUpdatedUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.favoriteItems.recompute(this.payload.favorite_item_id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }

  protected generateOptimisticFavoriteItemUpdatedUpdate() {
    const favoriteItemObservable = this.favoriteItem;
    if (!favoriteItemObservable) {
      logger.debug({
        message: "Attempt to update favorite item that does not exist",
        info: { favoriteItemId: this.payload.favorite_item_id },
      });

      return;
    }

    const value: FavoriteItemUpsertedSyncUpdateValue = {
      model_id: this.payload.favorite_item_id,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
      model_data: {
        ...favoriteItemObservable.modelData,
        sort_key: this.payload.sort_key,
      },
      model_scopes: favoriteItemObservable.modelScopes,
    };

    const syncUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
