import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { css } from "@/domains/emotion";
import {
  MdsDropdown,
  MdsDropdownItem,
  MdsDropdownItemKind,
  MdsDropdownProps,
  MdsDropdownSearchInputHeader,
} from "@/design-system/components/dropdown";
import {
  CollectionItem,
  CollectionItemMode,
  CollectionForOrganize,
  type Direction,
} from "@/components/note/editor/top-bar/organize";

// This is currently built to power the in-editor Organize dropdown
// But it's a good starting point to extract a generic ComboBox component

interface OrganizeComboProps extends Omit<MdsDropdownProps, "contentList"> {
  searchText: string;
  header: string | null;
  items: CollectionForOrganize[];
  showCreateNewItem: boolean;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onSelectItem: (collectionId: string) => void;
  onCreateNewItem: (name: string) => void;
  onClear: () => void;
}
export const OrganizeComboBox = ({
  searchText,
  header,
  items,
  showCreateNewItem,
  onSelectItem,
  onCreateNewItem,
  onInputChange,
  onClear,
  onOpenChange,
  ...dropdownProps
}: OrganizeComboProps) => {
  const [selectedItem, setSelectedItem] = useState(0);

  // keyboard handling and type-ahead is written custom for this component
  // TODO: consider using Floating UI features for this instead
  // https://floating-ui.com/docs/useListNavigation
  // https://floating-ui.com/docs/FloatingList
  // https://floating-ui.com/docs/useTypeahead
  const handleKeyDirection = useCallback(
    (direction: Direction) => {
      if (items.length === 0) setSelectedItem(-1);

      if (direction === "up") {
        if (selectedItem < 1) {
          setSelectedItem(items.length - (showCreateNewItem ? 0 : 1));
        } else {
          setSelectedItem(selectedItem - 1);
        }
      } else {
        if (selectedItem >= items.length - (showCreateNewItem ? 0 : 1)) {
          setSelectedItem(0);
        } else {
          setSelectedItem(selectedItem + 1);
        }
      }
    },
    [items.length, selectedItem, showCreateNewItem]
  );

  // always start with the first item selected
  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onOpenChange?.(isOpen);
      setSelectedItem(0);
    },
    [onOpenChange]
  );

  const handleEscape = useCallback(() => {
    handleOpenChange(false);
  }, [handleOpenChange]);

  const handleEnter = useCallback(() => {
    if (selectedItem === items.length) {
      onCreateNewItem(searchText);
    } else if (selectedItem >= 0) {
      onSelectItem(items[selectedItem].id);
    }

    setSelectedItem(0);
  }, [items, onCreateNewItem, onSelectItem, searchText, selectedItem]);

  const mdsDropdownItems: MdsDropdownItem[] = useMemo(() => {
    const ddItems: MdsDropdownItem[] = [];

    if (header) {
      ddItems.push({
        kind: MdsDropdownItemKind.Detail,
        id: "header",
        text: header,
      });
    }

    items.forEach((item, i) => {
      ddItems.push({
        kind: MdsDropdownItemKind.Other,
        id: item.id,
        content: (
          <CollectionItem
            title={item.title}
            noteCount={item.noteCount}
            collectionId={item.id}
            subtitle={item.description}
            mode={CollectionItemMode.SELECT}
            isShared={item.isShared}
            isCollected={item.isCollected}
            onClick={() => {
              onSelectItem(item.id);
            }}
            isSelected={i === selectedItem}
            onMouseEnter={() => {
              setSelectedItem(i);
            }}
            onMouseLeave={() => {
              setSelectedItem(-1);
            }}
          />
        ),
      });
    });

    return ddItems;
  }, [header, items, onSelectItem, selectedItem]);

  // Add an item for each Collection

  const contentList = {
    items: mdsDropdownItems,
    header: {
      children: (
        <MdsDropdownSearchInputHeader
          onChange={onInputChange}
          onClear={onClear}
          value={searchText}
          onKeyDirection={handleKeyDirection}
          onEscape={handleEscape}
          onEnter={handleEnter}
          placeholder="Add to existing or new collection"
        />
      ),
      height: 50,
    },
    footer: showCreateNewItem
      ? {
          children: (
            <CollectionItem
              title={searchText.trim()}
              noteCount={0}
              collectionId="new-collection"
              subtitle="Create new collection"
              mode={CollectionItemMode.CREATE}
              isSelected={selectedItem === items.length}
              isCollected={false}
              onMouseEnter={() => {
                setSelectedItem(items.length);
              }}
              onMouseLeave={() => {
                setSelectedItem(-1);
              }}
              onClick={() => {
                onCreateNewItem(searchText);
              }}
            />
          ),
          height: 50,
        }
      : undefined,
  };

  return (
    <MdsDropdown
      contentList={contentList}
      onOpenChange={handleOpenChange}
      {...dropdownProps}
      placement="below-right-alignment"
      innerStyles={{
        Row: {
          className: rowStyles,
        },
        Content: {
          className: contentStyles,
        },
      }}
    />
  );
};

const rowStyles = css({
  overflow: "visible",
});

const contentStyles = css({
  maxHeight: 320,
  minWidth: 320,
});
