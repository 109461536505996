import ColorHash from "color-hash";
import { FC, Fragment } from "react";
import { observer } from "mobx-react-lite";

import { SnippetChunk, TopicInfo } from "@/design-system/components/item-list/types";
import { mdsColors, mdsFontSizes, mdsFontWeights } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { isEmpty } from "lodash-es";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { useMdsHoverContentGroup } from "@/design-system/components/hover-content/MdsHoverContentGroup";
import { MdsHoverContent } from "@/design-system/components/hover-content/MdsHoverContent";
import { CollectionPreview } from "@/design-system/components/item-list/rows/item-preview/CollectionPreview";
import styled from "@emotion/styled";

export interface MdsItemListRowFeaturedContentProps {
  collections?: TopicInfo[];
  snippet?: SnippetChunk[];
  itemId?: string;
  showAll?: boolean;
  isLoading?: boolean;
}

export const MdsItemListRowFeaturedContent: FC<MdsItemListRowFeaturedContentProps> = observer(
  ({ collections, snippet, itemId, showAll, isLoading }) => {
    const length = showAll ? collections?.length : 2;
    const anyCollections = !isEmpty(collections);
    const firstTwoCollections = collections?.slice(0, length);
    const remainingCollections = collections?.slice(length);
    const additionalCollectionsCount = remainingCollections?.length;
    const shouldShowAdditionalCollections = !!additionalCollectionsCount;
    const group = useMdsHoverContentGroup();

    if (isLoading) {
      return <LoaderContainer></LoaderContainer>;
    }
    if (!collections?.length && !snippet?.length) return null;

    return (
      <Container wrap={showAll ?? false}>
        {firstTwoCollections?.map(featuredCollection => {
          return (
            <MdsHoverContent
              key={featuredCollection.id}
              group={group}
              content={() => {
                return itemId ? (
                  <CollectionPreview
                    collectionTopic={featuredCollection}
                    noteId={itemId}
                    onDismiss={() => {
                      group.hideAll({});
                    }}
                  />
                ) : null;
              }}
            >
              <CollectionChip
                collectionId={featuredCollection.id}
                label={featuredCollection.label}
              />
            </MdsHoverContent>
          );
        })}

        {shouldShowAdditionalCollections && (
          <span className={collectionOverflowStyles}>+{additionalCollectionsCount}</span>
        )}
        {anyCollections && snippet && snippet.length > 0 && (
          <MdsIcon
            className={dotSeparatorStylesStyles}
            innerStyles={{ Icon: { className: dotSeparatorInnerStyles } }}
            kind={MdsIconKind.Dot}
          />
        )}
        {snippet?.map(({ text, isHighlight }, index) => (
          <Fragment key={index}>
            {isHighlight ? (
              <span className={highlightedSnippetStyles}>{text}</span>
            ) : (
              <span className={standardSnippetStyles}>{text}</span>
            )}
          </Fragment>
        ))}
      </Container>
    );
  }
);

interface CollectionChipProps {
  collectionId: string;
  label: string;
}
const CollectionChip = ({ collectionId, label }: CollectionChipProps) => {
  const bgColorHash = new ColorHash({ lightness: 0.92, saturation: 0.45 });
  const textColorHash = new ColorHash({ lightness: 0.35, saturation: 0.45 });
  const bgColor = bgColorHash.hex(collectionId);
  const textColor = textColorHash.hex(collectionId);

  return (
    <CollectionChipContainer>
      <CollectionChipWrapper style={{ backgroundColor: bgColor, color: textColor }}>
        {label}
      </CollectionChipWrapper>
    </CollectionChipContainer>
  );
};

// enforce 16px height so when rendered in the List there's not noticeable layout shift
const CollectionChipContainer = styled.div({
  display: "inline-block",
  height: "16px",
});

const CollectionChipWrapper = styled.div({
  display: "inline-block",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  paddingLeft: "4px",
  paddingRight: "3px",
  borderRadius: "4px",
  marginRight: "4px",
});

const dotSeparatorStylesStyles = css({
  height: "100%",
  marginRight: "2px",
});

const dotSeparatorInnerStyles = css({
  fontSize: "4px",
});

const standardSnippetStyles = css({
  whiteSpace: "pre",
});

const highlightedSnippetStyles = css({
  whiteSpace: "pre",
  fontWeight: mdsFontWeights().semiBold,
});

interface ContainerProps {
  wrap: boolean;
}
const Container = styled("div", {
  shouldForwardProp: prop => prop !== "wrap",
})<ContainerProps>(({ wrap }) => ({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().small,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: wrap ? "wrap" : "nowrap",
  alignItems: "center",
}));

const collectionOverflowStyles = css({
  display: "inline-block",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  paddingLeft: "4px",
  paddingRight: "3px",
  borderRadius: "4px",
  marginRight: "7px",
  color: "rgba(105, 110, 125, 1)",
  backgroundColor: "rgba(243, 243, 245, 1)",
});

const LoaderContainer = styled.div({
  height: "16px",
});
