import { APP_CONFIG } from "@/domains/global/config";
import { isWin } from "@/domains/platform/isWin";
import { clientEnvModule } from "@/modules/client-env";
import { uuidModule } from "@/modules/uuid";
import { windowModule } from "@/modules/window";
import { useAppStore } from "@/store";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { TrashNoteOperation } from "@/store/sync/operations/notes/TrashNoteOperation";
import { useCallback, useMemo } from "react";

export const useProfileDropdownHandlers = (onClick?: () => void) => {
  const { store } = useAppStore();

  const isWindows = isWin();
  const isDesktop = clientEnvModule.isDesktop();

  const handleMobile = useCallback(() => {
    onClick?.();
    const url = isDesktop ? APP_CONFIG.URLS.IOS_APP_GUIDE : APP_CONFIG.URLS.IOS_APP_V2;
    windowModule.openInNewTab({ url: new URL(url) });
  }, [onClick, isDesktop]);

  const handleDesktop = useCallback(() => {
    onClick?.();
    windowModule.openInNewTab({
      url: new URL(isWindows ? APP_CONFIG.URLS.WINDOWS_APP : APP_CONFIG.URLS.MAC_APP),
    });
  }, [onClick, isWindows]);

  const handleLogOut = useCallback(async () => {
    onClick?.();
    await store.sync.logout();
    store.navigation.goToLogOut();
  }, [onClick, store.navigation, store.sync]);

  const handleOpenSettings = useCallback(() => {
    onClick?.();
    store.navigation.goToSettings();
  }, [onClick, store.navigation]);

  const handleOpenTemplates = useCallback(() => {
    onClick?.();
    store.navigation.goToTemplates();
  }, [onClick, store.navigation]);

  const handleTrashClick = useCallback(() => {
    onClick?.();
    store.navigation.goToTrash();
  }, [onClick, store.navigation]);

  const handleNavigateToAdminPanel = useCallback(() => {
    onClick?.();
    store.navigation.goToAdmin();
  }, [onClick, store.navigation]);

  const handleToggleDebugging = useCallback(() => {
    onClick?.();
    store.sync.actionQueue.toggleDebugging();
  }, [onClick, store.sync.actionQueue]);

  const handleToggleNoteContentPreloading = useCallback(async () => {
    onClick?.();
    store.noteContentDocuments.togglePreloading();
  }, [onClick, store.noteContentDocuments]);

  const handleResetSync = useCallback(async () => {
    await store.resetStorageAndReload();
  }, [store]);

  const handlePushFailedSyncOperation = useCallback(() => {
    const operation = new TrashNoteOperation({
      store,
      payload: { id: "invalid-id" },
    });
    store.sync.actionQueue.push(operation);
    onClick?.();
  }, [onClick, store]);

  const handlePushPermissionDeniedSyncOperation = useCallback(() => {
    const operation = new UpdateCollectionOperation({
      store,
      payload: {
        id: uuidModule.generate(),
        title: "Test Title",
        description: "Test Description",
      },
    });
    store.sync.actionQueue.push(operation);
    onClick?.();
  }, [onClick, store]);

  const handleNavigateToHelpAndSupport = useCallback(() => {
    windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.HELP_AND_SUPPORT) });
  }, []);

  const handlePauseSync = useCallback(() => {
    store.sync.actionQueue.pause();
  }, [store.sync.actionQueue]);

  const handleResumeSync = useCallback(() => {
    store.sync.actionQueue.resume();
  }, [store.sync.actionQueue]);

  return useMemo(
    () => ({
      handleDesktop,
      handleMobile,
      handleLogOut,
      handleOpenSettings,
      handleOpenTemplates,
      handleTrashClick,
      handleNavigateToAdminPanel,
      handleToggleDebugging,
      handleResetSync,
      handleToggleNoteContentPreloading,
      handlePushFailedSyncOperation,
      handlePushPermissionDeniedSyncOperation,
      handleNavigateToHelpAndSupport,
      handlePauseSync,
      handleResumeSync,
    }),
    [
      handleDesktop,
      handleMobile,
      handleLogOut,
      handleOpenSettings,
      handleOpenTemplates,
      handleTrashClick,
      handleNavigateToAdminPanel,
      handleToggleDebugging,
      handleResetSync,
      handleToggleNoteContentPreloading,
      handlePushFailedSyncOperation,
      handlePushPermissionDeniedSyncOperation,
      handleNavigateToHelpAndSupport,
      handlePauseSync,
      handleResumeSync,
    ]
  );
};
