import { makeObservable } from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { ThreadEventModelData } from "./types";
import { ThreadEventObservable } from "./ThreadEventObservable";
import { SyncUpdateValue } from "@/store/sync/types";

export class AppStoreThreadEventStore extends BaseSyncModelStore<
  ThreadEventObservable,
  ThreadEventModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ThreadEvent, ...injectedDeps });
    makeObservable(this, {
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<ThreadEventModelData>): ThreadEventObservable {
    return new ThreadEventObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }
}
