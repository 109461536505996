import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ItemContent, Virtuoso, Components, VirtuosoHandle } from "react-virtuoso";
import { MdsItemListRowRenderer } from "@/design-system/components/item-list";
import {
  MdsItemListRowData,
  MdsItemListRowType,
  MdsVirtualizedItemListMode,
} from "@/design-system/components/item-list/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { css, cx } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { MdsSkeletonItemList } from "@/design-system/components/item-list/MdsSkeletonItemList";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";

interface MdsVirtualizedItemListProps {
  endReached?: () => void;
  listState?: ListStateObservable;
  loading?: boolean;
  rows: MdsItemListRowData[];
  mode?: MdsVirtualizedItemListMode;
  scrollTop?: number;
  setScrollTop?: (scrollTop: number) => void;
}

const rowTypesWithBorders = [MdsItemListRowType.Item];
const Item: Components<MdsItemListRowData, HTMLDivElement>["Item"] = props => {
  const defaultClassName = "row";
  const extraClassName = rowTypesWithBorders.includes(props.item.type) ? "row-with-border" : "";
  const className = `${defaultClassName} ${extraClassName}`;

  return (
    <div className={className} {...props}>
      {props.children}
    </div>
  );
};

export const MdsVirtualizedItemList: FC<MdsVirtualizedItemListProps> = observer(
  ({
    endReached,
    listState,
    loading = false,
    rows,
    mode = MdsVirtualizedItemListMode.Regular,
    scrollTop = 0,
    setScrollTop,
  }) => {
    const { store } = useAppStore();
    const { isVisible, virtuoso, onScroll } = useVirtuoso(scrollTop, setScrollTop);

    // should we use new "smooth render" hook?
    const useSmoothScroll = typeof scrollTop === "number" && !!setScrollTop;

    useEffect(() => {
      if (!listState) return;

      const onKeyDown = (event: KeyboardEvent) => {
        if (!listState.highlightedItemId) return;

        const { key } = event;
        switch (key) {
          case "x":
            if (!event.shiftKey) {
              listState.toggleItemSelection(listState.highlightedItemId);
              break;
            }
          // eslint-disable-next-line no-fallthrough
          case "X": {
            listState.selectItemsFromLastSelectionUntil(listState.highlightedItemId);
            break;
          }
        }
      };
      window.addEventListener("keydown", onKeyDown);
      return () => window.removeEventListener("keydown", onKeyDown);
    }, [listState]);

    const listModeStyles = mode === MdsVirtualizedItemListMode.Chat ? chatStyles : undefined;

    const itemContent: ItemContent<MdsItemListRowData, unknown> = useCallback(
      (i, data) => MdsItemListRowRenderer({ index: i, data, className: listModeStyles }),
      [listModeStyles]
    );

    const handleMouseLeave = useCallback(() => listState?.highlightItem(), [listState]);

    const showSkeleton = !!loading;
    if (showSkeleton) {
      return <MdsSkeletonItemList />;
    }

    if (useSmoothScroll) {
      return (
        <Virtuoso
          className={cx(listStyles, listModeStyles)}
          ref={virtuoso}
          onScroll={onScroll}
          totalCount={rows.length}
          data={rows}
          itemContent={itemContent}
          endReached={endReached}
          onMouseLeave={handleMouseLeave}
          components={{ Item }}
          style={{ opacity: isVisible ? 1 : 0 }}
          initialScrollTop={scrollTop}
        />
      );
    }

    // TODO: deprecate navigation store after migrating all other lists to useVirtuoso
    return (
      <Virtuoso
        className={cx(listStyles, listModeStyles)}
        ref={store.navigation.setVirtuoso}
        restoreStateFrom={store.navigation.restoreVirtuosoStateFrom}
        totalCount={rows.length}
        data={rows}
        itemContent={itemContent}
        endReached={endReached}
        onMouseLeave={handleMouseLeave}
        components={{ Item }}
      />
    );
  }
);

const listStyles = css({
  scrollbarWidth: "none",
  transition: "opacity 0.1s ease-in-out",
  "::-webkit-scrollbar": {
    display: "none",
  },

  // Hack to solve flickering when cursor is right between rows.
  ".row": {
    borderTop: "1px solid transparent",
  },

  ".row-with-border + .row-with-border": {
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: mdsColors().grey.x50,
  },
});

const chatStyles = css({
  transform: `rotate(180deg) scaleX(-1)`,
  transformOrigin: `center`,
});

/**
 * Custom hook to manage Virtuoso list scrolling behavior and visibility transitions
 *
 * @param scrollTop - Current scroll position of the list
 * @param setScrollTop - Callback to update scroll position externally
 *
 * @returns Object containing:
 *   - virtuoso: Ref to the Virtuoso list component
 *   - onScroll: Scroll handler that updates external scroll position
 *   - isVisible: Boolean indicating if list should be visible (used for mount transition)
 */
const useVirtuoso = (scrollTop: number, setScrollTop?: (scrollTop: number) => void) => {
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [isVisible, setIsVisible] = useState(false);

  const onScroll = () => {
    virtuoso.current?.getState(({ scrollTop }) => {
      setScrollTop?.(scrollTop);
    });
  };

  // special handler that effectively resets scroll to 0 when after-mount change detected
  useEffect(() => {
    if (scrollTop !== 0) return;

    virtuoso.current?.scrollToIndex({
      index: 0,
      align: "start",
      behavior: "auto",
    });
  }, [scrollTop]);

  // this lil rascal is used to smooth the "mount" transition and masks content flashing
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });
  }, []);

  return { virtuoso, onScroll, isVisible };
};
