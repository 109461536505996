import { DeleteNotesPermanentlyModal } from "@/components/modal/delete-notes-permanently/DeleteNotesPermanentlyModal";
import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsPanelHeaderButton } from "@/design-system/components/panel-header/MdsPanelHeaderButton";
import { useAppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { observer } from "mobx-react-lite";

export const NoteTopBarTrashedNoteActionButtons = observer<{
  note: INoteObservable;
}>(({ note }) => {
  const { pageStore } = useAppStore();
  const deletePermanentlyModal = pageStore.notesViewPage.deleteNotePermanentlyModal;
  return (
    <>
      {!(note instanceof TemplateObservable) && (
        <MdsPanelHeaderButton
          variant={MdsButtonVariant.Tertiary}
          onClick={async () => await note.restoreFromTrash()}
          iconKind={MdsIconKind.Redo}
          label="Restore"
        />
      )}
      <MdsPanelHeaderButton
        variant={MdsButtonVariant.Tertiary}
        onClick={() => deletePermanentlyModal.open({ notes: [note] })}
        iconKind={MdsIconKind.Trash}
        label="Delete Forever"
      />
      <DeleteNotesPermanentlyModal store={deletePermanentlyModal} />
    </>
  );
});
