import { observer } from "mobx-react-lite";
import { RelatedCard } from "@/store/routing/types";
import styled from "@emotion/styled";
import { useAppStore } from "@/store/utils/hooks";
import { CopilotVotingButtons } from "@/components/copilot/TopicItemVotingButtons";
import { RefreshTopicsForNoteButton } from "@/components/copilot/RefreshTopicsForNoteButton";
import { TopicItemList } from "@/components/copilot/TopicItemList";
import { CopilotCardHeader } from "@/components/copilot/CopilotCardHeader";
import { CopilotTitleContainer } from "@/components/copilot/CopilotTitleContainer";
import { CopilotContainerText } from "@/components/copilot/CopilotContainerText";
import { CopilotSeeMoreButton } from "@/components/copilot/CopilotSeeMoreButton";
import { CopilotCardContents } from "@/components/copilot/CopilotCardContents";

const MAX_ITEMS_TO_SHOW = 3;

interface Props {
  showContents: boolean;
  card: RelatedCard;
  showAllItems: boolean;
}

export const CollapsedRelatedCard = observer<Props>(function Card({
  card,
  showContents,
  showAllItems,
}) {
  const { store, pageStore } = useAppStore();
  const maxItems = showAllItems ? Infinity : MAX_ITEMS_TO_SHOW;
  const numItemsMore = card.items.length - maxItems;
  const rows = card.items.slice(0, maxItems);

  // Make the buttons always visible (so CopilotVotingButtons does not worry about whether it is highlighted),
  // but only show their enclosing container on hover of the title
  return (
    <>
      <CopilotCardHeader>
        <CopilotTitleContainer>
          <CopilotContainerText>{card.title}</CopilotContainerText>
          {store.debug.debugModeEnabled && !pageStore.copilotView.isRunningPipeline && (
            <RefreshTopicsForNoteButton
              noteId={pageStore.copilotView.activeItem?.id}
              spaceAccountId={store.spaceAccounts.myPersonalSpaceAccountId}
            />
          )}
        </CopilotTitleContainer>
        <VotingButtonsWrapper className="voting-buttons-wrapper">
          <CopilotVotingButtons
            itemId={card.id}
            topicItemId={card.id}
            topicId={card.id}
            feedbackClass="topic"
            alwaysVisible={true}
          />
        </VotingButtonsWrapper>
      </CopilotCardHeader>
      <CopilotCardContents>
        {showContents && <TopicItemList rows={rows} topicId={card.id} />}
        {card.items.length > maxItems && (
          <CopilotSeeMoreButton onClick={() => store.sidePanel.goToCopilot(card)}>
            See {numItemsMore} more
          </CopilotSeeMoreButton>
        )}
      </CopilotCardContents>
    </>
  );
});

const VotingButtonsWrapper = styled.div`
  opacity: 0;
`;
