import { observer } from "mobx-react-lite";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsDropdownButton } from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { IndeterminateLoaderBar } from "@/components/loaders/IndeterminateLoaderBar";
import { DeepSearchHeaderText } from "@/pages/search/components/SearchPageDeepSearchHeaderText";
import { ContactsFacetFilter } from "@/components/filters/ContactsFacetFilter";
import { CollectionsFacetFilter } from "@/components/filters/CollectionsFacetFilter/CollectionsFacetFilter";
import { MediaKindsFacetFilter } from "@/components/filters/MediaKindsFacetFilter/MediaKindsFacetFilter";
import { DateFacetFilter } from "@/components/filters/DateFacetFilter/DateFacetFilter";
import { SearchPageFilter } from "@/modules/filters/search/SearchPageFilter";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { noop } from "lodash-es";

export const SearchPageHeader = observer(function SearchPageHeader() {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;
  const queryString = page.filters.params.queryString;

  return (
    <Wrapper>
      <MdsPanelHeader
        heading={queryString ? queryString : "Notes"}
        icon={queryString ? MdsIconKind.Search : MdsIconKind.Notebook}
        actions={<DeepSearchHeaderText />}
      />
      {page.isLoading && <IndeterminateLoaderBar />}
      <WrapperBottom>
        <WrapperBottomLeft>
          <FiltersContainer>{renderSortedFilters(page.filters)}</FiltersContainer>
        </WrapperBottomLeft>
        <WrapperBottomRight>
          <MdsDropdownButton
            contentList={page.filters.sortOptions}
            label={page.filters.sortLabel}
            iconKind={MdsIconKind.AngleDown}
            iconPosition={MdsButtonIconPosition.Right}
            placement="below-right-alignment"
          />
        </WrapperBottomRight>
      </WrapperBottom>
    </Wrapper>
  );
});

const renderSortedFilters = (pageFilters: SearchPageFilter) => {
  const filters = [
    {
      isActive: pageFilters.collectionsFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 0,
      component: (
        <CollectionsFacetFilter
          key="collections"
          searchText={pageFilters.collectionsSearchQuery}
          items={pageFilters.collectionsFacetFilterItems}
          onSearchTextChange={pageFilters.setCollectionSearchQuery}
          onSearchTextClear={pageFilters.clearCollectionSearchQuery}
          onSelectItem={pageFilters.toggleCollectionItem}
          onSelectClear={pageFilters.clearCollections}
          buttonPrefix="In"
          buttonLabel="Collections"
          selectedModeKey="in-any-of"
          onSelectMode={noop}
          noCollectionIsSelected={false}
          onNoCollectionIsSelected={noop}
          selectedItems={pageFilters.selectedCollectionTitles}
          supportModeSelection={false}
        />
      ),
    },
    {
      isActive: !!pageFilters.mediaKindsFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 1,
      component: (
        <MediaKindsFacetFilter
          key="media-kinds"
          items={pageFilters.mediaKindsFacetFilterItems}
          onSelectItem={pageFilters.toggleMediaKindItem}
          onSelectClear={pageFilters.clearMediaKinds}
          selectedItems={pageFilters.selectedMediaKinds}
        />
      ),
    },
    {
      isActive: !!pageFilters.dateParams,
      defaultOrder: 2,
      component: (
        <DateFacetFilter
          key="date"
          items={pageFilters.dateFilterItems}
          selectedRange={pageFilters.dateSelectedRange}
          onClear={() => pageFilters.clearDateFilter()}
        />
      ),
    },
    {
      isActive: pageFilters.modifiedByFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 3,
      component: (
        <ContactsFacetFilter
          key="modifiedBy"
          items={pageFilters.modifiedByFacetFilterItems}
          searchText={pageFilters.modifiedBySearchQuery}
          onSearchTextChange={pageFilters.setModifiedBySearchQuery}
          onSearchTextClear={pageFilters.clearModifiedBySearchQuery}
          onSelectItem={pageFilters.toggleModifiedByItem}
          onSelectClear={pageFilters.clearModifiedBy}
          buttonPrefix="Edited by"
        />
      ),
    },
    {
      isActive: pageFilters.createdByFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 4,
      component: (
        <ContactsFacetFilter
          key="createdBy"
          items={pageFilters.createdByFacetFilterItems}
          searchText={pageFilters.createdBySearchQuery}
          onSearchTextChange={pageFilters.setCreatedBySearchQuery}
          onSearchTextClear={pageFilters.clearCreatedBySearchQuery}
          onSelectItem={pageFilters.toggleCreatedByItem}
          onSelectClear={pageFilters.clearCreatedBy}
          buttonPrefix="Created by"
        />
      ),
    },
  ];

  return filters
    .sort((a, b) => {
      const activeSort = Number(b.isActive) - Number(a.isActive);
      if (activeSort !== 0) {
        return activeSort;
      }

      return a.defaultOrder - b.defaultOrder;
    })
    .map(filter => filter.component);
};

const Wrapper = styled.div({});

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
  gap: theme.spacing.md,
}));

const WrapperBottomLeft = styled.div({
  minWidth: 0,
});

const WrapperBottomRight = styled.div({
  flexShrink: 0,
});

const FiltersContainer = styled(FadeHorizontalScroll)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));
