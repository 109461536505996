import { ChatItem, ChatItemKind } from "@/store/chat/types";
import type { ChatItemContext } from "@/domains/chat";
import { observer } from "mobx-react-lite";
import { SectionHeader } from "@/components/messages/item/SectionHeader";
import { Message } from "@/components/messages/item/Message";
import { useAppStore } from "@/store";
import { logger } from "@/modules/logger";
import { MdsSkeleton } from "@/design-system/components/loader/MdsSkeleton";
import styled from "@emotion/styled";

export interface MessageItemProps {
  /**
   * The index of the item in the list data array.
   */
  index: number;
  /**
   * The data item to render.
   */
  data: ChatItem;
  /**
   * The value of the `context` prop passed to the list.
   */
  context: ChatItemContext;

  /**
   * The previous data item (if available).
   */
  prevData: ChatItem | null;
  /**
   * The next data item (if available).
   */
  nextData: ChatItem | null;
}

export const MessageItem = observer<MessageItemProps>(function MessageItem({
  data: item,
  context,
}) {
  const { store } = useAppStore();

  if (item.kind === ChatItemKind.SectionHeader) {
    return <SectionHeader sectionHeader={item.header} />;
  }

  if (item.kind === ChatItemKind.Message) {
    const message = store.chatMessages.get(item.id);
    if (!message) {
      return (
        <LoaderWrapper>
          <MdsSkeleton count={3} />
        </LoaderWrapper>
      );
    }

    return (
      <Message
        message={message}
        inSidePanel={context.inSidePanel}
        isGuidedChat_experiment={context.isGuidedChat_experiment}
      />
    );
  }

  logger.error({ message: "Unknown item kind", info: { item } });
  return null;
});

const LoaderWrapper = styled.div(({ theme }) => ({
  margin: theme.spacing.md,
}));
