/**
 * Return the given string converted to a string that can be used for a clean
 * filename. Remove leading and trailing spaces; convert other spaces to
 * underscores; and remove anything that is not an alphanumeric, dash,
 * underscore, or dot.
 *
 * normalizeFilename("john's portrait in 2004.jpg")
 * // Returns: 'johns_portrait_in_2004.jpg'
 *
 * Note that this needs to match our backend implementation.
 * We generate filenames optimistically on the frontend, so it needs to
 * match what ends up being stored server-side.
 */
export const normalizeFilename = ({ fileName }: { fileName: string }): string => {
  let str = fileName.trim().replace(/\s/g, "_");
  str = str.replace(/[^-\w.]/g, "");

  if (str === "" || str === "." || str === "..") {
    throw new Error(`[normalizeFilename] Could not derive file name from fileName='${fileName}'`);
  }

  return str;
};
