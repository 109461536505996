import { generateRelativePrepositionAdverbialDateString } from "@/domains/date/date";
import { observer } from "mobx-react-lite";
import { MdsTooltip, MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { copyTextToClipboardWithToast } from "@/actions/copyTextToClipboardWithToast";
import styled from "@emotion/styled";
import { DataImportObservable } from "@/store/data-imports/DataImportObservable";
import { get, isEmpty, uniq } from "lodash-es";
import { stringModule } from "@/modules/string";
import { DataImportStatusIndicator } from "@/pages/settings/components/migration/data-import-status-indicator";
import { DataImportKind } from "@/store/data-imports/types";

interface Props {
  dataImport: DataImportObservable;
}

export const ImportItem = observer(({ dataImport }: Props) => {
  const sourceMapValues = Object.values(dataImport.sourceMap?.contentInfoMap ?? {});
  const sourceMapEmails = sourceMapValues.map(value =>
    get(value, "account_info.profile_email_address", null)
  );

  const sourceMapInfoSection = (() => {
    const compactedEmails = sourceMapEmails.filter(email => email !== null);
    const uniqueEmails = uniq(compactedEmails);

    if (isEmpty(uniqueEmails)) {
      return null;
    }

    return <span> from {stringModule.toOxfordComma(uniqueEmails)}</span>;
  })();

  return (
    <ImportItemContainer>
      <DataImportStatusIndicator dataImport={dataImport} />
      <MdsTooltip config={{ label: dataImport.id, placement: MdsTooltipPlacement.Top }}>
        <span
          onClick={() =>
            copyTextToClipboardWithToast({
              text: dataImport.id,
              toastContent: "Import ID copied to clipboard",
            })
          }
        >
          {getText(dataImport)}{" "}
          {generateRelativePrepositionAdverbialDateString(
            dataImport.isRunning ? dataImport.startedAt : dataImport.endedAt!
          )}
        </span>
        {sourceMapInfoSection}
      </MdsTooltip>
    </ImportItemContainer>
  );
});

const ImportItemContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const getText = (dataImport: DataImportObservable) => {
  if (dataImport.isRunning) {
    return "Started running";
  }

  const isMigration = dataImport.kind === DataImportKind.MemClassicBatchAccountUpgradeV1;
  if (isMigration) {
    return "Migrated";
  }

  return "Imported";
};
