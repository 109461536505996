import { computed, makeObservable, observable, action, reaction, runInAction } from "mobx";
import { groupLens, LensGroup } from "@/modules/timeline";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsItemDropdown } from "@/design-system/constants/items/types";
import { MdsIconKind } from "@/design-system/components/icon";
import {
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { actions } from "@/actions";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import localDb from "@/domains/local-db";
import { liveQuery, Subscription } from "dexie";
import { getRowForNoteObservable } from "@/store/note/getRowForNoteObservable";
import { EventContext } from "@/domains/metrics/context";
import { getLocalTimeZone } from "@internationalized/date";
import { INotesListPage } from "@/store/pages/NotesListPageStore/types";
import { INoteObservable, NotesIndexTuple } from "@/store/note";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { SortByKind } from "@/modules/lenses/types";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

export class NotesListPageStore extends AppSubStore implements INotesListPage {
  supportsAddToCollectionListAction = true;
  supportsMoveNoteToTrashListAction = true;
  listState: ListStateObservable;
  searchQuery = "";
  addToCollectionModal: AddToCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  shareSheetModal: ShareSheetModalStore;

  isLoading: boolean;
  subscriptionInitialized: boolean;
  liveQuerySubscription?: Subscription;
  subscribedNoteIndexTuples: NotesIndexTuple[] = [];

  eventContext = EventContext.NotesMultiselectActions;

  filters: NotesListFilter;

  // allows to skip unnecessary reactions
  private initialized: boolean = false;

  // ref to current scroll position of virtualized list
  private currentScrollTop = 0;

  private readonly supportedSortOptions: SortByKind[] = [
    SortByKind.LastCreated,
    SortByKind.LastModified,
    SortByKind.LastViewed,
    SortByKind.LastShared,
  ];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });
    this.filters = new NotesListFilter(this.store, this.supportedSortOptions);

    this.isLoading = true;
    this.subscriptionInitialized = false;
    this.currentScrollTop = 0;

    makeObservable<
      this,
      | "generateItemDropdown"
      | "runLiveQuery"
      | "supportedSortOptions"
      | "initialized"
      | "currentScrollTop"
    >(this, {
      useEffects: false,
      supportedSortOptions: true,
      runLiveQuery: action,
      liveQuerySubscription: observable,
      subscriptionInitialized: observable,

      subscribedNoteIndexTuples: observable,
      groupedLensItems: computed,
      itemRows: computed,

      supportsAddToCollectionListAction: observable,
      supportsMoveNoteToTrashListAction: observable,
      searchQuery: observable,
      listState: false,
      addToCollectionModal: false,
      deleteSharedNotesModal: false,
      shareSheetModal: false,
      eventContext: false,

      isLoading: observable,
      generateItemDropdown: false,
      orderedItemIds: computed,
      hasNoItems: computed,

      initialize: action,
      filters: false,
      initialized: false,

      // virtualized list index
      currentScrollTop: observable,
      scrollTop: computed,
      setScrollTop: action,
    });

    reaction(
      () => this.filters.params,
      (params, previousParams) => {
        this.store.memDb.settings.setNotesListPageParams(this.filters.params);

        if (
          params.lens !== previousParams.lens ||
          params.sortBy !== previousParams.sortBy ||
          params.filter !== previousParams.filter
        ) {
          this.setScrollTop(0);
        }
      }
    );

    reaction(
      () => this.subscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );
  }

  get groupedLensItems(): LensGroup<NotesIndexTuple>[] {
    return groupLens(
      this.subscribedNoteIndexTuples.map(tuple => ({
        dateTime: new Date(tuple.length === 4 ? tuple[2] : tuple[1]),
        item: tuple,
      }))
    );
  }

  get scrollTop() {
    return this.currentScrollTop;
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    for (const group of this.groupedLensItems) {
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      for (const item of group.items) {
        const tuple = item.item;
        output.push({
          type: MdsItemListRowType.AsyncNote,
          key: tuple.length === 4 ? tuple[3] : tuple[2],
          size: MdsItemListSize.Medium,
          payload: {
            noteId: tuple.length === 4 ? tuple[3] : tuple[2],
            itemRow: note =>
              getRowForNoteObservable({
                dateTime: item.dateTime,
                dropdown: this.generateItemDropdown({ noteObservable: note }),
                inMainPanel: true,
                listState: this.listState,
                noteObservable: note,
                store: this.store,
              }),
          },
        });
      }
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });

    return output;
  }

  // TODO: intro named type of tuples, indexes are not safe nor readable
  get orderedItemIds() {
    return this.subscribedNoteIndexTuples.map(tuple => {
      if (tuple.length === 4) {
        return tuple[3];
      }

      return tuple[2];
    });
  }

  get hasNoItems() {
    return !this.isLoading && this.subscribedNoteIndexTuples.length === 0;
  }

  setScrollTop = (value: number) => {
    this.currentScrollTop = value;
  };

  // Initialization
  async initialize() {
    if (this.initialized) return;

    this.initialized = true;

    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getNotesListPageParams(),
      this.store.memDb.settings.getNotesListPageParams(),
    ]);

    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setNotesListPageParams(localDBParams);
      await localDb.settings.removeNotesListPageParams();
    }

    this.filters.setParams({
      lens: localDBParams?.lens ?? memDbParams?.lens ?? NotesListFilter.DEFAULT_LENS,
      sortBy: localDBParams?.sortBy ?? memDbParams?.sortBy ?? NotesListFilter.DEFAULT_SORT_BY,
      filter: localDBParams?.filter ?? memDbParams?.filter,
    });
  }

  private runLiveQuery() {
    return reaction(
      () => this.filters.params,
      ({ sortBy, lens, limit, filter }) => {
        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(() => {
          const filterOption = NotesListFilter.dateFilterMap[sortBy];
          const dateRange = this.filters.dateSelectedRange;

          return this.store.notes.search({
            lens,
            sortBy,
            limit,
            filter: {
              ...filter,
              [filterOption]: {
                from: dateRange?.start?.toDate(getLocalTimeZone()).toString(),
                to: dateRange?.end?.add({ days: 1 }).toDate(getLocalTimeZone()).toString(),
                olderThan: filter?.date?.olderThan,
              },
              collections: filter?.collections ? [filter.collections] : undefined,
            },
          });
        }).subscribe({
          next: rows => {
            runInAction(() => {
              this.subscribedNoteIndexTuples = rows;
              this.subscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Share,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
              eventContext: EventContext.NotesRowActions,
            }),
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Copy,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: async () => await noteObservable.toggleFavorite(),
        },
        {
          id: `add-to-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Collection,
          label: "Organize",
          onClick: () =>
            this.addToCollectionModal.open({
              noteIds: [noteObservable.id],
              eventContext: EventContext.NotesRowActions,
            }),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: MdsIconKind.Trash,
          label: "Delete",
          onClick: async () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              await noteObservable.moveToTrash();
            }
          },
        },
      ],
    };
  }

  useEffects() {
    useEffectOnMount(() => {
      const reactionDisposer = this.runLiveQuery();
      return () => {
        reactionDisposer();
        this.liveQuerySubscription?.unsubscribe();
      };
    });
    this.listState.useListStateEffects();
  }
}
