const TABLE_NAME = "settings";
const TABLE_SCHEMA = "";

const SIDE_PANEL_STATE_KEY = "side_panel_state";
const SIDE_PANEL_ACTIVE_TAB_ID_KEY = "side_panel_active_tab_id";
const SIDE_PANEL_TABS_KEY = "side_panel_tabs";

const NOTES_LIST_PAGE_PARAMS_KEY = "notes_list_page_params";
const TEMPLATES_LIST_PAGE_PARAMS_KEY = "templates_list_page_params";
const COLLECTIONS_LIST_PAGE_PARAMS_KEY = "collections_list_page_params";
const COLLECTIONS_VIEW_PAGE_PARAMS_KEY = "collections_view_page_params";

const getCollectionsViewPageParamsKey = (collectionId: string) =>
  `${COLLECTIONS_VIEW_PAGE_PARAMS_KEY}-${collectionId}`;

export default {
  TABLE_NAME,
  TABLE_SCHEMA,
  SIDE_PANEL_STATE_KEY,
  SIDE_PANEL_ACTIVE_TAB_ID_KEY,
  SIDE_PANEL_TABS_KEY,
  NOTES_LIST_PAGE_PARAMS_KEY,
  TEMPLATES_LIST_PAGE_PARAMS_KEY,
  COLLECTIONS_LIST_PAGE_PARAMS_KEY,
  COLLECTIONS_VIEW_PAGE_PARAMS_KEY,
  getCollectionsViewPageParamsKey,
};
