import { ChatMessageContext } from "@/store/chat/types";
import { useRef, useState } from "react";
import { useAppStore } from "@/store";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";

// Typically, holding state along with "shadow state" in a ref is a bad idea.
// We need to do this here because we need the state for traditional React state-based rendering,
// because the ChatInput renders chips for the current contexts. We also need the ref because
// MemCommonRichTextInput does not re-render when state changes, and therefore React callbacks
// that use state will have stale state from the first render. In those callbacks, we use
// getProvisionalContextsFromRef() to get the current contexts.

export const useProvisionalContexts = () => {
  const { store } = useAppStore();
  const [provisionalContexts, setProvisionalContexts] = useState<ChatMessageContext[]>([]);
  const provisionalContextsRef = useRef<ChatMessageContext[]>([]);

  const addProvisionalContext = (context: ChatMessageContext) => {
    // Don't add if already in provisional contexts or nav contexts
    if (
      provisionalContextsRef.current.some(c => c.id === context.id) ||
      store.chatConversations.hasNavContext(context.id)
    ) {
      return;
    }

    trackEvent(TrackedEvent.ChatInputContextAdd, {
      context_id: context.id,
      context_kind: context.kind,
      context_label: context.observable?.()?.title ?? "",
    });

    const newContexts = [...provisionalContextsRef.current, context];
    setProvisionalContexts(newContexts);
    provisionalContextsRef.current = newContexts;
  };

  const removeProvisionalContext = (context: ChatMessageContext) => {
    // If it's a nav context, remove it from the store
    if (store.chatConversations.hasNavContext(context.id)) {
      store.chatConversations.removeNavContext(context.id);
      return;
    }

    trackEvent(TrackedEvent.ChatInputContextRemove, {
      context_id: context.id,
      context_kind: context.kind,
      context_label: context.observable?.()?.title ?? "",
    });

    // Otherwise remove it from our local state
    const newContexts = provisionalContextsRef.current.filter(
      (ctx: ChatMessageContext) => ctx.id !== context.id
    );
    setProvisionalContexts(newContexts);
    provisionalContextsRef.current = newContexts;
  };

  const getProvisionalContextsFromRef = () => {
    const allContexts = [...store.chatConversations.navContexts, ...provisionalContextsRef.current];
    return allContexts;
  };

  const resetProvisionalContexts = () => {
    store.chatConversations.clearNavContexts();
    setProvisionalContexts([]);
    provisionalContextsRef.current = [];
  };

  const combinedContexts = [...store.chatConversations.navContexts, ...provisionalContexts];

  return {
    provisionalContextState: combinedContexts,
    addProvisionalContext,
    removeProvisionalContext,
    getProvisionalContextsFromRef,
    resetProvisionalContexts,
  };
};
