import { truncate } from "@/modules/truncate";
import { useMediaQuery } from "usehooks-ts";

type Props = {
  primaryTitle: string;
  secondaryTitle: string;
  isAllShared: boolean;
};

export const useModalTitleLimit = ({ primaryTitle, secondaryTitle, isAllShared }: Props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const titleLimit = getModalTitleLimit(!!secondaryTitle.trim(), isMobile, isAllShared);

  return truncate(primaryTitle, titleLimit);
};

const getModalTitleLimit = (
  hasSecondaryTitle: boolean,
  isMobile: boolean,
  isAllShared: boolean
) => {
  if (!isMobile && !hasSecondaryTitle) {
    return 25;
  }

  if (isMobile && hasSecondaryTitle) {
    return 5;
  }

  if (isMobile && isAllShared) {
    return 5;
  }

  return 10;
};
