import type { AppSubStoreArgs } from "@/store/types";
import { type SyncUpdateValue, SyncModelKind } from "@/store/sync/types";
import { type DataExportSyncModelData } from "@/store/data-exports/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { DataExportObservable } from "@/store/data-exports/DataExportObservable";
import {
  computed,
  makeObservable,
  observable,
  runInAction,
  onBecomeObserved,
  action,
  override,
  onBecomeUnobserved,
} from "mobx";
import { Maybe } from "@/domains/common/types";
import { liveQuery, Subscription } from "dexie";
import { CreateDataExportOperation } from "@/store/sync/operations/data-exports/CreateDataExportOperation";
import { uuidModule } from "@/modules/uuid";

export class AppStoreDataExportsStore extends BaseSyncModelStore<
  DataExportObservable,
  DataExportSyncModelData
> {
  liveQuerySubscription?: Subscription;
  subscribedItems: string[] = [];

  private initialLoaded: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.DataExport, ...injectedDeps });

    makeObservable<this, "getLatestExports" | "getItems" | "setIsReady" | "initialLoaded">(this, {
      isReady: true,
      initialLoaded: observable,

      createExport: true,
      computeIndexes: override,
      createSyncModel: false,

      liveQuerySubscription: observable,
      subscribedItems: observable,
      unsubscribeLiveQuery: action,
      subscribeLiveQuery: action,

      latestExport: computed,
      length: computed,
      getLatestExports: false,
      isExportInProgress: computed,
      getItems: false,

      setIsReady: action,
    });

    onBecomeObserved(this, "subscribedItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "subscribedItems", () => this.unsubscribeLiveQuery());
  }

  createSyncModel(data: SyncUpdateValue<DataExportSyncModelData>): DataExportObservable {
    return new DataExportObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  subscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.localTable.toCollection().keys();
    }).subscribe({
      next: items => {
        runInAction(() => {
          this.subscribedItems = items as string[];
          this.setIsReady(true);
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
  }

  get isReady() {
    return this.initialLoaded;
  }

  get length(): number {
    return this.subscribedItems.length;
  }

  get latestExport(): Maybe<DataExportObservable> {
    const latestOne = this.getLatestExports(1);
    return latestOne[0];
  }

  get isExportInProgress(): boolean {
    return this.latestExport?.isRunning ?? false;
  }

  getLatestExports(count: number): DataExportObservable[] {
    const observables = this.getMany(this.subscribedItems);
    const result = observables
      .toSorted((a, b) => b.startedAt.valueOf() - a.startedAt.valueOf())
      .slice(0, count);

    return result;
  }

  // ACTIONS
  public async createExport() {
    await new CreateDataExportOperation({
      store: this.store,
      payload: {
        id: uuidModule.generate(),
        kind: "MARKDOWN",
      },
    }).execute();
  }

  // PRIVATE
  private setIsReady(value: boolean) {
    this.initialLoaded = value;
  }
}
