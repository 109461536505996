import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import {
  INoteObservable,
  NoteContentDocumentDeletedSyncUpdateValue,
  NoteContentDocumentModelData,
  NoteDeletedSyncUpdateValue,
  NoteModelData,
} from "@/store/note/types";
import {
  SpaceAccountNoteDeletedSyncUpdateValue,
  SpaceAccountNoteModelData,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IDeleteNoteOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";
export class DeleteNoteOperation extends BaseSyncOperation<IDeleteNoteOperation> {
  private existingNote?: INoteObservable;
  private title = UNTITLED_NOTE_TITLE;

  get operationKind(): SyncOperationKind {
    return "DELETE_NOTE";
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const existingNote = this.existingNote;
    if (!existingNote) return [];

    const syncUpdates = [];

    // Cascade CollectionItems
    const collectionItems = existingNote.collectionList?.allCollectionItems || [];
    for (const collectionItem of collectionItems) {
      const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
        model_id: collectionItem.id,
        model_kind: "COLLECTION_ITEM",
        model_version: collectionItem.modelVersion,
      };
      const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: collectionItemValue,
      };
      syncUpdates.push(collectionItemUpdate);
    }

    // Cascade SpaceAccountNote
    const spaceAccountNote = existingNote.spaceAccountNote;
    if (spaceAccountNote) {
      const spaceAccountNoteValue: SpaceAccountNoteDeletedSyncUpdateValue = {
        model_id: spaceAccountNote.id,
        model_kind: "SPACE_ACCOUNT_NOTE",
        model_version: spaceAccountNote.modelVersion,
      };
      const spaceAccountNoteUpdate: OptimisticSyncUpdate<SpaceAccountNoteModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: spaceAccountNoteValue,
      };
      syncUpdates.push(spaceAccountNoteUpdate);
    }

    // Cascade FavoriteItem
    const favoriteItem = existingNote.favoriteItem;
    if (favoriteItem) {
      const favoriteItemValue: FavoriteItemDeletedSyncUpdateValue = {
        model_id: favoriteItem.id,
        model_kind: "FAVORITE_ITEM",
        model_version: favoriteItem.modelVersion,
      };
      const favoriteItemUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: favoriteItemValue,
      };
      syncUpdates.push(favoriteItemUpdate);
    }

    // Finally, delete the note and its content document
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.id,
    });
    const noteContentDocumentValue: NoteContentDocumentDeletedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
    };
    const noteContentDocumentDeleted: OptimisticSyncUpdate<NoteContentDocumentModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: noteContentDocumentValue,
    };
    syncUpdates.push(noteContentDocumentDeleted);

    const noteValue: NoteDeletedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "NOTE",
      model_version: existingNote.modelVersion,
    };
    const noteDeleted: OptimisticSyncUpdate<NoteModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: noteValue,
    };
    syncUpdates.push(noteDeleted);

    return syncUpdates;
  }

  async execute() {
    this.existingNote = await this.store.notes.getAsync(this.payload.id);
    if (!this.existingNote) {
      logger.error({
        message: "can't find existing note",
        info: {
          operation: getLoggableOperation(this),
        },
      });

      return;
    }

    this.title = this.existingNote.title || UNTITLED_NOTE_TITLE;

    await super.execute();
  }

  /**
   * Triggers the recomputation of related data after a note is deleted.
   * This method performs the following actions:
   * 1. Cascades updates to CollectionItems associated with the deleted note.
   * 2. Updates the SpaceAccountNote for the user's personal space.
   * 3. Updates the FavoriteItem if the note was favorited.
   * 4. Deletes the note's content document.
   * 5. Finally, deletes the note itself.
   *
   * @returns {Promise<void>} A promise that resolves when all recomputations are complete.
   */
  async triggerRecompute(): Promise<void> {
    const existingNote = this.store.notes.get(this.payload.id);
    if (!existingNote) return;

    // Cascade CollectionItems
    const collectionItems = existingNote.collectionList?.allCollectionItems || [];
    for (const collectionItem of collectionItems) {
      await this.store.collectionItems.recompute(collectionItem.id);
    }

    // Cascade SpaceAccountNote
    const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      noteId: this.payload.id,
    });
    await this.store.spaceAccountNotes.recompute(spaceAccountNoteId);

    // Cascade FavoriteItem
    const favoriteItemId = resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.payload.id,
    });
    await this.store.favoriteItems.recompute(favoriteItemId);

    // Finally, delete the note and its content document
    const noteContentDocumentId = resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.id,
    });
    await this.store.noteContentDocuments.recompute(noteContentDocumentId);
    await this.store.notes.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be deleted. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to permanently delete {this.mediumTitle}. Please contact the
        note owner.
      </>
    );
  }

  public handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be deleted. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }
}
