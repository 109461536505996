import { action, computed, makeObservable } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { ApiKeyModelData } from "@/store/api-keys/types";
import { ApiKeyObservable } from "@/store/api-keys/ApiKeyObservable";
import { CreateApiKeyOperation } from "@/store/sync/operations/api-keys/CreateApiKeyOperation";
import { Uuid } from "@/domains/global/identifiers";
import { isEmpty } from "lodash-es";

export class AppStoreApiKeysStore extends BaseSyncModelStore<ApiKeyObservable, ApiKeyModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountAppCallout, ...injectedDeps });

    makeObservable(this, {
      createSyncModel: false,
      createApiKey: action,
      isCreatingApiKey: computed,
    });
  }

  createSyncModel(data: SyncUpdateValue<ApiKeyModelData>): ApiKeyObservable {
    return new ApiKeyObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get isCreatingApiKey() {
    const apiKeyOperations =
      this.store.sync.actionQueue.operationsByOperationKind.get("CREATE_API_KEY");

    if (isEmpty(apiKeyOperations)) {
      return false;
    }

    return true;
  }

  /**
   * Actions
   */

  async createApiKey({
    id,
    title,
    description,
    secretKeyToken,
  }: {
    id: Uuid;
    secretKeyToken: Uuid;
    title: string | null;
    description: string | null;
  }) {
    await new CreateApiKeyOperation({
      store: this.store,
      payload: {
        id,
        secret_key_token: secretKeyToken,
        title,
        description,
      },
    }).execute();
  }
}
