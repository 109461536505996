import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";
import { AccountProfileImageContent } from "@/components/layout/components/account-profile/AccountProfileImageContent";
import { ProfileSize } from "@/components/layout/components/account-profile";
import { FC } from "react";
import { ProfileVariant } from "@/components/layout/components/account-profile/types";

interface TopBarProfileImageProps {
  togglePopUpBar: () => void;
}

export const TopBarProfileImage: FC<TopBarProfileImageProps> = observer(({ togglePopUpBar }) => {
  const { store } = useAppStore();
  const { profileDisplayName, profilePhotoUrl } = store.account.myAccount;

  return (
    <ProfileImageContainer onClick={togglePopUpBar}>
      <AccountProfileImageContent
        size={ProfileSize.Standard}
        photoUrl={profilePhotoUrl}
        displayName={profileDisplayName}
        variant={ProfileVariant.Rounded}
        showSyncStatus
      />
    </ProfileImageContainer>
  );
});

const ProfileImageContainer = styled.div(({ theme }) => ({
  cursor: "pointer",
  margin: theme.spacing.sm,
  WebkitAppRegion: "no-drag",
}));
