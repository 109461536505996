import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { MdsIcon } from "@/design-system/components/icon/MdsIcon";
import { MdsIconKind } from "@/design-system/components/icon/types";

interface TemplateChipProps {
  title: string;
  inline?: boolean;
}

export const TemplateChip: FC<TemplateChipProps> = ({ title, inline }) => (
  <div className={cx(chipStyles, inline && inlineStyles)}>
    <MdsIcon
      kind={MdsIconKind.Template}
      innerStyles={{ Icon: { className: chipIconStyles } }}
      className={cx(inline && inlineStyles)}
    />
    {inline && " "}
    <MdsText
      className={inline ? inlineStyles : undefined}
      size={MdsTextSize.Large}
      weight={MdsTextWeight.Bold}
      overflow={MdsTextOverflow.Ellipsis}
    >
      {title}
    </MdsText>
  </div>
);

const chipStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
  background: mdsColors().grey.x25,
  padding: "4px 6px",
  borderRadius: 8,
  flex: "1 1 auto",
  overflow: "hidden",
});

const chipIconStyles = css({
  fontSize: 20,
});

const inlineStyles = css({
  display: "inline",
});
