import styled from "@emotion/styled";
import { CalendarCell } from "react-aria-components";

export const StyledCalendarCell = styled(CalendarCell)`
  width: 2.286rem;
  line-height: 2.286rem;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  outline: none;
  forced-color-adjust: none;
  font-size: 0.875rem;

  &[data-hovered] {
    background: ${({ theme }) => theme.colors.grey.x50};
  }

  &[data-pressed] {
    background: ${({ theme }) => theme.colors.grey.x50};
    color: ${({ theme }) => theme.colors.grey.x500};
  }

  &[data-focus-visible] {
    outline: 2px solid ${({ theme }) => theme.colors.grey.x50};
    outline-offset: 2px;
  }

  &[data-selected] {
    background: ${({ theme }) => theme.colors.grey.x50};
    color: ${({ theme }) => theme.colors.grey.x900};
    border-radius: 0;

    &[data-focus-visible] {
      outline-color: ${({ theme }) => theme.colors.grey.x900};
      outline-offset: -3px;
    }
  }

  &[data-selection-start] {
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
  }

  &[data-selection-end] {
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
  }

  &[data-disabled],
  &[data-unavailable],
  &[data-invalid] {
    cursor: none;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.grey.x200};
    background-color: ${({ theme }) => theme.colors.grey.x100};
  }

  &[data-outside-month] {
    background: none;
    color: ${({ theme }) => theme.colors.grey.x200};
  }
`;
