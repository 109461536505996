import { CollectionObservable } from "@/store/collections/CollectionObservable";
import {
  CollectionModelData,
  CollectionUpsertedSyncUpdateValue,
  IndexedCollectionSyncUpdateValue,
  CollectionSearchParams,
  CollectionSearchReturnType,
  CollectionSearchReturnTypeMap,
} from "@/store/collections/types";
import { action, makeObservable, observable, override, runInAction } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { Uuid } from "@/domains/global/identifiers";
import { CreateCollectionOperation } from "@/store/sync/operations/collections/CreateCollectionOperation";
import { UpdateCollectionOperation } from "@/store/sync/operations/collections/UpdateCollectionOperation";
import { DeleteCollectionOperation } from "@/store/sync/operations/collections/DeleteCollectionOperation";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  SerializedOptimisticUpdate,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { generateSyncActionCollectionScopedPusherChannelKey } from "@/store/sync/utils";
import { PusherEventKind } from "@/domains/pusher/constants";
import { Channel } from "pusher-js";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { liveQuery, Table } from "dexie";
import { SpaceAccountCollectionModelData } from "@/store/recent-items/types";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import { CollectionIndexes } from "@/store/collections/CollectionIndexes";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { searchCollections } from "@/store/collections/CollectionSearch";

export interface CollectionsStore {
  getCollectionObservableById: (params: {
    collectionId?: Uuid;
  }) => CollectionObservable | undefined;

  localTable: Partial<Table>;
}

export class AppStoreCollectionsStore
  extends BaseSyncModelStore<CollectionObservable, CollectionModelData>
  implements CollectionsStore
{
  private collectionPusherChannels = new Map<string, Channel>();
  public static searchTitleLimit = 100;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Collection, ...injectedDeps });
    makeObservable<
      this,
      | "collectionPusherChannels"
      | "getSearchableData"
      | "updateSearchSuggestions"
      | "generateSearchSuggestions"
    >(this, {
      remoteTable: override,
      localTable: override,
      recompute: override,
      bulkRecompute: override,
      subscribeToIds: override,

      collectionPusherChannels: observable,

      createSyncModel: false,
      search: false,
      getMatchingCollections: false,
      getMatchingSharedCollections: false,
      getSearchableData: false,
      getCollectionObservableById: true,
      updateSearchSuggestions: action,
      generateSearchSuggestions: false,

      processSyncUpdate: override,
      subscribeToCollection: action,
      unsubscribeFromCollection: action,

      createCollection: action,
      updateCollection: action,
      deleteCollection: action,
    });
  }

  public subscribeToIds() {
    // Override subscribeToIds to initialize the Pusher subscriptions
    this.idsSubscription?.unsubscribe();
    this.idsSubscription = liveQuery(() => this.localTable.toCollection().primaryKeys()).subscribe({
      next: ids => {
        runInAction(() => this.ids.replace(ids));
        for (const id of ids) this.subscribeToCollection(id);
      },
    });
  }

  createSyncModel(data: IndexedCollectionSyncUpdateValue): CollectionObservable {
    return new CollectionObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<
      SyncUpdateValue<CollectionModelData>
    >;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<IndexedCollectionSyncUpdateValue>;
  }

  public override async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");
    const lastOptimisticUpdate = optimisticUpdates.at(-1);

    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      await this.localTable.delete(modelId);
      runInAction(() => this.pool.delete(modelId));
      await this.store.search.remove(modelId);
      return;
    }

    // Fetch other required data
    const spaceAccountCollectionId = resolveSpaceAccountCollectionSyncModelUuid({
      collectionId: modelId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });
    const spaceAccountCollection = (await this.db.mappedTables[
      SyncModelKind.SpaceAccountCollection
    ].local.get(spaceAccountCollectionId)) as
      | SyncUpdateValue<SpaceAccountCollectionModelData>
      | undefined;

    // Optimistic updates
    const data = lastOptimisticUpdate?.value || remoteData; // At least one of these should be defined

    if (!data) {
      await this.localTable.delete(modelId);
      await this.store.search.remove(modelId);
      runInAction(() => this.pool.delete(modelId));
      return;
    }

    const indexes = new CollectionIndexes({
      store: this.store,
      remoteData,
      optimisticUpdates,
      spaceAccountCollection,
    }).indexes;

    const dataWithIndexes: IndexedCollectionSyncUpdateValue = {
      ...(data as CollectionUpsertedSyncUpdateValue),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);
    await this.updateSearchSuggestions(dataWithIndexes);
  }

  public override async bulkRecompute(modelIds: Uuid[]) {
    const remoteData = (await this.remoteTable.bulkGet(modelIds)) as (
      | SyncUpdateValue<CollectionModelData>
      | undefined
    )[];

    const allOptimisticUpdates = await this.db.queue.optimisticUpdates
      .where("model_id")
      .anyOf(modelIds)
      .sortBy("locally_committed_at");
    const optimisticUpdatesById = new Map<
      Uuid,
      SerializedOptimisticUpdate<CollectionModelData>[]
    >();
    for (const update of allOptimisticUpdates) {
      optimisticUpdatesById.set(update.model_id, [
        ...(optimisticUpdatesById.get(update.model_id) || []),
        update,
      ]);
    }

    const collectionIdsToSpaceAccountCollectionIds = new Map(
      modelIds.map(modelId => [
        modelId,
        resolveSpaceAccountCollectionSyncModelUuid({
          collectionId: modelId,
          spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
        }),
      ])
    );
    const spaceAccountCollectionIds = Array.from(collectionIdsToSpaceAccountCollectionIds.values());
    const spaceAccountCollections = (await this.db.mappedTables[
      SyncModelKind.SpaceAccountCollection
    ].local.bulkGet(spaceAccountCollectionIds)) as (
      | SyncUpdateValue<SpaceAccountCollectionModelData>
      | undefined
    )[];
    const collectionIdsToSpaceAccountCollections: Map<
      Uuid,
      SyncUpdateValue<SpaceAccountCollectionModelData> | undefined
    > = new Map(
      spaceAccountCollectionIds.map((_spaceAccountCollectionId, i) => [
        modelIds[i],
        spaceAccountCollections[i],
      ])
    );

    const toDelete: Uuid[] = [];
    const toPut: IndexedCollectionSyncUpdateValue[] = [];
    const searchSuggestions: SearchSuggestion[] = [];

    for (const [i, modelId] of modelIds.entries()) {
      const spaceAccountCollection = collectionIdsToSpaceAccountCollections.get(modelId);
      const optimisticUpdates = optimisticUpdatesById.get(modelId) || [];
      const lastOptimisticUpdate = optimisticUpdates.at(-1);

      if (
        lastOptimisticUpdate?.kind === "DELETED" ||
        lastOptimisticUpdate?.kind === "ACL_REVOKED"
      ) {
        toDelete.push(modelId);
        continue;
      }

      const data = (lastOptimisticUpdate?.value || remoteData[i]) as
        | SyncUpdateValue<CollectionModelData>
        | undefined;

      if (!data) {
        // Case: remoteData is deleted and there are no optimistic updates\
        toDelete.push(modelId);
        continue;
      }

      const indexes = new CollectionIndexes({
        store: this.store,
        remoteData: data,
        optimisticUpdates,
        spaceAccountCollection,
      }).indexes;

      const dataWithIndexes: IndexedCollectionSyncUpdateValue = {
        ...(data as CollectionUpsertedSyncUpdateValue),
        ...indexes,
      };

      toPut.push(dataWithIndexes);
      searchSuggestions.push(this.generateSearchSuggestions(dataWithIndexes));
    }

    await this.db.transaction("rw", this.localTable, async () => {
      await this.localTable.bulkDelete(toDelete);
      await this.localTable.bulkPut(toPut);
    });

    await this.store.search.bulkUpdateSuggestions(searchSuggestions);

    runInAction(() => {
      for (const modelId of toDelete) {
        this.pool.delete(modelId);
      }
      // this is a noop update to retrigger get for observables that previously returned undefined in the case they they become defined through the recompute
      for (const data of toPut) {
        const modelId = data.model_id;
        if (!this.pool.has(modelId)) {
          this.pool.set(modelId, undefined);
          this.pool.delete(modelId);
        }
      }
    });
  }

  public async processSyncUpdate(update: SyncUpdate<CollectionModelData>) {
    await super.processSyncUpdate(update);
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED") {
      this.subscribeToCollection(update.value.model_id);
    }

    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED") {
      this.unsubscribeFromCollection(update.value.model_id);
    }
  }

  public subscribeToCollection(collectionId: string) {
    try {
      if (this.collectionPusherChannels.has(collectionId)) return;
      console.debug("[SYNC][AppStoreCollectionsStore] Subscribing to collection", collectionId);
      const collectionPusherChannelKey = generateSyncActionCollectionScopedPusherChannelKey({
        collectionId,
      });
      const channel = this.pusher.subscribe(collectionPusherChannelKey);
      channel.bind(PusherEventKind.SYNC_UPDATE_PUBLISHED, this.store.sync.queryForSyncActions);
      this.collectionPusherChannels.set(collectionId, channel);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreCollectionsStore] Error subscribing to collection",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  public unsubscribeFromCollection(collectionId: string) {
    try {
      const channel = this.collectionPusherChannels.get(collectionId);
      if (channel)
        console.debug(
          "[SYNC][AppStoreCollectionsStore] Unsubscribing from collection",
          collectionId
        );
      channel?.unsubscribe();
      this.collectionPusherChannels.delete(collectionId);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreCollectionsStore] Error unsubscribing from collection",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  public getCollectionObservableById = ({
    collectionId,
  }: {
    collectionId?: Uuid;
  }): CollectionObservable | undefined => {
    return collectionId ? this.get(collectionId) : undefined;
  };

  // ACTIONS
  public async createCollection({
    collectionId,
    title,
    description,
    eventContext,
  }: {
    collectionId?: Uuid;
    title?: string;
    description?: string;
    eventContext: EventContext;
  }) {
    await new CreateCollectionOperation({
      store: this.store,
      payload: {
        id: collectionId,
        title,
        description,
      },
    }).execute();
    trackEvent(TrackedEvent.CollectionCreate, {
      collection_id: collectionId,
      collection_primary_label: title,
      context: eventContext,
    });
  }

  public async updateCollection({
    collectionId,
    title,
    description,
  }: {
    collectionId: Uuid;
    title?: string;
    description?: string;
  }) {
    await new UpdateCollectionOperation({
      store: this.store,
      payload: {
        id: collectionId,
        title,
        description,
      },
    }).execute();
  }

  public async deleteCollection({ collectionId }: { collectionId: Uuid }) {
    await new DeleteCollectionOperation({
      store: this.store,
      payload: { id: collectionId },
    }).execute();
  }

  private generateSearchSuggestions(value: IndexedCollectionSyncUpdateValue): SearchSuggestion {
    const generateSortKey = (includeMentionedAt: boolean): number => {
      // TODO: utilize receivedAt and lastMentionedAt when ready
      let sortKey = value.last_viewed_at || value.model_data.shared_at;
      if (includeMentionedAt) {
        sortKey = value.last_added_to_at || sortKey;
      }

      return new Date(sortKey || value.created_at).getTime();
    };

    const suggestion = {
      modelId: value.model_id,
      label: value.title.slice(0, AppStoreCollectionsStore.searchTitleLimit) || "",
      lowercaseLabel: value.title.slice(0, AppStoreCollectionsStore.searchTitleLimit).toLowerCase(),
      type: SearchSuggestionType.COLLECTION,
      lastViewedAt: value.last_viewed_at,
      sortKey: generateSortKey(false),
      mentionKey: generateSortKey(true),
      isAvailable: value.is_available,
    };

    return suggestion;
  }

  private async updateSearchSuggestions(value: IndexedCollectionSyncUpdateValue) {
    try {
      const suggestion = this.generateSearchSuggestions(value);
      await this.store.search.updateSuggestion(suggestion);
    } catch (e) {
      logger.error({
        message:
          "[SYNC][AppStoreCollectionsStore] Error updating search suggestions and offline entry",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  // SEARCH
  public async search<T extends CollectionSearchReturnType>(
    params: CollectionSearchParams<T>
  ): Promise<CollectionSearchReturnTypeMap<T>> {
    return searchCollections(this.store, params);
  }

  public getMatchingCollections = async (query: string) => {
    if (!query) return [];
    return this.search({
      query,
      returns: CollectionSearchReturnType.CollectionObservable,
    });
  };

  public getMatchingSharedCollections = async (query: string): Promise<CollectionObservable[]> => {
    if (!query) return [];
    return this.search({
      query,
      filter: { isShared: true },
      returns: CollectionSearchReturnType.CollectionObservable,
    });
  };
}
