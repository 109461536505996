import { Uuid } from "@/domains/global/identifiers";
import { resolveSyncModelNamespacedUuid } from "@/modules/uuid/sync-models/resolveSyncModelNamespacedUuid";
import { SyncModelKind } from "@/store/sync/types";

export const resolveSpaceAccountTemplateSyncModelUuid = ({
  spaceAccountId,
  templateId,
}: {
  spaceAccountId: Uuid;
  templateId: Uuid;
}): Uuid => {
  return resolveSyncModelNamespacedUuid({
    modelKind: { value: SyncModelKind.SpaceAccountTemplate },
    uuids: [spaceAccountId, templateId],
  });
};
