import { useMemo } from "react";
import { MdsIconKind } from "@/design-system/components/icon/types";

import { MdsDropdownItemKind } from "@/design-system/components/dropdown/types";

import { MdsDropdownItem } from "@/design-system/components/dropdown/types";
import { SyncStatus } from "@/components/sync/SyncStatus";
import { isWin } from "@/domains/platform/isWin";
import { clientEnvModule } from "@/modules/client-env";
import { SyncState } from "@/components/sync/types";

export interface ProfileDropdownItemsHandlers {
  handleDesktop: () => void;
  handleMobile: () => void;
  handleLogOut: () => void;
  handleOpenSettings: () => void;
  handleOpenTemplates: () => void;
  handleTrashClick: () => void;
  handleNavigateToAdminPanel: () => void;
  handleToggleDebugging: () => void;
  handleToggleNoteContentPreloading: () => void;
  handleResetSync: () => void;
  handlePushFailedSyncOperation: () => void;
  handlePushPermissionDeniedSyncOperation: () => void;
  handleNavigateToHelpAndSupport: () => void;
  handlePauseSync: () => void;
  handleResumeSync: () => void;
}

export interface SyncInfo {
  isNoteContentPreloadingDisabled: boolean;
  syncState: SyncState;
}

export const useProfileDropdownItems = ({
  isDebugMode = false,
  handlers,
  syncInfo,
}: {
  isDebugMode?: boolean;
  handlers: ProfileDropdownItemsHandlers;
  syncInfo: SyncInfo;
}) => {
  const isWindows = isWin();
  const isDesktop = clientEnvModule.isDesktop();
  const downloadDesktopAppDropdownItems = useMemo<MdsDropdownItem[]>(
    () =>
      isDesktop
        ? []
        : [
            {
              id: "get-desktop-app",
              kind: MdsDropdownItemKind.Button,
              label: `Download ${isWindows ? "Windows" : "Mac"} app`,
              iconKind: MdsIconKind.Laptop,
              onClick: handlers.handleDesktop,
            },
          ],
    [handlers.handleDesktop, isDesktop, isWindows]
  );
  const basicDropdownItems = useMemo<MdsDropdownItem[]>(
    () => [
      {
        id: "settings",
        kind: MdsDropdownItemKind.Button,
        label: "Settings",
        iconKind: MdsIconKind.Settings,
        onClick: handlers.handleOpenSettings,
      },
      {
        id: "view-trash",
        kind: MdsDropdownItemKind.Button,
        label: "Trash",
        iconKind: MdsIconKind.Trash,
        onClick: handlers.handleTrashClick,
      },
      {
        id: "sign-out",
        kind: MdsDropdownItemKind.Button,
        label: "Sign out",
        iconKind: MdsIconKind.LogOut,
        onClick: handlers.handleLogOut,
      },
      {
        id: "divider-3",
        kind: MdsDropdownItemKind.Divider,
      },
      ...downloadDesktopAppDropdownItems,
      {
        id: "get-ios-app",
        kind: MdsDropdownItemKind.Button,
        label: "Download iOS app",
        iconKind: MdsIconKind.PhoneSms,
        onClick: handlers.handleMobile,
      },
      {
        id: "help-and-support",
        kind: MdsDropdownItemKind.Button,
        label: "Help and support",
        iconKind: MdsIconKind.Help,
        onClick: handlers.handleNavigateToHelpAndSupport,
      },
      {
        id: "divider-4",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "sync-state",
        kind: MdsDropdownItemKind.Other,
        content: <SyncStatus syncState={syncInfo.syncState} />,
      },
    ],
    [
      downloadDesktopAppDropdownItems,
      handlers.handleLogOut,
      handlers.handleMobile,
      handlers.handleNavigateToHelpAndSupport,
      handlers.handleOpenSettings,
      handlers.handleTrashClick,
      syncInfo.syncState,
    ]
  );

  const debugModeContentListItems = useMemo<MdsDropdownItem[]>(
    () => [
      {
        id: "divider-5",
        kind: MdsDropdownItemKind.Divider,
      },
      {
        id: "header",
        kind: MdsDropdownItemKind.Detail,
        text: "Admin Features",
      },
      {
        id: "debug-mode-panel",
        kind: MdsDropdownItemKind.Button,
        label: "Admin Panel",
        iconKind: MdsIconKind.GearCode,
        onClick: handlers.handleNavigateToAdminPanel,
      },
      {
        id: "toggle-queue-debug",
        kind: MdsDropdownItemKind.Button,
        label: "Toggle queue debug panel",
        iconKind: MdsIconKind.Question,
        onClick: handlers.handleToggleDebugging,
      },
      {
        id: "toggle-note-content-preloading",
        kind: MdsDropdownItemKind.Button,
        label: syncInfo.isNoteContentPreloadingDisabled
          ? "Enable note content preloading"
          : "Disable note content preloading",
        iconKind: MdsIconKind.Question,
        onClick: handlers.handleToggleNoteContentPreloading,
      },
      {
        id: "reset-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Reset Sync",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handleResetSync,
      },
      {
        id: "pause-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Pause Sync",
        iconKind: MdsIconKind.Exit,
        onClick: handlers.handlePauseSync,
      },
      {
        id: "resume-sync",
        kind: MdsDropdownItemKind.Button,
        label: "Resume Sync",
        iconKind: MdsIconKind.Play,
        onClick: handlers.handleResumeSync,
      },
      {
        id: "push-failed-sync-action",
        kind: MdsDropdownItemKind.Button,
        label: "Push failed sync operation",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handlePushFailedSyncOperation,
      },
      {
        id: "push-rejected-sync-operation",
        kind: MdsDropdownItemKind.Button,
        label: "Push permission denied sync operation",
        iconKind: MdsIconKind.Warning,
        onClick: handlers.handlePushPermissionDeniedSyncOperation,
      },
    ],
    [
      handlers.handleNavigateToAdminPanel,
      handlers.handlePauseSync,
      handlers.handlePushFailedSyncOperation,
      handlers.handlePushPermissionDeniedSyncOperation,
      handlers.handleResetSync,
      handlers.handleResumeSync,
      handlers.handleToggleDebugging,
      handlers.handleToggleNoteContentPreloading,
      syncInfo.isNoteContentPreloadingDisabled,
    ]
  );

  return useMemo(
    () =>
      !isDebugMode ? basicDropdownItems : [...basicDropdownItems, ...debugModeContentListItems],

    [basicDropdownItems, debugModeContentListItems, isDebugMode]
  );
};
