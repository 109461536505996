import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import styled from "@emotion/styled";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { ExportItem } from "@/pages/settings/components/data-export";

export const SettingsDataExportSection: FC = observer(() => {
  const { store } = useAppStore();
  const exportIsRunning = store.dataExports.isExportInProgress;
  const exportsAreReady = store.dataExports.isReady;

  const latestModelData = store.dataExports.latestExport?.modelData;
  const said = latestModelData?.owned_by_space_account_id ?? "no_space_account";
  const eid = store.dataExports.latestExport?.id ?? "no_export_id";
  const jobUrl = `https://cloud.temporal.io/namespaces/prod-backend-async-worker.rqp4d/workflows/ExportDataForSpaceAccountWorkflow-${said}-${eid}`;

  const handleExportClick = useCallback(async () => {
    trackEvent(TrackedEvent.ExportCreate);
    store.dataExports.createExport();
  }, [store.dataExports]);

  return (
    <>
      <p>Export your notes and collections as Markdown files.</p>
      <h3>How it works</h3>
      <ul>
        <li>Each of your notes will be exported as a Markdown file.</li>
        <li>
          Collections will be exported as a Markdown files that list all the notes in that
          collection.
        </li>
        <li>
          Images and attachments in your notes will be saved to a folder called
          &quot;attachments&quot;.
        </li>
        <li>It will all be zipped up and you can download it to your computer.</li>
      </ul>

      {exportsAreReady && (
        <>
          <h3>Your Exports</h3>
          <ExportsContainer>
            <MdsButton
              variant={MdsButtonVariant.Tertiary}
              label="Export all notes and collections"
              onClick={handleExportClick}
              isDisabled={exportIsRunning}
            />
            {store.dataExports.latestExport?.isRunning && store.debug.debugModeEnabled && (
              <DebugJobUrl>
                <a href={jobUrl} target="_blank" rel="noreferrer">
                  DEBUG: Temporal Job
                </a>
              </DebugJobUrl>
            )}
            {store.dataExports.getLatestExports(3).map(dataExport => (
              <ExportItem dataExport={dataExport} key={dataExport.id} />
            ))}
          </ExportsContainer>
        </>
      )}
    </>
  );
});

const ExportsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: 8,
  width: "fit-content",
});

const DebugJobUrl = styled.div({
  paddingLeft: 12,
});
