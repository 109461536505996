import { useState, ChangeEvent, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { ContactsFacetFilter } from "@/components/filters/ContactsFacetFilter";
import { useAppStore } from "@/store";
import { ContactsFacetFilterItem } from "@/components/filters/types";

interface CollectionsListPageContactFacetFilterProps {
  buttonPrefix: string;
  selectedSpaceAccountIds?: string[];
  onSelectItem: (id: string) => void;
  onSelectClear: () => void;
}

export const CollectionsListPageContactFacetFilter = observer(
  ({
    onSelectItem,
    selectedSpaceAccountIds,
    buttonPrefix,
    onSelectClear,
  }: CollectionsListPageContactFacetFilterProps) => {
    const [searchText, setSearchText] = useState("");
    const { store } = useAppStore();

    const mySpaceAccount = store.spaceAccounts.myPersonalSpaceAccount;
    const allContacts = store.contacts.displayContacts;

    const items = useMemo(() => {
      const output: ContactsFacetFilterItem[] = [];
      if (mySpaceAccount) {
        output.push({
          id: mySpaceAccount.id,
          displayName: `${mySpaceAccount.profileDisplayName} (Me)`,
          emailAddress: mySpaceAccount.profileEmailAddress,
          photoUrl: mySpaceAccount.profilePhotoUrl,
          isVisible: true,
          isSelected: selectedSpaceAccountIds?.includes(mySpaceAccount.id) ?? false,
        });
      }
      allContacts.forEach(contact => {
        output.push({
          id: contact.contactSpaceAccountId,
          displayName: contact.profileDisplayName,
          emailAddress: contact.profileEmailAddress,
          photoUrl: contact.profilePhotoUrl,
          isVisible: true,
          isSelected: selectedSpaceAccountIds?.includes(contact.contactSpaceAccountId) ?? false,
        });
      });
      return output;
    }, [mySpaceAccount, allContacts, selectedSpaceAccountIds]);

    const filteredItems = useMemo(
      () =>
        items.filter(({ displayName, emailAddress }) => {
          const lowerSearchText = searchText.toLowerCase();
          if (
            displayName?.toLowerCase().includes(lowerSearchText) ||
            emailAddress?.toLowerCase().includes(lowerSearchText)
          ) {
            return true;
          }
          return false;
        }),
      [items, searchText]
    );

    const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearchText(value);
    };

    const onSearchTextClear = () => {
      setSearchText("");
    };

    return (
      <ContactsFacetFilter
        buttonPrefix={buttonPrefix}
        searchText={searchText}
        onSearchTextChange={onSearchTextChange}
        onSearchTextClear={onSearchTextClear}
        items={filteredItems}
        onSelectItem={onSelectItem}
        onSelectClear={onSelectClear}
      />
    );
  }
);
