import { observer } from "mobx-react-lite";
import { FC } from "react";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { useAppBannerLinkOnClickHandler } from "@/components/banners/app-callout-banner/utils/app-banner-link-handling";
import styled from "@emotion/styled";

interface AppCalloutBannerInlineActionProps {
  label: string | null;
  url: string | null;
}

export const AppCalloutBannerInlineAction: FC<AppCalloutBannerInlineActionProps> = observer(
  ({ label, url }) => {
    if (!label) return null;
    if (!url) return null;

    const { onClickHandler } = useAppBannerLinkOnClickHandler({ url });

    return (
      <InlineActionText
        size={MdsTextSize.Small}
        lineHeight={MdsTextSize.Small}
        weight={MdsTextWeight.Regular}
        onClick={onClickHandler}
      >
        {label}
      </InlineActionText>
    );
  }
);

const InlineActionText = styled(MdsText)({
  display: "inline",
  marginLeft: "4px",
});
