import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IDeleteTemplateOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateDeletedSyncUpdateValue, TemplateModelData } from "@/store/templates/types";

export class DeleteTemplateOperation extends BaseSyncOperation<IDeleteTemplateOperation> {
  get operationKind(): SyncOperationKind {
    return "DELETE_TEMPLATE";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;
    const existingTemplate = this.store.templates.get(templateId);
    if (!existingTemplate) return [];

    const value: TemplateDeletedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
    };

    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
