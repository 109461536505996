import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { css } from "@/domains/emotion";
import { uuidModule } from "@/modules/uuid";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { mdsColors } from "@/design-system/foundations";
import {
  MdsDropdownIconButton,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { UNTITLED_TEMPLATE_TITLE } from "@/domains/untitled/untitled";

export const CollectionsViewPageNewNote = observer<{ isSidePanel: boolean }>(({ isSidePanel }) => {
  const { store, pageStore } = useAppStore();
  const page = isSidePanel ? store.sidePanel.collectionsViewPage : pageStore.collectionsViewPage;

  const collection = page.collection;
  const collectionTemplates = store.templates.getMany(
    page.subscribedTemplateRows.map(tuple => (tuple.length === 2 ? tuple[1] : tuple[2]))
  );

  const dropdownContentList: MdsDropdownContentList = {
    items: [
      {
        id: "manage-templates",
        kind: MdsDropdownItemKind.Button,
        label: "Manage templates",
        iconKind: MdsIconKind.Settings,
        onClick: () => store.navigation.goToTemplates(),
      },
    ],
  };

  if (!collection || !collection.canWrite) return null;
  return (
    <Container>
      <ContainerHeader>
        <HeaderText>Create a new note in this collection</HeaderText>
        <MdsDropdownIconButton
          placement="below-right-alignment"
          contentList={dropdownContentList}
        />
      </ContainerHeader>
      <CarouselContainer>
        <CollectionsViewPageNewNoteButton
          label="Blank Note"
          iconKind={MdsIconKind.EmptyDocument}
          onClick={() => page.handleCreateNewNote()}
        />
        {collectionTemplates.map(template => (
          <CollectionsViewPageNewNoteButton
            key={template.id}
            label={template.title || UNTITLED_TEMPLATE_TITLE}
            secondary="Use template"
            iconKind={MdsIconKind.Document}
            onClick={async () => {
              const noteId = uuidModule.generate();
              await store.templates.createNewNoteUsingTemplate({
                noteId,
                templateId: template.id,
              });
              await store.navigation.goToNote({ noteId });
            }}
          />
        ))}
        <CollectionsViewPageNewNoteButton
          label="Add a template"
          secondary="Format notes consistently"
          iconKind={MdsIconKind.Settings}
          onClick={async () => {
            const templateId = uuidModule.generate();
            await store.templates.createTemplate({ templateId });
            const template = await store.templates.getAsync(templateId);
            await template?.addToCollection({
              targetCollectionId: collection.id,
              roleKind: "EDITOR",
            });
            await store.navigation.goToTemplate({ templateId });
          }}
          variant="secondary"
        />
      </CarouselContainer>
    </Container>
  );
});

const CollectionsViewPageNewNoteButton = ({
  label,
  secondary,
  iconKind,
  onClick,
  variant = "primary",
}: {
  label: string;
  secondary?: string;
  iconKind: MdsIconKind;
  onClick?: () => void;
  variant?: "primary" | "secondary";
}) => {
  return (
    <ButtonContainer onClick={onClick} variant={variant}>
      <IconWrapper variant={variant}>
        <MdsIcon kind={iconKind} innerStyles={{ Icon: { className: buttonIconStyles } }} />
      </IconWrapper>
      <ButtonText>
        <ButtonPrimaryText>{label}</ButtonPrimaryText>
        <ButtonSecondaryText>{secondary}</ButtonSecondaryText>
      </ButtonText>
    </ButtonContainer>
  );
};

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  padding: theme.spacing.md,
  borderRadius: theme.borderRadius.mediumLarge,
  backgroundColor: theme.colors.grey.x25,
  flexShrink: 0,
}));

const ContainerHeader = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const HeaderText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.medium,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.grey.x600,
}));

const CarouselContainer = styled(FadeHorizontalScroll)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
});

const ButtonContainer = styled.button<{ variant?: "primary" | "secondary" }>(
  ({ theme, variant }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing.md,
    width: "250px",
    height: "64px",
    backgroundColor: variant === "primary" ? theme.colors.white : theme.colors.grey.x25,
    borderColor: theme.colors.grey.x100,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.borderRadius.mediumLarge,
    padding: "0px 12px",
    flexShrink: 0,
  })
);

const IconWrapper = styled.div<{ variant?: "primary" | "secondary" }>(({ theme, variant }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: variant === "primary" ? theme.colors.grey.x50 : theme.colors.grey.x100,
  padding: "10px",
  borderRadius: theme.borderRadius.maximum,
}));

const ButtonText = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing.xxs,
  width: "100%",
  overflow: "hidden",
  minWidth: 0,
}));

const ButtonPrimaryText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.medium,
  color: theme.colors.grey.x600,
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
}));

const ButtonSecondaryText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xsmall,
  fontWeight: theme.fontWeights.regular,
  color: theme.colors.grey.x400,
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
}));

const buttonIconStyles = css({
  height: 20,
  width: 20,
  color: mdsColors().grey.x400,
});
