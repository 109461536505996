import { AppStore } from "@/store/AppStore";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import React from "react";

export async function generateTemplateSharingText({
  template,
  store,
}: {
  template: TemplateObservable;
  store: AppStore;
}): Promise<JSX.Element | null> {
  if (!template) return null;
  if (!template.isAutoSharingEnabled) return null;

  const targetScopes = template.modelData.target_scopes;
  const targetScopeCollectionIds = targetScopes
    .filter(scope => scope.scope_kind === "COLLECTION_SCOPE")
    .map(scope => scope.value.collection_id);
  const targetScopeSpaceAccountIds = targetScopes
    .filter(scope => scope.scope_kind === "SPACE_ACCOUNT_SCOPE")
    .map(scope => scope.value.space_account_id)
    .filter(id => id !== store.spaceAccounts.myPersonalSpaceAccountId);
  const targetScopeCollections = store.collections.getMany(targetScopeCollectionIds);
  const targetScopeContacts = store.contacts.getManyBySpaceAccountId(targetScopeSpaceAccountIds);
  const hasTargetScopes = targetScopeCollections.length > 0 || targetScopeContacts.length > 0;
  if (!hasTargetScopes) return null;

  const privateCollectionNames = targetScopeCollections
    .filter(collection => collection.modelData.shared_at === null)
    .map(collection => collection.label);
  const sharedCollectionNames = targetScopeCollections
    .filter(collection => collection.modelData.shared_at !== null)
    .map(collection => collection.label);
  const contactEmails = targetScopeContacts.map(contact => contact?.profileEmailAddress);
  const sharedNames = [...sharedCollectionNames, ...contactEmails];

  return (
    <>
      <span>
        Note{" "}
        {privateCollectionNames.length > 0 && (
          <>
            added to <BoldedList items={privateCollectionNames} />
          </>
        )}
        {privateCollectionNames.length > 0 && sharedNames.length > 0 && " and "}
        {sharedNames.length > 0 && (
          <>
            shared with <BoldedList items={sharedNames} />
          </>
        )}
      </span>
    </>
  );
}

export const BoldedList = ({ items }: { items: string[] }) => (
  <>
    {items.map((item, index) => (
      <React.Fragment key={index}>
        {index > 0 && index === items.length - 1 && " and "}
        {index > 0 && index < items.length - 1 && ", "}
        <strong>{item}</strong>
      </React.Fragment>
    ))}
  </>
);
