import { ChatHeader } from "@/components/chat/ChatHeader";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { Panel } from "@/components/layout/Panel";
import { mdsSpacings } from "@/design-system/foundations/typography";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { ChatInput, MentionChip } from "@/pages/chat/ChatInput";
import { ChatMessagesList } from "@/pages/chat/list";
import { mentionChipToContext } from "@/pages/chat/lib";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { logger } from "@/modules/logger";
import { useProvisionalContexts } from "@/components/chat/useContexts";
import { MdsLink } from "@/mds/components/link";
import { useAppStore } from "@/store";
import { mdsColors } from "@/design-system/foundations";
import { AppCalloutBanner } from "@/components/banners/app-callout-banner/app-callout-banner";

import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { useChatSubscription } from "@/components/chat/use-chat-subscription";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { ChatMessageContextKind } from "@/store/chat/types";
import { css } from "@/domains/emotion";

export interface ChatViewProps {
  conversationId: string;
  inSidePanel?: boolean;
  isGuidedChat_experiment?: boolean;
  onNewChat?: () => void;
  onSelectChat?: (conversationId: string) => void;
}

const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingBottom: theme.spacing.md,
  position: "relative",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const FloatingButtonContainer = styled.div({
  position: "absolute",
  top: "80px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
});

export const ChatView = observer<ChatViewProps>(function ChatView({
  conversationId,
  inSidePanel = false,
  isGuidedChat_experiment = true,
  onNewChat,
  onSelectChat,
}) {
  const { store } = useAppStore();
  const [chatInputHeight, setChatInputHeight] = useState(80);
  const [showResumeButton, setShowResumeButton] = useState(false);
  const [recentConversationId, setRecentConversationId] = useState<string | undefined>();
  const [recentConversationTimestamp, setRecentConversationTimestamp] = useState<
    number | undefined
  >();

  const { subscribeToChat } = useChatSubscription(conversationId);

  const chatHistoryRef = useRef<ChatHistory | undefined>(undefined);
  const chatHistory = store.chatMessages.getChatHistoryById_forGuidedChat(conversationId);
  chatHistoryRef.current = chatHistory;

  const {
    provisionalContextState,
    addProvisionalContext,
    removeProvisionalContext,
    getProvisionalContextsFromRef,
    resetProvisionalContexts,
  } = useProvisionalContexts();

  const showEmptyState = !chatHistory || chatHistory.items.length === 0;

  const conversation = store.chatConversations.getChatConversation(conversationId);
  const isConversationTooLong = conversation?.isTooLong ?? false;
  const isConversationArchived = conversation?.isPrimaryChatConversation ?? false;
  const isInputDisabled = isConversationTooLong || isConversationArchived;

  /**
   * Handling for "normal chat".
   *
   * Once the story is ready, we grab the primary chat conversation and subscribe to it.
   * We unsubscribe on unmount.
   */
  useEffect(() => {
    if (!isGuidedChat_experiment) {
      subscribeToChat();
    }
  }, [subscribeToChat, isGuidedChat_experiment]);

  /**
   * Track views of the chat.
   */
  useEffect(() => {
    /**
     * @todo - Add context about what note/collection is side-by-side with the chat.
     */
    trackEvent(TrackedEvent.ChatView, {
      is_in_side_panel: inSidePanel,
      is_guided_chat: isGuidedChat_experiment,
    });
  }, [inSidePanel, isGuidedChat_experiment]);

  // Check for recent conversations when a new chat is created
  useEffect(() => {
    if (isGuidedChat_experiment && showEmptyState) {
      store.chatMessages.getMostRecentlySentUserMessage().then(recent => {
        if (recent && recent.conversationId !== conversationId) {
          setShowResumeButton(true);
          setRecentConversationId(recent.conversationId);
          setRecentConversationTimestamp(recent.locallyCreatedAt.getTime());
        } else {
          setShowResumeButton(false);
          setRecentConversationId(undefined);
          setRecentConversationTimestamp(undefined);
        }
      });
    } else {
      setShowResumeButton(false);
      setRecentConversationId(undefined);
      setRecentConversationTimestamp(undefined);
    }
  }, [conversationId, isGuidedChat_experiment, store.chatMessages, showEmptyState]);

  const getDraftMessage = useCallback(() => {
    return chatHistory?.getDraftMessage();
  }, [chatHistory]);

  const handleDraftChange = useCallback(
    (draftMessage?: string) => {
      // if it's an empty message, clear the draft message
      const msg = isEmptyMessage(draftMessage) ? undefined : draftMessage;
      chatHistory?.setDraftMessage(msg);
    },
    [chatHistory]
  );

  const handleAddMentionToContexts = useCallback(
    (mention: MentionChip) => {
      const mentionContext = mentionChipToContext(mention);
      if (mentionContext) {
        addProvisionalContext(mentionContext);
      } else {
        logger.error({
          message: "Failed to convert mention to context",
          info: {
            id: mention.id,
            kind: mention.kind ?? "undefined",
          },
        });
      }
    },
    [addProvisionalContext]
  );

  const handleSubmitChatMessage = (payload: {
    markdownContent: string;
    isGuidedChat_experiment: boolean;
    agentMode: boolean;
  }) => {
    // This needs to be a ref because the ChatInput seems to only take the first callback value
    // passed to it, so if you use `chatHistory` directly, it will only use the initial value.
    chatHistoryRef.current?.submitChatMessage({
      ...payload,
      contexts: getProvisionalContextsFromRef(),
    });
    resetProvisionalContexts();
  };

  const handleNewChatFromConversationTooLong = useCallback(() => {
    trackEvent(TrackedEvent.ChatCreateFromConversationTooLong);
    onNewChat?.();
  }, [onNewChat]);

  const handleResumePastChat = useCallback(() => {
    if (recentConversationId && onSelectChat) {
      trackEvent(TrackedEvent.ChatResume, {
        elapsed_minutes: recentConversationTimestamp
          ? Math.floor((Date.now() - recentConversationTimestamp) / (1000 * 60))
          : undefined,
      });

      trackEvent(TrackedEvent.ChatSelect, {
        from_conversation_id: conversationId,
        to_conversation_id: recentConversationId,
      });
      onSelectChat(recentConversationId);
    }
  }, [conversationId, recentConversationId, recentConversationTimestamp, onSelectChat]);

  const hasSingleNoteInContext = provisionalContextState.some(context => {
    return context.kind === ChatMessageContextKind.NoteMention;
  });

  const hasSingleCollectionInContext = provisionalContextState.some(context => {
    return context.kind === ChatMessageContextKind.CollectionMention;
  });

  return (
    <StyledPanel>
      <ChatHeader
        inSidePanel={inSidePanel}
        isGuidedChat_experiment={isGuidedChat_experiment}
        onNewChat={onNewChat}
        onSelectChat={onSelectChat}
      />
      {isGuidedChat_experiment && store.spaceAccountAppCallouts.pastChatsBanner && (
        <AppCalloutBanner />
      )}
      {showResumeButton && (
        <FloatingButtonContainer>
          <MdsButton
            iconKind={MdsIconKind.ArrowUp}
            label="Resume last chat"
            variant={MdsButtonVariant.Outlined}
            size={MdsButtonSize.Small}
            onClick={handleResumePastChat}
            className={css({
              backgroundColor: mdsColors().white,
            })}
          />
        </FloatingButtonContainer>
      )}
      {showEmptyState && isGuidedChat_experiment ? (
        <StyledEmptyState isShowingResume={showResumeButton}>
          <h3>
            {hasSingleCollectionInContext
              ? "Ask about this collection"
              : hasSingleNoteInContext
                ? "Ask about this note"
                : "Ask about your notes"}
          </h3>
          <SuggestedPrompt>
            {hasSingleNoteInContext && (
              <SuggestedPromptButton
                variant={MdsButtonVariant.Outlined}
                size={MdsButtonSize.Medium}
                label="Summarize this note"
                onClick={() => {
                  trackEvent(TrackedEvent.ChatMessageSendFromSuggestion, {
                    message: "Summarize this note",
                  });

                  handleSubmitChatMessage({
                    markdownContent: "Summarize this note",
                    isGuidedChat_experiment: true,
                    agentMode: true,
                  });
                }}
              />
            )}
            {hasSingleCollectionInContext && (
              <SuggestedPromptButton
                variant={MdsButtonVariant.Outlined}
                size={MdsButtonSize.Medium}
                label="Summarize this collection"
                onClick={() => {
                  trackEvent(TrackedEvent.ChatMessageSendFromSuggestion, {
                    message: "Summarize this collection",
                  });

                  handleSubmitChatMessage({
                    markdownContent: "Summarize this collection",
                    isGuidedChat_experiment: true,
                    agentMode: true,
                  });
                }}
              />
            )}
            <SuggestedPromptButton
              variant={MdsButtonVariant.Outlined}
              size={MdsButtonSize.Medium}
              label="What should I follow up on from this week?"
              onClick={() => {
                trackEvent(TrackedEvent.ChatMessageSendFromSuggestion, {
                  message: "What should I follow up on from this week?",
                });

                handleSubmitChatMessage({
                  markdownContent: "What should I follow up on from this week?",
                  isGuidedChat_experiment: true,
                  agentMode: true,
                });
              }}
            />
            <SuggestedPromptButton
              variant={MdsButtonVariant.Outlined}
              size={MdsButtonSize.Medium}
              label="Give me an overview of my last 14 days"
              onClick={() => {
                trackEvent(TrackedEvent.ChatMessageSendFromSuggestion, {
                  message: "Give me an overview of my last 14 days",
                });

                handleSubmitChatMessage({
                  markdownContent: "Give me an overview of my last 14 days",
                  isGuidedChat_experiment: true,
                  agentMode: true,
                });
              }}
            />
          </SuggestedPrompt>
        </StyledEmptyState>
      ) : (
        chatHistory && (
          <>
            <ChatMessagesList
              style={{
                overflowX: "hidden",
                transition: "height 0.1s ease-in-out",
                height: `calc(100dvh - ${chatInputHeight}px - ${TOP_BAR_HEIGHT}px - 100px)`,
                marginLeft: mdsSpacings().md,
                marginRight: mdsSpacings().md,
                paddingTop: mdsSpacings().md,
                paddingBottom: mdsSpacings().md,
                width: `calc(100% - 2 * ${mdsSpacings().md})`,
              }}
              chatHistory={chatHistory}
              inSidePanel={inSidePanel}
              isGuidedChat_experiment={isGuidedChat_experiment}
            />
            {isConversationTooLong && (
              <TooLongMessage>
                <TooLongMessageInner>
                  <Icon>
                    <MdsIcon kind={MdsIconKind.InfoCircle} />
                  </Icon>
                  <TooLongMessageContent>
                    <div>This chat is getting too long</div>
                    <MdsLink onPress={handleNewChatFromConversationTooLong}>
                      Start a new chat
                    </MdsLink>
                  </TooLongMessageContent>
                </TooLongMessageInner>
              </TooLongMessage>
            )}
            {isConversationArchived && (
              <TooLongMessage>
                <TooLongMessageInner>
                  <Icon>
                    <MdsIcon kind={MdsIconKind.InfoCircle} />
                  </Icon>
                  <TooLongMessageContent>
                    <div>This chat has been archived</div>
                    <MdsLink onPress={onNewChat}>Start a new chat</MdsLink>
                  </TooLongMessageContent>
                </TooLongMessageInner>
              </TooLongMessage>
            )}
          </>
        )
      )}

      {chatHistory && (
        <StyledChatInput
          conversationId={conversationId}
          hasSomePermanentContext={chatHistory?.hasSomeContexts ?? false}
          getAvailableChips={chatHistory.getAvailableChips}
          onHeight={setChatInputHeight}
          onSubmit={handleSubmitChatMessage}
          inSidePanel={inSidePanel}
          isGuidedChat_experiment={isGuidedChat_experiment}
          getDraftMessage={getDraftMessage}
          onDraftChange={handleDraftChange}
          addMentionToContexts={handleAddMentionToContexts}
          removeMentionFromContexts={removeProvisionalContext}
          contexts={provisionalContextState}
          isDisabled={isInputDisabled}
        />
      )}
    </StyledPanel>
  );
});

const StyledChatInput = styled(ChatInput)(({ theme }) => ({
  marginLeft: theme.spacing.md,
  marginRight: theme.spacing.md,
  width: `calc(100% - 2 * ${theme.spacing.md})`,
}));

export function isEmptyMessage(message?: string) {
  if (!message) return true;
  const textContent = new DOMParser().parseFromString(message, "text/html").body.textContent;
  if (!textContent) return true;
  return textContent.trim().length === 0;
}

const StyledEmptyState = styled.div<{ isShowingResume: boolean }>(({ theme, isShowingResume }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: theme.spacing.xl,
  textAlign: "center",
  color: theme.colors.grey.x700,
  overflow: "auto",
  [`@media (max-height: 450px)`]: {
    marginTop: isShowingResume ? "55px" : 0,
    justifyContent: isShowingResume ? "end" : "center",
  },

  "& h1": {
    marginBottom: theme.spacing.md,
    fontSize: "1.75rem",
    fontWeight: 600,
  },

  "& p": {
    marginBottom: theme.spacing.xl,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
}));

const SuggestedPrompt = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.md,
  maxWidth: "500px",
  width: "100%",
  // marginTop: theme.spacing.md,
  // padding: `0 ${theme.spacing.md}`,
}));

const SuggestedPromptButton = styled(MdsButton)(({ theme }) => ({
  whiteSpace: "normal",
  height: "auto",
  minHeight: "48px",
  padding: `${theme.spacing.sm} ${theme.spacing.md}`,
  textAlign: "center",
  lineHeight: 1.4,
  wordBreak: "break-word",
  fontWeight: 500,
}));

const Icon = styled.div(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  color: theme.colors.primary.x500,
  fontSize: "1.25rem",
  paddingTop: "2px",
}));

const TooLongMessage = styled.div(({ theme }) => ({
  padding: theme.spacing.md,
  marginLeft: theme.spacing.md,
  marginRight: theme.spacing.md,
  marginBottom: theme.spacing.md,
  backgroundColor: "rgb(246, 247, 255)",
  borderRadius: "12px",
}));

const TooLongMessageInner = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing.sm,
}));

const TooLongMessageContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xs,
  color: theme.colors.grey.x600,
  fontSize: "0.875rem",

  "& a": {
    color: mdsColors().mblue.x500,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
