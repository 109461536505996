import { css, cx } from "@/domains/emotion";
import { useCallback, useState, FC, ReactNode, ReactEventHandler } from "react";

import { EmotionClassStyles } from "@/domains/emotion/types";
import {
  MdsButtonProps,
  MdsButton,
  MdsButtonVariant,
  MdsButtonSize,
} from "@/design-system/components/button";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import {
  MdsDropdownContentList,
  MdsDropdownProps,
  MdsDropdown,
} from "@/design-system/components/dropdown";
import { mdsColors } from "@/design-system/foundations/colors";
import { MdsIconKind } from "@/design-system/components/icon";
import classNames from "classnames";
import { MdsTooltipConfig } from "@/design-system/components/tooltip";

const minDimensionsStyles = css({
  "&:hover": {
    backgroundColor: mdsColors().grey.x100,
    "> div > div": {
      backgroundColor: mdsColors().grey.x100,
    },
  },
  transition: "background-color 0.25s ease-in-out",
  "& > div > div": {
    transition: "background-color 0.25s ease",
  },
  minHeight: 32,
  minWidth: 32,
});

const compactStyles = css({
  "&:hover": {
    backgroundColor: mdsColors().grey.x100,
    "> div > div": {
      backgroundColor: mdsColors().grey.x100,
    },
  },
  transition: "background-color 0.25s ease-in-out",
  "& > div > div": {
    transition: "background-color 0.25s ease",
  },
  minHeight: 32,
  minWidth: 32,
  "> div": {
    gap: 4,
  },
});

export interface MdsDropdownButtonProps extends EmotionClassStyles {
  innerStyles?: MdsDropdownProps["innerStyles"];
  buttonClassName?: string;
  contentList: MdsDropdownContentList;
  iconKind?: MdsIconKind | ((isOpen: boolean) => MdsIconKind | ReactNode) | null;
  iconPosition?: MdsButtonIconPosition;
  dropdownIcon?: MdsButtonProps["dropdown"];
  label?: MdsButtonProps["label"];
  isDisabled?: MdsButtonProps["isDisabled"];
  isLoading?: MdsButtonProps["isLoading"];
  variant?: MdsButtonProps["variant"] | ((isOpen: boolean) => MdsButtonProps["variant"]);
  size?: MdsButtonProps["size"];
  shape?: MdsButtonProps["shape"];
  placement?: MdsDropdownProps["placement"];
  tooltipConfig?: MdsTooltipConfig;
  onOpen?: (isOpen: boolean) => void;
  offset?: MdsDropdownProps["offset"];
}

export const MdsDropdownButton: FC<MdsDropdownButtonProps> = ({
  className,
  innerStyles,
  buttonClassName,
  contentList,
  iconKind: inputIconKind,
  iconPosition,
  dropdownIcon,
  label,
  isDisabled,
  isLoading,
  shape,
  variant,
  size,
  placement,
  tooltipConfig,
  onOpen,
  offset,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpenState = useCallback(() => {
    onOpen?.(!isOpen);
    setIsOpen(prevState => !prevState);
  }, [isOpen, onOpen]);

  const handleOpenChange = (isOpen: boolean) => {
    onOpen?.(isOpen);
    setIsOpen(isOpen);
  };

  const handleClick: ReactEventHandler = useCallback(
    event => {
      event.stopPropagation();
      toggleIsOpenState();
    },
    [toggleIsOpenState]
  );

  const iconKind = (() => {
    if (inputIconKind === null) return;

    if (typeof inputIconKind === "function") {
      const ret = inputIconKind(isOpen);

      if (Object.values(MdsIconKind).includes(ret as MdsIconKind)) {
        const MdsIconKind = ret as MdsIconKind;
        return MdsIconKind;
      }

      if (ret) {
        const reactNode = ret as ReactNode;
        return () => reactNode;
      }
    }

    if (inputIconKind) {
      return inputIconKind as MdsIconKind;
    }

    return MdsIconKind.More;
  })();

  return (
    <MdsDropdown
      className={className}
      innerStyles={innerStyles}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      contentList={contentList}
      placement={placement}
      offset={offset}
    >
      <MdsButton
        className={cx(
          classNames(
            variant
              ? undefined
              : !label
                ? minDimensionsStyles
                : typeof label === "function"
                  ? compactStyles
                  : minDimensionsStyles,
            "mdn-dropdown-button"
          ),
          buttonClassName
        )}
        dropdown={dropdownIcon}
        variant={
          (typeof variant === "function" ? variant(isOpen) : variant) ??
          MdsButtonVariant.TextTertiary
        }
        size={size ?? MdsButtonSize.Medium}
        iconKind={iconKind}
        iconPosition={iconPosition}
        shape={shape}
        label={label ?? null}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onClick={handleClick}
        tooltipConfig={tooltipConfig}
      />
    </MdsDropdown>
  );
};
