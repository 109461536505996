import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import styled from "@emotion/styled";

interface IProps {
  onSelectClear: () => void;
}

export function SelectedButton({ onSelectClear }: IProps) {
  // Its div with role not a button because its nested inside other button
  return (
    <StyledDiv
      role="button"
      onClick={e => {
        e.stopPropagation();
        onSelectClear();
      }}
    >
      <MdsIcon kind={MdsIconKind.Exit} innerStyles={{ Icon: { className: "toggle-btn-icon" } }} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div({
  borderRadius: "100%",
  height: 16,
  width: 16,
  padding: 0,
  "& .toggle-btn-icon": {
    color: mdsColors().mblue.x500,
  },
  "&:hover": {
    backgroundColor: mdsColors().mblue.x200,
  },
});
