import { FC } from "react";
import { observer } from "mobx-react-lite";
import { MdsLink } from "@/mds";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { OptimisticDataImportItem } from "@/pages/settings/components/migration/optimistic-data-import-item";
import { ImportItem } from "@/pages/settings/components/migration/import-item";
import { windowModule } from "@/modules/window";

export const SettingsAccountMigrationV2Section: FC = observer(() => {
  const { store } = useAppStore();

  const importRecentlyStarted = store.accountTransition.importProcessStartedRecently;
  const anyImportIsRunning = store.dataImports.getLatestImports(3).some(imp => imp.isRunning);
  const shouldShowExtraLoaderIcon = importRecentlyStarted && !anyImportIsRunning;

  return (
    <>
      <Text>
        Your Mem account is now on version 2.0! You&apos;ll start seeing your content as it
        completes the migration process, which may take a few minutes; progress is shown below.
        Please note that shared content will only become available after all collaborators have been
        upgraded to 2.0, so it may take longer to appear.
      </Text>
      <br />
      Additionally, please note the following changes in Mem 2.0:
      <List>
        <li>
          <b>Notes, collections</b>, and <b>templates</b> will migrate as is.
        </li>
        <li>
          <b>Groups</b> will be converted to <b>shared collections</b>.
        </li>
        <li>
          <b>Favorited notes</b> will be converted to <b>pinned notes</b>.
        </li>
        <li>
          <b>Comments</b> will be converted to notes.
        </li>
      </List>
      <p>
        <MdsLink
          onPress={() => {
            windowModule.openInNewTab({
              url: new URL("https://get.mem.ai/blog/mem-2-dot-0-transition-guide"),
            });
          }}
        >
          Learn more in our transition guide.
        </MdsLink>
      </p>
      <ImportsContainer>
        {shouldShowExtraLoaderIcon && <OptimisticDataImportItem />}
        {store.dataImports.getLatestImports(3).map(imp => (
          <ImportItem dataImport={imp} key={imp.id} />
        ))}
      </ImportsContainer>
    </>
  );
});

const ImportsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: 8,
});

const Text = styled.p({
  marginTop: -10,
  marginBottom: 0,
});

const List = styled.ul({
  marginLeft: 0,
  paddingLeft: 25,
  marginBottom: 5,
});
