import { ContactsFacetFilterItem } from "@/components/filters/types";
import { AppStore } from "@/store";

export function getContactsFacetFilterItems(
  store: AppStore,
  searchQuery: string,
  selectedContacts: string[]
): ContactsFacetFilterItem[] {
  const mySpaceAccount = store.spaceAccounts.myPersonalSpaceAccount;
  const allContacts = store.contacts.displayContacts;

  const output: ContactsFacetFilterItem[] = [];

  if (mySpaceAccount) {
    output.push({
      id: mySpaceAccount.id,
      displayName: `${mySpaceAccount.profileDisplayName} (Me)`,
      emailAddress: mySpaceAccount.profileEmailAddress,
      photoUrl: mySpaceAccount.profilePhotoUrl,
      isSelected: selectedContacts.includes(mySpaceAccount.id),
      isVisible: true,
    });
  }

  allContacts.forEach(contact => {
    output.push({
      id: contact.contactSpaceAccountId,
      displayName: contact.profileDisplayName,
      emailAddress: contact.profileEmailAddress,
      photoUrl: contact.profilePhotoUrl,
      isSelected: selectedContacts?.includes(contact.contactSpaceAccountId) ?? false,
      isVisible: true,
    });
  });

  if (searchQuery.length > 0) {
    return output.map(contact => {
      const query = searchQuery.toLocaleLowerCase();
      const displayName = contact.displayName.toLocaleLowerCase();
      const emailAddress = contact.emailAddress?.toLocaleLowerCase() ?? "";

      return {
        ...contact,
        isVisible: displayName.includes(query) || emailAddress.includes(query),
      };
    });
  }

  return output;
}
