import { MentionContent } from "@/store/chat/mention/MentionContent";
import { MentionSubtitle } from "@/store/chat/mention/MentionSubtitle";
import { MentionTitle } from "@/store/chat/mention/MentionTitle";
import { observer } from "mobx-react-lite";

export const DateMentionContent = observer<{
  label: string;
  subtitle: string;
}>(({ label, subtitle }) => {
  return (
    <MentionContent>
      <MentionTitle>
        <span>{label}</span>
      </MentionTitle>
      <MentionSubtitle>{subtitle}</MentionSubtitle>
    </MentionContent>
  );
});
