import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { ChatConversationModelData } from "@/store/chat/types";

export type ChatConversationIndexData = {
  locally_created_at: string;
};

export class ChatConversationIndexes {
  private data: SyncUpdateValue<ChatConversationModelData>;

  constructor({
    remoteData,
    optimisticUpdates,
  }: {
    remoteData: Maybe<SyncUpdateValue<ChatConversationModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ChatConversationModelData>[];
  }) {
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<ChatConversationModelData>) || remoteData;
  }

  get locally_created_at() {
    return this.data.model_data.locally_created_at;
  }

  get indexes(): ChatConversationIndexData {
    return {
      locally_created_at: this.locally_created_at,
    };
  }
}
