import styled from "@emotion/styled";

type AppearProps = React.PropsWithChildren<{
  visible: boolean;
}>;

export const Appear = styled.div<AppearProps>(({ visible }) => ({
  opacity: visible ? 1 : 0,
  transition: "opacity 0.2s ease-in-out",
}));
