import { observer } from "mobx-react-lite";
import {
  MdsItemListSkeletonRowData,
  MdsItemListAsyncTemplateRowData,
  MdsItemListRowType,
} from "@/design-system/components/item-list/types";
import { FC } from "react";
import { useAppStore } from "@/store";
import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { MdsItemListItemRow } from "@/design-system/components/item-list/rows/MdsItemListItemRow";

export interface MdsItemListAsyncTemplateRowProps {
  data: MdsItemListAsyncTemplateRowData;
  className?: string;
}

export const MdsItemListAsyncTemplateRow: FC<MdsItemListAsyncTemplateRowProps> = observer(
  ({ data, className }) => {
    const { store } = useAppStore();
    const template = store.templates.get(data.payload.templateId);

    const skeletonRowData: MdsItemListSkeletonRowData = {
      key: data.key,
      type: MdsItemListRowType.Skeleton,
      size: data.size,
      payload: {},
    };
    if (!template) return <MdsItemListSkeletonRow data={skeletonRowData} className={className} />;

    const itemRowData =
      typeof data.payload.itemRow === "function"
        ? data.payload.itemRow(template)
        : data.payload.itemRow;

    return <MdsItemListItemRow data={itemRowData} className={className} />;
  }
);
