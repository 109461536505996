import { MdsIconKind } from "@/design-system/components/icon";

export enum MDSBannerKind {
  Default = "default",
  Info = "info",
  Error = "error",
}

export enum MDSBannerSlotName {
  Left = "left",
  Right = "right",
}

export interface MdsBannerProps extends React.PropsWithChildren {
  /**
   * The message of the banner.
   */
  message: string;

  /**
   * The kind of banner.
   */
  kind?: MDSBannerKind;

  /**
   * Optional icon to display on the left side of the banner.
   */
  iconKind?: MdsIconKind;

  /**
   * Optional callback to handle the close button.
   * If not set, the close button will not be displayed.
   */
  onDismiss?: () => void;
}
