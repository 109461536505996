import { SpaceAccountTemplateModelData } from "@/store/templates/types";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountTemplateObservable } from "@/store/templates/SpaceAccountTemplateObservable";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { Uuid } from "@/domains/global/identifiers";

export class AppStoreSpaceAccountTemplateStore extends BaseSyncModelStore<
  SpaceAccountTemplateObservable,
  SpaceAccountTemplateModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountTemplate, ...injectedDeps });
  }

  createSyncModel(
    updateValue: SyncUpdateValue<SpaceAccountTemplateModelData>
  ): SpaceAccountTemplateObservable {
    return new SpaceAccountTemplateObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  public async recompute(modelId: Uuid) {
    await super.recompute(modelId);
    const data = await this.localTable.get(modelId);
    const templateId = data?.model_data.template_id;
    if (templateId) await this.store.templates.recompute(templateId);
  }
}
