import { useCallback, useState, FC } from "react";

import { EmotionClassStyles } from "@/domains/emotion/types";
import {
  MdsDropdownContentList,
  MdsDropdownProps,
  MdsDropdown,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsTooltipConfig } from "@/design-system/components/tooltip";
import {
  MdsIconButton,
  MdsIconButtonProps,
  MdsIconButtonVariant,
} from "@/design-system/components/icon-button";
import classNames from "classnames";

export interface MdsDropdownIconButtonProps extends EmotionClassStyles {
  buttonClassName?: string;
  contentList: MdsDropdownContentList;
  iconKind?: MdsIconButtonProps["iconKind"];
  isDisabled?: MdsIconButtonProps["isDisabled"];
  isLoading?: MdsIconButtonProps["isLoading"];
  variant?: MdsIconButtonProps["variant"] | ((isOpen: boolean) => MdsIconButtonProps["variant"]);
  size?: MdsIconButtonProps["size"];
  shape?: MdsIconButtonProps["shape"];
  placement?: MdsDropdownProps["placement"];
  tooltipConfig?: MdsTooltipConfig;
  innerStyles?: MdsDropdownProps["innerStyles"];
}

export const MdsDropdownIconButton: FC<MdsDropdownIconButtonProps> = ({
  className,
  buttonClassName,
  contentList,
  iconKind = MdsIconKind.More,
  isDisabled,
  isLoading,
  shape,
  variant,
  size,
  placement,
  tooltipConfig,
  innerStyles,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpenState = () => setIsOpen(prevState => !prevState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = useCallback((event: any) => {
    event.stopPropagation();
    toggleIsOpenState();
  }, []);

  return (
    <MdsDropdown
      className={className}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      contentList={contentList}
      placement={placement}
      innerStyles={innerStyles}
    >
      <MdsIconButton
        className={classNames(buttonClassName, "mds-dropdown-icon-button")}
        iconKind={iconKind}
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={
          (typeof variant === "function" ? variant(isOpen) : variant) ??
          MdsIconButtonVariant.Transparent
        }
        size={size}
        shape={shape}
        onClick={handleClick}
        tooltipConfig={tooltipConfig}
      />
    </MdsDropdown>
  );
};
