import { observer } from "mobx-react-lite";

import { useAppStore } from "@/store";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { CollectionsListPageListEmpty } from "@/pages/collections/components/CollectionsListPageListEmpty";

export const CollectionsListPageList = observer(() => {
  const { pageStore } = useAppStore();

  const page = pageStore.collectionsListPage;

  page.listState.useListStateEffects();

  if (page.shouldShowEmptyState) {
    return <CollectionsListPageListEmpty />;
  }

  return (
    <>
      <MdsVirtualizedItemList
        rows={page.itemRows}
        listState={page.listState}
        loading={page.isLoading}
      />
      {page.listState.multiSelectUi}
    </>
  );
});
