import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";

import { useEffectOnMount } from "@/domains/react/useEffectOnMount";

export interface RedirectToActiveOrNewChat {
  inSidePanel?: boolean;
}

export const RedirectToActiveOrNewChat = observer<RedirectToActiveOrNewChat>(
  function RedirectToActiveOrNewChat({ inSidePanel = false }) {
    const { store } = useAppStore();

    useEffectOnMount(() => {
      const autoRouteToConversation = async () => {
        const conversationId =
          await store.chatConversations.resumeActiveOrCreateEmptyGuidedChatConversation();

        if (inSidePanel) {
          store.sidePanel.goToChat(conversationId);
        } else {
          store.navigation.goToChatConversation({ conversationId });
        }
      };

      autoRouteToConversation();
    });

    return null;
  }
);
