import { LocalStorageKey } from "@/modules/local-storage/constants";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";

export const writeUpgradedUserEmail = (value: string): void => {
  try {
    localStorage.setItem(LocalStorageKey.UpgradedUserEmail, value);
  } catch (unknownErr) {
    const err = unknownErr as Error;

    logger.warn({
      message: "[writeUpgradedUserEmail] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });
  }
};

export const clearUpgradedUserEmail = (): void => {
  try {
    localStorage.removeItem(LocalStorageKey.UpgradedUserEmail);
  } catch (unknownErr) {
    const err = unknownErr as Error;

    logger.warn({
      message: "[clearUpgradedUserEmail] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });
  }
};

export const readUpgradedUserEmail = (): string | null => {
  try {
    return localStorage.getItem(LocalStorageKey.UpgradedUserEmail);
  } catch (unknownErr) {
    const err = unknownErr as Error;

    logger.warn({
      message: "[readUpgradedUserEmail] Failed.",
      info: {
        err: objectModule.safeErrorAsJson(err),
      },
    });

    return null;
  }
};
