import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import {
  IndexedNoteSyncUpdateValue,
  NoteUpsertedSyncUpdateValue,
  INoteObservable,
  NoteObservable,
  NotesSearchParams,
  NotesIndexTuple,
  NotesSearchMentionedInParams,
  SpaceAccountNoteSuggestion,
} from "@/store/note";
import { uuidModule } from "@/modules/uuid";
import { NoteModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateNoteOperation } from "@/store/sync/operations/notes/CreateNoteOperation";
import { DeleteNoteOperation } from "@/store/sync/operations/notes/DeleteNoteOperation";
import { SerializedOptimisticUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
  override,
  runInAction,
} from "mobx";
import { Table } from "dexie";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { NoteIndexes } from "@/store/note/NoteIndexes";
import { resolveSpaceAccountNoteSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountNoteSyncModelUuid";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { EventContext } from "@/domains/metrics/context";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { NoteQueueObservable } from "@/store/note/NoteQueueObservable";
import { SortByKind } from "@/modules/lenses/types";
import { searchForNotes } from "@/store/note/NoteSearch";
import { searchForNotesMentionedIn } from "@/store/note/searchForNotesMentionedIn";
import { generateSpaceAccountNoteLiveSyncUpdatePusherChannelKey } from "@/store/sync/utils";
import { Channel } from "pusher-js";
import { PusherEventData, PusherEventKind } from "@/domains/pusher/constants";
import { debounce } from "lodash-es";

export interface NotesStore {
  allNotes: INoteObservable[];
  createNote: (payload: { noteId: Uuid; eventContext: EventContext }) => void;
  getNoteObservableById: (payload: { noteId: Uuid }) => Maybe<INoteObservable>;
  search: (payload: NotesSearchParams) => Promise<NotesIndexTuple[]>;
}

export class AppStoreNoteStore
  extends BaseSyncModelStore<NoteObservable, NoteModelData>
  implements NotesStore
{
  public static searchTitleLimit = 100;

  private queues = new ObservableMap<Uuid, NoteQueueObservable>();

  spaceAccountNoteSuggestionsPusherChannels = new ObservableMap<Uuid, Channel>(); // noteId -> Pusher Channel
  spaceAccountNoteSuggestions = new ObservableMap<Uuid, SpaceAccountNoteSuggestion[]>();

  public newNoteIsBeingCreated = false;

  public static mentionedNoteIdsIndex = "mentioned_note_ids";
  public static sortByIndexes = {
    [SortByKind.LastViewed]: "last_viewed_at",
    [SortByKind.LastCreated]: "locally_created_at",
    [SortByKind.LastModified]: "modified_at",
    [SortByKind.LastShared]: "shared_with_me_at",
    [SortByKind.Alphabetical]: "lowercase_primary_label",
  };

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Note, ...injectedDeps });
    makeObservable<this, "queues" | "updateSearchSuggestions" | "generateSearchSuggestions">(this, {
      searchForNotesMentionedIn: true,
      // App/react lifecycle
      resetState: false,

      // Queues
      queues: false,
      beforeUnload: false,
      getNoteQueue: false,

      remoteTable: override,
      localTable: override,
      recompute: override,
      bulkRecompute: override,

      createSyncModel: false,
      getNoteObservableById: false,

      allNotes: computed,

      composeNewNote: action,
      createNote: action,
      deleteNote: action,

      updateSearchSuggestions: false,
      generateSearchSuggestions: false,

      search: false,
      newNoteIsBeingCreated: observable,
      setNewNoteIsBeingCreated: action,

      // SpaceAccountNoteSuggestion Subscriptions
      spaceAccountNoteSuggestionsPusherChannels: observable,
      spaceAccountNoteSuggestions: observable,
      subscribeToLiveNoteSuggestions: action,
      unsubscribeFromLiveNoteSuggestions: action,
      handleSpaceAccountNoteSuggestion: action,
      removeSuggestion: action,
    });

    window.addEventListener("beforeunload", this.beforeUnload);
  }

  beforeUnload = (e: Event) => {
    for (const [, queue] of this.queues) {
      if (queue.isEmpty) continue;

      e.preventDefault();
      e.stopPropagation();

      return "Changes are being saved. Are you sure you want to leave?";
    }
  };

  createSyncModel(updateValue: IndexedNoteSyncUpdateValue): NoteObservable {
    return new NoteObservable({ id: updateValue.model_id, data: updateValue, store: this.store });
  }

  getNoteObservableById = ({ noteId }: { noteId?: Uuid }): Maybe<INoteObservable> => {
    return noteId ? this.get(noteId) : undefined;
  };

  getNoteQueue = ({ noteId }: { noteId: Uuid }): NoteQueueObservable => {
    let queue = this.queues.get(noteId);
    if (!queue) {
      queue = new NoteQueueObservable(this.store, noteId);
      this.queues.set(noteId, queue);
    }
    // Idea:
    //     We could use the queue on recompute + call recompute when reacting to a new operation
    //     on the queue.
    // Concerns:
    // 1. Order of operations read from disk vs those still only kept in memory.
    //    One possibility is to process disk operations and then memory ops, giving them
    //    higher priority.
    // 2. This only covers operations saved to the note queue (mostly from the editor).
    //    Since they are never queued in memory but written to disk and then they are loaded to
    //    the queue maybe it's not such a big issue.
    // 3. Collections and other models are not covered.
    //    To fix those we would need queues like NoteQueueObservable for each model id or to
    //    bring back an unified memory queue (much more complex).
    // Overall:
    //    Doing it for notes seems relevant, for other entities perhaps the complexity is not worth it.
    return queue;
  };

  get allNotes(): INoteObservable[] {
    // DEXIE REFACTOR TODO: REFACTOR FOR SEARCH
    return [];
  }

  // ACTIONS
  public composeNewNote = async ({ eventContext }: { eventContext: EventContext }) => {
    try {
      const noteId = uuidModule.generate();

      this.setNewNoteIsBeingCreated(true);

      await this.createNote({ noteId, eventContext });

      this.store.navigation.goToNote({ noteId, autoFocus: true });

      // escape-hatch in case user run to non-editor page
      setTimeout(() => this.setNewNoteIsBeingCreated(false), 2000);
    } catch (e) {
      this.setNewNoteIsBeingCreated(false);
      logger.error({
        message: "[SYNC][AppStoreNoteStore] Error creating new note",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  };

  public async createNote({ noteId, eventContext }: { noteId: Uuid; eventContext: EventContext }) {
    await new CreateNoteOperation({ store: this.store, payload: { id: noteId } }).execute();
    trackEvent(TrackedEvent.NoteCreate, { note_id: noteId, context: eventContext });
  }

  public async deleteNote({ noteId }: { noteId: Uuid }) {
    const queue = this.store.notes.getNoteQueue({ noteId });
    queue.push(new DeleteNoteOperation({ store: this.store, payload: { id: noteId } }));
  }

  public get remoteTable() {
    return this.db.mappedTables[this.modelKind].remote as Table<SyncUpdateValue<NoteModelData>>;
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<IndexedNoteSyncUpdateValue>;
  }

  public async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");

    const lastOptimisticUpdate = optimisticUpdates.at(-1);
    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      logger.debug({
        message: "[APP STORE NOTE STORE] deleting note / revoking access",
        info: { modelId },
      });

      await this.localTable.delete(modelId);
      this.pool.delete(modelId);
      await this.store.search.remove(modelId);

      return;
    }

    // Fetch other required data
    const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
      noteId: modelId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });

    const spaceAccountNote = (await this.db.mappedTables[SyncModelKind.SpaceAccountNote].local.get(
      spaceAccountNoteId
    )) as SyncUpdateValue<SpaceAccountNoteModelData> | undefined;

    // Optimistic updates
    const data = lastOptimisticUpdate?.value || remoteData; // At least one of these should be defined
    if (!data) {
      await this.localTable.delete(modelId);
      await this.store.search.remove(modelId);
      return;
    }

    const indexes = new NoteIndexes({
      store: this.store,
      remoteData,
      optimisticUpdates,
      spaceAccountNote,
    }).indexes;

    const dataWithIndexes: IndexedNoteSyncUpdateValue = {
      ...(data as NoteUpsertedSyncUpdateValue),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);

    // NOTE: we update fullText search content not here but in the NoteContentDocumentStore to
    // a. detach its potentially heavy computation from note content update
    // b. get a direct access to the most up-to-date note content (we don't optimistically update note content)
    await this.updateSearchSuggestions(dataWithIndexes);
  }

  public override async bulkRecompute(modelIds: Uuid[]) {
    const remoteData = (await this.remoteTable.bulkGet(modelIds)) as (
      | SyncUpdateValue<NoteModelData>
      | undefined
    )[];

    const allOptimisticUpdates = await this.db.queue.optimisticUpdates
      .where("model_id")
      .anyOf(modelIds)
      .sortBy("locally_committed_at");
    const optimisticUpdatesById = new Map<Uuid, SerializedOptimisticUpdate<NoteModelData>[]>();
    for (const update of allOptimisticUpdates) {
      optimisticUpdatesById.set(update.model_id, [
        ...(optimisticUpdatesById.get(update.model_id) || []),
        update,
      ]);
    }

    const noteIdsToSpaceAccountNoteIds = new Map(
      modelIds.map(modelId => [
        modelId,
        resolveSpaceAccountNoteSyncModelUuid({
          noteId: modelId,
          spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
        }),
      ])
    );
    const spaceAccountNoteIds = Array.from(noteIdsToSpaceAccountNoteIds.values());
    const spaceAccountNotes = (await this.db.mappedTables[
      SyncModelKind.SpaceAccountNote
    ].local.bulkGet(spaceAccountNoteIds)) as (
      | SyncUpdateValue<SpaceAccountNoteModelData>
      | undefined
    )[];
    const noteIdsToSpaceAccountNotes: Map<
      Uuid,
      SyncUpdateValue<SpaceAccountNoteModelData> | undefined
    > = new Map(
      spaceAccountNoteIds.map((_spaceAccountNoteId, i) => [modelIds[i], spaceAccountNotes[i]])
    );

    const toDelete: Uuid[] = [];
    const toPut: IndexedNoteSyncUpdateValue[] = [];
    const searchSuggestions: SearchSuggestion[] = [];

    for (const [i, modelId] of modelIds.entries()) {
      const spaceAccountNote = noteIdsToSpaceAccountNotes.get(modelId);
      const optimisticUpdates = optimisticUpdatesById.get(modelId) || [];
      const lastOptimisticUpdate = optimisticUpdates.at(-1);

      if (
        lastOptimisticUpdate?.kind === "DELETED" ||
        lastOptimisticUpdate?.kind === "ACL_REVOKED"
      ) {
        toDelete.push(modelId);
        continue;
      }

      const data = (lastOptimisticUpdate?.value || remoteData[i]) as
        | SyncUpdateValue<NoteModelData>
        | undefined;

      if (!data) {
        // Case: remoteData is deleted and there are no optimistic updates\
        toDelete.push(modelId);
        continue;
      }

      const indexes = new NoteIndexes({
        store: this.store,
        remoteData: data,
        optimisticUpdates,
        spaceAccountNote,
      }).indexes;

      const dataWithIndexes: IndexedNoteSyncUpdateValue = {
        ...(data as NoteUpsertedSyncUpdateValue),
        ...indexes,
      };

      toPut.push(dataWithIndexes);
      searchSuggestions.push(this.generateSearchSuggestions(dataWithIndexes));
    }

    await this.db.transaction("rw", this.localTable, async () => {
      console.debug("[AppStoreNoteStore] bulkRecompute delete", { ids: toDelete });
      await this.localTable.bulkDelete(toDelete);
      console.debug("[AppStoreNoteStore] bulkRecompute put", { ids: toPut.map(p => p.model_id) });
      await this.localTable.bulkPut(toPut);
      console.debug("[AppStoreNoteStore] bulkRecompute commit");
    });

    await this.store.search.bulkUpdateSuggestions(searchSuggestions);

    runInAction(() => {
      for (const modelId of toDelete) {
        this.pool.delete(modelId);
      }
      // this is a noop update to retrigger get for observables that previously returned undefined in the case they they become defined through the recompute
      for (const data of toPut) {
        const modelId = data.model_id;
        if (!this.pool.has(modelId)) {
          this.pool.set(modelId, undefined);
          this.pool.delete(modelId);
        }
      }
    });
  }

  public search = (params: NotesSearchParams) => searchForNotes({ store: this.store, params });

  public searchForNotesMentionedIn = (params: NotesSearchMentionedInParams) =>
    searchForNotesMentionedIn({ store: this.store, params });

  public setNewNoteIsBeingCreated = (value: boolean) => {
    this.newNoteIsBeingCreated = value;
  };

  private generateSearchSuggestions(value: IndexedNoteSyncUpdateValue): SearchSuggestion {
    const generateSortKey = (
      value: IndexedNoteSyncUpdateValue
      // includeMentionedAt: boolean
    ): number => {
      const sortKey = value.last_viewed_at || value.created_at;

      // TODO: add lastMentionedAt for includeMentionedAt

      return new Date(sortKey || "").getTime();
    };

    const suggestion = {
      modelId: value.model_id,
      label: value.primary_label.slice(0, AppStoreNoteStore.searchTitleLimit),
      lowercaseLabel: value.primary_label
        .slice(0, AppStoreNoteStore.searchTitleLimit)
        .toLowerCase(),
      type: SearchSuggestionType.NOTE,
      lastViewedAt: value.last_viewed_at,
      sortKey: generateSortKey(value),
      mentionKey: generateSortKey(value),
      isAvailable: value.is_available,
    };

    return suggestion;
  }

  private async updateSearchSuggestions(value: IndexedNoteSyncUpdateValue) {
    try {
      const suggestion = this.generateSearchSuggestions(value);
      await this.store.search.updateSuggestion(suggestion);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreNoteStore] Error generating search suggestion",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  resetState() {
    this.queues.forEach(queue => queue.dispose());
    window.removeEventListener("beforeunload", this.beforeUnload);
  }

  public subscribeToLiveNoteSuggestions = (noteId: Uuid): boolean => {
    const spaceAccountNoteId = resolveSpaceAccountNoteSyncModelUuid({
      noteId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });
    const pusherChannelKey = generateSpaceAccountNoteLiveSyncUpdatePusherChannelKey({
      spaceAccountNoteId,
    });

    const liveChannel = this.pusher.subscribe(pusherChannelKey);
    if (!liveChannel.subscribed) return false;
    this.spaceAccountNoteSuggestionsPusherChannels.set(noteId, liveChannel);
    liveChannel.bind(
      PusherEventKind.SPACE_ACCOUNT_NOTE_SUGGESTION_PUBLISHED,
      this.handleSpaceAccountNoteSuggestion
    );
    return true;
  };

  public unsubscribeFromLiveNoteSuggestions = (noteId: Uuid) => {
    const liveChannel = this.spaceAccountNoteSuggestionsPusherChannels.get(noteId);
    if (!liveChannel) return;
    this.pusher.unsubscribe(liveChannel.name);
    this.spaceAccountNoteSuggestionsPusherChannels.delete(noteId);
  };

  public handleSpaceAccountNoteSuggestion = debounce(
    (data: PusherEventData<PusherEventKind.SPACE_ACCOUNT_NOTE_SUGGESTION_PUBLISHED>) => {
      const { note_id, suggestions } = data;
      // Add unique IDs to suggestions if they don't have them
      const suggestionsWithIds = suggestions.map(suggestion => ({
        ...suggestion,
        id: suggestion.id || uuidModule.generate(),
      }));
      this.spaceAccountNoteSuggestions.set(note_id, suggestionsWithIds);
    },
    1000,
    { leading: false, trailing: true }
  );

  public removeSuggestion = (noteId: Uuid, suggestionId: string) => {
    const suggestions = this.spaceAccountNoteSuggestions.get(noteId);
    if (!suggestions) return;

    const updatedSuggestions = suggestions.filter(suggestion => suggestion.id !== suggestionId);
    this.spaceAccountNoteSuggestions.set(noteId, updatedSuggestions);
  };
}
