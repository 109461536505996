import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { WithAppStore } from "@/store/types";
import { AddTemplateToCollectionOperation } from "@/store/sync/operations/templates/AddTemplateToCollectionOperation";
import { RemoveTemplateFromCollectionOperation } from "@/store/sync/operations/templates/RemoveTemplateFromCollectionOperation";
import { Dexie, liveQuery, Subscription } from "dexie";
import { EventContext } from "@/domains/metrics/context";

type NoteCollectionItemTuple = [item_id: Uuid, collection_id: Uuid, model_id: Uuid];

export class TemplateCollectionListObservable {
  private store: AppStore;
  private templateId: Uuid;

  isLoading: boolean;
  liveQuerySubscription?: Subscription;
  subscribedCollectionItems: NoteCollectionItemTuple[] = [];

  constructor({ store, templateId }: { templateId: Uuid } & WithAppStore) {
    this.store = store;
    this.templateId = templateId;
    this.isLoading = true;

    makeObservable<this, "store" | "templateId">(this, {
      hasCollectionAsync: true,
      getAllCollectionItemsAsync: true,
      getAllCollectionsAsync: true,
      getAllCollectionIdsAsync: true,
      getAllCollectionItemIdsAsync: true,
      store: false,
      templateId: observable,

      isLoading: observable,
      liveQuerySubscription: observable,
      subscribedCollectionItems: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      allCollectionIds: computed,
      allCollectionItemIds: computed,
      allCollections: computed,
      allCollectionItems: computed,
      hasCollection: false,
      addCollection: action,
      removeCollection: action,
    });

    onBecomeObserved(this, "subscribedCollectionItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "subscribedCollectionItems", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.store.collectionItems.localTable
        .where("[item_id+collection_id+model_id]")
        .between(
          [this.templateId, Dexie.minKey, Dexie.minKey],
          [this.templateId, Dexie.maxKey, Dexie.maxKey]
        )
        .keys();
    }).subscribe({
      next: rows => {
        runInAction(() => {
          this.subscribedCollectionItems = rows as unknown as NoteCollectionItemTuple[];
          this.isLoading = false;
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  get allCollectionItemIds(): Set<Uuid> {
    return new Set(
      this.subscribedCollectionItems.map(
        ([_item_id, _collection_id, collection_item_id]) => collection_item_id
      )
    );
  }

  async getAllCollectionItemIdsAsync(): Promise<Uuid[]> {
    return this.store.collectionItems.localTable.where({ item_id: this.templateId }).primaryKeys();
  }

  get allCollectionIds(): Set<Uuid> {
    return new Set(
      this.subscribedCollectionItems.map(
        ([_item_id, collection_id, _collection_item_id]) => collection_id
      )
    );
  }

  async getAllCollectionIdsAsync(): Promise<Uuid[]> {
    const collectionItems = await this.store.collectionItems.localTable
      .where({ item_id: this.templateId })
      .toArray();
    return collectionItems.map(({ collection_id }) => collection_id);
  }

  get allCollections(): CollectionObservable[] {
    return this.store.collections.getMany(Array.from(this.allCollectionIds));
  }

  async getAllCollectionsAsync(): Promise<CollectionObservable[]> {
    const allCollectionIds = await this.getAllCollectionIdsAsync();
    const collections = await Promise.all(
      allCollectionIds.map(id => this.store.collections.getAsync(id))
    );
    return collections.filter(collection => !!collection);
  }

  get allCollectionItems(): CollectionItemObservable[] {
    return this.store.collectionItems.getMany(Array.from(this.allCollectionItemIds));
  }

  async getAllCollectionItemsAsync(): Promise<CollectionItemObservable[]> {
    const allCollectionItemIds = await this.getAllCollectionItemIdsAsync();
    const collectionItems = await Promise.all(
      allCollectionItemIds.map(id => this.store.collectionItems.getAsync(id))
    );
    return collectionItems.filter(item => !!item);
  }

  public hasCollection({ collectionId }: { collectionId: string }) {
    return this.allCollectionIds.has(collectionId);
  }

  public async hasCollectionAsync({ collectionId }: { collectionId: string }) {
    const allCollectionIds = await this.getAllCollectionIdsAsync();
    return allCollectionIds.includes(collectionId);
  }

  public async addCollection({
    collectionId,
    triggerSuccessToast,
    eventContext,
    wait = true,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
    eventContext: EventContext;
    wait?: boolean;
  }) {
    if (this.hasCollection({ collectionId })) return;

    const operation = new AddTemplateToCollectionOperation({
      store: this.store,
      payload: {
        collection_id: collectionId,
        template_id: this.templateId,
      },
      triggerSuccessToast,
      eventContext,
    });
    if (wait) {
      return await operation.execute();
    }
    const queue = this.store.templates.getQueue({ id: this.templateId });
    queue.push(operation);
  }

  public async removeCollection({
    collectionId,
    triggerSuccessToast,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
  }) {
    if (!this.hasCollection({ collectionId })) return;
    const queue = this.store.templates.getQueue({ id: this.templateId });
    queue.push(
      new RemoveTemplateFromCollectionOperation({
        store: this.store,
        payload: {
          collection_id: collectionId,
          template_id: this.templateId,
        },
        triggerSuccessToast,
      })
    );
  }
}
