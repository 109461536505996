// Import statements for UI components, state management, and utilities
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { PanelMode, mdsPanelModeBreakpoints } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { useMatch } from "react-router";
import { appRoutes } from "@/app/router";
import { SidebarMobile } from "@/components/layout/components/sidebar/SidebarMobile";
import { FC, useState, useCallback } from "react";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsButtonShape } from "@/design-system/components/button/types";
import { TopBarSidebarSection } from "@/components/layout/components/TopBarSidebarSection";
import { SidebarVariant } from "@/components/layout/components/sidebar/constants";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";
import { TopBarProfileImage } from "@/components/layout/components/TopBarProfileImage";
import { EventContext } from "@/domains/metrics/context";
import { isMac } from "@/domains/platform/isMac";
import { electronModule } from "@/modules/electron";
import { clientEnvModule } from "@/modules/client-env";

// Interface defining the required props for the TopBar component
interface TopBarProps {
  currentSidebarVariant: SidebarVariant; // Current sidebar display mode
  shouldHideSidebarToggle: boolean; // Whether to hide the sidebar toggle button
  toggleMenu: () => void; // Function to toggle the menu
  toggleSidebar: () => void; // Function to toggle the sidebar
}

// Main TopBar component - Uses MobX for state management (observer HOC)
export const TopBar: FC<TopBarProps> = observer(
  ({ currentSidebarVariant, shouldHideSidebarToggle, toggleMenu, toggleSidebar }) => {
    const { store, pageStore } = useAppStore();

    // Window control handlers for desktop app (Electron)
    const handleMinimizeWindow = useCallback(() => {
      electronModule.minimizeWindow();
    }, []);

    const handleMaximizeWindow = useCallback(() => {
      electronModule.maximizeWindow();
    }, []);

    const handleCloseWindow = useCallback(() => {
      electronModule.closeWindow();
    }, []);

    // State and handlers for mobile popup bar
    const [isPopUpBarOpen, setIsPopUpBarOpen] = useState(false);
    const togglePopUpBar = () => setIsPopUpBarOpen(prev => !prev);

    // Responsive layout detection
    const isNarrowView = !!store.interface.matchesPanelModeBreakpoint.get(PanelMode.Narrow);

    // Route-based conditions
    const isChatPath = !!useMatch(appRoutes.chat({ starPattern: true }));
    const isChatToggleDisabled = isChatPath;
    const searchQuery = pageStore.searchPage.filters.params.queryString;

    // Side panel state from store
    const { isChatActive, isCopilotActive, isMainPanelOpen, isSidePanelOpen, sidePanelWidth } =
      store.sidePanel;

    // UI display conditions
    const showFullTabButtons = isMainPanelOpen && isSidePanelOpen;
    const isDesktop = clientEnvModule.isDesktop();
    const isMacOS = isMac();
    const showWindowControls = isDesktop && !isMacOS;

    return (
      <>
        {/* Mobile sidebar overlay */}
        {isNarrowView && isPopUpBarOpen && (
          <SidebarMobile onClick={() => setIsPopUpBarOpen(false)} />
        )}
        <Wrapper>
          {/* Left section: Navigation, search, and mobile-specific controls */}
          <LeftSection>
            <Paddings>
              <TopBarSidebarSection
                isNarrowView={isNarrowView}
                currentSidebarVariant={currentSidebarVariant}
                shouldHideSidebarToggle={shouldHideSidebarToggle}
                toggleMenu={toggleMenu}
                toggleSidebar={toggleSidebar}
                navigateBack={store.navigation.goBack}
                navigateForward={store.navigation.goForward}
                canNavigateBack={store.navigation.canGoBack}
                canNavigateForward={store.navigation.canGoForward}
              />
              {/* Mobile-specific controls */}
              {isNarrowView && (
                <>
                  <TopBarProfileImage togglePopUpBar={togglePopUpBar} />
                  <MdsIconButton
                    iconKind={MdsIconKind.ComposeInline}
                    isDisabled={store.notes.newNoteIsBeingCreated}
                    onClick={() =>
                      store.notes.composeNewNote({ eventContext: EventContext.GlobalActions })
                    }
                  />
                </>
              )}
              {/* Search button with dynamic label */}
              <SearchButton
                label={searchQuery || (isNarrowView ? "Search or ask" : "Search or ask your Mem")}
                iconKind={MdsIconKind.Search}
                variant={MdsButtonVariant.TextTertiary}
                onClick={
                  searchQuery
                    ? () => pageStore.quickSearchModal.openWithQuery({ query: searchQuery })
                    : pageStore.quickSearchModal.toggleIsOpen
                }
              />
            </Paddings>
          </LeftSection>

          {/* Right section: Copilot, Chat, and window controls */}
          <RightSection style={{ width: showFullTabButtons ? sidePanelWidth : undefined }}>
            <PaddingsWithGap>
              {/* Conditional rendering of full tab buttons vs icon buttons */}
              {showFullTabButtons ? (
                <>
                  <SidePanelButton
                    label="Copilot"
                    iconKind={isMacOS ? MdsIconKind.Copilot : MdsIconKind.CopilotSolid}
                    variant={MdsButtonVariant.Transparent}
                    shape={MdsButtonShape.Square}
                    isSelected={isCopilotActive}
                    onClick={() => store.sidePanel.goToCopilot()}
                  />
                  <SidePanelButton
                    label="Chat"
                    iconKind={isMacOS ? MdsIconKind.Message : MdsIconKind.MessageSolid}
                    variant={MdsButtonVariant.Transparent}
                    shape={MdsButtonShape.Square}
                    isSelected={isChatActive}
                    onClick={() => store.sidePanel.goToChat()}
                  />
                </>
              ) : (
                <>
                  <MdsIconButton
                    iconKind={isMacOS ? MdsIconKind.Copilot : MdsIconKind.CopilotSolid}
                    selected={isSidePanelOpen && isCopilotActive}
                    tooltipConfig={{
                      content: "Copilot",
                      placement: MdsTooltipPlacement.Bottom,
                    }}
                    onClick={() => store.sidePanel.goToCopilot()}
                  />
                  <MdsIconButton
                    iconKind={isMacOS ? MdsIconKind.Message : MdsIconKind.MessageSolid}
                    selected={isSidePanelOpen && isChatActive}
                    tooltipConfig={{
                      content: "Chat",
                      placement: MdsTooltipPlacement.Bottom,
                    }}
                    onClick={() => store.sidePanel.goToChat()}
                  />
                </>
              )}
              <MdsSpacer />
              {/* Sidebar toggle button */}
              {showFullTabButtons && (
                <MdsIconButton
                  iconKind={MdsIconKind.ArrowRightFromLine}
                  tooltipConfig={{
                    content: isSidePanelOpen ? "Close sidebar" : "Open sidebar",
                    placement: MdsTooltipPlacement.Bottom,
                  }}
                  onClick={store.sidePanel.toggle}
                  isDisabled={isChatToggleDisabled}
                />
              )}
            </PaddingsWithGap>
            {/* Window controls for non-macOS desktop */}
            {showWindowControls && (
              <WindowControls>
                <Paddings>
                  <MdsIconButton
                    iconKind={MdsIconKind.WindowMinimize}
                    onClick={handleMinimizeWindow}
                  />
                  <MdsIconButton iconKind={MdsIconKind.Square} onClick={handleMaximizeWindow} />
                  <CloseButton iconKind={MdsIconKind.Exit} onClick={handleCloseWindow} />
                </Paddings>
              </WindowControls>
            )}
          </RightSection>
        </Wrapper>
      </>
    );
  }
);

// Styled components for layout and styling
// Main wrapper for the entire top bar
const Wrapper = styled.div(({ theme }) => ({
  width: "100%",
  paddingRight: theme.spacing.sm,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
  height: `${TOP_BAR_HEIGHT}px`,
  WebkitAppRegion: "drag", // Makes the top bar draggable in desktop app
  overflow: "hidden",

  // Responsive styling for narrow view
  [`@media ${mdsPanelModeBreakpoints()[PanelMode.Narrow]}`]: {
    flexWrap: "nowrap",
    gap: 0,
  },
}));

// Left section container
const LeftSection = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  overflow: "hidden",
  flexGrow: 1,
});

// Right section container
const RightSection = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  overflow: "hidden",
});

// Common padding container
const Paddings = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  paddingTop: theme.spacing.sm,
  paddingBottom: theme.spacing.sm,
}));

// Padding container with additional gap
const PaddingsWithGap = styled(Paddings)(({ theme }) => ({
  gap: theme.spacing.xsPlus,
}));

// Styled search button
const SearchButton = styled(MdsButton)(({ theme }) => ({
  height: "32px",
  flexGrow: 1,
  maxWidth: 360,
  alignItems: "flex-start",
  backgroundColor: theme.colors.lilac.x50,
  fontWeight: theme.fontWeights.regular,
  "&:hover, &:active": {
    backgroundColor: theme.colors.lilac.x200,
    color: theme.colors.grey.x0,
  },
  // Responsive styling for narrow view
  [`@media ${mdsPanelModeBreakpoints()[PanelMode.Narrow]}`]: {
    maxWidth: "none",
    flexGrow: 1,
    margin: "0 8px",
  },
}));

// Styled button for side panel
const SidePanelButton = styled(MdsButton, {
  shouldForwardProp: prop => prop !== "isSelected",
})(({ isSelected, theme }) => ({
  backgroundColor: isSelected ? theme.colors.lilac.x50 : undefined,
  borderRadius: theme.borderRadius.mediumPlus,
  boxSizing: "border-box",
  lineHeight: theme.lineHeights.xsmall,
  padding: `${theme.spacing.sm} ${theme.spacing.smd}`,
  "&:hover, &:active": {
    backgroundColor: theme.colors.lilac.x200,
  },
}));

// Container for window controls (minimize, maximize, close)
const WindowControls = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  borderLeft: `1px solid ${theme.colors.grey.x50}`,
  marginLeft: 20,
  paddingLeft: 20,
}));

// Styled close button with Windows-style hover effect
const CloseButton = styled(MdsIconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#C42B1C", // Windows 11 style
    transition: "all 0.1s ease-in-out",
    svg: {
      color: theme.colors.white,
    },
  },
}));
