import { ValidateEnumIsSubtype } from "@/domains/typing/types";
import { AssertTrue } from "@/domains/typing/types";
import { ApiSchema } from "@/modules/api";

export type DataImportSyncModelData = ApiSchema["DataImportSyncModelDataResponseSchema"];

export type DataImportKindValues = ApiSchema["DataImportSyncModelKind"];

export enum DataImportKind {
  MemClassicAccountV1 = "MEM_CLASSIC_ACCOUNT_V1",
  MemClassicAccountV2 = "MEM_CLASSIC_ACCOUNT_V2",
  MemClassicBatchAccountUpgradeV1 = "MEM_CLASSIC_BATCH_ACCOUNT_UPGRADE_V1",
}

type _ValidateDataImportKindValues = AssertTrue<
  ValidateEnumIsSubtype<DataImportKind, DataImportKindValues>
>;
