import { logger } from "@/modules/logger";
import type { DropItem, FileDropItem } from "react-aria-components";

// Handling React Aria DropItems
// https://react-spectrum.adobe.com/react-aria/DropZone.html

/**
 * Collects all file items from one or more DropItems.
 *
 * This function processes DropItems which may include files, directories,
 * and nested subdirectories. It recursively traverses directory structures
 * and returns a flattened array containing only FileDropItems.
 *
 * @param itemOrItems - A single DropItem or an array of DropItems to process
 * @returns A promise that resolves to a flattened array of FileDropItems
 */

interface CollectFileDropItemsOptions {
  items: DropItem[];
}
export function collectFileDropItems({
  items,
}: CollectFileDropItemsOptions): Promise<FileDropItem[]> {
  const promises = items
    .filter(item => item.kind === "file" || item.kind === "directory") // the "text" kind is ignored
    .map(async (item): Promise<FileDropItem[]> => {
      // Handle single DropItem
      if (item.kind === "file") {
        // For file items, return the FileDropItem directly
        return [item];
      }

      // For directory items, recursively get all file items
      logger.debug({ message: `Processing directory: ${item.name}` });

      // Get all entries in the directory
      const fileItems: FileDropItem[] = [];
      for await (const entry of item.getEntries()) {
        // Recursively process each entry
        const entryFileItems = await collectFileDropItems({ items: [entry] });
        fileItems.push(...entryFileItems);
      }
      return fileItems;
    });

  return Promise.all(promises).then(results => results.flat());
}
