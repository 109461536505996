import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveCollectionFromFavoritesOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";

export class RemoveCollectionFromFavoritesOperation extends BaseSyncOperation<IRemoveCollectionFromFavoritesOperation> {
  private favoriteItem?: FavoriteItemObservable;

  get operationKind(): SyncOperationKind {
    return "REMOVE_COLLECTION_FROM_FAVORITES";
  }

  get successToastMessage() {
    return <>Removed {this.mediumTitle} from your pins</>;
  }

  get title() {
    return this.store.collections.get(this.payload.collection_id)?.label;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  get favoriteItemId() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    return uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.collection_id,
    });
  }

  async execute() {
    this.favoriteItem = await this.store.favoriteItems.getAsync(this.favoriteItemId);

    await super.execute();
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

    const favoriteItemDeletedUpdate = this.generateOptimisticFavoriteItemDeletedUpdate();

    if (favoriteItemDeletedUpdate) {
      optimisticUpdates.push(favoriteItemDeletedUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.favoriteItems.recompute(this.favoriteItemId);
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }

  protected getToastMessage() {
    return (
      <>
        {this.mediumTitle} could not be removed from your pins. If this error continues, please
        contact support.
      </>
    );
  }

  protected generateOptimisticFavoriteItemDeletedUpdate() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const favoriteItemId = uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.collection_id,
    });

    const favoriteItemObservable = this.favoriteItem;
    if (!favoriteItemObservable) {
      logger.debug({
        message: "Attempt to delete favorite item that does not exist",
        info: { favoriteItemId },
      });

      return;
    }

    const value: FavoriteItemDeletedSyncUpdateValue = {
      model_id: favoriteItemId,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
    };

    const syncUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value,
    };

    return syncUpdate;
  }
}
