import { V2TransitionModal } from "@/components/modal/v2-transition";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";

interface V2TransitionOverlayProps {}

export const V2TransitionOverlay = observer<V2TransitionOverlayProps>(() => {
  const { store } = useAppStore();

  useEffectOnMount(() => {
    store.accountTransition.fetchTransitionInfo();
  });

  if (!store.accountTransition.userNeedsToCompleteV2Transition) {
    return null;
  }

  return <V2TransitionModal />;
});
