import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { MdsDropdownButton, MdsDropdownIconButton } from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { mdsFontSizes, mdsFontWeights } from "@/design-system/foundations/typography";
import { MdsEditableTextField } from "@/design-system/components/text-field/MdsEditableTextField";
import { CollectionsViewPageHeaderShareButton } from "@/pages/collections-view/components/CollectionsViewPageHeaderShareButton";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import styled from "@emotion/styled";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { MdsButtonIconPosition } from "@/design-system/components/button/types";
import { MdsIconKind } from "@/design-system/components/icon";
import { CollectionsFacetFilter } from "@/components/filters/CollectionsFacetFilter/CollectionsFacetFilter";
import { ContactsFacetFilter } from "@/components/filters/ContactsFacetFilter";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { DateFacetFilter } from "@/components/filters/DateFacetFilter/DateFacetFilter";
import { FadeHorizontalScroll } from "@/components/horizontal-scroll/FadeHorizontalScroll";
import { MediaKindsFacetFilter } from "@/components/filters/MediaKindsFacetFilter/MediaKindsFacetFilter";
import {
  MdsIconButton,
  MdsIconButtonVariant,
  MdsIconButtonSize,
} from "@/design-system/components/icon-button";

interface Props {
  isSidePanel: boolean;
}

export const CollectionsViewPageHeader = observer<Props>(function CollectionsViewPageHeader({
  isSidePanel,
}) {
  const stores = useAppStore();
  const page = isSidePanel
    ? stores.store.sidePanel.collectionsViewPage
    : stores.pageStore.collectionsViewPage;
  const collection = page.collectionObservable;
  const navigator = isSidePanel ? stores.store.sidePanel : stores.store.navigation;
  const { filters } = page;

  const collectionTitleInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (collection && !collection.label) collectionTitleInputRef.current?.focus();
  }, [collection]);

  useEffect(() => {
    // Make sure to save the collection when the component unmounts because blur is not guaranteed
    // to be called whe navigating away from the page.
    return () => {
      if (collection && !collection.isDeleted) collection.save();
    };
  }, [collection]);

  if (!collection) return null;
  return (
    <Wrapper>
      <MdsPanelHeader
        icon={<CollectionIcon collectionId={collection.id} />}
        heading={
          <MdsEditableTextField
            ref={collectionTitleInputRef}
            value={collection.title}
            placeholder={"Collection name"}
            onFocus={() => collection.lock()}
            onChange={e => collection.setTitle(e.currentTarget.value)}
            onBlur={async () => await collection.save()}
            onEnterKeyPress={async () => await collection.save()}
            autoFocus={!collection?.title}
            className={titleInputStyles}
            readOnly={!collection.canWrite}
          />
        }
        subheading={
          <MdsEditableTextField
            value={collection.description}
            placeholder={"Add a description"}
            onFocus={() => collection.lock()}
            onChange={e => collection.setDescription(e.currentTarget.value)}
            onBlur={async () => {
              await collection.save();
            }}
            onEnterKeyPress={async () => await collection.save()}
            autoFocus={collection?.label === ""}
            className={descriptionInputStyles}
            readOnly={!collection.canWrite}
          />
        }
        actions={
          <>
            <CollectionsViewPageHeaderShareButton
              collection={collection}
              isSidePanel={isSidePanel}
            />
            <MdsIconButton
              size={MdsIconButtonSize.Small}
              variant={MdsIconButtonVariant.Transparent}
              iconKind={collection.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack}
              tooltipConfig={{
                content: collection.isFavorited ? "Unpin from sidebar" : "Pin to sidebar",
                placement: MdsTooltipPlacement.Top,
              }}
              onClick={() => collection.toggleFavorite()}
            />
            <MdsDropdownIconButton
              contentList={page.headerDropdownButtonContentList}
              placement="below-right-alignment"
              tooltipConfig={{
                label: "More",
                placement: MdsTooltipPlacement.Top,
                delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
              }}
            />
          </>
        }
        onBackClick={navigator.goUp}
      />
      <WrapperBottom>
        <WrapperBottomLeft>
          <FiltersContainer>{renderSortedFilters(page.filters)}</FiltersContainer>
        </WrapperBottomLeft>
        <WrapperBottomRight>
          <MdsDropdownButton
            dropdownIcon
            contentList={filters.sortOptions}
            label={filters.sortLabel}
            iconKind={MdsIconKind.SortAlt}
            iconPosition={MdsButtonIconPosition.Left}
            placement="below-right-alignment"
          />
        </WrapperBottomRight>
      </WrapperBottom>
    </Wrapper>
  );
});

const renderSortedFilters = (pageFilters: NotesListFilter) => {
  const filters = [
    {
      isActive: !!pageFilters.params.filter?.date,
      defaultOrder: 2,
      component: (
        <DateFacetFilter
          key="date"
          items={pageFilters.dateFilterItems}
          selectedRange={pageFilters.dateSelectedRange}
          onClear={() => pageFilters.clearDateFilter()}
        />
      ),
    },
    {
      isActive: !!pageFilters.mediaKindsFacetFilterItems.some(item => item.isSelected),
      defaultOrder: 1,
      component: (
        <MediaKindsFacetFilter
          key="media-kinds"
          items={pageFilters.mediaKindsFacetFilterItems}
          onSelectItem={pageFilters.toggleMediaKindItem}
          onSelectClear={pageFilters.clearMediaKinds}
          selectedItems={pageFilters.selectedMediaKinds}
        />
      ),
    },
    {
      isActive: pageFilters.addedBySearchItems.some(item => item.isSelected),
      defaultOrder: 3,
      component: (
        <ContactsFacetFilter
          key="addedBy"
          items={pageFilters.addedBySearchItems}
          searchText={pageFilters.addedBySearchQuery}
          onSearchTextChange={pageFilters.setAddedBySearchQuery}
          onSearchTextClear={pageFilters.clearAddedBySearchQuery}
          onSelectItem={pageFilters.toggleAddedByItem}
          onSelectClear={pageFilters.clearAddedBy}
          buttonPrefix="Added by"
        />
      ),
    },
    {
      isActive: pageFilters.modifiedBySearchItems.some(item => item.isSelected),
      defaultOrder: 4,
      component: (
        <ContactsFacetFilter
          key="modifiedBy"
          items={pageFilters.modifiedBySearchItems}
          searchText={pageFilters.modifiedBySearchQuery}
          onSearchTextChange={pageFilters.setModifiedBySearchQuery}
          onSearchTextClear={pageFilters.clearModifiedBySearchQuery}
          onSelectItem={pageFilters.toggleModifiedByItem}
          onSelectClear={pageFilters.clearModifiedBy}
          buttonPrefix="Edited by"
        />
      ),
    },
    {
      isActive: pageFilters.createdBySearchItems.some(item => item.isSelected),
      defaultOrder: 5,
      component: (
        <ContactsFacetFilter
          key="createdBy"
          items={pageFilters.createdBySearchItems}
          searchText={pageFilters.createdBySearchQuery}
          onSearchTextChange={pageFilters.setCreatedBySearchQuery}
          onSearchTextClear={pageFilters.clearCreatedBySearchQuery}
          onSelectItem={pageFilters.toggleCreatedByItem}
          onSelectClear={pageFilters.clearCreatedBy}
          buttonPrefix="Created by"
        />
      ),
    },
    {
      isActive:
        pageFilters.selectedCollectionTitles.length > 0 ||
        pageFilters.collectionNoCollectionIsSelected,
      defaultOrder: 0,
      component: (
        <CollectionsFacetFilter
          key="collections"
          searchText={pageFilters.collectionsSearchQuery}
          items={pageFilters.collectionsFilterItems}
          onSearchTextChange={pageFilters.setCollectionSearchQuery}
          onSearchTextClear={pageFilters.clearCollectionSearchQuery}
          onSelectItem={pageFilters.toggleCollectionItem}
          onSelectClear={pageFilters.clearCollections}
          buttonPrefix={pageFilters.collectionNoCollectionIsSelected ? "" : "In"}
          buttonLabel="Collections"
          selectedModeKey={pageFilters.selectedCollectionModeKey}
          onSelectMode={pageFilters.onCollectionModeSelectionChange}
          noCollectionIsSelected={pageFilters.collectionNoCollectionIsSelected}
          onNoCollectionIsSelected={pageFilters.setCollectionNoCollectionIsSelected}
          selectedItems={pageFilters.selectedCollectionTitles}
        />
      ),
    },
  ];

  return filters
    .sort((a, b) => {
      const activeSort = Number(b.isActive) - Number(a.isActive);
      if (activeSort !== 0) {
        return activeSort;
      }

      return a.defaultOrder - b.defaultOrder;
    })
    .map(filter => filter.component);
};

const Wrapper = styled.div({});

const WrapperBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.md,
}));

const titleInputStyles = css({
  width: "100%",
  padding: 0,
  fontSize: mdsFontSizes().small,
  lineHeight: mdsFontSizes().small,
});

const descriptionInputStyles = css({
  width: "100%",
  padding: 0,
  borderRadius: mdsBorderRadius().medium,
  cursor: "text",
  transition: "0.08s ease",

  fontSize: mdsFontSizes().xxsmall,
  lineHeight: mdsFontSizes().small,
  fontWeight: mdsFontWeights().regular,
  color: mdsColors().grey.x500,

  overflow: "hidden",
  "&:placeholder": {
    color: mdsColors().grey.x400,
  },
  "&:not(:read-only):hover, &:not(:read-only):focus": {
    backgroundColor: mdsColors().grey.x25,
    overflow: "auto",
  },
});

const FiltersContainer = styled(FadeHorizontalScroll)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.sm,
}));

const WrapperBottomLeft = styled.div({
  minWidth: 0,
});

const WrapperBottomRight = styled.div({
  flexShrink: 0,
});
