import { useCallback, useEffect } from "react";
import { CustomEventMap, CustomEventName } from "./custom-event.types";

export function useCustomEvent<K extends CustomEventName>(
  eventName: K,
  handler: (data: CustomEventMap[K], event: CustomEvent<CustomEventMap[K]>) => void,
  options?: boolean | AddEventListenerOptions
) {
  const cb = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<CustomEventMap[K]>;
      handler(customEvent.detail, customEvent);
    },
    [handler]
  );

  useEffect(() => {
    document.addEventListener(eventName, cb, options);
    return () => {
      document.removeEventListener(eventName, cb, options);
    };
  }, [eventName, cb, options]);
}
