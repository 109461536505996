import { AppStore } from "@/store/AppStore";
import { FavoriteItem, FavoriteItemIndexTuple } from "@/store/favorite-items/types";
import { Dexie } from "dexie";

export const searchFavoriteItems = async ({
  store,
}: {
  store: AppStore;
}): Promise<FavoriteItem[]> => {
  const favoriteItems = (await store.favoriteItems.localTable
    .where("[sort_key+item_id+item_kind+model_id]")
    .between([Dexie.minKey, Dexie.maxKey], [Dexie.maxKey, Dexie.maxKey])
    .keys()) as unknown as FavoriteItemIndexTuple[];

  const noteIds: string[] = [];
  const collectionIds: string[] = [];

  favoriteItems.forEach(([_, itemId, itemKind]) => {
    // TODO: Expand to support saved searches when necessary
    const source = itemKind === "NOTE" ? noteIds : collectionIds;
    source.push(itemId);
  });

  const notes: ItemData[] = await getAvailableNotes(store, noteIds);
  const collections: ItemData[] = await getAvailableCollections(store, collectionIds);

  const result: FavoriteItem[] = [];

  favoriteItems.forEach(([sortKey, itemId, itemKind, modelId]) => {
    // TODO: Expand to support saved searches when necessary
    const source = itemKind === "NOTE" ? notes : collections;
    const item = source.find(({ model_id }) => model_id === itemId);
    if (!item) {
      return;
    }

    result.push({ sortKey, itemId, itemKind, title: item.title, modelId });
  });

  return result;
};

const getAvailableNotes = async (store: AppStore, noteIds: string[]): Promise<ItemData[]> => {
  const notes = await store.notes.localTable
    .where("model_id")
    .anyOf(noteIds)
    .and(item => item.is_available === 1)
    .toArray();

  return notes.map(({ model_id, primary_label }) => ({ model_id, title: primary_label }));
};

const getAvailableCollections = async (
  store: AppStore,
  collectionIds: string[]
): Promise<ItemData[]> => {
  const collections = await store.collections.localTable
    .where("model_id")
    .anyOf(collectionIds)
    .and(item => item.is_available === 1)
    .toArray();

  return collections.map(({ model_id, title }) => ({
    model_id,
    title,
  }));
};

type ItemData = {
  model_id: string;
  title: string;
};
