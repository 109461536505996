import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { ThreadsListPageListEmpty } from "./ThreadsListPageListEmpty";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";

interface ThreadsListPageListProps {}

export const ThreadsListPageList: FC<ThreadsListPageListProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.threadsListPage;

  if (page.isEmpty) {
    return <ThreadsListPageListEmpty />;
  }

  return <MdsVirtualizedItemList rows={page.itemRows} loading={false} />;
});
