import styled from "@emotion/styled";
import { RangeCalendar } from "react-aria-components";

export const StyledRangeCalendar = styled(RangeCalendar)`
  width: fit-content;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.grey.x900};
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.sm};
`;
