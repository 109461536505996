import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { WithAppStore } from "@/store/types";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";
import { Dexie, liveQuery, Subscription } from "dexie";
import { EventContext } from "@/domains/metrics/context";

type NoteCollectionItemTuple = [item_id: Uuid, collection_id: Uuid, model_id: Uuid];

export class NoteCollectionListObservable {
  private store: AppStore;
  private noteId: Uuid;

  isLoading: boolean;
  liveQuerySubscription?: Subscription;
  subscribedCollectionItems: NoteCollectionItemTuple[] = [];

  constructor({ store, noteId }: { noteId: Uuid } & WithAppStore) {
    this.store = store;
    this.noteId = noteId;
    this.isLoading = true;

    makeObservable<this, "store" | "noteId">(this, {
      hasCollectionAsync: true,
      getAllCollectionItemsAsync: true,
      getAllCollectionsAsync: true,
      getAllCollectionIdsAsync: true,
      getAllCollectionItemIdsAsync: true,
      store: false,
      noteId: observable,

      isLoading: observable,
      liveQuerySubscription: observable,
      subscribedCollectionItems: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      allCollectionIds: computed,
      allCollectionItemIds: computed,
      allCollections: computed,
      allCollectionItems: computed,
      hasCollection: false,
      addCollection: action,
      removeCollection: action,
    });

    onBecomeObserved(this, "subscribedCollectionItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "subscribedCollectionItems", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.store.collectionItems.localTable
        .where("[item_id+collection_id+model_id]")
        .between(
          [this.noteId, Dexie.minKey, Dexie.minKey],
          [this.noteId, Dexie.maxKey, Dexie.maxKey]
        )
        .keys();
    }).subscribe({
      next: rows => {
        runInAction(() => {
          this.subscribedCollectionItems = rows as unknown as NoteCollectionItemTuple[];
          this.isLoading = false;
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  get allCollectionItemIds(): Set<Uuid> {
    return new Set(
      this.subscribedCollectionItems.map(
        ([_item_id, _collection_id, collection_item_id]) => collection_item_id
      )
    );
  }

  async getAllCollectionItemIdsAsync(): Promise<Uuid[]> {
    return this.store.collectionItems.localTable.where({ item_id: this.noteId }).primaryKeys();
  }

  get allCollectionIds(): Set<Uuid> {
    return new Set(
      this.subscribedCollectionItems.map(
        ([_item_id, collection_id, _collection_item_id]) => collection_id
      )
    );
  }

  async getAllCollectionIdsAsync(): Promise<Uuid[]> {
    const collectionItems = await this.store.collectionItems.localTable
      .where({ item_id: this.noteId })
      .toArray();
    return collectionItems.map(({ collection_id }) => collection_id);
  }

  get allCollections(): CollectionObservable[] {
    return Array.from(this.allCollectionIds)
      .map(id => this.store.collections.get(id))
      .filter(collection => !!collection);
  }

  async getAllCollectionsAsync(): Promise<CollectionObservable[]> {
    const allCollectionIds = await this.getAllCollectionIdsAsync();
    const collections = await Promise.all(
      allCollectionIds.map(id => this.store.collections.getAsync(id))
    );
    return collections.filter(collection => !!collection);
  }

  get allCollectionItems(): CollectionItemObservable[] {
    return Array.from(this.allCollectionItemIds)
      .map(id => this.store.collectionItems.get(id))
      .filter(item => !!item);
  }

  async getAllCollectionItemsAsync(): Promise<CollectionItemObservable[]> {
    const allCollectionItemIds = await this.getAllCollectionItemIdsAsync();
    const collectionItems = await Promise.all(
      allCollectionItemIds.map(id => this.store.collectionItems.getAsync(id))
    );
    return collectionItems.filter(item => !!item);
  }

  public hasCollection({ collectionId }: { collectionId: string }) {
    return this.allCollectionIds.has(collectionId);
  }

  public async hasCollectionAsync({ collectionId }: { collectionId: string }) {
    const allCollectionIds = await this.getAllCollectionIdsAsync();
    return allCollectionIds.includes(collectionId);
  }

  public async addCollection({
    collectionId,
    triggerSuccessToast,
    eventContext,
    wait = true,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
    eventContext: EventContext;
    wait?: boolean;
  }) {
    if (this.hasCollection({ collectionId })) return;

    const operation = new AddNoteToCollectionOperation({
      store: this.store,
      payload: {
        collection_id: collectionId,
        note_id: this.noteId,
      },
      triggerSuccessToast,
      eventContext,
    });
    if (wait) {
      return await operation.execute();
    }
    const queue = this.store.notes.getNoteQueue({ noteId: this.noteId });
    queue.push(operation);
  }

  public async removeCollection({
    collectionId,
    triggerSuccessToast,
  }: {
    collectionId: Uuid;
    triggerSuccessToast?: boolean;
  }) {
    if (!this.hasCollection({ collectionId })) return;
    const queue = this.store.notes.getNoteQueue({ noteId: this.noteId });
    queue.push(
      new RemoveNoteFromCollectionOperation({
        store: this.store,
        payload: {
          collection_id: collectionId,
          note_id: this.noteId,
        },
        triggerSuccessToast,
      })
    );
  }
}
