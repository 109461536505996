import { SerializedOptimisticUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import {
  IndexedTemplateSyncUpdateValue,
  SpaceAccountTemplateModelData,
  TemplateIndexTuple,
  TemplateModelData,
  TemplateSearchParams,
  TemplateUpsertedSyncUpdateValue,
  TemplateVariableCode,
} from "@/store/templates/types";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { Uuid } from "@/domains/global/identifiers";
import { CreateTemplateOperation } from "@/store/sync/operations/templates/CreateTemplateOperation";
import { NoteQueueObservable } from "@/store/note/NoteQueueObservable";
import { makeObservable, observable, ObservableMap, override, runInAction } from "mobx";
import { resolveSpaceAccountTemplateSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountTemplateSyncModelUuid";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { TemplateIndexes } from "@/store/templates/TemplateIndexes";
import { searchTemplates } from "@/store/templates/TemplateSearch";
import Dexie, { liveQuery, Subscription, Table } from "dexie";
import { CreateNoteUsingTemplateOperation } from "@/store/sync/operations/templates/CreateNoteUsingTemplateOperation";
import { Maybe } from "@/domains/common/types";
import { SortByKind } from "@/modules/lenses/types";
import { DateTime } from "luxon";
import { noteModule } from "@mem-labs/common-editor";
import { TrackedEvent, trackEvent } from "@/domains/metrics";

export interface TemplatesStore {
  getVariables: () => Record<TemplateVariableCode, { displayName: string; value: string }>;
}

export class AppStoreTemplateStore extends BaseSyncModelStore<
  TemplateObservable,
  TemplateModelData
> {
  private queues = new ObservableMap<Uuid, NoteQueueObservable>();

  public static searchTitleLimit = 1000;
  isInitialized = false;
  sortedRecentTemplatesInteractedWithByMe: {
    template: TemplateObservable;
    lastInteractedAt: string;
  }[] = [];

  public static sortByIndexes = {
    [SortByKind.LastViewed]: "last_viewed_at",
    [SortByKind.LastCreated]: "locally_created_at",
    [SortByKind.LastModified]: "modified_at",
    [SortByKind.LastShared]: "shared_with_me_at",
    [SortByKind.Alphabetical]: "lowercase_primary_label",
  };

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Template, ...injectedDeps });
    makeObservable<
      this,
      | "search"
      | "queues"
      | "updateSearchSuggestions"
      | "generateSearchSuggestions"
      | "initializeLiveQuery"
    >(this, {
      sortedRecentTemplatesSubscription: true,
      isInitialized: true,
      initialize: true,
      initializeLiveQuery: true,
      createNewNoteUsingTemplate: true,
      localTable: override,
      queues: observable,
      sortedRecentTemplatesInteractedWithByMe: observable,
      beforeUnload: true,
      createSyncModel: true,
      createTemplate: true,
      getQueue: true,
      recompute: override,
      bulkRecompute: override,
      updateSearchSuggestions: false,
      generateSearchSuggestions: false,
      search: false,
      getVariables: true,
    });
  }

  getVariables() {
    const today = DateTime.now().toLocaleString(DateTime.DATE_FULL);
    return {
      [TemplateVariableCode.UserName]: {
        displayName: "User’s Full Name",
        value: this.store.account.myAccount.displayName,
      },
      [TemplateVariableCode.UserFirstName]: {
        displayName: "User’s First Name",
        value: this.store.account.myAccount.displayName.split(" ")[0],
      },
      [TemplateVariableCode.DateToday]: {
        displayName: "Today’s Date",
        value: today,
      },
    };
  }

  initialize() {
    if (this.isInitialized) return;
    this.initializeLiveQuery();
    runInAction(() => (this.isInitialized = true));
  }

  sortedRecentTemplatesSubscription: Maybe<Subscription>;

  initializeLiveQuery() {
    if (this.sortedRecentTemplatesSubscription) return;

    this.sortedRecentTemplatesSubscription = liveQuery(async () =>
      // Notes interacted with by me = include only non-empty last_interacted_at
      this.store.templates.localTable
        .where("[last_used_at+model_id]")
        .aboveOrEqual(["\0", Dexie.minKey])
        .reverse()
        .limit(MAX_RECENTS_TO_SHOW)
        .keys()
    ).subscribe({
      next: async templatesInteracted => {
        const sortedRecentTemplatesInteractedWithByMeIds = templatesInteracted as unknown as [
          string,
          string,
        ][];
        const templatesWithLastInteractedAt = await Promise.all(
          sortedRecentTemplatesInteractedWithByMeIds.map(
            async ([lastInteractedAt, modelId]): Promise<
              [TemplateObservable | undefined, string]
            > => {
              const template = await this.store.templates.getAsync(modelId);
              return [template, lastInteractedAt];
            }
          )
        );
        runInAction(() => {
          this.sortedRecentTemplatesInteractedWithByMe = templatesWithLastInteractedAt
            .filter(([template]) => !!template)
            .filter(([template]) => template?.isAvailable)
            .map(([template, lastInteractedAt]) => ({ template, lastInteractedAt })) as {
            template: TemplateObservable;
            lastInteractedAt: string;
          }[];
        });
      },
    });
  }

  beforeUnload = (e: Event) => {
    for (const [, queue] of this.queues) {
      if (queue.isEmpty) continue;

      e.preventDefault();
      e.stopPropagation();

      return "Changes are being saved. Are you sure you want to leave?";
    }
  };

  createSyncModel(updateValue: SyncUpdateValue<TemplateModelData>): TemplateObservable {
    return new TemplateObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  public async createTemplate({ templateId }: { templateId: Uuid }) {
    await new CreateTemplateOperation({ store: this.store, payload: { id: templateId } }).execute();
    trackEvent(TrackedEvent.TemplateCreate, {});
  }

  public async createNewNoteUsingTemplate({
    templateId,
    noteId,
  }: {
    templateId: Uuid;
    noteId: Uuid;
  }) {
    const template = await this.getAsync(templateId);
    if (!template) {
      logger.error({
        message:
          "[APP STORE TEMPLATE STORE] Error creating new note using template. Missing template",
        info: { templateId },
      });
      return;
    }

    const templateContents = await template.getTemplateContentsForInsertionAsync();
    if (!templateContents) return;

    const { remoteContent, documentUpdates } = templateContents;
    if (!templateContents) {
      logger.error({
        message:
          "[APP STORE TEMPLATE STORE] Error creating new note using template. Missing remote content",
        info: { templateId },
      });
      return;
    }

    const variables = Object.fromEntries(
      Object.entries(this.getVariables()).map(([code, { value }]) => [code, value])
    );

    const encodedContent = noteModule.replaceTemplateVariablesInEncodedContent({
      encodedContent: remoteContent,
      documentUpdates,
      stringifiedValues: JSON.stringify(variables),
      enableLogging: true,
    });

    if (!encodedContent) {
      logger.error({
        message:
          "[APP STORE TEMPLATE STORE] Error creating new note using template. Missing encoded content",
        info: { templateId },
      });
      return;
    }

    await new CreateNoteUsingTemplateOperation({
      store: this.store,
      payload: { template_id: templateId, note_id: noteId, encoded_content: encodedContent },
    }).execute();
  }

  getQueue = ({ id }: { id: Uuid }): NoteQueueObservable => {
    let queue = this.queues.get(id);
    if (!queue) {
      queue = new NoteQueueObservable(this.store, id);
      this.queues.set(id, queue);
    }
    return queue;
  };

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<IndexedTemplateSyncUpdateValue>;
  }

  public async recompute(modelId: Uuid) {
    const remoteData = await this.remoteTable.get(modelId);
    const optimisticUpdates = await this.db.queue.optimisticUpdates
      .where({ model_id: modelId })
      .sortBy("locally_committed_at");

    const lastOptimisticUpdate = optimisticUpdates.at(-1);
    if (lastOptimisticUpdate?.kind === "DELETED" || lastOptimisticUpdate?.kind === "ACL_REVOKED") {
      logger.debug({
        message: "[APP STORE TEMPLATE STORE] deleting template / revoking access",
        info: { modelId },
      });

      await this.localTable.delete(modelId);
      this.pool.delete(modelId);
      await this.store.search.remove(modelId);

      return;
    }

    // Fetch other required data
    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      templateId: modelId,
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
    });

    const spaceAccountTemplate = (await this.db.mappedTables[
      SyncModelKind.SpaceAccountTemplate
    ].local.get(spaceAccountTemplateId)) as
      | SyncUpdateValue<SpaceAccountTemplateModelData>
      | undefined;

    // const spaceAccountTemplate = await this.getSpaceAccountTemplateAsync(modelId);

    // Optimistic updates
    const data = lastOptimisticUpdate?.value || remoteData; // At least one of these should be defined
    if (!data) {
      await this.localTable.delete(modelId);
      await this.store.search.remove(modelId);
      return;
    }

    const indexes = new TemplateIndexes({
      store: this.store,
      remoteData,
      optimisticUpdates,
      spaceAccountTemplate,
    }).indexes;

    const dataWithIndexes: IndexedTemplateSyncUpdateValue = {
      ...(data as TemplateUpsertedSyncUpdateValue),
      ...indexes,
    };

    await this.localTable.put(dataWithIndexes, dataWithIndexes.model_id);
    await this.updateSearchSuggestions(dataWithIndexes);

    // NOTE: we update fullText search content not here but in the NoteContentDocumentStore to
    // a. detach its potentially heavy computation from note content update
    // b. get a direct access to the most up-to-date note content (we don't optimistically update note content)
    runInAction(() => {
      this.updateObservable(modelId);
    });
  }

  public override async bulkRecompute(modelIds: Uuid[]) {
    const remoteData = (await this.remoteTable.bulkGet(modelIds)) as (
      | SyncUpdateValue<TemplateModelData>
      | undefined
    )[];

    const allOptimisticUpdates = await this.db.queue.optimisticUpdates
      .where("model_id")
      .anyOf(modelIds)
      .sortBy("locally_committed_at");
    const optimisticUpdatesById = new Map<Uuid, SerializedOptimisticUpdate<TemplateModelData>[]>();
    for (const update of allOptimisticUpdates) {
      optimisticUpdatesById.set(update.model_id, [
        ...(optimisticUpdatesById.get(update.model_id) || []),
        update,
      ]);
    }

    const templateIdsToSpaceAccountTemplateIds = new Map(
      modelIds.map(modelId => [
        modelId,
        resolveSpaceAccountTemplateSyncModelUuid({
          templateId: modelId,
          spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
        }),
      ])
    );
    const spaceAccountTemplateIds = Array.from(templateIdsToSpaceAccountTemplateIds.values());
    const spaceAccountTemplates = (await this.db.mappedTables[
      SyncModelKind.SpaceAccountTemplate
    ].local.bulkGet(spaceAccountTemplateIds)) as (
      | SyncUpdateValue<SpaceAccountTemplateModelData>
      | undefined
    )[];
    const templateIdsToSpaceAccountTemplates: Map<
      Uuid,
      SyncUpdateValue<SpaceAccountTemplateModelData> | undefined
    > = new Map(
      spaceAccountTemplateIds.map((_spaceAccountTemplateId, i) => [
        modelIds[i],
        spaceAccountTemplates[i],
      ])
    );

    const toDelete: Uuid[] = [];
    const toPut: IndexedTemplateSyncUpdateValue[] = [];
    const searchSuggestions: SearchSuggestion[] = [];

    for (const [i, modelId] of modelIds.entries()) {
      const spaceAccountTemplate = templateIdsToSpaceAccountTemplates.get(modelId);
      const optimisticUpdates = optimisticUpdatesById.get(modelId) || [];
      const lastOptimisticUpdate = optimisticUpdates.at(-1);

      if (
        lastOptimisticUpdate?.kind === "DELETED" ||
        lastOptimisticUpdate?.kind === "ACL_REVOKED"
      ) {
        toDelete.push(modelId);
        continue;
      }

      const data = (lastOptimisticUpdate?.value || remoteData[i]) as
        | SyncUpdateValue<TemplateModelData>
        | undefined;

      if (!data) {
        // Case: remoteData is deleted and there are no optimistic updates\
        toDelete.push(modelId);
        continue;
      }

      const indexes = new TemplateIndexes({
        store: this.store,
        remoteData: data,
        optimisticUpdates,
        spaceAccountTemplate,
      }).indexes;

      const dataWithIndexes: IndexedTemplateSyncUpdateValue = {
        ...(data as TemplateUpsertedSyncUpdateValue),
        ...indexes,
      };

      toPut.push(dataWithIndexes);
      searchSuggestions.push(this.generateSearchSuggestions(dataWithIndexes));
    }

    await this.db.transaction("rw", this.localTable, async () => {
      await this.localTable.bulkDelete(toDelete);
      await this.localTable.bulkPut(toPut);
    });

    await this.store.search.bulkUpdateSuggestions(searchSuggestions);

    runInAction(() => {
      for (const modelId of toDelete) {
        this.pool.delete(modelId);
      }
      // this is a noop update to retrigger get for observables that previously returned undefined in the case they they become defined through the recompute
      for (const data of toPut) {
        const modelId = data.model_id;
        if (!this.pool.has(modelId)) {
          this.pool.set(modelId, undefined);
          this.pool.delete(modelId);
        }
      }
    });
  }

  private generateSearchSuggestions(value: IndexedTemplateSyncUpdateValue): SearchSuggestion {
    const generateSortKey = (
      value: IndexedTemplateSyncUpdateValue
      // includeMentionedAt: boolean
    ): number => {
      const sortKey = value.last_used_at || value.created_at;
      return new Date(sortKey || "").getTime();
    };

    // Include "Template" so search finds it.
    const label = `Template ${value.title.slice(0, AppStoreTemplateStore.searchTitleLimit)}`;

    const suggestion: SearchSuggestion = {
      modelId: value.model_id,
      label,
      lowercaseLabel: label.toLowerCase(),
      type: SearchSuggestionType.TEMPLATE,
      lastViewedAt: value.last_used_at,
      sortKey: generateSortKey(value),
      mentionKey: generateSortKey(value),
      isAvailable: 1,
    };

    return suggestion;
  }

  private async updateSearchSuggestions(value: IndexedTemplateSyncUpdateValue) {
    try {
      const suggestion = this.generateSearchSuggestions(value);
      await this.store.search.updateSuggestion(suggestion);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreTemplateStore] Error generating search suggestion",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  /**
   * SEARCH
   */
  public async search(params: TemplateSearchParams): Promise<TemplateIndexTuple[]> {
    return searchTemplates(this.store, params);
  }
}

const MAX_RECENTS_TO_SHOW = 3;
