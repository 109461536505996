import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { EventContext } from "@/domains/metrics/context";
import { clientEnvModule } from "@/modules/client-env";
import { logger } from "@/modules/logger";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store/AppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import { ISyncOperation } from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncModelData } from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";

export interface BaseSyncOperationParams<T extends ISyncOperation = ISyncOperation> {
  store: AppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number;
  triggerSuccessToast?: boolean;
}

export abstract class BaseSyncOperation<
  SyncOperation extends ISyncOperation,
> extends BaseSyncOperationGeneric<SyncOperation> {
  protected eventContext?: EventContext;
  protected store: AppStore;
  abstract get operationKind(): SyncOperation["operation_kind"];

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
    triggerSuccessToast,
    eventContext,
  }: BaseSyncOperationParams<SyncOperation> & { eventContext?: EventContext }) {
    super({
      store,
      payload,
      operationId,
      committedAt,
      latestSpaceAccountSequenceId,
      triggerSuccessToast,
    });
    this.store = store;
    this.eventContext = eventContext;
  }

  /**
   * @sealed YOU PROBABLY DON'T WANT TO OVERRIDE THIS.
   */
  public async generateSyncOperation(): Promise<SyncOperation> {
    const operation = {
      id: this.operationId,
      client_id: await clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperation;

    return operation;
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    return [];
  }

  get successToastMessage(): React.ReactNode {
    return null;
  }

  public async triggerRecompute(): Promise<void> {
    // Implement in subclasses if necessary please!
  }

  public async execute(): Promise<void> {
    const syncOp = await this.generateSyncOperation();
    if (!syncOp) {
      logger.error({
        message: "can't generate syncOp",
        info: {
          operation: getLoggableOperation(this),
        },
      });

      return;
    }

    await this.store.sync.actionQueue.push(this);
    await this.applyOptimisticUpdates(syncOp.id);
    await this.triggerRecompute();

    this.handleSuccess();
  }

  protected async applyOptimisticUpdates(syncOpId: Uuid) {
    const optimisticUpdates = await this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      await this.store.sync.actionQueue.applyOptimisticUpdate(syncOpId, optimisticUpdate);
    }
  }

  protected handleSuccess() {
    const toastContent = this.successToastMessage;
    if (toastContent) {
      toastModule.triggerToast({ content: toastContent, toastId: this.operationId });
    }
  }
}
