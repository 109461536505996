import styled from "@emotion/styled";
import { MDSBannerKind } from "./banner.types";
import { Theme } from "@emotion/react";

export const StyledBanner = styled.section<{ kind?: MDSBannerKind; showDismissBtn?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  border-radius: ${({ theme }) => theme.borderRadius.mediumLarge}px;

  height: 48px;
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-right: ${({ theme, showDismissBtn }) => (showDismissBtn ? 0 : theme.spacing.md)};

  background-color: ${({ theme, kind }) => getBackgroundColor(theme, kind)};

  line-height: ${({ theme }) => theme.lineHeights.small};
  font-size: ${({ theme }) => theme.fontSizes.small};

  & p {
    margin: 0;
    color: ${({ theme }) => getTextColor(theme)};
  }
`;

export const StyledRightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getBackgroundColor = (theme: Theme, kind?: MDSBannerKind) => {
  switch (kind) {
    case MDSBannerKind.Info:
      return theme.colors.mblue.x50;
    case MDSBannerKind.Error:
      return theme.colors.red.x50;

    default:
      return theme.colors.grey.x50;
  }
};

const getTextColor = (theme: Theme) => {
  return theme.colors.grey.x600;
};
