import { MDSButton, MDSCalendarGrid } from "@/mds";
import { StyledRangeCalendar, StyledHeader } from "./range-calendar.style";
import { IMDSRangeCalendarProps } from "./range-calendar.types";
import { MDSCalendarHeading } from "@/mds/components/date-time/calendars/calendar-heading";
import { MDSCalendarGridBody } from "@/mds/components/date-time/calendars/calendar-grid-body";
import { MDSCalendarGridHeader } from "@/mds/components/date-time/calendars/calendar-grid-header";
import { MDSCalendarHeaderCell } from "@/mds/components/date-time/calendars/calendar-header-cell";
import { MDSCalendarCell } from "@/mds/components/date-time/calendars/calendar-cell";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";

export function MDSRangeCalendar(props: IMDSRangeCalendarProps) {
  return (
    <StyledRangeCalendar {...props}>
      <StyledHeader>
        <MDSButton slot="previous">
          <MdsIcon kind={MdsIconKind.AngleLeft} />
        </MDSButton>
        <MDSCalendarHeading />
        <MDSButton slot="next">
          <MdsIcon kind={MdsIconKind.AngleRight} />
        </MDSButton>
      </StyledHeader>

      <MDSCalendarGrid>
        <MDSCalendarGridHeader>
          {day => <MDSCalendarHeaderCell>{day}</MDSCalendarHeaderCell>}
        </MDSCalendarGridHeader>
        <MDSCalendarGridBody>{date => <MDSCalendarCell date={date} />}</MDSCalendarGridBody>
      </MDSCalendarGrid>
    </StyledRangeCalendar>
  );
}
