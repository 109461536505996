import { DateRangePicker, Group } from "react-aria-components";
import styled from "@emotion/styled";

export const StyledDateRangePicker = styled(DateRangePicker)``;

export const StyledGroup = styled(Group)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 300px;

  border: 1px solid ${({ theme }) => theme.colors.grey.x100};
  border-radius: ${({ theme }) => theme.borderRadius.mediumLarge}px;
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.sm};
  // because border
  margin: 1px 0px;

  &[data-focus-within] {
    border: 2px solid ${({ theme }) => theme.colors.blue.x400};
    margin: 0;
    padding-left: calc(${({ theme }) => theme.spacing.sm} - 1px);
    padding-right: calc(${({ theme }) => theme.spacing.sm} - 1px);
  }
`;

export const LabelWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm} 0;
`;

export const Dash = styled.span`
  padding: 0 2px;
`;
