import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ImportInProgressBanner } from "@/components/banners";
import { AppCalloutBanner } from "@/components/banners/app-callout-banner";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";

export const PageBannerSection: FC = observer(() => {
  const { store } = useAppStore();
  const pastChatsBanner = store.spaceAccountAppCallouts.pastChatsBanner != undefined;

  return (
    <Wrapper>
      {!pastChatsBanner && <AppCalloutBanner />}
      <ImportInProgressBanner />
    </Wrapper>
  );
});

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 16,
});
