import { MentionContent } from "@/store/chat/mention/MentionContent";
import { MentionTitle } from "@/store/chat/mention/MentionTitle";
import { observer } from "mobx-react-lite";

export const SearchMentionContent = observer<{
  query: string;
}>(({ query }) => {
  return (
    <MentionContent>
      <MentionTitle>
        <span>{query}</span>
      </MentionTitle>
    </MentionContent>
  );
});
