import { uuidModule } from "@/modules/uuid";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateTemplateContentUsingDiffOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  OptimisticSyncUpdate,
  SyncOperationKind,
} from "@/store/sync/types";

export class UpdateTemplateContentUsingDiffOperation extends BaseSyncOperation<IUpdateTemplateContentUsingDiffOperation> {
  primaryLabel: string;

  get operationKind(): SyncOperationKind {
    return "UPDATE_TEMPLATE_CONTENT_USING_DIFF";
  }

  constructor({
    primaryLabel,
    ...params
  }: {
    primaryLabel: string;
  } & BaseSyncOperationParams<IUpdateTemplateContentUsingDiffOperation>) {
    super(params);
    this.primaryLabel = primaryLabel;
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;
    const template = this.store.templates.get(templateId);
    if (!template) return [];

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: template.modelVersion,
      model_data: {
        ...template.modelData,
        primary_label: this.primaryLabel,
      },
      model_scopes: template.modelScopes,
    };
    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    // TODO: Handle errors
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    // TODO: Handle errors
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    // TODO: Handle errors
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
