import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";
import { PusherEventData, PusherEventKind } from "@/domains/pusher/constants";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { uuidModule } from "@/modules/uuid";
import { resolvePrimaryChatConversationSyncModelUuid } from "@/modules/uuid/sync-models/resolvePrimaryChatConversationSyncModelUuid";
import { AppStore } from "@/store/AppStore";
import { ChatConversationObservable } from "@/store/chat/ChatConversationObservable";
import { ChatConversationModelData, ChatMessageContext } from "@/store/chat/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { CreateChatConversationOperation } from "@/store/sync/operations/chat/CreateChatConversationOperation";
import {
  OptimisticSyncUpdate,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import {
  generateChatConversationLiveSyncUpdatePusherChannelKey,
  generateSyncActionChatConversationScopedPusherChannelKey,
} from "@/store/sync/utils";
import { AppSubStoreArgs } from "@/store/types";
import {
  makeObservable,
  action,
  override,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";
import { Channel } from "pusher-js";
import { ChatConversationIndexes } from "@/store/chat/ChatConversationIndexes";
import { __dangerouslyAcceptEdits } from "@/boulder-hackathon/edit-assistant";
import { isMemAccount } from "@/store/contacts/isMemAccount";
import { newParagraphSchema, paragraphEditSchema } from "@/domains/pusher/schemas";

export class AppStoreChatConversationStore extends BaseSyncModelStore<
  ChatConversationObservable,
  ChatConversationModelData
> {
  private _isCreatingNewConversation = false;

  private chatConversationPusherChannels = new Map<string, Channel>();
  private liveChatConversationPusherChannels = new Map<string, Channel>();
  private _navContexts: ChatMessageContext[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.ChatConversation, ...injectedDeps });
    makeObservable<
      AppStoreChatConversationStore,
      | "_navContexts"
      | "chatConversationPusherChannels"
      | "liveChatConversationPusherChannels"
      | "handleChatConversationLiveSyncUpdate"
      | "handleChatConversationUpserted"
      | "_isCreatingNewConversation"
      | "isCreatingNewConversation"
    >(this, {
      handleAssistantNoteEditSuggested: action,
      _isCreatingNewConversation: observable,
      _navContexts: observable,
      handleChatConversationUpserted: true,
      getChatConversation: true,
      createNewGuidedConversation: false,
      getAsyncPrimaryChatConversation: true,
      primaryChatConversationId: true,
      chatConversationPusherChannels: observable,
      liveChatConversationPusherChannels: observable,
      createSyncModel: false,
      handleChatConversationLiveSyncUpdate: action,
      processSyncUpdate: override,
      primaryChatConversation: computed,
      generateChatConversationIfNeeded: action,
      subscribeToChatConversation: action,
      unsubscribeFromChatConversation: action,
      subscribeToLiveChatConversation: action,
      unsubscribeFromLiveChatConversation: action,
      navContexts: computed,
      addNavContext: action,
      removeNavContext: action,
      clearNavContexts: action,
      hasNavContext: false,
      getMostRecentlyCreatedConversation: false,
      getMostRecentConversation: false,
      isCreatingNewConversation: computed,
      createOrResumeEmptyGuidedChatConversation: false,
      resumeActiveOrCreateEmptyGuidedChatConversation: false,
      computeIndexes: false,
    });

    onBecomeObserved(this, "primaryChatConversationId", () => {
      this.subscribeToChatConversation(this.primaryChatConversationId);
    });
    onBecomeUnobserved(this, "primaryChatConversationId", () => {
      this.unsubscribeFromChatConversation(this.primaryChatConversationId);
    });
  }

  createSyncModel(data: SyncUpdateValue<ChatConversationModelData>): ChatConversationObservable {
    return new ChatConversationObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get primaryChatConversationId() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;

    return resolvePrimaryChatConversationSyncModelUuid({ spaceAccountId });
  }

  async getMostRecentlyCreatedConversation(): Promise<
    Maybe<{
      conversationId: string;
      locallyCreatedAt: Date;
    }>
  > {
    const conversation = await this.localTable.orderBy("locally_created_at").reverse().first();

    const conversationId = conversation?.model_id;
    const locallyCreatedAt = conversation?.model_data.locally_created_at;

    if (!conversationId || !locallyCreatedAt) return undefined;

    return {
      conversationId,
      locallyCreatedAt: new Date(locallyCreatedAt),
    };
  }

  async getMostRecentConversation(): Promise<
    Maybe<{
      conversationId: string;
      lastUserActivityAt: Date;
    }>
  > {
    const [mostRecentlyCreatedConversation, mostRecentlySentUserMessage] = await Promise.all([
      this.getMostRecentlyCreatedConversation(),
      this.store.chatMessages.getMostRecentlySentUserMessage(),
    ]);

    if (!mostRecentlyCreatedConversation || !mostRecentlySentUserMessage) return undefined;

    if (
      mostRecentlyCreatedConversation.locallyCreatedAt >
      mostRecentlySentUserMessage.locallyCreatedAt
    ) {
      return {
        conversationId: mostRecentlyCreatedConversation.conversationId,
        lastUserActivityAt: mostRecentlyCreatedConversation.locallyCreatedAt,
      };
    }

    return {
      conversationId: mostRecentlySentUserMessage.conversationId,
      lastUserActivityAt: mostRecentlySentUserMessage.locallyCreatedAt,
    };
  }

  async getAsyncPrimaryChatConversation() {
    return await this.getAsync(this.primaryChatConversationId);
  }

  public async processSyncUpdate(update: SyncUpdate<ChatConversationModelData>) {
    await super.processSyncUpdate(update);
    if (update.kind === "UPSERTED" || update.kind === "ACL_UPSERTED")
      this.subscribeToChatConversation(update.value.model_id);
    if (update.kind === "DELETED" || update.kind === "ACL_REVOKED")
      this.unsubscribeFromChatConversation(update.value.model_id);
  }

  get primaryChatConversation(): ChatConversationObservable | undefined {
    return this.getChatConversation(this.primaryChatConversationId);
  }

  public getChatConversation(id: Uuid): ChatConversationObservable | undefined {
    return this.get(id);
  }

  async generateChatConversationIfNeeded(id: string) {
    const chatConversation = await this.getAsync(id);
    if (chatConversation) {
      return chatConversation.id;
    }

    return this.createNewGuidedConversation(id);
  }

  async createNewGuidedConversation(optionalId?: string) {
    const id = optionalId ?? uuidModule.generate();

    trackEvent(TrackedEvent.ChatCreate, {
      chat_conversation_id: id,
    });

    await new CreateChatConversationOperation({
      store: this.store,
      payload: {
        id,
        is_primary_chat_conversation: false,
        kind: "GUIDED",
      },
    }).execute();

    return id;
  }

  // CHAT CONVERSATION SUBSCRIPTION - Always subscribed for chat-conversation scoped sync updates
  public subscribeToChatConversation(chatConversationId: string) {
    try {
      if (this.chatConversationPusherChannels.has(chatConversationId)) return;
      const chatConversationPusherChannelKey =
        generateSyncActionChatConversationScopedPusherChannelKey({
          chatConversationId,
        });
      const channel = this.pusher.subscribe(chatConversationPusherChannelKey);
      console.debug(
        "[SYNC][AppStoreChatConversationStore] Subscribing to chat conversation",
        chatConversationId
      );
      channel.bind(PusherEventKind.SYNC_UPDATE_PUBLISHED, this.store.sync.queryForSyncActions);
      this.chatConversationPusherChannels.set(chatConversationId, channel);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreChatConversationStore] Error subscribing to chat conversation",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  public unsubscribeFromChatConversation(chatConversationId: string) {
    try {
      const channel = this.chatConversationPusherChannels.get(chatConversationId);
      if (channel) {
        console.debug(
          "[SYNC][AppStoreChatConversationStore] Unsubscribing from chat conversation",
          chatConversationId
        );
        this.pusher.unsubscribe(channel.name);
      }
      this.chatConversationPusherChannels.delete(chatConversationId);
    } catch (e) {
      logger.error({
        message: "[SYNC][AppStoreChatConversationStore] Error unsubscribing from chat conversation",
        info: { error: objectModule.safeErrorAsJson(e as Error) },
      });
    }
  }

  // LIVE CHAT CONVERSATION SUBSCRIPTION - Subscribed when the chat UI is mounted
  public subscribeToLiveChatConversation(chatConversationId: string) {
    if (this.liveChatConversationPusherChannels.has(chatConversationId)) return;

    const chatConversationPusherChannelKey = generateChatConversationLiveSyncUpdatePusherChannelKey(
      { chatConversationId }
    );
    const liveChannel = this.pusher.subscribe(chatConversationPusherChannelKey);
    this.liveChatConversationPusherChannels.set(chatConversationId, liveChannel);

    liveChannel.bind(
      PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED,
      this.handleChatConversationLiveSyncUpdate
    );

    liveChannel.bind(
      PusherEventKind.CHAT_CONVERSATION_UPSERTED,
      this.handleChatConversationUpserted
    );

    if (isMemAccount(this.store.account.myAccount)) {
      liveChannel.bind(
        PusherEventKind.ASSISTANT_NOTE_EDIT_SUGGESTED,
        this.handleAssistantNoteEditSuggested
      );
    }
  }

  public unsubscribeFromLiveChatConversation(chatConversationId: string) {
    const liveChannel = this.liveChatConversationPusherChannels.get(chatConversationId);
    if (!liveChannel) return;
    this.pusher.unsubscribe(liveChannel.name);
    this.liveChatConversationPusherChannels.delete(chatConversationId);
  }

  private handleChatConversationLiveSyncUpdate = async ({
    value,
    sync_operation_id,
  }: PusherEventData<PusherEventKind.CHAT_CONVERSATION_MESSAGE_UPSERTED>) => {
    await this.store.chatMessages.processLiveSyncUpdate(sync_operation_id, value);
  };

  get navContexts(): ChatMessageContext[] {
    return this._navContexts;
  }

  public addNavContext(context: ChatMessageContext) {
    if (!this.hasNavContext(context.id)) {
      this._navContexts.push(context);
    }
  }

  public removeNavContext(contextId: string) {
    this._navContexts = this._navContexts.filter(c => c.id !== contextId);
  }

  public clearNavContexts() {
    this._navContexts = [];
  }

  public hasNavContext(contextId: string) {
    return this._navContexts.some(c => c.id === contextId);
  }

  private handleChatConversationUpserted = async ({
    value,
    sync_operation_id,
  }: PusherEventData<PusherEventKind.CHAT_CONVERSATION_UPSERTED>) => {
    // Remove any existing optimistic updates for this conversation
    await this.store.sync.actionQueue.removeAllOptimisticUpdatesByModelId(value.model_id);

    const optimisticUpdate: OptimisticSyncUpdate<ChatConversationModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: value.model_data.locally_created_at,
      kind: "UPSERTED",
      value: value,
    };

    await this.store.sync.actionQueue.applyOptimisticUpdate(sync_operation_id, optimisticUpdate);
    await this.recompute(optimisticUpdate.value.model_id);
  };

  get isCreatingNewConversation() {
    return this._isCreatingNewConversation;
  }

  async createOrResumeEmptyGuidedChatConversation(): Promise<string> {
    let conversationId: string = "";
    this._isCreatingNewConversation = true;

    // Snap to the most recent conversation if it's empty
    const recentConversation = await this.getMostRecentConversation();
    if (recentConversation) {
      const numMessagesInConversation = await this.store.chatMessages.getNumMessagesInConversation(
        recentConversation.conversationId
      );

      if (!numMessagesInConversation) {
        conversationId = recentConversation.conversationId;
      }
    }

    // If we couldn't snap to the most recent conversation, create a new one
    if (!conversationId) {
      conversationId = await this.createNewGuidedConversation();
    }

    this._isCreatingNewConversation = false;
    return conversationId;
  }

  // TODO: figure out where this should live more permanently
  async handleAssistantNoteEditSuggested({
    paragraph_edits,
    new_paragraphs,
  }: PusherEventData<PusherEventKind.ASSISTANT_NOTE_EDIT_SUGGESTED>): Promise<void> {
    if (!isMemAccount(this.store.account.myAccount)) return;

    try {
      // Validate the incoming data against our schemas
      paragraphEditSchema.parse(paragraph_edits);
      newParagraphSchema.parse(new_paragraphs);

      console.log("assistant note edit suggested:");
      console.log(paragraph_edits);
      console.log(new_paragraphs);

      __dangerouslyAcceptEdits(paragraph_edits, new_paragraphs);
    } catch (error) {
      // TODO: send error back to the tool call on the server
      logger.error({
        message: "[SYNC][AppStoreChatConversationStore] Invalid paragraph edits or new paragraphs",
        info: { error: objectModule.safeErrorAsJson(error as Error) },
      });
    }
  }

  async resumeActiveOrCreateEmptyGuidedChatConversation(): Promise<string> {
    let conversationId: string = "";

    // Snap to the most recent conversation if it was active within the last 5 minutes
    const mostRecentConversation = await this.getMostRecentConversation();
    if (mostRecentConversation) {
      const lastUserActivityAt = mostRecentConversation.lastUserActivityAt.getTime();
      const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;

      if (lastUserActivityAt > fiveMinutesAgo) {
        conversationId = mostRecentConversation.conversationId;
      }
    }

    // If we couldn't snap to the most recent conversation, then we want to get a new one
    if (!conversationId) {
      conversationId = await this.createOrResumeEmptyGuidedChatConversation();
    }

    return conversationId;
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ChatConversationModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ChatConversationModelData>[];
  }): Record<string, unknown> {
    return new ChatConversationIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
