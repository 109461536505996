import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveTemplateFromCollectionOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
export class RemoveTemplateFromCollectionOperation extends BaseSyncOperation<IRemoveTemplateFromCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "REMOVE_TEMPLATE_FROM_COLLECTION";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.template_id;

    const existingTemplate = await this.store.templates.getAsync(templateId);
    if (!existingTemplate) return [];

    const templateValue: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
      model_data: {
        ...existingTemplate.modelData,
        target_scopes: existingTemplate.modelData.target_scopes.filter(scope => {
          return !(
            scope.scope_kind === "COLLECTION_SCOPE" &&
            scope.value.collection_id === this.payload.collection_id
          );
        }),
      },
      model_scopes: existingTemplate.modelScopes,
    };
    const templateUpserted: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: templateValue,
    };

    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: templateId,
    });
    const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
      model_id: collectionItemId,
      model_kind: "COLLECTION_ITEM",
      model_version: 0,
    };
    const collectionItemDeleted: OptimisticSyncUpdate<CollectionItemModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: collectionItemValue,
    };

    return [templateUpserted, collectionItemDeleted];
  }

  async triggerRecompute() {
    const collectionItemId = uuidModule.resolveCollectionItemSyncModelUuid({
      collectionId: this.payload.collection_id,
      itemId: this.payload.template_id,
    });
    await this.store.templates.recompute(this.payload.template_id);
    await this.store.collectionItems.recompute(collectionItemId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
