import { logger } from "@/modules/logger";
import { notesModule } from "@/modules/notes";
import { uuidModule } from "@/modules/uuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { resolveSpaceAccountChatMessageSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountChatMessageSyncModelUuid";
import { ChatMessageObservable } from "@/store/chat/ChatMessageObservable";
import { SpaceAccountChatMessageObservable } from "@/store/chat/SpaceAccountChatMessageObservable";
import {
  SpaceAccountChatMessageModelData,
  SpaceAccountChatMessageUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { getMentionedNoteIds } from "@/store/note/metadata";
import {
  NoteContentDocumentModelData,
  NoteContentDocumentUpsertedSyncUpdateValue,
  NoteModelData,
  NoteUpsertedSyncUpdateValue,
} from "@/store/note/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ISaveDraftNoteOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";

export class SaveDraftNoteOperation extends BaseSyncOperation<ISaveDraftNoteOperation> {
  private chatMessage?: ChatMessageObservable;
  private spaceAccountChatMessage?: SpaceAccountChatMessageObservable;

  get operationKind(): SyncOperationKind {
    return "SAVE_DRAFT_NOTE";
  }

  get noteContentDocumentId() {
    return resolveNoteContentDocumentSyncModelUuid({
      noteId: this.payload.saved_note_id,
    });
  }

  get spaceAccountChatMessageId() {
    return resolveSpaceAccountChatMessageSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      chatMessageId: this.payload.chat_message_id,
    });
  }

  async execute() {
    this.chatMessage = await this.store.chatMessages.getAsync(this.payload.chat_message_id);
    this.spaceAccountChatMessage = await this.store.spaceAccountChatMessages.getAsync(
      this.spaceAccountChatMessageId
    );

    await super.execute();
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

    const noteUpsertedSyncUpdate = this.generateNoteUpsertedSyncUpdate();
    if (noteUpsertedSyncUpdate) {
      optimisticUpdates.push(noteUpsertedSyncUpdate);
    }

    const noteContentDocumentUpsertedSyncUpdate =
      this.generateNoteContentDocumentUpsertedSyncUpdate();
    if (noteContentDocumentUpsertedSyncUpdate) {
      optimisticUpdates.push(noteContentDocumentUpsertedSyncUpdate);
    }

    const spaceAccountChatMessageUpsertedSyncUpdate =
      this.generateChatMessageSectionUpsertedSyncUpdate();
    if (spaceAccountChatMessageUpsertedSyncUpdate) {
      optimisticUpdates.push(spaceAccountChatMessageUpsertedSyncUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.notes.recompute(this.payload.saved_note_id);
    await this.store.noteContentDocuments.recompute(this.noteContentDocumentId);
    await this.store.spaceAccountChatMessages.recompute(this.spaceAccountChatMessageId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("Failed to save draft note. Please try again.");
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData): void {
    this.triggerToast("You don't have permission to save this draft note.");
  }

  handleTransientError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "Failed to save draft note. Please try again.",
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData): void {
    this.triggerToast(
      "An unexpected error occurred while saving the draft note. Please try again."
    );
  }

  protected generateChatMessageSectionUpsertedSyncUpdate() {
    const spaceAccountChatMessage = this.spaceAccountChatMessage;
    const chatMessage = this.chatMessage;
    if (!chatMessage) {
      logger.debug({
        message: "Attempt to save draft note for non-existent chat message",
        info: { chatMessageId: this.payload.chat_message_id },
      });

      return;
    }

    const chatMessageSection = chatMessage.sectionMap[this.payload.chat_message_section_id];
    if (!chatMessageSection) {
      logger.debug({
        message: "Attempt to save draft note for non-existent chat message section",
        info: { chatMessageSectionId: this.payload.chat_message_section_id },
      });

      return;
    }

    if (chatMessageSection.kind !== "DRAFT_NOTE") {
      logger.debug({
        message: "Attempt to save draft note for a non-DRAFT_NOTE chat message section",
        info: { chatMessageSectionId: this.payload.chat_message_section_id },
      });

      return;
    }

    const spaceAccountChatMessageValue: SpaceAccountChatMessageUpsertedSyncUpdateValue = {
      model_id: this.spaceAccountChatMessageId,
      model_kind: "SPACE_ACCOUNT_CHAT_MESSAGE",
      model_version: spaceAccountChatMessage?.modelVersion ?? 0,
      model_data: {
        ...spaceAccountChatMessage?.modelData,
        space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        chat_message_id: this.payload.chat_message_id,
        saved_as_note_id: this.payload.saved_note_id,
        saved_as_note_at: this.committedAt,
        feedback_responded_at: null,
        feedback_response: null,
        feedback_selected_suggestions: [],
        feedback_user_text: null,
        sections: [
          ...(spaceAccountChatMessage?.sections ?? []),
          {
            section_id: this.payload.chat_message_section_id,
            saved_as_note_id: this.payload.saved_note_id,
            saved_as_note_at: this.committedAt,
          },
        ],
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const spaceAccountChatMessageUpsertedSyncUpdate: OptimisticSyncUpdate<SpaceAccountChatMessageModelData> =
      {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: spaceAccountChatMessageValue,
      };

    return spaceAccountChatMessageUpsertedSyncUpdate;
  }

  protected generateNoteUpsertedSyncUpdate() {
    const { primaryLabel, secondaryLabel, mediaKinds, mentions } =
      notesModule.convertEncodedContentToNoteContent(this.payload.encoded_content);

    const noteUpsertedValue: NoteUpsertedSyncUpdateValue = {
      model_id: this.payload.saved_note_id,
      model_kind: "NOTE",
      model_version: 0,
      model_data: {
        trashed_at: null,
        created_at: this.committedAt,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        created_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        modified_by_space_account_ids: [],
        primary_label: primaryLabel ?? "",
        primary_label_strategy: "INFERRED_FROM_CONTENT",
        secondary_label: secondaryLabel ?? "",
        secondary_label_strategy: "INFERRED_FROM_CONTENT",
        media_kinds: mediaKinds ?? [],
        mentioned_note_ids: getMentionedNoteIds(mentions),
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const noteUpsertedSyncUpdate: OptimisticSyncUpdate<NoteModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: noteUpsertedValue,
    };

    return noteUpsertedSyncUpdate;
  }

  protected generateNoteContentDocumentUpsertedSyncUpdate() {
    const noteContentDocumentId = this.noteContentDocumentId;
    const noteContentDocumentUpsertedValue: NoteContentDocumentUpsertedSyncUpdateValue = {
      model_id: noteContentDocumentId,
      model_kind: "NOTE_CONTENT_DOCUMENT",
      model_version: 0,
      model_data: {
        note_id: this.payload.saved_note_id,
        encoded_content: this.payload.encoded_content,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const noteContentDocumentUpsertedSyncUpdate: OptimisticSyncUpdate<NoteContentDocumentModelData> =
      {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: noteContentDocumentUpsertedValue,
      };

    return noteContentDocumentUpsertedSyncUpdate;
  }
}
