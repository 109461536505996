export enum SettingsTabId {
  PersonalPage = "personal-page",
  EmailPreferencesPage = "email-preferences-page",
  SubscriptionManagementPage = "subscription-management-page",
  AccountManagementPage = "account-management-page",
  AccountMigrationPage = "account-migration-page",
  DebugAccountPage = "debug-account-management-page",
  DebugSandboxPage = "debug-sandbox-page",
  ExportsPage = "export-page",
  ApiKeysPage = "api-keys-page",
}
