import { IMDSSwitchProps, IMDSSwitchVariant } from "./switch.types";
import { StyledSwitch } from "./switch.style";

export function MDSSwitch(props: IMDSSwitchProps) {
  const { label, style, variant = IMDSSwitchVariant.WHITE, ...rest } = props;
  return (
    <StyledSwitch {...rest} style={style} variant={variant}>
      <div className="indicator" />
      {label}
    </StyledSwitch>
  );
}
