import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateDataExportOperation } from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncModelData, SyncOperationKind } from "@/store/sync/types";
import { DataExportSyncModel, DataExportSyncModelData } from "@/store/data-exports/types";
import { DateTime } from "luxon";

export class CreateDataExportOperation extends BaseSyncOperation<ICreateDataExportOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_DATA_EXPORT" as SyncOperationKind;
  }

  async triggerRecompute() {
    await this.store.dataExports.recompute(this.payload.id);
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    const dataExportUpdate = this.generateCreateDataExportUpdate();

    if (dataExportUpdate) {
      optimisticUpdates.push(dataExportUpdate);
    }

    return optimisticUpdates;
  }

  protected generateCreateDataExportUpdate(): OptimisticSyncUpdate<DataExportSyncModelData> {
    const value: DataExportSyncModel = {
      model_id: this.payload.id,
      model_kind: "DATA_EXPORT",
      model_version: 0,
      model_data: {
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        kind: "MARKDOWN",
        locally_created_at: DateTime.now().toISO(),
        locally_modified_at: DateTime.now().toISO(),
        started_at: DateTime.now().toISO(),
        ended_at: null,
        ended_with_error: null,
      },
      model_scopes: [
        generateDefaultOwnerScopes({
          store: this.store,
        }),
      ],
    };

    const syncUpdate: OptimisticSyncUpdate<DataExportSyncModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
