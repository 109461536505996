import { IAsyncData } from "@/domains/async/AsyncData";
import styled from "@emotion/styled";
import { inflect } from "inflection";
import { isUndefined } from "lodash-es";

interface Props {
  sizeData: IAsyncData<number>;
  itemTypeName?: string;
  description?: string;
}

export function CollectionItemSubtitle({ sizeData, itemTypeName, description }: Props) {
  return (
    <Wrapper hasLoadedData={!isUndefined(sizeData.data)}>
      {getCollectionItemSubtitle(sizeData, itemTypeName, description)}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ hasLoadedData: boolean }>(({ theme, hasLoadedData }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xsmall,
  lineHeight: theme.lineHeights.xxsmall,
  opacity: hasLoadedData ? 1 : 0,
  transition: "opacity 0.12s ease-in-out",
}));

export const getCollectionItemSubtitle = (
  sizeData: IAsyncData<number>,
  itemTypeName?: string,
  description?: string
) => {
  if (isUndefined(sizeData.data)) {
    /**
     * We return a whitespace character to ensure that the subtitle is not collapsed.
     * (We should improve this in the future)
     */
    return "\u00A0";
  }

  if (sizeData.data === 0) {
    if (description) {
      return description;
    }

    return `No ${inflect(itemTypeName ?? "item", 0)}`;
  }

  const countText = `${sizeData.data} ${inflect(itemTypeName ?? "item", sizeData.data)}`;

  if (!description) {
    return countText;
  }

  return `${countText} • ${description}`;
};
