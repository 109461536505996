import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { action, computed, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import { Maybe } from "@/domains/common/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { createProxyWithIsDeleted } from "@/hooks/useObjectAfterDeletion";
import { MountedStore } from "@/store/pages/MountedStore";
import { FileUploadRejectedModalStore } from "@/components/modal/file-upload-rejected/FileUploadRejectedModalStore";
import { ImageUploadRejectedModalStore } from "@/components/modal/image-upload-rejected/ImageUploadRejectedModalStore";
import { LeaveNoteMessageModalStore } from "@/store/pages/NotesViewPageStore/LeaveNoteMessageModalStore";
import { NoteType } from "@/components/note/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { Uuid } from "@/domains/global/identifiers";
import { MdsChip } from "@/design-system/components/input/types";
import { MdsChipInputProps } from "@/design-system/components/input/MdsChipInput";
import { ShareChip, ShareSheetChipType } from "@/components/modal/share-sheet/types";
import { AccountProfileImage } from "@/components/layout/components/account-profile/AccountProfileImage";
import { ProfileKind, ProfileSize } from "@/components/layout/components/account-profile";
import { isEmail } from "@/domains/validation/validation";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { differenceBy } from "lodash-es";
import { DeleteTemplateModalStore } from "@/components/modal/delete-template/DeleteTemplateModalStore";

enum ORDER_PRIORITY_PREFIX {
  HIGH = "2-",
  MEDIUM = "1-",
  LOW = "0-",
}

export class TemplatesViewPageStore extends AppSubStore {
  // TODO: implements INotesViewPageStore<TemplateObservable>
  deleteTemplateModal: DeleteTemplateModalStore;
  shareSheetModal: ShareSheetModalStore;
  leaveNoteMessageModal: LeaveNoteMessageModalStore;
  addToCollectionModal: AddToCollectionModalStore;
  fileUploadRejectedModal: FileUploadRejectedModalStore;
  imageUploadRejectedModal: ImageUploadRejectedModalStore;
  mountState = new MountedStore();

  /** This is a proxy with isDeleted=true after the template is gone. */
  itemObservable: Maybe<TemplateObservable>;
  couldAccessNoteOnMount: Maybe<boolean>;
  chips: MdsChip[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.deleteTemplateModal = new DeleteTemplateModalStore(injectedDeps, {});
    this.addToCollectionModal = new AddToCollectionModalStore(injectedDeps, {});
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.fileUploadRejectedModal = new FileUploadRejectedModalStore(injectedDeps);
    this.imageUploadRejectedModal = new ImageUploadRejectedModalStore(injectedDeps);

    makeObservable(this, {
      deleteTemplateModal: false,
      openFileUploadRejectedModal: true,
      openImageUploadRejectedModal: true,
      fileUploadRejectedModal: false,
      imageUploadRejectedModal: false,
      shareSheetModal: false,
      leaveNoteMessageModal: false,
      addToCollectionModal: false,
      mountState: false,
      useEffects: false,
      goToMention: false,
      onMentionMouseOver: false,
      onMentionMouseOut: false,
      getChips: false,
      amIMounted: computed,
      myAccount: computed,
      itemObservable: observable,
      couldAccessNoteOnMount: observable,
      chips: observable,
      isUpToDate: computed,
      templateId: computed,
      template: computed,
      itemExists: computed,
      showNotFound: computed,
      setTemplateObservable: action,
      setCouldAccessNoteOnMount: action,
      setChips: action,
    });

    // We need this to be already observable to react to changes its observable props.
    this.leaveNoteMessageModal = new LeaveNoteMessageModalStore(this, NoteType.Template);

    reaction(
      () => ({ template: this.template, amIMounted: this.amIMounted }),
      async ({ template: maybeTemplate, amIMounted }, { template: previousNote }) => {
        const template = (() => {
          if (maybeTemplate) return maybeTemplate;
          if (!previousNote || !amIMounted) return;

          return createProxyWithIsDeleted(previousNote);
        })();
        this.setTemplateObservable(template);
        if (template) await template.markAsViewed();

        const emailChips =
          template?.grantedEmailAddresses.map(email => ({
            id: email,
            type: ShareSheetChipType.Email,
            label: email,
            displayName: email,
            orderByString: `${ORDER_PRIORITY_PREFIX.LOW}${new Date().toISOString()}`, // Display last
            searchLabels: [email],
          })) ?? [];
        const spaceAccountChips = await Promise.all(
          template?.grantedSpaceAccountIds.map(async spaceAccountId => {
            const spaceAccount = await this.store.contacts.getBySpaceAccountIdAsync(spaceAccountId);
            const displayName = spaceAccount?.profileDisplayName ?? "Unknown";
            return {
              id: spaceAccountId,
              type: ShareSheetChipType.SpaceAccount,
              label: displayName,
              displayName: displayName,
              orderByString: `${ORDER_PRIORITY_PREFIX.HIGH}${new Date().toISOString()}`, // Display first
              searchLabels: [displayName, spaceAccount?.profileEmailAddress].filter(e => e),
            };
          }) ?? []
        );
        const collectionChips = await Promise.all(
          template?.grantedCollectionIds.map(async collectionId => {
            const collection = await this.store.collections.getAsync(collectionId);
            const label = collection?.label;
            return {
              id: collectionId,
              type: ShareSheetChipType.Collection,
              label: label,
              displayName: label,
              orderByString: `${ORDER_PRIORITY_PREFIX.MEDIUM}${new Date().toISOString()}`, // Display middle
              searchLabels: [label],
            };
          }) ?? []
        );
        const chips = [...emailChips, ...spaceAccountChips, ...collectionChips] as MdsChip[];
        runInAction(() => {
          this.chips = chips;
        });
      }
    );

    reaction(
      () => this.itemObservable?.canAccess,
      (canAccess, previousCanAccess) => {
        const couldAccessNoteOnMount = (() => {
          // Getting access later is fine.
          if (canAccess) return true;
          // Prefer the previous value if it's already set.
          if (typeof previousCanAccess === "boolean") return previousCanAccess;
          // Either undefined (_unknown_) or false.
          return canAccess;
        })();
        this.setCouldAccessNoteOnMount(couldAccessNoteOnMount);
      }
    );
  }

  getChips = async (query: string): Promise<ShareChip[]> => {
    const output: ShareChip[] = [];

    const contacts = (await this.store.contacts.getMatchingContacts(query)) || [];
    if (!contacts) return output;
    for (const contact of contacts) {
      if (this.template?.grantedSpaceAccountIds.includes(contact.contactSpaceAccountId)) continue;

      output.push({
        id: contact.contactSpaceAccountId,
        type: ShareSheetChipType.SpaceAccount,
        orderByString: `${ORDER_PRIORITY_PREFIX.HIGH}${contact.lastSharedWithAt}`,
        label: contact.profileDisplayName,
        displayName: contact.profileDisplayName,
        secondaryLabel: contact.profileEmailAddress,
        photoUrl: contact.profilePhotoUrl ?? undefined,
        icon: (size?: number) => (
          <AccountProfileImage
            size={size === 34 ? ProfileSize.Large : ProfileSize.Small}
            profile={{
              kind: ProfileKind.Contact,
              data: contact,
            }}
          />
        ),
        searchLabels: [contact.profileDisplayName, contact.profileEmailAddress],
      });
    }

    const email = query.trim();
    if (
      isEmail(email) &&
      !this.template?.grantedEmailAddresses.includes(email) &&
      output.length === 0
    ) {
      output.push({
        id: email,
        type: ShareSheetChipType.Email,
        orderByString: `${ORDER_PRIORITY_PREFIX.LOW}${new Date().toISOString()}`, // Display last
        label: `Invite ${email}`,
        displayName: email,
        icon: (_size?: number) => <MdsIcon kind={MdsIconKind.Envelope} />,
        searchLabels: [email],
      });
    }

    const collections = (await this.store.collections.getMatchingCollections(query)) ?? [];
    for (const collection of collections) {
      if (this.template?.grantedCollectionIds.includes(collection.id)) continue;

      output.push({
        id: collection.id,
        type: ShareSheetChipType.Collection,
        orderByString: collection.lastInteractedAtByMe
          ? `${ORDER_PRIORITY_PREFIX.HIGH}${collection.lastInteractedAtByMe}`
          : `${ORDER_PRIORITY_PREFIX.MEDIUM}${collection.lastInteractedAt}`,
        label: collection.label,
        displayName: collection.label,
        secondaryLabel: collection.description, // TODO: Do we need to set max width on this?
        photoUrl: undefined,
        icon: (size?: number) => <CollectionIcon collectionId={collection.id} size={size} />,
        searchLabels: [collection.label],
      });
    }

    return output;
  };

  setChips: MdsChipInputProps["setChips"] = chips => {
    const shareChips = (typeof chips === "function" ? chips(this.chips) : chips) as ShareChip[];
    if (this.template) {
      const removed = differenceBy(toJS(this.chips), shareChips, "id");
      for (const chip of removed) {
        switch ((chip as ShareChip).type) {
          case ShareSheetChipType.SpaceAccount:
            this.template.revokeAccessViaSpaceAccount({
              targetSpaceAccountId: chip.id,
            });
            break;
          case ShareSheetChipType.Collection:
            this.template.removeFromCollection({
              targetCollectionId: chip.id,
            });
            break;
        }
      }
      const added = differenceBy(shareChips, toJS(this.chips), "id");
      for (const chip of added) {
        switch ((chip as ShareChip).type) {
          case ShareSheetChipType.Email:
            this.template.grantAccessViaEmailAddress({
              targetEmailAddress: chip.id,
              roleKind: "EDITOR",
            });
            break;
          case ShareSheetChipType.SpaceAccount:
            this.template.grantAccessViaSpaceAccount({
              targetSpaceAccountId: chip.id,
              roleKind: "EDITOR",
            });
            break;
          case ShareSheetChipType.Collection:
            this.template.addToCollection({
              targetCollectionId: chip.id,
              roleKind: "EDITOR",
            });
            break;
        }
      }
      this.chips = shareChips.map(chip =>
        chip.type === ShareSheetChipType.Email ? { ...chip, label: chip.displayName } : chip
      );
    }
  };

  goToMention: INotesViewPageStore["goToMention"] = ({ id, mentionKind, url, keys }) => {
    this.store.navigation.goToMention({ id, mentionKind, url, keys });
  };

  onMentionMouseOver: INotesViewPageStore["onMentionMouseOver"] = () => {
    // show the mention hover that allows open-in-Copilot
  };

  onMentionMouseOut: INotesViewPageStore["onMentionMouseOut"] = () => {
    // hide the mention hover
  };

  openFileUploadRejectedModal: INotesViewPageStore["openFileUploadRejectedModal"] = ({
    code,
    fileName,
  }) => {
    this.fileUploadRejectedModal.open({
      code,
      fileName,
    });
  };

  openImageUploadRejectedModal: INotesViewPageStore["openImageUploadRejectedModal"] = ({
    code,
    imageName,
  }) => {
    this.imageUploadRejectedModal.open({
      code,
      imageName,
    });
  };

  setTemplateObservable(templateObservable?: TemplateObservable) {
    this.itemObservable = templateObservable;
  }

  setCouldAccessNoteOnMount(couldAccessNoteOnMount?: boolean) {
    this.couldAccessNoteOnMount = couldAccessNoteOnMount;
  }

  get amIMounted() {
    return this.mountState.isMounted;
  }

  get isUpToDate() {
    return this.store.sync.isUpToDate;
  }

  get myAccount() {
    return this.store.spaceAccounts.myPersonalSpaceAccount;
  }

  get templateId(): Maybe<Uuid> {
    return this.store.routing.templateIdParam ?? undefined;
  }

  get template() {
    if (!this.mountState.isMounted || !this.templateId) return;
    return this.store.templates.get(this.templateId);
  }

  get showNotFound() {
    if (!this.mountState.isMounted) return false;

    if (this.couldAccessNoteOnMount === false) return true;

    if (this.leaveNoteMessageModal.isModalOpen) return false;

    return !this.itemExists;
  }

  get itemExists(): boolean {
    // Return true when it's unknown.
    if (!this.mountState.isMounted || !this.store.routing.templateIdParam) {
      return true;
    }

    const doesNotExist = this.store.templates.doesNotExist(this.store.routing.templateIdParam);
    return doesNotExist !== true;
  }

  useEffects() {
    this.mountState.useEffects();
  }
}
