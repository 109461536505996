import { ApiSchema } from "@/modules/api";
import { SyncErrorModalFieldsGenerator } from "@/store/sync/operations/errors/SyncErrorModalFields";
import React from "react";

export type CustomSyncErrorKind =
  ApiSchema["SubmitSyncOperationFailedCustomResponseDataSharedKindEnum"];

export enum SyncErrorDisplayType {
  Toast = "TOAST",
  Modal = "MODAL",
  None = "NONE",
}

export enum SyncErrorHandlingType {
  Revert = "REVERT",
  RetryForever = "RETRY-FOREVER",
  RetryWithLimit = "RETRY-WITH-LIMIT",
  Fail = "FAIL",
  FailAfterFewAttempts = "FAIL-AFTER-FEW-ATTEMPTS",
}

interface SyncErrorOptions {
  message: string;
  toastMessage?: React.ReactNode;
  displayType: SyncErrorDisplayType;
  operationId: string;
  handlingType: SyncErrorHandlingType;
  syncErrorModalFieldsGenerator?: SyncErrorModalFieldsGenerator;
  retryEndActionHandler?: () => void;
}

export class SyncError extends Error {
  toastMessage?: React.ReactNode;
  displayType: SyncErrorDisplayType;
  operationId: string;
  handlingType: SyncErrorHandlingType;
  syncErrorModalFieldsGenerator?: SyncErrorModalFieldsGenerator;
  retryEndActionHandler?: () => void;

  constructor({
    message,
    toastMessage,
    handlingType,
    displayType,
    operationId,
    syncErrorModalFieldsGenerator,
    retryEndActionHandler,
  }: SyncErrorOptions) {
    super(message);

    this.toastMessage = toastMessage;
    this.handlingType = handlingType;
    this.displayType = displayType;
    this.operationId = operationId;
    this.syncErrorModalFieldsGenerator = syncErrorModalFieldsGenerator;
    this.retryEndActionHandler = retryEndActionHandler;
  }
}
