import { Maybe } from "@/domains/common/types";
import { SpaceAccountAppCalloutObservable } from "@/store/app-callout/SpaceAccountAppCalloutObservable";
import {
  MessageModal,
  MessageModalDefinition,
  ModalDefinition,
  ModalDefinitionKind,
} from "@/store/modals/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { isEqual } from "lodash-es";
import { action, reaction } from "mobx";
import { makeObservable } from "mobx";
import { observable } from "mobx";
import { computed } from "mobx";

export class AppCalloutModal extends AppSubStore implements MessageModalDefinition, MessageModal {
  private spaceAccountAppCalloutObservable: Maybe<SpaceAccountAppCalloutObservable>;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<this, "spaceAccountAppCalloutObservable">(this, {
      kind: computed,
      modal: computed,
      buttonLabel: computed,
      title: computed,
      message: computed,
      isModalOpen: computed,
      modalDefinition: computed,
      handleButtonClick: action,
      handleClose: action,
      secondaryButton: computed,
      spaceAccountAppCalloutObservable: observable,
    });

    reaction(
      () => this.store.spaceAccountAppCallouts.highestPriorityVisibleModal,
      current => {
        this.spaceAccountAppCalloutObservable = current;
      }
    );

    reaction(
      () => this.modalDefinition,
      (current, previous) => {
        if (isEqual(current, previous)) return;

        if (previous) this.store?.modals.removeModal(previous);
        if (current) this.store?.modals.addModal(current);
      }
    );
  }

  get kind(): ModalDefinitionKind.Message {
    return ModalDefinitionKind.Message;
  }

  get modal(): MessageModal {
    return this;
  }

  get title(): string {
    return this.spaceAccountAppCalloutObservable?.primaryLabel ?? "";
  }

  get message(): string {
    return this.spaceAccountAppCalloutObservable?.secondaryLabel ?? "";
  }

  get buttonLabel(): string {
    return this.spaceAccountAppCalloutObservable?.primaryActionLabel ?? "";
  }

  get isModalOpen() {
    return this.modalDefinition === this;
  }

  get modalDefinition(): Maybe<ModalDefinition> {
    if (this.spaceAccountAppCalloutObservable?.isVisible) return this;
  }

  get secondaryButton() {
    if (!this.spaceAccountAppCalloutObservable) return;
    if (!this.spaceAccountAppCalloutObservable?.secondaryActionLabel) return;

    return {
      label: this.spaceAccountAppCalloutObservable?.secondaryActionLabel ?? "",
      onClick: () => {
        if (!this.spaceAccountAppCalloutObservable) return;

        this.store.navigation.goToImportFrom1dot0();
        this.store.spaceAccountAppCallouts.dismissAppCallout({
          appCallout: this.spaceAccountAppCalloutObservable,
        });
      },
    };
  }

  handleClose(): void {
    if (!this.spaceAccountAppCalloutObservable) return;

    this.store.spaceAccountAppCallouts.dismissAppCallout({
      appCallout: this.spaceAccountAppCalloutObservable,
    });
  }

  handleButtonClick(): void {
    if (!this.spaceAccountAppCalloutObservable) return;

    this.store.spaceAccountAppCallouts.dismissAppCallout({
      appCallout: this.spaceAccountAppCalloutObservable,
    });
  }
}
