import { memo, useMemo, FC } from "react";

import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { MdsDropdownContentList, MdsDropdownItem } from "@/design-system/components/dropdown";
import { mdsDropdownItemComponentMapper } from "@/design-system/components/dropdown/MdsDropdownItemComponent";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsBorderRadius } from "@/design-system/foundations/common";
import { ZIndex } from "@/domains/design/constants";
import styled from "@emotion/styled";

export interface MdsDropdownContentProps extends EmotionClassStyles {
  isOpen?: boolean;
  contentList: MdsDropdownContentList;
  rowStyles?: string;
  onHover?: ({ isHovering, itemId }: { isHovering?: boolean; itemId?: string }) => void;
  onOpenChange?: (value: boolean) => void;
  listRef?: React.RefObject<HTMLDivElement>;
}

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  width: "max-content",
  minWidth: "176px",
  height: "100%",
  borderStyle: "solid",
  borderWidth: 1,
  padding: 4,
  borderColor: mdsColors().grey.x25,
  borderRadius: mdsBorderRadius().mediumLarge,
  boxShadow: "#455c6830 0 6px 10px",
  backgroundColor: mdsColors().grey.x0,
  zIndex: ZIndex.Dropdown,
  userSelect: "none",
  overflow: "hidden",
});

const SectionDivider = styled.div({
  height: 1,
  width: "100%",
  backgroundColor: mdsColors().grey.x25,
});

const ContentContainer = styled.div({
  padding: 0,
  margin: 0,

  // When we need to scroll because content doesn't fit in the viewport.
  overflowY: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

let dropdownTooltipCount = 0;

const generateDropdownTooltipId = () => {
  dropdownTooltipCount++;
  return `mds-dropdown-tooltip-${dropdownTooltipCount}`;
};

export const MdsDropdownContent: FC<MdsDropdownContentProps> = memo(
  ({ className, contentList, onHover, onOpenChange, listRef, rowStyles }) => {
    const tooltipId = useMemo(() => generateDropdownTooltipId(), []);
    const dropdownComponents = useMemo(
      () =>
        contentList.items.map((item: MdsDropdownItem) =>
          mdsDropdownItemComponentMapper({
            item,
            onOpenChange,
            tooltipId,
            onHover,
            className: rowStyles,
          })
        ),
      [contentList.items, onHover, onOpenChange, rowStyles, tooltipId]
    );

    const containerStyles = cx(contentStyles, className);

    return (
      <>
        <div
          ref={listRef}
          onMouseEnter={() => onHover?.({ isHovering: true })}
          onMouseLeave={() => onHover?.({ isHovering: false })}
          className={containerStyles}
        >
          {/* Header */}
          {contentList.header && (
            <>
              {contentList.header.children}
              {!contentList.header.hideDivider && <SectionDivider />}
            </>
          )}

          {/* Content */}
          <ContentContainer>{dropdownComponents}</ContentContainer>

          {/* Footer */}
          {contentList.footer && (
            <>
              {dropdownComponents.length > 0 && <SectionDivider />}
              {contentList.footer.children}
            </>
          )}
        </div>
      </>
    );
  }
);
