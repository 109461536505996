import {
  computed,
  makeObservable,
  observable,
  onBecomeUnobserved,
  onBecomeObserved,
  runInAction,
  action,
} from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppStore } from "@/store";
import { WithAppStore } from "@/store/types";
import { SpaceAccountTopicObservable } from "@/store/topics/SpaceAccountTopicObservable";
import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { Maybe } from "@/domains/common/types";
import { Subscription } from "node_modules/react-hook-form/dist/utils/createSubject";
import { Dexie, liveQuery } from "dexie";

type NoteSpaceAccountTopicItemTuple = [item_id: Uuid, space_account_topic_id: Uuid, model_id: Uuid];

const ALLOWED_TOPIC_KINDS: string[] = [];
// const ALLOWED_TOPIC_KINDS = ["GENERAL"]; // TODO @Tarek: turn this on after the backend is updated

export class NoteTopicListObservable {
  private store: AppStore;
  private noteId: Uuid;

  liveQuerySubscription: Maybe<Subscription>;
  subscribedSpaceAccountTopicItems: NoteSpaceAccountTopicItemTuple[] = [];

  constructor({ store, noteId }: { noteId: Uuid } & WithAppStore) {
    this.store = store;
    this.noteId = noteId;

    makeObservable<this, "store" | "noteId">(this, {
      store: false,
      noteId: observable,

      subscribedSpaceAccountTopicItems: observable,
      liveQuerySubscription: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      allTopics: computed,
      allTopicItems: computed,
      sortedAllTopics: computed,
    });

    onBecomeObserved(this, "subscribedSpaceAccountTopicItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "subscribedSpaceAccountTopicItems", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.store.spaceAccountTopicItems.localTable
        .where("[item_id+space_account_topic_id+model_id]")
        .between(
          [this.noteId, Dexie.minKey, Dexie.minKey],
          [this.noteId, Dexie.maxKey, Dexie.maxKey]
        )
        .keys();
    }).subscribe({
      next: rows => {
        runInAction(() => {
          this.subscribedSpaceAccountTopicItems =
            rows as unknown as NoteSpaceAccountTopicItemTuple[];
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  get allTopicItems(): SpaceAccountTopicItemObservable[] {
    return this.subscribedSpaceAccountTopicItems
      .map(([_item_id, _space_account_topic_id, model_id]) =>
        this.store.spaceAccountTopicItems.get(model_id)
      )
      .filter(spaceAccountTopicItem => !!spaceAccountTopicItem);
  }

  get allTopics(): SpaceAccountTopicObservable[] {
    return this.subscribedSpaceAccountTopicItems
      .map(([_item_id, space_account_topic_id, _model_id]) =>
        this.store.spaceAccountTopics.get(space_account_topic_id)
      )
      .filter(spaceAccountTopic => !!spaceAccountTopic);
  }

  get sortedAllTopics(): SpaceAccountTopicObservable[] {
    return this.allTopicItems
      .map(item => ({ topicItem: item, topic: item.spaceAccountTopic }))
      .sort(
        ({ topicItem: topicItemA }, { topicItem: topicItemB }) =>
          topicItemA.topicRelevanceScore - topicItemB.topicRelevanceScore
      )
      .map(({ topic }) => topic)
      .filter(topic => topic !== undefined)
      .filter(topic => {
        // don't do any filtering if the topic allowlist is empty
        if (ALLOWED_TOPIC_KINDS.length === 0) return true;

        return topic.kind && ALLOWED_TOPIC_KINDS.includes(topic.kind);
      });
  }
}
