import { CollapsedRelatedCard } from "@/components/copilot/CollapsedRelatedCard";
import { Panel } from "@/components/layout/Panel";
import { MdsButton } from "@/design-system/components/button/MdsButton";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { useAppStore } from "@/store/utils/hooks";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list/MdsVirtualizedItemList";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MdsItemListRowData, MdsItemListRowType } from "@/design-system/components/item-list/types";
import { useMdsTextFieldChangeHandlers } from "@/design-system/components/text-field/utils/changeHandlers";
import { getRowForNoteId } from "@/store/note/getRowForNoteId";
import { OnChange, ReactKeyboardEvent } from "@/design-system/constants/handlers/types";
import { MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { getRowForNoteObservable } from "@/store/note/getRowForNoteObservable";
import { MdsHorizontalDivider } from "@/design-system/components/divider";
import { RefreshTopicsForAccountButton } from "@/components/copilot/RefreshTopicsForAccountButton";
import { NoRelatedNotes } from "@/components/copilot/NoRelatedNotes";
import { useLiveQuery } from "dexie-react-hooks";
import { CopilotLoaderBar } from "@/components/copilot/CopilotLoaderBar";
import { RefreshTopicsForNoteButton } from "@/components/copilot/RefreshTopicsForNoteButton";
import { CopilotMentionThisNote } from "@/components/copilot/CopilotMentionThisNote";
import { CopilotSuggestions } from "@/components/copilot/CopilotSuggestions";

export const CopilotView = observer(function CopilotView() {
  const { store, pageStore } = useAppStore();

  const isOnTopOfMainPanel = store.sidePanel.isSidePanelOpen && !store.sidePanel.canRenderSplitView;
  const shouldRenderBackButton = isOnTopOfMainPanel;

  const page = pageStore.copilotView;
  const activeNote = page.activeItem;
  const suggestions = activeNote
    ? store.notes.spaceAccountNoteSuggestions.get(activeNote.id)
    : undefined;

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isSearching = typeof searchQuery === "string";

  const [, setKeyboardSelectedIndex] = useState(0);
  const searchSuggestions = useLiveQuery(
    () => (searchQuery ? store.search.forSuggestions(searchQuery) : undefined),
    [searchQuery]
  );

  const searchResultRows = useMemo(() => {
    const suggestions = searchSuggestions ?? [];
    if (!searchQuery) {
      return store.recentItems.sortedRecentNotesInteractedWithByMe.map(noteObservable =>
        getRowForNoteObservable({
          noteObservable,
          inMainPanel: false,
          dateTime: noteObservable.lastViewedAt,
          dropdown: { items: [] },
          store,
        })
      );
    }

    const suggestionRows = suggestions.map(suggestion =>
      getRowForNoteId({
        store,
        noteId: suggestion.modelId,
        dateTime: suggestion.lastViewedAt,
        inMainPanel: false,
      })
    );

    const rows: MdsItemListRowData[] = suggestionRows.filter(row => !!row);
    // rows.unshift({
    //   type: MdsItemListRowType.ActionButton,
    //   key: "search-for",
    //   payload: {
    //     label: `Search for "${searchQuery}"`,
    //     onClick: () => {},
    //     icon: MdsIconKind.Search,
    //   },
    // });

    return rows;
  }, [searchQuery, searchSuggestions, store]);

  const handleEnterKeyPress = useCallback(
    (e: ReactKeyboardEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const row = searchResultRows[0];
      switch (row.type) {
        case MdsItemListRowType.Item: {
          row.payload.onClick({ itemId: row.payload.id });
          break;
        }
        case MdsItemListRowType.ActionButton: {
          // TODO: Implement full search
          break;
        }
      }
    },
    [searchResultRows]
  );

  const handleKeyDown = useCallback(
    (e: ReactKeyboardEvent<HTMLElement>) => {
      if (!isSearching) return;

      switch (e.key) {
        case "Enter":
          handleEnterKeyPress(e);
          return;
        case "Escape":
          e.preventDefault();
          e.stopPropagation();
          setSearchQuery("");
          return;
        case "ArrowDown":
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          setKeyboardSelectedIndex(
            prev =>
              (prev + searchResultRows.length + e.key === "ArrowDown" ? 1 : -1) %
              searchResultRows.length
          );
          return;
      }
    },
    [handleEnterKeyPress, isSearching, searchResultRows.length]
  );

  return (
    <StyledPanel onKeyDown={handleKeyDown}>
      <SearchHeader>
        {shouldRenderBackButton && (
          <MdsIconButton iconKind={MdsIconKind.LeftArrow} onClick={store.sidePanel.goUp} />
        )}
        <EditSearchQuery
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onEnterKeyPress={handleEnterKeyPress}
        />
        {store.debug.debugModeEnabled && !pageStore.copilotView.isRunningPipeline && (
          <RefreshTopicsForAccountButton
            spaceAccountId={store.spaceAccounts.myPersonalSpaceAccountId}
          />
        )}
      </SearchHeader>
      <Container addPadding scrollable={!isSearching}>
        {isSearching && (
          <>
            <SearchResultsHeader>{!searchQuery ? "Recents" : "Notes"}</SearchResultsHeader>
            <Container scrollable>
              <MdsVirtualizedItemList rows={searchResultRows} />
            </Container>
          </>
        )}
        <MainContentContainer>
          <RelatedCardsSection>
            <CopilotLoaderBar />
            <CopilotMentionThisNote />
            {!isSearching && page.relatedCards.length === 0 ? (
              <>
                {/* Refresh button in debug mode only */}
                {store.debug.debugModeEnabled &&
                  !pageStore.copilotView.isRunningPipeline &&
                  !!pageStore.copilotView.activeItem && (
                    <div style={{ position: "relative" }}>
                      <div style={{ position: "absolute", top: 12 }}>
                        <RefreshTopicsForNoteButton
                          noteId={pageStore.copilotView.activeItem?.id}
                          spaceAccountId={store.spaceAccounts.myPersonalSpaceAccountId}
                        />
                      </div>
                    </div>
                  )}

                <NoRelatedNotes isTopLevel={!page.activeItem} />
              </>
            ) : (
              <>
                {page.relatedCards.map((card, index) => (
                  <div key={card.id}>
                    <CollapsedRelatedCard
                      card={card}
                      showContents={true}
                      showAllItems={page.relatedCards.length < 2}
                    />
                    {index < page.relatedCards.length - 1 && <RelatedCardsSectionDivider />}
                  </div>
                ))}
              </>
            )}
          </RelatedCardsSection>
          {suggestions && suggestions.length > 0 && (
            <SuggestionsContainer>
              <CopilotSuggestions suggestions={suggestions} />
            </SuggestionsContainer>
          )}
        </MainContentContainer>
      </Container>
    </StyledPanel>
  );
});

const EditSearchQuery = ({
  onEnterKeyPress,
  searchQuery,
  setSearchQuery,
}: {
  setSearchQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  onEnterKeyPress: OnChange<ReactKeyboardEvent<HTMLInputElement>>;
  searchQuery?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { onChangeHandler, onBlurHandler, onFocusHandler, onKeyPressHandler } =
    useMdsTextFieldChangeHandlers({
      onEnterKeyPress,
      onFocus: () => setSearchQuery(prev => prev ?? ""),
      onChange: e => setSearchQuery(e.currentTarget.value),
    });

  const handleContainerClick = useCallback(
    () => setSearchQuery(prev => prev ?? ""),
    [setSearchQuery]
  );

  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setSearchQuery("");
    },
    [setSearchQuery]
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.currentTarget.blur();
      e.preventDefault();
      e.stopPropagation();
      setSearchQuery(undefined);
    },
    [setSearchQuery]
  );

  const isSearching = typeof searchQuery === "string";
  useLayoutEffect(() => {
    if (isSearching) inputRef.current?.focus();
    else inputRef.current?.blur();
  }, [isSearching]);

  return (
    <SearchRow>
      <InputContainer onClick={handleContainerClick}>
        <MdsIcon kind={MdsIconKind.Search} />
        <StyledInput
          ref={inputRef}
          value={searchQuery ?? ""}
          placeholder={"Open a note by title"}
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onKeyPress={onKeyPressHandler}
        />
        {!!searchQuery?.length && <ClearButton iconKind={MdsIconKind.Exit} onClick={handleClear} />}
      </InputContainer>
      <CancelButton
        visible={isSearching}
        variant={MdsButtonVariant.TextTertiary}
        label="Cancel"
        onClick={handleCancel}
      />
    </SearchRow>
  );
};

const SearchRow = styled.div(({ theme }) => ({
  flex: 1,
  alignItems: "center",
  display: "flex",
  gap: theme.spacing.sm,
  height: "36px",
}));

const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingBottom: theme.spacing.sm,
}));

interface ContainerProps {
  scrollable?: boolean;
  addPadding?: boolean;
}

const Container = styled("div", {
  shouldForwardProp: prop => !["addPadding", "isSearching", "scrollable"].includes(prop),
})<ContainerProps>(({ addPadding, scrollable: scrollable, theme }) => ({
  height: "100%",
  padding: addPadding ? `0 ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm}` : undefined,
  display: "flex",
  flexDirection: "column",
  ...(scrollable
    ? {
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }
    : {}),
}));

const SearchHeader = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.grey.x50}`,
  padding: `14px ${theme.spacing.md}`,
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const InputContainer = styled.div(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.grey.x100,
  borderColor: theme.colors.grey.x100,
  borderRadius: theme.spacing.sm,
  borderStyle: `solid`,
  borderWidth: `1px`,
  color: theme.colors.grey.x700,
  display: "flex",
  gap: theme.spacing.xs,
  height: "32px",
  padding: `0 ${theme.spacing.smd}`,
  transition: "all 0.1s ease-in-out",
  width: "100%",
}));

const StyledInput = styled.input(({ theme }) => ({
  backgroundColor: theme.colors.transparent,
  flex: 1,
  fontWeight: theme.fontWeights.regular,
  fontSize: theme.fontSizes.small,
}));

const ClearButton = styled(MdsIconButton)(({ theme }) => ({
  marginRight: `-${theme.spacing.sm}`,
}));

interface CancelButtonProps {
  visible: boolean;
}

const CancelButton = styled(MdsButton, {
  shouldForwardProp: prop => prop !== "visible",
})<CancelButtonProps>(({ visible, theme }) => ({
  fontSize: theme.fontSizes.medium,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.small,
  margin: visible ? -theme.spacing.xsPlus : `-${theme.spacing.sm}`,
  padding: visible ? theme.spacing.xsPlus : 0,
  transition: "all 0.1s ease-in-out",
  width: visible ? "fit-content" : 0,
  "> div": {
    width: visible ? "fit-content" : 0,
    "> span": {
      display: visible ? undefined : "none",
    },
  },
}));

const SearchResultsHeader = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.semiBold,
  lineHeight: theme.lineHeights.xsmall,
  padding: `16px 8px 4px 10px`,
}));

const MainContentContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: 0,
  overflow: "hidden",
});

const RelatedCardsSection = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 0",
  minHeight: 0,
  overflowY: "auto",
});

const RelatedCardsSectionDivider = styled(MdsHorizontalDivider)(({ theme }) => ({
  boxSizing: "border-box",
  margin: `${theme.spacing.sm} 0`,
}));

const SuggestionsContainer = styled.div({
  flex: "0 0 auto",
  marginTop: "8px",
  minHeight: "fit-content",
});
