import { V2TransitionCardButton } from "@/components/modal/v2-transition/v2-transition-card-button";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations";
import { mdsLineSpacings } from "@/design-system/foundations/typography";
import styled from "@emotion/styled";
import { inflect } from "inflection";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import v2TransitionFreshImage from "@/assets/images/v2-transition-fresh.png";
import v2TransitionImportImage from "@/assets/images/v2-transition-import.png";

interface V2TransitionModalContentProps {
  noteCount: number;
  collectionCount: number;
  handleStartFresh: () => Promise<unknown>;
  handleStartImport: () => Promise<unknown>;
  transitionIsLoading: boolean;
  transitionIsError: boolean;
}

export const V2TransitionModalContent = observer<V2TransitionModalContentProps>(
  ({
    noteCount,
    collectionCount,
    handleStartFresh,
    handleStartImport,
    transitionIsLoading,
    transitionIsError,
  }) => {
    const [startFresh, setStartFresh] = useState(true);

    const notesLabel = `${noteCount} ${inflect("note", noteCount)}`;
    const collectionsLabel = `${collectionCount} ${inflect("collection", collectionCount)}`;
    const combinedContentLabel = `${notesLabel}, ${collectionsLabel}`;

    const handleContinue = useCallback(() => {
      if (startFresh) {
        handleStartFresh();
      } else {
        handleStartImport();
      }
    }, [startFresh, handleStartFresh, handleStartImport]);

    return (
      <Container>
        <TitleText
          size={MdsTextSize.Large}
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Large}
        >
          Finish your upgrade to Mem 2.0
        </TitleText>
        <MdsText
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
        >
          As part of the upgrade, we gathered up any notes and collections you created with Mem 1.0.
          You can bring them over or start fresh in Mem 2.0.
        </MdsText>
        <MdsText
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
        >
          To complete your upgrade, choose how you&apos;d like to bring over your notes from 1.0:
        </MdsText>
        <ActionSection>
          <ButtonSection>
            <V2TransitionCardButton
              primaryLabel="Don't bring my notes over"
              secondaryLabel="Continue without anything from 1.0"
              imageSrc={v2TransitionFreshImage}
              onClick={() => setStartFresh(true)}
              isActive={startFresh}
            />
            <V2TransitionCardButton
              primaryLabel="Bring my notes over"
              secondaryLabel={combinedContentLabel}
              imageSrc={v2TransitionImportImage}
              onClick={() => setStartFresh(false)}
              isActive={!startFresh}
            />
          </ButtonSection>
        </ActionSection>
        <ContinueSection>
          {transitionIsError && (
            <MdsText color={mdsColors().red.x600}>Something went wrong. Please try again.</MdsText>
          )}
          <MdsButton
            variant={MdsButtonVariant.FilledDark}
            label="Continue"
            onClick={handleContinue}
            isLoading={transitionIsLoading}
          />
        </ContinueSection>
      </Container>
    );
  }
);

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "24px",
  maxWidth: 640,
  minWidth: 320,
});

const ActionSection = styled.div({
  maxWidth: 640,
  containerType: "inline-size",
  containerName: "actionSection",
});

const ButtonSection = styled.div({
  gap: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "nowrap",
  "@container actionSection (min-width: 540px)": {
    flexDirection: "row",
  },
});

const ContinueSection = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  justifyContent: "flex-end",
  alignItems: "center",
});

const TitleText = styled(MdsText)({
  letterSpacing: mdsLineSpacings().mediumLarge,
});
