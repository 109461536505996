import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { AccountProfileNavbarCardContent } from "./AccountProfileNavbarCardContent";
import { useProfileDropdownHandlers } from "@/components/layout/components/account-profile/useProfileDropdownHandlers";

export const AccountProfileNavbarCard = observer(({ onHide }: { onHide?: () => void }) => {
  const { store } = useAppStore();
  const featureFlags = store.spaceAccountFeatureFlags.featureFlags;
  const { profileDisplayName, profileEmailAddress, profilePhotoUrl } = store.account.myAccount;
  const { syncState } = store.sync;
  const { isPreloadingDisabled: isNoteContentPreloadingDisabled } = store.noteContentDocuments;

  const isDebugMode = store.debug.debugModeEnabled;

  const handlers = useProfileDropdownHandlers(onHide);

  return (
    <AccountProfileNavbarCardContent
      isDebugMode={isDebugMode}
      accountInfo={{
        profilePhotoUrl,
        profileDisplayName,
        profileEmailAddress,
      }}
      handlers={handlers}
      syncInfo={{
        syncState,
        isNoteContentPreloadingDisabled,
      }}
      featureFlags={featureFlags}
    />
  );
});
