import { AppStore } from "@/store/AppStore";
import { Dexie } from "dexie";

export const searchUploadedFileBatches = async ({
  store,
}: {
  store: AppStore;
}): Promise<string[]> => {
  // Get all uploaded file batches from the local table
  const indexes = (await store.uploadedFileBatches.localTable
    .where("model_id")
    .between(Dexie.minKey, Dexie.maxKey)
    .keys()) as unknown as [model_id: string][];

  return indexes.map(index => index[0]);
};
