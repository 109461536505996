import { MdsIconKind } from "@/design-system/components/icon";
import { MdsShortcutKeyCharacter } from "@/design-system/components/shortcut-key/types";
import { ReactNode } from "react";

export interface MdsMenuItemProps {
  onClick?: () => void;
  size: MdsMenuItemSize;
  title: string;
  subtitle?: string;
  isShared?: boolean;
  icon?: MdsIconKind | ReactNode | ((size: number) => ReactNode);
  isSelected?: boolean;
  selectionVariant?: MdsMenuItemSelectionVariant;
  shortcutKeys?: MdsShortcutKeyCharacter[];
  rightLabel?: string;
}

export enum MdsMenuItemSize {
  Small = "Small",
  Medium = "Medium",
}

export enum MdsMenuItemSelectionVariant {
  Checkmark = "Checkmark",
  RadioButton = "RadioButton",
}
