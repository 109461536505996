import { useMemo, useState } from "react";
import {
  MdsDropdown,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
  MdsDropdownProps,
} from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import { MdsMenuItemSize } from "@/design-system/components/menu-item/types";
import { MdsMenuItem } from "@/design-system/components/menu-item/MdsMenuItem";
import { MdsIconKind } from "@/design-system/components/icon";
import { ToggleButton } from "@/components/filters/ToggleButton/ToggleButton";
import { pluralize } from "@/modules/pluralize";
import { NoteMediaKind } from "@/store/note/types";
import {
  MediaKindsFacetFilterItem,
  MediaKindsFacetFilterItemKind,
} from "@/components/filters/MediaKindsFacetFilter/types";

interface MediaKindsFacetFilterProps
  extends Omit<MdsDropdownProps, "contentList" | "isOpen" | "onOpenChange" | "children"> {
  items: MediaKindsFacetFilterItem[];
  onSelectItem: (id: NoteMediaKind) => void;
  onSelectClear: () => void;
  selectedItems: NoteMediaKind[];
}

export const MediaKindsFacetFilter = ({
  items,
  onSelectItem,
  onSelectClear,
  ...dropdownProps
}: MediaKindsFacetFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedItems = items.filter(item => item.isSelected);
  const hasSelectedItems = selectedItems.length > 0;

  const computedButtonLabel = useMemo(() => {
    const count = selectedItems.length;

    if (selectedItems.length === 1) {
      return `Contains ${selectedItems[0].label.toLowerCase()}`;
    }

    if (count > 1) {
      return `Contains ${selectedItems[0].label.toLowerCase()} or ${count - 1} ${pluralize(
        count - 1,
        "other",
        "others"
      )}`;
    }

    return "Contains";
  }, [selectedItems]);

  const contentListItems: MdsDropdownItem[] = useMemo(() => {
    const output: MdsDropdownItem[] = [];

    for (const item of items) {
      if (item.kind === MediaKindsFacetFilterItemKind.Label) {
        output.push({
          kind: MdsDropdownItemKind.Detail,
          id: item.id,
          text: item.label,
        });
      }

      if (item.kind === MediaKindsFacetFilterItemKind.MediaKind) {
        output.push({
          kind: MdsDropdownItemKind.Other,
          id: item.id,
          content: (
            <MdsMenuItem
              title={item.label}
              size={MdsMenuItemSize.Small}
              icon={item.data.icon}
              isSelected={!!item.isSelected}
              onClick={() => onSelectItem(item.data.mediaKind)}
            />
          ),
        });
      }
    }
    return output;
  }, [items, onSelectItem]);

  const contentList: MdsDropdownContentList = useMemo(
    () => ({ items: contentListItems }),
    [contentListItems]
  );

  return (
    <div data-test-id="media-kinds-facet-filter">
      <MdsDropdown
        contentList={contentList}
        {...dropdownProps}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        innerStyles={{
          Row: { className: rowStyles },
          Content: { className: contentStyles },
        }}
      >
        <ToggleButton
          setIsOpen={setIsOpen}
          iconKind={MdsIconKind.Shapes}
          hasSelectedItems={hasSelectedItems}
          computedButtonLabel={computedButtonLabel}
          onClear={() => {
            onSelectClear();
          }}
        />
      </MdsDropdown>
    </div>
  );
};

const rowStyles = css({
  overflow: "visible",
});

const contentStyles = css({
  maxHeight: 320,
  minWidth: 176,
});
