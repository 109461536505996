import { Uuid } from "@/domains/global/identifiers";
import { makeObservable, observable } from "mobx";

export class AccountTransitionContentInfoObservable {
  noteIds: Uuid[];
  collectionIds: Uuid[];

  constructor({ noteIds, collectionIds }: { noteIds: Uuid[]; collectionIds: Uuid[] }) {
    this.noteIds = noteIds;
    this.collectionIds = collectionIds;

    makeObservable(this, {
      noteIds: observable,
      collectionIds: observable,
    });
  }
}
