import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Draggable } from "@hello-pangea/dnd";

import { css, cx } from "@/domains/emotion";
import { MdsDraggableListItem } from "@/design-system/components/draggable-list/types";
import { MdsDraggableItemContent } from "@/design-system/components/draggable-list/MdsDraggableItemContent";
import { mdsDropdownButtonItemBaseStyles } from "@/design-system/components/dropdown/MdsDropdownButtonItemComponent";
import { mdsColors } from "@/design-system/foundations";

export interface MdsDraggableItemProps {
  item: MdsDraggableListItem;
  draggableIndex: number;
}

export const MdsDraggableItem = observer<MdsDraggableItemProps>(function MdsDraggableItem({
  item,
  draggableIndex,
}) {
  return (
    <Draggable key={item.id} draggableId={item.id} index={draggableIndex}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={cx(
            /**
             * We share the default base styles with MdsDropdownButtonItemComponent
             */
            mdsDropdownButtonItemBaseStyles,
            classNames(draggableItemStyles, snapshot.isDragging && "dragging"),
            item.isActive && isActiveStyles
          )}
          onClick={e => {
            e.stopPropagation();
            item.onClick?.({ event: e });
          }}
        >
          <MdsDraggableItemContent item={item} />
        </div>
      )}
    </Draggable>
  );
});

const draggableItemStyles = css({
  padding: "6px 8px 6px 10px",
  display: "flex",
  gap: "8px",
  position: "relative",
  borderRadius: "8px",
  cursor: "grab",
  userSelect: "none",
  // Undo transition set by mdsDropdownButtonItemBaseStyles to prevent flickering
  transition: "0s",

  "&.dragging": {
    boxShadow: "rgba(0, 0, 0, 0.16) 0 8px 16px",
    background: "#FFFFFF",
    cursor: "grabbing",

    "> .grip": {
      display: "block",
    },
  },

  "&:hover": {
    background: mdsColors().lilac.x50,

    "> .grip": {
      display: "block",
    },
  },

  "> .grip": {
    display: "none",
    position: "absolute",
    right: "0",

    svg: {
      color: "#9397a5",
      lineHeight: "normal",
      fontSize: "inherit",
      transform: "scale(0.9)",
    },
  },
});

const isActiveStyles = css({
  backgroundColor: mdsColors().grey.x50,
});
