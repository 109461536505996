/**
 * Because we coordinate imports using APIs + Sync Models, we are
 * using a "minimum" amount of time that we treat imports as being
 * in-progress before we show the "real" status.
 *
 * (The goal being that the import sync model should sync from the server to
 * the client before we show the "real" status.)
 *
 * We add some extra buffer our "standard" sync polling speed of 60 seconds.
 */
export const MINIMUM_IMPORT_DURATION_SECONDS = 90;
