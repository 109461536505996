import { useAppStore } from "@/store";
import { useState, useEffect, useRef } from "react";

interface TitledConversation {
  conversationId: string;
  title: string;
  timestamp: number;
}

/**
 * Get a list of conversations with titles sorted by the latest user message in the conversation.
 *
 * @returns Array of conversations containing the conversation ID, title (first user message content),
 * and timestamp of the latest user message, sorted by most recent message first
 */
export const useTitledConversations = (): TitledConversation[] => {
  const { store } = useAppStore();
  const [convoList, setConvoList] = useState<TitledConversation[]>([]);
  const mostRecentMessageIdRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    const isStoreLoaded = store.chatConversations.idsLoaded;
    const storeConvoLen = store.chatConversations.all.length;
    const storeIdLen = store.chatConversations.ids.size;
    if (!isStoreLoaded || storeConvoLen !== storeIdLen) {
      // we aren't ready to do this work yet
      // not only does this skip the work below, but using these observables appears to make this hook more stable
      // TODO (Rick): refactor this out of hook form and into a store observable
      return;
    }

    if (store.chatMessages.lastUserMessageId === mostRecentMessageIdRef.current) {
      // if there isn't a new user message, we can just skip all this work
      // this work is expensive, so we don't want to do it unless we have to
      return;
    } else {
      mostRecentMessageIdRef.current = store.chatMessages.lastUserMessageId;
    }
    const conversations = store.chatConversations.all;
    const promises: Promise<TitledConversation>[] = [];

    // for every conversation in the store, get a Promise for a TitledConversation object
    conversations.forEach(convo => {
      // filter all messages down to the ones in this conversation
      const convoMessages = store.chatMessages.sortedChatMessageIndexes.filter(
        idx => idx[0] === convo.id && !idx[2]
      );

      // get the first and last user messages in the conversation
      const firstUserMsg = convoMessages
        .sort((a, b) => new Date(a[1]).valueOf() - new Date(b[1]).valueOf())
        .slice(0, 1);
      const lastUserMsg = convoMessages
        .sort((a, b) => new Date(b[1]).valueOf() - new Date(a[1]).valueOf())
        .slice(0, 1);

      if (firstUserMsg.length === 1) {
        // if the conversation has a user message, add a promise to get the message content
        const messageId = firstUserMsg[0][6];
        const promise = store.chatMessages.getAsync(messageId).then(
          (message): TitledConversation => ({
            conversationId: convo.id,
            title: message?.plainTextContent ?? "New chat",
            timestamp: new Date(lastUserMsg[0][1]).valueOf(),
          })
        );
        promises.push(promise);
      } else {
        // if the conversation has no user message, add a resolved promise for the convo
        promises.push(
          Promise.resolve({
            conversationId: convo.id,
            title: "New chat",
            timestamp: new Date(convo.locallyCreatedAt).valueOf(),
          })
        );
      }
    });

    // when all promises are resolved, sort the conversations by timestamp and set the state
    Promise.all(promises).then(results => {
      const resolvedConversations: TitledConversation[] = [];
      results.forEach(result => {
        resolvedConversations.push(result);
      });
      resolvedConversations.sort((a, b) => b.timestamp - a.timestamp);
      setConvoList(resolvedConversations);
    });
  }, [
    store.chatMessages.sortedChatMessageIndexes,
    store.chatConversations.all,
    store.chatMessages,
    store.chatConversations.idsLoaded,
    store.chatConversations.ids.size,
  ]);

  return convoList;
};
