import {
  makeObservable,
  observable,
  computed,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { UploadedFileBatchModelData } from "./types";
import { UploadedFileBatchObservable } from "./UploadedFileBatchObservable";
import { SyncUpdateValue } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription } from "dexie";
import { searchUploadedFileBatches } from "@/store/uploaded-files/search-uploaded-file-batches";
import { CreateUploadedFileBatchOperation } from "@/store/sync/operations/uploaded-file/CreateUploadedFileBatchOperation";
import { uuidModule } from "@/modules/uuid";

export class AppStoreUploadedFileBatchStore extends BaseSyncModelStore<
  UploadedFileBatchObservable,
  UploadedFileBatchModelData
> {
  liveQuerySubscription: Maybe<Subscription>;
  uploadedFileBatches: UploadedFileBatchObservable[] = [];
  private initialLoaded: boolean = false;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.UploadedFileBatch, ...injectedDeps });
    makeObservable<
      this,
      "subscribeLiveQuery" | "unsubscribeLiveQuery" | "setIsReady" | "initialLoaded"
    >(this, {
      searchUploadedFileBatches: true,
      createSyncModel: false,

      uploadedFileBatches: observable,
      liveQuerySubscription: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      initialLoaded: observable,
      setIsReady: action,
      isReady: computed,
      createUploadedFileBatch: action,
    });

    onBecomeObserved(this, "uploadedFileBatches", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "uploadedFileBatches", () => this.unsubscribeLiveQuery());
    onBecomeObserved(this, "isReady", () => this.subscribeLiveQuery());
  }

  get isReady() {
    return this.initialLoaded;
  }

  createSyncModel(data: SyncUpdateValue<UploadedFileBatchModelData>): UploadedFileBatchObservable {
    return new UploadedFileBatchObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public searchUploadedFileBatches = async (): Promise<UploadedFileBatchObservable[]> => {
    const ids = await Dexie.waitFor(searchUploadedFileBatches({ store: this.store }));
    return this.getMany(ids);
  };

  /**
   * Creates a new uploaded file batch.
   *
   * @param config Optional configuration JSON for the batch
   * @returns The ID of the newly created file batch
   */
  public async createUploadedFileBatch({
    config = {},
  }: {
    config?: Record<string, unknown>;
  } = {}): Promise<string> {
    const batchId = uuidModule.generate();

    await new CreateUploadedFileBatchOperation({
      store: this.store,
      payload: {
        id: batchId,
        config_json: config,
        processing_started_at: null,
        processing_ended_at: null,
      },
    }).execute();

    return batchId;
  }

  private subscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() => {
      return this.searchUploadedFileBatches();
    }).subscribe({
      next: items => {
        runInAction(() => {
          this.uploadedFileBatches = items;
          this.setIsReady(true);
        });
      },
    });
  }

  private unsubscribeLiveQuery() {
    this.setIsReady(false);
    this.liveQuerySubscription?.unsubscribe();
  }

  private setIsReady(value: boolean) {
    this.initialLoaded = value;
  }
}
