import { logger } from "@/modules/logger";
import { urlParamsModule } from "@/modules/url-params";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { uuidModule } from "@/modules/uuid";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import { SavedSearchObservable } from "@/store/saved-searches/SavedSearchObservable";
import {
  SavedSearchDeletedSyncUpdateValue,
  SavedSearchModelData,
} from "@/store/saved-searches/types";
import {
  BaseSyncOperation,
  BaseSyncOperationParams,
} from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IRemoveSavedSearchFromFavoritesOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";

export interface RemoveSavedSearchFromFavoritesOperationParams
  extends BaseSyncOperationParams<IRemoveSavedSearchFromFavoritesOperation> {
  savedSearchQueryString: string;
}

export class RemoveSavedSearchFromFavoritesOperation extends BaseSyncOperation<IRemoveSavedSearchFromFavoritesOperation> {
  private savedSearch?: SavedSearchObservable;
  private favoriteItem?: FavoriteItemObservable;
  private savedSearchQueryString: string;

  get operationKind(): SyncOperationKind {
    return "REMOVE_SAVED_SEARCH_FROM_FAVORITES";
  }

  get favoriteItemId() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    return uuidModule.resolveFavoriteItemSyncModelUuid({
      spaceAccountId,
      itemId: this.payload.saved_search_id,
    });
  }

  constructor({
    savedSearchQueryString,
    ...params
  }: RemoveSavedSearchFromFavoritesOperationParams) {
    super(params);
    this.savedSearchQueryString = savedSearchQueryString;
  }

  get savedSearchParams(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.savedSearchQueryString,
    });
  }

  get savedSearchLabel() {
    return this.savedSearchParams.queryString;
  }

  get successToastMessage() {
    return <>Removed “{this.savedSearchLabel}” from your pins</>;
  }

  async execute() {
    this.savedSearch = await this.store.savedSearches.getAsync(this.payload.saved_search_id);
    this.favoriteItem = await this.store.favoriteItems.getAsync(this.favoriteItemId);

    await super.execute();
  }

  public async generateOptimisticUpdates() {
    const optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData | SavedSearchModelData>[] =
      [];

    const favoriteItemDeletedUpdate = this.generateOptimisticFavoriteItemDeletedUpdate();
    const savedSearchDeletedUpdate = this.generateOptimisticSavedSearchDeletedUpdate();

    if (favoriteItemDeletedUpdate) {
      optimisticUpdates.push(favoriteItemDeletedUpdate);
    }

    if (savedSearchDeletedUpdate) {
      optimisticUpdates.push(savedSearchDeletedUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.favoriteItems.recompute(this.favoriteItemId);
    await this.store.savedSearches.recompute(this.payload.saved_search_id);
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(this.getToastMessage(), SyncErrorHandlingType.RetryWithLimit);
  }

  protected getToastMessage() {
    return `Added “${this.savedSearchLabel}” search results could not be removed from your pins. If this error continues, please contact support.`;
  }

  private generateOptimisticFavoriteItemDeletedUpdate() {
    const favoriteItemObservable = this.favoriteItem;
    if (!favoriteItemObservable) {
      logger.debug({
        message: "Attempt to delete favorite item that does not exist",
        info: { favoriteItemId: this.payload.saved_search_id },
      });

      return;
    }

    const favoriteItemDeletedValue: FavoriteItemDeletedSyncUpdateValue = {
      model_id: favoriteItemObservable.id,
      model_kind: "FAVORITE_ITEM",
      model_version: favoriteItemObservable.modelVersion,
    };
    const favoriteItemDeletedUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: favoriteItemDeletedValue,
    };

    return favoriteItemDeletedUpdate;
  }

  private generateOptimisticSavedSearchDeletedUpdate() {
    const savedSearchObservable = this.savedSearch;
    if (!savedSearchObservable) {
      logger.debug({
        message: "Attempt to delete saved search that does not exist",
        info: { savedSearchId: this.payload.saved_search_id },
      });

      return;
    }

    const savedSearchDeletedValue: SavedSearchDeletedSyncUpdateValue = {
      model_id: this.payload.saved_search_id,
      model_kind: "SAVED_SEARCH",
      model_version: savedSearchObservable.modelVersion,
    };

    const savedSearchDeletedUpdate: OptimisticSyncUpdate<SavedSearchModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value: savedSearchDeletedValue,
    };

    return savedSearchDeletedUpdate;
  }
}
