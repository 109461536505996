import { MemCommonEditorStore } from "@/components/note/store/MemCommonEditorStore";
import { observer } from "mobx-react-lite";
import { css, cx } from "@/domains/emotion";
import styled from "@emotion/styled";
import { mdsColors } from "@/design-system/foundations";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { NoteType } from "@/components/note/types";
import { MdsButtonShape, MdsButtonVariant } from "@/design-system/components/button/types";
import { TemplateVariableCode } from "@/store/templates/types";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { useAppStore } from "@/store";
import { MemCommonEditorActionKind } from "@mem-labs/common-editor";
import { MdsButton, MdsButtonProps } from "@/design-system/components/button";
import {
  MdsHoverContent,
  MdsHoverContentPlacement,
} from "@/design-system/components/hover-content/MdsHoverContent";
import { UNTITLED_TEMPLATE_TITLE } from "@/domains/untitled/untitled";
import { trackEvent } from "@/domains/metrics";
import { TrackedEvent } from "@/domains/metrics";
import { FloatingActions } from "@/components/floating-actions/FloatingActions";

interface EditorActionsProps {
  editorStore: MemCommonEditorStore;
}

export const EditorActions = observer<EditorActionsProps>(({ editorStore }) => {
  const { store, pageStore } = useAppStore();

  const isOpen =
    !!editorStore.noteObservable &&
    (editorStore.noteType !== NoteType.Note || editorStore.isNoteEmpty);

  const variables = store.templates.getVariables();

  return (
    <FloatingActions isOpen={isOpen}>
      <div className={cx(boxStyle)}>
        {editorStore.noteType === NoteType.Template && (
          <>
            <Label>Add variable</Label>
            <Variables
              label="Names"
              iconKind={MdsIconKind.User}
              contentList={{
                items: [
                  {
                    id: "header",
                    kind: MdsDropdownItemKind.Detail,
                    text: "Insert template user’s name",
                  },
                  {
                    id: TemplateVariableCode.UserFirstName,
                    kind: MdsDropdownItemKind.Button,
                    label: "First Name",
                    isChecked: false,
                    iconKind: MdsIconKind.User,
                    content: () => (
                      <DropdownItemContentContent>
                        <DropdownItemContentTitle>
                          <span>First name</span>
                        </DropdownItemContentTitle>
                        <DropdownItemContentSubtitle>
                          {variables[TemplateVariableCode.UserFirstName].value}
                        </DropdownItemContentSubtitle>
                      </DropdownItemContentContent>
                    ),
                    onClick: () => {
                      editorStore.dispatchAction({
                        kind: MemCommonEditorActionKind.InsertTemplateVariable,
                        payload: {
                          code: TemplateVariableCode.UserFirstName,
                          displayName: variables[TemplateVariableCode.UserFirstName].displayName,
                        },
                      });
                    },
                  },
                  {
                    id: TemplateVariableCode.UserName,
                    kind: MdsDropdownItemKind.Button,
                    label: "Full Name",
                    isChecked: false,
                    iconKind: MdsIconKind.User,
                    content: () => (
                      <DropdownItemContentContent>
                        <DropdownItemContentTitle>
                          <span>Full name</span>
                        </DropdownItemContentTitle>
                        <DropdownItemContentSubtitle>
                          {variables[TemplateVariableCode.UserName].value}
                        </DropdownItemContentSubtitle>
                      </DropdownItemContentContent>
                    ),
                    onClick: () => {
                      editorStore.dispatchAction({
                        kind: MemCommonEditorActionKind.InsertTemplateVariable,
                        payload: {
                          code: TemplateVariableCode.UserName,
                          displayName: variables[TemplateVariableCode.UserName].displayName,
                        },
                      });
                    },
                  },
                ],
              }}
            />
            <Variables
              label="Dates"
              iconKind={MdsIconKind.Calendar}
              contentList={{
                items: [
                  {
                    id: "header",
                    kind: MdsDropdownItemKind.Detail,
                    text: "Insert date",
                  },
                  {
                    id: TemplateVariableCode.DateToday,
                    kind: MdsDropdownItemKind.Button,
                    label: "Today",
                    isChecked: false,
                    iconKind: MdsIconKind.Calendar,
                    content: () => (
                      <DropdownItemContentContent>
                        <DropdownItemContentTitle>
                          <span>Today</span>
                        </DropdownItemContentTitle>
                        <DropdownItemContentSubtitle>
                          {variables[TemplateVariableCode.DateToday].value}
                        </DropdownItemContentSubtitle>
                      </DropdownItemContentContent>
                    ),
                    onClick: () => {
                      editorStore.dispatchAction({
                        kind: MemCommonEditorActionKind.InsertTemplateVariable,
                        payload: {
                          code: TemplateVariableCode.DateToday,
                          displayName: variables[TemplateVariableCode.DateToday].displayName,
                        },
                      });
                    },
                  },
                ],
              }}
            />
          </>
        )}
        {editorStore.noteType === NoteType.Note && editorStore.isNoteEmpty && (
          <>
            <Label>Use template</Label>
            {!store.templates.ids.size && (
              <MdsButton
                variant={MdsButtonVariant.Outlined}
                label="Create template"
                iconKind={MdsIconKind.Plus}
                onClick={() => {
                  pageStore.templatesListPage.handleCreateNewTemplate();
                }}
              />
            )}
            {store.templates.sortedRecentTemplatesInteractedWithByMe
              .slice(0, 2)
              .map(({ template }) => (
                <MdsButton
                  key={template.id}
                  variant={MdsButtonVariant.Outlined}
                  label={template.title || UNTITLED_TEMPLATE_TITLE}
                  iconKind={MdsIconKind.Template}
                  onClick={async () => {
                    const templateContents = await template.getTemplateContentsForInsertionAsync();
                    if (!templateContents) return;

                    const { remoteContent, documentUpdates } = templateContents;

                    editorStore.dispatchAction({
                      kind: MemCommonEditorActionKind.InsertTemplate,
                      payload: {
                        templateId: template.id,
                        templateRemoteContent: remoteContent,
                        templateDocumentUpdates: documentUpdates,
                        templateVariables: Object.entries(store.templates.getVariables()).map(
                          ([code, variable]) => ({
                            code,
                            value: variable.value,
                          })
                        ),
                      },
                    });
                    trackEvent(TrackedEvent.TemplateUsed, {
                      template_name: template.title || UNTITLED_TEMPLATE_TITLE,
                      context: "floating_bar",
                    });
                  }}
                />
              ))}
            {store.templates.ids.size > 0 && (
              <Variables
                label={null}
                iconKind={MdsIconKind.More}
                contentList={{
                  items: [
                    ...store.templates.sortedRecentTemplatesInteractedWithByMe.slice(2).map(
                      ({ template }): MdsDropdownItem => ({
                        id: template.id,
                        kind: MdsDropdownItemKind.Button,
                        label: template.title || UNTITLED_TEMPLATE_TITLE,
                        iconKind: MdsIconKind.Template,
                        onClick: async () => {
                          const templateContents =
                            await template.getTemplateContentsForInsertionAsync();
                          if (!templateContents) return;

                          const { remoteContent, documentUpdates } = templateContents;

                          editorStore.dispatchAction({
                            kind: MemCommonEditorActionKind.InsertTemplate,
                            payload: {
                              templateId: template.id,
                              templateRemoteContent: remoteContent,
                              templateDocumentUpdates: documentUpdates,
                              templateVariables: Object.entries(store.templates.getVariables()).map(
                                ([code, variable]) => ({
                                  code,
                                  value: variable.value,
                                })
                              ),
                            },
                          });
                          trackEvent(TrackedEvent.TemplateUsed, {
                            template_name: template.title || UNTITLED_TEMPLATE_TITLE,
                            context: "floating_bar",
                          });
                        },
                      })
                    ),
                    {
                      id: "divider",
                      kind: MdsDropdownItemKind.Divider,
                      className:
                        store.templates.sortedRecentTemplatesInteractedWithByMe.length <= 2
                          ? displayNone
                          : undefined,
                    },
                    {
                      id: "manage-templates",
                      kind: MdsDropdownItemKind.Button,
                      label: "Manage templates",
                      iconKind: MdsIconKind.GearCode,
                      onClick: () => {
                        store.navigation.goToTemplates();
                      },
                    },
                  ],
                }}
              />
            )}
          </>
        )}
      </div>
    </FloatingActions>
  );
});

const boxStyle = css({
  alignItems: "center",
  background: "white",
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: 8,
  boxShadow: `0px 6px 10px rgba(69, 78, 104, 0.2)`,
  display: "flex",
  gap: 8,
  justifyContent: "flex-start",
  padding: `10px 18px`,
  fontSize: 14,
});

const Label = styled.span(({ theme }) => ({
  color: mdsColors().grey.x600,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.semiBold,
}));

const Variables = ({
  label,
  iconKind,
  contentList,
}: {
  label: MdsButtonProps["label"];
  iconKind?: MdsButtonProps["iconKind"];
  contentList: MdsDropdownContentList;
}) => {
  return (
    <MdsHoverContent
      content={() => <MdsDropdownContent contentList={contentList} />}
      placement={MdsHoverContentPlacement.Above}
    >
      <MdsButton
        label={label}
        variant={MdsButtonVariant.Outlined}
        shape={MdsButtonShape.Square}
        iconKind={iconKind}
      />
    </MdsHoverContent>
  );
};

const DropdownItemContentContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const DropdownItemContentTitle = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.grey.x600,
  display: "flex",
  gap: theme.spacing.sm,
  fontSize: theme.fontSizes.small,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const DropdownItemContentSubtitle = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xsmall,
}));

const displayNone = css({
  display: "none",
});
