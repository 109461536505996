import { MdsDropdownContentList, MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { MdsDropdownContent } from "@/design-system/components/dropdown/MdsDropdownContent";
import { FC, useMemo } from "react";
import { SidebarAccountInfo } from "@/components/layout/components/sidebar/SidebarAccountInfo";
import styled from "@emotion/styled";
import { ProfileSize } from "@/components/layout/components/account-profile";
import {
  ProfileDropdownItemsHandlers,
  SyncInfo,
  useProfileDropdownItems,
} from "@/components/layout/components/account-profile/useProfileDropdownItems";

export type SidebarDropdownMenuContentHandlers = ProfileDropdownItemsHandlers;

export interface SidebarDropdownMenuContentProps {
  className?: string;
  featureFlags: Record<string, boolean>;
  handlers: SidebarDropdownMenuContentHandlers;
  isDebugMode?: boolean;
  accountInfo: {
    profilePhotoUrl: string | null;
    profileDisplayName: string;
    profileEmailAddress: string;
  };
  displayAccountInfo: boolean;
  syncInfo: SyncInfo;
}

export const SidebarDropdownMenuContent: FC<SidebarDropdownMenuContentProps> = ({
  className,
  accountInfo,
  displayAccountInfo,
  syncInfo,
  handlers,
  isDebugMode,
}) => {
  const profileDropdownItems = useProfileDropdownItems({
    isDebugMode,
    handlers,
    syncInfo,
  });

  const contentList = useMemo<MdsDropdownContentList>(() => {
    return {
      items: [
        {
          id: "account-info",
          kind: MdsDropdownItemKind.Other,
          content: displayAccountInfo ? (
            <AccountInfoWrapper>
              <SidebarAccountInfo
                profileSize={ProfileSize.Large}
                profilePhotoUrl={accountInfo.profilePhotoUrl}
                profileDisplayName={accountInfo.profileDisplayName}
                profileEmailAddress={accountInfo.profileEmailAddress}
              />
            </AccountInfoWrapper>
          ) : null,
        },
        ...profileDropdownItems,
      ],
    };
  }, [
    accountInfo.profileDisplayName,
    accountInfo.profileEmailAddress,
    accountInfo.profilePhotoUrl,
    displayAccountInfo,
    profileDropdownItems,
  ]);

  return <MdsDropdownContent className={className} contentList={contentList} />;
};

const AccountInfoWrapper = styled.div(({ theme }) => ({
  padding: theme.spacing.sm,
  borderBottom: `1px solid ${theme.colors.grey.x100}`,
}));
