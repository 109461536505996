import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateTemplateAclViaCollectionOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { TemplateModelData, TemplateUpsertedSyncUpdateValue } from "@/store/templates/types";

export class UpdateTemplateAclViaCollectionOperation extends BaseSyncOperation<IUpdateTemplateAclViaCollectionOperation> {
  get operationKind(): SyncOperationKind {
    return "UPDATE_TEMPLATE_ACL_VIA_COLLECTION";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;

    const existingTemplate = await this.store.templates.getAsync(templateId);
    if (!existingTemplate) return [];

    const value: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: existingTemplate.modelVersion,
      model_data: {
        ...existingTemplate.modelData,
        target_scopes: [
          ...existingTemplate.modelData.target_scopes,
          {
            scope_kind: "COLLECTION_SCOPE",
            role_kind: this.payload.role_kind,
            value: { collection_id: this.payload.collection_id },
          },
        ],
      },
      model_scopes: existingTemplate.modelScopes,
    };

    const syncUpdate: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
