import { IMDSSwitchProps, IMDSSwitchVariant } from "./switch.types";
import styled from "@emotion/styled";
import { Switch } from "react-aria-components";
import { Theme } from "@emotion/react";

const getVariantColor = ({ theme, variant }: { theme: Theme; variant?: string }) => {
  switch (variant) {
    case IMDSSwitchVariant.WHITE:
      return theme.colors.grey.x0;
    case IMDSSwitchVariant.BLACK:
      return theme.colors.grey.x900;
    default:
      return theme.colors.grey.x0;
  }
};

export const StyledSwitch = styled(Switch)<Pick<IMDSSwitchProps, "variant">>`
  display: flex;
  align-items: center;
  gap: 0.571rem;
  font-size: 1.143rem;
  color: ${getVariantColor};
  forced-color-adjust: none;

  .indicator {
    width: 2rem;
    height: 1.143rem;
    border: 2px solid ${getVariantColor};
    background: transparent;
    border-radius: 1.143rem;
    transition: all 200ms;

    &:before {
      content: "";
      display: block;
      margin: 1px;
      width: 0.75rem;
      height: 0.75rem;
      background: ${getVariantColor};
      border-radius: 16px;
      transition: all 200ms;
    }
  }

  &[data-pressed] .indicator {
    border-color: ${getVariantColor};

    &:before {
      background: ${getVariantColor};
    }
  }

  &[data-selected] {
    .indicator {
      border-color: ${getVariantColor};
      background: transparent;

      &:before {
        background: ${getVariantColor};
        transform: translateX(100%);
      }
    }

    &[data-pressed] {
      .indicator {
        border-color: ${getVariantColor};
        background: transparent;
      }
    }
  }

  &[data-focus-visible] .indicator {
    outline: 2px solid ${({ theme }) => theme.colors.blue.x400};
    outline-offset: 2px;
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.grey.x200};

    .indicator {
      border-color: ${({ theme }) => theme.colors.grey.x200};
      background-color: ${({ theme }) => theme.colors.grey.x0};

      &:before {
        background: ${({ theme }) => theme.colors.grey.x200};
      }
    }
  }
`;
