import { useMemo, useState } from "react";
import {
  MdsDropdown,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
  MdsDropdownProps,
} from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import {
  MdsMenuItemSelectionVariant,
  MdsMenuItemSize,
} from "@/design-system/components/menu-item/types";
import { MdsMenuItem } from "@/design-system/components/menu-item/MdsMenuItem";
import { ToggleButton } from "@/components/filters/ToggleButton/ToggleButton";
import { DateFacetFilterItem, RangeValue } from "@/components/filters/DateFacetFilter/types";
import { MDSDateRangePicker } from "@/mds";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { DateTime } from "luxon";

interface Props
  extends Omit<MdsDropdownProps, "contentList" | "isOpen" | "onOpenChange" | "children"> {
  items: DateFacetFilterItem[];
  selectedRange?: RangeValue;
  onClear: () => void;
}

export const DateFacetFilter = ({ items, onClear, selectedRange, ...dropdownProps }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedItems = items.filter(item => item.type !== "any" && item.isSelected);

  const computedButtonLabel = useMemo(() => {
    if (!selectedItems.length) {
      return "Date";
    }

    if (selectedRange) {
      return `From ${DateTime.fromISO(selectedRange.start.toString()).toFormat("MMM d, yyyy")} to ${DateTime.fromISO(selectedRange.end.toString()).toFormat("MMM d, yyyy")}`;
    }

    return `${selectedItems[0].title}`;
  }, [selectedItems, selectedRange]);

  const contentList: MdsDropdownContentList = useMemo(
    () => ({
      items: getContentListItems(items, selectedRange),
    }),

    [items, selectedRange]
  );

  return (
    <div data-test-id="date-facet-filter">
      <MdsDropdown
        isOpen={isOpen}
        {...dropdownProps}
        contentList={contentList}
        onOpenChange={setIsOpen}
        innerStyles={{
          Row: { className: rowStyles },
          Content: { className: contentStyles },
        }}
      >
        <ToggleButton
          setIsOpen={setIsOpen}
          iconKind={MdsIconKind.CalendarDay}
          hasSelectedItems={selectedItems.length > 0}
          computedButtonLabel={computedButtonLabel}
          onClear={onClear}
        />
      </MdsDropdown>
    </div>
  );
};

const getContentListItems = (
  items: DateFacetFilterItem[],
  selectedRange: RangeValue | undefined
) => {
  const output: MdsDropdownItem[] = [];

  for (const item of items) {
    if (item.type === "any" || item.type === "older-than") {
      output.push({
        kind: MdsDropdownItemKind.Other,
        id: item.title,
        content: (
          <MdsMenuItem
            title={item.title}
            size={MdsMenuItemSize.Small}
            isSelected={!!item.isSelected}
            selectionVariant={MdsMenuItemSelectionVariant.Checkmark}
            onClick={() => {
              item.onSelect({ type: item.type });
            }}
          />
        ),
      });

      continue;
    }

    if (item.type === "custom-range") {
      output.push({
        kind: MdsDropdownItemKind.Divider,
        id: "divider",
      });
      output.push({
        kind: MdsDropdownItemKind.Other,
        id: item.title,
        content: (
          <DateRangePickerWrapper>
            <MDSDateRangePicker
              label={item.title}
              value={selectedRange || null}
              onChange={range => {
                item.onSelect({
                  type: item.type,
                  from: range?.start || undefined,
                  to: range?.end || undefined,
                });
              }}
            />
          </DateRangePickerWrapper>
        ),
      });
    }
  }

  return output;
};

const rowStyles = css({
  overflow: "visible",
});

const contentStyles = css({
  maxHeight: 320,
  minWidth: 176,
});

const DateRangePickerWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.sm};
`;
