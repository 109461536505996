import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors, mdsLineSpacings } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { APP_CONFIG } from "@/domains/global/config";
import { TrackedEvent, trackEvent } from "@/domains/metrics";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { windowModule } from "@/modules/window";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const SyncStoreInitializationChecker = observer(function SyncStoreInitializationChecker() {
  const { store } = useAppStore();

  const [isOpen, setIsOpen] = useState(store.sync.initializationFailed);

  const [channel, setChannel] = useState<BroadcastChannel>();

  useEffectOnMount(() => {
    const channel = new BroadcastChannel("initialization-failed");
    setChannel(channel);

    channel.onmessage = event => {
      const isFailed = event.data;
      if (isFailed) setIsOpen(true);
    };
  });

  useEffect(() => {
    if (!store.sync.initializationFailed) return;

    setIsOpen(true);
    channel?.postMessage(true);
    trackEvent(TrackedEvent.AppStoreInitErrorShown);
  }, [channel, store.sync.initializationFailed]);

  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={isOpen}
      handleCloseModal={noop}
      className={modalStyles}
    >
      <Inner>
        <MdsText
          size={MdsTextSize.Large}
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Large}
          className={css({
            letterSpacing: mdsLineSpacings().mediumLarge,
          })}
        >
          Something went wrong
        </MdsText>
        <MdsText
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
        >
          Please reload to continue. If this persist, please contact support.
        </MdsText>
        <Buttons>
          <MdsButton
            size={MdsButtonSize.Small}
            variant={MdsButtonVariant.TextTertiary}
            label="Support"
            onClick={() =>
              windowModule.openInNewTab({ url: new URL(APP_CONFIG.URLS.HELP_AND_SUPPORT) })
            }
          />
          <MdsButton
            size={MdsButtonSize.Small}
            variant={MdsButtonVariant.Outlined}
            label="Reload"
            onClick={() => windowModule.forceReload()}
          />
        </Buttons>
      </Inner>
    </MdsModal>
  );
});

const modalStyles = css({
  zIndex: ZIndex.AppStoreCrashNotice,
});

const Inner = styled.div(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.md,
  justifyContent: "space-between",
  padding: theme.spacing.lg,
}));

const Buttons = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.md,
  justifyContent: "flex-end",
  width: "100%",
}));
