import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { ZIndex } from "@/domains/design/constants";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { clientEnvModule } from "@/modules/client-env";
import { logger } from "@/modules/logger";
import { windowModule } from "@/modules/window";
import styled from "@emotion/styled";
import { useState } from "react";

export const AppVersionHandling = () => {
  const [reloadRequired, setReloadRequired] = useState(false);

  useEffectOnMount(() => {
    const myBuildInfo = clientEnvModule.buildInfo().buildInfo.buildTimestamp.toISO();
    logger.info({
      message: `[app-version] Current build: ${myBuildInfo}`,
    });

    const channel = new BroadcastChannel("app-version");
    channel.postMessage({ buildInfo: myBuildInfo });

    channel.onmessage = event => {
      const { buildInfo } = event.data;
      if (buildInfo && buildInfo !== myBuildInfo) {
        logger.info({
          message: `[app-version] Newer build available: ${buildInfo}`,
        });
        setReloadRequired(true);
      }
    };
    return () => channel.close();
  });

  if (!reloadRequired) return null;

  return (
    <NoticeCard>
      <MdsText size={MdsTextSize.XSmall}>
        A new version of Mem is available.
        <br />
        Please reload to continue.
      </MdsText>
      <ReloadButton
        size={MdsButtonSize.Small}
        variant={MdsButtonVariant.Outlined}
        label="Reload"
        onClick={() => windowModule.forceReload()}
      />
    </NoticeCard>
  );
};

const NoticeCard = styled.div(({ theme }) => ({
  alignItems: "flex-start",
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.grey.x25}`,
  borderRadius: theme.spacing.sm,
  bottom: 0,
  boxShadow: `0px 6px 10px rgba(69, 78, 104, 0.2)`,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.md,
  justifyContent: "space-between",
  margin: theme.spacing.xs,
  padding: theme.spacing.smd,
  position: "absolute",
  right: 0,
  zIndex: ZIndex.AppVersionNotice,
}));

const ReloadButton = styled(MdsButton)({
  alignSelf: "flex-end",
});
