import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { SearchPageHeader } from "@/pages/search/SearchPageHeader";
import { useAppStore } from "@/store";
import { ShareSheetModal } from "@/components/modal/share-sheet/ShareSheetModal";
import { SearchPageNotesList } from "./components/SearchPageNotesList";
import { MdsPanelContainer } from "@/design-system/components/panel";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { SearchPageFloaty } from "@/pages/search/components/SearchPageFloaty";

interface SearchPageProps {}

export const SearchPage: FC<SearchPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;
  page.listState.useListStateEffects();

  const queryString = page.filters.params.queryString;
  /**
   * Track views of the search page.
   */
  useEffect(() => {
    if (queryString) {
      trackEvent(TrackedEvent.SearchView, {
        query: queryString,
      });
    }
  }, [queryString]);

  return (
    <Wrapper data-test-id="search-page">
      <ShareSheetModal store={page.shareSheetModal} />
      <AddToCollectionModal store={page.addToCollectionModal} />
      <SearchPageHeader />
      <MdsPanelContainer>
        <SearchPageNotesList />
        {page.listState.multiSelectUi}
        <SearchPageFloaty />
      </MdsPanelContainer>
    </Wrapper>
  );
});

const Wrapper = styled.div({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});
