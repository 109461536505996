import { SortByKind } from "@/modules/lenses/types";
import { SearchPageDateParams } from "@/modules/filters/search/SearchPageFilter";
import {
  SearchEngineRequestFilter,
  ValidSearchSortByValue,
} from "@/store/pages/SearchPageStore/types";

export function convertDateParamsToSearchEngineFilters(
  dateParams: SearchPageDateParams | undefined,
  sortBy: ValidSearchSortByValue
): SearchEngineRequestFilter[] {
  const output: SearchEngineRequestFilter[] = [];
  if (!dateParams) return output;

  const { olderThan, from, to } = dateParams;

  if (olderThan) {
    const now = Date.now();
    const thresholds = {
      week: 7 * 24 * 60 * 60 * 1000,
      month: 30 * 24 * 60 * 60 * 1000,
      year: 365 * 24 * 60 * 60 * 1000,
    };
    const date = new Date(now - thresholds[olderThan]).toISOString();
    if (olderThan === "week") {
      if (sortBy === SortByKind.LastModified)
        output.push({ kind: "MODIFIED_BEFORE", value: { modified_before: date } });
      if (sortBy === SortByKind.LastCreated)
        output.push({ kind: "CREATED_BEFORE", value: { created_before: date } });
    }
    if (olderThan === "month") {
      if (sortBy === SortByKind.LastModified)
        output.push({ kind: "MODIFIED_BEFORE", value: { modified_before: date } });
      if (sortBy === SortByKind.LastCreated)
        output.push({ kind: "CREATED_BEFORE", value: { created_before: date } });
    }
    if (olderThan === "year") {
      if (sortBy === SortByKind.LastModified)
        output.push({ kind: "MODIFIED_BEFORE", value: { modified_before: date } });
      if (sortBy === SortByKind.LastCreated)
        output.push({ kind: "CREATED_BEFORE", value: { created_before: date } });
    }
  } else {
    if (sortBy === SortByKind.LastModified) {
      if (to) output.push({ kind: "MODIFIED_BEFORE", value: { modified_before: to } });
      if (from) output.push({ kind: "MODIFIED_AFTER", value: { modified_after: from } });
    }
    if (sortBy === SortByKind.LastCreated) {
      if (to) output.push({ kind: "CREATED_BEFORE", value: { created_before: to } });
      if (from) output.push({ kind: "CREATED_AFTER", value: { created_after: from } });
    }
  }

  return output;
}
