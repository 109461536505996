import { syntaxTreeModule } from "@mem-labs/common-editor";

export const copyNote = async (content: string) => {
  const html = syntaxTreeModule.convertMdxToHtml({
    mdxContent: content,
  });

  // const plaintext = syntaxTreeModule.convertHtmlToMarkdown({
  //   htmlContent: html,
  //   // We might want to preserve markdown formatting here.
  //   semanticalText: true,
  // });

  const item = new ClipboardItem({
    ["text/html"]: new Blob([html], { type: "text/html" }),
    ["text/plain"]: new Blob([content], { type: "text/plain" }),
    // Alternatively, we could use the real plaintext version.
    // ["text/plain"]: new Blob([plaintext], { type: "text/plain" }),
    // Unfortunately, browsers don't support this right now.
    // ["text/markdown"]: new Blob([content], { type: "text/markdown" }),
  });

  await navigator.clipboard.write([item]);
};
