import { components } from "@/__openapi__/backend/schema";
import { AsyncData } from "@/domains/async/AsyncData";
import { IndexedBoolean } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { EventContext } from "@/domains/metrics/context";
import {
  GrantableSyncScopeRoleKind,
  SyncModelPermissionEntryWithStatus,
  SyncModelScope,
} from "@/domains/sync-scopes/types";
import { ApiSchema } from "@/modules/api";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { NoteCollectionListObservable } from "@/store/collection-items/NoteCollectionListObservable";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { IContactModel } from "@/store/contacts/types";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { NoteContentDocumentIndexData } from "@/store/note/NoteContentDocumentIndexes";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import { NoteIndexData } from "@/store/note/NoteIndexes";
import { NoteQueueObservable } from "@/store/note/NoteQueueObservable";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import {
  IExternalOperation,
  ISyncOperation,
  ISyncOperationGuestMode,
} from "@/store/sync/operations/types";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  UpsertedSyncUpdateResponse,
  DeletedSyncUpdateResponse,
  SyncUpdateValue,
} from "@/store/sync/types";
import {
  ExtractedNoteMention,
  MemCommonEditorContext,
  MemCommonEditorFileInfo,
  MemCommonEditorImageInfo,
} from "@mem-labs/common-editor";
import { JsonValue } from "type-fest";

export type NoteModelData = ApiSchema["NoteSyncModelDataResponseSchema"];
export type NoteUpsertedSyncUpdateValue = ApiSchema["NoteSyncModelResponseSchema"];
export type NoteDeletedSyncUpdateValue = ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type NoteSyncUpdateValue = NoteUpsertedSyncUpdateValue | NoteDeletedSyncUpdateValue;

export type NoteUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  value: NoteUpsertedSyncUpdateValue;
};
export type NoteDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  value: NoteDeletedSyncUpdateValue;
};
export type NoteSyncUpdate = NoteUpsertedSyncUpdate | NoteDeletedSyncUpdate;

export type IndexedNoteSyncUpdateValue = SyncUpdateValue<NoteModelData> & NoteIndexData;

export type IndexedNoteContentDocumentSyncUpdateValue =
  SyncUpdateValue<NoteContentDocumentModelData> & NoteContentDocumentIndexData;

export interface INoteObservable {
  id: Uuid;
  modelVersion: number;
  modelData: NoteModelData;
  modelScopes: SyncModelScope[];
  remoteContent: string | null;
  queuedDocumentUpdates: { id: string; encodedContentDiff: string }[];
  grantedCollectionIds: string[];
  grantedSpaceAccountIds: string[];
  grantedEmailAddresses: string[];
  permissions: SyncModelPermissionEntryWithStatus[];
  title: string;
  createdAt: string;
  modifiedAt: string;
  locallyCreatedAt: string;
  isOwnedByMe: boolean;
  isFavorited: boolean;
  isTrashed: boolean;
  isDeleted: boolean;
  isAvailable: boolean;
  secondaryTitle: string;
  // TODO: might be worth moving to IBaseSyncModel
  sharedBy: ContactObservable | undefined;
  // TODO: might be worth moving to IBaseSyncModel
  path: string;
  // TODO: might be worth moving to IBaseSyncModel
  trashedAt: string | null;
  favoriteItem?: FavoriteItemObservable | undefined;
  primaryOwner: IContactModel | undefined;
  authors: IContactModel[];
  sharedWithLabels: string[];
  isShared: boolean;
  hasPendingShare: boolean;
  collectionList?: NoteCollectionListObservable;
  canAccess: boolean;
  canWrite: boolean;
  isNoteContentDocumentLoaded: boolean;
  lastViewedAt: string;
  receivedAt: string | null | undefined;
  lastMentionedAt: string | undefined;
  spaceAccountNote: SpaceAccountNoteObservable | undefined;
  memoryQueue: NoteQueueObservable | undefined;
  noteContentDocument: AsyncData<NoteContentDocumentObservable> | undefined;
  addToRecents(): void;
  toggleFavorite(): Promise<void>;
  delete(): Promise<void>;
  deleteEmptyNote(): Promise<void>;
  moveToTrash(args?: { triggerSuccessToast?: boolean }): Promise<void>;
  restoreFromTrash(args?: { triggerSuccessToast?: boolean }): Promise<void>;
  saveAsNewNote({ eventContext }: { eventContext: EventContext }): Promise<void>;
  updateContentUsingDiff(_: {
    encodedContentDiff: string | null;
    primaryLabel: string;
    secondaryLabel: string;
    mediaKinds?: NoteMediaKind[];
    transactionMetadata?: Record<string, JsonValue>;
    tiptapJsonContent?: string;
    mentions?: ExtractedNoteMention[];
    templateId?: string;
  }): void;
  grantAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }): Promise<void>;
  grantAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }): Promise<void>;
  updateAccessViaSpaceAccount({
    targetSpaceAccountId,
    roleKind,
  }: {
    targetSpaceAccountId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }): Promise<void>;
  updateAccessViaEmailAddress({
    targetEmailAddress,
    roleKind,
  }: {
    targetEmailAddress: string;
    roleKind: GrantableSyncScopeRoleKind;
  }): Promise<void>;
  updateAccessViaCollection({
    collectionId,
    roleKind,
  }: {
    collectionId: string;
    roleKind: GrantableSyncScopeRoleKind;
  }): Promise<void>;
  revokeAccessViaSpaceAccount({
    targetSpaceAccountId,
  }: {
    targetSpaceAccountId: string;
  }): Promise<void>;
  revokeAccessViaEmailAddress({
    targetEmailAddress,
  }: {
    targetEmailAddress: string;
  }): Promise<void>;
  uploadImageAssociatedWithNote({ info }: { info: MemCommonEditorImageInfo }): void;
  uploadFileAssociatedWithNote({ info }: { info: MemCommonEditorFileInfo }): void;
  generateCommonEditorContext(): MemCommonEditorContext;
  fetchNoteContentDocument(): Promise<void>;
}

export type NoteMediaKind = components["schemas"]["MediaKind"];

// NOTE CONTENT DOCUMENT
export type NoteContentDocumentModelData =
  ApiSchema["NoteContentDocumentSyncModelDataResponseSchema"];

export type NoteContentDocumentUpsertedSyncUpdateValue =
  ApiSchema["NoteContentDocumentSyncModelResponseSchema"];
export type NoteContentDocumentDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type NoteContentDocumentSyncUpdateValue =
  | NoteContentDocumentUpsertedSyncUpdateValue
  | NoteContentDocumentDeletedSyncUpdateValue;

export type NoteContentDocumentUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  value: NoteContentDocumentUpsertedSyncUpdateValue;
};
export type NoteContentDocumentDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  value: NoteContentDocumentDeletedSyncUpdateValue;
};
export type NoteContentDocumentSyncUpdate =
  | NoteContentDocumentUpsertedSyncUpdate
  | NoteContentDocumentDeletedSyncUpdate;

export type NotesSearchParams = {
  lens: NotesLensKind;
  sortBy: NotesSortByKind;
  filter?: NotesFilterParams;
  limit: number;
};

export type NotesIndexTuple =
  | [
      is_available: IndexedBoolean,
      is_owned_by_me: IndexedBoolean,
      datetime: string,
      model_id: string,
    ]
  | [is_available: IndexedBoolean, datetime: string, model_id: string];

export type NotesLensKind = LensKind.All | LensKind.AddedByMe | LensKind.SharedWithMe;

export type NotesSortByKind =
  | SortByKind.LastCreated
  | SortByKind.LastModified
  | SortByKind.LastViewed
  | SortByKind.Alphabetical
  | SortByKind.LastShared;

export type NotesFilterParams = {
  createdAt?: {
    from?: string;
    to?: string;
    olderThan?: NotesFilterOlderThan;
  };

  modifiedAt?: {
    from?: string;
    to?: string;
    olderThan?: NotesFilterOlderThan;
  };

  viewedAt?: {
    from?: string;
    to?: string;
    olderThan?: NotesFilterOlderThan;
  };

  sharedWithMeAt?: {
    from?: string;
    to?: string;
    olderThan?: NotesFilterOlderThan;
  };

  createdBy?: string[];
  modifiedBy?: string[];
  addedBy?: string[];
  collections?: NotesFilterCollections[];
  ids?: string[];
  mediaKinds?: NoteMediaKind[];
  fullTextQuery?: string;
};

export type NotesFilterCollections = {
  ids: string[];
  mode: CollectionsFilterMode;
};

export type NotesFilterOlderThan = "week" | "month" | "year";

/**
 * The mode to filter by.
 * - "all": Only include notes that are in all of the provided collections.
 * - "any": Include notes that are in any of the provided collections.
 * - "anyOrNone": Include notes that are in any of the provided collections or not in any collection.
 * - "none": Only include notes that are not in any collection
 */
export type CollectionsFilterMode = "all" | "any" | "anyOrNone" | "none";

export type NotesSearchMentionedInParams = {
  noteId: Uuid;
  sortBy?: NotesSortByKind;
  offset?: number;
  limit?: number;
};

export type MemoryQSyncOperation = BaseSyncOperationGeneric<
  ISyncOperation | ISyncOperationGuestMode | IExternalOperation
>;

// LIVE SPACE ACCOUNT NOTE SUGGESTIONS
export enum SpaceAccountNoteSuggestionKind {
  ADD_TO_NEW_COLLECTION = "ADD_TO_NEW_COLLECTION",
  ADD_TO_EXISTING_COLLECTION = "ADD_TO_EXISTING_COLLECTION",
  ASK_CHAT = "ASK_CHAT",
  SEE_RELATED_NOTE = "SEE_RELATED_NOTE",
}

export interface AddToNewCollectionSpaceAccountNoteSuggestion {
  kind: SpaceAccountNoteSuggestionKind.ADD_TO_NEW_COLLECTION;
  value: {
    collection_title: string;
  };
}

export interface AddToExistingCollectionSpaceAccountNoteSuggestion {
  kind: SpaceAccountNoteSuggestionKind.ADD_TO_EXISTING_COLLECTION;
  value: {
    collection_id: string;
  };
}

export interface AskChatSpaceAccountNoteSuggestion {
  kind: SpaceAccountNoteSuggestionKind.ASK_CHAT;
  value: {
    message: string;
  };
}

export interface SeeRelatedNoteSpaceAccountNoteSuggestion {
  kind: SpaceAccountNoteSuggestionKind.SEE_RELATED_NOTE;
  value: {
    note_id: string;
  };
}

export type SpaceAccountNoteSuggestion = {
  id: string;
} & (
  | AddToNewCollectionSpaceAccountNoteSuggestion
  | AddToExistingCollectionSpaceAccountNoteSuggestion
  | AskChatSpaceAccountNoteSuggestion
  | SeeRelatedNoteSpaceAccountNoteSuggestion
);
