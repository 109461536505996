import { BaseError } from "@/domains/errors";
import { Uuid } from "@/domains/global/identifiers";
import { api } from "@/modules/api";
import { MemCommonEditorFileInfo } from "@mem-labs/common-editor";
import axios, { AxiosProgressEvent, CancelToken } from "axios";

export interface UploadFileConfig {
  cancelToken?: CancelToken;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export const uploadFile = async ({
  info,
  blob,
  config,
}: {
  info: MemCommonEditorFileInfo;
  blob: Blob;
  config?: UploadFileConfig;
}): Promise<{ signedUrl: string }> => {
  const fileId = info.fileId;
  const fileName = info.normalizedFileName;
  const fileMimeType = info.fileMimeType;

  const data = await getSignedUrlFromBackend({ fileId, fileName, fileMimeType });

  const { signedUrl } = data;

  const uploadResult = await axios.put(signedUrl, blob, {
    headers: {
      "Content-Type": fileMimeType,
    },
    cancelToken: config?.cancelToken,
    onUploadProgress: config?.onUploadProgress,
  });

  if (uploadResult.status !== 200) {
    throw new BaseError({ message: "[uploadFile] Failed to upload file" });
  }

  return {
    signedUrl,
  };
};

async function getSignedUrlFromBackend({
  fileId,
  fileName,
  fileMimeType,
}: {
  fileId: Uuid;
  fileName: string;
  fileMimeType: string;
}): Promise<{
  fileId: Uuid;
  signedUrl: string;
}> {
  const { data } = await api.post("/v2/file-uploads/generate-signed-url", {
    body: {
      file_id: fileId,
      file_name: fileName,
      file_mime_type: fileMimeType,
    },
  });

  if (!data) {
    throw new BaseError({ message: "[getSignedUrlFromBackend] Failed to generate signed URL" });
  }

  return {
    fileId,
    signedUrl: data.signed_url,
  };
}

export function makeUploadUrlParts(url: string): {
  bucketName: string | null;
  blobName: string | null;
} {
  const urlObj = new URL(url);
  const pathParts = urlObj.pathname.split("/").filter(Boolean);

  if (pathParts.length === 0) {
    return {
      bucketName: null,
      blobName: null,
    };
  }

  const bucketName = pathParts[0];
  const blobName = pathParts.slice(1).join("/");
  return { bucketName, blobName };
}
