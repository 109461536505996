import { mdsColors } from "@/design-system/foundations/colors";

export const mdsBorderRadius = () =>
  ({
    none: 0,
    medium: 4,
    mediumPlus: 6,
    mediumLarge: 8,
    large: 16,
    maximum: 100,
  }) as const;

export const mdsTransitions = () =>
  ({
    background: "background 0.08s ease",
  }) as const;

export const mdsBorders = () =>
  ({
    default: `1px solid ${mdsColors().grey.x100}`,
  }) as const;

export const mdsShadows = () =>
  ({
    default: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    hoverCard: "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
    popover: "rgba(69, 92, 104, 0.19) 0px 6px 10px",
    tooltip: "0px 2px 6px rgba(0, 0, 0, 0.12)",
  }) as const;
