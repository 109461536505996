import { Uuid } from "@/domains/global/identifiers";
import { cache } from "@/modules/cache";
import { CacheKey } from "@/modules/cache/constants";
import { uuidModule } from "@/modules/uuid";

/**
 * Generated per-client-group (per-user).
 * Should be cleared on logout.
 */
export const clientEnvClientGroupId = async (): Promise<{ clientGroupId: Uuid }> => {
  const activeClientGroup = await cache.get(CacheKey.ActiveClientInfo);

  if (activeClientGroup?.clientGroupId) {
    return { clientGroupId: activeClientGroup.clientGroupId };
  }

  const newClientGroupId = uuidModule.generate();

  await cache.set(CacheKey.ActiveClientInfo, { clientGroupId: newClientGroupId });

  return { clientGroupId: newClientGroupId };
};

export const clearClientEnvClientGroupId = async (): Promise<void> => {
  await cache.del(CacheKey.ActiveClientInfo);
};
