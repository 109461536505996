import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { DeleteCollectionsModalStore } from "@/components/modal/delete-collections/DeleteCollectionsModalStore";
import { mdsColors, mdsFontWeights } from "@/design-system/foundations";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { pluralize } from "@/modules/pluralize";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionChip } from "@/components/chips/CollectionChip";
import { useModalTitleLimit } from "@/components/modal/modal-title-limits/modal-title-limits";

interface Props {
  state: DeleteCollectionsModalStore;
}

export const DeleteCollectionsModal = observer<Props>(({ state }) => {
  const primaryTitle = state.collections[0]?.label || "";
  const secondaryTitle = getSecondaryTitle(state.collections);
  const sharedCollections = state.collections.filter(collection => collection.isShared);
  const isAllShared = sharedCollections.length === state.collections.length;
  const isAnyShared = sharedCollections.length > 0;
  const prefix =
    isAnyShared && (isAllShared || state.collections.length === 1) ? "Delete shared" : "Delete";

  const title = useModalTitleLimit({ primaryTitle, secondaryTitle, isAllShared: false });

  if (!state.collections.length) return null;

  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={state.isOpen && !!state.collections.length}
      handleCloseModal={() => state.close()}
      showOverflow={true}
    >
      <div className={modalContainerStyles}>
        <div className={headerStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              {prefix}
              <CollectionChip id={state.collections[0].id} label={title} inline /> {secondaryTitle}?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => state.close()} />
        </div>

        <MdsText
          lineHeight={MdsTextSize.Small}
          color={mdsColors().grey.x600}
          size={MdsTextSize.Small}
        >
          {isAnyShared ? (
            <>
              {!isAllShared && <span>Some collections you selected were shared with others. </span>}
              If you delete{" "}
              {pluralize(state.collections.length, "this collection", "these collections")},
              collaborators will immediately lose access to{" "}
              {pluralize(state.collections.length, "it", "them")}.{" "}
            </>
          ) : (
            <div className={contentStyles}>
              Make sure you want to permanently delete{" "}
              {pluralize(state.collections.length, "this collection", "these collections")}.{" "}
            </div>
          )}

          <p className={warningTextStyles}>You cannot undo this action.</p>
        </MdsText>
        <div className={buttonSectionStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.TextTertiary}
            onClick={() => state.close()}
          />
          <MdsButton
            label={`Delete ${pluralize(state.collections.length, "collection", "collections")}`}
            variant={MdsButtonVariant.Danger}
            onClick={async () => await state.handleSubmit()}
          />
        </div>
      </div>
    </MdsModal>
  );
});

const getSecondaryTitle = (collections: CollectionObservable[]) => {
  if (collections.length === 1) return "";

  return `and ${collections.length - 1} other ${pluralize(collections.length - 1, "collection", "collections")}`;
};

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "24px",
  width: "min(600px, 90vw)",
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const contentStyles = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const warningTextStyles = css({
  fontWeight: mdsFontWeights().semiBold,
  color: mdsColors().grey.x600,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
