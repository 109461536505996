import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsModal } from "@/design-system/components/modal";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { mdsColors, mdsLineSpacings } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { localStorageModule } from "@/modules/local-storage";
import { useAppStore } from "@/store";
import { ModalDefinition, ModalDefinitionKind } from "@/store/modals/types";
import styled from "@emotion/styled";
import { noop } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useCallback, useRef, useState } from "react";

export const UpgradedUserEmailChecker = observer(function UpgradedUserEmailChecker() {
  const { store } = useAppStore();

  const modalDefinitionRef = useRef<ModalDefinition>({
    kind: ModalDefinitionKind.UpgradedUserEmailMismatch,
  });

  const [upgradedUserEmail, setUpgradedUserEmail] = useState<string | null>(null);

  useEffectOnMount(() => {
    (async () => {
      const upgradedUserEmail = localStorageModule.readUpgradedUserEmail();
      setUpgradedUserEmail(upgradedUserEmail);

      const isIncompatible =
        !!upgradedUserEmail && upgradedUserEmail !== store.account.myAccount.profileEmailAddress;

      if (!isIncompatible) {
        localStorageModule.clearUpgradedUserEmail();
        return;
      }

      // Priority: we want to potentially switch the account before other long operations, e.g,
      // from "App reload required".
      store.modals.addModal(modalDefinitionRef.current, { priority: true });
    })();
  });

  const onClearMigratedUserEmail = useCallback(() => {
    localStorageModule.clearUpgradedUserEmail();
    store.modals.removeModal(modalDefinitionRef.current);
    setUpgradedUserEmail(null);
  }, [store.modals]);

  const onSignOut = useCallback(() => {
    store.auth.logOut();
  }, [store.auth]);

  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={store.modals.current?.kind === ModalDefinitionKind.UpgradedUserEmailMismatch}
      handleCloseModal={noop}
      className={modalStyles}
    >
      <Inner>
        <MdsText
          size={MdsTextSize.Large}
          weight={MdsTextWeight.Bold}
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Large}
          className={css({
            letterSpacing: mdsLineSpacings().mediumLarge,
          })}
        >
          Switch accounts to continue
        </MdsText>
        <MdsText
          color={mdsColors().grey.x600}
          lineHeight={MdsTextSize.Small}
          size={MdsTextSize.Small}
        >
          You&apos;re signed in with a different account than the one you used to upgrade. Sign in
          as <EmailAddress>{upgradedUserEmail}</EmailAddress> to access your migrated content.
        </MdsText>
        <Buttons>
          <MdsButton
            size={MdsButtonSize.Small}
            variant={MdsButtonVariant.TextTertiary}
            label="Dismiss"
            onClick={onClearMigratedUserEmail}
          />
          <MdsButton
            size={MdsButtonSize.Small}
            variant={MdsButtonVariant.Outlined}
            label={`Sign in as ${upgradedUserEmail}`}
            onClick={onSignOut}
          />
        </Buttons>
      </Inner>
    </MdsModal>
  );
});

const modalStyles = css({
  zIndex: ZIndex.AppStoreCrashNotice,
});

const Inner = styled.div(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.md,
  justifyContent: "space-between",
  padding: theme.spacing.lg,
}));

const Buttons = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.md,
  justifyContent: "flex-end",
  width: "100%",
}));

const EmailAddress = styled.span(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontWeight: theme.fontWeights.semiBold,
}));
