import { NOTE_EDITOR_TOP_BAR_HEIGHT } from "@/components/note/editor/top-bar/NoteEditorTopBar";
import { NoteObservableEditor } from "@/components/note/NoteObservableEditor";
import { NoteEditorMode, NoteType } from "@/components/note/types";
import { mdsMediaQueries } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { TemplatesEditorBanner } from "@/pages/templates-view/components/TemplatesEditorBanner";
import { TemplateEditorTopBar } from "@/pages/templates-view/TemplateEditorTopBar";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { IContactModel } from "@/store/contacts/types";
import { TemplatesViewPageStore } from "@/store/pages/TemplatesViewPageStore";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { useAppStore } from "@/store/utils/hooks";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

interface TemplateEditorProps {
  isSidePanelOpen: boolean;
  chatHistory?: ChatHistory;
  goToMention?: TemplatesViewPageStore["goToMention"];
  openFileUploadRejectedModal?: TemplatesViewPageStore["openFileUploadRejectedModal"];
  openImageUploadRejectedModal?: TemplatesViewPageStore["openImageUploadRejectedModal"];
  templateObservable?: TemplateObservable;
  myAccount?: IContactModel;
}

export const TemplateEditor = observer<TemplateEditorProps>(function TemplateEditor({
  chatHistory,
  goToMention,
  openFileUploadRejectedModal,
  openImageUploadRejectedModal,
  templateObservable: templateObservable,
  myAccount,
}) {
  const { store } = useAppStore();

  /**
   * Track views of the note editor.
   */
  useEffect(() => {
    if (!templateObservable) return;

    trackEvent(TrackedEvent.TemplateView, {
      note_id: templateObservable.id,
      note_primary_label: templateObservable.title,
    });
  }, [templateObservable, store]);

  if (!templateObservable) return null;
  return (
    <div className={fullHeight}>
      <TemplateEditorTopBar template={templateObservable} />
      <TemplatesEditorBanner template={templateObservable} />
      <NoteObservableEditor
        noteType={NoteType.Template}
        // Don't steal focus from template name input.
        autoFocus={false}
        className={editorStyles}
        chatHistory={chatHistory}
        goToMention={goToMention}
        openFileUploadRejectedModal={openFileUploadRejectedModal}
        openImageUploadRejectedModal={openImageUploadRejectedModal}
        noteObservable={templateObservable}
        myAccount={myAccount}
        mode={NoteEditorMode.Editable}
      />
    </div>
  );
});

const fullHeight = css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  width: "100%",
});

// Note: left padding is always 16px larger than right padding due to the scrollbar.
// Only issue is on mobile where the scrollbar is overlay it's not part of the width calculations.
const editorStyles = css({
  "--extra-padding-left": `56px`,
  "--extra-padding-right": `56px`,
  background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)) 0 100%`,
  backgroundRepeat: "no-repeat",
  backgroundColor: "#fff",
  backgroundSize: "100% 30px, 100% 30px, 100% 12px, 100% 12px",
  backgroundAttachment: "local, local, scroll, scroll",
  boxSizing: "border-box",
  cursor: "text",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: `calc(100% - ${NOTE_EDITOR_TOP_BAR_HEIGHT})`,
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  // Consistent sizing whether the scrollbar is visible or not.
  scrollbarGutter: "stable",
  width: "100%",

  "--extra-padding-bottom": "200px",

  [mdsMediaQueries().rules.tablet]: {
    "--extra-padding-left": `40px`,
    "--extra-padding-right": `40px`,
    "--extra-padding-bottom": "100px",
  },

  [mdsMediaQueries().rules.phone]: {
    "--extra-padding-left": `16px`,
    "--extra-padding-right": `16px`,
    "--extra-padding-bottom": "100px",
  },
});
