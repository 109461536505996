import { observer } from "mobx-react-lite";
import { FC, FormEvent, useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { appRoutes } from "@/app/router";
import { AuthCardBase } from "@/components/auth/AuthCardBase";
import { authCardStyles } from "@/components/auth/utils/authCardStyles";
import { MdsInput } from "@/design-system/components/input/MdsInput";
import { css } from "@/domains/emotion";
import { MdsButton } from "@/design-system/components/button";
import { usePublicAppStore } from "@/store";

export interface AuthCardLogInProps {}

export const AuthCardOAuthToken: FC<AuthCardLogInProps> = observer(() => {
  const { publicStore } = usePublicAppStore();

  const [accountId, setAccountId] = useState<string>("");
  const [oauthToken, setOauthToken] = useState<string>("");

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault;
    await publicStore.auth.authenticateAccountUsingOAuthToken({ accountId, oauthToken });
  };

  const isLoading = publicStore.auth.authenticateAccountUsingOAuthTokenState.isLoading;

  return (
    <AuthCardBase title="Log in to Mem 2.0">
      <div className={formStyles}>
        <MdsInput
          type="text"
          placeholder="Account ID"
          value={accountId}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setAccountId(e.target.value)}
          disabled={isLoading}
        />
        <MdsInput
          type="text"
          placeholder="OAuth Token"
          value={oauthToken}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setOauthToken(e.target.value)}
          disabled={isLoading}
        />
        <MdsButton label="Login" isLoading={isLoading} onClick={handleLogin} />
      </div>
      <div className={authCardStyles.altText}>
        <span>Don&apos;t have an account?</span>{" "}
        <span>
          <Link className={authCardStyles.link} to={appRoutes.signUp({}).path}>
            Sign up
          </Link>
        </span>
      </div>
    </AuthCardBase>
  );
});

const formStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  margin: "24px 0",
  width: "100%",
});
