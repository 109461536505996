import { SortByKind } from "@/modules/lenses/types";

export const sortKindLabelMap: {
  [key in SortByKind]: string;
} = {
  [SortByKind.LastModified]: "Last modified",
  [SortByKind.LastCreated]: "Last created",
  [SortByKind.LastViewed]: "Last viewed",
  [SortByKind.Alphabetical]: "Alphabetical",
  [SortByKind.LastShared]: "Last shared",
  [SortByKind.LastAdded]: "Last added",
  [SortByKind.LastUsed]: "Last used",
};
