import { MdsSpinner } from "@/design-system/components/loader/MdsSpinner";
import styled from "@emotion/styled";

export const OptimisticDataImportItem = () => (
  <ImportItemContainer>
    <MdsSpinner size={16} />
    <span>Starting import...</span>
  </ImportItemContainer>
);

const ImportItemContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
});
