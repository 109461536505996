import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { PageBannerSection } from "@/components/banners/page-banner-section";

export const ThreadsListPageHeader = observer(() => {
  return (
    <Wrapper>
      <MdsPanelHeader heading="Threads" icon={MdsIconKind.Collection} />
      <PageBannerSection />
    </Wrapper>
  );
});

const Wrapper = styled.div({});
