import { FileDropItem } from "react-aria-components";
import { uuidModule } from "@/modules/uuid";
import { checkIfFileCanBeUploaded } from "@/modules/file/validation";
import { makeUploadUrlParts, uploadFile } from "@/modules/file/upload";
import { normalizeFilename } from "@/modules/file/normalizeFileName";

export async function uploadAriaFileItem(fileItem: FileDropItem) {
  const file = await fileItem.getFile();
  const fileId = uuidModule.generate();

  const normalizedFileName = normalizeFilename({ fileName: fileItem.name });
  const info = {
    fileId,
    normalizedFileName,
    fileMimeType: fileItem.type,
    encodedFileContent: file.name, // TODO: write a comment explaining this unexpected key/val
    fileSizeBytes: file.size,
  };

  const canBeUploaded = checkIfFileCanBeUploaded({ info });
  if (!canBeUploaded) {
    throw new Error("File can not be uploaded");
  }

  const { signedUrl } = await uploadFile({
    info,
    blob: file,
  });

  const urlParts = makeUploadUrlParts(signedUrl);

  if (urlParts.blobName === null) {
    throw new Error("Failed to extract object key from signed URL");
  }

  return {
    info,
    fileItem,
    uploadedFileBlobName: urlParts.blobName,
    signedUrl,
  };
}

export function uploadAriaFileItems(fileItems: FileDropItem[]) {
  return Promise.all(fileItems.map(uploadAriaFileItem));
}
