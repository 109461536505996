import { Maybe } from "@/domains/common/types";
import { SyncModelPermissionEntryWithStatus } from "@/domains/sync-scopes/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";

export const canWrite = ({
  model,
  myPersonalSpaceAccountId,
  permissions,
  getCollectionObservableById,
}: {
  model: {
    id: string;
    canAccess: boolean;
    isOwnedByMe: boolean;
  };
  myPersonalSpaceAccountId: string;
  permissions: SyncModelPermissionEntryWithStatus[];
  getCollectionObservableById?: ({
    collectionId,
  }: {
    collectionId?: string;
  }) => Maybe<CollectionObservable>;
}) => {
  if (!model.canAccess) return false;
  if (model.isOwnedByMe) return true;

  for (const permission of permissions) {
    switch (permission.role_kind) {
      case "OWNER": {
        if (
          permission.scope_kind === "SPACE_ACCOUNT_SCOPE" &&
          permission.space_account_id === myPersonalSpaceAccountId
        )
          return true;
        break;
      }
      case "EDITOR": {
        switch (permission.scope_kind) {
          case "SPACE_ACCOUNT_SCOPE": {
            if (permission.space_account_id === myPersonalSpaceAccountId) return true;
            break;
          }
          case "COLLECTION_SCOPE": {
            const collection = getCollectionObservableById?.({
              collectionId: permission.collection_id,
            });
            if (collection?.canWrite) return true;
            break;
          }
        }
      }
    }
  }

  return false;
};
