import { ScreenOverlay } from "@/components/overlay/ScreenOverlay";
import { css, keyframes } from "@/domains/emotion";
import { FC } from "react";
import memLogomarkImage from "@/assets/images/mem-logomark.svg";
import { ZIndex } from "@/domains/design/constants";
import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";

interface LoadingOverlayProps {
  isVisible: boolean;
  animateIn?: boolean;
  loadingMessage?: string;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  isVisible,
  animateIn = true,
  loadingMessage = "Loading...",
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: animateIn ? 0.05 : 0, duration: animateIn ? 0.2 : 0 }}
          className={overlayStyles}
        >
          <ScreenOverlay>
            <motion.img
              src={memLogomarkImage}
              className={loaderStyles}
              animate={{ scale: [1, 1.05, 1] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <LoadingMessage>{loadingMessage}</LoadingMessage>
          </ScreenOverlay>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const overlayStyles = css({
  position: "absolute",
  zIndex: ZIndex.ScreenOverlay,
});

const pulseAnimation = keyframes({
  from: {
    transform: "scale3d(1, 1, 1)",
  },
  "50%": {
    transform: "scale3d(1.05, 1.05, 1.05)",
  },
  to: {
    transform: "scale3d(1, 1, 1)",
  },
});

const loaderStyles = css({
  width: 48,
  height: 48,
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -24,
  marginLeft: -24,
  zIndex: -1,
  animation: `1s ${pulseAnimation} infinite`,
});

const LoadingMessage = styled.div(({ theme }) => ({
  fontSize: 14,
  color: theme.colors.grey.x500,
  marginTop: 90,
}));
