import styled from "@emotion/styled";
import { ToggleButton } from "react-aria-components";

export const StyledToggleButton = styled(ToggleButton)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey.x100};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey.x400};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.xsmall};

  padding: ${({ theme }) => theme.spacing.sm};

  z-index: 1;

  &[data-disabled] {
    z-index: 0;
  }

  &[data-selected] {
    background: ${({ theme }) => theme.colors.grey.x50};
    color: ${({ theme }) => theme.colors.grey.x600};
  }

  &[data-focus-visible] {
    z-index: 2;
  }
`;
