import { isMemAccount } from "@/store/contacts/isMemAccount";
import { useAppStore } from "@/store";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { DropZone, DropItem } from "react-aria-components";
import { fileModule } from "@/modules/file";
import styled from "@emotion/styled";
import { toastModule } from "@/modules/toast";
import { observer } from "mobx-react-lite";
import { pluralize } from "@/modules/pluralize";

export const NotesPageDragAndDrop: FC<PropsWithChildren> = observer(({ children }) => {
  const { store } = useAppStore();
  const [isDragging, setIsDragging] = useState(false);
  const isMemmer = isMemAccount(store.account.myAccount);

  const handleDropEnter = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDropExit = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    async (e: { items: DropItem[] }) => {
      setIsDragging(false);
      console.log(e);
      console.log("Dropped items:", e.items);

      // Recursively get all file items from e.items
      const allFileItems = await fileModule.collectFileDropItems(e);
      // Filter for just .md files
      const markdownFileItems = allFileItems.filter(
        fileItem => fileItem.name.toLowerCase().endsWith(".md") || fileItem.type === "text/markdown"
      );

      // TODO 1. Create a Thread sync op for this upload
      // TODO 2. Create a Thread Event for this drop
      // DONE 3. Create an Uploaded File Batch
      // DONE 4. Create an Uploaded File for each file in the batch
      // TODO 5. Tie Uploaded File Batch to Thread Event

      if (markdownFileItems.length > 0) {
        // create a file batch for these files
        const batchId = await store.uploadedFileBatches.createUploadedFileBatch();
        toastModule.triggerToast({ content: "Upload batch started" }); // Toasting in dev mode for now

        // Upload the files to GCS and Sync Ops
        try {
          // Upload the files to storage
          const uploadResults = await fileModule.uploadAriaFileItems(markdownFileItems);
          const uploadCount = uploadResults.length;
          toastModule.triggerToast({
            content: `${uploadCount} ${pluralize(uploadCount, "file", "files")} files uploaded`,
          });

          // Create sync ops for all uploaded files
          await store.uploadedFiles.createUploadedFiles({
            batchId,
            files: uploadResults.map(u => {
              return {
                ...u.info,
                uploadedFileBlobName: u.uploadedFileBlobName,
              };
            }),
          });

          toastModule.triggerToast({
            content: "Upload batch complete",
          });
        } catch (error) {
          console.error(error);
          // TODO: mark the batch as errored
        }
      } else {
        toastModule.triggerToast({ content: "Only Markdown files are currently supported" });
      }
    },
    [store.uploadedFiles, store.uploadedFileBatches]
  );

  if (!isMemmer) return children;

  return (
    <>
      <StyledDropZone onDropEnter={handleDropEnter} onDropExit={handleDropExit} onDrop={handleDrop}>
        {children}
        {isDragging && <DragTarget>Drop files here to upload</DragTarget>}
      </StyledDropZone>
    </>
  );
});

const StyledDropZone = styled(DropZone)`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  transition: all 0.2s ease;
`;

const DragTarget = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
