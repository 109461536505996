import { action, computed, makeObservable, observable } from "mobx";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { toastModule } from "@/modules/toast";

export class DeleteCollectionsModalStore extends AppSubStore {
  private onClose?: () => void;
  public isOpen: boolean = false;
  public collectionIds: Uuid[] = [];

  constructor(injectedDeps: AppSubStoreArgs, { onClose }: { onClose?: () => void }) {
    super(injectedDeps);

    this.onClose = onClose;

    makeObservable<this, "onClose">(this, {
      onClose: false,
      isOpen: observable,
      collectionIds: observable,
      collections: computed,
      handleSubmit: action,
      open: action,
      close: action,
    });
  }

  get collections(): CollectionObservable[] {
    return this.store.collections.getMany(this.collectionIds);
  }

  public async handleSubmit() {
    if (!this.collections.length) return;

    await Promise.all(this.collections.map(collection => collection.delete()));

    toastModule.triggerToast({
      content:
        this.collections.length > 1
          ? `${this.collections.length} collections deleted.`
          : `${this.collections[0].label} deleted.`,
    });

    this.store.navigation.goToCollections();
    this.close();
  }

  public open({ collectionIds }: { collectionIds?: Uuid[] }) {
    if (!collectionIds) return;
    this.collectionIds = collectionIds;
    this.isOpen = true;
  }

  public close() {
    this.collectionIds = [];
    this.isOpen = false;
    this.onClose?.();
  }
}
