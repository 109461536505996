import styled from "@emotion/styled";
import { CalendarGrid } from "react-aria-components";

export const StyledCalendarGrid = styled(CalendarGrid)`
  border-collapse: collapse;

  & td {
    padding: 2px 0;
  }
`;
