import { DateInput } from "react-aria-components";

import styled from "@emotion/styled";

export const StyledDateInput = styled(DateInput)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.small};

  width: fit-content;
  min-width: 50px;
  white-space: nowrap;
  forced-color-adjust: none;
`;
