import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsModal } from "@/design-system/components/modal";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText, MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { MdsIconButton } from "@/design-system/components/icon-button";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { NoteChip } from "@/components/chips/NoteChip";
import { INoteObservable } from "@/store/note";
import { pluralize } from "@/modules/pluralize";
import { useModalTitleLimit } from "@/components/modal/modal-title-limits/modal-title-limits";

export interface DeleteSharedNotesModalProps {
  store: DeleteSharedNotesModalStore;
}

export const DeleteSharedNotesModal = observer<DeleteSharedNotesModalProps>(({ store }) => {
  const primaryTitle = store.notes[0]?.title || "";
  const secondaryTitle = getSecondaryTitle(store.notes);
  const isAllShared = store.notes.every(note => note.isShared);
  const prefix = store.notes.length === 1 || isAllShared ? "Delete shared" : "Delete";
  const title = useModalTitleLimit({ primaryTitle, secondaryTitle, isAllShared });

  if (!store.notes.length) return null;

  return (
    <MdsModal
      rootElementId="root"
      isModalOpen={store.isOpen && !!store.notes.length}
      handleCloseModal={() => store.close()}
    >
      <div className={modalContainerStyles}>
        <div className={headerStyles}>
          <div className={titleStyles}>
            <MdsText size={MdsTextSize.Large} weight={MdsTextWeight.Bold}>
              {prefix} <NoteChip inline title={title} /> {secondaryTitle}?
            </MdsText>
          </div>
          <MdsSpacer />
          <MdsIconButton iconKind={MdsIconKind.Exit} onClick={() => store.close()} />
        </div>
        <MdsText
          lineHeight={MdsTextSize.Small}
          color={mdsColors().grey.x600}
          size={MdsTextSize.Small}
        >
          {store.notes.length === 1 && (
            <div>
              <div>
                If you delete this note, collaborators will still be able to access it for up to 30
                days or until you clear the Trash.
              </div>
              <p>Once permanently deleted, nobody will be able to access it.</p>
            </div>
          )}
          {store.notes.length > 1 && (
            <div>
              {!isAllShared && <span>Some notes you selected were shared with others.</span>}{" "}
              <>
                Once notes are moved to trash, collaborators on shared notes will still be able to
                access it for 30 days or until you clear the trash.
              </>
              <p>Once permanently deleted, nobody will be able to access them.</p>
            </div>
          )}
        </MdsText>
        <div className={buttonSectionStyles}>
          <MdsButton
            label="Cancel"
            variant={MdsButtonVariant.Tertiary}
            onClick={() => store.close()}
          />
          <MdsButton
            label={`Delete ${pluralize(store.notes.length, "note", "notes")}`}
            variant={MdsButtonVariant.Danger}
            onClick={() => store.handleSubmit()}
          />
        </div>
      </div>
    </MdsModal>
  );
});

const getSecondaryTitle = (items: INoteObservable[]) => {
  if (items.length === 1) return "";

  return `and ${items.length - 1} other ${pluralize(items.length - 1, "note", "notes")}`;
};

const modalContainerStyles = css({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "30px",
  width: "min(600px, 90vw)",
});

const headerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const titleStyles = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 6,
});

const buttonSectionStyles = css({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 8,
});
