import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ImportInProgressBannerContent } from "@/components/banners/import-in-progress/import-in-progress-banner-content";

interface ImportInProgressBannerProps {}

export const ImportInProgressBanner: FC<ImportInProgressBannerProps> = observer(() => {
  const { store } = useAppStore();

  const isMigrationInProgress = store.dataImports.isMigrationInProgress;
  if (isMigrationInProgress) {
    return (
      <ImportInProgressBannerContent
        message="Migration in progress"
        actionLabel="See progress"
        onAction={store.navigation.goToImportFrom1dot0}
      />
    );
  }

  if (!store.dataImports.isImportInProgress) {
    return null;
  }

  return (
    <ImportInProgressBannerContent
      message="Import is in progress"
      actionLabel="Go to Imports"
      onAction={store.navigation.goToImportFrom1dot0}
    />
  );
});
