import { FC } from "react";
import { ClassNamesArg, css, cx } from "@/domains/emotion";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { generateCollectionIconBackgroundStyles } from "@/domains/collections/generateCollectionIconBackgroundStyles";
import { mdsColors } from "@/design-system/foundations";
import { MdsIconStylingProps } from "@/design-system/components/icon/MdsIcon";
import { Uuid } from "@/domains/global/identifiers";

export interface CollectionIconStylingProps {
  className?: ClassNamesArg;
  innerStyles?: {
    MdsIcon?: MdsIconStylingProps;
  };
}

interface CollectionIconProps extends CollectionIconStylingProps {
  collectionId?: Uuid;
  size?: number;
  variant?: "default" | "placeholder";
}

export const CollectionIcon: FC<CollectionIconProps> = ({
  collectionId,
  className,
  innerStyles,
  size = 32,
  variant = "default",
}) => {
  const collectionIconWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: size,
    width: size,
    borderRadius: "100%",
    flexShrink: 0,
    border: variant === "placeholder" ? `1px dashed ${mdsColors().grey.x400}` : "none",
  });

  const collectionIconStyles = css({
    color: variant === "placeholder" ? mdsColors().grey.x400 : mdsColors().white,
    height: size / 2,
    width: size / 2,
  });

  const collectionIconBackgroundStyles =
    variant === "placeholder"
      ? css({ backgroundColor: "transparent" })
      : generateCollectionIconBackgroundStyles(collectionId);

  return (
    <div className={cx(collectionIconWrapperStyles, collectionIconBackgroundStyles, className)}>
      <MdsIcon
        kind={MdsIconKind.Collection}
        className={innerStyles?.MdsIcon?.className}
        innerStyles={{
          Icon: {
            className: cx(collectionIconStyles, innerStyles?.MdsIcon?.innerStyles?.Icon?.className),
          },
        }}
      />
    </div>
  );
};
