import { observer } from "mobx-react-lite";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";
import { useAppStore } from "@/store";
import { SearchPageNotesListEmpty } from "./SearchPageNotesListEmpty";

export const SearchPageNotesList = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;

  if (page.hasNoItems) {
    return (
      <SearchPageNotesListEmpty
        query={page.filters.params.queryString}
        isSuccess={page.isSuccess}
        anyFiltersSelected={page.filters.hasAnyFilters || !page.showDeepSearchResults}
        onRetryClick={page.load}
      />
    );
  }

  return <MdsVirtualizedItemList rows={page.itemRows} listState={page.listState} />;
});
