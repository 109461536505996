import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { css } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import { DataImportObservable } from "@/store/data-imports/DataImportObservable";

interface Props {
  dataImport: DataImportObservable;
}

export const DataImportStatusIndicator = observer(({ dataImport }: Props) =>
  dataImport.isRunning ? (
    <MdsIcon kind={MdsIconKind.SpinnerSolid} />
  ) : dataImport.endedWithError ? (
    <MdsIcon kind={MdsIconKind.Danger} className={failureIconStyles} />
  ) : (
    <MdsIcon kind={MdsIconKind.CheckCircleSolid} className={successIconStyles} />
  )
);

const successIconStyles = css({
  color: mdsColors().green.x400,
});

const failureIconStyles = css({
  color: mdsColors().red.x500,
});
