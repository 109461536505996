import { observer } from "mobx-react-lite";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsText, MdsTextSize } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import {
  SyncModelPermissionEntry,
  SyncModelPermissionEntryCollection,
  SyncModelPermissionEntryStatus,
} from "@/domains/sync-scopes/types";
import { useAppStore } from "@/store";
import { ShareSheetPermissionRowLayout } from "@/components/modal/share-sheet/share-sheet-permission-row/ShareSheetPermissionRowLayout";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { MdsTextOverflow } from "@/design-system/components/text/types";

interface ShareSheetCollectionPermissionRowProps {
  permission: SyncModelPermissionEntryCollection & SyncModelPermissionEntryStatus;
  isUnsharing: boolean;
  canUpdateAccess: boolean;
  canRevokeAccess: boolean;
  handleRevokeAccess: (permission: SyncModelPermissionEntry) => void;
  handleUpdateAccess: (permission: SyncModelPermissionEntry) => void;
  handleCancelOperation: (syncOperationId: string) => void;
}

export const ShareSheetCollectionPermissionRow = observer<ShareSheetCollectionPermissionRowProps>(
  ({
    permission,
    isUnsharing,
    canUpdateAccess,
    canRevokeAccess,
    handleRevokeAccess,
    handleUpdateAccess,
    handleCancelOperation,
  }) => {
    const { store } = useAppStore();
    const collection = store.collections.getCollectionObservableById({
      collectionId: permission.collection_id,
    });
    const collectionContact =
      store.collectionMetadata.getCollectionMetadataObservableByCollectionId(
        permission.collection_id
      );
    const title = collection
      ? collection.label
      : collectionContact?.title || UNTITLED_COLLECTION_TITLE;

    return (
      <ShareSheetPermissionRowLayout
        permission={permission}
        isUnsharing={isUnsharing}
        canUpdateAccess={canUpdateAccess}
        canRevokeAccess={canRevokeAccess}
        handleRevokeAccess={() =>
          handleRevokeAccess({
            scope_kind: "COLLECTION_SCOPE",
            collection_id: permission.collection_id,
            role_kind: "REVOKED",
          })
        }
        handleUpdateAccessToReadOnly={() =>
          handleUpdateAccess({
            scope_kind: "COLLECTION_SCOPE",
            collection_id: permission.collection_id,
            role_kind: "VIEWER",
          })
        }
        handleUpdateAccessToReadWrite={() =>
          handleUpdateAccess({
            scope_kind: "COLLECTION_SCOPE",
            collection_id: permission.collection_id,
            role_kind: "EDITOR",
          })
        }
        handleCancelOperation={handleCancelOperation}
      >
        <div className={container}>
          <CollectionIcon collectionId={permission.collection_id} size={34} />
          <MdsText
            size={MdsTextSize.Small}
            overflow={MdsTextOverflow.EllipsisWrap}
            numberOfLines={2}
          >
            {title}
          </MdsText>
        </div>
      </ShareSheetPermissionRowLayout>
    );
  }
);

const container = css({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  minWidth: 0,
});
