import { Table } from "dexie";
import { SidePanelRoute, SidePanelState, SidePanelTab } from "@/store/routing/types";
import { CollectionsListPageParams } from "@/store/pages/CollectionsListPageStore/types";
import { toJS } from "mobx";
import { PreloadingState } from "@/store/sync/types";
import { Maybe } from "@/domains/common/types";
import { NotesListPageParams } from "@/modules/filters/notes/types";
import { logger } from "@/modules/logger";
import { TemplatesListPageParams } from "@/store/pages/TemplatesListPageStore/TemplatesListPageStore";
import { BootstrapState, LogoutState } from "@/components/sync/types";

const SIDE_PANEL_STATE_KEY = "side_panel_state";
const SIDE_PANEL_ACTIVE_TAB_ID_KEY = "side_panel_active_tab_id";
const SIDE_PANEL_TABS_KEY = "side_panel_tabs";

const NOTES_LIST_PAGE_PARAMS_KEY = "notes_list_page_params";
const TEMPLATES_LIST_PAGE_PARAMS_KEY = "templates_list_page_params";
const COLLECTIONS_LIST_PAGE_PARAMS_KEY = "collections_list_page_params";
const COLLECTIONS_VIEW_PAGE_PARAMS_KEY = "collections_view_page_params-v2";

const NOTE_CONTENT_PRELOADING_STATE_KEY = "note_content_preloading_state";
const SPACE_ACCOUNT_TOPIC_ITEM_PRELOADING_STATE_KEY = "space_account_topic_item_preloading_state";

const LAST_SYNC_ID_KEY = "last_sync_id";
const BOOTSTRAP_STATE_KEY = "bootstrap_state";
const BOOTSTRAP_HEARTBEAT_KEY = "bootstrap_heartbeat";

const LOGOUT_STATE_KEY = "logout_state";
const LOGOUT_HEARTBEAT_KEY = "logout_heartbeat";

const LAST_HYDRATE_CURSOR_KEY = "last_hydrate_cursor";
const SPACE_ACCOUNT_TOPIC_ITEM_LAST_HYDRATE_CURSOR_KEY =
  "space_account_topic_item_last_hydrate_cursor";

const CURRENT_CHAT_CONVERSATION_ID_KEY = "current_chat_conversation_id";

const getCollectionsViewPageParamsKey = (
  collectionId: string
): `collections_view_page_params-${string}` =>
  `${COLLECTIONS_VIEW_PAGE_PARAMS_KEY}-${collectionId}`;

type SettingsTable = Table<
  unknown,
  | "side_panel_state"
  | "side_panel_active_tab_id"
  | "side_panel_tabs"
  | "notes_list_page_params"
  | "templates_list_page_params"
  | "collections_list_page_params"
  | "collections_view_page_params"
  | `collections_view_page_params-${string}`
  | "note_content_preloading_state"
  | "last_sync_id"
  | "bootstrap_state"
  | "bootstrap_heartbeat"
  | "logout_state"
  | "logout_heartbeat"
  | "last_hydrate_cursor"
  | "space_account_topic_item_preloading_state"
  | "space_account_topic_item_last_hydrate_cursor"
  | "current_chat_conversation_id"
>;

export class SettingsDB {
  table: SettingsTable;

  constructor(table: SettingsTable) {
    this.table = table;
  }

  // SIDE PANEL STATE
  getSidePanelState = async (): Promise<{
    sidePanelState?: SidePanelState;
    activeTabId?: SidePanelTab;
    tabs?: Map<SidePanelTab, SidePanelRoute[]>;
  }> => {
    const [sidePanelState, activeTabId, tabsString] = await Promise.all([
      this.table.get(SIDE_PANEL_STATE_KEY),
      this.table.get(SIDE_PANEL_ACTIVE_TAB_ID_KEY),
      this.table.get(SIDE_PANEL_TABS_KEY),
    ]);

    const parsedTabs = tabsString ? JSON.parse(tabsString as string) : [];
    const tabs = new Map<SidePanelTab, SidePanelRoute[]>();
    for (const [tabId, tabRoutes] of parsedTabs) {
      tabs.set(tabId as SidePanelTab, tabRoutes as SidePanelRoute[]);
    }

    return {
      sidePanelState: sidePanelState as SidePanelState,
      activeTabId: activeTabId as SidePanelTab,
      tabs,
    };
  };

  setSidePanelState = async (state: SidePanelState) => {
    return this.table.put(state, SIDE_PANEL_STATE_KEY);
  };

  setSidePanelActiveTabId = async (sidePanelActiveTab: SidePanelTab) => {
    return this.table.put(sidePanelActiveTab, SIDE_PANEL_ACTIVE_TAB_ID_KEY);
  };

  setSidePanelTabs = async (tabs: [SidePanelTab, SidePanelRoute[]][]) => {
    const tabsString = JSON.stringify(tabs);
    return this.table.put(tabsString, SIDE_PANEL_TABS_KEY);
  };

  // NOTE LIST PAGE PARAMS
  getNotesListPageParams = async (): Promise<Partial<NotesListPageParams>> => {
    const params = await this.table.get(NOTES_LIST_PAGE_PARAMS_KEY);
    return params as Partial<NotesListPageParams>;
  };

  setNotesListPageParams = async (params: Partial<NotesListPageParams>) => {
    return this.table.put(toJS(params), NOTES_LIST_PAGE_PARAMS_KEY);
  };

  // TEMPLATES LIST PAGE PARAMS
  getTemplatesListPageParams = async (): Promise<Partial<TemplatesListPageParams>> => {
    const params = await this.table.get(TEMPLATES_LIST_PAGE_PARAMS_KEY);
    return params as Partial<TemplatesListPageParams>;
  };

  setTemplatesListPageParams = async (params: Partial<TemplatesListPageParams>) => {
    return this.table.put(toJS(params), TEMPLATES_LIST_PAGE_PARAMS_KEY);
  };

  // COLLECTIONS LIST PAGE PARAMS
  getCollectionsListPageParams = async (): Promise<Partial<CollectionsListPageParams>> => {
    const params = await this.table.get(COLLECTIONS_LIST_PAGE_PARAMS_KEY);
    return params as Partial<CollectionsListPageParams>;
  };

  setCollectionsListPageParams = async (params: Partial<CollectionsListPageParams>) => {
    return this.table.put(toJS(params), COLLECTIONS_LIST_PAGE_PARAMS_KEY);
  };

  // COLLECTIONS VIEW PAGE PARAMS
  getCollectionsViewPageParams = async (
    collectionId: string
  ): Promise<Partial<NotesListPageParams>> => {
    const params = await this.table.get(getCollectionsViewPageParamsKey(collectionId));
    return params as Partial<NotesListPageParams>;
  };

  setCollectionsViewPageParams = async (
    collectionId: string,
    params: Partial<NotesListPageParams>
  ) => {
    const jsParams = toJS(params);
    logger.info({
      message: `[Settings] setCollectionsViewPageParams`,
      info: {
        collectionId,
        params: JSON.stringify(jsParams),
      },
    });

    return this.table.put(jsParams, getCollectionsViewPageParamsKey(collectionId));
  };

  // BOOTSTRAP

  setLastSyncId = async ({ syncId }: { syncId: string }) => {
    return this.table.put(syncId, LAST_SYNC_ID_KEY);
  };

  getLastSyncId = async (): Promise<string | undefined> => {
    return (await this.table.get(LAST_SYNC_ID_KEY)) as string | undefined;
  };

  clearLastSyncId = async () => {
    return this.table.delete(LAST_SYNC_ID_KEY);
  };

  setBootstrapState = async (bootstrapState: BootstrapState) => {
    return this.table.put(bootstrapState, BOOTSTRAP_STATE_KEY);
  };

  getBootstrapState = async (): Promise<Maybe<BootstrapState>> => {
    return (await this.table.get(BOOTSTRAP_STATE_KEY)) as Maybe<BootstrapState>;
  };

  clearBootstrapState = async () => {
    return this.table.delete(BOOTSTRAP_STATE_KEY);
  };

  setBootstrapHeartbeat = async () => {
    return this.table.put(Date.now(), BOOTSTRAP_HEARTBEAT_KEY);
  };

  getBootstrapHeartbeat = async (): Promise<Maybe<number>> => {
    return (await this.table.get(BOOTSTRAP_HEARTBEAT_KEY)) as Maybe<number>;
  };

  // LOGOUT
  setLogoutState = async (logoutState: LogoutState) => {
    return this.table.put(logoutState, LOGOUT_STATE_KEY);
  };

  getLogoutState = async (): Promise<Maybe<LogoutState>> => {
    return (await this.table.get(LOGOUT_STATE_KEY)) as Maybe<LogoutState>;
  };

  setLogoutHeartbeat = async () => {
    return this.table.put(Date.now(), LOGOUT_HEARTBEAT_KEY);
  };

  getLogoutHeartbeat = async (): Promise<Maybe<number>> => {
    return (await this.table.get(LOGOUT_HEARTBEAT_KEY)) as Maybe<number>;
  };

  // PRELOAD NOTE CONTENT DOCUMENTS

  setNoteContentPreloadingState = async (noteContentPreloadingState: PreloadingState) => {
    return this.table.put(noteContentPreloadingState, NOTE_CONTENT_PRELOADING_STATE_KEY);
  };

  getNoteContentPreloadingState = async (): Promise<Maybe<PreloadingState>> => {
    return (await this.table.get(NOTE_CONTENT_PRELOADING_STATE_KEY)) as Maybe<PreloadingState>;
  };

  clearNoteContentPreloadingState = async () => {
    return this.table.delete(NOTE_CONTENT_PRELOADING_STATE_KEY);
  };

  setNoteContentDocumentLastHydrateCursor = async ({ cursor }: { cursor: string }) => {
    return this.table.put(cursor, LAST_HYDRATE_CURSOR_KEY);
  };

  getNoteContentDocumentLastHydrateCursor = async (): Promise<string | undefined> => {
    return (await this.table.get(LAST_HYDRATE_CURSOR_KEY)) as string | undefined;
  };

  clearNoteContentDocumentLastHydrateCursor = async () => {
    return this.table.delete(LAST_HYDRATE_CURSOR_KEY);
  };

  // PRELOAD SPACE ACCOUNT TOPIC ITEMS

  setSpaceAccountTopicItemPreloadingState = async (preloadingState: PreloadingState) => {
    return this.table.put(preloadingState, SPACE_ACCOUNT_TOPIC_ITEM_PRELOADING_STATE_KEY);
  };

  getSpaceAccountTopicItemPreloadingState = async (): Promise<Maybe<PreloadingState>> => {
    return (await this.table.get(SPACE_ACCOUNT_TOPIC_ITEM_PRELOADING_STATE_KEY)) as
      | PreloadingState
      | undefined;
  };

  clearSpaceAccountTopicItemPreloadingState = async () => {
    return this.table.delete(SPACE_ACCOUNT_TOPIC_ITEM_PRELOADING_STATE_KEY);
  };

  setSpaceAccountTopicItemLastHydrateCursor = async ({ cursor }: { cursor: string }) => {
    return this.table.put(cursor, SPACE_ACCOUNT_TOPIC_ITEM_LAST_HYDRATE_CURSOR_KEY);
  };

  getSpaceAccountTopicItemLastHydrateCursor = async (): Promise<string | undefined> => {
    return (await this.table.get(SPACE_ACCOUNT_TOPIC_ITEM_LAST_HYDRATE_CURSOR_KEY)) as
      | string
      | undefined;
  };

  clearSpaceAccountTopicItemLastHydrateCursor = async () => {
    return this.table.delete(SPACE_ACCOUNT_TOPIC_ITEM_LAST_HYDRATE_CURSOR_KEY);
  };

  // CURRENT CHAT CONVERSATION ID
  getCurrentChatConversationId = async (): Promise<string | undefined> => {
    return (await this.table.get(CURRENT_CHAT_CONVERSATION_ID_KEY)) as string | undefined;
  };

  setCurrentChatConversationId = async (conversationId: string) => {
    return this.table.put(conversationId, CURRENT_CHAT_CONVERSATION_ID_KEY);
  };

  removeCurrentChatConversationId = async () => {
    return this.table.delete(CURRENT_CHAT_CONVERSATION_ID_KEY);
  };
}
