import { Uuid } from "@/domains/global/identifiers";
import { ApiKeyModelData } from "@/store/api-keys/types";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { DateTime } from "luxon";
import { makeObservable, computed } from "mobx";

export class ApiKeyObservable extends BaseSyncModel<ApiKeyModelData> {
  public readonly modelKind = SyncModelKind.SpaceAccountAppCallout;

  constructor({
    id,
    data,
    store,
  }: { id: Uuid; data: SyncUpdateValue<ApiKeyModelData> } & WithAppStore) {
    super({ id, data, store });
    makeObservable(this, {
      modelKind: true,
      createdAt: computed,
      title: computed,
      description: computed,
      isRevoked: computed,
    });
  }

  get title(): string | null {
    return this.modelData.title;
  }

  get description(): string | null {
    return this.modelData.description;
  }

  get createdAt(): DateTime {
    return DateTime.fromISO(this.modelData.created_at);
  }

  get isRevoked(): boolean {
    return this.modelData.is_revoked;
  }
}
