import { NoteType } from "@/components/note/types";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { capitalize } from "lodash-es";

export const deleteNotesPermanently = async ({
  notes,
  store,
  noteType = NoteType.Note,
}: {
  notes: Pick<INoteObservable | TemplateObservable, "id" | "delete">[];
  store: AppStore;
  noteType?: NoteType;
}) => {
  await Promise.all(notes.map(note => note.delete()));
  if (notes.length > 1) {
    if (noteType === NoteType.Note) {
      store.navigation.goToNotes();
    } else {
      store.navigation.goToTemplates();
    }
  }
  toastModule.triggerToast({
    content: (
      <div>
        {capitalize(noteType)}
        {notes.length > 1 ? "s" : ""} deleted permanently
      </div>
    ),
  });
};
