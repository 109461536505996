import { ClientPlatformKind } from "@/domains/api/types";
import { clientEnvIsDesktop } from "@/modules/client-env/isDesktop";

export const clientEnvPlatformKind = (): ClientPlatformKind => {
  const isDesktop = clientEnvIsDesktop();

  /**
   * We'll need to improve this in the future once we add windows support.
   */
  if (isDesktop) {
    return ClientPlatformKind.MacOS;
  }

  return ClientPlatformKind.Web;
};
