import { FC } from "react";
import styled from "@emotion/styled";
import { MDSBanner, MDSBannerSlotName } from "@/mds";
import { observer } from "mobx-react-lite";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";

interface ImportInProgressBannerProps {
  message: string;
  actionLabel: string;
  onAction: () => void;
  onDismiss?: () => void;
}

export const ImportInProgressBannerContent: FC<ImportInProgressBannerProps> = observer(
  ({ onAction, message, actionLabel, onDismiss }) => {
    return (
      <Wrapper>
        <MDSBanner message={message} iconKind={MdsIconKind.SpinnerSolid} onDismiss={onDismiss}>
          {actionLabel && onAction && (
            <div slot={MDSBannerSlotName.Right}>
              <MdsButton
                size={MdsButtonSize.Small}
                variant={MdsButtonVariant.Transparent}
                label={actionLabel}
                onClick={onAction}
              />
            </div>
          )}
        </MDSBanner>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  padding: "0 24px",
});
