import Dexie from "dexie";
import { AppStore } from "@/store/AppStore";

import {
  CollectionItemFilterOlderThan,
  CollectionItemIndexTuple,
  CollectionItemSearchFilter,
  CollectionItemSearchParams,
  IndexedCollectionItemSyncUpdateValue,
} from "@/store/collection-items/types";

export const searchForCollectionItems = async (
  store: AppStore,
  { filter, collectionId }: CollectionItemSearchParams
): Promise<CollectionItemIndexTuple[]> => {
  let dexieCollection = store.collectionItems.localTable
    .where("[collection_id+item_id+locally_created_at+model_id]")
    .between(
      [collectionId, Dexie.minKey, Dexie.minKey, Dexie.minKey],
      [collectionId, Dexie.maxKey, Dexie.maxKey, Dexie.maxKey]
    );

  if (filter) {
    dexieCollection = prepareAddedAtFilter(dexieCollection, filter.addedAt);
    dexieCollection = prepareAddedByFilter(dexieCollection, filter.addedBy);
  }

  return dexieCollection.reverse().keys() as unknown as Promise<CollectionItemIndexTuple[]>;
};

const getDateFromTo = (date: { from?: string; to?: string }) => {
  const from = date.from ? new Date(date.from).getTime() : undefined;
  const to = date.to ? new Date(date.to).getTime() : undefined;

  return { from, to };
};

const prepareAddedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedCollectionItemSyncUpdateValue>,
  addedAt: CollectionItemSearchFilter["addedAt"]
) => {
  if (!addedAt) {
    return dexieCollection;
  }

  if (addedAt.olderThan) {
    return prepareOlderThanFilter(dexieCollection, addedAt.olderThan, "locally_created_at");
  }

  return prepareRangeFilter(dexieCollection, "locally_created_at", addedAt);
};

const prepareOlderThanFilter = (
  dexieCollection: Dexie.Collection<IndexedCollectionItemSyncUpdateValue>,
  olderThan: CollectionItemFilterOlderThan,
  dateField: "locally_created_at"
) => {
  if (!olderThan) {
    return dexieCollection;
  }

  const now = Date.now();
  const thresholds = {
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };

  return dexieCollection.filter(note => {
    const fieldValue = new Date(note[dateField]).getTime();
    return now - fieldValue > thresholds[olderThan];
  });
};

const prepareRangeFilter = (
  dexieCollection: Dexie.Collection<IndexedCollectionItemSyncUpdateValue>,
  dateField: "locally_created_at",
  range: CollectionItemSearchFilter["addedAt"]
) => {
  if (!range) {
    return dexieCollection;
  }

  const { from, to } = getDateFromTo(range);
  if (from === undefined && to === undefined) {
    return dexieCollection;
  }

  return dexieCollection.filter(note => {
    const fieldValue = new Date(note[dateField]).getTime();

    if (to && from === undefined) {
      return fieldValue <= to;
    }

    if (from && to === undefined) {
      return fieldValue >= from;
    }

    if (from && to) {
      return fieldValue >= from && fieldValue <= to;
    }

    return false;
  });
};

const prepareAddedByFilter = (
  dexieCollection: Dexie.Collection<IndexedCollectionItemSyncUpdateValue>,
  addedBy: CollectionItemSearchFilter["addedBy"]
) => {
  if (!addedBy || !addedBy.length) {
    return dexieCollection;
  }

  return dexieCollection.filter(item => {
    if (!item.model_data.added_by_space_account_id) {
      return true;
    }

    return addedBy.includes(item.model_data.added_by_space_account_id);
  });
};
