import { MdsIconKind } from "@/design-system/components/icon";
import { IItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/types";
import { MdsItemAction, MdsItemDropdown } from "@/design-system/components/item-list/types";
import { MdsTextStylingProps } from "@/design-system/components/text/MdsText";
import { ClassNamesArg } from "@/domains/emotion";
import { Uuid } from "@/domains/global/identifiers";
import { OnClick } from "@/domains/react/types";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { ReactNode } from "react";

export enum MdsItemKind {
  Collection = "COLLECTION",
  Note = "NOTE",
  Template = "TEMPLATE",
  Thread = "THREAD",
}

export enum MdsSharedByVariant {
  SharedBy = "SHARED_BY",
  AddedToCollectionBy = "ADDED_TO_COLLECTION_BY",
}

export interface ExtraRow {
  id: string;
  content: () => ReactNode;
}

export interface TitleIcon {
  kind: MdsIconKind;
  tooltip?: string;
  innerStyles?: {
    Icon?: {
      className?: ClassNamesArg;
    };
  };
}

export interface ImageInfo {
  mimeType: string;
  url: string;
}

export interface SnippetChunk {
  text: string;
  isHighlight?: boolean;
}

export interface TopicInfo {
  id: string;
  label: string;
}

export interface MdsCarouselItemStylingProps {
  className?: string;
  Label?: MdsTextStylingProps;
  Secondary?: MdsTextStylingProps;
}

export interface MdsCarouselItem
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "className" | "onClick"> {
  id: string;
  label: string;
  secondary: string;
  className?: ClassNamesArg;
  innerStyles?: {
    Label?: MdsTextStylingProps;
    Secondary?: MdsTextStylingProps;
  };
  icon: () => ReactNode;
  onClick: OnClick<{ itemId: string }>;
}

export interface IMdsListState {
  isHoverable: boolean;
  isSelectModeActive: boolean;
  highlightedItemId?: string;
  highlightItem: (itemId: string) => void;
  isSelected: (itemId: string) => boolean;
  selectItemsFromLastSelectionUntil: (itemId: string) => void;
  toggleItemSelection: (itemId: string) => void;
}

export type MdsItem<TKind extends MdsItemKind = MdsItemKind> = {
  kind: TKind;
  id: Uuid;
  label: string;
  createPreviewState?: () => IItemPreviewState;
  dateLabel?: string;
  onClick: OnClick<{ itemId: string }>;
  description?: string;
  titleIcon?: TitleIcon;
  sharedBy?: ContactObservable;
  sharedByVariant?: MdsSharedByVariant;
  lastEditedDate?: string;
  listState?: IMdsListState;
  rightAlignedComponent?: ReactNode;
  extraRows?: ExtraRow[];
  isSpecial?: boolean;
} & (
  | {
      action: MdsItemAction;
      dropdown?: undefined;
    }
  | {
      action?: undefined;
      dropdown: MdsItemDropdown;
    }
  | {
      action?: undefined;
      dropdown?: undefined;
    }
);

export type MdsCollectionItem = MdsItem<MdsItemKind.Collection>;
export type MdsNoteItem = MdsItem<MdsItemKind.Note>;
