import { FC } from "react";
import { observer } from "mobx-react-lite";
import { EmptyContainer } from "@/components/styling/empty/EmptyContainer";
import { EmptyText } from "@/components/styling/empty/EmptyText";

interface ThreadsListPageListEmptyProps {}

export const ThreadsListPageListEmpty: FC<ThreadsListPageListEmptyProps> = observer(() => {
  return (
    <EmptyContainer>
      <EmptyText>No threads yet.</EmptyText>
    </EmptyContainer>
  );
});
