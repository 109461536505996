import styled from "@emotion/styled";
import { ToggleButtonGroup } from "react-aria-components";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  gap: 0;

  &[data-orientation="vertical"] {
    flex-direction: column;
  }

  &[data-orientation="horizontal"] {
    flex-direction: row;
  }

  > button {
    flex: 1;
    text-align: center;

    &:first-child {
      border-start-end-radius: 0px;
      border-end-end-radius: 0px;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0px;
    }

    &:last-child {
      border-start-start-radius: 0px;
      border-end-start-radius: 0px;
    }
  }
`;
