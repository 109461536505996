import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateUploadedFileBatchOperation } from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncModelData, SyncModelKind } from "@/store/sync/types";
import { DateTime } from "luxon";
import { UploadedFileBatchModelData } from "@/store/uploaded-files/types";

export class CreateUploadedFileBatchOperation extends BaseSyncOperation<ICreateUploadedFileBatchOperation> {
  get operationKind() {
    return "CREATE_UPLOADED_FILE_BATCH" as const;
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];

    const batchUpdate = this.generateUploadedFileBatchUpdate();
    if (batchUpdate) {
      optimisticUpdates.push(batchUpdate);
    }

    return optimisticUpdates;
  }

  protected generateUploadedFileBatchUpdate(): OptimisticSyncUpdate<UploadedFileBatchModelData> {
    const now = DateTime.now().toISO();
    const owner_id = this.store.spaceAccounts.myPersonalSpaceAccountId;

    return {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: {
        model_id: this.payload.id,
        model_kind: SyncModelKind["UploadedFileBatch"],
        model_version: 1,
        model_data: {
          owned_by_space_account_id: owner_id,
          locally_created_at: now,
          locally_modified_at: now,
          config_json: (this.payload.config_json ?? {}) as Record<string, never>,
          processing_enqueued_at: null,
          processing_started_at: this.payload.processing_started_at ?? null,
          processing_ended_at: this.payload.processing_ended_at ?? null,
        },
        model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
      },
    };
  }

  async triggerRecompute(): Promise<void> {
    await this.store.uploadedFileBatches.recompute(this.payload.id);
  }
}
