import { ApiAuthStrategy } from "@/domains/auth/types";
import { PublicAppSubStoreArgs, PublicAppSubStore } from "@/store/types";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { api } from "@/modules/api";
import { enumModule } from "@/modules/enum";
import { BaseError } from "@/domains/errors";
import {
  AsyncData,
  asyncDataFailed,
  asyncDataLoaded,
  asyncDataLoading,
  buildAsyncData,
} from "@/domains/async/AsyncData";
import { GuestInfo } from "@/store/auth/types";
import { isString } from "lodash-es";
import { toastModule } from "@/modules/toast";
import { appRoutes } from "@/app/router";
import { cache } from "@/modules/cache";
import { windowModule } from "@/modules/window";
import { clientEnvModule } from "@/modules/client-env";
import { localStorageModule } from "@/modules/local-storage";
import { UPGRADED_USER_EMAIL_SEARCH_PARAM } from "@/modules/google-identity/constants";

export class AppStoreAuthStore extends PublicAppSubStore {
  activeAuthToken: string | null = null;
  activeAuthStrategy: ApiAuthStrategy | null = null;
  initializationState = buildAsyncData<boolean>({});
  authenticateAccountUsingGoogleOAuthCodeState = buildAsyncData<boolean>({});
  authenticateAccountUsingEmailPasswordState = buildAsyncData<boolean>({});
  authenticateAccountUsingOAuthTokenState = buildAsyncData<boolean>({});
  guestInfo?: GuestInfo;
  loggedInDuringThisSession = false;
  private static readonly PERIODIC_CHECK_INTERVAL = 2500;
  private periodicCheckIntervalId: number | null = null;

  constructor(injectedDeps: PublicAppSubStoreArgs) {
    super(injectedDeps);

    makeObservable<
      AppStoreAuthStore,
      | "periodicCheckIntervalId"
      | "startPeriodicCheck"
      | "stopPeriodicCheck"
      | "periodicCheckIfAuthed"
    >(this, {
      startPeriodicCheck: true,
      stopPeriodicCheck: true,
      periodicCheckIntervalId: true,
      checkAndSetAuthInfo: action,
      periodicCheckIfAuthed: true,
      resetSystemStateForLogIn: true,
      updateAuthenticateAccountUsingOAuthTokenState: true,
      authenticateAccountUsingOAuthTokenState: true,
      authenticateAccountUsingOAuthToken: true,
      initialize: action,
      processSearchParams: action,
      storeAuthToken: action,
      clearAuthToken: action,
      logOut: action,
      authenticateAccountUsingGoogleOAuthCode: action,
      authenticateAccountUsingEmailPassword: action,
      activeAuthToken: observable,
      activeAuthStrategy: observable,
      initializationState: observable,
      authenticateAccountUsingGoogleOAuthCodeState: observable,
      authenticateAccountUsingEmailPasswordState: observable,
      guestInfo: observable,
      isAuthenticated: computed,
      isStandardMode: computed,
      isGuestMode: computed,
      isGuestModeOrStandardMode: computed,
      updateInitializationState: action,
      updateAuthenticateAccountUsingGoogleOAuthCodeState: action,
      updateAuthenticateAccountUsingEmailPasswordState: action,
      loggedInDuringThisSession: observable,
    });

    reaction(() => this.activeAuthToken, api.setAuthToken);
  }

  get isAuthenticated() {
    return this.isStandardMode;
  }

  get isStandardMode() {
    return this.activeAuthStrategy === ApiAuthStrategy.Account;
  }

  get isGuestMode() {
    return this.activeAuthStrategy === ApiAuthStrategy.GuestAccount;
  }

  get isGuestModeOrStandardMode() {
    return this.isStandardMode || this.isGuestMode;
  }

  updateInitializationState = (f: (state: AsyncData<boolean>) => AsyncData<boolean>) => {
    this.initializationState = f(this.initializationState);
  };

  updateAuthenticateAccountUsingGoogleOAuthCodeState = (
    f: (state: AsyncData<boolean>) => AsyncData<boolean>
  ) => {
    this.authenticateAccountUsingGoogleOAuthCodeState = f(
      this.authenticateAccountUsingGoogleOAuthCodeState
    );
  };

  updateAuthenticateAccountUsingEmailPasswordState = (
    f: (state: AsyncData<boolean>) => AsyncData<boolean>
  ) => {
    this.authenticateAccountUsingEmailPasswordState = f(
      this.authenticateAccountUsingEmailPasswordState
    );
  };

  updateAuthenticateAccountUsingOAuthTokenState = (
    f: (state: AsyncData<boolean>) => AsyncData<boolean>
  ) => {
    this.authenticateAccountUsingOAuthTokenState = f(this.authenticateAccountUsingOAuthTokenState);
  };

  initialize = async () => {
    this.updateInitializationState(asyncDataLoading);

    try {
      await this.checkAndSetAuthInfo();
    } catch (err) {
      logger.error({
        message: "[AppStoreAuthStore] checkAndSetAuthInfo failed",
        info: { err: objectModule.safeErrorAsJson(err as BaseError) },
      });
    } finally {
      this.updateInitializationState(asyncData => asyncDataLoaded(asyncData, true));
      this.startPeriodicCheck();
    }
  };

  // When tab is not authenticated, periodically check if any other tab has been authenticated.
  private periodicCheckIfAuthed = async () => {
    if (this.isAuthenticated) {
      this.stopPeriodicCheck();
      return;
    }

    logger.debug({
      message: "[AppStoreAuthStore] isAuthenticated false, checking if authed",
    });

    try {
      await this.checkAndSetAuthInfo();
    } catch (err) {
      this.stopPeriodicCheck();
      logger.error({
        message: "[AppStoreAuthStore] checkAndSetAuthInfo failed",
        info: { err: objectModule.safeErrorAsJson(err as BaseError) },
      });
    }
  };

  checkAndSetAuthInfo = async () => {
    const accountInfo = await localDb.global.getCurrentAuthInfo();

    if (accountInfo?.authToken && accountInfo?.authStrategy) {
      const validValues: string[] = enumModule.values(ApiAuthStrategy);

      if (validValues.includes(accountInfo.authStrategy)) {
        logger.debug({
          message: "[AppStoreAuthStore] checkAndSetAuthInfo success",
          info: { accountInfo },
        });

        runInAction(() => {
          this.activeAuthToken = accountInfo.authToken;
          this.activeAuthStrategy = accountInfo.authStrategy as ApiAuthStrategy;
        });
      }
    }
  };

  processSearchParams = ({
    searchParams,
    setSearchParams,
  }: {
    searchParams: URLSearchParams;
    setSearchParams: (searchParams: URLSearchParams) => void;
  }) => {
    const guestInfoParam = searchParams.get("guest_info");
    if (guestInfoParam && !this.isAuthenticated) {
      try {
        const json = JSON.parse(atob(decodeURIComponent(guestInfoParam)));

        runInAction(() => {
          this.guestInfo = {
            spaceAccountId: getStringFromJson(json, "invitee_guest_account_id"),
            inviter: {
              spaceAccountId:
                getStringFromJson(json, "inviter_space_account_id") ??
                "fake-inviter-space-account-id",
              profileEmailAddress: "",
              profileDisplayName: getStringFromJson(json, "inviter_display_name") ?? "Someone",
              profilePhotoUrl: getStringFromJson(json, "inviter_photo_url"),
            },
            noteTitle: getStringFromJson(json, "note_title"),
          };
          this.activeAuthToken = getStringFromJson(json, "guest_access_token") ?? null;
          this.activeAuthStrategy = ApiAuthStrategy.GuestAccount;
        });
      } catch (err) {
        logger.error({
          message: "[processSearchParams] Failed to parse guest info.",
          info: { err: objectModule.safeErrorAsJson(err as BaseError) },
        });
      }
    }

    const upgradedUserEmailParam = searchParams.get(UPGRADED_USER_EMAIL_SEARCH_PARAM);
    if (upgradedUserEmailParam) {
      localStorageModule.writeUpgradedUserEmail(upgradedUserEmailParam);

      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.delete(UPGRADED_USER_EMAIL_SEARCH_PARAM);
      // Don't set state during react render.
      setTimeout(() => setSearchParams(newSearchParams), 0);
    }
  };

  storeAuthToken = async ({
    accountId,
    authToken,
    authStrategy,
  }: {
    accountId: Uuid;
    authToken: string;
    authStrategy: ApiAuthStrategy;
  }) => {
    try {
      await localDb.global.setCurrentAuthInfo({ accountId, authToken, authStrategy });
    } catch (unknownErr) {
      /**
       * We currently ignore persistance failures, although the user
       * would have to log in again next time they use the app.
       */
      logger.warn({
        message: "[authenticateAccountUsingGoogleOAuthCode] Failed to persist auth token.",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
    }
  };

  clearAuthToken = async () => {
    try {
      await localDb.global.setCurrentAuthInfo({
        accountId: null,
        authToken: null,
        authStrategy: null,
      });
    } catch (unknownErr) {
      logger.warn({
        message: "[authenticateAccountUsingGoogleOAuthCode] Failed to clear auth token.",
        info: { err: objectModule.safeErrorAsJson(unknownErr as BaseError) },
      });
    }
  };

  logOut = async () => {
    try {
      await this.clearAuthToken();

      runInAction(() => {
        this.loggedInDuringThisSession = false;
        this.activeAuthStrategy = null;
        this.activeAuthToken = null;
        this.authenticateAccountUsingGoogleOAuthCodeState = buildAsyncData({});
      });

      /**
       * Clear all sync-related data.
       * Note that we do NOT clear the pending queued data (to prevent data-loss).
       * The next time the user logs in, this should get picked back up.
       */
      const dbPromises = [
        clientEnvModule.clearClientId(),
        clientEnvModule.clearClientGroupId(),
        cache.clearAll(),
      ];

      // Maximum of 4 seconds to clear the data.
      const maxTimeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("[logOut] Clear operation timed out")), 4000)
      );

      /**
       * If the clear operation takes too long, we should still
       * force a reload + logout.
       */
      await Promise.race([Promise.all(dbPromises), maxTimeoutPromise]);
    } catch (unknownErr) {
      logger.error({
        message: "",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });
    }

    /**
     * Force-redirect after logging out.
     */
    const logInUrl = windowModule.buildUrl({
      path: appRoutes.logIn({}).path,
    });

    windowModule.navigateToInternal({
      url: logInUrl,
    });
  };

  resetSystemStateForLogIn = async () => {
    clientEnvModule.clearClientId();
    await clientEnvModule.clearClientGroupId();
  };

  authenticateAccountUsingGoogleOAuthCode = async ({
    code,
    redirectURI,
    isSignUp,
  }: {
    code: string;
    redirectURI: string;
    isSignUp: boolean;
  }) => {
    this.resetSystemStateForLogIn();
    this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncDataLoading);

    try {
      const result = await (async () => {
        if (isSignUp) {
          return await api.post("/v2/auth/google/sign-up", {
            body: {
              google_oauth_code: code,
              redirect_uri: redirectURI,
              client_kind: "MEM_WEB_CLIENT",
            },
          });
        }

        return await api.post("/v2/auth/google/log-in", {
          body: {
            google_oauth_code: code,
            redirect_uri: redirectURI,
            client_kind: "MEM_WEB_CLIENT",
          },
        });
      })();

      if (!result.data) {
        throw new BaseError({
          message: "[_authActionAuthenticateAccountUsingGoogleOAuthCode] Failed.",
        });
      }

      const authenticatedAccountId = result.data.account_id;
      const token = result.data.oauth_access_token;
      const strategy = ApiAuthStrategy.Account;

      await this.storeAuthToken({
        accountId: authenticatedAccountId,
        authToken: token,
        authStrategy: strategy,
      });

      this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncData =>
        asyncDataLoaded(asyncData, true)
      );

      runInAction(() => {
        this.activeAuthToken = token;
        this.activeAuthStrategy = strategy;
        this.loggedInDuringThisSession = true;
      });
    } catch (unknownErr) {
      toastModule.triggerToast({ content: "Failed to login." });

      logger.error({
        message: "Failed to login. Google oauth",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });

      this.updateAuthenticateAccountUsingGoogleOAuthCodeState(asyncDataFailed);
    }
  };

  authenticateAccountUsingEmailPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    this.resetSystemStateForLogIn();
    this.updateAuthenticateAccountUsingEmailPasswordState(asyncDataLoading);

    try {
      const result = await api.post("/v2/auth/email-password/log-in", {
        body: { email_address: email, password },
      });

      if (!result.data) {
        throw new BaseError({
          message: "[authenticateAccountUsingEmailPassword] Failed.",
        });
      }

      const authenticatedAccountId = result.data.account_id;
      const token = result.data.oauth_access_token;
      const strategy = ApiAuthStrategy.Account;

      await this.storeAuthToken({
        accountId: authenticatedAccountId,
        authToken: token,
        authStrategy: strategy,
      });

      this.updateAuthenticateAccountUsingEmailPasswordState(asyncData =>
        asyncDataLoaded(asyncData, true)
      );

      runInAction(() => {
        this.activeAuthToken = token;
        this.activeAuthStrategy = strategy;
      });
    } catch (unknownErr) {
      toastModule.triggerToast({ content: "Failed to login." });

      logger.error({
        message: "Failed to login. Email password",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });

      this.updateAuthenticateAccountUsingEmailPasswordState(asyncDataFailed);
    }
  };

  authenticateAccountUsingOAuthToken = async ({
    accountId,
    oauthToken,
  }: {
    accountId: Uuid;
    oauthToken: string;
  }) => {
    this.resetSystemStateForLogIn();
    this.updateAuthenticateAccountUsingOAuthTokenState(asyncDataLoading);

    try {
      const strategy = ApiAuthStrategy.Account;

      localStorageModule.clearUpgradedUserEmail();

      await this.storeAuthToken({
        accountId: accountId,
        authToken: oauthToken,
        authStrategy: strategy,
      });

      this.updateAuthenticateAccountUsingOAuthTokenState(asyncData =>
        asyncDataLoaded(asyncData, true)
      );

      runInAction(() => {
        this.activeAuthToken = oauthToken;
        this.activeAuthStrategy = strategy;
        this.loggedInDuringThisSession = true;
      });
    } catch (unknownErr) {
      toastModule.triggerToast({ content: "Failed to login." });

      logger.error({
        message: "Failed to login. OAuth token",
        info: {
          err: objectModule.safeErrorAsJson(unknownErr as BaseError),
        },
      });

      this.updateAuthenticateAccountUsingOAuthTokenState(asyncDataFailed);
    }
  };

  private startPeriodicCheck = () => {
    if (this.periodicCheckIntervalId === null) {
      this.periodicCheckIntervalId = window.setInterval(
        this.periodicCheckIfAuthed,
        AppStoreAuthStore.PERIODIC_CHECK_INTERVAL
      );
    }
  };

  private stopPeriodicCheck = () => {
    if (this.periodicCheckIntervalId !== null) {
      window.clearInterval(this.periodicCheckIntervalId);
      this.periodicCheckIntervalId = null;
    }
  };
}

const getStringFromJson = (json: Record<string, unknown>, key: string) => {
  const s = json[key];
  if (isString(s)) return s;
};
