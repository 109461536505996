import { convertEncodedFileContentToBlob } from "@/modules/file/blob";
import { MemCommonEditorFileInfo } from "@mem-labs/common-editor";

export const downloadFileFromContent = async ({
  info,
}: {
  info: MemCommonEditorFileInfo;
}): Promise<void> => {
  const { encodedFileContent, fileMimeType, normalizedFileName } = info;

  const blob = convertEncodedFileContentToBlob({ encodedFileContent, fileMimeType });

  const url = URL.createObjectURL(blob);

  /**
   * Create a temporary anchor element to trigger the download.
   */
  const a = document.createElement("a");
  a.href = url;
  a.download = normalizedFileName;

  /**
   * Trigger the download.
   */
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  /**
   * Revoke the object URL to free up memory.
   */
  URL.revokeObjectURL(url);
};

export const downloadFileFromUrlDirectly = async ({
  fileName,
  downloadUrl,
  inTab,
}: {
  fileName: string | null;
  downloadUrl: string | null;
  inTab: boolean;
}): Promise<void> => {
  if (!downloadUrl) {
    return;
  }

  /**
   * Create a temporary anchor element to trigger the download.
   */
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = fileName ?? getFilenameFromUrl(downloadUrl);

  if (inTab) {
    a.target = "_blank";
    a.rel = "noopener noreferrer";
  }

  /**
   * Trigger the download.
   */
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadFileFromUrl = async ({
  fileName,
  downloadUrl,
}: {
  fileName: string | null;
  downloadUrl: string | null;
}): Promise<void> => {
  if (!downloadUrl) {
    return;
  }

  try {
    // Fetch the file content
    const response = await fetch(downloadUrl);
    const blob = await response.blob();

    // Create a Blob URL
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName ?? getFilenameFromUrl(downloadUrl);
    a.style.display = "none";

    // Trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

// Helper function to extract filename from URL
function getFilenameFromUrl(url: string): string {
  const pathname = new URL(url).pathname;
  const index = pathname.lastIndexOf("/");
  return pathname.substring(index + 1);
}
