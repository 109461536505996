import { V2TransitionModalContent } from "@/components/modal/v2-transition/v2-transition-modal-content";
import { MdsModal } from "@/design-system/components/modal";
import { noop } from "lodash-es";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";

interface V2TransitionModalProps {}

export const V2TransitionModal = observer<V2TransitionModalProps>(() => {
  const { store } = useAppStore();

  const transitionContent = store.accountTransition.fetchTransitionContentState.data;
  const noteCount = transitionContent?.noteIds.length ?? 0;
  const collectionCount = transitionContent?.collectionIds.length ?? 0;
  const isOpen = !!transitionContent;

  return (
    <MdsModal isModalOpen={isOpen} handleCloseModal={noop}>
      <V2TransitionModalContent
        noteCount={noteCount}
        collectionCount={collectionCount}
        handleStartFresh={() => store.accountTransition.startFreshProcess()}
        handleStartImport={() => store.accountTransition.startImportProcess()}
        transitionIsLoading={store.accountTransition.transitionProcessIsLoading}
        transitionIsError={store.accountTransition.transitionProcessIsError}
      />
    </MdsModal>
  );
});
