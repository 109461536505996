import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { PlatformIconKind } from "@/domains/api/types";
import { css } from "@/domains/emotion";
import { PlatformIcon } from "@/components/icons/platform-icon";
import { mdsColors } from "@/design-system/foundations";

interface AppCalloutBannerIconProps {
  iconKind: PlatformIconKind | null;
}

export const AppCalloutBannerIcon: FC<AppCalloutBannerIconProps> = observer(({ iconKind }) => {
  if (!iconKind) return null;

  return (
    <IconWrapper>
      <LargeIcon iconKind={iconKind} innerStyles={{ Icon: { className: innerIconStyles } }} />
    </IconWrapper>
  );
});

const innerIconStyles = css({
  fontSize: "58px",
  color: mdsColors().grey.x600,
});

const IconWrapper = styled.div({
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "8px",
  paddingRight: "32px",
});

const LargeIcon = styled(PlatformIcon)({
  height: "80px",
  width: "80px",
});
