import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { resolveCollectionMetadataModelUuid } from "@/modules/uuid/sync-models/resolveCollectionMetadataModelUuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionModelData, CollectionUpsertedSyncUpdateValue } from "@/store/collections/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IUpdateCollectionOperation } from "@/store/sync/operations/types";
import { SyncCustomErrorData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";

export class UpdateCollectionOperation extends BaseSyncOperation<IUpdateCollectionOperation> {
  private existingCollection?: CollectionObservable;

  get operationKind(): SyncOperationKind {
    return "UPDATE_COLLECTION";
  }

  get title() {
    return this.store.collections.get(this.payload.id)?.label;
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  private get collectionMetadataId() {
    return resolveCollectionMetadataModelUuid({
      collectionId: this.payload.id,
    });
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<CollectionModelData>[]> {
    const optimisticUpdates = [];

    const collectionUpdate = this.generateCollectionUpdate();
    if (collectionUpdate) {
      optimisticUpdates.push(collectionUpdate);
    }

    return optimisticUpdates;
  }

  async execute() {
    this.existingCollection = await this.store.collections.getAsync(this.payload.id);

    await super.execute();
  }

  async triggerRecompute() {
    await this.store.collections.recompute(this.payload.id);
    await this.store.collectionMetadata.recompute(this.collectionMetadataId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be updated. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to update {this.mediumTitle}. Please contact the collection
        owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be updated. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  private generateCollectionUpdate(): OptimisticSyncUpdate<CollectionModelData> | undefined {
    if (!this.existingCollection) {
      logger.error({
        message: "Attempted to update collection for collection that doesn't exist",
        info: {
          collectionId: this.payload.id,
        },
      });

      return;
    }

    const value: CollectionUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: this.existingCollection.modelVersion,
      model_data: {
        ...this.existingCollection.modelData,
        title: this.payload.title ?? this.existingCollection.modelData.title,
        description: this.payload.description ?? this.existingCollection.modelData.description,
        locally_modified_at: this.committedAt,
        last_updated_at: this.committedAt,
      },
      model_scopes: this.existingCollection.modelScopes,
    };

    const syncUpdate: OptimisticSyncUpdate<CollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
