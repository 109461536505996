import { useAppStore } from "@/store";

import { TopicInfo } from "@/design-system/components/item-list/types";
import styled from "@emotion/styled";
import ColorHash from "color-hash";
import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
} from "@/design-system/foundations";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MdsText } from "@/design-system/components/text";
import { css } from "@/domains/emotion";
import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { removeNoteFromCollection } from "@/actions/removeNoteFromCollection";
import { observer } from "mobx-react-lite";
import { INoteObservable } from "@/store/note";
import { FigmaMenuItem } from "@/mds/menu-item";
import { MDSMenu } from "@/mds/menu";
import { ReactEventHandler } from "react";

interface CollectionPreviewProps {
  collectionTopic: TopicInfo;
  noteId: string;
  onDismiss: () => void;
}
export const CollectionPreview = observer(
  ({ collectionTopic, noteId, onDismiss }: CollectionPreviewProps) => {
    const { store } = useAppStore();

    const bgColor = bgColorHash.hex(collectionTopic.id);
    const collection = store.collections.getCollectionObservableById({
      collectionId: collectionTopic.id,
    });
    const note = store.notes.getNoteObservableById({ noteId });

    const handleRemove: React.MouseEventHandler = e => {
      if (!note || !collection) return;

      e.stopPropagation();

      removeNoteFromCollection({
        note,
        collection: collection,
        store: store,
      });

      return true;
    };

    const handleOpenInCopilot: ReactEventHandler = e => {
      if (!collection) return;

      store.sidePanel.goToCollection({ collectionId: collection.id });

      e.stopPropagation();
      return true;
    };

    const handleOpenNote = (note: INoteObservable) => {
      if (!note) return;

      onDismiss();
      store.routing.setNoteIdParam(note.id);
    };

    if (!collection) return null;

    return (
      <CollectionPreviewContainer>
        <Header style={{ backgroundColor: bgColor }}>
          <div>
            <CollectionIcon collectionId={collection.id} />
          </div>
          <HeaderTitle>
            <MdsText className={titleStyles}>{collection.label}</MdsText>
            <MdsText className={descriptionStyles}>{collection.description}</MdsText>
          </HeaderTitle>
        </Header>
        <Main>
          <RecentNotes
            notes={store.recentItems.getRecentNotesByCollectionId(collection.id)}
            onOpenNote={handleOpenNote}
          />
          <CardActions>
            {noteId && (
              <MdsButton
                variant={MdsButtonVariant.TextTertiaryOutlined}
                iconKind={MdsIconKind.Exit}
                label="Remove"
                size={MdsButtonSize.Small}
                onClick={handleRemove}
                // no tooltip for now
              />
            )}
            <MdsButton
              variant={MdsButtonVariant.TextTertiaryOutlined}
              iconKind={MdsIconKind.Copilot}
              label="Open in Copilot"
              size={MdsButtonSize.Small}
              onClick={handleOpenInCopilot}
              // no tooltip for now
            />
          </CardActions>
        </Main>
      </CollectionPreviewContainer>
    );
  }
);

interface RecentNotesProps {
  notes: INoteObservable[];
  onOpenNote: (note: INoteObservable) => void;
}
const RecentNotes = ({ notes, onOpenNote }: RecentNotesProps) => (
  <Recents>
    <MdsText className={recentsHeaderStyles}>Recents</MdsText>
    <RecentsItems>
      <MDSMenu>
        {notes.map(
          note =>
            note && (
              <FigmaMenuItem
                key={note.id}
                type="note"
                text={note.title}
                onAction={() => {
                  onOpenNote(note);
                }}
              />
            )
        )}
      </MDSMenu>
    </RecentsItems>
  </Recents>
);

const CollectionPreviewContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.colors.white,
  minWidth: 290,
  maxWidth: 380,
  borderRadius: theme.borderRadius.large,
  overflow: "hidden",
  boxShadow: theme.shadows.hoverCard,
}));

const bgColorHash = new ColorHash({ lightness: 0.92, saturation: 0.45 });

const Header = styled.div(({ theme }) => ({
  padding: `${theme.spacing.smd} ${theme.spacing.md}`,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.sm,
}));

const HeaderTitle = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
}));

const Main = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.sm,
  margin: 0,
  padding: 0,
  paddingTop: theme.spacing.sm,

  borderBottomLeftRadius: theme.borderRadius.large,
  borderBottomRightRadius: theme.borderRadius.large,

  borderLeft: `1px solid ${theme.colors.grey.x25}`,
  borderRight: `1px solid ${theme.colors.grey.x25}`,
  borderBottom: `1px solid ${theme.colors.grey.x25}`,
  borderTop: "none",
}));

const Recents = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.xxs,
  padding: `0 ${theme.spacing.md}`,
}));

const RecentsItems = styled.div(({ theme }) => ({
  marginLeft: `-${theme.spacing.sm}`,
  marginRight: `-${theme.spacing.sm}`,
}));

const CardActions = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.sm,
  padding: `0 ${theme.spacing.md} ${theme.spacing.smd} ${theme.spacing.md}`,
}));

const titleStyles = css({
  color: mdsColors().grey.x600,
  fontSize: mdsFontSizes().medium,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().small,
});

const descriptionStyles = css({
  color: mdsColors().grey.x500,
  textOverflow: "ellipsis",
  overflow: "hidden",
  fontSize: mdsFontSizes().small,
  fontWeight: mdsFontWeights().regular,
  lineHeight: mdsLineHeights().xsmall,
});

const recentsHeaderStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
});
