import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useMemo, useState, useEffect } from "react";
import { useEventListener } from "usehooks-ts";
import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import {
  MdsDropdownContent,
  MdsDropdownContentProps,
} from "@/design-system/components/dropdown/MdsDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { AppStore, useAppStore } from "@/store";
import { css, cx } from "@/domains/emotion";
import { actions } from "@/actions";
import { isMac } from "@/domains/platform/isMac";
import { SearchSuggestion, SearchSuggestionType } from "@/domains/search";
import { DateTime } from "luxon";
import { generateRecentDateString } from "@/domains/date/date";
import { mdsColors } from "@/design-system/foundations";
import { useAsync } from "@/modules/use-async";
import { TrackedEvent } from "@/domains/metrics";
import { trackEvent } from "@/domains/metrics";

export interface SuggestedSearchesListProps {
  className?: string;
  currentSearchQuery: string;
  limit: number;
  onClick?: () => void;
}

const questionWords = ["who", "what", "when", "where", "why", "how", "which", "whose", "whom"];

export const SuggestedSearchesList = observer<SuggestedSearchesListProps>(function RecentItems({
  currentSearchQuery,
  className,
  limit,
  onClick,
}) {
  const { store, pageStore } = useAppStore();
  const { handleSearch, handleAsk, updateSelectedItemId } = pageStore.quickSearchModal;

  const listRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { result, invoke } = useAsync({
    fn: async () => await store.search.forSuggestions(currentSearchQuery),
    options: {
      defaultValue: [],
    },
  });

  // safe to cast since we set defaultValue
  const suggestions = result as SearchSuggestion[];
  useEffect(() => {
    invoke(currentSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery]);

  // Reset selectedIndex to 0 when currentSearchQuery changes
  useEffect(() => {
    setSelectedIndex(0);
  }, [currentSearchQuery]);

  const handleSearchClick = () => {
    pageStore.searchPage.resetPreviousQueryString();
    handleSearch();
  };

  const fixedItems: MdsDropdownButtonItem[] = useMemo(() => {
    const fixedItems: MdsDropdownButtonItem[] = [
      {
        id: `search-${currentSearchQuery}`,
        kind: MdsDropdownItemKind.Button,
        label: `Search for "${currentSearchQuery}"`,
        iconKind: MdsIconKind.Search,
        onClick: getClickHandler(null, store, handleSearchClick, onClick),
      },
    ];

    const lowercaseQuery = currentSearchQuery.toLowerCase();
    const isQuestion =
      lowercaseQuery.includes("?") || questionWords.some(word => lowercaseQuery.includes(word));
    if (isQuestion) {
      fixedItems.unshift({
        id: `ask-${currentSearchQuery}`,
        kind: MdsDropdownItemKind.Button,
        label: `Ask "${currentSearchQuery}"`,
        iconKind: MdsIconKind.Message,
        onClick: getClickHandler(null, store, handleAsk, onClick),
      });
    }

    return fixedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery, handleAsk, onClick, store]);

  const onKeyDown = getKeyDownHandler(
    selectedIndex,
    Math.min(suggestions.length, limit) + fixedItems.length,
    setSelectedIndex,
    getAction(
      currentSearchQuery,
      fixedItems,
      suggestions,
      store,
      selectedIndex,
      handleSearchClick,
      handleAsk,
      onClick
    )
  );

  useEventListener("keydown", onKeyDown, listRef);
  useEffect(() => {
    if (selectedIndex < fixedItems.length) {
      updateSelectedItemId();
      return;
    }

    const item = suggestions[selectedIndex - fixedItems.length];
    if (!item) {
      updateSelectedItemId();
      return;
    }

    if (item.type === SearchSuggestionType.NOTE) {
      updateSelectedItemId(item.modelId);
    } else {
      updateSelectedItemId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const contentList: MdsDropdownContentList = useMemo(() => {
    const filteredItems = suggestions.slice(0, limit);

    return {
      items: [
        {
          id: "header",
          text: "Suggestions",
          kind: MdsDropdownItemKind.Detail,
        },
        ...fixedItems.map((item, index) => {
          const isSelected = selectedIndex === index;
          const combinedItemClassName = cx({}, isSelected && quickSearchSelectedItemStyles);

          return {
            ...item,
            className: combinedItemClassName,
          };
        }),
        ...filteredItems.map((item, index) => {
          const isSelected = selectedIndex === fixedItems.length + index;
          const combinedItemClassName = cx({}, isSelected && quickSearchSelectedItemStyles);

          const selectedLabelDetail = generateRecentDateString(
            DateTime.fromISO(item.lastViewedAt || ""),
            {
              skipFullDayName: true,
            }
          );

          const button: MdsDropdownButtonItem = {
            id: item.modelId,
            kind: MdsDropdownItemKind.Button,
            label: item.label || "Untitled",
            isSelected,
            selectedLabelDetail,
            iconKind: getIcon(item),
            className: combinedItemClassName,
            iconSize: 16,
            onClick: getClickHandler(item, store, handleSearch, onClick),
          };

          return button;
        }),
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchQuery, limit, selectedIndex, suggestions, fixedItems]);

  const onHover: MdsDropdownContentProps["onHover"] = ({ itemId }) => {
    // Skip the first item because it's a header
    const index = contentList.items.slice(1).findIndex(s => s.id === itemId);

    setSelectedIndex(index >= 0 ? index : 0);
  };

  return <MdsDropdownContent contentList={contentList} className={className} onHover={onHover} />;
});

const quickSearchSelectedItemStyles = css({
  background: mdsColors().grey.x50,
});

const getIcon = (item: SearchSuggestion) =>
  item.type === SearchSuggestionType.NOTE ? MdsIconKind.Document : MdsIconKind.Collection;

// TODO: following "Recents" pattern here but we can re-do Dropdown as set of links and allow browser to take care of opening new page natively
const getClickHandler =
  (
    item: SearchSuggestion | null,
    store: AppStore,
    handleSearch: () => void,
    onClick?: () => void
  ) =>
  ({
    event,
  }: {
    event?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>;
  }) => {
    if (!item) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        handleSearch();
      } else {
        handleSearch();
        onClick?.();
      }

      return;
    }

    if (item.type === SearchSuggestionType.NOTE) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        actions.openNoteInNewTab({ noteId: item.modelId });
      } else {
        store.navigation.goToNote({ noteId: item.modelId });
        onClick?.();
      }
    }
    if (item.type === SearchSuggestionType.COLLECTION) {
      if (isMac() ? event?.metaKey : event?.ctrlKey) {
        actions.openCollectionInNewTab({ collectionId: item.modelId });
      } else {
        store.navigation.goToCollection({ collectionId: item.modelId });
        onClick?.();
      }
    }
  };

const getKeyDownHandler =
  (
    selectedIndex: number,
    limit: number,
    setSelectedIndex: Dispatch<SetStateAction<number>>,
    onAction: () => void
  ) =>
  (e: KeyboardEvent) => {
    if (e.key == "Enter") {
      e.preventDefault();
      onAction();
      return;
    }

    let newIndex = selectedIndex;
    if (e.key === "ArrowDown") {
      newIndex = selectedIndex + 1;
    }

    if (e.key === "ArrowUp") {
      newIndex = selectedIndex - 1;
    }

    // wrap around
    if (newIndex > limit - 1) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = limit - 1;
    }

    setSelectedIndex(newIndex);
  };

const getAction =
  (
    currentSearchQuery: string,
    fixedItems: MdsDropdownButtonItem[],
    suggestions: SearchSuggestion[],
    store: AppStore,
    selectedIndex: number,
    handleSearch: () => void,
    handleAsk: () => Promise<void>,
    onClick?: () => void
  ) =>
  () => {
    if (selectedIndex < fixedItems.length) {
      const item = fixedItems[selectedIndex];

      if (item.id.startsWith("search")) {
        handleSearch();
        return;
      }

      if (item.id.startsWith("ask")) {
        handleAsk();
        return;
      }

      return;
    }

    const item = suggestions[selectedIndex - fixedItems.length];

    if (item.type === SearchSuggestionType.NOTE) {
      store.navigation.goToNote({ noteId: item.modelId });

      /**
       * Track the navigation to a note
       */
      trackEvent(TrackedEvent.QuickSearchOpenNote, {
        query: currentSearchQuery,
        position: selectedIndex,
        note_id: item.modelId,
        note_primary_label: store.notes.get(item.modelId)?.title,
      });
    } else if (item.type === SearchSuggestionType.COLLECTION) {
      store.navigation.goToCollection({ collectionId: item.modelId });

      /**
       * Track the navigation to a collection
       */
      trackEvent(TrackedEvent.QuickSearchOpenCollection, {
        query: currentSearchQuery,
        position: selectedIndex,
        collection_id: item.modelId,
        collection_primary_label: store.collections.get(item.modelId)?.label,
      });
    }

    onClick?.();
  };
