import React, { useCallback, useMemo, FC } from "react";

import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { TAB_BAR_ITEM_HORIZONTAL_PADDING } from "@/design-system/components/tab-bar/constants";
import { MdsTabBarItem } from "@/design-system/components/tab-bar/types";
import { css, cx } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsBorderRadius, mdsColors, mdsFontSizes } from "@/design-system/foundations";

export interface MdsTabBarItemContentProps extends EmotionClassStyles {
  selectedItemId: string;
  item: MdsTabBarItem;
}

const wrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 48,
  backgroundColor: mdsColors().grey.x0,
  transition: "0.08s ease",
  "&:hover": {
    backgroundColor: mdsColors().grey.x50,
  },
  "&:active": {
    backgroundColor: mdsColors().grey.x100,
  },
  borderRadius: mdsBorderRadius().medium,
  paddingLeft: TAB_BAR_ITEM_HORIZONTAL_PADDING,
  paddingRight: TAB_BAR_ITEM_HORIZONTAL_PADDING,
});

const angleRightStyles = css({
  "@media (max-width: 750px)": {
    display: "none",
  },
});

const labelStyles = css({
  fontSize: mdsFontSizes().small,
  color: mdsColors().grey.x700,
  userSelect: "none",
  textAlign: "start",
});

export const MdsTabBarItemContent: FC<MdsTabBarItemContentProps> = ({
  item,
  selectedItemId,
  className,
}) => {
  const { onClick: onItemClick, id: itemId } = item;

  const handleClick = useCallback(() => onItemClick({ itemId }), [onItemClick, itemId]);
  const isSelected = useMemo(() => item.id === selectedItemId, [item.id, selectedItemId]);

  const combinedWrapperStyles = cx(
    wrapperStyles,
    css({
      backgroundColor: isSelected ? mdsColors().grey.x50 : mdsColors().grey.x0,
    }),
    className
  );

  return (
    <button className={combinedWrapperStyles} onClick={handleClick}>
      <div className={labelStyles}>{item.label}</div>
      <div className={angleRightStyles}>
        <MdsIcon kind={MdsIconKind.AngleRight} />
      </div>
    </button>
  );
};
