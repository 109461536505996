import { importWrapperStyle } from "@/pages/settings/SettingsAccountMigrationPage";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsDataExportSection } from "@/pages/settings/components/SettingsDataExportSection";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { FC } from "react";

export const SettingsExportsPage: FC = () => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="Export Notes and Collections">
        <div className={importWrapperStyle}>
          <SettingsDataExportSection />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
};
