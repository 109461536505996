import { IMDSDateRangePickerProps } from "./date-range-picker.types";
import { StyledDateRangePicker, StyledGroup, LabelWrapper, Dash } from "./date-range-picker.style";
import { MDSButton, MDSDateSegment, MDSRangeCalendar } from "@/mds";
import { MDSDialog, MDSPopover } from "@/mds/components/overlays";
import { MDSDateInput } from "@/mds/components/forms";
import { MDSLabel } from "@/mds/components/forms/label";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MDSDateField } from "@/mds/components/date-time/date-field";
import { useState } from "react";

export function MDSDateRangePicker(props: IMDSDateRangePickerProps) {
  const { label, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  const arrowType = isOpen ? MdsIconKind.DropdownOpened : MdsIconKind.DropdownClosed;

  return (
    <StyledDateRangePicker {...rest} onOpenChange={setIsOpen}>
      {label && (
        <LabelWrapper>
          <MDSLabel>{label}</MDSLabel>
        </LabelWrapper>
      )}
      <StyledGroup>
        <MDSDateField slot="start">
          <MDSDateInput>{segment => <MDSDateSegment segment={segment} />}</MDSDateInput>
        </MDSDateField>
        <Dash aria-hidden="true">–</Dash>
        <MDSDateField slot="end">
          <MDSDateInput>{segment => <MDSDateSegment segment={segment} />}</MDSDateInput>
        </MDSDateField>
        <MDSButton size="sm">
          <MdsIcon kind={arrowType} />
        </MDSButton>
      </StyledGroup>
      <MDSPopover>
        <MDSDialog>
          <MDSRangeCalendar />
        </MDSDialog>
      </MDSPopover>
    </StyledDateRangePicker>
  );
}
