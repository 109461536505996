import { z } from "zod";

export const paragraphEditSchema = z.object({
  edit_description: z.string(),
  new_content: z.string(),
  paragraph_id: z.string(),
});

export const newParagraphSchema = z.object({
  after_paragraph_id: z.string(),
  edit_description: z.string(),
  new_content: z.string(),
});

export type ParagraphEdit = z.infer<typeof paragraphEditSchema>;
export type NewParagraph = z.infer<typeof newParagraphSchema>;
