import Dexie from "dexie";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { AppStore } from "@/store/AppStore";
import {
  IndexedTemplateSyncUpdateValue,
  TemplatesSortByKind,
  TemplateSearchParams,
  TemplateIndexTuple,
  TemplatesFilterCollections,
} from "@/store/templates/types";

const DEFAULT_SORT_BY = SortByKind.LastModified;

export async function searchTemplates(
  store: AppStore,
  params: TemplateSearchParams
): Promise<TemplateIndexTuple[]> {
  const sortBy = params.sortBy ?? DEFAULT_SORT_BY;

  const filterIndex = params.lens === LensKind.All ? "" : "is_owned_by_me+";

  const filteredSortMap: Record<TemplatesSortByKind, string> = {
    [SortByKind.LastCreated]: `[${filterIndex}created_at+model_id]`,
    [SortByKind.LastModified]: `[${filterIndex}modified_at+model_id]`,
    [SortByKind.LastViewed]: `[${filterIndex}last_viewed_at+model_id]`,
    [SortByKind.LastShared]: `[${filterIndex}shared_with_me_at+model_id]`,
    [SortByKind.Alphabetical]: `[${filterIndex}lowercase_title+model_id]`,
    [SortByKind.LastUsed]: `[${filterIndex}last_used_at+model_id]`,
  };

  const betweenClause = getBetweenClause(params);
  let query = store.templates.localTable
    .where(filteredSortMap[sortBy] ?? filteredSortMap[DEFAULT_SORT_BY])
    .between(betweenClause[0], betweenClause[1])
    .filter(template => {
      return (
        matchesQuery(template, params.query) &&
        matchesModifiedBy(template, params.filter?.modifiedBy) &&
        matchesCreatedBy(template, params.filter?.createdBy) &&
        matchesExcludeIds(template, params.excludeIds) &&
        matchesIds(template, params.filter?.ids) &&
        matchesInCollections(template, params.filter?.collections)
      );
    });

  if (params.limit) query = query.limit(params.limit);

  if (sortBy !== SortByKind.Alphabetical) query = query.reverse();

  return query.keys() as unknown as Promise<TemplateIndexTuple[]>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBetweenClause(params: TemplateSearchParams): any[][] {
  if (params.lens === LensKind.AddedByMe) {
    return [
      [1, Dexie.minKey, Dexie.minKey], // Filter out empty string values
      [1, [[]], [[]]],
    ];
  }
  if (params.lens === LensKind.SharedWithMe) {
    return [
      [0, Dexie.minKey, Dexie.minKey], // Filter out empty string values
      [0, [[]], [[]]],
    ];
  }
  return [
    [Dexie.minKey, Dexie.minKey],
    [[[]], [[]]],
  ];
}

const matchesQuery = (template: IndexedTemplateSyncUpdateValue, query?: string) => {
  return !query || template.lowercase_title.includes(query.toLowerCase());
};

const matchesModifiedBy = (template: IndexedTemplateSyncUpdateValue, modifiedBy?: string[]) => {
  if (!modifiedBy?.length) return true;
  const modifiedBySet = new Set(template.model_data.modified_by_space_account_ids);
  return modifiedBy.some(id => modifiedBySet.has(id));
};

const matchesCreatedBy = (template: IndexedTemplateSyncUpdateValue, createdBy?: string[]) => {
  if (!createdBy?.length) return true;
  return createdBy.some(id => id === template.model_data.owned_by_space_account_id);
};

const matchesExcludeIds = (template: IndexedTemplateSyncUpdateValue, excludeIds?: string[]) => {
  if (!excludeIds?.length) return true;
  return !excludeIds.includes(template.model_id);
};

const matchesIds = (template: IndexedTemplateSyncUpdateValue, ids?: string[]) => {
  if (!ids?.length) return true;
  return ids.includes(template.model_id);
};

const matchesInCollections = (
  template: IndexedTemplateSyncUpdateValue,
  collections?: TemplatesFilterCollections
) => {
  // TODO: This should use collectionItems instead of target_scopes...
  const templateCollectionIds = new Set(
    template.model_data.target_scopes
      .filter(scope => scope.scope_kind === "COLLECTION_SCOPE")
      .map(scope => scope.value.collection_id)
  );

  const targetCollectionIds = new Set(collections?.ids || []);
  const intersection = templateCollectionIds.intersection(targetCollectionIds);

  if (collections?.ids.length === 0 && collections?.mode !== "none") return true;
  switch (collections?.mode) {
    case "all":
      return intersection.size === targetCollectionIds.size;
    case "any":
      return intersection.size > 0;
    case "anyOrNone":
      return intersection.size > 0 || templateCollectionIds.size === 0;
    case "none":
      return templateCollectionIds.size === 0;
  }
  return true;
};
