import { uuidModule } from "@/modules/uuid";
import { resolveSpaceAccountTemplateSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountTemplateSyncModelUuid";
import {
  SpaceAccountTemplateModelData,
  SpaceAccountTemplateUpsertedSyncUpdateValue,
} from "@/store/templates/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IMarkTemplateViewedOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";

export class MarkTemplateViewedOperation extends BaseSyncOperation<IMarkTemplateViewedOperation> {
  get operationKind(): SyncOperationKind {
    return "MARK_TEMPLATE_VIEWED";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      spaceAccountId,
      templateId: this.payload.template_id,
    });
    const spaceAccountTemplateObservable =
      this.store.spaceAccountTemplates.get(spaceAccountTemplateId);

    const value: SpaceAccountTemplateUpsertedSyncUpdateValue = {
      model_id: spaceAccountTemplateId,
      model_kind: "SPACE_ACCOUNT_TEMPLATE",
      model_version: spaceAccountTemplateObservable?.modelVersion ?? 0,
      model_data: {
        space_account_id: spaceAccountId,
        template_id: this.payload.template_id,
        last_viewed_at: this.committedAt,
        last_used_at: spaceAccountTemplateObservable?.modelData.last_used_at ?? null,
        shared_with_me_at: spaceAccountTemplateObservable?.modelData.shared_with_me_at ?? null,
        shared_with_me_by_space_account_id:
          spaceAccountTemplateObservable?.modelData.shared_with_me_by_space_account_id ?? null,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const syncUpdate: OptimisticSyncUpdate<SpaceAccountTemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };
    return [syncUpdate];
  }

  async triggerRecompute() {
    const spaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    const spaceAccountTemplateId = resolveSpaceAccountTemplateSyncModelUuid({
      spaceAccountId,
      templateId: this.payload.template_id,
    });
    await this.store.spaceAccountTemplates.recompute(spaceAccountTemplateId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
