import { SpaceAccountTopicItemObservable } from "@/store/topics/SpaceAccountTopicItemObservable";
import { SpaceAccountTopicItemModelData } from "@/store/topics/types";
import { action, observable, override, runInAction, makeObservable, ObservableMap } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  HydrationSyncUpdateResponse,
  OptimisticSyncUpdate,
  PreloadingState,
  SyncModelKind,
  SyncUpdateValue,
} from "@/store/sync/types";
import { SpaceAccountTopicItemIndexes } from "@/store/topics/SpaceAccountTopicItemIndexes";
import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";
import { objectModule } from "@/modules/object";
import { logger } from "@/modules/logger";
import { isClientUpgradeError } from "@/store/utils/errors";
import { api } from "@/modules/api";
import { LogoutState } from "@/components/sync/types";

export class AppStoreSpaceAccountTopicItemsStore extends BaseSyncModelStore<
  SpaceAccountTopicItemObservable,
  SpaceAccountTopicItemModelData
> {
  public preloadingState: PreloadingState = PreloadingState.NotStarted;
  public preloadStateByNoteId: ObservableMap<string, PreloadingState> = new ObservableMap();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountTopicItem, ...injectedDeps });
    makeObservable<AppStoreSpaceAccountTopicItemsStore, "isLoggingOut">(this, {
      isLoggingOut: true,
      createSyncModel: false,
      computeIndexes: override,
      preloadingState: observable,
      preloadStateByNoteId: observable,
      preloadAll: action,
      preloadForNote: action,
      maybePreloadForNote: action,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountTopicItemModelData>
  ): SpaceAccountTopicItemObservable {
    return new SpaceAccountTopicItemObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<SpaceAccountTopicItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<SpaceAccountTopicItemModelData>[];
  }): Record<string, unknown> {
    return new SpaceAccountTopicItemIndexes({ remoteData, optimisticUpdates }).indexes;
  }

  async preloadAll() {
    const spaceAccountTopicItemPreloadingState =
      await this.store.memDb.settings.getSpaceAccountTopicItemPreloadingState();

    if (spaceAccountTopicItemPreloadingState === PreloadingState.Complete) {
      logger.debug({
        message:
          "[SYNC][AppStoreSpaceAccountTopicItemsStore] Space account topic items preloading was already complete",
      });

      return;
    }

    if (
      this.preloadingState === PreloadingState.InProgress ||
      this.preloadingState === PreloadingState.Complete
    ) {
      logger.debug({
        message:
          "[SYNC][AppStoreSpaceAccountTopicItemsStore] Space account topic items preloading is already in progress or complete",
      });

      return;
    }

    runInAction(() => (this.preloadingState = PreloadingState.InProgress));

    let hasError = false;
    let nextPageToken: string | undefined =
      (await this.store.memDb.settings.getSpaceAccountTopicItemLastHydrateCursor()) || undefined;
    let hasNextPage = false;

    do {
      try {
        if (hasNextPage && !nextPageToken) {
          logger.error({
            message:
              "[SYNC][AppStoreSpaceAccountTopicItemsStore] Unexpected hydration_session_cursor while preloading space account topic items",
            info: { hasNextPage, nextPageToken: nextPageToken || "undefined" },
          });

          return;
        }

        // stop hydrating if we're logging out
        if (this.isLoggingOut()) {
          return;
        }

        const response: HydrationSyncUpdateResponse = await api.get(`/v2/sync/updates/hydrate`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              hydration_session_cursor: nextPageToken,
              only_sync_model_kinds: [SyncModelKind.SpaceAccountTopicItem],
              page_size: 1000,
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) this.store.sync.handleClientUpgradeError();
          hasError = true;
          break;
        }

        const updates = response.data?.results || [];
        const values = updates.map(
          update => update.value as SyncUpdateValue<SpaceAccountTopicItemModelData>
        );

        logger.info({
          message:
            "[SYNC][AppStoreSpaceAccountTopicItemsStore] Preloading space account topic items: Processing updates and saving cursor...",
          info: { updates: updates.length || 0 },
        });

        // stop hydrating if we're logging out (checking again because fetch is async)
        if (this.isLoggingOut()) {
          return;
        }

        await this.store.memDb.transaction(
          "rw",
          [this.remoteTable, this.store.memDb.settings.table],
          async () => {
            await this.remoteTable.bulkPut(values);
            await this.store.memDb.settings.setSpaceAccountTopicItemLastHydrateCursor({
              cursor: response.data?.hydration_session_cursor || "",
            });
          }
        );

        nextPageToken = response.data?.hydration_session_cursor || undefined;
        hasNextPage = response.data?.has_next_page || false;

        logger.info({
          message:
            "[SYNC][AppStoreSpaceAccountTopicItemsStore] Preloading space account topic items: Saved cursor",
          info: {
            cursor: response.data?.hydration_session_cursor || "undefined",
            hasNextPage,
            nextPageToken: nextPageToken || "undefined",
          },
        });
      } catch (e) {
        logger.error({
          message:
            "[SYNC][AppStoreSpaceAccountTopicItemsStore] Error preloading space account topic items",
          info: { error: objectModule.safeErrorAsJson(e as Error) },
        });
        hasError = true;
        break;
      }
    } while (hasNextPage);

    if (hasError) {
      runInAction(() => (this.preloadingState = PreloadingState.Failed));
      logger.error({
        message:
          "[SYNC][AppStoreSpaceAccountTopicItemsStore] Failed to preload space account topic items",
      });
      return;
    }

    await this.bulkRecomputeAll();

    runInAction(async () => {
      this.preloadingState = PreloadingState.Complete;
    });

    await this.store.memDb.settings.setSpaceAccountTopicItemPreloadingState(
      PreloadingState.Complete
    );

    console.debug(
      "[SYNC][AppStoreSpaceAccountTopicItemsStore] Space account topic items preloaded"
    );
  }

  async maybePreloadForNote(noteId: string) {
    if (this.preloadingState === PreloadingState.Complete) return;
    await this.preloadForNote(noteId);
  }

  async preloadForNote(noteId: string) {
    let nextPageToken: string | undefined = undefined;
    let hasNextPage = false;
    const updatedIds: string[] = [];

    if (this.preloadStateByNoteId.get(noteId) === PreloadingState.Complete) return;
    runInAction(() => this.preloadStateByNoteId.set(noteId, PreloadingState.InProgress));

    do {
      try {
        const response: HydrationSyncUpdateResponse = await api.get(
          "/v2/sync-updates/hydrate/topic-items",
          {
            params: {
              query: {
                note_ids: [noteId],
                space_id: this.store.spaces.myPersonalSpaceId,
                hydration_session_cursor: nextPageToken,
                page_size: 1000,
              },
            },
          }
        );

        if (response.error) {
          if (isClientUpgradeError(response.error)) this.store.sync.handleClientUpgradeError();
          logger.error({
            message:
              "[SYNC][AppStoreSpaceAccountTopicItemsStore] Error preloading topic items for note",
            info: { noteId, error: response.error },
          });
          break;
        }

        const updates = response.data?.results || [];
        const values: SyncUpdateValue<SpaceAccountTopicItemModelData>[] = [];

        for (const update of updates) {
          values.push(update.value as SyncUpdateValue<SpaceAccountTopicItemModelData>);
          updatedIds.push(update.value.model_id);
        }

        await this.store.memDb.transaction("rw", [this.remoteTable], async () => {
          await this.remoteTable.bulkPut(values);
        });

        nextPageToken = response.data?.hydration_session_cursor || undefined;
        hasNextPage = response.data?.has_next_page || false;
      } catch (e) {
        logger.error({
          message:
            "[SYNC][AppStoreSpaceAccountTopicItemsStore] Error preloading topic items for note",
          info: { noteId, error: objectModule.safeErrorAsJson(e as Error) },
        });
        break;
      }
    } while (hasNextPage);

    await this.bulkRecompute(updatedIds);
    runInAction(() => this.preloadStateByNoteId.set(noteId, PreloadingState.Complete));
  }

  private isLoggingOut() {
    if (
      this.store.sync.logoutState === LogoutState.LoggingOut ||
      this.store.sync.logoutState === LogoutState.LoggedOut
    ) {
      logger.info({
        message:
          "[SYNC][AppStoreSpaceAccountTopicItemsStore] Stopping preloading space account topic items because we're logging out",
      });
      return true;
    }

    return false;
  }
}
