import { EventContext } from "@/domains/metrics/context";
import { toastModule } from "@/modules/toast";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable } from "@/store/note";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
export const addNotesToCollection = async ({
  notes,
  collection,
  store,
  eventContext,
}: {
  notes: (INoteObservable | TemplateObservable)[];
  collection: CollectionObservable;
  store: AppStore;
  eventContext: EventContext;
}) => {
  if (!notes.length) return;

  await Promise.all(
    notes.map(note =>
      collection.itemList.addItem({
        itemId: note.id,
        itemKind: note instanceof TemplateObservable ? "TEMPLATE" : "NOTE",
        triggerSuccessToast: notes.length === 1,
        eventContext,
      })
    )
  );

  if (notes.length === 1) return;

  toastModule.triggerToast({
    content: (
      <div>
        {`${notes.length === 1 ? notes[0].title : notes[0] instanceof TemplateObservable ? "Templates" : "Notes"} added to `}
        <a onClick={() => store.navigation.goToCollection({ collectionId: collection.id })}>
          {collection.label}
        </a>
        {"."}
      </div>
    ),
  });
};
