import { ChangeEventHandler, Key, useMemo, useState } from "react";
import {
  MdsDropdown,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
  MdsDropdownProps,
  MdsDropdownSearchInputHeader,
} from "@/design-system/components/dropdown";
import { css } from "@/domains/emotion";
import {
  MdsMenuItemSelectionVariant,
  MdsMenuItemSize,
} from "@/design-system/components/menu-item/types";
import { MdsMenuItem } from "@/design-system/components/menu-item/MdsMenuItem";
import { MdsIconKind } from "@/design-system/components/icon";
import { ToggleButton } from "@/components/filters/ToggleButton/ToggleButton";
import { pluralize } from "@/modules/pluralize";
import { CollectionsFacetFilterItem } from "@/components/filters/CollectionsFacetFilter/types";
import { CollectionIcon } from "@/components/collection/CollectionIcon";
import { MDSToggleButton, MDSToggleButtonGroup } from "@/mds";
import styled from "@emotion/styled";
import { isUndefined } from "lodash-es";

interface CollectionsFacetFilterProps
  extends Omit<MdsDropdownProps, "contentList" | "isOpen" | "onOpenChange" | "children"> {
  buttonLabel?: string;
  buttonPrefix?: string;
  searchText: string;
  onSearchTextChange: ChangeEventHandler<HTMLInputElement>;
  onSearchTextClear: () => void;
  items: CollectionsFacetFilterItem[];
  onSelectItem: (id: string) => void;
  onSelectClear: () => void;
  selectedModeKey: string;
  onSelectMode: (keys: Set<Key>) => void;
  noCollectionIsSelected: boolean;
  onNoCollectionIsSelected: (isSelected: boolean) => void;
  selectedItems: string[];
  supportNoCollectionOption?: boolean;
  supportModeSelection?: boolean;
}

export const CollectionsFacetFilter = ({
  buttonLabel,
  buttonPrefix,
  items,
  searchText,
  onSearchTextChange,
  onSearchTextClear,
  onSelectItem,
  onSelectClear,
  selectedModeKey,
  onSelectMode,
  noCollectionIsSelected,
  onNoCollectionIsSelected,
  selectedItems,
  supportNoCollectionOption,
  supportModeSelection = true,
  ...dropdownProps
}: CollectionsFacetFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSelectedItems = selectedItems.length > 0;

  const computedButtonLabel = useMemo(() => {
    const count = selectedItems.length;
    if (!isUndefined(buttonPrefix)) {
      if (selectedItems.length === 1) {
        return `${buttonPrefix} ${selectedItems[0]}`;
      }

      const conjunction = selectedModeKey === "in-any-of" ? "or" : "and";

      if (count > 1) {
        return `${buttonPrefix} ${selectedItems[0]} ${conjunction} ${count - 1} ${pluralize(count - 1, "other", "others")}`;
      }

      return `${buttonLabel}`;
    }

    if (!count) {
      return `${buttonLabel}`;
    }

    return `${selectedItems.length} ${buttonLabel}`;
  }, [buttonLabel, buttonPrefix, selectedItems, selectedModeKey]);

  const contentListItems: MdsDropdownItem[] = useMemo(() => {
    const output: MdsDropdownItem[] = [];

    if (supportNoCollectionOption && selectedModeKey === "in-any-of") {
      output.push({
        kind: MdsDropdownItemKind.Other,
        id: "no-collection",
        content: (
          <MdsMenuItem
            title="No Collection"
            size={MdsMenuItemSize.Medium}
            isSelected={noCollectionIsSelected}
            onClick={() => onNoCollectionIsSelected(!noCollectionIsSelected)}
            icon={<CollectionIcon variant="placeholder" />}
            selectionVariant={MdsMenuItemSelectionVariant.RadioButton}
          />
        ),
      });
    }

    for (const item of items) {
      if (!item.isVisible) {
        continue;
      }

      output.push({
        kind: MdsDropdownItemKind.Other,
        id: item.id,
        content: (
          <MdsMenuItem
            title={item.title}
            subtitle={item.subtitle}
            size={MdsMenuItemSize.Medium}
            isSelected={!!item.isSelected}
            onClick={() => onSelectItem(item.id)}
            icon={<CollectionIcon collectionId={item.id} />}
            selectionVariant={MdsMenuItemSelectionVariant.RadioButton}
          />
        ),
      });
    }

    if (output.length === 0) {
      output.push({
        kind: MdsDropdownItemKind.Other,
        id: "no-collection",
        content: (
          <EmptyText>
            {searchText
              ? "No results match your current search"
              : "You haven't created any collections yet"}
          </EmptyText>
        ),
      });
    }
    return output;
  }, [
    items,
    onSelectItem,
    selectedModeKey,
    noCollectionIsSelected,
    onNoCollectionIsSelected,
    supportNoCollectionOption,
    searchText,
  ]);

  const contentList: MdsDropdownContentList = useMemo(
    () => ({
      items: contentListItems,
      header: {
        hideDivider: true,
        children: (
          <>
            <MdsDropdownSearchInputHeader
              onChange={onSearchTextChange}
              onClear={onSearchTextClear}
              value={searchText}
              placeholder="Filter"
            />
            <SectionDivider />
            {supportModeSelection && (
              <ToggleGroupWrapper>
                <MDSToggleButtonGroup
                  selectionMode="single"
                  onSelectionChange={onSelectMode}
                  selectedKeys={[selectedModeKey]}
                >
                  <MDSToggleButton ariaLabel="In any of" id="in-any-of">
                    In any of
                  </MDSToggleButton>
                  <MDSToggleButton ariaLabel="In all of" id="in-all-of">
                    In all of
                  </MDSToggleButton>
                </MDSToggleButtonGroup>
              </ToggleGroupWrapper>
            )}
          </>
        ),
        height: 50,
      },
    }),
    [
      onSearchTextChange,
      onSearchTextClear,
      searchText,
      contentListItems,
      onSelectMode,
      selectedModeKey,
      supportModeSelection,
    ]
  );

  return (
    <div data-test-id="collections-facet-filter">
      <MdsDropdown
        contentList={contentList}
        {...dropdownProps}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        innerStyles={{
          Row: { className: rowStyles },
          Content: { className: contentStyles },
        }}
      >
        <ToggleButton
          setIsOpen={setIsOpen}
          iconKind={MdsIconKind.Collection}
          hasSelectedItems={hasSelectedItems}
          computedButtonLabel={computedButtonLabel}
          onClear={() => {
            onSelectClear();
          }}
        />
      </MdsDropdown>
    </div>
  );
};

const rowStyles = css({
  overflow: "visible",
});

const contentStyles = css({
  maxHeight: 320,
  minWidth: 176,
});

const ToggleGroupWrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing.sm} 0`,
}));

const SectionDivider = styled.div(({ theme }) => ({
  height: 1,
  width: "100%",
  backgroundColor: theme.colors.grey.x25,
}));

const EmptyText = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  padding: theme.spacing.smd,
}));
