import { MdsModal } from "@/design-system/components/modal";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";

import { ImportIsRunningModalContent } from "@/components/modal/imports/import-is-running-modal-content";

interface V2TransitionModalProps {}

export const ImportIsRunningModal = observer<V2TransitionModalProps>(() => {
  const { store } = useAppStore();

  const isOpen = store.accountTransition.importProcessStartedModalIsOpen;

  const handleCloseModal = () => {
    store.accountTransition.closeImportProcessStartedModal();
  };

  const handleGoToImports = () => {
    store.navigation.goToImportFrom1dot0();
    handleCloseModal();
  };

  return (
    <MdsModal isModalOpen={isOpen} handleCloseModal={handleCloseModal}>
      <ImportIsRunningModalContent
        handleGoToImports={handleGoToImports}
        handleCloseModal={handleCloseModal}
      />
    </MdsModal>
  );
});
