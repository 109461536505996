import { DatePicker, Group } from "react-aria-components";

import styled from "@emotion/styled";

export const StyledDatePicker = styled(DatePicker)``;

export const StyledGroup = styled(Group)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 200px;

  border: 1px solid ${({ theme }) => theme.colors.grey.x100};
  border-radius: ${({ theme }) => theme.borderRadius.mediumLarge}px;
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.sm};

  &[data-focus-within] {
    border-color: ${({ theme }) => theme.colors.grey.x300};
  }
`;

export const LabelWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm} 0;
`;
