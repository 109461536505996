import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { ThreadsListPageList } from "./components/ThreadsListPageList";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { FadeBottom } from "@/components/horizontal-scroll/FadeBottom";
import { ThreadsListPageHeader } from "./components/ThreadsListPageHeader";
import { MdsPanelContainer } from "@/design-system/components/panel";

interface ThreadsListPageProps {}

export const ThreadsListPage: FC<ThreadsListPageProps> = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.threadsListPage;

  useEffectOnMount(() => {
    page.resetState();
  });

  return (
    <>
      <ThreadsListPageHeader />
      <MdsPanelContainer>
        <ThreadsListPageList />
        <FadeBottom />
      </MdsPanelContainer>
    </>
  );
});
