import { Maybe } from "@/domains/common/types";
import { QueryObservable } from "@/store/queries/QueryObservable";
import {
  QueueProcessingState,
  SyncModelData,
  SyncUpdate,
  SyncUpdateResponse,
} from "@/store/sync/types";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
} from "mobx";
import { BaseSyncActionQueue } from "@/store/sync/BaseSyncActionQueue";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { GuestAppStore } from "@/store";
import { DateTime, Duration } from "luxon";
import { BootstrapState, OutboundSyncStatus, SyncState } from "@/components/sync/types";
import { first } from "lodash-es";
import { standardizeSyncId } from "@/store/sync/utils";
import { IntervalObservable } from "@/store/common";
import { liveQuery, Subscription } from "dexie";
import {
  BOOTSTRAP_TIMEOUT,
  BOOTSTRAP_HEARTBEAT_INTERVAL,
  SYNC_LOCK_ID,
} from "@/store/sync/constants";

const ONE_MINUTE = 60 * 1000;

const SYNC_IS_UP_TO_DATE_DURATION = Duration.fromObject({ seconds: 10 });
const SYNC_STATUS_UPDATE_INTERVAL = 2000;
const MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED_MS = 10_000;

export interface BaseQueryValue {
  results: SyncUpdateResponse[];
  /** Matches the return type from the server. */
  latest_sync_id?: string | null;
}

export abstract class AppStoreBaseSyncStore<
  Store extends AppStore | GuestAppStore,
  SyncActionQueue extends BaseSyncActionQueue<AppStore | GuestAppStore>,
  QueryValue extends BaseQueryValue,
> extends AppSubStore<Store> {
  public actionQueue: SyncActionQueue;
  public initializationFailed = false;

  /**
   * These are "Inbound" sync attributes.
   * "Outbound" sync attributes are stored on our ActionQueue.
   */
  protected lastSyncId: Maybe<string> = undefined;
  latestSpaceAccountSequenceId: Maybe<number> = undefined;

  lastSyncedAt: Maybe<DateTime> = undefined;
  isBootstrapping = false;
  isSyncing = false;
  _isUpToDateInterval: IntervalObservable;
  hasCompletedInitialSync = false;

  private pollingDisposer: Maybe<IReactionDisposer> = undefined;
  protected pollingInterval = ONE_MINUTE;

  abstract createSyncActionQueue(): SyncActionQueue;
  abstract finishProcessingQueryResponse(_: QueryValue): Promise<void>;
  abstract syncQuery: QueryObservable<QueryValue>;
  abstract subscribe(): void;
  abstract unsubscribe(): void;
  abstract processSyncUpdate(
    update: SyncUpdate<SyncModelData>,
    options?: { hydrating?: boolean }
  ): Promise<void>;

  abstract fetchAndSaveBootstrapEvents(): Promise<{
    lastSyncId: Maybe<string>;
    count: number;
  }>;
  abstract bulkProcessLocal(): Promise<void>;

  public syncStatus: OutboundSyncStatus = OutboundSyncStatus.Idle;
  private syncStatusInterval: Maybe<NodeJS.Timeout> = undefined;

  public isBootstrapActiveTab = false;
  public bootstrapStateSubscription: Maybe<Subscription> = undefined;
  public bootstrapState: BootstrapState = BootstrapState.Idle;
  public bootstrapDuration: Maybe<number> = undefined;

  constructor(injectedDeps: AppSubStoreArgs<Store>) {
    super(injectedDeps);

    this.actionQueue = this.createSyncActionQueue();
    this._isUpToDateInterval = new IntervalObservable({
      duration: Duration.fromObject({ seconds: 5 }),
    });

    makeObservable<
      this,
      "lastSyncId" | "pollingDisposer" | "pollingInterval" | "syncStatusInterval"
    >(this, {
      bootstrapDuration: true,
      startPolling: true,
      _isUpToDateInterval: observable,
      actionQueue: observable,
      lastSyncId: observable,
      latestSpaceAccountSequenceId: observable,

      pollingDisposer: false,
      pollingInterval: true,

      createSyncActionQueue: false,
      finishProcessingQueryResponse: action,
      syncQuery: computed,
      subscribe: action,
      unsubscribe: action,

      processSyncUpdate: action,
      fetchAndSaveBootstrapEvents: action,
      bulkProcessLocal: action,
      bootstrap: action,
      stopSync: action,
      resetSync: action,
      isBootstrapping: observable,
      hasCompletedInitialSync: observable,
      isSyncing: observable,
      lastSyncedAt: observable,
      initializationFailed: observable,
      initialize: action,
      isReady: computed,
      isUpToDate: computed,

      // BOOTSTRAP
      restoreLastSyncId: action,
      saveLastSyncId: action,
      clearLastSyncId: action,
      bootstrapState: observable,
      bootstrapStateSubscription: observable,
      bootstrapProgressMessage: computed,
      isBootstrapActiveTab: observable,
      checkBootstrapStatus: action,

      outboundLastSyncedAt: computed,
      pendingOutboundOperationCount: computed,
      earliestPendingOutboundOperationCommittedAt: computed,
      syncStatus: observable,
      updateSyncStatus: action,

      syncState: computed,
      syncStatusInterval: false,
      isQueueStuck: false,
      isSyncingForSomeTime: computed,
    });

    reaction(
      () => [this.actionQueue.processingState, this.pendingOutboundOperationCount],
      () => {
        this.updateSyncStatus();
      }
    );

    onBecomeObserved(this, "syncStatus", () => {
      this.syncStatusInterval = setInterval(() => {
        this.updateSyncStatus();
      }, SYNC_STATUS_UPDATE_INTERVAL);
    });

    onBecomeUnobserved(this, "syncStatus", () => {
      clearInterval(this.syncStatusInterval);
      this.syncStatusInterval = undefined;
    });

    onBecomeObserved(this, "bootstrapState", () => {
      this.bootstrapStateSubscription?.unsubscribe();
      this.bootstrapStateSubscription = liveQuery(() =>
        this.store.memDb.settings.getBootstrapState()
      ).subscribe({
        next: state => {
          runInAction(() => {
            this.bootstrapState = state ?? BootstrapState.Idle;
          });
        },
      });
    });

    onBecomeUnobserved(this, "bootstrapState", () => {
      this.bootstrapStateSubscription?.unsubscribe();
    });
  }

  public async initialize() {
    runInAction(() => {
      this.isBootstrapping = true;
    });

    try {
      await this.bootstrap();
      this.actionQueue.start();
      this.startPolling();
      this.subscribe();
    } catch (err) {
      logger.error({
        message: "[SYNC][AppStoreSyncStore] [useEffectOnMount] AppStore failed to initialize.",
        info: { err: objectModule.safeErrorAsJson(err as Error) },
      });

      await this.clearLastSyncId();
      runInAction(() => {
        this.initializationFailed = true;
      });
    } finally {
      runInAction(() => {
        this.isBootstrapping = false;
      });
    }
  }

  /**
   * We use this pollingDisposer to observe the `this.syncQuery` and call.
   *
   * (It is a QueryObservable, so that when it starts being observed, it
   * automatically starts polling.)
   *
   * In the future, we might consider refactoring `this.syncQuery` to use a more
   * linear flow (rather than the indirect one polling via QueryObservable).
   */
  public startPolling() {
    if (this.pollingDisposer) return;

    console.debug(
      "[SYNC][AppStoreBaseSyncStore][startPolling] Started observing the `this.syncQuery`."
    );

    this.pollingDisposer = reaction(
      () => this.syncQuery.data,
      data => {
        console.debug(
          `[SYNC][AppStoreBaseSyncStore][startPolling] Received data up to latest_sync_id=${data?.latest_sync_id ?? "null"}`
        );
      }
    );
  }

  public async saveLastSyncId(syncId: string) {
    this.lastSyncId = standardizeSyncId(syncId);

    await this.store.memDb.settings.setLastSyncId({ syncId });
  }

  public async clearLastSyncId() {
    await this.store.memDb.settings.clearLastSyncId();
  }

  public async restoreLastSyncId() {
    const syncId = await this.store.memDb.settings.getLastSyncId();

    if (!syncId) {
      /**
       * If it isn't set, we try the backwards-compatible version.
       *
       * Wrapped in a try-catch because `standardizeSyncId` may
       * throw an error if the syncId is malformed.
       *
       * We can remove this once everyone is cut-over to CVRs, using
       * the force-upgrade-client flow.
       *
       * TODO: @MacroMackie follow up with this on Friday, Nov 15.
       */
      try {
        const lastUpdate = await this.store.memDb.syncUpdates
          .orderBy("locally_committed_at")
          .last();

        if (lastUpdate) {
          const lastSyncId = standardizeSyncId(lastUpdate.sync_id);

          logger.debug({
            message: "[Sync] Set lastSyncId from db",
            info: { lastSyncId: `${lastSyncId}` },
          });

          this.lastSyncId = lastSyncId;
        }
      } catch (unknownErr) {
        logger.error({
          message: "[Sync] Failed to restore lastSyncId using legacy logic.",
          info: { unknownErr: objectModule.safeErrorAsJson(unknownErr as Error) },
        });
      }
    }

    this.lastSyncId = syncId;
  }

  public async bootstrap() {
    /*
     * Bootstrap phases:
     * 1. Fetch bootstrap events from the server. While fetching, commit updates to the remote DB.
     * 2. Run bulkRecompute for each table in prioritized order to commit changes to the local DB
     */

    await navigator.locks.request(
      SYNC_LOCK_ID,
      { mode: "exclusive", ifAvailable: false },
      async () => {
        const existingLastSyncId = await this.store.memDb.settings.getLastSyncId();
        if (existingLastSyncId !== undefined) {
          await this.store.memDb.settings.setBootstrapState(BootstrapState.Completed);
          return;
        }

        await this.store.memDb.settings.setBootstrapState(BootstrapState.Downloading);
        runInAction(() => {
          this.isBootstrapActiveTab = true;
        });

        const heartbeatInterval = setInterval(() => {
          this.store.memDb.settings.setBootstrapHeartbeat();
        }, BOOTSTRAP_HEARTBEAT_INTERVAL);

        const bootstrapStartTime = performance.now();
        console.debug("[SYNC][AppStoreSyncStore] Starting Bootstrap...");

        const { lastSyncId, count } = await this.fetchAndSaveBootstrapEvents();

        if (lastSyncId === undefined) {
          await this.store.memDb.settings.setBootstrapState(BootstrapState.Failed);
          // Handled in the try-catch block in `initialize`
          throw new Error("Bootstrap failed");
        }

        const now = performance.now();
        logger.debug({
          message: "[SYNC][AppStoreSyncStore] Start bulk recomputing models...",
          info: { count },
        });

        await this.store.memDb.settings.setBootstrapState(BootstrapState.Processing);
        await this.bulkProcessLocal();

        const end = performance.now();
        logger.debug({
          message: "[SYNC][AppStoreSyncStore] End bulk recomputing models...",
          info: { duration: `${end - now}ms` },
        });

        this.lastSyncedAt = DateTime.now();
        await this.saveLastSyncId(lastSyncId);
        const bootstrapEndTime = performance.now();
        this.bootstrapDuration = bootstrapEndTime - bootstrapStartTime;

        await this.store.memDb.settings.setBootstrapState(BootstrapState.Completed);
        runInAction(() => {
          this.isBootstrapActiveTab = false;
          this.bootstrapDuration = bootstrapEndTime - bootstrapStartTime;
        });
        clearInterval(heartbeatInterval);

        console.debug("[SYNC][AppStoreSyncStore] Completed Bootstrap", {
          lastSyncId: this.lastSyncId,
          count,
        });
      }
    );
  }

  public async checkBootstrapStatus() {
    /**
     * When another tab is performing a bootstrap, other tabs need to check that the process is still ongoing
     * If it's not, another tab should take over and try to bootstrap.
     */
    if (this.isBootstrapActiveTab) return;

    const bootstrapHeartbeat = await this.store.memDb.settings.getBootstrapHeartbeat();
    if (!bootstrapHeartbeat) return;

    const now = performance.now();
    const diff = now - bootstrapHeartbeat;

    if (diff > BOOTSTRAP_TIMEOUT) await this.bootstrap();
  }

  get bootstrapProgressMessage() {
    if (this.bootstrapState === BootstrapState.Downloading) return "Downloading your data...";
    if (this.bootstrapState === BootstrapState.Processing) return "Processing your data...";
    if (this.bootstrapState === BootstrapState.Completed) return "Almost done!";
    return "Loading...";
  }

  get isReady() {
    return !!this.lastSyncedAt;
  }

  get isUpToDate() {
    const lastSync = this.lastSyncedAt ?? DateTime.fromMillis(0);

    const now = this._isUpToDateInterval.value;

    /**
     * If we've synced within the last "SYNC_IS_UP_TO_DATE_DURATION" seconds,
     * we consider ourselves "up to date."
     */
    return lastSync.diff(now).milliseconds <= SYNC_IS_UP_TO_DATE_DURATION.milliseconds;
  }

  public stopSync() {
    this.actionQueue.pause();
    this.unsubscribe();
    this.pollingDisposer?.();
    this.pollingDisposer = undefined;
  }

  public resetSync() {
    this.actionQueue.reset();
  }

  public isQueueStuck(): boolean {
    const count = this.pendingOutboundOperationCount;

    if (count === 0) {
      return false;
    }

    if (!this.outboundLastSyncedAt) {
      return false;
    }

    if (
      !this.earliestPendingOutboundOperationCommittedAt ||
      this.earliestPendingOutboundOperationCommittedAt >
        DateTime.utc().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED_MS)
    ) {
      return false;
    }

    return (
      this.outboundLastSyncedAt <
      DateTime.now().minus(MAX_PENDING_DURATION_BEFORE_CONSIDERED_PAUSED_MS)
    );
  }

  public get isSyncingForSomeTime() {
    return !!this.outboundLastSyncedAt && this.outboundLastSyncedAt < DateTime.now().minus(2000);
  }

  public updateSyncStatus() {
    const count = this.pendingOutboundOperationCount;
    const state = this.actionQueue.processingState;

    if (count > 0 && this.isQueueStuck()) {
      this.syncStatus = OutboundSyncStatus.Paused;
      return;
    }

    if (count > 0) {
      this.syncStatus = OutboundSyncStatus.Syncing;
      return;
    }

    if (state === QueueProcessingState.NotReady || state === QueueProcessingState.Paused) {
      this.syncStatus = OutboundSyncStatus.Paused;
      return;
    }

    this.syncStatus = OutboundSyncStatus.Idle;
  }

  get outboundLastSyncedAt(): Maybe<DateTime> {
    return this.actionQueue.lastProcessedAt;
  }

  get pendingOutboundOperationCount(): number {
    return this.actionQueue.processing.length;
  }

  get earliestPendingOutboundOperationCommittedAt(): Maybe<DateTime> {
    const firstOperation = first(this.actionQueue.processing);

    if (!firstOperation) {
      return undefined;
    }

    return DateTime.fromISO(firstOperation.committedAt);
  }

  get syncState(): SyncState {
    return {
      status: this.syncStatus,
      pendingOperationCount: this.pendingOutboundOperationCount,
      outboundLastSyncedAt: this.outboundLastSyncedAt,
      inboundLastSyncedAt: this.lastSyncedAt,
    };
  }
}
