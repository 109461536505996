import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import React, { memo, forwardRef } from "react";

export interface MdsButtonChipProps {
  onClick?: () => void;
  onClickButton?: () => void;
  buttonIcon: MdsIconKind;
  isSelected?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const MdsButtonChip = memo(
  forwardRef<HTMLDivElement, MdsButtonChipProps>(
    ({ children, onClick, onClickButton, buttonIcon, isSelected, isDisabled }, ref) => {
      return (
        <div
          ref={ref}
          className={css(
            chipStyles,
            isSelected && selectedStyles,
            isDisabled && disabledStyles,
            onClick && clickableStyles
          )}
          onClick={!isDisabled ? onClick : undefined}
        >
          {children}
          <div
            className={css(closeButtonStyles, isDisabled && closeButtonDisabledStyles)}
            onClick={!isDisabled ? onClickButton : undefined}
          >
            <MdsIcon kind={buttonIcon} />
          </div>
        </div>
      );
    }
  )
);

const chipStyles = css({
  borderRadius: "4px",
  padding: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: mdsColors().grey.x50,
  color: mdsColors().grey.x700,
  cursor: "default",
  fontSize: "14px",
  lineHeight: "20px",
  whiteSpace: "nowrap",
  "&:hover": {
    background: mdsColors().grey.x100,
    color: mdsColors().grey.x700,
  },
  "&> svg": {
    margin: "0 6px 0 0",
    padding: 0,
    width: "16px",
    height: "16px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const clickableStyles = css({
  cursor: "pointer",
});

const selectedStyles = css({
  background: mdsColors().blue.x400,
  color: mdsColors().white,
  "&:hover": {
    background: mdsColors().blue.x400,
    color: mdsColors().white,
  },
});

const disabledStyles = css({
  background: mdsColors().grey.x50,
  color: mdsColors().grey.x500,
  "&:hover": {
    background: mdsColors().grey.x50,
    color: mdsColors().grey.x500,
  },
});

const closeButtonStyles = css({
  marginLeft: "8px",
  width: "16px",
  height: "16px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

const closeButtonDisabledStyles = css({
  cursor: "default",
});
