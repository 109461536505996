import { IndexedBoolean } from "@/domains/common/types";
import { ApiSchema } from "@/modules/api";
import {
  UpsertedSyncUpdateResponse,
  DeletedSyncUpdateResponse,
  SyncUpdateValue,
} from "@/store/sync/types";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { TemplatesListPageFilterParams } from "@/modules/filters/templates/types";

/**
 * TEMPLATES
 */

export type TemplateModelData = ApiSchema["TemplateSyncModelDataResponseSchema"];

export type TemplateUpsertedSyncUpdateValue = ApiSchema["TemplateSyncModelResponseSchema"];
export type TemplateDeletedSyncUpdateValue = ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type TemplateSyncUpdateValue =
  | TemplateUpsertedSyncUpdateValue
  | TemplateDeletedSyncUpdateValue;

export type TemplateUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  kind: "UPSERTED";
  value: TemplateUpsertedSyncUpdateValue;
};
export type TemplateDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  kind: "DELETED";
  value: TemplateDeletedSyncUpdateValue;
};
export type TemplateSyncUpdate = TemplateUpsertedSyncUpdate | TemplateDeletedSyncUpdate;

/**
 * TEMPLATE CONTENT DOCUMENTS
 */

export type TemplateContentDocumentModelData =
  ApiSchema["TemplateContentDocumentSyncModelDataResponseSchema"];

export type TemplateContentDocumentUpsertedSyncUpdateValue =
  ApiSchema["TemplateContentDocumentSyncModelResponseSchema"];
export type TemplateContentDocumentDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type TemplateContentDocumentSyncUpdateValue =
  | TemplateContentDocumentUpsertedSyncUpdateValue
  | TemplateDeletedSyncUpdateValue;

export type TemplateContentDocumentUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  kind: "UPSERTED";
  value: TemplateContentDocumentUpsertedSyncUpdateValue;
};
export type TemplateContentDocumentDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  kind: "DELETED";
  value: TemplateContentDocumentDeletedSyncUpdateValue;
};
export type TemplateContentDocumentSyncUpdate =
  | TemplateContentDocumentUpsertedSyncUpdate
  | TemplateContentDocumentDeletedSyncUpdate;

/**
 * SPACE ACCOUNT TEMPLATES
 */

export type SpaceAccountTemplateModelData =
  ApiSchema["SpaceAccountTemplateSyncModelDataResponseSchema"];

export type SpaceAccountTemplateUpsertedSyncUpdateValue =
  ApiSchema["SpaceAccountTemplateSyncModelResponseSchema"];
export type SpaceAccountTemplateDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type SpaceAccountTemplateSyncUpdateValue =
  | SpaceAccountTemplateUpsertedSyncUpdateValue
  | SpaceAccountTemplateDeletedSyncUpdateValue;

export type SpaceAccountTemplateUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  kind: "UPSERTED";
  value: SpaceAccountTemplateUpsertedSyncUpdateValue;
};
export type SpaceAccountTemplateDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  kind: "DELETED";
  value: SpaceAccountTemplateDeletedSyncUpdateValue;
};
export type SpaceAccountTemplateSyncUpdate =
  | SpaceAccountTemplateUpsertedSyncUpdate
  | SpaceAccountTemplateDeletedSyncUpdate;

export type TemplateIndexData = {
  title: string;
  lowercase_title: string;
  created_at: string;
  modified_at: string;
  shared_with_me_at: string;
  last_used_at: string;
  last_viewed_at: string;
  is_owned_by_me: IndexedBoolean;
};

export type IndexedTemplateSyncUpdateValue = SyncUpdateValue<TemplateModelData> & TemplateIndexData;

/**
 * SEARCH
 */

export type TemplatesSearchParams = {
  lens: TemplatesLensKind;
  sortBy: TemplatesSortByKind;
  filter?: TemplatesFilterParams;
  limit: number;
};

export type TemplateIndexTuple =
  | [is_owned_by_me: IndexedBoolean, datetime: string, model_id: string]
  | [datetime: string, model_id: string];

export type TemplatesLensKind = LensKind.All | LensKind.AddedByMe | LensKind.SharedWithMe;

export type TemplatesSortByKind =
  | SortByKind.LastModified
  | SortByKind.LastCreated
  | SortByKind.LastViewed
  | SortByKind.LastShared
  | SortByKind.LastUsed
  | SortByKind.Alphabetical;

export type TemplatesFilterParams = {
  createdBy?: string[];
  modifiedBy?: string[];
  collections?: TemplatesFilterCollections[];
  ids?: string[];
};

export type TemplatesFilterCollections = {
  ids: string[];
  mode: CollectionsFilterMode;
};

/**
 * The mode to filter by.
 * - "all": Only include notes that are in all of the provided collections.
 * - "any": Include notes that are in any of the provided collections.
 * - "anyOrNone": Include notes that are in any of the provided collections or not in any collection.
 * - "none": Only include notes that are not in any collection
 */
export type CollectionsFilterMode = "all" | "any" | "anyOrNone" | "none";

export interface TemplateSearchParams {
  query?: string;
  lens: TemplatesLensKind;
  sortBy?: TemplatesSortByKind;
  filter?: TemplatesListPageFilterParams;
  excludeIds?: string[];
  limit?: number;
}

export enum TemplateVariableCode {
  UserName = "user-name",
  UserFirstName = "user-first-name",
  DateToday = "date-today",
}
