import { AppStore } from "@/store/AppStore";
import { Dexie } from "dexie";

export const searchUploadedFiles = async ({
  store,
  batchId,
}: {
  store: AppStore;
  batchId?: string;
}): Promise<string[]> => {
  // Create a collection to query
  let collection = store.uploadedFiles.localTable
    .where("model_id")
    .between(Dexie.minKey, Dexie.maxKey);

  // If a batch ID is provided, filter by batch ID
  if (batchId) {
    collection = collection.filter(file => file.model_data.uploaded_file_batch_id === batchId);
  }

  // Get all uploaded files from the local table with filtering
  const indexes = (await collection.keys()) as unknown as [model_id: string][];

  return indexes.map(index => index[0]);
};
