import styled from "@emotion/styled";

export const CopilotCardHeader = styled.div(({ theme }) => ({
  alignItems: `center`,
  display: `flex`,
  justifyContent: `space-between`,
  gap: theme.spacing.xs,
  padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.xs}`,
  "&:hover .voting-buttons-wrapper": {
    opacity: 1,
  },
}));
