import { usePublicAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Outlet } from "react-router";
import { useSearchParams, useHref, useNavigate } from "react-router-dom";
import { RouterProvider } from "react-aria-components";
import { useRedirectToLandingPage } from "@/app/router/useRedirectToLandingPage";

interface RootOutletProps {}

export const RootOutlet: FC<RootOutletProps> = observer(() => {
  useRedirectToLandingPage();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { publicStore } = usePublicAppStore();
  publicStore.useInitializePublicStoreEffects({ searchParams, setSearchParams });

  return (
    <RouterProvider navigate={navigate} useHref={useHref}>
      <Outlet />
    </RouterProvider>
  );
});
