import { Uuid } from "@/domains/global/identifiers";
import { enumModule } from "@/modules/enum";
import { validationModule } from "@/modules/validation";
import { DataImportKind } from "@/store/data-imports/types";

import { get, isPlainObject, isString } from "lodash-es";
import { computed, makeObservable } from "mobx";
import { JsonObject } from "type-fest";

export class DataImportSourceMapObservable {
  public readonly id: Uuid;
  public readonly value: JsonObject;

  constructor({ id, value }: { id: Uuid; value: Record<string, string> }) {
    this.id = id;
    this.value = value;

    makeObservable(this, {
      id: true,
      value: true,
      kind: computed,
      contentInfoMap: computed,
    });
  }

  static fromApiData({ data }: { data: JsonObject }): DataImportSourceMapObservable | null {
    const id = get(data, "id");

    if (!validationModule.isUuid(id)) {
      return null;
    }

    const value = get(data, "value");

    if (!isPlainObject(value)) {
      return null;
    }

    return new DataImportSourceMapObservable({
      id,
      value: value as Record<string, string>,
    });
  }

  get kind(): DataImportKind | null {
    const kindValue = this.value.kind;

    if (!isString(kindValue)) {
      return null;
    }

    const parsedKind = enumModule.findMatchingStringValue(DataImportKind, kindValue);

    if (!parsedKind) {
      return null;
    }

    return parsedKind;
  }

  get contentInfoMap(): Record<string, string> {
    const contentInfoMap = get(this.value, "content_info_map");

    if (!isPlainObject(contentInfoMap)) {
      return {};
    }

    return contentInfoMap as Record<string, string>;
  }
}
