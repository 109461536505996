import { UNTITLED_NOTE_TITLE } from "@/domains/untitled/untitled";
import { uuidModule } from "@/modules/uuid";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import {
  TemplateContentDocumentModelData,
  TemplateContentDocumentSyncUpdateValue,
  TemplateModelData,
  TemplateUpsertedSyncUpdateValue,
} from "@/store/templates/types";
import { ICreateTemplateOperation } from "@/store/sync/operations/types";
import {
  OptimisticSyncUpdate,
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
} from "@/store/sync/types";
import { resolveTemplateContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveTemplateContentDocumentSyncModelUuid";

/**
 * Matches an empty tiptap JSON document.
 *
 * We use this instead of something like "" because it makes it possible to
 * merge the content with user-generated content (don't need extra checks for
 * empty string, ...)
 *
 * This also matches the default value for NoteContentDocument's encoded_content
 * on the backend.
 */
const INITIAL_TEMPLATE_CONTENT_DOCUMENT_ENCODED_CONTENT = "AAA=";

export class CreateTemplateOperation extends BaseSyncOperation<ICreateTemplateOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_TEMPLATE";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const templateId = this.payload.id;

    const templateValue: TemplateUpsertedSyncUpdateValue = {
      model_id: templateId,
      model_kind: "TEMPLATE",
      model_version: 0,
      model_data: {
        title: "",
        primary_label: UNTITLED_NOTE_TITLE,
        locally_created_at: this.committedAt,
        locally_modified_at: this.committedAt,
        modified_by_space_account_ids: [this.store.spaceAccounts.myPersonalSpaceAccountId],
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        created_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        target_scopes: [],
        is_auto_sharing_enabled: true,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };
    const templateUpserted: OptimisticSyncUpdate<TemplateModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value: templateValue,
    };

    const templateContentDocumentId = resolveTemplateContentDocumentSyncModelUuid({ templateId });

    const templateContentDocument: TemplateContentDocumentSyncUpdateValue = {
      model_id: templateContentDocumentId,
      model_kind: "TEMPLATE_CONTENT_DOCUMENT",
      model_version: 0,
      model_data: {
        template_id: templateId,
        encoded_content: INITIAL_TEMPLATE_CONTENT_DOCUMENT_ENCODED_CONTENT,
      },
    };
    const templateContentDocumentUpserted: OptimisticSyncUpdate<TemplateContentDocumentModelData> =
      {
        optimistic_update_id: uuidModule.generate(),
        locally_committed_at: this.committedAt,
        kind: "UPSERTED",
        value: templateContentDocument,
      };

    return [templateUpserted, templateContentDocumentUpserted];
  }

  async triggerRecompute() {
    await this.store.templates.recompute(this.payload.id);
    const templateContentDocumentId = resolveTemplateContentDocumentSyncModelUuid({
      templateId: this.payload.id,
    });
    await this.store.templateContentDocuments.recompute(templateContentDocumentId);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.ignoreError();
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.ignoreError(SyncErrorHandlingType.RetryWithLimit);
  }
}
