import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { EntityNotFoundType, NotFoundPage } from "@/pages/not-found/NotFoundPage";
import { MdsItemListSkeletonRow } from "@/design-system/components/item-list/rows/MdsItemListSkeletonRow";
import { MdsItemListRowType, MdsItemListSize } from "@/design-system/components/item-list/types";
import { MdsItemListSkeletonRowData } from "@/design-system/components/item-list/types/MdsItemListRowTypes";

export const ThreadsViewPage = observer(function ThreadsViewPage() {
  const { store: appStore, pageStore } = useAppStore();
  const store = pageStore.threadsViewPage;
  store.useEffects();

  if (store.showNotFound) {
    /**
     * @TODO - Swap this.
     */
    return <NotFoundPage entityType={EntityNotFoundType.Template} />;
  }

  const thread = store.itemObservable;

  const skeletonRowData: MdsItemListSkeletonRowData = {
    key: "skeleton",
    type: MdsItemListRowType.Skeleton,
    size: MdsItemListSize.Large,
    payload: {},
  };

  if (!thread) return <MdsItemListSkeletonRow data={skeletonRowData} />;

  const threadEvents = appStore.threadEvents.all.filter(event => event.threadId === thread.id);

  const threadEventData = threadEvents.map(event => event.modelData);

  return (
    <>
      <div>
        <section>
          <h2>Thread</h2>
          <div>
            <span>ID: {thread.id}</span>
          </div>
        </section>

        <section>
          <pre>{JSON.stringify(thread.modelData, null, 2)}</pre>
        </section>

        <section>
          <h3>Thread Events</h3>
          <div>
            {threadEventData.length > 0 ? (
              threadEventData.map((event, index) => (
                <div key={index}>{JSON.stringify(event, null, 2)}</div>
              ))
            ) : (
              <p>No events found for this thread</p>
            )}
          </div>
        </section>
      </div>
    </>
  );
});
