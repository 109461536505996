// import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { ErrorOverlay, LoadingOverlay } from "@/components/overlay";
import { BootstrapState } from "@/components/sync/types";
import { useAppStore } from "@/store";
import { BOOTSTRAP_TIMEOUT } from "@/store/sync/constants";
import { useInterval } from "usehooks-ts";

export const BootstrapOverlay = observer(function BootstrapOverlay() {
  const { store } = useAppStore();
  const bootstrapState = store.sync.bootstrapState;
  const bootstrapProgressMessage = store.sync.bootstrapProgressMessage;
  const isBootstrapActiveTab = store.sync.isBootstrapActiveTab;

  const isLoading =
    bootstrapState === BootstrapState.Downloading || bootstrapState === BootstrapState.Processing;

  useInterval(async () => {
    if (!isLoading || isBootstrapActiveTab) {
      return;
    }

    await store.sync.checkBootstrapStatus();
  }, BOOTSTRAP_TIMEOUT);

  return (
    <>
      <ErrorOverlay isVisible={bootstrapState === BootstrapState.Failed} />
      <LoadingOverlay isVisible={isLoading} loadingMessage={bootstrapProgressMessage} />
    </>
  );
});
