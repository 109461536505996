import { uuidModule } from "@/modules/uuid";
import { PusherEventData, PusherEventKind } from "@/domains/pusher/constants";

type ParagraphEdits =
  PusherEventData<PusherEventKind.ASSISTANT_NOTE_EDIT_SUGGESTED>["paragraph_edits"];
type NewParagraphs =
  PusherEventData<PusherEventKind.ASSISTANT_NOTE_EDIT_SUGGESTED>["new_paragraphs"];

// FOR BOULDER HACKATHON DEMO ONLY
export const __dangerouslyAcceptEdits = (
  paragraph_edits: ParagraphEdits,
  new_paragraphs: NewParagraphs
) => {
  // Process paragraph edits
  for (const edit of paragraph_edits) {
    const { paragraph_id, new_content, edit_description } = edit;
    console.log(`Applying edit to paragraph ${paragraph_id}: ${edit_description}`);
    // Find the DOM element with data-id matching paragraph_id and update its content
    const paragraphElement = document.querySelector(
      `[data-id="${paragraph_id}"]`
    ) as HTMLParagraphElement;
    if (paragraphElement) {
      paragraphElement.textContent = new_content;
    } else {
      console.warn(`Could not find paragraph element with data-id="${paragraph_id}"`);
    }
  }

  // Process new paragraphs
  for (const newParagraph of new_paragraphs) {
    const { after_paragraph_id, new_content, edit_description } = newParagraph;
    console.log(`Adding new paragraph after ${after_paragraph_id}: ${edit_description}`);
    // Find the DOM element with data-id matching after_paragraph_id
    const afterParagraphElement = document.querySelector(
      `[data-id="${after_paragraph_id}"]`
    ) as HTMLParagraphElement;

    if (afterParagraphElement) {
      // Create a new paragraph element
      const newParagraphElement = document.createElement("p");
      newParagraphElement.textContent = new_content;
      newParagraphElement.dataset.id = uuidModule.generate(); // Generate a new ID for the paragraph

      // Insert the new paragraph after the reference paragraph
      if (afterParagraphElement.nextSibling) {
        afterParagraphElement.parentNode?.insertBefore(
          newParagraphElement,
          afterParagraphElement.nextSibling
        );
      } else {
        afterParagraphElement.parentNode?.appendChild(newParagraphElement);
      }
    } else {
      console.warn(`Could not find paragraph element with data-id="${after_paragraph_id}"`);

      // If we can't find the specific paragraph, try to append to the editor
      const editorElement = document.querySelector(".tiptap.ProseMirror");
      if (editorElement) {
        // Create a new paragraph element
        const newParagraphElement = document.createElement("p");
        newParagraphElement.textContent = new_content;
        newParagraphElement.dataset.id = uuidModule.generate(); // Generate a new ID for the paragraph

        // Append to the editor
        editorElement.appendChild(newParagraphElement);
        console.log("Added paragraph to the end of the editor");
      } else {
        console.warn("Could not find editor element with class 'tiptap ProseMirror'");
      }
    }
  }

  return { paragraph_edits, new_paragraphs };
};
