import styled from "@emotion/styled";
import { ChatMessageSection } from "@/domains/chat";
import { Text } from "@/components/messages/item/section/text";
import { DraftNote } from "@/components/messages/item/section/draft-note";
import { FactCard } from "@/components/messages/item/section/fact-card";
import { SpaceAccountChatMessageSection } from "@/store/chat/types";
import { SectionWrapper } from "@/components/messages/item/section/section-wrapper";
import { UserMessage } from "@/components/messages/item/section/user-message";
import { OnClickMemItem } from "@mem-labs/common-editor";
import { StatusUpdate } from "@/components/messages/item/section/status-update";

interface Props {
  spaceAccountSection?: SpaceAccountChatMessageSection;
  section: ChatMessageSection;
  isSystemMessage: boolean;
  inSidePanel?: boolean;
  dataTestId?: string;
  isIncomplete?: boolean;
  onClickMemItem: OnClickMemItem;
}

export function Section({
  section,
  spaceAccountSection,
  isSystemMessage,
  inSidePanel,
  dataTestId: rootTestId,
  isIncomplete,
  onClickMemItem,
}: Props) {
  const dataTestId = `${rootTestId}-section`;

  const isProcessing = isSystemMessage && section.status === "PROCESSING";

  if (isProcessing && !section.value.content) return null;

  if (isSystemMessage && section.kind === "STATUS_UPDATE") {
    return <StatusUpdate data={section.value} dataTestId={dataTestId} />;
  }

  return (
    <SectionWrapper isProcessing={isProcessing}>
      <Content sectionKind={section.kind}>
        {!isSystemMessage && <UserMessage data={section.value} onClickMemItem={onClickMemItem} />}

        {isSystemMessage && section.kind === "TEXT" && (
          <Text
            data={section.value}
            isIncomplete={isIncomplete}
            dataTestId={dataTestId}
            onClickMemItem={onClickMemItem}
          />
        )}
        {isSystemMessage && section.kind === "FACT_CARD" && (
          <FactCard data={section.value} isIncomplete={isIncomplete} dataTestId={dataTestId} />
        )}

        {isSystemMessage && section.kind === "STATUS_UPDATE" && (
          <StatusUpdate data={section.value} dataTestId={dataTestId} />
        )}

        {isSystemMessage && section.kind === "DRAFT_NOTE" && (
          <DraftNote
            isProcessing={isProcessing}
            dataTestId={dataTestId}
            section={section}
            spaceAccountSection={spaceAccountSection}
            inSidePanel={inSidePanel}
            isIncomplete={isIncomplete}
            onClickMemItem={onClickMemItem}
          />
        )}
      </Content>
    </SectionWrapper>
  );
}

const Content = styled.div<{ sectionKind?: string }>(({ sectionKind }) => ({
  display: "flex",
  flexDirection: "column",
  ...(sectionKind === "STATUS_UPDATE"
    ? {
        gap: 0,
        margin: 0,
        padding: 0,
        "> div": {
          marginBottom: 0,
        },
      }
    : {
        gap: "0.75rem",
      }),
}));
