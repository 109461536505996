import { observer } from "mobx-react-lite";
import { FC } from "react";

import { css, cx } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations/colors";
import {
  MdsItemListSkeletonRowData,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsSkeleton } from "@/design-system/components/loader/MdsSkeleton";

export interface MdsItemListSkeletonRowProps {
  data: MdsItemListSkeletonRowData;
  className?: string;
}

export const MdsItemListSkeletonRow: FC<MdsItemListSkeletonRowProps> = observer(
  ({ data: { size = MdsItemListSize.Medium }, className }) => {
    return (
      <div className={cx(wrapperStyles, rowSizeStyleMapping[size], className)}>
        <MdsSkeleton className={iconWrapperStyles} />
        <MdsSkeleton className={contentStyles} />
        <MdsSpacer />
        <MdsSkeleton className={timestampStyles} />
      </div>
    );
  }
);

const wrapperStyles = css({
  alignItems: "center",
  backgroundColor: mdsColors().grey.x0,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  paddingLeft: 4,
  paddingRight: 16,
  transition: "0.08s ease",
  userSelect: "none",
  width: "100%",

  "&:hover": {
    backgroundColor: mdsColors().grey.x25,
  },
});

const iconWrapperStyles = css({
  margin: "0 8px",
  width: "18px",
});

const contentStyles = css({
  flexGrow: 1,
  margin: "0 2px",
  maxWidth: "45%",
});

const timestampStyles = css({
  margin: 0,
  width: 61,
});

const rowSizeStyleMapping: { [key in MdsItemListSize]: string } = {
  [MdsItemListSize.Medium]: css({
    height: 48,
  }),
  [MdsItemListSize.Large]: css({
    height: 56,
  }),
  [MdsItemListSize.XLarge]: css({
    minHeight: "70px",
  }),
  [MdsItemListSize.Full]: css({
    minHeight: "70px",
  }),
};
