import Dexie from "dexie";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { AppStore } from "@/store/AppStore";
import { NotesFilterParams } from "@/store/note/types";
import {
  IndexedNoteSyncUpdateValue,
  NotesFilterOlderThan,
  NotesIndexTuple,
  NotesSearchParams,
  NotesSortByKind,
  NotesFilterCollections,
} from "@/store/note";
import { CollectionItemIndexTuple } from "@/store/collection-items/types";
import { convertEncodedContentToNoteContent } from "@/modules/notes/conversions";
import { getAllWords } from "@/domains/search";
import { AppStoreNoteStore } from "@/store/note/AppStoreNoteStore";

export const searchForNotes = async ({
  store,
  params,
}: {
  store: AppStore;
  params: NotesSearchParams;
}): Promise<NotesIndexTuple[]> => {
  const { lens, sortBy, limit, filter } = params;

  // TODO: figure out the way to use something safer than a string
  const filterIndex = lens === LensKind.All ? "is_available" : "is_available+is_owned_by_me";

  const sortByIndex = AppStoreNoteStore.sortByIndexes[sortBy];

  const sortMap: Record<NotesSortByKind, string> = {
    [SortByKind.LastCreated]: `[${filterIndex}+${sortByIndex}+model_id]`,
    [SortByKind.LastModified]: `[${filterIndex}+${sortByIndex}+model_id]`,
    [SortByKind.LastViewed]: `[${filterIndex}+${sortByIndex}+model_id]`,
    [SortByKind.Alphabetical]: `[${filterIndex}+${sortByIndex}+model_id]`,
    [SortByKind.LastShared]: `[${filterIndex}+${sortByIndex}+model_id]`,
  };

  const betweenClause = {
    [LensKind.All]: [
      [1, Dexie.minKey, -Infinity],
      [1, Dexie.maxKey, Infinity],
    ],
    [LensKind.AddedByMe]: [
      [1, 1, Dexie.minKey, -Infinity],
      [1, 1, Dexie.maxKey, Infinity],
    ],
    [LensKind.SharedWithMe]: [
      [1, 0, Dexie.minKey, -Infinity],
      [1, 0, Dexie.maxKey, Infinity],
    ],
  };

  let dexieCollection = store.notes.localTable
    .where(sortMap[sortBy])
    .between(betweenClause[lens][0], betweenClause[lens][1]);

  if (filter) {
    dexieCollection = prepareCreatedAtFilter(dexieCollection, filter.createdAt);
    dexieCollection = prepareModifiedAtFilter(dexieCollection, filter.modifiedAt);
    dexieCollection = prepareViewedAtFilter(dexieCollection, filter.viewedAt);
    dexieCollection = prepareSharedWithMeAtFilter(dexieCollection, filter.sharedWithMeAt);
    dexieCollection = prepareCreatedByFilter(dexieCollection, filter.createdBy);
    dexieCollection = prepareModifiedByFilter(dexieCollection, filter.modifiedBy);
    dexieCollection = prepareIdsFilter(dexieCollection, filter.ids);
    dexieCollection = prepareMediaKindsFilter(dexieCollection, filter.mediaKinds);
    // Analysis:
    //
    // In https://dexie.org/docs/liveQuery()#rules-for-the-querier-function:
    //     - Don't call non-Dexie asynchronous API:s directly from it.
    //     - If you really need to call a non-Dexie asynchronous API (such as webCrypto), wrap the returned promise through `Promise.resolve()` or Dexie.waitFor() before awaiting it.
    //
    // OTOH prepareCollectionFilter is Dexie-compatible so we should be able to call it directly.
    // Nevertheless we're not updating the Notes page automatically without Dexie.waitFor().
    dexieCollection = await Dexie.waitFor(
      prepareCollectionsFilter(dexieCollection, filter.collections, store)
    );

    dexieCollection = await Dexie.waitFor(
      prepareFullTextSearchFilter(dexieCollection, store, filter.fullTextQuery)
    );
  }

  // only alphabetical is sorted in ascending order
  if (sortBy !== SortByKind.Alphabetical) {
    dexieCollection = dexieCollection.reverse();
  }

  return dexieCollection.limit(limit).keys() as unknown as Promise<NotesIndexTuple[]>;
};

const getDateFromTo = (date: { from?: string; to?: string }) => {
  const from = date.from ? new Date(date.from).getTime() : undefined;
  const to = date.to ? new Date(date.to).getTime() : undefined;

  return { from, to };
};

const prepareCreatedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  createdAt: NotesFilterParams["createdAt"]
) => {
  if (!createdAt) {
    return dexieCollection;
  }

  if (createdAt.olderThan) {
    return prepareOlderThanFilter(dexieCollection, createdAt.olderThan, "locally_created_at");
  }

  return prepareRangeFilter(dexieCollection, "locally_created_at", createdAt);
};

const prepareModifiedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  modifiedAt: NotesFilterParams["modifiedAt"]
) => {
  if (!modifiedAt) {
    return dexieCollection;
  }

  if (modifiedAt.olderThan) {
    return prepareOlderThanFilter(dexieCollection, modifiedAt.olderThan, "modified_at");
  }

  return prepareRangeFilter(dexieCollection, "modified_at", modifiedAt);
};

const prepareViewedAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  viewedAt: NotesFilterParams["viewedAt"]
) => {
  if (!viewedAt) {
    return dexieCollection;
  }

  if (viewedAt.olderThan) {
    return prepareOlderThanFilter(dexieCollection, viewedAt.olderThan, "last_viewed_at");
  }

  return prepareRangeFilter(dexieCollection, "last_viewed_at", viewedAt);
};

const prepareSharedWithMeAtFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  sharedWithMeAt: NotesFilterParams["sharedWithMeAt"]
) => {
  if (!sharedWithMeAt) {
    return dexieCollection;
  }

  if (sharedWithMeAt.olderThan) {
    return prepareOlderThanFilter(dexieCollection, sharedWithMeAt.olderThan, "shared_with_me_at");
  }

  return prepareRangeFilter(dexieCollection, "shared_with_me_at", sharedWithMeAt);
};

const prepareCreatedByFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  createdBy: NotesFilterParams["createdBy"]
) => {
  if (!createdBy || !createdBy.length) {
    return dexieCollection;
  }

  return dexieCollection.filter(note =>
    createdBy.includes(note.model_data.owned_by_space_account_id)
  );
};

const prepareModifiedByFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  modifiedBy: NotesFilterParams["modifiedBy"]
) => {
  if (!modifiedBy || !modifiedBy.length) {
    return dexieCollection;
  }

  return dexieCollection.filter(note =>
    modifiedBy.some(id => note.model_data.modified_by_space_account_ids.includes(id))
  );
};

const prepareOlderThanFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  olderThan: NotesFilterOlderThan,
  dateField: "locally_created_at" | "modified_at" | "last_viewed_at" | "shared_with_me_at"
) => {
  if (!olderThan) {
    return dexieCollection;
  }

  const now = Date.now();
  const thresholds = {
    week: 7 * 24 * 60 * 60 * 1000,
    month: 30 * 24 * 60 * 60 * 1000,
    year: 365 * 24 * 60 * 60 * 1000,
  };

  return dexieCollection.filter(note => {
    const fieldValue = new Date(note[dateField]).getTime();
    return now - fieldValue > thresholds[olderThan];
  });
};

const prepareRangeFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  dateField: "locally_created_at" | "modified_at" | "last_viewed_at" | "shared_with_me_at",
  range:
    | NotesFilterParams["createdAt"]
    | NotesFilterParams["modifiedAt"]
    | NotesFilterParams["viewedAt"]
    | NotesFilterParams["sharedWithMeAt"]
) => {
  if (!range) {
    return dexieCollection;
  }

  const { from, to } = getDateFromTo(range);
  if (from === undefined && to === undefined) {
    return dexieCollection;
  }

  return dexieCollection.filter(note => {
    const fieldValue = new Date(note[dateField]).getTime();

    if (to && from === undefined) {
      return fieldValue <= to;
    }

    if (from && to === undefined) {
      return fieldValue >= from;
    }

    if (from && to) {
      return fieldValue >= from && fieldValue <= to;
    }

    return false;
  });
};

const prepareMediaKindsFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  mediaKinds: NotesFilterParams["mediaKinds"]
) => {
  if (!mediaKinds || !mediaKinds.length) return dexieCollection;
  const mediaKindsSet = new Set(mediaKinds);
  return dexieCollection.filter(note => {
    const noteMediaKinds = new Set(note.model_data.media_kinds);
    return noteMediaKinds.intersection(mediaKindsSet).size > 0;
  });
};

const prepareCollectionsFilter = async (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  collections: NotesFilterParams["collections"],
  store: AppStore
) => {
  if (!collections) {
    return Promise.resolve(dexieCollection);
  }

  await Promise.all(
    collections.map(collection => prepareCollectionFilter(dexieCollection, collection, store))
  );

  return dexieCollection;
};

const prepareCollectionFilter = async (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  collections: NotesFilterCollections,
  store: AppStore
) => {
  if (!collections) return dexieCollection;
  if (!collections.ids.length && collections.mode !== "none") return dexieCollection;

  // Remove duplicates from collections.ids
  const collectionIds = [...new Set(collections.ids)];

  // Create Sets to track note IDs
  const noteIdsByCollection: Record<string, Set<string>> = {};
  const allNotesInSelectedCollections = new Set<string>();

  // Initialize Sets for each collection
  collectionIds.forEach(id => {
    noteIdsByCollection[id] = new Set<string>();
  });

  const { itemsOfSelectedCollections, allCollectionItemIndexes } =
    await queryItemsOfSelectedCollections(collections, store);

  for (const item of itemsOfSelectedCollections) {
    noteIdsByCollection[item[0]].add(item[1]);
    allNotesInSelectedCollections.add(item[1]);
  }

  // In all collections mode, we filter notes by checking if the note is in all specified collections
  if (collections.mode === "all") {
    return dexieCollection.filter(note =>
      collectionIds.every(id => noteIdsByCollection[id].has(note.model_id))
    );
  }

  // In anyOrNone mode, we filter notes by checking if the note is in any of the specified collections
  // or if it is not in any of collections at all
  if (collections.mode === "anyOrNone") {
    const allNotesInAllCollections = new Set<string>(
      allCollectionItemIndexes?.map(item => item[1])
    );

    return dexieCollection.filter(note => {
      const isInAnySpecifiedCollection = collectionIds.some(collectionId =>
        noteIdsByCollection[collectionId].has(note.model_id)
      );

      return isInAnySpecifiedCollection || !allNotesInAllCollections.has(note.model_id);
    });
  }

  if (collections.mode === "none") {
    const allNotesInAllCollections = new Set<string>(
      allCollectionItemIndexes?.map(item => item[1])
    );

    return dexieCollection.filter(note => {
      return !allNotesInAllCollections.has(note.model_id);
    });
  }

  // In any mode, we filter notes by checking if the note is in any of the specified collections
  // (excluding notes in no collections)
  return dexieCollection.filter(note => {
    const isInAnySpecifiedCollection = collectionIds.some(collectionId =>
      noteIdsByCollection[collectionId].has(note.model_id)
    );

    return isInAnySpecifiedCollection;
  });
};

const prepareIdsFilter = (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  ids: NotesFilterParams["ids"]
) => {
  if (!ids || !ids.length) return dexieCollection;

  return dexieCollection.filter(note => ids.includes(note.model_id));
};

/**
 *  We can use 2 different optimization strategies:
 *
 *  1. In "any" or "any" mode we locate collection items by provided collection_ids.
 *     This is fast enough because we select and then traverse only small subset of data
 *
 *  2. In "none" or "anyOrNone" mode we have to access all collection items but we can take advantage of the indexes
 *     and select only keys (which is faster because Dexie accesses keys faster than values)
 */
const queryItemsOfSelectedCollections = async (
  collections: NotesFilterCollections,
  store: AppStore
): Promise<{
  itemsOfSelectedCollections: CollectionItemIndexTuple[];
  allCollectionItemIndexes?: CollectionItemIndexTuple[];
}> => {
  if (!collections) return Promise.resolve({ itemsOfSelectedCollections: [] });

  const { mode, ids: collectionIds } = collections;

  // In "all" or "any" mode we locate collection items by provided collection_ids.
  if (mode === "all" || mode === "any") {
    const collectionItems = await store.collectionItems.localTable
      .where("collection_id")
      .anyOf(collectionIds)
      .toArray();

    const itemsOfSelectedCollections: CollectionItemIndexTuple[] = collectionItems.map(item => [
      item.collection_id,
      item.item_id,
      item.model_id,
    ]);

    return { itemsOfSelectedCollections };
  }

  // In "none" or "anyOrNone" mode we query for all collection items _keys_
  const allCollectionItemIndexes = (await store.collectionItems.localTable
    .where("[collection_id+item_id+model_id]")
    .between(Dexie.minKey, Dexie.maxKey)
    .keys()) as unknown as CollectionItemIndexTuple[];

  let itemsOfSelectedCollections: CollectionItemIndexTuple[] = [];
  if (mode === "anyOrNone") {
    itemsOfSelectedCollections = allCollectionItemIndexes.filter(item =>
      collectionIds.includes(item[0])
    );
  }

  return { itemsOfSelectedCollections, allCollectionItemIndexes };
};

const prepareFullTextSearchFilter = async (
  dexieCollection: Dexie.Collection<IndexedNoteSyncUpdateValue>,
  store: AppStore,
  query: string | undefined
) => {
  if (!query) {
    return dexieCollection;
  }

  const terms = getAllWords(query);

  const noteIds = await store.noteContentDocuments.localTable
    .orderBy("note_id")
    .filter(noteContentDocument => {
      const encoded_content = noteContentDocument.model_data.encoded_content;
      const content = getContent(encoded_content);

      return terms.every(term => content.some(word => term.startsWith(word)));
    })
    .keys();

  return dexieCollection.filter(note => noteIds.includes(note.model_id));
};

const getContent = (encodedContent: string | null): string[] => {
  if (!encodedContent) {
    return [];
  }

  const { plaintext } = convertEncodedContentToNoteContent(encodedContent);

  return getAllWords(plaintext);
};
