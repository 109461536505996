import styled from "@emotion/styled";
import { isMac } from "@/domains/platform/isMac";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { MentionTooltipParams } from "@/components/note/types";
import { createPortal } from "react-dom";
import { ZIndex } from "@/domains/design/constants";
import { useEffect } from "react";

type TProps = {
  mentionTooltipParams?: MentionTooltipParams;
  resetMentionTooltipParams: () => void;
};

export const MentionTooltip = observer(
  ({ mentionTooltipParams, resetMentionTooltipParams }: TProps) => {
    const { visible, top, left, itemId } = mentionTooltipParams ?? {};

    useHideTooltipOnScroll({
      mentionTooltipParams,
      resetMentionTooltipParams,
    });

    const tooltipContent = (
      <AnimatePresence>
        {visible && (
          <motion.div
            key={`mention-tooltip-${itemId}`}
            initial={{ opacity: 0, transform: "translate(-50%, -90%)" }}
            animate={{ opacity: 1, transform: "translate(-50%, -100%)" }}
            exit={{ opacity: 0, transform: "translate(-50%, -90%)" }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            style={{
              position: "fixed",
              top: `${top}px`,
              left: `${left}px`,
              pointerEvents: "none",
              zIndex: ZIndex.MentionsMenu,
            }}
          >
            <Content>{isMac() ? "Opt-Click" : "Alt-Click"} to open in Copilot</Content>
          </motion.div>
        )}
      </AnimatePresence>
    );

    return createPortal(tooltipContent, document.body);
  }
);

const Content = styled.div(({ theme }) => ({
  background: theme.colors.grey.x700,
  color: theme.colors.white,
  fontSize: theme.fontSizes.xxsmall,
  padding: `${theme.spacing.xs} ${theme.spacing.xsPlus}`,
  borderRadius: theme.borderRadius.mediumPlus,
  boxShadow: theme.shadows.tooltip,
  userSelect: "none",
}));

const useHideTooltipOnScroll = ({
  mentionTooltipParams,
  resetMentionTooltipParams,
}: {
  mentionTooltipParams?: MentionTooltipParams;
  resetMentionTooltipParams: () => void;
}) => {
  useEffect(() => {
    if (!mentionTooltipParams) return;
    if (!mentionTooltipParams.visible) return;

    const handleScroll = () => {
      if (mentionTooltipParams.visible) {
        resetMentionTooltipParams();
      }
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [mentionTooltipParams, resetMentionTooltipParams]);
};
