import { LoadingOverlay } from "@/components/overlay";
import { LogoutState } from "@/components/sync/types";
import { useAppStore } from "@/store";
import { LOGOUT_TIMEOUT } from "@/store/sync/constants";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useInterval } from "usehooks-ts";

export const LogoutOverlay = observer(() => {
  const { store } = useAppStore();
  const logoutState = store.sync.logoutState;
  const navigate = useNavigate();
  const isActiveLogoutTab = store.sync.isActiveLogoutTab;

  useInterval(async () => {
    if (logoutState !== LogoutState.LoggingOut || isActiveLogoutTab) {
      return;
    }

    await store.sync.checkLogoutStatus();
  }, LOGOUT_TIMEOUT);

  useEffect(() => {
    if (logoutState === LogoutState.LoggedOut) navigate("/logout");
  }, [logoutState, navigate]);

  return (
    <LoadingOverlay
      isVisible={logoutState === LogoutState.LoggingOut}
      loadingMessage="Logging out..."
    />
  );
});
