import { Maybe } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { SyncModelPermissionEntryWithStatus, SyncModelScope } from "@/domains/sync-scopes/types";
import { AppStore } from "@/store";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { canWrite } from "@/store/sync/operations/canWrite";
import { getGrantedCollectionIdsForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedCollectionIdsForPermissions";
import { getGrantedEmailAddressesForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedEmailAddressesForPermissions";
import { getGrantedSpaceAccountIdsForPermissions } from "@/store/sync/operations/helpers/permissions/getGrantedSpaceAccountIdsForPermissions";
import {
  getPermissionsForModel,
  getRemotePermissionsForModel,
} from "@/store/sync/operations/helpers/permissions/getPermissionsForModel";
import { SyncModelData, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { liveQuery } from "dexie";
import { Subscription } from "dexie";
import { WithAppStore } from "@/store/types";
import { filter } from "lodash-es";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx";

export abstract class BaseSyncModel<ModelData extends SyncModelData> {
  public store: AppStore;

  public localSubscription: Maybe<Subscription>;
  public remoteSubscription: Maybe<Subscription>;

  public id: Uuid;
  public data: SyncUpdateValue<ModelData>;
  public remoteData: SyncUpdateValue<ModelData>;
  public isDeleted: boolean = false;
  public canAccess = true;
  public abstract modelKind: SyncModelKind;

  constructor({
    id,
    data,
    store,
  }: {
    id: Uuid;
    data: SyncUpdateValue<ModelData>;
  } & WithAppStore) {
    this.store = store;

    this.id = id;
    this.remoteData = data;
    this.data = data;

    makeObservable(this, {
      localSubscription: false,
      remoteSubscription: false,
      subscribeToLocal: action,
      unsubscribeFromLocal: action,
      subscribeToRemote: action,
      unsubscribeFromRemote: action,
      updateFromLocal: action,
      updateFromRemote: action,

      modelKind: false,
      store: false,
      id: observable,
      remoteData: observable,
      isDeleted: observable,
      canAccess: observable,

      setCanAccess: action,

      data: observable,
      modelData: computed,
      modelVersion: computed,
      modelScopes: computed,
      remotePermissions: computed,
      permissions: computed,

      sharedBy: computed,
      isOwnedByMe: computed,
      isAvailable: computed,
      canWrite: computed,

      grantedCollectionIds: computed,
      grantedSpaceAccountIds: computed,
      grantedEmailAddresses: computed,
      collections: computed,
      isShared: computed,
      hasPendingShare: computed,
      sharedWithCount: computed,
      sharedWithSpaceAccountsCount: computed,
      sharedWithLabels: computed,
    });

    onBecomeObserved(this, "data", () => this.subscribeToLocal());
    onBecomeUnobserved(this, "data", () => this.unsubscribeFromLocal());
    onBecomeObserved(this, "remoteData", () => this.subscribeToRemote());
    onBecomeUnobserved(this, "remoteData", () => this.unsubscribeFromRemote());
  }

  public subscribeToLocal() {
    this.localSubscription?.unsubscribe();
    this.localSubscription = liveQuery(() =>
      this.store.memDb.mappedTables[this.modelKind].local.get(this.id)
    ).subscribe({
      next: data =>
        runInAction(() => {
          if (data) this.data = data as SyncUpdateValue<ModelData>;
        }),
    });
  }

  public subscribeToRemote() {
    this.remoteSubscription?.unsubscribe();
    this.remoteSubscription = liveQuery(() =>
      this.store.memDb.mappedTables[this.modelKind].remote.get(this.id)
    ).subscribe({
      next: data =>
        runInAction(() => {
          if (data) this.remoteData = data as SyncUpdateValue<ModelData>;
        }),
    });
  }

  public unsubscribeFromLocal() {
    this.localSubscription?.unsubscribe();
  }

  public unsubscribeFromRemote() {
    this.remoteSubscription?.unsubscribe();
  }

  async updateFromLocal() {
    const data = await this.store.memDb.mappedTables[this.modelKind].local.get(this.id);
    if (data) this.data = data as SyncUpdateValue<ModelData>;
  }

  async updateFromRemote() {
    const data = await this.store.memDb.mappedTables[this.modelKind].remote.get(this.id);
    if (data) this.remoteData = data as SyncUpdateValue<ModelData>;
  }

  public setCanAccess(canAccess: boolean) {
    this.canAccess = canAccess;
  }

  get modelData(): ModelData {
    return this.data.model_data;
  }

  get modelVersion(): number {
    return Math.max(this.data.model_version, this.remoteData.model_version);
  }

  get modelScopes(): SyncModelScope[] {
    return this.data.model_scopes;
  }

  get remotePermissions(): SyncModelPermissionEntryWithStatus[] {
    return getRemotePermissionsForModel({
      remoteData: this.remoteData,
    });
  }

  get permissions(): SyncModelPermissionEntryWithStatus[] {
    return getPermissionsForModel({
      id: this.id,
      remoteData: this.remoteData,
      actionQueue: this.store.sync.actionQueue,
    });
  }

  get sharedBy(): ContactObservable | undefined {
    const ownedByPermission = this.remotePermissions.find(
      permission => permission.role_kind === "OWNER"
    );
    if (!ownedByPermission || this.isOwnedByMe) return undefined;
    if (!("space_account_id" in ownedByPermission)) return undefined;
    const ownedBySpaceAccountId = ownedByPermission.space_account_id;
    const contactObservable = this.store.contacts.getBySpaceAccountId(ownedBySpaceAccountId);
    return contactObservable;
  }

  get isOwnedByMe(): boolean {
    const myPersonalSpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    // Use this.permissions instead of this.remotePermissions because we want to handle optimistic creation
    const ownedByPermission = this.permissions.find(permission => permission.role_kind === "OWNER");
    if (!ownedByPermission) return false;
    if ("space_account_id" in ownedByPermission) {
      return ownedByPermission.space_account_id === myPersonalSpaceAccountId;
    } else {
      return false;
    }
  }

  get isAvailable(): boolean {
    return !this.isDeleted && this.canAccess;
  }

  get canWrite(): boolean {
    return canWrite({
      model: this,
      myPersonalSpaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      permissions: this.permissions,
      getCollectionObservableById: this.store.collections.getCollectionObservableById,
    });
  }

  get grantedCollectionIds(): string[] {
    return getGrantedCollectionIdsForPermissions({ permissions: this.remotePermissions });
  }

  get grantedSpaceAccountIds(): string[] {
    return getGrantedSpaceAccountIdsForPermissions({ permissions: this.remotePermissions });
  }

  get grantedEmailAddresses(): string[] {
    return getGrantedEmailAddressesForPermissions({ permissions: this.remotePermissions });
  }

  get collections(): CollectionObservable[] {
    return filter(
      this.grantedCollectionIds.map(collectionId =>
        this.store.collections.getCollectionObservableById({ collectionId })
      )
    ) as CollectionObservable[];
  }

  get isShared(): boolean {
    return this.remotePermissions.some(
      permission => permission.role_kind !== "OWNER" && permission.role_kind !== "REVOKED"
    );
  }

  get hasPendingShare(): boolean {
    for (const permission of this.permissions) {
      if (permission.status === "PENDING") return true;
    }
    return false;
  }

  get sharedWithCount(): number {
    return this.sharedWithLabels.length;
  }

  get sharedWithSpaceAccountsCount(): number {
    let output = 0;
    for (const permission of this.remotePermissions) {
      if (permission.role_kind === "REVOKED" || permission.role_kind === "OWNER") continue;
      if (permission.scope_kind === "SPACE_ACCOUNT_SCOPE") output++;
    }
    return output;
  }

  get sharedWithLabels(): string[] {
    const output: string[] = [];
    for (const permission of this.remotePermissions) {
      if (permission.role_kind === "REVOKED" || permission.role_kind === "OWNER") continue;
      if ("space_account_id" in permission) {
        const contact = this.store.contacts.getBySpaceAccountId(permission.space_account_id);
        if (contact) output.push(contact.profileDisplayName);
      }
      if ("collection_id" in permission) {
        const collection =
          this.store.collectionMetadata.getCollectionMetadataObservableByCollectionId(
            permission.collection_id
          );
        if (collection && collection.title) output.push(collection.title);
      }
    }
    return output;
  }
}
