import { mdsSpacings, mdsSidebarWidths } from "@/design-system/foundations";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "@emotion/styled";
import { MdsHoverContent } from "@/design-system/components/hover-content/MdsHoverContent";
import { SidebarDropdownMenu } from "@/components/layout/components/sidebar/SidebarDropdownMenu";
import { SidebarAccountInfo } from "@/components/layout/components/sidebar/SidebarAccountInfo";
import { SidebarContents } from "@/components/layout/components/sidebar/SidebarContents";
import { TOP_BAR_HEIGHT } from "@/components/layout/components/constants";

export const SidebarExpanded = observer(() => {
  const { store } = useAppStore();
  const { profileDisplayName, profilePhotoUrl, profileEmailAddress } = store.account.myAccount;

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <SidebarWrapper onClick={stopPropagation}>
      <SidebarMainWrapper>
        <MdsHoverContent
          requireClick
          content={() => <SidebarDropdownMenu className={dropdownStyles} />}
        >
          <AccountInfoWrapper>
            <SidebarAccountInfo
              withDropdownIcon
              profileDisplayName={profileDisplayName}
              profileEmailAddress={profileEmailAddress}
              profilePhotoUrl={profilePhotoUrl}
            />
          </AccountInfoWrapper>
        </MdsHoverContent>
        <SidebarContents />
      </SidebarMainWrapper>
    </SidebarWrapper>
  );
});

const SidebarWrapper = styled.div(({ theme }) => ({
  color: theme.colors.grey.x700,
  display: `flex`,
  flexDirection: `column`,
  height: `calc(100dvh - ${TOP_BAR_HEIGHT}px)`,
  opacity: 1,
  transition: `opacity 0.25s ease-in-out, transform 0.25s ease-in-out`,
  width: mdsSidebarWidths().expanded,
  flexShrink: 0,
  zIndex: ZIndex.Sidebar,
  paddingTop: 24,
}));
SidebarWrapper.displayName = "SidebarWrapper";

const SidebarMainWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  paddingLeft: mdsSpacings().md,
  paddingRight: mdsSpacings().md,
  overflow: "hidden",
});
SidebarMainWrapper.displayName = "SidebarMainWrapper";

const AccountInfoWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: mdsSpacings().xs,
  padding: mdsSpacings().sm,
});
AccountInfoWrapper.displayName = "AccountInfoWrapper";

const dropdownStyles = css({
  minWidth: `calc(${mdsSidebarWidths().expanded}px - ${mdsSpacings().md} * 2)`,
});
