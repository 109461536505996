import { ControlledSleep } from "@/domains/event-loop/synchronization";
import { APP_CONFIG } from "@/domains/global/config";
import { ExponentialBackoff } from "@/domains/network/ExponentialBackoff";

const FORCE_CHECK_EVERY_MS = 30 * 1000;
const BACKOFF_MIN_MS = 250;
const BACKOFF_MAX_MS = 10 * 1000;
const MAX_NUM_SUBSEQUENT_FAILURES = 3;

export type NetworkStatus = {
  isOnline: boolean;
  lastOnline: Date;
};

const NetworkStatus: NetworkStatus = {
  isOnline: true,
  lastOnline: new Date(),
};

type NetworkStatusSubscription = (networkStatus: NetworkStatus) => void | Promise<void>;

type NetworkDetectionContext = {
  backoff: ExponentialBackoff;
  controlledSleep: ControlledSleep;
  numSubsequentFailures: number;
  resolveWhenOnline?: () => void;
  pendingWhenOnline?: Promise<void>;
  subscriptions: NetworkStatusSubscription[];
};

const NetworkDetectionContext: NetworkDetectionContext = {
  backoff: new ExponentialBackoff(BACKOFF_MIN_MS, BACKOFF_MAX_MS),
  controlledSleep: new ControlledSleep(),
  numSubsequentFailures: 0,
  resolveWhenOnline: undefined,
  pendingWhenOnline: undefined,
  subscriptions: [],
};

export const isNetworkOk = async () => {
  try {
    const response = await fetch(APP_CONFIG.SYSTEM.HEALTH_CHECK_URL);
    const { success } = await response.json();
    return success == true && response.status >= 200 && response.status <= 299;
  } catch (_err) {
    // Fail gracefully so the request may be retried.
  }
  return false;
};

const notifySubscriptions = () => {
  NetworkDetectionContext.subscriptions.forEach(subscription => {
    subscription(NetworkStatus);
  });
};

const recordOffline = () => {
  const wasAlreadyOffline = !NetworkStatus.isOnline;
  if (wasAlreadyOffline) {
    return;
  }
  NetworkStatus.isOnline = false;
  NetworkDetectionContext.pendingWhenOnline = new Promise(resolve => {
    NetworkDetectionContext.resolveWhenOnline = resolve;
  });
  notifySubscriptions();
};

const recordOnline = () => {
  const wasAlreadyOnline = NetworkStatus.isOnline;
  NetworkStatus.isOnline = true;
  NetworkStatus.lastOnline = new Date();
  NetworkDetectionContext.numSubsequentFailures = 0;
  if (NetworkDetectionContext.resolveWhenOnline) {
    NetworkDetectionContext.pendingWhenOnline = undefined;
    NetworkDetectionContext.resolveWhenOnline();
  }
  if (!wasAlreadyOnline) {
    notifySubscriptions();
  }
};

const onlineDetectionLoop = async () => {
  for (;;) {
    const isOnline = await isNetworkOk();
    if (!isOnline) {
      recordNetworkFailure();
      await NetworkDetectionContext.controlledSleep.sleep(
        NetworkDetectionContext.backoff.getNextDelayMs()
      );
      continue;
    }
    // We're online as of now
    recordOnline();
    await NetworkDetectionContext.controlledSleep.sleep(FORCE_CHECK_EVERY_MS);
  }
};

const registerNetworkChangeEventHandlers = () => {
  const handleNetworkChange = () => {
    NetworkDetectionContext.backoff.reset();
    NetworkDetectionContext.controlledSleep.awake();
  };
  window.addEventListener("online", handleNetworkChange);
  window.addEventListener("offline", handleNetworkChange);
};

export const forceNetworkCheck = async () => {
  NetworkDetectionContext.controlledSleep.awake();
};

export const recordNetworkFailure = () => {
  NetworkDetectionContext.numSubsequentFailures++;
  if (NetworkDetectionContext.numSubsequentFailures === MAX_NUM_SUBSEQUENT_FAILURES) {
    recordOffline();
  }
};

export const getNetworkStatus = () => NetworkStatus;

export const getIsOnline = () => NetworkStatus.isOnline;

export const getWhenOnline = async () => {
  if (!NetworkDetectionContext.pendingWhenOnline) {
    return;
  }
  await NetworkDetectionContext.pendingWhenOnline;
};

export const subscribeToNetworkStatus = (subscription: NetworkStatusSubscription) => {
  NetworkDetectionContext.subscriptions.push(subscription);
  // Returns an unsubscribe() fn
  return () => {
    NetworkDetectionContext.subscriptions = NetworkDetectionContext.subscriptions.filter(
      subscription => subscription !== subscription
    );
  };
};

// Kick off the online detection loop.
registerNetworkChangeEventHandlers();
onlineDetectionLoop();
