import { MdsIconKind } from "@/design-system/components/icon";
import { MDSBanner, MDSBannerKind, MDSBannerSlotName } from "@/mds";
import styled from "@emotion/styled";

type TProps = {
  message: string;
  onDismiss: () => void;
};

export const ShareSheetModalFailedRow = ({ message, onDismiss }: TProps) => {
  return (
    <Wrapper>
      <MDSBanner
        iconKind={MdsIconKind.Exclamation}
        message={message}
        kind={MDSBannerKind.Error}
        onDismiss={onDismiss}
      >
        <RightAddon slot={MDSBannerSlotName.Right}>Failed</RightAddon>
      </MDSBanner>
    </Wrapper>
  );
};

const Wrapper = styled.div(() => ({
  width: "100%",
}));

const RightAddon = styled.div(({ theme }) => ({
  color: theme.colors.red.x500,
  fontWeight: 600,
}));
