import { MdsText } from "@/design-system/components/text/MdsText";
import { MdsTextStylingMode, MdsTextWeight } from "@/design-system/components/text/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { logger } from "@/modules/logger";
import { uuidModule } from "@/modules/uuid";
import { resolveFavoriteItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveFavoriteItemSyncModelUuid";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { resolveSpaceAccountCollectionSyncModelUuid } from "@/modules/uuid/sync-models/resolveSpaceAccountCollectionSyncModelUuid";
import {
  CollectionItemDeletedSyncUpdateValue,
  CollectionItemModelData,
} from "@/store/collection-items/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionDeletedSyncUpdateValue, CollectionModelData } from "@/store/collections/types";
import {
  FavoriteItemDeletedSyncUpdateValue,
  FavoriteItemModelData,
} from "@/store/favorite-items/types";
import {
  SpaceAccountCollectionDeletedSyncUpdateValue,
  SpaceAccountCollectionModelData,
} from "@/store/recent-items/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { SyncErrorHandlingType } from "@/store/sync/operations/errors/SyncError";
import { IDeleteCollectionOperation } from "@/store/sync/operations/types";
import {
  SyncCustomErrorData,
  SyncModelData,
  SyncOperationKind,
  OptimisticSyncUpdate,
} from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { SpaceAccountCollectionObservable } from "@/store/recent-items/SpaceAccountCollectionObservable";

export class DeleteCollectionOperation extends BaseSyncOperation<IDeleteCollectionOperation> {
  private title = UNTITLED_COLLECTION_TITLE;
  private existingCollection?: CollectionObservable;
  private existingCollectionItems: CollectionItemObservable[] = [];
  private existingFavoriteItem?: FavoriteItemObservable;
  private existingSpaceAccountCollection?: SpaceAccountCollectionObservable;

  get operationKind(): SyncOperationKind {
    return "DELETE_COLLECTION";
  }

  get mediumTitle() {
    return (
      <MdsText stylingMode={MdsTextStylingMode.InheritStyles} weight={MdsTextWeight.Medium}>
        {this.title}
      </MdsText>
    );
  }

  private get favoriteItemId() {
    return resolveFavoriteItemSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      itemId: this.payload.id,
    });
  }

  private get spaceAccountCollectionId() {
    return resolveSpaceAccountCollectionSyncModelUuid({
      spaceAccountId: this.store.spaceAccounts.myPersonalSpaceAccountId,
      collectionId: this.payload.id,
    });
  }

  async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const existingCollection = this.existingCollection;
    if (!existingCollection) return [];

    const optimisticUpdates = [];

    for (const collectionItem of this.existingCollectionItems) {
      const collectionItemValue: CollectionItemDeletedSyncUpdateValue = {
        model_id: collectionItem.id,
        model_kind: "COLLECTION_ITEM",
        model_version: collectionItem.modelVersion,
      };
      const collectionItemUpdate: OptimisticSyncUpdate<CollectionItemModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: collectionItemValue,
      };
      optimisticUpdates.push(collectionItemUpdate);
    }

    // Cascade FavoriteItem
    if (this.existingFavoriteItem) {
      const favoriteItemValue: FavoriteItemDeletedSyncUpdateValue = {
        model_id: this.existingFavoriteItem.id,
        model_kind: "FAVORITE_ITEM",
        model_version: this.existingFavoriteItem.modelVersion,
      };
      const favoriteItemUpdate: OptimisticSyncUpdate<FavoriteItemModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: favoriteItemValue,
      };
      optimisticUpdates.push(favoriteItemUpdate);
    }

    // Cascade SpaceAccountCollection
    if (this.existingSpaceAccountCollection) {
      const spaceAccountCollectionValue: SpaceAccountCollectionDeletedSyncUpdateValue = {
        model_id: this.existingSpaceAccountCollection.id,
        model_kind: "SPACE_ACCOUNT_COLLECTION",
        model_version: this.existingSpaceAccountCollection.modelVersion,
      };
      const spaceAccountCollectionUpdate: OptimisticSyncUpdate<SpaceAccountCollectionModelData> = {
        optimistic_update_id: uuidModule.generate(),

        locally_committed_at: this.committedAt,
        kind: "DELETED",
        value: spaceAccountCollectionValue,
      };
      optimisticUpdates.push(spaceAccountCollectionUpdate);
    }

    // Finally, delete Collection
    const value: CollectionDeletedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "COLLECTION",
      model_version: existingCollection.modelVersion,
    };
    const optimisticUpdate: OptimisticSyncUpdate<CollectionModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "DELETED",
      value,
    };
    optimisticUpdates.push(optimisticUpdate);

    return optimisticUpdates;
  }

  async execute() {
    this.existingCollection = await this.store.collections.getAsync(this.payload.id);
    this.existingCollectionItems =
      (await this.existingCollection?.itemList.getAllCollectionItemsAsync()) || [];
    this.existingFavoriteItem = await this.store.favoriteItems.getAsync(this.favoriteItemId);
    this.existingSpaceAccountCollection = await this.store.spaceAccountCollections.getAsync(
      this.spaceAccountCollectionId
    );

    if (!this.existingCollection) {
      logger.error({
        message: "can't find existing collection",
        info: {
          operation: getLoggableOperation(this),
        },
      });

      return;
    }

    this.title = this.existingCollection.label;

    await super.execute();
  }

  async triggerRecompute() {
    await this.store.favoriteItems.recompute(this.favoriteItemId);
    await this.recomputeDeleteCollectionItems();
    await this.store.spaceAccountCollections.recompute(this.payload.id);
    await this.store.collections.recompute(this.payload.id);
  }

  handleInvalidError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>{this.mediumTitle} could not be deleted. If this error continues, please contact support.</>
    );
  }

  handlePermissionDeniedError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        You do not have permissions to permanently delete {this.mediumTitle}. Please contact the
        collection owner.
      </>
    );
  }

  handleUnknownError(_errorData: SyncCustomErrorData) {
    this.triggerToast(
      <>
        {this.mediumTitle} could not be deleted. If this error continues, please contact support.
      </>,
      SyncErrorHandlingType.RetryWithLimit
    );
  }

  private async recomputeDeleteCollectionItems() {
    if (!this.existingCollection) return;
    await Promise.all(
      this.existingCollectionItems.map(item => this.store.collectionItems.recompute(item.id))
    );
  }
}
