import styled from "@emotion/styled";

interface StatusUpdateData {
  content: string;
}

interface Props {
  data: StatusUpdateData;
  dataTestId?: string;
}

export function StatusUpdate({ data, dataTestId }: Props) {
  return <Container data-testid={dataTestId}>{data.content}</Container>;
}

const Container = styled.div(_ => ({
  backgroundColor: "white",
  color: "#666666",
  padding: "0px 0px 8px 0px",
  fontSize: "0.85rem",
  whiteSpace: "nowrap",
  textWrap: "pretty",
}));
