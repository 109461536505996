import { useAppStore } from "@/store";
import { useCallback, useEffect, useState } from "react";

export const useChatSubscription = (conversationId?: string) => {
  const { store } = useAppStore();
  const [subscribedChannelId, setSubscribedChannelId] = useState<string | undefined>();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!conversationId) return;

      // if the operation for creating the conversation is in the action queue, wait for it to be acknowledged before subscribing to the conversation
      store.sync.actionQueue.getOperationsByModelId(conversationId).then(operations => {
        if (operations.length > 0) {
          if (operations[0].acknowledged) {
            clearInterval(timer);
            setSubscribedChannelId(conversationId);
            store.chatConversations.subscribeToLiveChatConversation(conversationId);
          }
        } else {
          clearInterval(timer);
          setSubscribedChannelId(conversationId);
          store.chatConversations.subscribeToLiveChatConversation(conversationId);
        }
      });
    }, 250);

    return () => {
      clearInterval(timer);

      if (subscribedChannelId) {
        store.chatConversations.unsubscribeFromLiveChatConversation(subscribedChannelId);
        setSubscribedChannelId(undefined);
      }
    };
  }, [conversationId, store.chatConversations, store.sync.actionQueue, subscribedChannelId]);

  // TODO: delete
  const subscribeToChat = useCallback(() => {
    if (conversationId && subscribedChannelId !== conversationId) {
      store.chatConversations.subscribeToLiveChatConversation(conversationId);
      setSubscribedChannelId(conversationId);
    }
  }, [conversationId, subscribedChannelId, store.chatConversations]);

  // TODO: delete
  const unsubscribeFromChat = useCallback(() => {
    if (conversationId) {
      store.chatConversations.unsubscribeFromLiveChatConversation(conversationId);
      setSubscribedChannelId(undefined);
    }
  }, [conversationId, store.chatConversations]);

  return {
    subscribeToChat,
    unsubscribeFromChat,
  };
};
