import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { MdsText, MdsTextWeight, MdsTextSize } from "@/design-system/components/text";
import { mdsColors } from "@/design-system/foundations/colors";
import { AppCalloutBannerInlineAction } from "@/components/banners/app-callout-banner/app-callout-banner-inline-action";

interface AppCalloutBannerSecondaryLabelProps {
  label: string;
  inlineActionLabel: string | null;
  inlineActionUrl: string | null;
}

export const AppCalloutBannerSecondaryLabel: FC<AppCalloutBannerSecondaryLabelProps> = observer(
  ({ label, inlineActionLabel, inlineActionUrl }) => {
    if (!label) return null;

    return (
      <SecondaryLabelText
        size={MdsTextSize.Small}
        lineHeight={MdsTextSize.Small}
        weight={MdsTextWeight.Regular}
        color={mdsColors().grey.x600}
      >
        {label}
        <AppCalloutBannerInlineAction label={inlineActionLabel} url={inlineActionUrl} />
      </SecondaryLabelText>
    );
  }
);

const SecondaryLabelText = styled(MdsText)({
  marginBottom: "8px",
});
