import { Maybe } from "@/domains/common/types";
import { AppStore } from "@/store/AppStore";
import { NoteContentDocumentModelData } from "@/store/note/types";
import { OptimisticSyncUpdate, SyncUpdateValue } from "@/store/sync/types";

export type NoteContentDocumentIndexData = {
  note_id: string;
};

export class NoteContentDocumentIndexes {
  private store: AppStore;
  private data: SyncUpdateValue<NoteContentDocumentModelData>;
  private optimisticUpdates: OptimisticSyncUpdate<NoteContentDocumentModelData>[];

  constructor({
    store,
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<NoteContentDocumentModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<NoteContentDocumentModelData>[];
  }) {
    this.store = store;
    this.optimisticUpdates = optimisticUpdates;
    this.data =
      (optimisticUpdates.at(-1)?.value as SyncUpdateValue<NoteContentDocumentModelData>) ||
      remoteData;
  }

  get note_id() {
    return this.data.model_data.note_id;
  }

  get indexes(): NoteContentDocumentIndexData {
    return {
      note_id: this.note_id,
    };
  }
}
