import { AssertTrue, ValidateEnumMatchesType } from "@/domains/typing/types";
import {
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFilter,
  ValidSearchEngineRequestFacetFilterKind,
  ValidSearchEngineRequestFilterKind,
  ValidSearchSortByValue,
} from "@/store/pages/SearchPageStore/types";
import { ApiSchema } from "@/modules/api";

export type SearchEngineRequest = ApiSchema["SearchEngineRequest"];
export type SearchEngineResponse = ApiSchema["SearchEngineResponse"];
export type SearchEngineResult = ApiSchema["SearchEngineResult"] & { isDeepSearch?: boolean };

export type SearchEngineParams = {
  queryString: string;
  filters: SearchEngineRequestFilter[];
  facetFilters: SearchEngineRequestFacetFilter[];
  sortBy?: ValidSearchSortByValue;
};

export type SearchEnginePaginationInfo = {
  search_session_cursor?: string;
  session_id: string;
};

export enum SearchEngineFilterKind {
  CreatedAfter = "CREATED_AFTER",
  CreatedBefore = "CREATED_BEFORE",
  ModifiedAfter = "MODIFIED_AFTER",
  ModifiedBefore = "MODIFIED_BEFORE",
}

type _SearchEngineFilter = AssertTrue<
  ValidateEnumMatchesType<ValidSearchEngineRequestFilterKind, SearchEngineFilterKind>
>;

export enum SearchEngineFacetFilterKind {
  OwnedBySpaceAccount = "OWNED_BY_SPACE_ACCOUNT",
  CreatedBySpaceAccount = "CREATED_BY_SPACE_ACCOUNT",
  ModifiedBySpaceAccount = "MODIFIED_BY_SPACE_ACCOUNT",
  InCollection = "IN_COLLECTION",
  ContainsLinkKind = "CONTAINS_LINK_KIND",
  ContainsMediaKind = "CONTAINS_MEDIA_KIND",
  FromCaptureSource = "FROM_CAPTURE_SOURCE",
}

type _SearchEngineFacetFilter = AssertTrue<
  ValidateEnumMatchesType<ValidSearchEngineRequestFacetFilterKind, SearchEngineFacetFilterKind>
>;

/** @todo - Make this consistent. */
export enum SearchEngineParamKey {
  QueryStr = "q",
  Filter = "filter",
  FacetFilter = "facet-filter",
  SortBy = "sort-by",
}

export const SEARCH_ENGINE_PARAM_KEY_SORT_ORDER: Record<SearchEngineParamKey, number> = {
  [SearchEngineParamKey.QueryStr]: 0,
  [SearchEngineParamKey.Filter]: 1,
  [SearchEngineParamKey.FacetFilter]: 2,
  [SearchEngineParamKey.SortBy]: 3,
};
