import { ReactNode, FC } from "react";

import {
  MdsCardSize,
  MdsCardKind,
  MdsCardRadius,
  MdsCardBorder,
} from "@/design-system/components/card/types";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { mdsColors } from "@/design-system/foundations/colors";
import { css, cx } from "@/domains/emotion";
import { mdsBorderRadius } from "@/design-system/foundations/common";

export interface MdsCardProps extends EmotionClassStyles {
  kind?: MdsCardKind;
  size?: MdsCardSize;
  radius?: MdsCardRadius;
  border?: MdsCardBorder;
  children: ReactNode;
  dataTestId?: string;
}

const wrapperStyles = css({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
});

const cardBorderStyleMapping: { [key in MdsCardBorder]: string } = {
  [MdsCardBorder.Hidden]: css({
    borderStyle: "hidden",
  }),
  [MdsCardBorder.Solid]: css({
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: mdsColors().grey.x25,
  }),
  [MdsCardBorder.SmartCard]: css({
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: mdsColors().grey.x100,
  }),
};

const cardSizeStyleMapping: { [key in MdsCardSize]: string } = {
  [MdsCardSize.Auto]: css({
    height: "auto",
  }),
  [MdsCardSize.Grow]: css({
    height: "100%",
  }),
};

const cardRadiusStyleMapping: { [key in MdsCardRadius]: string } = {
  [MdsCardRadius.None]: css({
    borderRadius: mdsBorderRadius().none,
  }),
  [MdsCardRadius.Medium]: css({
    borderRadius: mdsBorderRadius().medium,
  }),
  [MdsCardRadius.MediumLarge]: css({
    borderRadius: mdsBorderRadius().mediumLarge,
  }),
  [MdsCardRadius.Large]: css({
    borderRadius: mdsBorderRadius().large,
  }),
};

const cardKindStyleMapping: { [key in MdsCardKind]: string } = {
  [MdsCardKind.Seamless]: css({
    boxShadow: "none",
  }),
  [MdsCardKind.Flat]: css({
    boxShadow: "none",
  }),
  [MdsCardKind.XSmallShadow]: css({
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.05)`,
  }),
  [MdsCardKind.SmallShadow]: css({
    boxShadow: `0px 3px 5px 0px rgba(69, 79, 104, 0.04)`,
  }),
  [MdsCardKind.LargeShadow]: css({
    boxShadow: `0px 6px 10px 0px rgba(69, 78, 104, 0.2)`,
  }),
};

export const MdsCard: FC<MdsCardProps> = ({
  kind = MdsCardKind.Flat,
  size = MdsCardSize.Auto,
  radius = MdsCardRadius.Medium,
  border = MdsCardBorder.Solid,
  className,
  children,
  dataTestId,
}) => {
  const cardKindStyles = cardKindStyleMapping[kind];
  const cardSizeStyles = cardSizeStyleMapping[size];
  const cardRadiusStyles = cardRadiusStyleMapping[radius];
  const cardBorderStyles = cardBorderStyleMapping[border];

  const combinedStyles = cx(
    wrapperStyles,
    cardBorderStyles,
    cardKindStyles,
    cardRadiusStyles,
    cardSizeStyles,
    className
  );

  return (
    <div className={combinedStyles} data-test-id={dataTestId}>
      {children}
    </div>
  );
};
