import { MdsIconKind } from "@/design-system/components/icon";
import { NoteMediaKind } from "@/store/note";

export enum MediaKindsFacetFilterItemKind {
  Label = "label",
  MediaKind = "media-kind",
}

export type MediaKindsFacetFilterItem =
  | {
      id: string;
      kind: MediaKindsFacetFilterItemKind.Label;
      isSelected: boolean;
      label: string;
    }
  | {
      id: string;
      kind: MediaKindsFacetFilterItemKind.MediaKind;
      isSelected: boolean;
      label: string;
      data: {
        mediaKind: NoteMediaKind;
        icon: MdsIconKind;
      };
    };
