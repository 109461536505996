import { useCallback } from "react";

import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { windowModule } from "@/modules/window";
import { TrackedEvent, trackEvent } from "@/domains/metrics";

export const useAppBannerLinkOnClickHandler = ({ url }: { url: string }) => {
  const onClickHandler = useCallback(() => {
    /**
     * @TODO - If it is an in-app URL, we should navigate directly
     * instead of opening in a new tab.
     */
    const normalizedUrl = url;

    try {
      const parsedUrl = new URL(normalizedUrl);

      trackEvent(TrackedEvent.AppCalloutBannerActionClicked, {
        url: parsedUrl.toString(),
      });

      windowModule.openInNewTab({ url: parsedUrl });
    } catch (unknownErr) {
      const err = unknownErr as Error;

      logger.error({
        message: `[useAppBannerLinkOnClickHandler] Failed to open URL.`,
        info: {
          error: objectModule.safeErrorAsJson(err),
          url,
        },
      });
    }
  }, [url]);

  return { onClickHandler };
};
