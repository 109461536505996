import { appRoutes } from "@/app/router";
import { copyTextToClipboard } from "@/modules/clipboard/copyTextToClipboard";
import { toastModule } from "@/modules/toast";
import { windowModule } from "@/modules/window";

export const copyTemplateLinkToClipboard = async ({ templateId }: { templateId: string }) => {
  const targetUrl = windowModule.buildUrl({
    path: appRoutes.templatesView({ params: { templateId } }).path,
  });

  await copyTextToClipboard({
    text: targetUrl.toString(),
  });

  toastModule.triggerToast({ content: "Link copied to clipboard" });
};
