import { observer } from "mobx-react-lite";
import { useAppStore } from "@/store";
import { MdsVirtualizedItemList } from "@/design-system/components/item-list";

export const TemplatesListPageList = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.templatesListPage;

  return <MdsVirtualizedItemList rows={page.itemRows} loading={false} />;
});
