import { MdsTooltip } from "@/design-system/components/tooltip/MdsTooltip";

import { observer } from "mobx-react-lite";

import styled from "@emotion/styled";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { MdsIcon } from "@/design-system/components/icon";
import { TitleIcon } from "@/design-system/components/item-list/types";

interface Props {
  titleIcon: TitleIcon;
}

export const TitleIconComponent = observer(({ titleIcon }: Props) => {
  const { tooltip, kind, innerStyles } = titleIcon;

  if (!tooltip) {
    return (
      <Wrapper>
        <MdsIcon kind={kind} innerStyles={innerStyles} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <MdsTooltip
        config={{
          content: tooltip,
          placement: MdsTooltipPlacement.Top,
        }}
      >
        <MdsIcon kind={kind} innerStyles={innerStyles} />
      </MdsTooltip>
    </Wrapper>
  );
});

const Wrapper = styled.span(({ theme }) => ({
  marginLeft: theme.spacing.sm,
  fontSize: theme.fontSizes.xxsmall,
  fontWeight: theme.fontWeights.regular,
  lineHeight: theme.lineHeights.xxxsmall,
  color: theme.colors.grey.x500,
}));
