import { AppStore } from "@/store/AppStore";
import { SortByKind } from "@/modules/lenses/types";
import { AppStoreNoteStore } from "@/store/note/AppStoreNoteStore";
import { NotesSearchMentionedInParams } from "@/store/note/types";
import Dexie from "dexie";

/**
 * Search for notes mentioned in a note.
 *
 * @param store - AppStore instance
 * @param params - The parameters for the search. Important: offset and limit are done in memory, not on the database level.
 *
 * @returns The notes mentioned in the note.
 */
export const searchForNotesMentionedIn = async ({
  store,
  params,
}: {
  store: AppStore;
  params: NotesSearchMentionedInParams;
}) => {
  const { noteId, sortBy = SortByKind.LastModified, offset = 0, limit = 100 } = params;

  const sortByIndex = AppStoreNoteStore.sortByIndexes[sortBy];
  let dexieCollection = store.notes.localTable
    .where(AppStoreNoteStore.mentionedNoteIdsIndex)
    .equals(noteId) // equals actually means "contains" in this case: https://dexie.org/docs/MultiEntry-Index#querying-multientry-indexes
    .and(note => note.is_available === 1)
    .distinct();

  // For all but alphabetical we need to sort from "largest" to "smallest"
  // And only alphabetical must go in natural order: A-Z
  if (sortBy !== SortByKind.Alphabetical) {
    dexieCollection = dexieCollection.reverse();
  }

  const results = await Dexie.waitFor(dexieCollection.sortBy(sortByIndex));

  return results.slice(offset, offset + limit);
};
