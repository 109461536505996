import { makeObservable, observable } from "mobx";

export class AccountTransitionInfoObservable {
  forceUpgradedToVersionTwo: boolean;
  hasMatchingVersionTwoAccount: boolean;
  versionTwoTransitionDecisionRequired: boolean;

  constructor({
    forceUpgradedToVersionTwo,
    hasMatchingVersionTwoAccount,
    versionTwoTransitionDecisionRequired,
  }: {
    forceUpgradedToVersionTwo: boolean;
    hasMatchingVersionTwoAccount: boolean;
    versionTwoTransitionDecisionRequired: boolean;
  }) {
    this.forceUpgradedToVersionTwo = forceUpgradedToVersionTwo;
    this.hasMatchingVersionTwoAccount = hasMatchingVersionTwoAccount;
    this.versionTwoTransitionDecisionRequired = versionTwoTransitionDecisionRequired;

    makeObservable(this, {
      forceUpgradedToVersionTwo: observable,
      hasMatchingVersionTwoAccount: observable,
      versionTwoTransitionDecisionRequired: observable,
    });
  }
}
