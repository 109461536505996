import { css } from "@/domains/emotion";
import styled from "@emotion/styled";
import {
  MdsMenuItemSize,
  MdsMenuItemProps,
  MdsMenuItemSelectionVariant,
} from "@/design-system/components/menu-item/types";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { MdsShortcutKey } from "@/design-system/components/shortcut-key/MdsShortcutKey";
import { MdsShortcutKeyCharacter } from "@/design-system/components/shortcut-key/types";
import { mdsColors } from "@/design-system/foundations/colors";

// Figma Link: https://www.figma.com/design/ZEgqtUJHh5ksFWQ7Nq0P2u/Mem-Design-System?node-id=7335-32433&m=dev

const iconSizeStyleMapping: { [key in MdsMenuItemSize]: string } = {
  [MdsMenuItemSize.Small]: css({
    height: 16,
  }),
  [MdsMenuItemSize.Medium]: css({
    height: 32,
  }),
};

export const MdsMenuItem = ({
  size,
  title,
  subtitle,
  icon,
  isSelected,
  selectionVariant = MdsMenuItemSelectionVariant.Checkmark,
  isShared,
  rightLabel,
  shortcutKeys,
  onClick,
}: MdsMenuItemProps) => {
  return (
    <Wrapper size={size} onClick={onClick}>
      <WrapperLeft>
        <MdsMenuItemIcon icon={icon} size={size} />
        <WrapperText>
          <WrapperTextTop>
            <Title>{title}</Title>
            {isShared && (
              <MdsIcon
                kind={MdsIconKind.Shared}
                innerStyles={{ Icon: { className: SharedIconStyles } }}
              />
            )}
          </WrapperTextTop>
          {size === MdsMenuItemSize.Medium && subtitle && <Subtitle>{subtitle}</Subtitle>}
        </WrapperText>
      </WrapperLeft>
      <WrapperRight>
        <MdsMenuItemSelected isSelected={isSelected} selectionVariant={selectionVariant} />
        <MdsMenuItemRightLabel rightLabel={rightLabel} />
        <MdsMenuItemShortcutKeys shortcutKeys={shortcutKeys} />
      </WrapperRight>
    </Wrapper>
  );
};

export const MdsMenuItemIcon = ({
  icon,
  size,
}: {
  icon: MdsMenuItemProps["icon"];
  size: MdsMenuItemSize;
}) => {
  const iconSize = size === MdsMenuItemSize.Small ? 16 : 32;
  if (!icon) return null;
  const iconContent =
    typeof icon === "function" ? (
      icon(iconSize)
    ) : typeof icon === "string" ? (
      <MdsIcon
        kind={icon as MdsIconKind}
        innerStyles={{ Icon: { className: iconSizeStyleMapping[size] } }}
      />
    ) : (
      icon
    );
  return <WrapperIcon>{iconContent}</WrapperIcon>;
};

const MdsMenuItemShortcutKeys = ({
  shortcutKeys,
}: {
  shortcutKeys?: MdsShortcutKeyCharacter[];
}) => {
  if (!shortcutKeys) return null;
  return (
    <ShortcutKeysWrapper>
      {shortcutKeys.map(key => (
        <MdsShortcutKey key={key} character={key} />
      ))}
    </ShortcutKeysWrapper>
  );
};

const MdsMenuItemRightLabel = ({ rightLabel }: { rightLabel?: string }) => {
  if (!rightLabel) return null;
  return <RightLabel>{rightLabel}</RightLabel>;
};

const MdsMenuItemSelected = ({
  isSelected,
  selectionVariant,
}: {
  isSelected?: boolean;
  selectionVariant: MdsMenuItemSelectionVariant;
}) => {
  if (isSelected === undefined) return null;
  if (selectionVariant === MdsMenuItemSelectionVariant.Checkmark) {
    if (isSelected) return <MdsIcon kind={MdsIconKind.Check} />;
    return null;
  }
  if (selectionVariant === MdsMenuItemSelectionVariant.RadioButton) {
    if (isSelected)
      return (
        <MdsIcon
          kind={MdsIconKind.CheckCircleSolid}
          innerStyles={{ Icon: { className: css({ color: mdsColors().blue.x500 }) } }}
        />
      );
    if (!isSelected)
      return (
        <MdsIcon
          kind={MdsIconKind.Circle}
          innerStyles={{ Icon: { className: css({ color: mdsColors().grey.x400 }) } }}
        />
      );
  }
  return null;
};

const Wrapper = styled.button<{ size: MdsMenuItemSize }>(({ theme, size }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing.sm,
  height: size === MdsMenuItemSize.Small ? "32px" : "50px",
  width: "100%",
  minWidth: "176px",
  maxWidth: "288px",
  borderRadius: theme.borderRadius.mediumLarge,
  "&:hover": {
    backgroundColor: theme.colors.grey.x25,
  },
}));

const WrapperLeft = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing.sm,
  overflow: "hidden",
  minWidth: 0,
}));

const WrapperRight = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing.sm,
  gap: theme.spacing.sm,
  flexShrink: 0,
}));

const WrapperIcon = styled.div(() => ({
  flexShrink: 0,
}));

const WrapperText = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  fontSize: theme.fontSizes.small,
  overflow: "hidden",
  minWidth: 0,
}));

const WrapperTextTop = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing.sm,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
}));

const Title = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.xsmall,
  textAlign: "left",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
}));

const Subtitle = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.xxsmall,
  lineHeight: theme.lineHeights.xsmall,
  color: theme.colors.grey.x500,
  textAlign: "left",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "100%",
}));

const RightLabel = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.xsmall,
  color: theme.colors.grey.x500,
}));

const ShortcutKeysWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.xs,
}));

const SharedIconStyles = css({
  height: 12,
  width: 12,
});
