import { uuidModule } from "@/modules/uuid";
import { ApiKeyModelData, ApiKeyUpsertedSyncUpdateValue } from "@/store/api-keys/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateApiKeyOperation } from "@/store/sync/operations/types";
import { SyncModelData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";
import { DateTime } from "luxon";

export class CreateApiKeyOperation extends BaseSyncOperation<ICreateApiKeyOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_API_KEY";
  }

  public async generateOptimisticUpdates(): Promise<OptimisticSyncUpdate<SyncModelData>[]> {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    const apiKeyUpdate = this.generateCreateApiKeyUpdate();

    if (apiKeyUpdate) {
      optimisticUpdates.push(apiKeyUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.apiKeys.recompute(this.payload.id);
  }

  protected generateCreateApiKeyUpdate(): OptimisticSyncUpdate<ApiKeyModelData> {
    const value: ApiKeyUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "API_KEY",
      model_version: 0,
      model_data: {
        id: this.payload.id,
        title: this.payload.title ?? null,
        description: this.payload.description ?? null,
        created_at: DateTime.now().toISO(),
        is_revoked: false,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const syncUpdate: OptimisticSyncUpdate<ApiKeyModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
