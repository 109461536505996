import { IMDSSwitchFloatyProps } from "./switch-floaty.types";
import {
  RightContainer,
  LeftContainer,
  StyledSwitchFloaty,
  IconWrapper,
} from "./switch-floaty.style";
import { MDSSwitch } from "@/mds/components/forms";
import { MdsIcon } from "@/design-system/components/icon";
import { useState } from "react";

export const MDSSwitchFloaty = (props: IMDSSwitchFloatyProps) => {
  const { iconKind, label, style, defaultSelected, onChange, switchProps, ...buttonProps } = props;
  const [selected, setSelected] = useState(defaultSelected);

  const handleClick = () => {
    if (buttonProps.isDisabled) return;
    const value = !selected;
    handleChange(value);
  };

  const handleChange = (value: boolean) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <StyledSwitchFloaty
      onPress={handleClick}
      {...buttonProps}
      style={style}
      data-selected={selected}
    >
      <LeftContainer>
        {iconKind && (
          <IconWrapper>
            <MdsIcon kind={iconKind} />
          </IconWrapper>
        )}
        {label && <span>{label}</span>}
      </LeftContainer>

      <RightContainer>
        <MDSSwitch
          {...switchProps}
          isSelected={selected}
          onChange={handleChange}
          style={{ cursor: "pointer" }}
        />
      </RightContainer>
    </StyledSwitchFloaty>
  );
};
