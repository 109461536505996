import { computed, makeObservable } from "mobx";
import { BaseSyncModel } from "@/store/sync/BaseSyncModel";
import { SyncModelKind } from "@/store/sync/types";
import { WithAppStore } from "@/store/types";
import { UploadedFileModelData } from "./types";
import { SyncUpdateValue } from "@/store/sync/types";

export class UploadedFileObservable extends BaseSyncModel<UploadedFileModelData> {
  public modelKind = SyncModelKind.UploadedFile;

  constructor({
    id,
    data,
    store,
  }: { id: string; data: SyncUpdateValue<UploadedFileModelData> } & WithAppStore) {
    super({ id, data, store });

    makeObservable(this, { normalizedFilename: computed, modelKind: false });
  }

  get normalizedFilename() {
    return this.data.model_data.normalized_filename;
  }
}
