import Icons from "@/assets/icons/Icons";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsTextSize } from "@/design-system/components/text";
import { MdsText } from "@/design-system/components/text/MdsText";
import { mdsColors } from "@/design-system/foundations/colors";
import { css } from "@/domains/emotion";
import { observer } from "mobx-react-lite";

export enum EntityNotFoundType {
  Note = "note",
  Collection = "collection",
  Template = "template",
}

export interface NotFoundPageProps {
  entityType?: EntityNotFoundType;
  withBackButton?: () => void;
}

export const NotFoundPage = observer<NotFoundPageProps>(function NotFound({
  entityType,
  withBackButton,
}) {
  return (
    <div className={containerStyles}>
      <div className={innerStyles}>
        <Icons.NotFound height="80px" width="80px" />
        <MdsText
          color={mdsColors().grey.x500}
          size={MdsTextSize.Small}
          lineHeight={MdsTextSize.Small}
        >
          {entityType && `This ${entityType} doesn’t exist`}
          {!entityType && `Doesn’t exist`}
        </MdsText>
        {!!withBackButton && (
          <MdsButton
            label="Go back"
            iconKind={MdsIconKind.LeftArrow}
            variant={MdsButtonVariant.TextTertiaryOutlined}
            onClick={withBackButton}
          />
        )}
      </div>
    </div>
  );
});

const containerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100dvh",
  justifyContent: "center",
  minHeight: 200,
});

const innerStyles = css({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  justifyContent: "center",
  marginBottom: "10vh",
});
