import { mdsColors } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { SettingsSubsectionWrapper } from "@/pages/settings/components/SettingSubsectionWrapper";
import { SettingsSectionWrapper } from "@/pages/settings/components/SettingsSectionWrapper";
import { SettingsApiKeysSection } from "@/pages/settings/components/api-keys/SettingsApiKeysSection";
import { observer } from "mobx-react-lite";
import { FC } from "react";

/**
 * @TODO - @MacroMackie - This was is a temporary implementation for now.
 * (Just copied from the other Settings-Pages).
 *
 * When we roll this out more broadly, we should rewrite these pages with best practices.
 */
export const SettingsApiKeysPage: FC = observer(() => {
  return (
    <SettingsSectionWrapper>
      <SettingsSubsectionWrapper title="API Keys">
        <div className={wrapperStyles}>
          <SettingsApiKeysSection />
        </div>
      </SettingsSubsectionWrapper>
    </SettingsSectionWrapper>
  );
});

/**
 * @TODO - @MacroMackie - This was is a temporary implementation for now.
 * (Just copied from the other Settings-Pages).
 *
 * When we roll this out more broadly, we should rewrite these pages with best practices.
 */
const wrapperStyles = css({
  fontSize: 14,
  lineHeight: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  color: mdsColors().grey.x700,
});
