import { css } from "@/domains/emotion";
import { OutboundSyncStatus } from "@/components/sync/types";
import { useMemo } from "react";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations/colors";
import { SyncState } from "@/components/sync/types";

interface Props {
  syncState: SyncState;
}

export const SyncStatusIcon = ({ syncState }: Props) => {
  const iconKind = useIconKind(syncState.status);
  const iconStyles = useIconStyles(syncState.status);

  // TODO: Re-enable this once we have inbound sync working
  if (syncState.status === OutboundSyncStatus.Idle) {
    return null;
  }

  return <MdsIcon kind={iconKind} className={iconStyles} />;
};

const useIconKind = (syncStatus: OutboundSyncStatus): MdsIconKind => {
  return useMemo(() => {
    if (syncStatus === OutboundSyncStatus.Paused) {
      return MdsIconKind.CloudSlashFlipped;
    }

    if (syncStatus === OutboundSyncStatus.Syncing) {
      return MdsIconKind.Spinner;
    }

    if (syncStatus === OutboundSyncStatus.Idle) {
      return MdsIconKind.CheckCircleSolid;
    }

    return MdsIconKind.CloudSlashFlipped;
  }, [syncStatus]);
};

const useIconStyles = (syncStatus: OutboundSyncStatus) => {
  return useMemo(() => {
    if (syncStatus === OutboundSyncStatus.Paused) {
      return css({
        marginTop: 4,
        color: mdsColors().mblue.x600,
      });
    }

    if (syncStatus === OutboundSyncStatus.Syncing) {
      return css({
        marginTop: 2,
        color: mdsColors().mblue.x600,
      });
    }

    if (syncStatus === OutboundSyncStatus.Idle) {
      return css({
        marginTop: 2,
        color: mdsColors().green.x500,
      });
    }

    return css({
      marginTop: 4,
      color: mdsColors().grey.x500,
    });
  }, [syncStatus]);
};
