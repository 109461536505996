import { actions } from "@/actions";
import { NoteType } from "@/components/note/types";
import {
  MdsDropdownIconButton,
  MdsDropdownContentList,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsTooltipPlacement } from "@/design-system/components/tooltip";
import { AnimationTiming } from "@/design-system/constants/common";
import { css } from "@/domains/emotion";
import { useAppStore } from "@/store";
import { INoteObservable } from "@/store/note/types";
import { TemplateObservable } from "@/store/templates/TemplateObservable";
import { observer } from "mobx-react-lite";

interface NoteEditorTopBarExtraActionsDropdownButtonProps {
  note?: INoteObservable | TemplateObservable;
  noteType?: NoteType;
  inSidePanel?: boolean;
}

export const NoteEditorTopBarExtraActionsDropdownButton =
  observer<NoteEditorTopBarExtraActionsDropdownButtonProps>(
    function NoteEditorTopBarExtraActionsDropdownButton({
      note,
      noteType = NoteType.Note,
      inSidePanel,
    }) {
      const { store } = useAppStore();

      if (!note) return null;

      const contentList: MdsDropdownContentList = {
        items: [
          {
            id: "copy-note-link",
            kind: MdsDropdownItemKind.Button,
            label: "Copy link",
            iconKind: MdsIconKind.Link,
            onClick: () => actions.copyNoteLinkToClipboard({ noteId: note.id, noteType }),
          },
          {
            id: "move-to-trash",
            kind: MdsDropdownItemKind.Button,
            label: "Delete",
            iconKind: MdsIconKind.Trash,
            onClick: async () => {
              if (note instanceof TemplateObservable) {
                await note.delete();
              } else {
                await note.moveToTrash();
              }
              switch (noteType) {
                case NoteType.Note:
                  if (inSidePanel) {
                    store.sidePanel.goUp();
                  } else {
                    store.navigation.goToNotes();
                  }
                  break;
                case NoteType.Template:
                  if (inSidePanel) {
                    store.sidePanel.goUp();
                  } else {
                    store.navigation.goToTemplates();
                  }
                  break;
              }
            },
          },
        ],
      };

      return (
        <MdsDropdownIconButton
          buttonClassName={moreButtonStyles}
          contentList={contentList}
          placement="below-right-alignment"
          tooltipConfig={{
            label: "More",
            placement: MdsTooltipPlacement.Top,
            delaySeconds: AnimationTiming.TooltipHoverDelaySeconds,
          }}
        />
      );
    }
  );

const moreButtonStyles = css({
  maxWidth: "36px",
});
